export const RegisteredGarageTableConfig = {
  columns: [
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'Id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'paymentId',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'SerialNo',
      sortable: false,
      header: {
        text: 'serial_no',
        tooltip: '',
      },
      selector: {
        field: 'serialNo',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'DocumentName',
      sortable: false,
      selector: {
        field: 'name',
      },
      header: {
        text: 'document',
        tooltip: '',
        style: 'text-align: center;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Date',
      sortable: false,
      selector: {
        field: 'date',
      },
      header: {
        text: 'date',
        tooltip: '',
        style: 'text-align: center;'
      },
      cellStyle: 'justify-content: center;',
      widget: {
        type: 'date',
      },
    },
    {
      id: 'Attachment',
      sortable: false,
      selector: {
        field: 'url',
      },
      header: {
        text: 'attachment',
        tooltip: 'attachment',
        style: 'text-align: center;'
      },
      childColumns: [ {
        id: 'Download',
        // disableColumns: true,
        selector: {
          field: 'url',
        },
        widget: {
          type: 'icon',
          config: {
            svgIcon: 'download-arrow',
          }
        },
      }, {
        id: 'Preview',
        selector: {
          field: 'url',
        },
        // disableColumns: true,
        widget: {
          type: 'icon',
          config: {
            svgIcon: 'eye',
          }
        },
      } 
      ],
      cellStyle: 'justify-content: center;',
    }
  ]
}
<div class="main-container">
    <ng-container *ngIf="!formSubmitted; else submittedTemplate">
        <header>
            <img src="assets/img/addenda-icon.png"
                 alt="addenda">
            <div class="header-content">
                <strong>{{'self_registration' | translate}}</strong>
                <span>{{'self_registration_msg' | translate}}</span>
            </div>
        </header>
        <div class="border">
            <div class="highlight"></div>
        </div>

        <div class="main-content">
            <div class="notification"
                 *ngIf="!hideNotification">
                <mat-icon svgIcon="lock"></mat-icon>
                <span>{{'privacy_issue_msg' | translate}}</span>
                <mat-icon svgIcon="cross"
                          class="right-icon"
                          (click)="hideNotification = true"></mat-icon>
            </div>
            <main>
                <form [formGroup]="onboardingForm">
                    <section>
                        <div class="form-row">
                            <div class="xa-form-group">
                                <xa-text label="{{'business_type' | translate}}"
                                         [disabled]="true"
                                         name="business type"
                                         formControlName="businessType">
                                </xa-text>
                            </div>
                            <div class="xa-form-group hide-sm"></div>
                        </div>
                        <div class="form-row">
                            <div class="xa-form-group">
                                <xa-text label="{{'business_name' | translate}}"
                                         name="business name"
                                         [required]="true"
                                         formControlName="businessName">
                                </xa-text>
                            </div>
                            <div class="xa-form-group">
                                <xa-select [config]="designationConfig"
                                           [options]="designationList"
                                           formControlName="designation"></xa-select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="xa-form-group">
                                <div class="mobile-fields">
                                    <label class="form-label uppercase"
                                           for="">{{'enter_mobile_number' | translate}} <span class="required">*</span></label>
                                    <div class="control-wrapper">
                                        <xa-select class="code"
                                                   formControlName="code"
                                                   [config]="codeConfig"
                                                   [options]="countryList"></xa-select>
                                        <xa-tel placeholder="{{'mobile_number' | translate}}"
                                                formControlName="phone">
                                        </xa-tel>
                                    </div>
                                </div>
                            </div>
                            <div class="xa-form-group">
                                <xa-text label="{{'email_id' | translate}}"
                                         name="email"
                                         [required]="true"
                                         formControlName="email">
                                </xa-text>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="form-row">
                            <div class="xa-form-group">
                                <xa-select [config]="countryConfig"
                                           [options]="countryList"
                                           [showRequired]="true"
                                           formControlName="country"></xa-select>
                            </div>
                            <div class="xa-form-group">
                                <xa-select [config]="regionConfig"
                                           [options]="regionList"
                                           [showRequired]="true"
                                           formControlName="region"></xa-select>
                            </div>
                            <div class="xa-form-group">
                                <xa-select [config]="cityConfig"
                                           [options]="cityList"
                                           [showRequired]="true"
                                           formControlName="city"></xa-select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="form-row">
                            <div class="xa-form-group">
                                <xa-select [config]="productConfig"
                                           [options]="productList"
                                           formControlName="productInterested"></xa-select>
                            </div>
                            <div class="xa-form-group">
                                <xa-select [config]="serviceConfig"
                                           [options]="serviceList"
                                           formControlName="services"
                                           *ngIf="onboardingForm.get('productInterested').value === 1"></xa-select>
                            </div>
                        </div>

                        <div class="document-section">
                            <div class="header">
                                <strong>{{'documents' | translate}}</strong>
                                <span>{{'trade_license' | translate}} / {{'registration' | translate}} / {{'government_id' | translate}}</span>
                            </div>
                            <div class="card-container">
                                <ng-container *ngFor="let document of documents">
                                    <ng-container *ngIf="!document.image; else imageTemplate">
                                        <div class="card">
                                            <div class="card-header">{{document.name | translate}}</div>
                                            <div class="card-body">
                                                <mat-icon svgIcon="file-document-outline"></mat-icon>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button"
                                                        class="mat-ripple xa-button"
                                                        size="sm"
                                                        appearancetype="default"
                                                        color="primary"
                                                        bordertype="default"
                                                        (click)="currentSelection = document.name;file.click()">
                                                    <mat-icon svgIcon="upload"></mat-icon>
                                                    {{'upload' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #imageTemplate>
                                        <div class="card image">
                                            <div class="card-content">
                                                <img [src]="document.image"
                                                     alt="image">
                                            </div>
                                            <div class="card-title">{{document.name | translate}}</div>
                                            <div class="card-action">
                                                <mat-icon color="black"
                                                          svgIcon="view"
                                                          (click)="previewDocument(document)"></mat-icon>
                                                <div class="horizontal-line"></div>
                                                <mat-icon svgIcon="delete"
                                                          color="red"
                                                          (click)="deleteDocument(document)"></mat-icon>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>

                            </div>
                            <input type="file"
                                   hidden
                                   #file
                                   (change)="handleUpload($event)"
                                   accept="image/png, image/jpg, image/jpeg" />
                        </div>

                        <div class="recaptcha-container">
                            <re-captcha formControlName="recaptchaKey"></re-captcha>
                        </div>
                    </section>
                </form>
            </main>
            <footer>
                <xa-button (click)="submit()"
                           [disabled]="onboardingForm.invalid">{{'submit' | translate}}</xa-button>
            </footer>
        </div>
    </ng-container>
    <ng-template #submittedTemplate>
        <div class="submit-flow">
            <div class="submit-card">
                <mat-icon svgIcon="submit-success"></mat-icon>
                <div class="content">
                    <strong>{{'thank_you_form_submission' | translate}}</strong>
                    <strong>{{'team_will_reach_out_msg' | translate}}</strong>
                </div>
            </div>
        </div>
    </ng-template>

</div>
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { RecoveryDetailsForm } from 'src/app/helper/form/recovery-add-form.helper';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import * as dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { BeneficiaryListDialogComponent } from '../beneficiary-list-dialog/beneficiary-list-dialog.component';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { XaInlineDocumentUploaderComponent } from 'src/app/widgets/xa-inline-document-uploader/xa-inline-document-uploader.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-edit-recovery-dialog',
  templateUrl: './edit-recovery-dialog.component.html',
  styleUrls: [ './edit-recovery-dialog.component.scss' ]
})
export class EditRecoveryDialogComponent implements OnInit, OnDestroy {

  public uploader: ElementRef;
  @ViewChild('documentUpload') documentUpload: XaInlineDocumentUploaderComponent;
  /**
    Sets the content of the uploader element.
    @param {ElementRef} content - The content of the uploader element.
  */
  @ViewChild('uploader') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.uploader = content;
    }
  }

  public i18n: Localization;
  public file: any[] = [];
  public controls: DynamicFormBase<any>[] | null;
  public userData: any;
  public formGroup: FormGroup;

  public recoveryData: any;
  public isEdit: boolean;
  private beneficiaryDetail: any;
  public existingDocumentConfig: XADropdownConfig = {
    name: 'existingDocument',
    label: 'select_document',
    placeHolder: 'select_from_existing',
    labelKey: 'name',
    valueKey: 'id'
  }

  private readonly optionSubscription: Subscription;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
  */
  constructor(
    public dialogRef: MatDialogRef<EditRecoveryDialogComponent>,
    private readonly monitorService: MonitorService,
    private readonly commonService: CommonService,
    private readonly claimService: ClaimsService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly translateService: TranslateService,
    private readonly mediaCollectionService:MediaCollectionService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.i18n = this.commonService.geti18nInfo();
    this.commonService.userProfileData.subscribe((userData) => {
      if (userData?.data) {
        this.userData = userData.data;
      }
    });

    
    this.optionSubscription = this.dynamicFormService.updatedOption.subscribe((option) => {
      if (option?.item === 'search') {
        this.openBeneficiaryDialog();
      }
    });
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.initializeFormGroup();
    this.getRecoveryDetail();
  }

  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'EditRecoveryDialogComponent', 'addenda-claim' ]);
    this.dialogRef.close();
  }

  /**
   * initialize form group
   */
  initializeFormGroup(): void {

    const formBuilderForm = cloneDeep(RecoveryDetailsForm);
    const controls = formBuilderForm.map((it) => { return this.dynamicFormService.controlMapper(it, this.userData) });
    
    this.controls = this.dynamicFormService.getSortedFormControls(controls);
    const claimantsControl = this.controls.find(q => q.key == 'claimantName');
    claimantsControl.options = [ { id: this.data.objectGuid, name: this.data.claimantName } ];

    this.controls = [ ...this.controls ];
    this.formGroup = this.dynamicFormService.createFormGroup(this.controls);
  }

  /**
   * get recovery detail
   */
  getRecoveryDetail(): void {
    const recoveryGuid = this.data.guid;
    const claimGuid = this.data.objectGuid;

    this.commonService.showLoading();
    this.claimService.getRecoveryDetail(recoveryGuid, claimGuid).subscribe({
      next: (response: any) => {
        if (response?.value?.length == 1) {
          this.recoveryData = response.value[0];

          this.beneficiaryDetail = {
            'beneficiaryName': this.recoveryData.BeneficiaryName || '',
            'beneficiaryId': this.recoveryData.BeneficiaryId,
            'beneficiaryTypeId': this.recoveryData.BeneficiaryLookupTypeId
          }

          const obj = {
            'recoveryDate': this.recoveryData.RecoveryDate,
            'beneficiary': this.recoveryData.BeneficiaryName,
            'recoveryTypeId': this.recoveryData.RecoveryTypeId,
            'claimantName': this.data.objectGuid,
            'recoveryAmount': this.recoveryData.Amount,
            'status': this.recoveryData.RecoveryStatusId,
            'remarks': this.recoveryData.Remarks
          }

          this.formGroup.patchValue(obj);
        }
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
  Handles the click event for the "Add File" button.
  @param {Event} event - The click event.
  @returns {void}
  */
  handleAddFileClick(): void {
    this.uploader.nativeElement.click()
  }

  /**
   * handle file upload
   * @param event 
  */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.monitorService.logEvent('handleUpload', [ 'EditRecoveryDialog', 'addenda-claims', {
      event
    } ]);
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translateService.instant('file_size_5mb_error_message');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = this.translateService.instant('upload_type_not_supported');
        this.commonService.showToast(0, message);
        this.uploader.nativeElement.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (): void => {
        this.file.push(currentFile[0]);
      }
    }
  }

  /**
 * 
 * @param url 
 */
  download(document): void {
    let documentUrl = '';
    if (document.BlobUrl) {
      documentUrl = document.BlobUrl;
      this.commonService.download(documentUrl);
    } else if (document) {
      const blob = window.URL.createObjectURL(document);
      const link = window.document.createElement('a');
      link.href = blob;
      link.download = decodeURIComponent(document.name);

      // Version link.click() to work at firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      setTimeout(() => {
        // firefox
        window.URL.revokeObjectURL(blob);
        link.remove();
      }, 100);
    }
  }

  /**
 * 
 * downloadMediaPart 
 */
  downloadMediaPart(document): void {
    if(document.BlobUrl){
      this.commonService.download(document.BlobUrl);
    }else
      if (document.MediaGuid && document.MediaPartGuid) {
        this.commonService.showLoading()
        this.mediaCollectionService
          .getMediaPartDownloadUrl(document.MediaGuid, document.MediaPartGuid, this.data.claimId, this.data.domainId)
          .subscribe({
            next: (res) => {
              this.commonService.hideLoading();
              if(res?.data?.mediaParts?.[0]?.getURL) {
                this.commonService.download(res?.data?.mediaParts?.[0]?.getURL)
              }
            },
            error: () => {
              this.commonService.hideLoading();
            }
          })
      }
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    this.monitorService.logEvent('checkFileSize', [ 'EditRecoveryDialogComponent', 'addenda-claim', {
      fileSize: img.size
    } ]);
    if (img.size > this.commonService.maxFileSize) {
      return false;
    }
    return true;
  }

  /**
 * check filename
 * @param filename 
 * @returns 
 */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    this.monitorService.logEvent('isValidImageFile', [ 'PaymentAddComponent', 'addenda-repair', {
      fileName: filename
    } ]);
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      return this.commonService.allowFilesExtension.includes(extension?.toLowerCase());
    }
    return false;
  }

  /**
    Removes a file from the component's state.
    @param {any} event - The event object that triggered the function.
    @returns {void}
  */
  handleFileRemove(doc?: any): void {
    this.commonService.showLoading();
    this.claimService.deleteRecoveryDocument(this.data.objectGuid, this.data.guid, doc.DocumentGuid).subscribe((res) => {
      if(res) {
        if(doc.Name.includes('Additional')) {
          this.mediaCollectionService.deleteMediaTemplate(doc.MediaGuid, doc.MediaPartGuid, this.data?.claimId, this.data?.domainId).subscribe((resMediaDelete) => {
            if(resMediaDelete) {
              this.commonService.hideLoading();
              this.getRecoveryDetail();
            }
          })
        } else {
          this.commonService.hideLoading();
          this.getRecoveryDetail();
        }
      }
    })
  }

  /**
   * open beneficiary dialog
   */
  openBeneficiaryDialog(): void {
    const dialogRef = this.dialog.open(BeneficiaryListDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '60vw',
      maxHeight: '58vh',
      panelClass: 'beneficiary-panel',
      disableClose: false, //should be true later
      data: {
        garageId: null
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.beneficiaryDetail = response;
        this.formGroup.get('beneficiary').patchValue(this.beneficiaryDetail.beneficiaryName);
      }
    })
  }

  /**
     * save payment
     */
  async saveRecovery(): Promise<void> {
    this.commonService.showLoading();
    if (this.formGroup.valid) {
      let documentDetails = [];
      const filesToUpload = this.documentUpload?.file.filter(ele => !ele.url)
      if(filesToUpload.length > 0) {
        const uploadedDocs = await this.claimService.uploadDocs(filesToUpload, this.documentUpload?.mediaCollectionGuid, this.data?.claimId) ;
        documentDetails = uploadedDocs.map((ele) => {
          return {
            'name': ele.name,
            'mediaGuid': ele.mediaGuid,
            'mediaPartGuid': ele.mediaPartGuid,
          }
        })
      }
      
      this.documentUpload?.file.forEach((ele) => {
        if(ele.url) {
          documentDetails.push({
            'name': ele.name,
            'mediaGuid': ele.mediaGuid,
            'mediaPartGuid': ele.mediaPartGuid,
          })
        }
      })
     
      const formObj = {
        ...this.formGroup.value,
        recoveryStatusId: this.formGroup.get('status').value,
        recoveryTypeId: this.formGroup.get('recoveryTypeId').value,
        recoveryDate: dayjs(this.formGroup.get('recoveryDate').value).format('YYYY-MM-DD'),
        beneficiaryId: this.beneficiaryDetail?.beneficiaryId,
        beneficiaryLookupTypeId: this.beneficiaryDetail?.beneficiaryTypeId,
        beneficiaryName: this.beneficiaryDetail?.beneficiaryName,
        amount: this.formGroup.get('recoveryAmount').value,
        objectGuid: this.data.objectGuid,
        recoveryGuid: this.data.guid,
        remarks: this.formGroup.get('remarks').value,
        documentDetails: documentDetails
      }
      this.claimService.saveRecoveryJson(formObj).subscribe({
        next: () => {
          this.commonService.hideLoading();
          this.dialogRef.close(true);
        },
        error: () => {
          this.commonService.hideLoading();
        }
      })
    } else {
      this.commonService.showToast(0, 'Form is invalid');
    }
  }

  /**
   * 
   * @param val 
   * @returns 
   */
  capitalizeFirstLetter(val: string): string {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.optionSubscription.unsubscribe();
    this.dynamicFormService.broadcastOption(null);
  }
  
}

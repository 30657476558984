<div class="message-container">
    <div class="header">
        <h3>{{ (data.hideUpdate ? 'view_notes' : !this.data.notes? 'add_notes':'edit_notes') | translate }}</h3>
        <div class="close-icon">
            <a (click)="close();">
                <mat-icon [svgIcon]="'cross'"></mat-icon>
            </a>
        </div>
    </div>
    <div class="content">
        <chq-input [inputModel]="notesModel"
                   (controlOutput)="onFormUpdate($event);"
                   tabindex="-1"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="cancelButton"
                    *ngIf="this.data.notes && !this.data.hideUpdate && !this.commonService.isViewOnly"
                    class="left"></chq-button>
        <chq-button [buttonModel]="confirmButton"
                    *ngIf="!this.data.hideUpdate && !this.commonService.isViewOnly"
                    class="right"></chq-button>
    </div>
</div>
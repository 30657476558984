import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DamageReport } from 'src/app/model/chq-damage-report';
import { FooterModel } from 'src/app/model/chq-upload-model';

@Component({
  selector: 'repair-recommendation',
  templateUrl: './repair-recommendation.component.html',
  styleUrls: [ './repair-recommendation.component.scss' ]
})
export class RepairRecommendationComponent implements OnChanges {
  footerOptions: FooterModel ={
    'text': 'next_create_estimate',
    'icon': 'boardview',
    'route': 'case/gt',
    'status': '',
    'currentStatus': 'RepairEstimation',
    'caseId': '30e7a26d-6b87-4fe1-8054-8487609deb22',
    'showBack': true,
    'hideEdit': true,
    'showUploadOption': false
  };
  @Input() damageReport: DamageReport[];
  @Input() damageRecommendations: any[];
  public currentCategorization = '';
  public currentCategoryUrl = '';
  filteredDamageReport: DamageReport[] = [];
  @Output() backEvent:EventEmitter<any> = new EventEmitter();

  /**
   * handle OnChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['damageReport'] && changes['damageReport'].currentValue) {
      this.filteredDamageReport = this.damageReport.filter(report=> report.status === 'Analysed');
      const unique = Array.from(new Set(this.filteredDamageReport.map((dm:any) => dm.categorization)));
      const isBodyshop = unique.length > 1;
      if(isBodyshop){
        const bodyShopData = this.filteredDamageReport.filter(report=> report.categorization === 'Bodyshop');
        this.currentCategorization = bodyShopData[0].categorization;
        this.currentCategoryUrl = bodyShopData[0].categorizationIconUrl;
      }else{
        this.currentCategorization = this.filteredDamageReport[0].categorization;
        this.currentCategoryUrl = this.filteredDamageReport[0].categorizationIconUrl;
      }
    }
  }
  /**
   * Return repair category even If one View has categorization = Bodyshop return Bodyshop else return Carheal x
   */
  getRepairCategory():any {
    // filter damageReport to check
    return ''
  }

  /**
   * get is repair
   */
  isQuote():boolean{
    return location.href.includes('quote');
  }

  /**
   * handle showAI button
   */
  showAI(): void {
    this.backEvent.emit(true)
  }

  /**
   * CHecks if current browser is mac or not
   * @returns boolean
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ClaimBeneficiariesTableConfig, ClaimGarageBeneficiariesTableConfig } from 'src/app/config/display/claims-table-config';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { ApiPagination, defaultPagination, PaginateInfo } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData } from 'src/app/widgets/xa-table/xa-table.component';
import { AddBeneficiaryDialogComponent } from '../add-beneficiary-dialog/add-beneficiary-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-beneficiary-list-dialog',
  templateUrl: './beneficiary-list-dialog.component.html',
  styleUrls: [ './beneficiary-list-dialog.component.scss' ]
})
export class BeneficiaryListDialogComponent implements OnInit {

  selectedType:number;
  searchBeneficiary: string = '';
  searchPlaceholder: string = '';

  radioButtonOptions: any[] = [];
  garageId: any;
  queryParams: string = '';

  //table

  public tableConfig: TableConfig;
  public pageChanged: boolean = false;
  public selectedRows: Array<string> = [];
  public pagination: ApiPagination = defaultPagination;
  public static readonly currentPage: number = 1;
  public showData = false;
  public beneficiaryTableData: any = [];

  public i18n: Localization;
  public fetchingAPI: boolean = true;
  private readonly configData: any;// = ClaimBeneficiariesTableConfig;

  public type: string = 'Beneficiary';
  public hideAddButton: boolean = true;
  

  /**
   * constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private readonly commonService: CommonService,
    private readonly claimsService: ClaimsService,
    public dialogRef: MatDialogRef<BeneficiaryListDialogComponent>,
    private translate: TranslateService,
    private readonly dialog: MatDialog) {
    this.i18n = this.commonService.geti18nInfo();
    this.garageId = data.garageId || 0;
  }

  /**
   * ng on init
   */
  async ngOnInit(): Promise<void> {
    await this.getBeneficiaryLookup();
    this.initTable();
    this.getData();
  }

  /**
   * initialize table
   */
  initTable(): void {
    //this.configData = 
    this.tableConfig = this.type == 'Partnership' ? ClaimGarageBeneficiariesTableConfig : ClaimBeneficiariesTableConfig;
    this.showData = false;
    this.pagination = {
      ...this.pagination, ...{
        pageSize: 4,
        count: 0,
        currentPage: BeneficiaryListDialogComponent.currentPage,
        orderBy: 'Id',
        sortDirection: 'desc'
      }
    };
  }

  /**
   * get beneficiary lookup 
   */
  async getBeneficiaryLookup(): Promise<void> {
    this.commonService.showLoading();
    return await firstValueFrom(this.claimsService.getBeneficiaryLookup()).then((response: any) => {
      if (response?.data?.length > 0) {
        this.radioButtonOptions = response.data;
      }

      if (this.radioButtonOptions.length > 0) {
        this.selectedType = this.radioButtonOptions[0].id;
        this.searchPlaceholder = this.translate.instant('search ') + this.radioButtonOptions[0].name;
        this.type = this.radioButtonOptions[0].name;
        if (this.radioButtonOptions[0].name == 'Partnership') {
          this.hideAddButton = true;
        } else {
          this.hideAddButton = false;
        }
      }

      this.commonService.hideLoading();
    }).catch((err: any) => {
      this.commonService.hideLoading();
    })
  }

  /**
   * get beneficiary or garage list
   */
  getData(queryParams: any = null): void {

    if (this.queryParams) {
      queryParams = this.queryParams;
    }

    this.commonService.showLoading();
   
    if (this.type == 'Partnership') {
      this.getGarageList(queryParams);
    } else {
      this.claimsService.getBeneficiaryList(this.pagination, queryParams).subscribe({
        next: (response: any) => {
          if (response?.value) {
            this.pagination = {
              ...this.pagination, count: response['@odata.count'] || 0
            }
            this.beneficiaryTableData = response?.value.map((item, ind) => {
              item['Garage'] = item.BeneficiaryName;
              item['Type'] = item.TypeId;
              item['PhoneNumber'] = item.MobileCountryCode + ' ' + item.MobileNumber;
              return item;
            });
          }
          this.commonService.hideLoading();
        }, error: (err: any) => {
          this.commonService.hideLoading();
        }
      })
    }
  }

  /**
   * on search
   * @param $event 
   */
  onSearchSelected($event): void {
    let queryParams = null;
    this.pagination.currentPage = 1;
    if (this.type == 'Partnership') {
      if ($event?.value) {
        queryParams = `$filter=contains(tolower(name), '${$event?.value}') and (id in (${this.garageId}))`
      }
    } else {
      if($event.value) {
        queryParams = `$filter=contains(tolower(beneficiaryName), '${$event?.value}')`
      }
    }
    this.queryParams = queryParams;
    this.getData(queryParams);
  }

  /**
    Handles a cell click event.
    @param {TableRowData} row - The row data.
    @param {Partial} column - The column data.
    @returns {void}
  */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>): void => {
    if(column.id === 'SelectAction') {
      const obj = {
        'beneficiaryDetailGuid': row[1]?.result?.value,
        'beneficiaryName': row[2]?.result?.value || '',
        'beneficiaryId': row[0]?.result?.value,
        'beneficiaryTypeId': this.selectedType
      }
      this.close(obj);
    } else if(column.id === 'DeleteAction') {
      //this.deleteRow(row[0]?.result?.value)
      this.deleteBeneficiaryDetail(row[1]?.result?.value)
      
    } else if(column.id === 'EditAction') {
      this.openBeneficiaryDialog(row[0]?.result?.value, row[1]?.result?.value);
    }
  }

  /**
    Handles pagination change events.
    @param {PaginateInfo} event - The pagination information object.
    @returns {void}
  */
  handlePaginationChange(event: PaginateInfo): void {
    const pagination: ApiPagination = {
      pageSize: event.pageSize,
      offset: event.offset,
      currentPage: event.currentPage
    };
  
    this.pagination.currentPage = event.currentPage;
    this.pagination = { ...this.pagination, ...pagination };
  
    this.pageChanged = true;
    this.getData();
  }

  /**
   * open beneficiary dialog
   */
  openBeneficiaryDialog(id: string, guid: string = ''): void {
    const dialogRef = this.dialog.open(AddBeneficiaryDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '60vw',
      maxHeight: '60vh',
      panelClass: 'beneficiary-panel',
      disableClose: true,
      data: {
        id: id,
        guid: guid
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          this.getData();
        }
      } 
    })
  }

  /**
   * on radio change
   */
  onRadioChange($event): void {    
    const name = this.radioButtonOptions.find(q=> q.id == this.selectedType)?.name;
    this.searchPlaceholder = this.translate.instant('search ') + name;
    this.type = name;
    if (name == 'Partnership') {
      this.hideAddButton = true;
    } else {
      this.hideAddButton = false;
    }
    this.beneficiaryTableData = [];
    this.searchBeneficiary = '';
    this.queryParams = '';
    this.initTable();
    this.getData();
  }

  /**
   * getGarageList
   */
  getGarageList(filter: string = null): void {

    let query = `$count=true&$top=${this.pagination.pageSize}&skip=${(this.pagination.currentPage - 1) * this.pagination.pageSize}`;
    if (!filter && this.garageId) {
      query = query + `&$filter=id eq ${this.garageId}`;
    } else {
      query = query + `&${filter}`;
    }

    this.commonService.showLoading();
    this.claimsService.getGarageList(query, null).subscribe({
      next: (response: any) => {
        if(response) {
          this.pagination.count = response['@odata.count'] || 0;

          this.beneficiaryTableData = response?.value.map((item, ind) => {
            item['Garage'] = item.Name;
            item['PhoneNumber'] = item.CountryCode + ' ' + item.Phone;
            return item;
          });
        }
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }
    }); 
  }

  /**
   * delete beneficiary detail
   */
  deleteBeneficiaryDetail(guid: string): void {
    const dialogRef = this.commonService.openConfirmYesNoDialog(this.translate.instant('delete'), this.translate.instant('delete_beneficiary'));
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService.showLoading();
        this.claimsService.deleteBeneficiaryDetail(guid).subscribe({
          next: (response: any) => {
            if (response) {
              this.commonService.showInfoToast(0, this.translate.instant('beneficiary_deleted'));
              this.commonService.hideLoading();
              this.close(null);
            }
            this.commonService.hideLoading();
          },
          error: (err: any) => {
            this.commonService.hideLoading();
          }
        })
      }
    });
    
  }

  /**
   * close dialog
   */
  close(obj): void {
    this.dialogRef.close(obj);
  }
}

<div class="vehicle-container">
  <div class="title"
       *ngIf="!hideTitle">
    <ng-content></ng-content>
    {{'vehicle_detail' | translate }}
  </div>
  <div class="container">
    <dynamic-form [formControls]="controls"
                  [form]="VehicleFormGroup"></dynamic-form>
    <mat-icon svgIcon="delete"
              (click)="this.deleteVehicle.emit();"
              *ngIf="showDelete"></mat-icon>
  </div>

</div>
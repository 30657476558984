import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, startWith } from 'rxjs';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { claimBeneficiariesDetailsForm } from 'src/app/helper/form/claim-v2-form.helper';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-add-beneficiary-dialog',
  templateUrl: './add-beneficiary-dialog.component.html',
  styleUrls: [ './add-beneficiary-dialog.component.scss' ]
})
export class AddBeneficiaryDialogComponent implements OnInit {

  public beneficiaryId: string = '';
  public beneficiaryGuid: string = '';
  public controls: DynamicFormBase<any>[] | null;
  public beneficiaryFormGroup: FormGroup;
  public countryList: any[] = [];
  public userData: any;

  /**
   * constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private readonly commonService: CommonService,
    private readonly claimsService: ClaimsService,
    public dialogRef: MatDialogRef<AddBeneficiaryDialogComponent>,
    private readonly dynamicFormService: DynamicFormService,
    private translate: TranslateService) {
    this.beneficiaryId = data.id;
    this.beneficiaryGuid = data.guid;
    this.commonService.userProfileData.subscribe((userData) => {
      if (userData?.data) {
        this.userData = userData.data;
      }
    });
  }

  /**
   * on init
   */
  ngOnInit(): void {
    const formBuilderForm = claimBeneficiariesDetailsForm;
    this.getCustomerForm(formBuilderForm);
  }

  /**
   * get customer form
   * @param formBuilderForm 
   */
  async getCustomerForm(formBuilderForm: any): Promise<void> {
    const controls = formBuilderForm.map((it) => { return this.dynamicFormService.controlMapper(it, this.userData) });

    this.controls = this.dynamicFormService.getSortedFormControls(controls);

    this.beneficiaryFormGroup = this.dynamicFormService.createFormGroup(this.controls);

    this.phoneNumberValidation();

    if (this.beneficiaryId && this.beneficiaryId != '0') {
      this.getBeneficiaryDetail();
    }
  }

  /**
   * get beneficiary detail
   */
  getBeneficiaryDetail(): void {
    this.commonService.showLoading();
    this.claimsService.getBeneficiaryDetail(this.beneficiaryId).subscribe({
      next: (response: any) => { 
        if (response?.value?.length > 0) {
          const obj = {
            'beneficiaryName': response?.value[0].BeneficiaryName,
            'typeId': response?.value[0].TypeId,
            'email': response?.value[0].Email,
            'address': response?.value[0].Address,
            'code': response?.value[0].MobileCountryCode,
            'mobileNumber': response?.value[0].MobileNumber
          }

          this.beneficiaryFormGroup.patchValue(obj);
        }
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * phone number validation
   */
  phoneNumberValidation(): void {
    this.beneficiaryFormGroup.get('code')?.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([ prev, next ]: [any, any]) => {
        if (prev !== next && next) {
          const country = this.countryList.find(country => country.phoneCountryCode === next);
          if (country) {
            const mobileNoControl = this.controls.find(control => control.key === 'mobileNumber');
            mobileNoControl.disabled = false;
            const patternValidator = mobileNoControl.validators.find(validator => validator.type === 'pattern');
            patternValidator.regex = country.regExpression;
            this.beneficiaryFormGroup.get('mobileNumber').setValue('');
            this.beneficiaryFormGroup.get('mobileNumber').clearValidators();
            this.beneficiaryFormGroup.get('mobileNumber').setValidators(this.dynamicFormService.createValidators(mobileNoControl));
            this.controls = [ ...this.controls ];
          }
        }
      });
  }

  /**
   * save beneficiary
   */
  saveBeneficiary(): void {

    this.validateAllFields();

    if (!this.beneficiaryFormGroup.valid) {
      this.commonService.showToast(0, this.translate.instant('fill_required_fields'));
      return;
    }

    let obj: any = {
      'BeneficiaryName': this.beneficiaryFormGroup.get('beneficiaryName').value,
      'TypeId': this.beneficiaryFormGroup.get('typeId').value,
      'Email': this.beneficiaryFormGroup.get('email').value,
      'Address': this.beneficiaryFormGroup.get('address').value,
      'MobileCountryCode': this.beneficiaryFormGroup.get('code').value,
      'MobileNumber': this.beneficiaryFormGroup.get('mobileNumber').value
    }

    if (this.beneficiaryId && this.beneficiaryId != '0') {
      obj = {
        ...obj, 'BeneficiaryDetailGuid': this.beneficiaryGuid
      }
    }
    this.commonService.showLoading();
    let promise;
    if (this.beneficiaryId && this.beneficiaryId != '0') {
      promise = this.claimsService.updateBeneficiary(obj)
    } else {
      promise = this.claimsService.addBeneficiary(obj)
    }

    promise.subscribe({
      next: (response: any) => {
        if (response) {
          this.close(obj);
        }
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
  * validate all fields
  */
  validateAllFields(): void {
    Object.keys(this.beneficiaryFormGroup.controls).forEach((controlName) => {
      const control = this.beneficiaryFormGroup.get(controlName);
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false }); // Re-run validation rules
      }
    });
  }
  
  /**
   * close dialog
   */
  close(obj): void {
    this.dialogRef.close(obj);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, of } from 'rxjs';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { CommonService } from 'src/app/services/common/common.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: [ './onboarding.component.scss' ]
})
export class OnboardingComponent implements OnInit{
  @ViewChild('file') file: ElementRef;
  formSubmitted: boolean = false;
  hideNotification: boolean = false;
  currentSelection: string = '';
  onboardingForm: FormGroup;
  designationList: Array<any> = [];
  codeList: Array<any> = [];
  countryList: Array<any> = [];
  cityList: Array<any> = [];
  regionList: Array<any> = [];
  productList: Array<any> = [];
  serviceList: Array<any> = [];
  allServiceList: Array<any> = [];
  designationConfig: XADropdownConfig = {
    name: 'designation',
    label: 'designation',
    labelKey: 'name',
    valueKey: 'id',
    showRequiredLabel: true
  }
  codeConfig: XADropdownConfig = {
    name: 'code',
    labelKey: 'phoneCountryCode',
    valueKey: 'phoneCountryCode',
    showHeaderIcon: true,
    showRequiredLabel: true
  }
  countryConfig: XADropdownConfig = {
    name: 'country',
    label: 'country',
    labelKey: 'name',
    valueKey: 'id',
    showHeaderIcon: false,
    showRequiredLabel: true
  }
  cityConfig: XADropdownConfig = {
    name: 'city',
    label: 'city',
    labelKey: 'name',
    valueKey: 'id',
    showRequiredLabel: true
  }
  regionConfig: XADropdownConfig = {
    name: 'region',
    label: 'area_region',
    labelKey: 'name',
    valueKey: 'id',
    showRequiredLabel: true
  }
  productConfig: XADropdownConfig = {
    name: 'product',
    label: 'product_interested',
    labelKey: 'name',
    valueKey: 'id',
    showRequiredLabel: true
  }
  serviceConfig: XADropdownConfig = {
    name: 'services',
    label: 'services',
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'id',
    multiple: true,
    showRequiredLabel: true
  }
  documents: Array<any> = [
    {
      name: 'trade_license',
      image: '',
      imageFile: '',
      imageFileName: ''
    },
    {
      name: 'registration',
      image: '',
      imageFile: '',
      imageFileName: ''
    },
    {
      name: 'government_id',
      image: '',
      imageFile: '',
      imageFileName: ''
    }
  ]

  /**
   * constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private onboardingService: OnboardingService,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.onboardingForm = this.formBuilder.group({
      businessType: [ 'Garage', Validators.required ],
      businessName: [ '', Validators.required ],
      designation: [ '', Validators.required ],
      phone: [ '', Validators.required ],
      code: [ '', Validators.required ],
      email: [ '', [ Validators.required, Validators.email ] ],
      country: [ '', Validators.required ],
      city: [ '', Validators.required ],
      region: [ '', Validators.required ],
      productInterested: [ '', Validators.required ],
      services: [ '', Validators.required ],
      recaptchaKey: [ '', Validators.required ]
    });
    this.onboardingForm.get('country')?.valueChanges.subscribe((val)=>{
      this.onboardingForm.get('region').setValue(null);
      this.onboardingForm.get('city').setValue(null);
      this.getRegion(val);
    });
    this.onboardingForm.get('region')?.valueChanges.subscribe((val)=>{
      if(val) {
        this.onboardingForm.get('city').setValue(null);
        this.getCity(val);
      }
    });
    this.onboardingForm.get('code')?.valueChanges.subscribe((val)=>{
      const selectedCode = this.countryList.find(x => x.phoneCountryCode === val);
      this.onboardingForm.get('phone').setValue(null);
      this.onboardingForm.get('phone').setValidators(Validators.pattern(selectedCode.regExpression));
      this.onboardingForm.get('phone').updateValueAndValidity();
    });

    this.onboardingForm.get('productInterested').valueChanges.subscribe((val) => {
      if (val === 1) {
        this.onboardingForm.get('services').setValue(null);
        this.onboardingForm.get('services').setValidators(Validators.required);
      } else {
        const repair = this.allServiceList.find(x => x.name === 'Addenda Repair');
        this.onboardingForm.get('services').setValue([ repair.id ])
        this.onboardingForm.get('services').removeValidators(Validators.required);
      }
      this.onboardingForm.get('services').updateValueAndValidity();
    })

    this.getData();
  }

  /**
   * get required look up
   */
  getData(): void {
    this.commonService.showLoading();
    const result = forkJoin({
      country: this.onboardingService.getCountryList(),
      designation: this.onboardingService.getDesignationList(),
      product: this.onboardingService.getProductList(),
      service: this.onboardingService.getServiceList()
    }).pipe(
      catchError(error => of(error))
    )
    result.subscribe((dict: any) => {
      this.commonService.hideLoading();
      if(dict) {
        this.countryList = dict.country;
        this.designationList = dict.designation?.data;
        this.productList = dict.product?.data;
        this.allServiceList = dict.service?.data;
        this.serviceList = this.allServiceList.filter(x => x.serviceCategory === 'B2B2C');
      }
    })
  }

  /**
   * handleUpload
   */
  handleUpload(event:any):void {
    const currentFile = event.target.files || event.srcElement.files || event.dataTransfer.files;
    if(currentFile.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(currentFile[0]);
    reader.onload = (): void => {
      const document = this.documents.find(x => x.name === this.currentSelection);
      document.imageFile = currentFile[0];
      document.image = reader.result;
      document.imageFileName = currentFile[0].name;
      this.resetUploader();
    }

    reader.onerror = ():void =>{
      this.resetUploader();
    }
  }

  /**
   * trigger null
   */
  resetUploader(): void {
    setTimeout(() => {
      this.file.nativeElement.value = null;
    });
  }

  /**
   * delete document
   * @param document 
   */
  deleteDocument(document): void {
    document.image = '';
    document.imageFile = '';
    document.imageFileName = '';
  }

  /**
   * Preview document
   * @param document 
   */
  previewDocument(document): void {
    this.dialog.open(ImgDialogComponent, {
      data: {
        state: '',
        title: this.translateService.instant(document.name),
        image: document.image
      }, 
      autoFocus: false
    });
  }

  /**
   * submit form
   */
  submit(): void {
    const phone = `${this.onboardingForm.get('code').value} ${this.onboardingForm.get('phone').value}`
    const country = this.countryList.find(x => x.id === this.onboardingForm.get('country').value);
    const region = this.regionList.find(x => x.id === this.onboardingForm.get('region').value);
    const city = this.cityList.find(x => x.id === this.onboardingForm.get('city').value);
    const formData = new FormData();
    formData.append('BusinessType', this.onboardingForm.get('businessType').value);
    formData.append('BusinessName', this.onboardingForm.get('businessName').value);
    formData.append('DesignationType', this.onboardingForm.get('designation').value);
    formData.append('Phone', phone);
    formData.append('Email', this.onboardingForm.get('email').value);
    formData.append('Country', country?.name);
    formData.append('Region', region?.name);
    formData.append('CityID', this.onboardingForm.get('city').value);
    formData.append('City', city?.name);
    formData.append('ProductInterestedId', this.onboardingForm.get('productInterested').value);
    formData.append('AutoMotiveServicesIds', this.onboardingForm.get('services').value?.join());
    formData.append('Status', 'Pending');
    formData.append('TradeLicenseDoc', this.documents.find(x => x.name === 'trade_license').imageFile);
    formData.append('RegistrationDoc', this.documents.find(x => x.name === 'registration').imageFile);
    formData.append('GovtIdDoc', this.documents.find(x => x.name === 'government_id').imageFile);
    this.commonService.showLoading();
    this.onboardingService.selfRegistration(formData).subscribe({
      next: () => {
        this.commonService.hideLoading();
        this.formSubmitted = true
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * get region
   * @param id 
   */
  getRegion(id): void {
    this.commonService.showLoading();
    this.onboardingService.getRegionList(id).subscribe({
      next: (response) => {
        this.regionList = response;
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * get city
   * @param id 
   */
  getCity(id): void {
    this.commonService.showLoading();
    this.onboardingService.getCityList(id).subscribe({
      next: (response) => {
        this.cityList = response;
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }
}

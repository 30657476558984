import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { CameraOverlayComponent } from '../camera-overlay/camera-overlay.component';
import { CommonService } from 'src/app/services/common/common.service';
import {
  Platform
} from '@angular/cdk/platform';
import { cloneDeep } from 'lodash';
import { ProgressCheckComponent } from '../progress-check/progress-check.component';
import { XaTimerComponent } from '../xa-timer/xa-timer.component';
@Component({
  selector: 'xa-video',
  templateUrl: './video.component.html',
  styleUrls: [ './video.component.scss' ]
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('overlayElement') overlayElement: CameraOverlayComponent;
  @ViewChild('progress') progress:ProgressCheckComponent;
  @ViewChild('xaTimer') xaTimer:XaTimerComponent;
  @Input() autoStart:boolean = false;
  @Input() outlineUrl:string = '';
  @Input() procedureStep:string = '';
  @Input() displaySkip:boolean = false;
  @Input() currentStep:string='';
  @Input() forceOrientation:boolean = false;
  @Input() showTiltText:boolean = false;
  @Input() steps:any[] = [];
  @ViewChild('div') div:HTMLDivElement;
  @Output() videoRecorded = new EventEmitter<any>();
  @Output() processingVideo = new EventEmitter<any>();
  recordedBlobs = [];
  
  private mediaRecorder:MediaRecorder | void= null;
  private videoCaptureTimeStamp = {
    'plateNo': '',
    'odoMeter': '',
    'vinNumber': '',
    'fullVehicle': ''
  }
  public activeIndex = 0;
  public image:string;
  public isLandscapeModeActive:boolean = false;
  public landscape:MediaQueryList = window.matchMedia('(orientation: landscape)');
  public processing:boolean = false;
  public showCamera:boolean = false;
  public isRecording:boolean = false;
  public videoSteps:any[] = [];
  platform = inject(Platform);


  /**
   * constructor
   * @param cameraService 
   */
  constructor(private cameraService: CameraService, private commonService:CommonService
  ) {
    this.cameraService.videoObjectObservable.subscribe({
      next: (event:any)=>{
        if(!event){
          return;
        }
        if (event.data && event.data.size > 0 && event.action ==='blob') {
          this.recordedBlobs.push(event.data);
        }else if(event.action ==='upload'){
          this.emitUpload(event.data);
        }else if(event.action === 'converting'){
          this.processingVideo.emit();
        }
       
      }
    })
  }


  /**
   * ng oninit
   */
  ngOnInit(): void {
    this.isLandscapeModeActive = this.landscape.matches;
    this.videoSteps = cloneDeep(this.steps);
    this.landscape.addEventListener('change', (ev) => {
      this.isLandscapeModeActive = ev.matches;
      if(this.isLandscapeModeActive && this.showCamera){
        this.startCamera();
      }
    });
    if(this.autoStart){
      this.startCamera();
    } 
  }

  /**
   * get current text from videoSteps array based on activeIndex
   * 
   * */
  get currentText():string{
    if(!this.videoSteps){
      return '';
    }
    return this.videoSteps[this.activeIndex]?.text;
  }


  /**
   * get orientation
   */
  get getOrientationLock():boolean{
    //|| this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape'
    return this.forceOrientation ;
  }

  /**
   * start camera
   * 
   */
  async startCamera():Promise<any>{
    if(!this.isLandscapeModeActive){
      this.showCamera = true;
      return;
    }
    this.activeIndex = 0;
    this.recordedBlobs = [];
    this.mediaRecorder = await this.cameraService.initVideo(this.videoElement.nativeElement, this.platform.ANDROID || this.platform.IOS).catch((e) => {
      this.commonService.showToast(0, `Error initializing camera: ${e.message}`);
      this.closeCamera();
    });
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }
    this.showCamera = true;
  }

  /**
   * close camera
   */
  closeCamera():void{
    this.image = null;
    this.showCamera = false;
    this.activeIndex = 0;
    this.videoSteps = cloneDeep(this.steps);
    this.videoCaptureTimeStamp = {
      'plateNo': '',
      'odoMeter': '',
      'vinNumber': '',
      'fullVehicle': ''
    }
    this.isRecording = false;
    if(this.mediaRecorder){
      this.cameraService.stopRecording(this.mediaRecorder, this.recordedBlobs);
    }
  }

  /**
   * start recording
   */
  startRecording():void{
    if(this.mediaRecorder){
      this.cameraService.startRecording(this.mediaRecorder);
      this.isRecording = true;
    }
  }

  /**
   * start recording
   */
  stopRecording():void{
    if(this.mediaRecorder){
      let rotate = false;
      if(window.innerHeight > window.innerWidth){
        rotate = true;
      }
      this.videoCaptureTimeStamp.fullVehicle = this.xaTimer?.displayTime;
      this.cameraService.stopRecording(this.mediaRecorder, this.recordedBlobs);
      this.isRecording = false;
      this.activeIndex = 0;
      this.image = null;
      this.showCamera = false;
    }
  }
  
  /**
   * emit upload
   */
  emitUpload(blob:Blob):void{
    this.videoRecorded.emit({ target: { files: [ blob ] }, timestamps: this.videoCaptureTimeStamp });
    this.showCamera = false;
    this.image = null;
    this.videoSteps = cloneDeep(this.steps);
  }

  /***
   * update skip
   */
  emitDone():void{
    this.videoSteps[this.activeIndex].isCompleted = true;
    if(this.videoSteps[this.activeIndex].title === 'ODOMETER'){
      this.videoCaptureTimeStamp.odoMeter = this.xaTimer?.displayTime;
    }else if(this.videoSteps[this.activeIndex].title === 'VIN Number'){
      this.videoCaptureTimeStamp.vinNumber = this.xaTimer?.displayTime;
    } else if(this.videoSteps[this.activeIndex].title === 'Plate Number'){
      this.videoCaptureTimeStamp.plateNo = this.xaTimer?.displayTime;
    }else{
      this.videoCaptureTimeStamp.fullVehicle = this.xaTimer?.displayTime;
    }
    if(this.progress && this.progress.track?.length > 0){
      this.progress.track[this.activeIndex].isCompleted = true;
    }
    if(this.activeIndex === (this.videoSteps?.length -1)){
      this.stopRecording();
    }else{
      this.activeIndex++;
    }
    
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.cameraService.stopCamera();
  }
}

<div class="dialog-container-select-claimant">
    <div class="header">
        <h3>{{ 'change_assignee' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="input-wrapper"
             [formGroup]="formGroup">
            <xa-radio-group formControlName="assignee"
                            name="assignee"
                            class="radio-group-display-flex">
                <xa-radio [value]="item.value"
                          *ngFor="let item of list">{{ item.label | translate}}</xa-radio>
            </xa-radio-group>

        </div>
    </div>
    <div class="btn-container">
        <ng-container>
            <xa-button color="gray"
                       type="outline"
                       size="sm"
                       class="right"
                       (click)="cancel();">
                {{'cancel' |translate}}
            </xa-button>
            <xa-button size="sm"
                       class="right"
                       [disabled]="!selectedAssignee"
                       (click)="assigneeChange()"
            >
                {{'assign' |translate}}
            </xa-button>
        </ng-container>
    </div>
</div>
<div class="estimate-add-operation fx-col h-100">
    <form class="fx-1">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <chq-input #operationTypeControl
                               class="col-12"
                               [inputModel]="formModel.operationType"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <chq-input #operationControl
                               class="col-12"
                               [inputModel]="formModel.operation"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <chq-input #codeControl
                           [inputModel]="formModel.code"
                           (clicked)="hideDropdown($event);"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="col-6">
                <div class="row"
                     *ngIf="formData.operationType === OPERATION_TYPE.replace || formData.operationType === OPERATION_TYPE.painting">
                    <chq-input #quantityControl
                               class="col-6"
                               [inputModel]="formModel.quantity"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                    <chq-input #priceControl
                               class="col-6"
                               [inputModel]="formModel.price"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
                <div class="row"
                     *ngIf="formData.operationType === OPERATION_TYPE.repair || formData.operationType === OPERATION_TYPE.removeAndRefit || formData.operationType === OPERATION_TYPE.polish || formData.operationType === OPERATION_TYPE.checkFields || formData.operationType === OPERATION_TYPE.performFields">
                    <chq-input #labourAmountControl
                               class="col-6"
                               [inputModel]="formModel.labourAmount"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                    <chq-input #labourTimeControl
                               class="col-6"
                               [inputModel]="formModel.labourTime"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 repair-type">
                <chq-input #repairTypeControl
                           [inputModel]="formModel.repairType"
                           (clicked)="hideDropdown($event);"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="col-6">
                <div class="row"
                     *ngIf="formData.operationType === OPERATION_TYPE.replace || formData.operationType === OPERATION_TYPE.painting">
                    <chq-input #labourAmountControl
                               class="col-6"
                               [inputModel]="formModel.labourAmount"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                    <chq-input #labourTimeControl
                               class="col-6"
                               [inputModel]="formModel.labourTime"
                               (clicked)="hideDropdown($event);"
                               (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <chq-input #informationControl
                           [inputModel]="formModel.information"
                           (clicked)="hideDropdown($event);"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div class="col-6">
                <div class="information"
                     *ngIf="formData.operationType === OPERATION_TYPE.replace">
                    <div class="icon-wrapper"><mat-icon [svgIcon]="'information-operation'"
                                  class="cross"></mat-icon></div>
                    <span>{{"operation_replace_information_popup" | translate}}</span>
                </div>
            </div>
        </div>
        <div class="paint-section"
             *ngIf="(formData.operationType === OPERATION_TYPE.replace || formData.operationType === OPERATION_TYPE.repair || formData.operationType === OPERATION_TYPE.removeAndRefit) && !operationToEdit?.OperationGuid">
            <div class="d-flex">
                <div>{{ 'add_paint' | translate }}</div>
                <div class="ml-auto">
                    <chq-toggle [checked]="isAddPaintSelected"
                                (ToggleChange)="onToggleChange($event)"
                                name="isAddPaintSelected"></chq-toggle>
                </div>
            </div>
            <div class="row"
                 *ngIf="isAddPaintSelected">
                <div class="col-6">
                    <div class="row">
                        <chq-input #codeControl
                                   class="col-12"
                                   [inputModel]="formModel.paintCode"
                                   (clicked)="hideDropdown($event);"
                                   (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <chq-input #codeControl
                                   class="col-12"
                                   [inputModel]="formModel.paintLabourTime"
                                   (clicked)="hideDropdown($event);"
                                   (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
            </div>
            <div class="row"
                 *ngIf="isAddPaintSelected">
                <div class="col-6">
                    <div class="row">
                        <chq-input #codeControl
                                   class="col-12"
                                   [inputModel]="formModel.paintLabourAmount"
                                   (clicked)="hideDropdown($event);"
                                   (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <chq-input #codeControl
                                   class="col-12"
                                   [inputModel]="formModel.paintMaterialCharge"
                                   (clicked)="hideDropdown($event);"
                                   (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="fx-0 justify-space-between d-flex">
        <div class="col-6 save-operation">
            <chq-input *ngIf="!(operationToEdit?.Id)"
                       [inputModel]="formModel.saveOperation"
                       (controlOutput)="onFormUpdate($event)"></chq-input>
        </div>
        <div class="col-6">
            <div class="footer-button">
                <chq-button [buttonModel]="resetButtonModel"
                            class="reset-button"></chq-button>
                <chq-button [buttonModel]="addButtonModel"></chq-button>
            </div>
        </div>
    </div>
</div>
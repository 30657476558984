<div class="message-container">
    <div class="header">
        <h3>{{data.title | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'"
                      (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="content">
        <xa-text tabindex="-1"
                 [label]="data.label"
                 [placeholder]="data.placeholder"
                 [maxlength]="data.maxLength"
                 [name]="data.label"
                 [(ngModel)]="inputText"
                 [required]="true"
                 #name="ngModel"></xa-text>
        <div *ngIf="name.invalid && (name.dirty || name.touched)"
             class="alert alert-validation">
            <div *ngIf="name.hasError('maxlength')">
                {{'max_characters'| translate: { character: data.maxLength } }}
            </div>
            <div *ngIf="name.hasError('required')">
                {{'required_name'| translate: { name: data.label | translate } }}
            </div>
        </div>
    </div>
    <div class="footer form-data-footer">
        <xa-button class="right"
                   (click)="add();"
                   [disabled]="!inputText || inputText?.length > 50">
            <mat-icon svgIcon="plus-outline"></mat-icon>
            {{'add' | translate}}
        </xa-button>
    </div>
</div>
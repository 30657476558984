<div class="policy-authentication">
    <div class="header">
        <h3>{{'authenticate_by_entering_any_one_the_detail_below' | translate }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="">
        <dynamic-form [formControls]="controls" [form]="submitFormGroup"></dynamic-form>
    </div>
    <div class="footer form-data-footer">
        <xa-button type="outline" color="gray" (click)="close();">{{'cancel' | translate }}</xa-button>
        <xa-button (click)="searchPolicy()">{{'search' | translate }}</xa-button>
    </div>
</div>
<div class="form-field-text">
  <label *ngIf="label"
         class="form-label pointer-events"
         for="inputId">
    {{label | translate}}
    <span class="required"
          *ngIf="required && label">*</span>
    <mat-icon *ngIf="hint"
              [svgIcon]="'hint'"
              style="width:12px;height:12px;margin-left:5px;"
              [matTooltip]="hint">

    </mat-icon>
  </label>
  <div class="color-picker-input"
       [ngClass]="{'disabled-color': disabled}">
    <div *ngIf="!value">
      <mat-icon class="colorpicker-icon"
                #ignoredButton
                (click)="toggle=!toggle"
                [svgIcon]="'colorpicker'"></mat-icon>
      <input #ignoredInput
             class="remove-background-color color-input"
             [cpIgnoredElements]="[ignoredButton, ignoredInput]"
             [(cpToggle)]="toggle"
             [(colorPicker)]="defaultValue"
             [cpOKButton]="true"
             [cpOKButtonText]="'Save'"
             [id]="inputId"
             [name]="nameId"
             [readonly]="readonly"
             [disabled]="disabled"
             [required]="required"
             [cpOKButtonClass]="okBtnClass || 'okBtnClass'"
             [cpSaveClickOutside]="false"
             (colorPickerClose)="value=$event;_onInputInteraction(value);onTouched()"
             [cpOutputFormat]="'hex'"
             (colorPickerSelect)="value=$event;_onInputInteraction(value);onTouched()" />
    </div>
    <input *ngIf="value"
           [style.background]="value"
           color="color-input"
           [(colorPicker)]="value"
           [cpOKButton]="true"
           [cpOKButtonText]="'Save'"
           [id]="inputId"
           class="color-picker-input"
           [name]="nameId"
           [readonly]="readonly"
           [disabled]="disabled"
           [required]="required"
           [cpOKButtonClass]="okBtnClass || 'okBtnClass'"
           [cpSaveClickOutside]="false"
           [cpDisableInput]="false"
           (colorPickerClose)="_onInputInteraction(value);onTouched();"
           [cpOutputFormat]="'hex'" />
    <span *ngIf="disabled && value">
      {{value}}
    </span>
    <ng-content></ng-content>
  </div>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  repairTypes = [];
  /**
   * constructor
   * @param http
   */
  constructor(
    private readonly http: HttpClient,
    private readonly commonService: CommonService
  ) {
  }

  /**
 * get booking types
 * @returns Observable
 */
  public getBookingRepairTypes(query): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/booking-repair-type${query}`;
    return this.http.get(url);
  }

  /**
   * This is old api replace this with getBookingRepairTypes
   * get booking types
   * @returns Observable
   */
  public getBookingTypes(): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/lookup?type=BookingType`;
    return this.http.get(url);
  }

  /**
   * get repair types
   * @returns Observable
   */
  public checkPlateNumber(plateNumber: string, repairGuid: string | null): Observable<any> {
    const url = repairGuid ? `${environment.caseMgmtUrl}/api/repair/by-plate-number/${plateNumber}/${repairGuid}` : `${environment.caseMgmtUrl}/api/repair/by-plate-number/${plateNumber}`;
    return this.http.get(url);
  }
  /**
   * This is old api replace this with getBookingRepairTypes
   * get repair types
   * @returns Observable
   */
  public getRepairTypes(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=RepairServiceType`;
    return this.http.get(url);
  }

  /**
   * get repair types
   * @returns Observable
   */
  public getRepairStatuses(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=repairstatus`;
    return this.http.get(url);
  }

  /**
   * get booking detail
   * @returns Observable
   */
  public getBookingByGuid(guid: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/booking/${guid}`;
    return this.http.get(url);
  }

  /**
   * get repair detail
  */
  public getRepairDetailByGuid(guid: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${guid}`;
    // Set Domain ID when api is called
    return this.http.get(url).pipe(tap((el) => {
      this.commonService.domainId.next(el?.data?.domainId);
    }));
  }

  /**
   * add repair booking
   */
  createRepair(bookingData: object): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair`;
    return this.http.post(url, bookingData);
  }

  /**
  * Update repair
  * @param updateData
  * @param repairId
  * @returns
  */
  updateRepair(updateData: any, repairId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairId}`;
    return this.http.put(url, updateData);
  }

  /**
  * Patch repair
  * @param updateData
  * @param repairId
  * @returns
  */
  patchRepair(patchData: any, repairId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairId}`;
    return this.http.patch(url, patchData);
  }

  /**
   * Update repair status
   * @param statusId
   * @param repairid
   * @returns
   */
  updateRepairStatus(status: string, repairId: string, reason: string): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/repair/${repairId}/status`;
    return this.http.put(url, {
      status,
      reason
    }, { headers });
  }

  /**
   * get repair tasks
   */
  getRepairTask(repairId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repairstages/${repairId}/repairjobs`;
    return this.http.get(url);
  }

  /**
   * update repair task nodes
   */
  updateRepairTaskNotes(repairId: string, notes: string, repairTaskGuid: string, repairJobGuid): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repairstages/repairjob/${repairId}/${repairJobGuid}/tasknote`;
    const body = {
      repairTaskGuid,
      notes
    }
    return this.http.put(url, body);
  }

  /**
   * update repair timer status
   */
  updateRepairTimerStatus(payload: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repairstages/repairjobtimer`;
    return this.http.post(url, payload);
  }

  /**
   * update repair timer status
   */
  updateRepairTaskStatus(repairTaskGuid: string, repairId: string, status: string, repairJobGuid, differentTechnicianGuid): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repairstages/repairjob/${repairId}/${repairJobGuid}/taskstatus`;
    const body: any = [ {
      'repairTaskGuid': repairTaskGuid,
      status
    } ];

    if (differentTechnicianGuid) {
      body[0].assigneeGuid = differentTechnicianGuid;
    }
    return this.http.put(url, body);
  }

  /**
   * Get employeess by competencies
   * @param competencies
   * @param startDate
   * @param endData
   */
  getEmployeesByCompetencies(repairServiceTypeIds: number[] = null, startDate: string, endDate: string, orgId?: number, updateBookingGuid?: string): Observable<any> {
    return of(undefined);
    const url = `${environment.bookingMgmtUrl}/api/employee/get-by-criteria`;
    const data = repairServiceTypeIds == null ? {
      startDate,
      endDate,
      orgId,
      updateBookingGuid
    } : {
      repairServiceTypeIds,
      startDate,
      endDate,
      orgId,
      updateBookingGuid
    }
    return this.http.post(url, data);
  }

  /**
   * update Quote status externally
   * @param status
   * @param repairId
   * @param reason
   * @param token
   * @returns
   */
  updateQuoteStatusExternally(status: string, repairId: string, reason: string, token: string, domainId: number): Observable<any> {
    const localTime = new Date().toString();
    const url = `${environment.caseMgmtUrl}/api/external/case/${repairId}/status`;
    const headers = new HttpHeaders().append('token', decodeURIComponent(token)).append('localtime', localTime)
    return this.http.put(url, {
      status,
      reason,
      domainId
    }, {
      headers
    });
  }
  /**
   * update repair status externally
   * @param status
   * @param repairId
   * @param reason
   * @param token
   * @returns
   */
  updateRepairStatusExternally(status: string, repairId: string, reason: string, token: string): Observable<any> {
    const localTime = new Date().toString();
    const url = `${environment.caseMgmtUrl}/api/external/${repairId}/status`;
    const headers = new HttpHeaders().append('token', decodeURIComponent(token)).append('localtime', localTime)
    return this.http.put(url, {
      status,
      reason
    }, {
      headers
    });
  }

  /**
 * get booking types
 * @returns Observable
 */
  public getCalendarSetting(): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/settings/calendar`;
    return this.http.get(url);
  }


  /**
   * update calendar setting
   * @param data
   * @returns
   */
  updateCalendarSetting(data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/settings/calendar`;
    return this.http.put(url, data);
  }

  /**
   * get repair detail
  */
  public getRepairBooking(guid: string): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/booking/by-object/${guid}`;
    return this.http.get(url);
  }

  /**
   *
   * @param data
   * @returns
   */
  createBooking(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/booking`;
    return this.http.post(url, data);
  }

  /**
   *
   * @param data
   * @returns
   */
  updateBooking(bookingGuid: any, data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/booking/${bookingGuid}`;
    return this.http.patch(url, data);
  }

  /**
   *
   * @param bookingGuid
   * @returns
   */
  public deleteBooking(bookingGuid: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/booking/${bookingGuid}`;
    return this.http.delete(url);
  }

  /**
   * createDeliveryServiceDetails
   * @param {*} data
   * @returns {Observable<any>}
   */
  createDeliveryServiceDetails(data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/deliverydetail`;
    return this.http.post(url, data);
  }

  /**
   * updateClaimRepairNotificationStatus
   * @param {*} bookingGuid
   * @param {string} status
   * @returns {Observable<any>}
   */
  updateClaimRepairNotificationStatus(notificationGuid: string, bookingGuid: string, status: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationRepair/claimrepairnotification/${bookingGuid}/${notificationGuid}/${status}`;
    return this.http.put(url, {});
  }

  /**
   * get no show reasons
   * @returns Observable
   */
  public getNoShowReason(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=RepairNoShowReason`;
    return this.http.get(url);
  }


  /**
   * getRepairNotes
   * @returns {Observable<any>}
   */
  getRepairNotes(repairGuid): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairGuid}/notes`;
    return this.http.get(url);
  }

  /**
   * createRepairNotes
   * @returns {Observable<any>}
   */
  createRepairNotes(repairGuid, data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/${repairGuid}/notes`;
    return this.http.post(url, data);
  }

  /**
   * get survey pdf
   */
  getFeaturePackage(orgId): Observable<any> {
    const url = `${environment.autoCoreApi}/api/FeaturePackage/${orgId}`;
    return this.http.get(url);
  }

  /**
   * editEstimateAddRevision - Repairer View
   *
   */
  editEstimateAddRevision(repairId: any, paramObj: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/${repairId}/estimate-revision`;
    return this.http.post(url, paramObj);
  }

  /**
   * sendWhatsappAskForRevisionNotification
   *
   */
  sendWhatsappAskForRevisionNotification(payload): Observable<any> {
    const url = `${environment.communicationApi}/api/message`;
    return this.http.post(url, payload);
  }

  /**
   * sendEmailAskForRevisionNotification
   *
   */
  sendEmailAskForRevisionNotification(payload, guid: string = ''): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/message/${guid}/send-message`;
    return this.http.post(url, payload);
  }

  /**
   * getEstimateDetails
   */
  getLatestEstimateDetails(repairId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/${repairId}/estimate-details`;
    return this.http.get(url);
  }

  /**
   * get operation types
   * @returns 
   */
  public getOperationTypes(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/repair-service-type`;
    return this.http.get(url);
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckPartsAvailabilityTableConfig } from 'src/app/config/display/check-parts-availability-table-config';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CellClickedCallback, TableConfig, TableRowData, XaTable } from 'src/app/widgets/xa-table/xa-table.component';

@Component({
  selector: 'app-check-parts-availability-popup',
  templateUrl: './check-parts-availability-popup.component.html',
  styleUrls: [ './check-parts-availability-popup.component.scss' ]
})
export class CheckPartsAvailabilityPopupComponent implements OnInit {
  public tableConfig: TableConfig;
  public tableDataRow: any = [];
  public static currentPage: number = 1;
  private configData: any = CheckPartsAvailabilityTableConfig;
  public i18n: Localization;
  private selectedParts: any = [];
  isLoading: boolean = true;
  @ViewChildren('tableXA') tableXA!: QueryList<XaTable>;

  public dropDownModel: any = {
    placeHolder: '0',
    label: '',
    name: '',
    value: '',
    type: 'select',
    displayValue: 'label',
    fieldValue: 'value',
    disabled: true
  };

  public selectedAvailableParts: any[] = [];

  treeData: any = [];
  selectedQuantities: any [] = [];

  /**
     * constructor
     * @param dialogRef 
     * @param data 
     * @param changeDetectionRef 
     */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly dialogRef: MatDialogRef<CheckPartsAvailabilityPopupComponent>,
    private readonly monitorService: MonitorService,
    public readonly commonService: CommonService,
    private readonly inventoryService: InventoryService,
    private readonly cdk: ChangeDetectorRef,
    private readonly router: Router
  ) {
    this.i18n = this.commonService.geti18nInfo();
    this.selectedParts = data.selectedParts;
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.tableConfig = this.configData;
    this.checkAvailabilities();
  }

  /**
   * check availabilities 
   */
  checkAvailabilities(): void {
    this.commonService.showLoading();
    const payload = [];
    this.selectedParts.forEach((selectedPart: any) => {
      payload.push({
        'itemName': selectedPart.description || '',
        'itemCode': selectedPart.code || '',
        'oeCode': selectedPart.code || '',
        'amCode': selectedPart.code || '',
        'partGuid': selectedPart.partGuidId
      })
    });
    this.inventoryService.checkPartsAvailability({ 'checkavailability': payload }).subscribe({
      next: (response: any) => {
        
        if (response?.data?.length > 0) {

          response.data.forEach((part: any, index: number) => {
            const parentPart = this.selectedParts.find(q => q.partGuidId == part.partGuid);

            this.tableDataRow[index] = part.partDetailsList?.map((ele) => {

              const quantity = ele.quantity >= parentPart?.quantity ? parentPart?.quantity : ele.quantity;

              this.dropDownModel = {
                ...this.dropDownModel,
                options: Array.from({ length: quantity }, (_, i) => ({
                  label: (i + 1),
                  value: i + 1
                })),
                selectedOption: { label: quantity, value: quantity },
                editHide: false,
                value: ele.quantity,
                showDropdown: true
              }

              ele['itemCode'] = `<a class="code-style" href="${this.navigateToInventory(ele.inventoryItemGuid)}">${ele.itemCode}</a>`;
              ele['condition'] = `<span class=${ele.condition?.toLowerCase()?.split(' ')?.join('-')}>${ele.condition}</span>`;
              ele['dropDownModel'] = this.dropDownModel;
              ele['partGuid'] = part.partGuid;
              return ele;
            });
            this.tableDataRow[index].partGuid = part.partGuid;
            //const parentPart = this.selectedParts.find(q => q.partGuidId == part.partGuid);

            this.treeData.push({
              label: part.partName,
              id: part.partGuid,
              code: parentPart?.code || '',
              cost: parentPart?.cost || '',
              total: parentPart?.total || '',
              quantity: parentPart?.quantity || 0,
              expanded: true,
              children: [
              ]
            })
          });
        }
        this.isLoading = false;
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        console.log(err);
        this.isLoading = false;
        this.commonService.hideLoading();
      }
    })
  }


  /**
   * navigate to inventory details
   */
  navigateToInventory(inventoryGuid: string): string {
    return `/inventory/detail/${inventoryGuid}`
  }

  /**
   * on dropdown select
   */
  onDropdownSelected = (event: any, row: TableRowData): void => {
    const id = row[1].result.value;
    const partGuid = row[14].result.value;
    let quantity = event?.value?.value || 0;
    const alreadySelectedPart = this.selectedAvailableParts?.find(q => q.inventoryItemId == id && q.partGuid == partGuid);

    let isExceeded: boolean = false;

    if (alreadySelectedPart) {
      //alreadySelectedPart.requestedQty = quantity;

      const parentPart = this.selectedParts.find(q => q.partGuidId == alreadySelectedPart.partGuid);

      const indexTableDataRow = this.tableDataRow.findIndex(q => q.partGuid == alreadySelectedPart.partGuid);
      const index = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == id);

      //

      const previousQuantity = this.tableDataRow[indexTableDataRow][index].quantity;

      this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
        quantity: this.selectedQuantities[indexTableDataRow].quantity + quantity - previousQuantity
      }

      if (parentPart.quantity < this.selectedQuantities[indexTableDataRow].quantity) {
        this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
          quantity: this.selectedQuantities[indexTableDataRow].quantity - quantity + previousQuantity
        }

        isExceeded = true;
        this.commonService.showToast(2000, 'Quantity exceeded than required units');
        //return;
      }

      //
      quantity = isExceeded ? previousQuantity : quantity;

      alreadySelectedPart.requestedQty = quantity;
      this.tableDataRow[indexTableDataRow][index].quantity = quantity;

      this.tableDataRow[indexTableDataRow][index].dropDownModel = {
        ...this.tableDataRow[indexTableDataRow][index].dropDownModel,
        selectedOption: { label: quantity, value: quantity },
        value: quantity
      };

      this.triggerResetTable(indexTableDataRow, alreadySelectedPart.partGuid, id, false);
      /*this.tableDataRow[indexTableDataRow] = [ ...this.tableDataRow[indexTableDataRow] ];
      this.tableDataRow[indexTableDataRow].partGuid = alreadySelectedPart.partGuid;

      const tableInstance = this.tableXA.toArray()[indexTableDataRow];
      this.selectedAvailableParts.forEach((item: any) => {
        const selectedIndex = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == item.inventoryItemId);

        if (tableInstance && selectedIndex > -1) {
          setTimeout(() => {
            tableInstance.triggerSelect(selectedIndex);
          }, 1);
        }
      })*/
    }
  }

  /**
   * select all rows
   */
  selectAllRows($event: any, partGuid: string): void {
    const indexTableDataRow = this.tableDataRow.findIndex(q => q.partGuid == partGuid);

    const tableInstance = this.tableXA.toArray()[indexTableDataRow];

    const parentPart = this.selectedParts.find(q => q.partGuidId == partGuid);
    let isExceeded: boolean = false;

    if ($event) {
      for (let i = 0; i < this.tableDataRow[indexTableDataRow].length; i++) {
        const availablePart = this.tableDataRow[indexTableDataRow][i];
        const itemGuid = availablePart.inventoryItemGuid;
        //const quantity = availablePart.quantity;
        const quantity = availablePart.quantity >= parentPart?.quantity ? parentPart?.quantity : availablePart.quantity;
        const id = availablePart.id;
  
        if (this.selectedQuantities[indexTableDataRow]?.quantity) {
          this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
            quantity: this.selectedQuantities[indexTableDataRow].quantity + quantity
          }
        } else {
          this.selectedQuantities[indexTableDataRow] = {};
          this.selectedQuantities[indexTableDataRow] = { ... this.selectedQuantities[indexTableDataRow], 
            quantity: quantity
          }
        }

        this.tableDataRow[indexTableDataRow][i].dropDownModel = {
          ...this.tableDataRow[indexTableDataRow][i].dropDownModel,
          disabled: true
        };
  
        if (parentPart.quantity < this.selectedQuantities[indexTableDataRow].quantity) {
          this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
            quantity: this.selectedQuantities[indexTableDataRow].quantity - quantity
          }
  
          this.tableDataRow[indexTableDataRow][i].checked = false;
          // this.tableDataRow[indexTableDataRow][index].dropDownModel = {
          //   ...this.tableDataRow[indexTableDataRow][index].dropDownModel,
          //   disabled: true
          // };
  
          this.commonService.showToast(2000, 'Quantity exceeded than required units');
          isExceeded = true;
          this.selectedQuantities[indexTableDataRow] = {};
          this.selectedAvailableParts = this.selectedAvailableParts.filter(q=>q.partGuid != partGuid);
          //this.triggerResetTable(indexTableDataRow, partGuid);
          break;
        }
      }
      // this.tableDataRow[indexTableDataRow].forEach((availablePart: any, index: number) => {

        
      //   //
      // });
    }
    
    if (isExceeded) {
      this.triggerResetTable(indexTableDataRow, partGuid);
      return;
    }
    
    this.tableDataRow[indexTableDataRow].forEach((availablePart: any, index: number) => {
      const itemGuid = availablePart.inventoryItemGuid;
      //const quantity = availablePart.quantity;
      const quantity = availablePart.quantity >= parentPart?.quantity ? parentPart?.quantity : availablePart.quantity;
      const id = availablePart.id;

      if ($event) {
        this.tableDataRow[indexTableDataRow][index].dropDownModel = {
          ...this.tableDataRow[indexTableDataRow][index].dropDownModel,
          editHide: false,
          disabled: false,
          selectedOption: { label: quantity, value: quantity },
          value: quantity
        };

        this.tableDataRow[indexTableDataRow][index].checked = true;
        const alreadySelectedPart = this.selectedAvailableParts?.find(q => q.inventoryItemId == availablePart.id && q.partGuid == partGuid);
        if (!alreadySelectedPart) {
          if (!this.selectedAvailableParts) {
            this.selectedAvailableParts = [];
          }
          this.selectedAvailableParts.push({
            'inventoryItemId': id,
            'requestedQty': quantity,
            'inventoryCost': availablePart.unitPrice,
            'totalPurchaseCost': availablePart.totalPurchaseCost,
            'partGuid': availablePart.partGuid,
            'partName': parentPart.description,
            'partCode': availablePart.oemCode || availablePart.amCode || parentPart.code || ''

          });
        }
      } else {
        //this.selectedAvailableParts = this.selectedAvailableParts.filter(q => q.inventoryItemId != id && q.partGuid == partGuid);
        this.selectedAvailableParts = this.selectedAvailableParts.filter(q => !(q.inventoryItemId === id && q.partGuid === partGuid));
        this.selectedQuantities[indexTableDataRow] = {};
        this.tableDataRow[indexTableDataRow][index].dropDownModel = { ...this.tableDataRow[indexTableDataRow][index].dropDownModel, editHide: false, disabled: true };
        this.tableDataRow[indexTableDataRow][index].checked = false;
      }
    });

    this.triggerResetTable(indexTableDataRow, partGuid);
    // this.tableDataRow[indexTableDataRow] = [ ...this.tableDataRow[indexTableDataRow] ];
    // this.tableDataRow[indexTableDataRow].partGuid = partGuid;
    // this.selectedAvailableParts.forEach((item: any) => {
    //   const selectedIndex = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == item.inventoryItemId);

    //   if (tableInstance && selectedIndex > -1) {
    //     setTimeout(() => {
    //       tableInstance.triggerSelect(selectedIndex);
    //     }, 1);
    //   }
    // })
  }

  /**
   * trigger reset table
   */
  triggerResetTable(indexTableDataRow: number, partGuid: string, id?: number, reset:boolean = true ): void {
    //const tableInstance = this.tableXA.toArray()[indexTableDataRow];

    this.tableDataRow[indexTableDataRow] = [ ...this.tableDataRow[indexTableDataRow] ];
    this.tableDataRow[indexTableDataRow].partGuid = partGuid;

    //if (reset) {
    //this.selectedAvailableParts.forEach((item: any) => {
    // const selectedIndex = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == item.inventoryItemId);
    //const selectedIndex = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == (id || item.inventoryItemId));
  
    // if (tableInstance && selectedIndex > -1) {
    //   setTimeout(() => {
    //     tableInstance.triggerSelect(selectedIndex);
    //   }, 1);
    // }
    //})
    // }
  }

  /**
   * get first valid code
   */
  getFirstValidCode(ele: any): string {
    return ele.oemCode?.trim() || ele.amCode?.trim() || ele.itemCode?.trim() || '';
  }

  /**
   * close
   */
  close(reload?: boolean): void {
    this.dialogRef.close(reload);
  }

  /**
     * 
        guid selection
     */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: any, event: any): void => {
    if (column.selector.field == 'checked') {
      const id = row[1].result.value;
      const quantity: any = row[12].result.value?.selectedOption?.value || 0;
      const partGuid = row[14].result.value;

      const indexTableDataRow = this.tableDataRow.findIndex(q => q.partGuid == partGuid);

      const index = this.tableDataRow[indexTableDataRow].findIndex(q => q.id == id);
      this.tableDataRow[indexTableDataRow][index].dropDownModel = {
        ...this.tableDataRow[indexTableDataRow][index].dropDownModel,
        editHide: false,
        disabled: !event.srcElement.checked,
        selectedOption: { label: quantity, value: quantity },
        value: quantity
      };
      this.tableDataRow[indexTableDataRow][index].quantity = quantity;
      //const tableInstance = this.tableXA.toArray()[indexTableDataRow];
      this.tableDataRow[indexTableDataRow][index].checked = event.srcElement.checked;
      if (event.srcElement.checked) {
        const parentPart = this.selectedParts.find(q => q.partGuidId == partGuid);
        if (this.selectedQuantities[indexTableDataRow]?.quantity) {
          this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
            quantity: this.selectedQuantities[indexTableDataRow].quantity + quantity
          }
        } else {
          this.selectedQuantities[indexTableDataRow] = {};
          this.selectedQuantities[indexTableDataRow] = { ... this.selectedQuantities[indexTableDataRow], 
            quantity: quantity
          }
        }

        if (parentPart.quantity < this.selectedQuantities[indexTableDataRow].quantity) {
          this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
            quantity: this.selectedQuantities[indexTableDataRow].quantity - quantity
          }

          this.tableDataRow[indexTableDataRow][index].dropDownModel = {
            ...this.tableDataRow[indexTableDataRow][index].dropDownModel,
            disabled: true
          };

          this.tableDataRow[indexTableDataRow][index].checked = false;
          this.commonService.showToast(2000, 'Quantity exceeded than required units');
          this.triggerResetTable(indexTableDataRow, partGuid, id);
          return;
        }

        //this.tableDataRow[indexTableDataRow] = [ ...this.tableDataRow[indexTableDataRow] ];
        //this.tableDataRow[indexTableDataRow].partGuid = partGuid;
        
        this.selectedAvailableParts.push({
          'inventoryItemId': id, //this.tableDataRow[indexTableDataRow][index].id,
          'requestedQty': quantity,
          'inventoryCost': this.tableDataRow[indexTableDataRow][index].unitPrice,
          'totalPurchaseCost': this.tableDataRow[indexTableDataRow][index].totalPurchaseCost,
          'partGuid': partGuid,
          'partName': parentPart.description,
          'partCode': this.tableDataRow[indexTableDataRow][index].oemCode || this.tableDataRow[indexTableDataRow][index].amCode || parentPart.code || ''
        });
        const selectTableData = this.tableDataRow[indexTableDataRow].find(q => q.id == id);
        if (selectTableData) {
          selectTableData.quantity = quantity;
        }

      } else {
        this.tableDataRow[indexTableDataRow][index].checked = false;
        this.selectedQuantities[indexTableDataRow] = { ...this.selectedQuantities[indexTableDataRow], 
          quantity: this.selectedQuantities[indexTableDataRow].quantity - quantity
        }

        this.selectedAvailableParts = this.selectedAvailableParts.filter(q => !(q.inventoryItemId === id && q.partGuid === partGuid));
        //this.selectedAvailableParts = this.selectedAvailableParts.filter(q => q.inventoryItemId != id && q.partGuid == partGuid);
        //this.tableDataRow[indexTableDataRow] = [ ...this.tableDataRow[indexTableDataRow] ];
        //this.tableDataRow[indexTableDataRow].partGuid = partGuid;
      }

      // trigger select every row
      // 
      this.triggerResetTable(indexTableDataRow, partGuid, id, false);
    }
  };

  /**
   * toggle tree node
   */
  toggle(node: any): void {
    node.expanded = !node.expanded;
  }

  /**
   * checkIndividualInventoryItemsAcrossAllParts
   */
  checkIndividualInventoryItemsAcrossAllParts(): void {
    const groupedParts = this.selectedAvailableParts.reduce((acc, part) => {
      const key = part.inventoryItemId;
    
      if (!acc[key]) {
        acc[key] = [];
      }
    
      acc[key].push(part);
    
      return acc;
    }, {}); // Initial accumulator is an empty object {}

    const groupedArray: any = Object.entries(groupedParts).map(([ inventoryItemId, items ]) => ({
      inventoryItemId: Number(inventoryItemId), 
      items
    }));

    const allocatedQuantity = groupedArray.find(q => q.inventoryItemId === 859)?.items?.reduce((sum, item) => sum + item.requestedQty, 0) || 0;

    //
    const flatTableDataRow = this.tableDataRow.flat().reduce((acc, part) => {
      //const key = part.inventoryItemGuid;
      const key = part.id;
    
      if (!acc[key]) {
        acc[key] = [];
      }
    
      acc[key].push(part);
    
      return acc;
    }, {});

    const groupedArrayTableData = Object.entries(flatTableDataRow).map(([ id, items ]) => ({
      inventoryItemId: id, 
      items
    }));

  }
  /**
   * reserve selected parts
   */
  reserveParts(): void {
    //
    // console.log(this.selectedAvailableParts);
    // //this.checkIndividualInventoryItemsAcrossAllParts();
    // return;
    
    this.commonService.showLoading();
    this.inventoryService.reserveParts(this.selectedAvailableParts, this.data?.repairGuid).subscribe({
      next: (response: any) => {
        if (response) {
          this.commonService.showInfoToast(5000, 'parts_get_reserved');
        }
        this.commonService.hideLoading();
        this.close(true);
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { countryCodeList, customerFeedbackStepData, StepData } from 'src/app/helper/form/customer-feedback.helper';
import { licensePlateValidator } from 'src/app/helper/form/vehicle-form.helper';
import { CommonService } from 'src/app/services/common/common.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: [ './customer-feedback.component.scss' ]
})

export class CustomerFeedback implements OnInit {

  feedbackForm: FormGroup;
  currentStep = 0;
  steps: StepData[] = customerFeedbackStepData;
  public countryList = countryCodeList;
  public objectGuid: string;
  public successOrErrorPage: string = '';
  feedbackSource: string = 'repair';
  logoUrl: string = '';

  /**
   * 
   * @param commonService 
   */
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private route: ActivatedRoute
  ) {
    const el: any = document.getElementById('custom-loader');
    if (el) {
      el.style.display = 'none';
    }

    this.feedbackForm = this.fb.group({
      defaultStep1: this.fb.group({
        licensePlate: [ '', [ Validators.required, Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$'), licensePlateValidator() ] ]
      })
    });
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.commonService.showLoading();

    const token = this.route.snapshot.queryParamMap.get('token') ?? '';
    this.feedbackSource = this.route.snapshot.queryParamMap.get('feedbackSource') ?? 'repair';
    this.logoUrl = this.route.snapshot.queryParamMap.get('orgLogo') ?? '';
    this.feedbackService.getFeedbackQuestionnaires(token, this.feedbackSource).subscribe({
      next: (response) => {
        this.commonService.hideLoading();

        this.groupAndCreateQuestionForm(response.data);

        this.objectGuid = response.data.additionalInformation.objectId;

        this.setInputValue('defaultStep1', 'licensePlate', response.data.additionalInformation.licensePlateNumber);
        this.steps[0].input[1].question = this.steps[0].input[1].question.replace('{{license_plate_number}}', response.data.additionalInformation.licensePlateNumber);
      },
      error: (err) => {
        if (err?.error?.errorMessages[0]?.error.indexOf('Feedback already submitted') == 0) {
          this.successOrErrorPage = 'error';
        }
        this.commonService.hideLoading();
      }
    });
  }


  /**
   * 
   * @param groupName 
   * @param controlName 
   * @param propertyName 
   */
  setInputValue(groupName, controlName, value): void {
    this.getControl(groupName, controlName).setValue(value);
    this.getControl(groupName, controlName).markAllAsTouched();
  }

  /**
   * 
   */
  groupAndCreateQuestionForm(data): void {
    Array.from(new Set(data.feedbackQuestions.map(x => x.questionGroup))).forEach((groupId: string) => {
      const matchingInput = data.feedbackQuestions.filter(x => x.questionGroup === groupId);
      this.steps.push({
        group: groupId,
        input: matchingInput
      });

      const stepGroup = new FormGroup({});

      matchingInput.forEach((question) => {
        stepGroup.addControl(question.questionCode, new FormControl('', Validators.required));
      });

      this.feedbackForm.addControl(groupId, stepGroup);
    });
  }

  /**
   * 
   */
  getControl(groupName: string, controlName: string): any {
    return this.feedbackForm.get(groupName).get(controlName);
  }

  /**
   * 
   */
  nextStep(): void {
    const currentGroup = this.feedbackForm.get(this.steps[this.currentStep].group);
    if (currentGroup?.valid) {
      this.currentStep++;
    } else {
      currentGroup?.markAllAsTouched();
    }
  }

  /**
   * 
   */
  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  /**
   * 
   */
  submitForm(): void {
    if (this.feedbackForm.valid) {
      this.commonService.showLoading();

      const token = this.route.snapshot.queryParamMap.get('token') || '';

      this.feedbackService.saveFeedback(token, this.feedbackSource, this.generatePostBody()).subscribe({
        next: (result) => {
          if (result) {
            this.commonService.showInfoToast(1000, 'Feedback send successfully');
            this.successOrErrorPage = 'success';
          }
        }, complete: () => {
          this.commonService.hideLoading();
        }
      });
    } else {
      this.feedbackForm.markAllAsTouched();
    }
  }

  /**
   * 
   * @returns 
   */
  generatePostBody(): any {
    const rawData = this.feedbackForm.getRawValue();
    const payload: any = {
      'objectId': this.objectGuid,
      'feedbackAnswers': []
    };

    Object.keys(rawData)
      .forEach((group) => {

        if (group.indexOf('default') < 0) {
          Object.keys(rawData[group])
            .forEach((question) => {
              payload.feedbackAnswers.push({
                'questionCode': question,
                'answer': rawData[group][question]
              })
            });
        }
      });

    return payload;
  }

}
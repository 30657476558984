<div class="check-parts-availability-container"
     *ngIf="!isLoading">
    <div class="header">
        <h3>{{ 'available_parts' | translate}}</h3>
        <div class="close-icon"
             (click)="close();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div *ngFor="let parent of treeData;let index = index;"
         class="tree-node fx-col">
        <div class="mat-tree-node">
            <xa-button type="basic"
                       size="xs"
                       class="tree-button"
                       color="black"
                       (click)="toggle(parent)">
                <mat-icon class="mat-icon-rtl-mirror"
                          [svgIcon]="parent.expanded ?  'minus-square' : 'plus-square'">

                </mat-icon>
                <span class="parent-label">{{ parent.label }}</span>
            </xa-button>
            <div class="fx-col part-detail">
                <div class="fx-row part-detail-row">
                    <div>
                        <span>Code:&nbsp;</span><span><b>{{ parent.code }}</b></span>
                    </div>
                    <div>
                        <span>Quantity:&nbsp;</span><span><b>{{ parent.quantity }}</b></span>
                    </div>
                    <div>
                        <span>Price:&nbsp;</span><span><b>{{ parent.cost }}</b></span>
                    </div>
                    <div>
                        <span>Total Price:&nbsp;</span><span><b>{{ parent.total }}</b></span>
                    </div>
                </div>
            </div>

        </div>

        <!-- Child Nodes -->
        <div *ngIf="parent.expanded"
             class="child-nodes">
            <div class="child-node">
                <div class="popup-body">
                    <div class="table-container">
                        <div class="table-wrapper">
                            <xa-table [config]="tableConfig"
                                      [tableData]="tableDataRow[index]"
                                      [cellClicked]="handleCellClick"
                                      [onDropdownSelect]="onDropdownSelected"
                                      [selectAllRows]="selectAllRows.bind(this)"
                                      [i18n]="i18n"
                                      [id]="parent.id"
                                      #tableXA></xa-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="treeData?.length === 0"
         class="no-tree-data-container">
        <div class="no-tree-data">
            {{ 'no_parts_available' | translate}}
        </div>
    </div>
    <div class="btn-container">
        <xa-button type="outline"
                   color="gray"
                   class="right"
                   (click)="close()">
            {{'cancel'|translate}}
        </xa-button>
        <xa-button class="right"
                   (click)="reserveParts()"
                   [disabled]="selectedAvailableParts?.length <= 0">
            <mat-icon [svgIcon]="'file-with-attachment'"></mat-icon>
            {{'reserve_parts'|translate}}
        </xa-button>
    </div>
</div>
<div class="check-parts-availability-container"
     *ngIf="isLoading">
    <div class="header align-self-center">
        <h2>{{'part_loading_alert' | translate }}</h2>
    </div>
    <div class="show-image"
         style="display: flex;align-self:center;">
        <img src="assets/img/search.gif"
             alt="Loading..."
             style="width:300px;height:200px;">
    </div>
</div>
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AddVehicleForm } from 'src/app/model/chq-new-case-form.model';
import { CustomValidationResult } from 'src/app/model/chq-validation-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';

export const newVehicleForm = (isMG: boolean = false): AddVehicleForm => {
  const model = {
    vin: {
      placeHolder: 'search_vin',
      name: 'vin',
      label: 'vin_number',
      type: 'text',
      value: 0,
      icon: 'search',
      maxLength: 17,
      validation: {
        name: 'vin',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.minLength(17), Validators.pattern('^[a-hA-Hj-nJ-NpPr-zR-Z0-9]+$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['pattern']) {
            return 'invalid_characters'
          }
          if (error['minlength']) {
            return 'vin_length_validation'
          }
          return '';
        }
      }
    },
    make: {
      placeHolder: 'make',
      name: 'make',
      label: 'make',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'name',
      type: 'select',
      value: 0,
      validation: {
        name: 'make',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_make'
          }
          return '';
        }
      },
    },
    model: {
      placeHolder: 'model',
      name: 'model',
      label: 'model',
      options: [ {} ],
      displayValue: 'modelDescription',
      fieldValue: 'modelDescription',
      type: 'select',
      value: 0,
      validation: {
        name: 'model',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_model'
          }
          return '';
        }
      }
    },
    year: {
      placeHolder: 'import_interval',
      label: 'import_interval',
      name: 'year',
      //selectedOption: '',
      options: [ {} ],
      displayValue: 'name',
      fieldValue: 'name',
      type: 'select',
      value: 0,
      validation: {
        name: 'year',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_import_year'
          }
          return '';
        }
      }
    },
    licensePlate: {
      placeHolder: 'license_plate',
      label: 'license_plate',
      name: 'licensePlate',
      value: 0,
      type: 'text',
      maxLength: 12,
      validation: {
        name: 'licensePlate',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9\\//]*$'), licensePlateValidator() ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_license_plate'
          }
          if (error['maxlength']) {
            return 'max_length_license_plate'
          }
          if (error['pattern']) {
            return 'only_alphanumberic'
          }
          if (error['invalidLicensePlate']) {
            return 'invalid_license_plate'
          }
          return '';
        }
      }
    },
    carMilege: {
      placeHolder: 'car_mileage',
      label: 'car_mileage',
      name: 'carMilege',
      value: 0,
      type: 'text',
      maxLength: 7,
      validation: {
        name: 'carMilege',
        validationFunction: (): ValidatorFn => {
          if (isMG) {
            return Validators.compose([ Validators.maxLength(7), Validators.pattern('^[0-9]*$') ]);
          } else {
            return Validators.compose([ Validators.required, Validators.maxLength(7), Validators.pattern('^[0-9]*$') ]);
          }
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_car_mileage'
          }
          if (error['maxlength']) {
            return 'max_length_car_mileage'
          }
          if (error['pattern']) {
            return 'pattern_numeric'
          }
          if (error['invalidCarMileage']) {
            return 'invalid_car_mileage'
          }
          return '';
        }
      }
    },
    registrationDate: {
      placeHolder: 'registration_date',
      label: 'registration_date',
      name: 'registrationDate',
      value: '',
      type: 'date',
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
      minDate: new Date('1980-01-01'),
      validation: {
        name: 'registrationDate',
        validationFunction: (): ValidatorFn => {
          if (isMG) {
            return Validators.compose([]);
          } else {
            return Validators.compose([ Validators.required ]);
          }
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_date'
          }
          return '';
        }
      }
    },
    manufacturingDate: {
      placeHolder: 'manufacturing_date',
      label: 'manufacturing_date',
      name: 'manufacturingDate',
      value: '',
      type: 'date',
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
      minDate: new Date('1980-01-01'),
      validation: {
        name: 'manufacturingDate',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_date'
          }
          return '';
        }
      }
    },
    vehicleType: {
      placeHolder: 'body_type',
      name: 'vehicleType',
      label: 'body_type',
      options: [],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'select',
      value: 0,
      validation: {
        name: 'vehicleType',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_body_type'
          }
          return '';
        }
      }
    },
    modelCode: {
      placeHolder: 'model_code',
      name: 'modelCode',
      label: 'model_code',
      options: [],
      displayValue: 'name',
      fieldValue: 'id',
      type: 'text',
      maxLength: 40,
      value: 0,
      validation: {
        name: 'modelCode',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.maxLength(40), Validators.pattern('^[a-zA-Z0-9\\W_]*$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['maxlength']) {
            return 'max_length_model_code'
          }
          if (error['pattern']) {
            return 'pattern_model_code'
          }
          return '';
        }
      }
    },
  }
  return model;
};

export const newVehicleHeader: ChqWidgetsHeaderModel = {
  title: 'Add vehicle',
  step: 2,
  description: 'Add your customers vehicle below',
  stepName: 'Step Two',
};

/**
 *
 * @returns
 */
export function carMileageValidator(): ValidatorFn {
  const result: CustomValidationResult = { 'invalidCarMileage': false };
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value < 1) {
      result['invalidCarMileage'] = true;
      return result;
    }
    return null;
  }
}

/**
 * License plate Validator
 * @returns
 */
export function licensePlateValidator(): ValidatorFn {
  const result: CustomValidationResult = { 'invalidLicensePlate': false };
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '0') {
      result['invalidLicensePlate'] = true;
      return result;
    }
    return null;
  }
}

<div class="page-container">
    <div class="header-estimate">
        <!-- <div class="back-wrapper row">
            <mat-icon [svgIcon]="'left-arrow'" (click)="back();"></mat-icon>
            <div> {{'back_estimate' | translate  }}</div>
        </div> -->
        <div class="estimate-header">{{'estimate_log' | translate}}</div>
    </div>
    <div class="estimate-container">
        <div class="toolbar-container">
            <div class="toolbar-wrapper row">
                <div class="part-status col-6" *ngIf="estimateData?.length > 0">
                    <div class="part-status-label">{{ 'status' | translate | uppercase }}</div>
                </div>
            </div>
        </div>
    
        <div class="table-container">
            <table class="main-table">
                <thead>
                    <th class="header-item">{{'username' | translate}}</th>
                    <th class="header-item">{{'date_and_time' | translate}}</th>
                    <th class="header-item">{{'version' | translate}}</th>
                    <th class="header-item">{{'source' | translate}}</th>
                    <th class="header-item" width="15%">{{'action' | translate}}</th>
                </thead>
                <tbody *ngIf="estimateData?.length > 0">
                    <tr class="estimate-data-row" *ngFor="let estimate of estimateData">
                        <td class="data-item">{{estimate.userName}}</td>
                        <td class="data-item">{{estimate.createdDate | date : 'M/d/yy - h:mm a'}}</td>
                        <td class="data-item">{{estimate.version}}</td>          
                        <td class="data-item">{{estimate.source}}</td>
                        <td class="data-item align-center flex link" (click)="openEstimate(estimate)">
                            <mat-icon [svgIcon]="'viewFile'" color="black"></mat-icon>
                            {{'view_estimate' | translate}}
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="estimateData?.length === 0 && !fetchAPI">
                    <tr>
                        <td colspan="7" class="no-parts">{{ 'no_estimate_available' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    
    </div>
</div>

<div class="footer-full-wrapper">
    <div class="footer-image-wrapper">
        <div class="image-wrapper">
            <img src="assets/img/addenda-claims.png" alt=""/>
        </div>
    </div>
</div>
<div class="form-field-numeric">
    <label for=""
           class="form-label pointer-events">
        {{label | translate:{currency: '(' + i18n?.currencySymbol + ')'} }}
        <span class="required"
              *ngIf="required && label">*</span>
        <mat-icon *ngIf="hint"
                  class="hint"
                  [svgIcon]="hintIcon || 'hint'"
                  [matTooltip]="hint">
        </mat-icon>
    </label>
    <div class="text-field">
        <input class="form-value"
               autocomplete="off"
               inputmode="numeric"
               #input
               type="number"
               [id]="inputId"
               [name]="nameId"
               [min]="min"
               [max]="max"
               onkeydown="javascript: return (['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.key) || ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code)) ? true : !(isNaN(Number(event.key)) && event.key !== '.') && (event.key!=='Space' || event.code!=='Space')"
               placeholder="{{(placeholder || label) |  translate:{currency: '(' + i18n?.currencySymbol + ')'} }}"
               [readonly]="readonly"
               [disabled]="disabled"
               [required]="required"
               [value]="value"
               [pattern]="pattern"
               [step]="step"
               (keyup)="_onInputInteraction();onTouched()"
               (wheel)="$event.preventDefault()">
        <p class="append"
           *ngIf="textAddon && textAddon !== '' ">{{textAddon}}</p>
        <span class="counter"
              *ngIf="showCounter">
            <mat-icon svgIcon="dropdown-up"
                      (click)="increment()"></mat-icon>
            <mat-icon svgIcon="dropdown"
                      (click)="decrement()"></mat-icon>
        </span>
        <ng-content></ng-content>
    </div>
</div>
import { Observable, Observer } from 'rxjs';
import { UploadStepModel } from 'src/app/model/chq-upload-model'

export interface XaFileUploadSelector{
    showSPI:boolean,
    showMultipleUpload:boolean,
    isDisabled:boolean,
    footerLogo:string
    label:string,
    spiLabel?:string,
    bulkLabel?:string,
    spiDescription?:string,
    bulkDescription?:string
}

export interface ReviewIconButtons{
    icon?: string,
    iconClass?: string,
    iconAction?: number,
    displayIndicator?: boolean,
    displayAsButton?:boolean,
    iconWrapperClass?:string,
    text?:string,
    buttonType?:string
  }

export enum IconActions{
    delete = 1,
    comment = 2,
    edit = 3,
    upload = 4,
    download = 5
}
  
export enum UploadActions{
    delete = 1,
    upload = 2,
    retake = 3,
    cancelBooking = 4,
    skip = 5,
    skipAll = 6,
    notify = 7,
    back = 8
}

export interface XAImages{
    uploadSelector?:XaFileUploadSelector,
    fileReviewSection?: XAFileReviewSection,
    currentPage:string,
    displayReviewSection:boolean,
    displayUploader:boolean,
    displayAI:boolean,
    objectId:number,
    domainId:number,
    inspectionId:number,
    displaySelector:boolean,
    uploadMode:string,
    isFixed?:boolean,
    hideLogo?:boolean,
    organizationDetail?:any,
    isSurveyor?:boolean,
    onImageUploadAction?: (param?:any) => void;
    onMultipleImagesUploadComplete?: (param?:any) => void;
    onImageSkipped?:(param?:any) =>void;
    onImageDeleted?:(param?:any) =>void;
}


export enum HeaderActions{
  deleteAll = 1,
  ai = 2,
  custom = 3,
  recommendation = 4,
  fraudDetection = 5,
  pdf = 6,
  retry = 7
}
export interface XaHeaderAction{
  label:string,
  icon:string,
  type: HeaderActions,
  customAction?:any
  btnType?:string,
  callback?: (action?:XaHeaderAction, param2?:any) =>void,
  btnColor?:string,
  requireFileUpload?:boolean,
  location: 'review' | 'AI' | 'recommendation' | 'selection' | 'upload' |'AI-Detail'
}

export enum Components{
    selection = 1,
    uploader = 2,
    review = 3,
    ai = 4,
    aiReview = 5
}

export const exportMapper = (step:UploadStepModel, actions:any, isMultiUpload?:boolean):any[] =>{
  let filteredActions = [];
  if(isMultiUpload || step.procedureSteps === 'Bulk Upload Image'){
    const iconActionTypes = [ IconActions.upload, IconActions.edit ];
    const validActions = actions.filter((a) =>{
      return (a.iconAction && !iconActionTypes.includes(a.iconAction)) 
    });
    return validActions;
  }
  if(step.procedureSteps !== 'Bulk Upload Image' && !step.isMassUpload && (step.url || step.uploadedPartGuid)){
    const iconActionTypes = [ IconActions.comment, IconActions.upload, IconActions.delete ];
    const uploadActionTypes = [ UploadActions.upload, UploadActions.skipAll, UploadActions.skip, UploadActions.back ];
    const validActions = actions.filter((a) =>{
      return (a.iconAction && !iconActionTypes.includes(a.iconAction)) || (a.type && !uploadActionTypes.includes(a.type));
    });
    filteredActions = [ ...validActions ];
  }

  if(step.url || step.uploadedPartGuid){
    const iconActionTypes = [ IconActions.comment, IconActions.delete, IconActions.edit ];
    const validActions = actions.filter((a) =>{
      return (a.iconAction && iconActionTypes.includes(a.iconAction)) && filteredActions.filter(f => f.iconAction === a.iconAction).length === 0;
    });
    filteredActions = [ ...filteredActions, ...validActions ];
  }

  if(!step.url && !step.image && !step.uploadedPartGuid && !step?.uploadInProgress){
    const iconActionTypes = [ IconActions.upload ];
    const uploadActionTypes = [ UploadActions.upload, UploadActions.skip ];
    const validActions = actions.filter((a) =>{
      return (a.iconAction && iconActionTypes.includes(a.iconAction)) || (a.type && uploadActionTypes.includes(a.type));
    });
    filteredActions = [ ...filteredActions, ...validActions ];
  }

  if(step?.isLocked){
    filteredActions = filteredActions.filter(a => a.iconAction !== IconActions.delete && a.iconAction !== IconActions.edit );
  }
  
  return filteredActions;
}

export interface WrapperStyle{
    height:string,
    width:string
}

export interface PhotosAction{
    type: 'upload' | 'ai' | 'review',
    action:any,
    step?:UploadStepModel
}


export enum FooterActions{
  createPdf = 1,
  retry = 2,
  upload=4,
  custom = 3
}

export interface XaImageAction{
  label?:string,
  icon?:string,
  btnType?:string,
  btnColor?:string,
  type?:FooterActions,
  isUploadAction?:boolean,
  disabled?:boolean,
  callback?: (action:XaImageAction, param2?:any) =>void,
  location: 'review' | 'AI' | 'recommendation' | 'selection' | 'upload' | 'AI-Detail'
}

export interface XADocuments{
  fileReviewSection?: XAFileReviewSection,
  uploadSelector?:XaFileUploadSelector,
  displaySelector?:boolean,
  displayReviewSection?:boolean;
  currentPage:string,
  isConsolidated?:boolean,
  objectId:number,
  domainId:number,
  isFixed?:boolean,
  hideLogo?:boolean,
  fromPersonal?: boolean,
  footerActions?:XaImageAction[],
  headerActions?: XaHeaderAction[],
  onDocumentDataLoad?:(param?:any, param2?:any) =>void;
  options?: any,
  onDocumentUploadAction?: (param?:any) => void;
}

export type XAFileReviewSection = {
    files:UploadStepModel[],
    type: 'document' | 'photo',
    actions: ReviewIconButtons[],
    permissionTag:string,
    wrapperStyle?:WrapperStyle,
    iconContainerClass:string,
    multipleUpload:boolean,
    maxFileLength:number,
    minFileLength:number,
    enableSPI:boolean,
    enableBulk:boolean,
    displayDialog:boolean,
    isEditable:boolean,
    aiReviewSection:boolean,
    headerActions?: XaHeaderAction[],
    footerActions:XaImageAction[],
    footerLogo?:string,
    bulkUploadInspectionItemId:number,
    observable?:(param1?: any, param2?: any)=>Observable<any>,
    observer?: (param1?: any, param2?: any)=>Observer<any>,
    onFileSelection?:(param1?:any) =>void;
    objectId:number,
    templateId:number,
    objectType:string,
    partTemplateGuid?:string,
    templateGuid?:string,
    domainId?:number,
    collectionGuid?:string,
    inspectionId:number,
    label:string
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-my-inventory-filter-popup',
  templateUrl: './my-inventory-filter-popup.component.html',
  styleUrls: [ './my-inventory-filter-popup.component.scss' ]
})
export class MyInventoryFilterPopupComponent implements OnInit {
  public groupConfig: XADropdownConfig = { 
    name: 'group', 
    label: 'group', 
    placeHolder: 'select_dot',
    labelKey: 'Name',
    valueKey: 'Id',
  }
  public groupOptions: [];
  public conditionConfig: XADropdownConfig = { 
    name: 'condition', 
    label: 'condition', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'name'
  }
  public conditionOptions: [];
  public stockStatusConfig: XADropdownConfig = { 
    name: 'stockStatus', 
    label: 'stock_status', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'id'
  }
  public stockStatusOptions: [];
  public statusConfig: XADropdownConfig = { 
    name: 'status', 
    label: 'status', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'name'
  }
  public statusOptions: [];

  public supplierConfig: XADropdownConfig = { 
    name: 'supplier', 
    label: 'supplier', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'id',
    enableSearch: true
  }
  public supplierOptions: [];

  public manufacturerConfig: XADropdownConfig = { 
    name: 'manufacturer', 
    label: 'manufacturer', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'id',
    enableSearch: true
  }
  public manufacturerOptions: [];

  public operatorConfig: XADropdownConfig = { 
    name: 'operator', 
    label: 'operator', 
    placeHolder: 'select_dot',
    labelKey: 'name',
    valueKey: 'id'
  }
  public operatorOptions: any = [ {
    name: '<=',
    id: 'less'
  }, {
    name: '>=',
    id: 'greater'
  } ];

  selectedGroup = '';
  selectedSupplier = '';
  selectedManufacturer = '';
  selectedCondition = '';
  selectedStockStatus = '';
  selectedStatus = '';
  selectedStockLevel = '';
  selectedOperator = '';
  /**
     * constructor
     * @param dialogRef 
     * @param data 
     */
  constructor(public dialogRef: MatDialogRef<MyInventoryFilterPopupComponent>,
      private monitorService: MonitorService,
      private commonService: CommonService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  /**
   * 
   */
  ngOnInit(): void {
    if(this.data.groupList?.length > 0) {
      this.groupOptions = this.data.groupList
    }
    if(this.data.manufacturerList?.length > 0) {
      this.manufacturerOptions = this.data.manufacturerList
    }
    if(this.data.conditionList?.length > 0) {
      this.conditionOptions = this.data.conditionList
    }
    if(this.data.supplierList?.length > 0) {
      this.supplierOptions = this.data.supplierList
    }
    if(this.data.stockStatusList?.length > 0) {
      this.stockStatusOptions = this.data.stockStatusList
    }
    if(this.data.statusList?.length > 0) {
      this.statusOptions = this.data.statusList
    }
    if(this.data.selectedFilters && Object.keys(this.data.selectedFilters).length > 0) {
      if(this.data.selectedFilters?.selectedGroup) this.selectedGroup = this.data.selectedFilters.selectedGroup;
      if(this.data.selectedFilters?.selectedSupplier) this.selectedSupplier = this.data.selectedFilters.selectedSupplier;
      if(this.data.selectedFilters?.selectedManufacturer) this.selectedManufacturer = this.data.selectedFilters.selectedManufacturer;
      if(this.data.selectedFilters?.selectedCondition) this.selectedCondition = this.data.selectedFilters.selectedCondition;
      if(this.data.selectedFilters?.selectedStockStatus) this.selectedStockStatus = this.data.selectedFilters.selectedStockStatus;
      if(this.data.selectedFilters?.selectedStatus) this.selectedStatus = this.data.selectedFilters.selectedStatus;
      if(this.data.selectedFilters?.selectedStockLevel) this.selectedStockLevel = this.data.selectedFilters.selectedStockLevel;
      if(this.data.selectedFilters?.selectedOperator) this.selectedOperator = this.data.selectedFilters.selectedOperator;
    }
  }
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }
  /**
   * handle close
   */
  searchDropdown(event, type): void {
    if(!event) event = ''
    if(type === 'supplier' && this.data.supplierList?.length > 0) {
      this.supplierOptions = this.data.supplierList.filter(x => x.name?.toLowerCase().includes(event?.toLowerCase()))
    }
    if(type === 'manufacturer' && this.data.manufacturerList?.length > 0) {
      this.manufacturerOptions = this.data.manufacturerList.filter(x => x.name?.toLowerCase().includes(event?.toLowerCase()))
    }
  }

  /**
   * handleApplyFilter
   */
  handleApplyFilter(): void {
    this.dialogRef.close({
      selectedGroup: this.selectedGroup,
      selectedSupplier: this.selectedSupplier,
      selectedManufacturer: this.selectedManufacturer,
      selectedCondition: this.selectedCondition,
      selectedStockStatus: this.selectedStockStatus,
      selectedStatus: this.selectedStatus,
      selectedStockLevel: this.selectedStockLevel,
      selectedOperator: this.selectedOperator,
    });
  }

  /**
   * resetFilters
   */
  resetFilters(): void {
    this.selectedGroup = '';
    this.selectedSupplier = '';
    this.selectedManufacturer = '';
    this.selectedCondition = '';
    this.selectedStockStatus = '';
    this.selectedStatus = '';
    this.selectedStockLevel = '';
    this.selectedOperator = ''
  }
}

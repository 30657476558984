<div class="form-field-text">
    <label for="" class="form-label pointer-events">
        {{label | translate}}
        <span class="required"
        *ngIf="required && label">*</span>
    </label>
    <div class="text-field">
        <input class="form-value"
               autocomplete="off"
               #elemRef
               [id]="inputId"
               [name]="nameId"
               placeholder="{{placeholder || label | translate}}"
               [readonly]="true"
               [disabled]="disable"
               [required]="required"
               [value]="inputValue"
               (keyup)="handleInputOnHost($event)">
        <mat-icon svgIcon="date" (click)="openPicker();"></mat-icon>
    </div>
</div>
<ng-container *ngIf="type === 'date';else datetime">
    <dx-date-box type="date"
                 *ngIf="showDateBox"
                 [showClearButton]="false"
                 #input
                 [width]="'100%'"
                 [invalidDateMessage]="''"
                 [useMaskBehavior]="true"
                 [openOnFieldClick]="true"
                 [adaptivityEnabled]="true"
                 [min]="minDate"
                 [displayFormat]="'dd/MM/yyyy'"
                 [max]="maxDate"
                 [pickerType]="'calendar'"
                 [height]="'0px'"
                 [validationError]="false"
                 [validationMessageMode]="'none'"
                 (onValueChanged)="setCalendarData($event);"
                 (onInitialized)="onInitialized()"
                 [acceptCustomValue]="false"
                 [opened]="opened"
                 (onOpened)="dropdownOpened = true"
                 (onClosed)="dropdownOpened = false; opened=false; onClosed();"
                 [disabled]="disable">
    </dx-date-box>
</ng-container>

<ng-template #datetime>
    <ng-container *ngIf="type === 'datetime';else time">
        <dx-date-box type="datetime"
                     *ngIf="showDateBox"
                     [showClearButton]="false"
                     #input
                     [width]="'100%'"
                     [invalidDateMessage]="''"
                     [useMaskBehavior]="true"
                     [openOnFieldClick]="true"
                     [adaptivityEnabled]="true"
                     [min]="minDate"
                     [displayFormat]="'dd/MM/yyyy hh:mm aa'"
                     [max]="maxDate"
                     [pickerType]="'calendar'"
                     [height]="'0px'"
                     [validationError]="false"
                     [validationMessageMode]="'none'"
                     [acceptCustomValue]="false"
                     [opened]="opened"
                     (onValueChanged)="setCalendarData($event);"
                     (onInitialized)="onInitialized()"
                     (onOpened)="dropdownOpened = true"
                     (onClosed)="dropdownOpened = false; opened=false; onClosed();"
                     [disabled]="disable">
        </dx-date-box>
    </ng-container>
</ng-template>

<ng-template #time>
    <ng-container *ngIf="type === 'time';">
        <dx-date-box type="time"
                     *ngIf="showDateBox"
                     [showClearButton]="false"
                     #input
                     [width]="'100%'"
                     [height]="'0px'"
                     [invalidDateMessage]="''"
                     [useMaskBehavior]="true"
                     [openOnFieldClick]="true"
                     [adaptivityEnabled]="true"
                     (onValueChanged)="setCalendarData($event);"
                     [min]="minDate"
                     [displayFormat]="'hh:mm aa'"
                     [max]="maxDate"
                     [pickerType]="'list'"
                     [validationError]="false"
                     [validationMessageMode]="'none'"
                     [disabled]="disabled"
                     (onInitialized)="onInitialized()"
                     (onOpened)="dropdownOpened = true"
                     [opened]="opened"
                     (onClosed)="dropdownOpened = false; opened=false; onClosed();"
                     [interval]="interval">
        </dx-date-box>
    </ng-container>
</ng-template>
<div class="garage-auto-assignment">
    <div class="header">
        <div class="close-icon" (click)="close();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div class="main-container">
        <div class="preference-text" *ngIf="!thresholdReachedMessage">{{ 'auto_garage_assignment_title' | translate }}</div>
        <div class="preference-text" *ngIf="thresholdReachedMessage">{{thresholdReachedMessage}}</div>
        <div class="garage-info-wrapper">
            <div class="info-group">
                <img class="avtar fx-0" src="{{garageDetails.logoUrl || 'assets/img/xa_logo.png'}}" />
                <div class="card-wrapper fx-1">
                    <div class="title-wrap">
                        <span class="garage-name fx-1">{{garageDetails.name}}</span>
                        <div class="location-group" *ngIf="garageDetails.distance">
                            <mat-icon class="location-icon" [svgIcon]="'location'"></mat-icon>
                            <span class="distance">{{garageDetails.distance}}</span>
                        </div>
                    </div>
                    <div class="status-wrap" *ngIf="garageDetails.type">
                        <div class="label-wrap">{{garageDetails.type}}</div>
                    </div>
                    <div class="address-wrap" *ngIf="garageDetails.address">{{garageDetails.address}}</div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="footer form-data-footer">
        <xa-button (click)="handleCancel()" type="outline" class="left">
            <span> {{'no' | translate }}</span>
        </xa-button>
        <xa-button (click)="handleYes()" class="right">
            <span> {{'yes' | translate }}</span>
        </xa-button>
    </div>
</div>
<div class="dialog-container-select-claimant">
    <div class="header">
        <h3>{{ 'select_claimant_request_missing_info' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="form-container">
            <div class="input-wrapper"
                 [formGroup]="formGroup">
                <xa-select formControlName="claimant"
                           class="coverage-select"
                           [options]="options"
                           [config]="config"
                           ngDefaultControl></xa-select>
            </div>
        </div>
    </div>
    <div class="btn-container">
        <ng-container>
            <xa-button color="gray" type="outline" size="sm" class="right" [disabled]="!selectedClaim" (click)="openReqMissingInfoPopup()">
                {{'next' |translate}}
            </xa-button>
        </ng-container>
    </div>
</div>
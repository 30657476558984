<div class="message-container">
    <div class="header">
        <h3>{{'notification' | translate }}!</h3>
        <!--<div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="continue();"></mat-icon>
        </div>-->
    </div>
    <div class="content">
        <label>{{data.message | translate}}</label>
    </div>
    <div class="footer form-data-footer">
        <!--<chq-button [buttonModel]="cancelButton" class="left"></chq-button>-->
        <button class="btn-confirm" mat-dialog-close (click)="continue()">{{'continue' | translate }}</button>
    </div>
</div>

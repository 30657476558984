import { Component, ElementRef, HostListener, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { CommonService } from 'src/app/services/common/common.service';
import {
  Platform
} from '@angular/cdk/platform';

@Component({
  selector: 'xa-camera-overlay',
  templateUrl: './camera-overlay.component.html',
  styleUrls: [ './camera-overlay.component.scss' ]
})
export class CameraOverlayComponent implements OnInit {
  @Input() vehicleModel: string;
  @Input() procedureStep:string;
  @ViewChild('imgElement') imgElement: ElementRef<HTMLImageElement>;
  @Input() forceOrientation:boolean = false;
  @Input() outlineLoading:boolean;
  public overlayShadow:string = '';
  platform = inject(Platform);
  public showTiltText:boolean = false;
  
  /**
   * 
   * @param configurationService 
   */
  constructor(
    private configurationService:ConfigurationService,
    private commonService:CommonService
  ) {}

  /**
   * on init
   */
  ngOnInit(): void {
    this.fillImage();
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }
  }

  /**
   * Returns the URL of the SVG outline for the given vehicle model.
   * The URL is constructed by concatenating the base URL of the
   * vehicle outline SVGs with the vehicle model name.
   * @returns a string URL of the SVG outline.
   */
  public get getVehicleOutline():string {
    return this.vehicleModel || '';
  }

   /**
   * resize event listiner
   */
   @HostListener('window:resize', [ '$event' ])
  onResize(event: any): void {
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }else{
      this.showTiltText = false;
    }
  }

   /**
   * get height
   */
   get heightScreen():string{ 
     if(window.innerHeight > window.innerWidth){
       return `${window.innerWidth - 60}px`; 
     }
     return `${window.innerHeight - 60}px`; 
   }

   /**
   * get height
   */
   get widthScreen():string{ 
     if(window.innerHeight > window.innerWidth){
       return `${window.innerHeight - 50}px`; 
     }
     return `${window.innerWidth - 35}px`; 
   }

   /**
   * fill image
   */
   fillImage():void{
     if(this.getVehicleOutline.includes('/assets/icons/outlines') || this.getVehicleOutline.includes(';base64,')){
       this.overlayShadow = this.getVehicleOutline;
       return;
     }
     // eslint-disable-next-line max-len
     this.overlayShadow = '';
     this.outlineLoading = true;
     this.commonService.imageReaderObservable(this.getVehicleOutline).subscribe((data) => {
       const url = data.image;
       const tempImg = new Image();
       tempImg.src = url;
       tempImg.onload = (event:any):void=>{
         const tempCanvas = document.createElement('canvas');
         const tempCtx = tempCanvas.getContext('2d');
         tempCanvas.height = tempImg.naturalHeight;
         tempCanvas.width = tempImg.naturalWidth;

         tempCtx.drawImage(tempImg, 0, 0);
         this.commonService.processImage(tempCanvas, '#FFFFFF');
         this.overlayShadow = tempCanvas.toDataURL('image/png');
         this.outlineLoading = false;
       }
     });
   }


   /**
   * get orientation
   */
   get getOrientationLock():boolean{
     //|| this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape'
     return this.forceOrientation ;
   }

  
}

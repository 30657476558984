<div class="customer-policy-details-container">
    <div class="header">
        <h3>{{'attention' | translate }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="title" >
        <div *ngIf="!data.hideExisting">{{splitExistingClaimData(0)}}<a href='{{getURLToRedirect()}}'>{{data.claimNo}}</a>{{splitExistingClaimData(1)}}</div>
        <h3 *ngIf="data.hideExisting">{{ data.message | translate }}</h3>
    </div>

    <div class="footer form-data-footer">
        <chq-button [buttonModel]="noButton" class="left"></chq-button>
        <chq-button [buttonModel]="yesButton" class="left"></chq-button>
    </div>
</div>
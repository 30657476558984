<div class="dialog-container" *ngIf="!showOTPScreen" [hidden]="dropdownLoading">
    <div class="title-wrapper">
        <div class="title-handler">
           <h3>{{ heading | translate }}</h3>
        </div>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" class="cross" style="fill:black;" (click)="close();"></mat-icon>
        </div>
        
    </div>
    
    <div mat-dialog-content class="content-wrapper">
        <p>
            {{ 'add_mobile_msg' | translate }}
        </p>
        <div class="mobile-wrapper">
            <label>{{ 'enter_mobile_number' | translate }}</label>
            <div class="div-wrapper">
                <chq-input [inputModel]="dropdownModel" (controlOutput)="onFormUpdate($event)">
                </chq-input>
                <div class="input-container">
                    <chq-input [inputModel]="phone" #phoneRef (controlOutput)="onFormUpdate($event)"  
                    ></chq-input>
                </div>
            </div>
        </div>
         <div class="mobile-otp">
            <div *ngIf="errorMessages" class="wrong_otp">
                {{errorMessages}}
            </div>
        </div>
        <div class="button-actions">
            <chq-button [buttonModel]="resetButtonModel"></chq-button>
        </div>
    </div>
</div>
<otp-mobile-dialog  *ngIf="showOTPScreen" [data]="otpData" (closeOTP)="hideOTPPage($event);" (closeEvent)="close();"></otp-mobile-dialog>
<div class="main-container fx-col">
    <div class="main-heading fx-row justify-content-between">
        <div class="main-heading-text">
            {{ 'upload_registration' | translate}}
        </div>
        <div>
            <mat-icon [svgIcon]="'cross'"
                      (click)="close(null)">
            </mat-icon>
        </div>
    </div>
    <div class="container-wrapper fx-col justify-content-between">
        <div class="content fx-row">
            <div class="photo-container" style="display: flex; align-items: center;justify-content: space-around;" 
            (click)="close({ 'card': 'front' })">
                <div ><b>{{ 'front' | translate}}</b></div>
            </div>
            <div class="photo-container" style="display: flex; align-items: center;justify-content: space-around;"
            (click)="close({ 'card': 'back' })">
                <div><b>{{ 'back' | translate}}</b></div>
            </div>
        </div>
    </div>
</div>

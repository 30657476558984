import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-garage-auto-assignment-confirmation',
  templateUrl: './garage-auto-assignment-confirmation.component.html',
  styleUrls: [ './garage-auto-assignment-confirmation.component.scss' ]
})
export class GarageAutoAssignmentConfirmationComponent {
  public garageDetails: any = {}
  public thresholdReachedMessage = '';
  /**
    Constructor for GarageAutoAssignmentConfirmationComponent.
    @param {any} data - Data injected into the dialog.
    @param {MatDialogRef<GarageAutoAssignmentConfirmationComponent>} dialogRef - Reference to the dialog.
    @param {MonitorService} monitorService - Service for monitoring.
    @param {CommonService} commonService - Service for common functionalities. 
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GarageAutoAssignmentConfirmationComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private translationService: TranslateService,
  ) {
    this.garageDetails = data.garageDetails || {}
    if(data?.isGarageThresholdReached && !data.isPreferredGarageRejectedRequest) {
      this.thresholdReachedMessage = this.translationService.instant('auto_garage_assignment_title_threshold_reached')?.replace('{{garage_name}}', this?.garageDetails?.preferGarageName)
    }
  }

  /**
   * handle clicked on No
   */
  handleCancel(): void {
    this.monitorService.logEvent('handleCancel', [ 'GarageAutoAssignmentConfirmationComponent', 'addenda-claim' ]);
    this.close();
  }

  /**
   * handle clicked on Yes
   */
  handleYes(): void {
    this.monitorService.logEvent('handleYes', [ 'GarageAutoAssignmentConfirmationComponent', 'addenda-claim' ]);
    this.dialogRef.close(true);
  }
  /**
   * close
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'GarageAutoAssignmentConfirmationComponent', 'addenda-claim' ]);
    this.dialogRef.close();
  }
}

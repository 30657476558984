import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { WidgetsModule } from 'src/app/widgets/widgets.module';
import { NoAccessErrorComponent } from './no-access-error/no-access-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundErrorComponent } from './not-found-error/not-found-error.component';

@NgModule({
  declarations: [  
    NoAccessErrorComponent,
    NotFoundErrorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    WidgetsModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    TranslateModule,
  ],
  exports: [ RouterModule, NoAccessErrorComponent ],
})
export class ErrorModule {}

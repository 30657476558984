<div class="content-wrapper-left row-wrapper"
     (click)="showAI()">
  <div class="d-flex align-center">
    <mat-icon svgIcon="arrow-back-left"
              class="back-icon"
              aria-hidden="false"
              aria-label='back icon'></mat-icon>
  </div>
  <div class="title-wrapper title-text">
    <h4>{{'back_ai' | translate}}</h4>
  </div>
</div>

<div class="main-container">
  <div class="info-container"
       [ngClass]="{'is-quote': isQuote }">
    <!-- Back btn  -->

    <!-- Back btn END  -->
    <div class="header-div">
      <h3 class="header-label text-black">{{ 'repair_recommendation' | translate }}</h3>
    </div>
    <div class="image-wrapper">
      <div class="table-container">
        <div class="header-container">
          <div class="header">
            <!-- USING filteredDamageReport[0] for sample data REPLACE filteredDamageReport[0] WITH ACTUAL DATA -->
            <h4>{{ 'vehicle' | translate }}</h4>
            <div class="header-wrapper">
              <div class="text-wrapper">
                <label>{{ 'type' | translate }}:&nbsp; </label>
                <label>{{filteredDamageReport[0]?.vehicleType}}</label>
              </div>
            </div>
          </div>
          <!-- Repair Category -->
          <div class="header flex-basis-20"
               *ngIf="filteredDamageReport[0]?.categorization">
            <h4>{{ 'repair_category' | translate }}</h4>
            <div class="header-wrapper">
              <div class="text-wrapper">
                <img [src]='currentCategoryUrl'
                     alt="repair category logo" />
                <label>{{currentCategorization}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <div class="table-header-wrapper row">
            <h4 class="col-10">{{ 'part' | translate }}</h4>
            <h4 class="col-2">{{ 'repair_recommendation' | translate }}</h4>
          </div>
          <div class='data-row-container'
               [ngClass]="{'isIos': isiOS}">
            <div *ngFor="let item of damageRecommendations"
                 class="row data-cols">
              <span class="col-10">{{item.partName}}</span>
              <span class="col-2">{{item.recommendation}}</span>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="aiAnalysisResult.length === 0 || currentStep.isEmpty">
               <label class="no-data">No data present</label>
           </div> -->
      </div>
    </div>
  </div>
</div>
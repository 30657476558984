import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-add-manufacturer-suppliers',
  templateUrl: './add-manufacturer-suppliers.component.html',
  styleUrls: [ './add-manufacturer-suppliers.component.scss' ]
})
export class AddManufacturerSuppliersComponent {
  formGroup: FormGroup;
  name: string = '';
  typeId: any = '';
  marketTypeConfig = {
    placeHolder: 'select_type',
    label: 'select_type',
    name: 'type',
    labelKey: 'name',
    valueKey: 'id',
    multiple: false,
    showRequiredLabel: true
  }
  /**
     * constructor
     * @param dialogRef 
     * @param data 
     */
  constructor(public dialogRef: MatDialogRef<AddManufacturerSuppliersComponent>,
      private monitorService: MonitorService,
      public dialog: MatDialog,
      private commonService: CommonService,
      private inventoryService: InventoryService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  /**
     * ngOnInit
     */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'name': new FormControl(''),
      'type': new FormControl(''),
    });
    this.formGroup.get('name').valueChanges.subscribe((v) => {
      this.name = v;
    })
    this.formGroup.get('type').valueChanges.subscribe((v) => {
      this.typeId = v;
    })
    if(this.data.isSupplierModule) { 
      this.data.typeList.shift()
    }
  }

  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('cancel', [ 'AddManufacturerSuppliersComponent', 'addenda-inventory' ]);
    this.dialogRef.close();
  }

  /**
   * submitForm
   */
  submitForm(): void {
    this.commonService.showLoading();
    const payload: any = {
      name: this.name
    }
    if(this.data.isSupplierModule) {
      payload.IsManufacturer = false;
      payload.MarketTypeId = this.typeId;
    }
    firstValueFrom(this.inventoryService.addManufacturer(payload)).then((resp) => {
      this.commonService.hideLoading();
      this.dialogRef.close(true)
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }
}

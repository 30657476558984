<div class="repair-intimation-request">
    <div class="header">
        <h3>{{'claim_details' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content">
        <div class="row input-row">
            <div class="col-12 details">
                <label>{{'customer_name' | translate}}</label>
                <span>{{data?.CustomerDetails?.FirstName}} {{data?.CustomerDetails?.LastName}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12 details">
                <label>{{'claimant_type' | translate}}</label>
                <span>{{data?.CustomerDetails?.ClaimType}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-6 details">
                <label>{{'policy_number' | translate}}</label>
                <span>{{data?.CustomerDetails?.PolicyNo}}</span>
            </div>
            <div class="col-6 details">
                <label>{{'loss_date' | translate}}</label>
                <span>{{data?.CustomerDetails?.LossDate | date:'dd/MM/YYYY'}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-6 details">
                <label>{{'make' | translate}}</label>
                <span>{{data?.VehicleMetaData?.Make}}</span>
            </div>
            <div class="col-6 details">
                <label>{{'model' | translate}}</label>
                <span>{{data?.VehicleMetaData?.Model}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-3 details">
                <label>{{'code' | translate}}</label>
                <span>{{data?.CustomerDetails?.CountryCode}}</span>
            </div>
            <div class="col-6 details">
                <label>{{'mobile_number' | translate}}</label>
                <span>{{data?.CustomerDetails?.MobileNumber}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12 details">
                <label>{{'police_reference_number' | translate}}</label>
                <span>{{data?.CustomerDetails?.PoliceReferenceNumber}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12 details">
                <label>{{'repair_number' | translate}}</label>
                <span>{{data?.RepairNumber}}</span>
            </div>
        </div>
        <div class="row input-row">
            <div class="col-12 d-flex" style="justify-content: center;gap: 10px">
                <div *ngIf="!approveRejectCompleted" class="col-6 full-width" (click)="rejectForm()"><xa-button type="outline" color="gray">{{'reject' | translate}}</xa-button></div>
                <div *ngIf="!approveRejectCompleted" class="col-6 full-width" (click)="approveForm()"><xa-button >{{'accept' | translate}}</xa-button></div>
                <div *ngIf="showApprovedOrRejected === 'Approved'" class="col-6 full-width"><xa-button (click)="viewIntimation()">{{'view_intimation' | translate}}</xa-button></div>
                <span class="text-danger" *ngIf="showApprovedOrRejected === 'Rejected'">{{'status' | translate}} : {{ 'rejected' | translate}}</span>
            </div>
        </div>
    </div>
</div>

export const ClaimALLTableConfig = {
  columns: [
    {
      id: 'sno',
      width: '60px',
      header: {
        text: 'sno',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'sno',
      },
      widget: {
        type: 'text',
        /*config: {
                style: 'color: #3B82F6; cursor: pointer;',
              }*/
      }
    },
    {
      id: 'ClaimGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'ClaimGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'ClaimId',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimId',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'CustomerName',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'CustomerName',
      },
      header: {
        text: 'customer_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'ClaimNo',
      sortable: true,
      width: 'auto',
      header: {
        text: 'claim_no',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'ClaimNo',
      },
      widget: {
        type: 'text',
        /*config: {
                style: 'color: #3B82F6; cursor: pointer;',
              }*/
      }
    },
    {
      id: 'FnolId',
      sortable: true,
      width: '100px',
      selector: {
        field: 'FnolId',
      },
      header: {
        text: 'fnol_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'PolicyNo',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'PolicyNo',
      },
      header: {
        text: 'policy_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleName',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'VehicleName',
      },
      header: {
        text: 'vehicle_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleNumber',
      sortable: true,
      width: '100px',
      selector: {
        field: 'VehicleNumber',
      },
      header: {
        text: 'vehicle_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'CreatedDate',
      sortable: true,
      width: '105px',
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'date',
      },
    },
    {
      id: 'Status',
      sortable: true,
      width: '180px',
      selector: {
        field: 'Status',
      },
      header: {
        text: 'status',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'status'
      }
    },
    {
      id: 'action',
      width: '0px',
      style: 'display: none',
      selector: {
        field: 'mode',
      },
      header: {
        text: 'action',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      widget: {
        type: 'action',
        config: {
          svgIcon: 'edit',
          style: 'fill: var(--xa-black) !important'
        }
      },
    },
    {
      id: 'PoliceReferenceNo',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'PoliceReferenceNo',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'PoliceReferenceNo',
      },
      widget: {
        type: 'text'
      }
    },
  ]
};

export const ClaimAssignedTableConfig = {
  columns: [
    {
      id: 'sno',
      width: '60px',
      header: {
        text: 'sno',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'sno',
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
    {
      id: 'ClaimGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'ClaimGuid',
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
    {
      id: 'CustomerName',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'CustomerName',
      },
      header: {
        text: 'customer_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'ClaimNo',
      sortable: true,
      width: 'auto',
      header: {
        text: 'claim_no',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'ClaimNo',
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
    {
      id: 'FnolId',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'FnolId',
      },
      header: {
        text: 'fnol_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'PolicyNo',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'PolicyNo',
      },
      header: {
        text: 'policy_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleName',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'VehicleName',
      },
      header: {
        text: 'vehicle_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'VehicleNumber',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'VehicleNumber',
      },
      header: {
        text: 'vehicle_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'CreatedDate',
      sortable: true,
      width: '105px',
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'date',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'Severity',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'Severity',
      },
      header: {
        text: 'damage_severity',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'Status',
      sortable: true,
      width: '180px',
      selector: {
        field: 'Status',
      },
      header: {
        text: 'status',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'status',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
    {
      id: 'PoliceReferenceNo',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'PoliceReferenceNo',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'PoliceReferenceNo',
      },
      widget: {
        type: 'text'
      }
    },
  ]
};


export const ClaimDashboardTableConfig = {
  columnSpacing: '0px',
  columns: [
    {
      id: 'ClaimGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'ClaimGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'ClaimNo',
      sortable: false,
      style: 'padding-inline-start: 0px !important;',
      header: {
        text: 'claim_no',
        tooltip: '',
        style: 'padding-inline-start: 10px;'
      },
      selector: {
        field: 'ClaimNo',
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
    {
      id: 'FnolId',
      sortable: false,
      selector: {
        field: 'FnolId',
      },
      header: {
        text: 'fnol_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'PolicyNo',
      sortable: false,
      selector: {
        field: 'PolicyNo',
      },
      header: {
        text: 'policy_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'Vehicle(Make, Model, License Plate)',
      sortable: false,
      width: '139px',
      selector: {
        field: 'VehicleName',
      },
      header: {
        text: 'vehicle_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'CreatedDate',
      sortable: false,
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'date',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      },
    },
    {
      id: 'Status',
      sortable: false,
      selector: {
        field: 'Status',
      },
      header: {
        text: 'status',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'status',
        config: {
          style: 'padding-inline-start: 5px;',
        }
      }
    },
  ]
}

export const ClaimAGarageTableConfig = {
  columns: [
    {
      id: 'sno',
      width: '60px',
      header: {
        text: 'sno',
        tooltip: '',
      },
      selector: {
        field: 'sno',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'ClaimGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'ClaimGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'ClaimId',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'ClaimId',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'ClaimNo',
      sortable: true,
      width: 'auto',
      header: {
        text: 'claim_no',
        tooltip: '',
      },
      selector: {
        field: 'ClaimNo',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'ApprovedClaimAmount',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'ApprovedClaimAmount',
      },
      header: {
        text: 'Approved Claim Amount',
        tooltip: '',
      },
      widget: {
        type: 'currency',
      },
    },
    {
      id: 'Status',
      sortable: true,
      selector: {
        field: 'Status',
      },
      header: {
        text: 'status',
        tooltip: '',
      },
      widget: {
        type: 'html'
      }
    },
    {
      id: 'InvoicedAmount',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'InvoicedAmount',
      },
      header: {
        text: 'Invoiced Amount',
        tooltip: '',
      },
      widget: {
        type: 'currency',
      },
    },
    {
      id: 'VehicleName',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'VehicleName',
      },
      header: {
        text: 'Vehicle',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'PhoneNumber',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'PhoneNumber',
      },
      header: {
        text: 'Phone number',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'ExcessPayable',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'ExcessPayable',
      },
      header: {
        text: 'Excess payable',
        tooltip: '',
      },
      widget: {
        type: 'currency',
      },
    },
    {
      id: 'DamagesSeverity',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'DamagesSeverity',
      },
      header: {
        text: 'Damage severity',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Action',
      sortable: false,
      width: '150px',
      selector: {
        field: 'Action',
      },
      header: {
        text: 'Action',
        tooltip: '',
      },
      widget: {
        type: 'html'
      },
    },
  ]
};

export const ClaimIntimationTableConfig = {
  columns: [
    {
      id: 'sno',
      width: '60px',
      header: {
        text: 'sno',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'sno',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'FnolGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'FnolGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'FnolGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'IntimationId',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'IntimationId',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'CustomerName',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'CustomerName',
      },
      header: {
        text: 'customer_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Reference',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'Reference',
      },
      header: {
        text: 'fnol_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
      cellStyle: 'text-decoration: underline;color: var(--xa-primary);',
    },
    {
      id: 'Source',
      sortable: true,
      width: 'auto',
      selector: {
        field: 'Source',
      },
      header: {
        text: 'source',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'IntimationDate',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'SubmittedDate',
      },
      header: {
        text: 'intimation_date',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'date',
      },
      cellStyle: 'text-decoration: underline;color: var(--xa-primary);',
    },
    {
      id: 'IntimatorType',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'SubIntimationType',
      },
      header: {
        text: 'intimator_type',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleName',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'VehicleName',
      },
      header: {
        text: 'vehicle_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'VehicleNumber',
      sortable: false,
      width: '100px',
      selector: {
        field: 'VehiclePlateNumber',
      },
      header: {
        text: 'vehicle_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'CreatedDate',
      sortable: false,
      width: '105px',
      selector: {
        field: 'CreatedDate',
      },
      header: {
        text: 'creation_date',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'date',
      },
    },
    // {
    //   id: 'AssigneeName',
    //   sortable: false,
    //   width: '220px',
    //   selector: {
    //     field: 'dropDownModel',
    //   },
    //   header: {
    //     text: 'assigned',
    //     tooltip: '',
    //     style: 'padding-inline-start: 5px;'
    //   },
    //   widget: {
    //     type: 'dropdown',
    //     config: {
    //       style: 'width: 100%;'
    //     }
    //   },
    // },
    {
      id: 'FNOLStatus',
      sortable: false,
      selector: {
        field: 'Status',
      },
      header: {
        text: 'status',
      },
      widget: {
        type: 'text'
      },
      cellStyle: 'color: var(--xa-repair-header);',
      width: '70px',
    }
  ]
};

export const ClaimV2GarageListTableConfig = {
  columns: [
    {
      id: 'sno',
      width: '60px',
      header: {
        text: 'sno',
        tooltip: '',
      },
      selector: {
        field: 'sno',
      },
      widget: {
        type: 'text',
      }
    },
    // {
    //   id: 'GarageGuid',
    //   width: '0px',
    //   style: 'display: none',
    //   header: {
    //     text: 'GarageGuid',
    //     tooltip: '',
    //     style: 'display: none; max-width: 0'
    //   },
    //   cellStyle: 'display: none; max-width: 0',
    //   selector: {
    //     field: 'GarageGuid',
    //   },
    //   widget: {
    //     type: 'text'
    //   }
    // },
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'GarageId',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'GarageStatus',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'GarageStatus',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Status',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'LogoUrl',
      sortable: false,
      width: '70px',
      header: {
        text: 'logo',
        tooltip: '',
      },
      selector: {
        field: 'LogoUrl',
      },
      widget: {
        type: 'logo',
      }
    },
    {
      id: 'Name',
      sortable: true,
      width: '210px',
      selector: {
        field: 'NameHtml',
      },
      header: {
        text: 'garage_name',
        tooltip: '',
      },
      widget: {
        type: 'html',
      },
    },
    {
      id: 'Phone',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'GarageMobileNumber',
      },
      header: {
        text: 'Phone number',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Type',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Type',
      },
      header: {
        text: 'type',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Address',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Address',
      },
      header: {
        text: 'address',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    }, 
    {
      id: 'Partnership',
      sortable: false,
      width: '140px',
      selector: {
        field: 'Partnership',
      },
      header: {
        text: 'Partnership',
        tooltip: '',
      },
      widget: {
        type: 'html',
      },
    }, 
    {
      id: 'GarageAverageRating',
      sortable: false,
      width: '70px',
      selector: {
        field: 'GarageAverageRating',
      },
      header: {
        text: 'garage_rating',
        tooltip: '',
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Action',
      sortable: false,
      width: '100px',
      selector: {
        field: 'Action',
      },
      header: {
        text: 'Action',
        tooltip: '',
      },
      widget: {
        type: 'html'
      },
    },
  ]
};

export const ClaimBeneficiariesTableConfig = {
  columns: [
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'Id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'BeneficiaryDetailGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'BeneficiaryDetailGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'BeneficiaryDetailGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'BeneficiaryName',
      sortable: false,
      width: 'auto',
      header: {
        text: 'beneficiary_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'BeneficiaryName',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'Type',
      sortable: false,
      width: '100px',
      selector: {
        field: 'BeneficiaryType',
      },
      header: {
        text: 'type',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'PhoneNumber',
      sortable: false,
      width: '150px',
      selector: {
        field: 'PhoneNumber',
      },
      header: {
        text: 'phone_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Email',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Email',
      },
      header: {
        text: 'email_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Address',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Address',
      },
      header: {
        text: 'address',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'SelectAction',
      width: '60px',
      sortable: false,
      style: 'border-right: 0',
      selector: {
        field: 'BeneficiaryDetailGuid',
      },
      header: {
        text: 'Action',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
        //style: 'visibility: hidden'
      },
      cellStyle: 'justify-content: center; border-right: 0',
      widget: {
        type: 'icon',
        config: {
          text: 'Select',
          svgIcon: '',
          style: 'text-decoration: underline; color: var(--xa-primary)'
        }
      },
    },
    {
      id: 'EditAction',
      width: '60px',
      sortable: false,
      style: 'border-right: 0',
      selector: {
        field: 'BeneficiaryDetailGuid',
      },
      header: {
        text: '',
        tooltip: '',
       
        style: 'visibility: hidden'
      },
      cellStyle: 'justify-content: center; border-right: 0',
      widget: {
        type: 'icon',
        config: {
          svgIcon: 'edit',
          style: 'width: 18px; height: 18px;'
        }
      },
    },
    {
      id: 'DeleteAction',
      width: '60px',
      sortable: false,
      selector: {
        field: 'BeneficiaryDetailGuid',
      },
      header: {
        text: '',
        tooltip: '',
        style: 'visibility: hidden'
      },
      cellStyle: 'justify-content: center',
      widget: {
        type: 'icon',
        config: {
          svgIcon: 'delete',
          style: 'fill: var(--xa-dark-red2) !important; width: 15px; height: 15px;'
        }
      },
    }
  ]
}

export const ClaimGarageBeneficiariesTableConfig = {
  columns: [
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'Id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'Id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'GarageGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'GarageGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'GarageGuid',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'GarageName',
      sortable: false,
      width: 'auto',
      header: {
        text: 'garage_name',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      selector: {
        field: 'Name',
      },
      widget: {
        type: 'text',
      }
    },
    {
      id: 'Type',
      sortable: false,
      width: '100px',
      selector: {
        field: 'Type',
      },
      header: {
        text: 'type',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'PhoneNumber',
      sortable: false,
      width: '150px',
      selector: {
        field: 'PhoneNumber',
      },
      header: {
        text: 'phone_number',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Email',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Email',
      },
      header: {
        text: 'email_id',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'Address',
      sortable: false,
      width: 'auto',
      selector: {
        field: 'Address',
      },
      header: {
        text: 'address',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      widget: {
        type: 'text',
      },
    },
    {
      id: 'SelectAction',
      width: '60px',
      sortable: false,
      style: 'border-right: 0',
      selector: {
        field: 'Id',
      },
      header: {
        text: 'Action',
        tooltip: '',
        style: 'padding-inline-start: 5px;'
      },
      cellStyle: 'justify-content: center; border-right: 0',
      widget: {
        type: 'icon',
        config: {
          text: 'Select',
          svgIcon: '',
          style: 'text-decoration: underline; color: var(--xa-primary)'
        }
      },
    }
  ]
}
import { MsalService } from '@azure/msal-angular';
import { ConfigurationService } from './service/configuration.service';

/**
 *
 * @param provider
 * @param msalBroadcastService
 * @param translate
 * @returns
 */
export function appConfigFactory(
  configurationService: ConfigurationService,
  msalService: MsalService
): () => Promise<any> {
  return () => {
    return new Promise((resolve) => {
      msalService.handleRedirectObservable().subscribe({
        next: async () => {
          if (
            !window.location.pathname.includes('/landing') 
            && !window.location.pathname.includes('/quote-assessment') 
            && !window.location.pathname.includes('/verify-otp') 
            && !window.location.pathname.includes('/claim/assignment') 
            && !window.location.pathname.includes('/claim/estimate') 
            && !window.location.pathname.includes('/claim/invoice') 
            && !window.location.pathname.includes('/feedback') 
            && !window.location.pathname.includes('/approval')) {
            await configurationService?.validateAndPullConfigFile();
          }
          resolve(true);
        },
        error: (err) => {
          console.log(err);
          resolve(true);
        }
      });
    });
  }
}

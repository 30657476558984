<section class="parts-container">
  <span class="title">Additional parts</span>
  <span class="sub-title">Do you want to include the additional cost for the adjacent parts along with
    the {{data.name}}?</span>

  <div class="blend-parts-list">

    <ng-container *ngFor="let item of blendParts">
      <div *ngFor="let op of item.operationTypes"
           class="part-row">
        <div class="col-1">
          <xa-checkbox [(ngModel)]="op.checked"
                       [name]="item.partName"> </xa-checkbox>
          <span class="blend-name">{{item.partName}}</span>
        </div>
        <div class="col-1">
          <span class="operation-name">{{operationMap[op?.repairOperationType]}}</span>
          <xa-numeric *ngIf="data.estimationMode === 1"
                      [(ngModel)]="op.labourTime"
                      class="amount-input"
                      [min]="0"
                      [max]="99.99"
                      textAddon=" "> </xa-numeric>
          <xa-numeric *ngIf="data.estimationMode === 2"
                      [(ngModel)]="op.labourAmount"
                      class="amount-input"
                      [min]="0"
                      [max]="999999"
                      textAddon=" "> </xa-numeric>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="btn-wrapper">
    <xa-button (click)="close()"
               type="outline">Cancel</xa-button>
    <xa-button [disabled]="getSelected(blendParts)?.length === 0 && this.data?.additionalParts?.length === 0" (click)="addBlendParts()"
               color="primary">Okay</xa-button>
  </div>
</section>

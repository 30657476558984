<div class="form-field-text">
  <label *ngIf="label"
         class="form-label pointer-events"
         for="inputId">
    {{label | translate}}
    <span class="required"
          *ngIf="required && label">*</span>
    <mat-icon *ngIf="hint"
              [svgIcon]="'hint'"
              style="width:12px;height:12px;margin-left:5px;"
              [matTooltip]="hint">

    </mat-icon>
  </label>
  <div class="text-field">
    <div class="unit"
         *ngFor="let val of valueArr;let i=index;">
      <input class="form-value"
             autocomplete="off"
             #input
             [id]="inputId + '_'+ i"
             [name]="nameId  + '_'+ i"
             placeholder="{{placeholder || label | translate}}"
             [readonly]="readonly"
             [disabled]="disabled"
             [required]="required"
             onkeydown="javascript: return (['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.key) || ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code)) ? true : !(isNaN(Number(event.key)) && event.key !== '.') && (event.key!=='Space' || event.code!=='Space')"
             [value]="valueArr[i]"
             [max]="max"
             (keydown.space)="$event.preventDefault();"
             (keyup.space)="$event.preventDefault();"
             [attr.maxlength]="blockMaxLength ? maxLength : null"
             [attr.minlength]="minLength"
             (keyup)="_onInputInteraction($event, i);onTouched()" />
      <span class="x"
            *ngIf="i < (valueArr?.length - 1)">x</span>
    </div>
    <ng-content></ng-content>
  </div>
</div>
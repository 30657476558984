<div class="request-missing-info-container">
    <div class="header">
        <h3>{{'request_missing_information' | translate }}</h3>
        <div class="close-icon"(click)="close();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div class="content gap-23">
        <chq-input class="checkbox-label" [inputModel]="missingInfoModel"
            (controlOutput)="onFormUpdate($event)"></chq-input>
        <chq-input class="checkbox-label" [inputModel]="missingInfoFieldsModel"
            (controlOutput)="onFormUpdate($event)"></chq-input>
            <ng-container *ngIf="missingInfoFieldsModel.selectedOption.length > 0">
                <div class="tag-wrapper">
                    <span class="tag-tile" *ngFor="let data of missingInfoFieldsModel.selectedOption; let ind=index;">
                        <span class="tag-label">{{data.name}}</span>
                        <mat-icon [svgIcon]="'cross'" (click)="removeMissingDataField(data, ind);"></mat-icon>
                    </span>
                </div>
            </ng-container>
        <chq-input [inputModel]="notesModel" (controlOutput)="onFormUpdate($event);" tabindex="-1"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="cancelButton" class="left"></chq-button>
        <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
</div>
<mat-tree [dataSource]="dataSource"
          *ngIf="dataSource?.data?.length > 0"
          [treeControl]="treeControl">
    <mat-tree-node class="tree-group-node"
                   (click)="nodeClick(node)"
                   *matTreeNodeDef="let node"
                   [attr.id]="node.guid"
                   [class.selected]="node === selectedNode"
                   matTreeNodeToggle>
        <div class="fx-row"
             style="width: 100%;">
            <div style="align-self: center;"
                 [ngClass]="multipleInput ? 'node-title' : ''">
                {{node.name}}
            </div>
            <div class="tree-text"
                 *ngIf="multipleInput">
                <xa-numeric class="threshold-input"
                            [(ngModel)]="node.value"
                            (ngModelChange)="updateNodeValue(node, $event)"
                            [name]="node.name"
                            [minlength]="1"
                            [min]="0"
                            [max]="9999"
                            #child="ngModel"></xa-numeric>
            </div>
        </div>


    </mat-tree-node>

    <mat-nested-tree-node [attr.id]="node.guid"
                          *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node"
             [ngClass]="multipleInput ? 'node-header' : ''">
            <xa-button type="basic"
                       size="xs"
                       class="tree-button"
                       color="black"
                       (click)="treeControl.isExpanded(node) ? activeCategoryName = node.name: ''"
                       matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror"
                          [svgIcon]="treeControl.isExpanded(node) ? 'minus-square' : 'plus-square'">

                </mat-icon>
            </xa-button>
            <div class="fx-row parent-row">
                <div class="align-self-center"
                     [ngClass]="multipleInput ? 'node-title' : ''">
                    {{node.name}}
                </div>
                <div class="tree-text"
                     *ngIf="multipleInput">
                    <xa-numeric class="threshold-input"
                                [(ngModel)]="node.value"
                                [name]="node.name"
                                [minlength]="1"
                                [min]="0"
                                [max]="9999"
                                (ngModelChange)="updateNodeValue(node, $event)"
                                #parent="ngModel"></xa-numeric>
                </div>
                <div class="apply-checkbox"
                     *ngIf="multipleInput">
                    <xa-checkbox (ngModelChange)="applyAllRadio($event, node)"
                                 [(ngModel)]="node.applyAll"
                                 #parentCheckbox
                                 [attr.data-id]="'parentCheckbox-' + node.id"
                                 [distinctId]="node.id"
                                 [required]=false>{{ 'apply_all' | translate}}</xa-checkbox>
                </div>
            </div>
        </div>
        <div class="tree-btn"
             *ngIf="treeControl.isExpanded(node) && showNewBtn && node.children?.length <= 100">
            <xa-button type="outline-radius"
                       size="sm"
                       (click)="addNewGroup(node.guid);"
                       color="black">
                <mat-icon svgIcon="plus-filled"></mat-icon>
                {{'add_group' | translate}}</xa-button>
        </div>

        <div [class.tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>


    </mat-nested-tree-node>
</mat-tree>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-additional-parts-dialog',
  templateUrl: './additional-parts-dialog.component.html',
  styleUrls: [ './additional-parts-dialog.component.scss' ]
})
export class AdditionalPartsDialogComponent implements OnInit {
  /**
   * Creates an instance of AdditionalPartsDialogComponent.
   *
   * @constructor
   * @param {*} data
   * @param {MatDialogRef<AdditionalPartsDialogComponent>} dialogRef
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdditionalPartsDialogComponent>
  ) { }

  blendParts = [];
  transformedData = [];
  operationMap = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'R+R': 'MET',
    'RemoveAndRefit': 'MET',
    'Paint': 'Paint'
  }

  /** Description placeholder */
  ngOnInit(): void {
    this.data.blendPartOperations = this.data.blendPartOperations?.map((el) => {
      return { ...el, operationTypes: [] }
    })
    this.transformedData = this.data.blendPartOperations?.reduce((acc, item) => {
      // Check if the partName already exists in the accumulator
      const existingPart = acc.find(part => part.partName === item.partName);

      // If the partName does not exist, create a new entry
      if (!existingPart) {
        acc.push({
          partName: item.partName,
          id: item.id,
          bodyTypeId: item.bodyTypeId,
          partId: item.partId,
          operationTypes: [ {
            repairOperationType: item.repairOperationType,
            repairOperationTypeId: item.repairOperationTypeId,
            labourTime: item.labourTime,
            labourAmount: item.labourAmount,
          } ]
        });
      } else {
        // If it exists, push the new operation into the existing operations array
        existingPart.operationTypes.push({
          repairOperationType: item.repairOperationType,
          repairOperationTypeId: item.repairOperationTypeId,
          labourTime: item.labourTime,
          labourAmount: item.labourAmount,
        });
      }
      return acc;
    }, []);
    if (this.data?.additionalParts?.length > 0) {
      this.data.additionalParts.forEach((additionalPart) => {
        const partId = additionalPart?.addendaPartMasterId


        this.transformedData.forEach((blendPart) => {
          if (blendPart.id === partId) {
            // Patch values from additionalParts into blendPartOperations
            blendPart.estimateChargeId = additionalPart?.estimateChargeId || null;
            blendPart.operationId = additionalPart?.operationId || null;
            blendPart.code = additionalPart?.code || '';
            blendPart.operationTypes = blendPart.operationTypes.map((blendOperation) => {
              // Find matching operation from additionalPart based on repairOperationTypeId
              const matchingOperation = additionalPart.operationTypes.find(
                additionalOperation =>
                  additionalOperation.repairOperationType.includes(this.data.operationNameMap[blendOperation.repairOperationType])
              );
              if (matchingOperation) {
                // Merge and add checked: true for matched operation
                return {
                  ...blendOperation,
                  ...matchingOperation,
                  repairOperationType: blendOperation.repairOperationType,
                  checked: true
                };
              }
              // If no match, return the original blendOperation without checked: true
              return blendOperation;
            });
          }
        });
      });
    }
    this.blendParts = this.data.paint ? this.transformedData : this.transformedData?.map((part) => {
      // Filter out operationTypes where repairOperationType is "Paint"
      const filteredOperationTypes = part.operationTypes.filter(operation => operation.repairOperationType !== 'Paint');

      // Return a new part object if it has at least one operationType remaining
      if (filteredOperationTypes.length > 0) {
        return {
          ...part,
          operationTypes: filteredOperationTypes
        };
      }
    })
      .filter(Boolean)
  }


  /**
   * Description placeholder
   *
   * @param {*} parts
   * @returns {*}
   */
  getSelected(parts:any):any {
    return parts
      ?.map(part => ({
        ...part,
        operationTypes: part.operationTypes?.filter(operation => operation.checked) // Filter out operations without checked: true
      }))
      ?.filter(part => part.operationTypes?.length > 0); // Remove parts with empty operationTypes

  }

  /** Description placeholder */
  close(): void {
    const returnData = null
    this.dialogRef.close(returnData)
  }

  /** Description placeholder */
  addBlendParts(): void {
    const returnData = this.getSelected(this.blendParts)
    this.dialogRef.close(returnData)
  }
}

<div class="camera-content-root"
     [ngClass]="{'show-cam':showCamera}">
    <div class="close"
         [ngClass]="{ 'lock-screen':getOrientationLock, 'ios': platform.IOS}"
         *ngIf="image || showCamera">
        <progress-check [tracks]="stepNames"
                        #progress></progress-check>
        <span *ngIf="displaySkip"
              class="skip-camera"
              (click)="updateSkip();">{{skipText | translate}}</span>
    </div>
    <div class="camera-wrapper"
         [ngClass]="{'show-cam':showCamera || image, 'lock-screen':getOrientationLock, 'and': platform.ANDROID, 'ios': platform.IOS}">
        <div class="camera-container"
             [hidden]="!showCamera">
            <video #videoElement
                   autoplay
                   [ngClass]="{'ios' : platform.IOS}"
                   playsinline>
            </video>
            <div class="text-container">
                <div class="procedure"><span>{{procedureStep}}</span>
                </div>
            </div>
            <ng-container *ngIf="showCamera">
                <xa-camera-overlay [vehicleModel]="outlineUrl"
                                   [procedureStep]="currentStep"
                                   [forceOrientation]="forceOrientation"
                                   #overlayElement></xa-camera-overlay>
            </ng-container>
        </div>
        <div class="shutter-container"
             *ngIf="showCamera && !processing">
            <mat-icon (click)="captureImage()"
                      svgIcon="shutter"></mat-icon>
            <span>{{'Photo' | translate}}</span>
        </div>
        <div class="loading"
             *ngIf="processing">
            <span>{{'Processing...' | translate}}</span>
        </div>

        <div class="image-container"
             *ngIf="image && !showCamera">
            <img class="image-captured"
                 [src]="image"
                 alt="img" />
            <div class="actions">
                <xa-button type="outline"
                           (click)="startCamera();">Retake</xa-button>
                <xa-button (click)="emitUpload();">Upload</xa-button>
            </div>
        </div>
    </div>
    <div class="bottom-bar"
         [ngClass]="{ 'lock-screen':getOrientationLock, 'ios': platform.IOS}"
         *ngIf="(image || showCamera) && currentText">
        <span [innerHTML]="currentText">
        </span>
    </div>
</div>
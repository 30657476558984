<div class="loading" *ngIf="isPDFLoading ">
    <div class="overlay"></div>
    <div class="spinner">
        <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
    </div>
</div>
<div class="fraud-detection-container fx-col">
    <div class="fraud-detection-header fx-row justify-space-between">
        <div class="header-1 fx-row">
            <div class="header fx-col header-div-big">
                <div>
                    {{'correlation_report' | translate }}
                </div>
                <div *ngIf="data.type === 'claim'">
                    {{'claim' | translate }} #{{ this.claimantData?.claimNo }}
                </div>
                <div *ngIf="data.type === 'case'">
                    {{'Case' | translate }} #{{ this.claimantData?.caseNo }}
                </div>
            </div>
            <div class="header fx-col header-div-small" *ngIf="data.type === 'case'">
                <div>
                    {{'quote_id' | translate }}
                </div>
                <div>
                    {{ this.claimantData?.quoteNo }}
                </div>
            </div>
            <div class="header fx-col header-div-small">
                <div>
                    {{'date' | translate }}
                </div>
                <div>
                    {{ dateToday }}
                </div>
            </div>
        </div>
        <div class="header-2 fx-row">
            <div>
                <mat-icon [svgIcon]="'cross'" (click)="onClose()"></mat-icon>
            </div>
        </div>
    </div>
    <div class="tab-content" *ngIf="data.showTab">
        <xa-tabs [tabPosition]="'top'"
                    (tabChanged)="changeTab($event)"
                    [mode]="'underline'"
                    [hideContent]="true">
                <xa-tab *ngFor="let item of userListMenus"
                        tabTitle="{{item.displayName}}"
                        [active]="item?.active"
                        [disabled]="item?.disabled"></xa-tab>
            </xa-tabs>
    </div>
    <div class="fraud-detection-body">
        <div class="claimant-details-wrapper">
            <div class="claimant-details-header fx-row">
                <div class="claimant-details-header-text">
                    {{'claimant_details' | translate }}
                </div>
                <div class="case-status">
                    <div class="status" *ngIf="data.type === 'case'">{{ this.claimantData?.caseStatus }}</div>
                    <div class="status" *ngIf="data.type === 'claim'">{{ this.claimDetails?.claimStatus }}</div>
                </div>
            </div>
            <div class="claimant-details-body fx-row">
                <div class="claimant-details fx-col claimant-details-left">
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'claimant_name' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value claimant-name" *ngIf="data.type === 'claim'">{{ claimDetails?.customerDetails?.policyHolderFirstName }}
                                {{claimDetails?.customerDetails?.policyHolderLastName }}</div>
                                <div class="claimant-value claimant-name" *ngIf="data.type === 'case'">{{ this.claimantData?.driverDetails?.firstName }}
                                    {{this.claimantData?.driverDetails?.lastName }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'case'">
                        <div class="claimant-property">{{ 'creation_date' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ (this.claimantData?.createdDate?.toString() | date: 'dd-MM-yyyy') || '-' }}
                            </div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'date_of_notification' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ (claimDetails?.customerDetails?.claimIntimationDate | date: 'dd-MM-yyyy') || '-' }}
                            </div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'loss_date' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ claimDetails?.lossDetails?.lossDate | date: 'dd-MM-yyyy' }}
                            </div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'place_of_loss' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">
                                {{ claimDetails?.lossDetails?.lossLocation || '-' }}
                            </div>
                            <div class="empty"></div>
                        </div>

                    </div>
                    <div class="fx-row">
                        <div class="claimant-property"  *ngIf="data.type === 'claim'">{{ 'claim_status' | translate }}</div>
                        <div class="claimant-property"  *ngIf="data.type === 'case'">{{ 'case_status' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.claimStatus || this.claimantData?.caseStatus }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                </div>
                <div class="claimant-details fx-col claimant-details-right">
                    <div class="fx-row" *ngIf="data.type === 'case'">
                        <div class="claimant-property">{{ 'quote_id' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ this.claimantData?.quoteNo || '-' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'policy_number' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyNo || '-' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'address' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ claimDetails?.customerDetails?.policyHolderAddress1 || '-' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row" *ngIf="data.type === 'claim'">
                        <div class="claimant-property">{{ 'birth_date' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value">{{ (claimDetails?.customerDetails?.dateOfBirth | date: 'dd-MM-yyyy') || '-' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'phone_number' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value" *ngIf="data.type ==='claim'">{{ claimDetails?.customerDetails?.policyMobileCountryCode }} {{
                                claimDetails?.customerDetails?.policyHolderMobileNumber }}</div>
                            <div class="claimant-value" *ngIf="data.type ==='case'">{{ this.claimantData?.driverDetails?.countryCode }} {{
                                this.claimantData?.driverDetails?.phoneNumber }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>
                    <div class="fx-row">
                        <div class="claimant-property">{{ 'email' | translate }}</div>
                        <div class="claimant-value-wrapper">
                            <div class="claimant-value" *ngIf="data.type ==='claim'">{{ claimDetails?.customerDetails?.policyHolderEmail || '-' }}</div>
                            <div class="claimant-value" *ngIf="data.type ==='case'"> {{ this.claimantData?.driverDetails?.email || '-' }}</div>
                            <div class="empty"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="other-details fx-row">
            <div class="vehicle-reasons">
                <div class="vehicle-details-wrapper">
                    <div class="vehicle-details-header fx-row">
                        <div>
                            {{ 'vehicle_detail' | translate }}
                        </div>
                    </div>
                    <div class="vehicle-details-body fx-row">
                        <div class="vehicle-details fx-col vehicle-details-border">
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'make' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value" *ngIf="data.type ==='claim'">{{ claimDetails?.vehicleMetaData?.make }}</div>
                                    <div class="vehicle-value" *ngIf="data.type ==='case'">{{ this.claimantData?.vehicleMetaData?.make }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'model' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value" *ngIf="data.type ==='claim'">{{ claimDetails?.vehicleMetaData?.model }}</div>
                                    <div class="vehicle-value" *ngIf="data.type ==='case'">{{ this.claimantData?.vehicleMetaData?.model }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-details fx-col pl-24 vehicle-details-right">
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'plate_reg_number' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value" *ngIf="data.type ==='claim'">{{ claimDetails?.vehicleMetaData?.plateNo }}</div>
                                    <div class="vehicle-value" *ngIf="data.type ==='case'">{{ this.claimantData?.vehicleMetaData?.plateNo }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                            <div class="fx-row">
                                <div class="vehicle-property">{{ 'chassis_vin' | translate }}</div>
                                <div class="vehicle-value-wrapper">
                                    <div class="vehicle-value" *ngIf="data.type ==='claim'">{{ claimDetails?.vehicleMetaData?.vinNum }}</div>
                                    <div class="vehicle-value" *ngIf="data.type ==='case'">{{ this.claimantData?.vehicleMetaData?.vinNum }}</div>
                                    <div class="empty"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="documents">
            <div class="document-details-wrapper">
                <div class="document-details-header fx-row">
                    <div class="document-details-header-text">
                        {{'documents' | translate }}
                    </div>
                </div>
                <div class="document-details-body fx-row">
                    <table>
                        <tr>
                            <th>{{'type' | translate }}</th>
                            <th> {{'make' | translate }}</th>
                            <th> {{'model' | translate }}</th>
                            <th> {{'plate_number' | translate }}</th>
                            <th> {{'vin_number' | translate }}</th> 
                        </tr>
                        <tr *ngFor="let item of documentCorrelation">
                            <td>
                                {{item.itemName}}
                            </td>
                            <td>
                                <mat-icon *ngIf="item.make !== null" [svgIcon]="item.make.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'make')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.make.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.make === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.model !== null" [svgIcon]="item.model.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'model')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.model.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.model === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.plateNumber !== null" [svgIcon]="item.plateNumber.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'plateNumber')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.plateNumber.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.plateNumber === null">-</span>
                            </td>
                           <td>
                                <mat-icon *ngIf="item.vin !== null" [svgIcon]="item.vin.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'vin')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.vin.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.vin.isMatch === null">-</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="documents" *ngIf="data.type ==='case'">
            <div class="document-details-wrapper">
                <div class="document-details-header fx-row">
                    <div class="document-details-header-text">
                        {{'document_vs_photos' | translate }}
                    </div>
                </div>
                <div class="document-details-body fx-row">
                    <table>
                        <tr>
                            <th> {{'document_vs_photos' | translate }}</th>
                            <th> {{'make' | translate }}</th>
                            <th> {{'model' | translate }}</th>
                            <th> {{'plate_number' | translate }}</th>
                            <th> {{'vin_number' | translate }}</th> 
                        </tr>
                        <tr *ngFor="let item of this.aiCorrelation">
                            <td>
                                {{item.itemName}}
                            </td>
                            <td>
                                <mat-icon *ngIf="item.make !== null" [svgIcon]="item.make.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'make', 'docVsPhoto')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.make.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.make === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.model !== null" [svgIcon]="item.model.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'model', 'docVsPhoto')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.model.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.model === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.plateNumber !== null" [svgIcon]="item.plateNumber.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'plateNumber', 'docVsPhoto')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.plateNumber.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.plateNumber === null">-</span>
                            </td>
                           <td>
                                <mat-icon *ngIf="item.vin !== null" [svgIcon]="item.vin.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'vin', 'docVsPhoto')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.vin.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.vin.isMatch === null">-</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="images">
            <div class="image-details-wrapper">
                <div class="image-details-header fx-row">
                    <div class="image-details-header-text">
                        {{'photos' | translate }}
                    </div>
                </div>
                <div class="image-details-body fx-row">
                    <table>
                        <tr>
                            <th>{{'image' | translate }}</th>
                            <th> {{'make' | translate }}</th>
                            <th> {{'model' | translate }}</th>
                            <th> {{'plate_number' | translate }}</th>
                        </tr>
                        <tr *ngFor="let item of imageCorrelation">
                            <td>
                                {{item.itemName}}
                            </td>
                            <td>
                                <mat-icon *ngIf="item.make !== null" [svgIcon]="item.make.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'make')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.make.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.make === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.model !== null" [svgIcon]="item.model.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'model')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.model.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.model === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.plateNumber !== null && (!['left side', 'leftside', 'right side','rightside'].includes(item.itemName?.toLowerCase()))" [svgIcon]="item.plateNumber.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'plateNumber')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.plateNumber.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="(item.plateNumber === null) || (['left side', 'leftside', 'right side','rightside'].includes(item.itemName?.toLowerCase()))">-</span>
                            </td>
                            <!-- <td>
                                <span>{{ item?.color?.result || '-' }}</span>
                                <span *ngIf="item?.color === null">-</span>
                            </td> -->
                        </tr>
                    </table>
                </div>
            </div>
            <div class="additional-details-wrapper" *ngIf="additionalCorrelation !== null && additionalCorrelation !== undefined">
                <div class="additional-details-header fx-row">
                    <div class="image-details-header-text">
                        {{'additional_photos' | translate }}
                    </div>
                </div>
                <div class="additional-details-body fx-row">
                    <table>
                        <tr>
                           <th>{{'image' | translate }}</th>
                           <th>{{'entered_value' | translate }}</th>
                           <th>{{'result' | translate }}</th>
                        </tr>
                        <tr *ngFor="let item of additionalCorrelation">
                            <td>{{ item.itemName | uppercase }}</td>
                            <td>{{ item?.item?.actual }}</td>
                            <td>

                                <mat-icon [svgIcon]="item?.item?.isMatch ? 'check' : 'cross'"
                                [ngClass]="item?.item?.isMatch  ? 'green' : 'red'"
                                [matTooltip]="getTooltipText(item, 'item')"
                                [matTooltipClass]="'custom-tooltip'">
                            </mat-icon>
                            </td>
                        </tr>
                        <tr *ngIf="(!additionalCorrelation || additionalCorrelation?.length === 0)">
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="video-details-wrapper" *ngIf="videoCorrelation !== null && videoCorrelation !== undefined">
                <div class="video-details-header fx-row">
                    <div class="video-details-header-text">
                        {{'videos' | translate }}
                    </div>
                </div>
                <div class="video-details-body fx-row">
                    <table>
                        <tr>
                            <th>{{'video' | translate }}</th>
                            <th> {{'make' | translate }}</th>
                            <th> {{'model' | translate }}</th>
                            <th> {{'plate_number' | translate }}</th>
                            <th> {{'vin_number' | translate }}</th> 
                            <th>{{'odometer' | translate }}</th>
                        </tr>
                        <tr *ngFor="let item of videoCorrelation">
                            <td>
                                {{item.itemName}}
                            </td>
                            <td>
                                <mat-icon *ngIf="item.make !== null" [svgIcon]="item.make.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'make')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.make.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.make === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.model !== null" [svgIcon]="item.model.isMatch ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'model')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.model.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.model === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.plateNumber !== null" [svgIcon]="item.plateNumber.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'plateNumber')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.plateNumber.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.plateNumber === null">-</span>
                            </td>
                            <!-- <td>
                                <span>{{ item?.color?.result || '-' }}</span>
                                <span *ngIf="item?.color === null">-</span>
                            </td> -->
                            <td>
                                <mat-icon *ngIf="item.vin !== null" [svgIcon]="item.vin.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'vin')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.vin.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.vin === null">-</span>
                            </td>
                            <td>
                                <mat-icon *ngIf="item.odometer !== null" [svgIcon]="item.odometer.isMatch  ? 'check' : 'cross'"
                                [matTooltip]="getTooltipText(item, 'odometer')"
                                [matTooltipClass]="'custom-tooltip'"
                                    [ngClass]="item.odometer.isMatch  ? 'green' : 'red'">
                                </mat-icon>
                                <span *ngIf="item.odometer === null">-</span>
                            </td>
                        </tr>
                        <tr *ngIf="(!videoCorrelation || videoCorrelation?.length === 0)">
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <!-- <td>
                                -
                            </td> -->
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="legends">
        <div class="legend-wrapper fx-row">
            <div><b>{{ 'legend' | translate }}:</b></div>
            <div class="fx-row icon-legend">

                <mat-icon [svgIcon]="'check'"
                                    [ngClass]="'green'">
                                </mat-icon>
                <div>{{ 'match' | translate }}</div>
            </div>
            <div class="fx-row icon-legend">

                <mat-icon [svgIcon]="'cross'"
                                    [ngClass]="'red'">
                                </mat-icon>
                <div>{{ 'mismatch' | translate }}</div>
            </div>
    </div>
</div>
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VideoPlayerComponent } from 'src/app/dialogs/video-player/video-player.component';
import { CommonService } from 'src/app/services/common/common.service';
import { VideoComponent } from 'src/app/widgets/video/video.component';

@Component({
  selector: 'video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: [ './video-uploader.component.scss' ]
})
export class VideoUploaderComponent implements OnChanges{
  @ViewChild('uploader') uploader: ElementRef;
  @Input() videoUrl: string = '';
  @Input() uploadStatus: string = 'Not Uploaded';
  @Input() maxFileSize: number = 100000000 * 2;
  @Input() status: string;
  @Input() mandatory: boolean;
  @Input() showInternalCamera:boolean;
  @Output() fileChanged: EventEmitter<{ 'file'?: File, 'action'?: string }> = new EventEmitter();
  @ViewChild('video') video: VideoComponent;
  localVideoUrl: string = '';
  selectedFile: File = null;
  serverVideoUrl: string = '';
  processingVideo: boolean = false;
  /**
   * constructor
   * @param commonService
   * @param dialog
   */
  constructor(
    private commonService: CommonService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {

  }

  /**
   * on change event
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['uploadStatus']?.currentValue === 'IsUploadFailed') {
      if(!this.showInternalCamera){
        this.selectedFile = null;
        this.localVideoUrl = '';
        this.uploader.nativeElement.value = null;
      }
    }
    if(changes['videoUrl']?.currentValue) {
      setTimeout(() => {
        this.serverVideoUrl = changes['videoUrl']?.currentValue;
      }, 10);
      
    }
  }

  /**
   * processing video
   */
  processingVideoHandler():void{
    this.processingVideo = true;
    this.localVideoUrl = '';
    this.serverVideoUrl = '';
    this.status = '';
  }

  /**
   * delete  video
   */
  delete(): void {    
    this.fileChanged.emit({ 'file': null, 'action': 'delete' });
    setTimeout(() => {
      this.localVideoUrl = '';
      this.serverVideoUrl = '';
      this.status = '';
    }, 10);
  }

  /**
   * OPEN VIDEO
   */
  openVideo():void{
    this.video.startCamera();
  }


  /**
   * handle file upload
   * @param event 
   */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.processingVideo = true;
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = this.translate.instant('video_size_limit');
        this.commonService.showToast(0, message);
        this.processingVideo = false;
        // this.uploader.nativeElement.value = null;
        return;
      }
      this.localVideoUrl = '';
      setTimeout(() => {
        this.serverVideoUrl = '';
        this.selectedFile = currentFile[0];
        this.localVideoUrl = URL.createObjectURL(this.selectedFile);
        this.processingVideo = false;
        if(!this.showInternalCamera){
          this.uploader.nativeElement.value = null;
        }
        this.fileChanged.emit({ 'file': this.selectedFile, 'action': 'add' });
      }, 10);
    }
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(file: Blob): boolean {
    if (file.size > this.maxFileSize) {
      return false;
    }
    return true;
  }
  
  /**
   * max mb
   */
  get maxMbAllowed(): number {
    return this.maxFileSize/(1000*1000);
  }
  /**
   * open video player
   */
  openVideoPlayer(): void {
    if(this.videoUrl || this.localVideoUrl) {
      this.dialog.open(VideoPlayerComponent, {
        data: {
          videoUrl: this.localVideoUrl || this.serverVideoUrl
        }, 
        autoFocus: false,
        height: '80vh',
        width: '80vw'
      });
    }
  }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  /**
     * constructor
     * @param http
     */
  constructor(private http: HttpClient) {
  }

  /**
   * get country list
   * @returns 
   */
  getCountryList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries`;
    return this.http.get(url);

  }
  
  /**
   * get region list
   * @param countryId 
   * @returns 
   */
  getRegionList(countryId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/${countryId}`;
    return this.http.get(url);
  }

  /**
   * get city List
   * @param regionId 
   * @returns 
   */
  getCityList(regionId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/city/${regionId}`;
    return this.http.get(url);
  }

  /**
   * get designation List
   * @returns 
   */
  getDesignationList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=DesignationType&isEnum=true`;
    return this.http.get(url);
  }

  /**
   * get product List
   * @returns 
   */
  getProductList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=Product&isEnum=true`;
    return this.http.get(url);
  }

  /**
   * get service List
   * @returns 
   */
  getServiceList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/AutomotiveServices/lookup/serviceType/Application`;
    return this.http.get(url);
  }

  /**
   * self registration
   * @param formData 
   * @returns 
   */
  selfRegistration(formData): Observable<any> {
    const url = `${environment.autoCoreApi}/api/SelfRegistration`;
    return this.http.post(url, formData)
  }

}

import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, Subscription } from 'rxjs';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { policyEnquiryFormV2 } from 'src/app/helper/form/policy-enquiry-helper-v2';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ExistingClaimDialogComponent } from '../existing-claim-dialog/existing-claim-dialog.component';
import { CustomerPolicyDetailsDialogComponent } from '../customer-policy-details-dialog/customer-policy-details-dialog.component';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-policy-authentication',
  templateUrl: './policy-authentication.component.html',
  styleUrls: [ './policy-authentication.component.scss' ]
})
export class PolicyAuthenticationComponent implements OnDestroy{
  submitFormGroup: FormGroup;
  controls: DynamicFormBase<any>[] | null = [];
  policyEnquiryFormControls: DynamicFormBase<any>[] | null;
  currentSearchValue: string = '';
  currentSelectedValue: string = '';
  customerDetailsPopupData: any;

  shouldOpenPopup: boolean = false;
  subscriptions: Subscription[] = [];

  userData: any;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dynamicFormService: DynamicFormService,
    private readonly claimsService: ClaimsService,
    private readonly commonService: CommonService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PolicyAuthenticationComponent>,
    private translateService: TranslateService
  ) {
    this.initializeDynamicForm()
  }


  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * initialize dynamic form
   */
  initializeDynamicForm(): void {
    const filteredControls = policyEnquiryFormV2.filter(ele => ele.key !== 'lossDateTime')
    this.controls = this.dynamicFormService.getSortedFormControls(filteredControls);
    this.submitFormGroup = this.dynamicFormService.createFormGroup(this.controls);

    this.submitFormGroup.get('selectAndSearchDetails').setValue('policyNumber');
    this.currentSelectedValue = this.submitFormGroup.get('selectAndSearchDetails').value;
    this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'chassisNumber', this.controls);
    this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'plateNumber', this.controls);
    this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policeReport', this.controls);
    this.submitFormGroup.get('selectAndSearchDetails')?.valueChanges
      .subscribe((value: any) => {
        this.currentSelectedValue = value;

        //set new validator
        this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, this.currentSelectedValue, this.controls, true);
        
        if (this.currentSelectedValue === 'policyNumber') {
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'chassisNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'plateNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policeReport', this.controls);
        } else if (this.currentSelectedValue === 'chassisNumber') {
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policyNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'plateNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policeReport', this.controls);
        } else if (this.currentSelectedValue === 'plateNumber') { 
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'chassisNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policyNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policeReport', this.controls);
        } else if (this.currentSelectedValue === 'policeReport') { 
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'chassisNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'policyNumber', this.controls);
          this.submitFormGroup = this.clearAndSetNewValidators(this.submitFormGroup, 'plateNumber', this.controls);
        }           
      });
  }

  /**
   * 
   * @param formGroup 
   * @param key 
   * @param controls 
   */
  clearAndSetNewValidators(formGroup: FormGroup, key: string, controls: any, setValidator?: boolean): FormGroup {
    const requiredControl = controls.find(control => control.key === key);
    requiredControl.required = !!setValidator;
    formGroup.get(key).setValue('');
    formGroup.get(key).clearValidators();

    if (setValidator) {
      formGroup.get(key).setValidators(this.dynamicFormService.createValidators(requiredControl));
    } 

    this.controls = [ ...this.controls ];
    return formGroup;
  }

  /**
   * mark form group touched
   * @param formGroup 
   * @returns 
   */
  markFormGroupTouched(formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      control.markAsDirty();
    });
  }

  /**
   * search policy
   */
  searchPolicy(): void {
    const searchText = this.submitFormGroup.get(this.currentSelectedValue)?.value || '';
    
    if(this.submitFormGroup?.valid) {
      this.onClickSearchIcon(searchText)
    } else {
      this.markFormGroupTouched(this.submitFormGroup);
    }
  }

  /**
  * 
  * @param searchText 
  */
  async onClickSearchIcon(searchText: any): Promise<void> {
    if (searchText === '') {
      return;
    }

    await this.populatePolicyForm(searchText);

    if (!(this.customerDetailsPopupData?.data && this.shouldOpenPopup)) return;

    this.dialogRef.close(this.customerDetailsPopupData);
  }

  /**
   * populate policy number
   */
  public async populatePolicyForm(data: any): Promise<any> {
    this.commonService.showLoading();
    let searchTypeDetail: string;

    const searchDetailValue = this.submitFormGroup.get('selectAndSearchDetails').value;
    if (searchDetailValue === 'policeReport') {
      searchTypeDetail = '4';
    } else if (searchDetailValue === 'chassisNumber') {
      searchTypeDetail = '2';
    } else if (searchDetailValue === 'plateNumber') {
      searchTypeDetail = '3';
    } else {
      searchTypeDetail = '1';
    }

    const headers = {
      searchType: searchTypeDetail,
      searchValue: data,
      lossDate: this.data.lossDate
    }
    return firstValueFrom(this.claimsService.getPolicyDetails(headers)).then(async (result: any) => {
      if (result?.success && result.data != null) {
        this.shouldOpenPopup = true;
        console.log(result);
        this.customerDetailsPopupData = result;
        this.commonService.hideLoading();
      } else {
        this.shouldOpenPopup = false;
        this.customerDetailsPopupData = null;
        this.commonService.hideLoading();
        this.commonService.showInfoToast(5000, 'show-info | ' + this.translateService.instant('value_not_exist_in_database'));
      }
    }).catch(() => {
      this.shouldOpenPopup = false;
      this.customerDetailsPopupData = null;
      this.commonService.hideLoading();
    });
  }
  
  /**
   * on destroy event
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((element: Subscription) => {
      element.unsubscribe();
    });

    this.dynamicFormService.broadcastOption(null);
  }
}

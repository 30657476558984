<div class="media-component"
     [ngClass]="{'ios' : platform.IOS}"
     *ngIf="!files || files?.length === 0">
    <h3>{{title | translate}}</h3>
    <div class="media-wrapper">
        <div class="name">
            <h4>{{name | translate}}</h4>
            <h5>{{subtitle | translate}}</h5>
        </div>
        <div class="image-wrapper">
            <img src="{{documentPlaceHolderURL}}"
                 alt="name"
                 class="image" />
            <img *ngIf="!hideOutlineImg"
                 src="{{outlineImage}}"
                 alt="name"
                 class="image-placeholder" />
        </div>
        <div class="media-desc">
            <ng-content></ng-content>
        </div>
    </div>
    <xa-button class="upload-btn"
               [ngClass]="{'ios' : platform.IOS}"
               [id]="module"
               (click)="triggerUpload()">{{buttonText | translate}}</xa-button>
</div>
<xa-video #video
          *ngIf="module==='video'"
          [steps]="videoSteps"
          [forceOrientation]="true"
          (processingVideo)="processingVideoHandler();"
          (videoRecorded)="handleUpload($event);">
</xa-video>
<div [hidden]="true">
    <xa-documents *ngIf="module==='document'"
                  #document
                  [isCustomerLink]="true"
                  [xaDocument]="xaDocuments"></xa-documents>
</div>

<xa-camera *ngIf="module==='image'"
           skipText="skip"
           [forceOrientation]="true"
           [displaySkip]="currentStep?.isSkipEnabled"
           [outlineUrl]="outlineUrl"
           [procedureStep]="currentStep?.instruction"
           #camera
           [currentStep]="currentStep?.stepName"
           [steps]="sides"
           (skipped)="handleImageSkip();"
           (initialized)="setCameraCurrentIndex();"
           (imageUploaded)="handleImageUpload($event)"></xa-camera>
<div class="media-component-review"
     [ngClass]="{'ios' : platform.IOS}"
     *ngIf="files?.length > 0">
    <h3 class="review-title">{{reviewTitle | translate : {stepName: currentStep?.parentName || currentStep?.stepName } }}
        <span class="mandatory-index"
              *ngIf="isMandatory()">{{'label_mandatory' | translate }}</span>
    </h3>
    <div class="review-card"
         [ngClass]="{'bottom-last': i === (files?.length -1)}"
         *ngFor="let item of files;let i = index;">
        <div class="upload-status">
            <img [hidden]="true"
                 [src]="item.url"
                 alt="item.url"
                 *ngIf="item.url">
            <mat-icon *ngIf="(item.isUploadSuccess || item.url)"
                      [svgIcon]="'checked'"
                      class="status-ico"
                      aria-hidden="false"
                      aria-label="checked icon"></mat-icon>
            {{(item.uploadInProgress
            ? 'upload_inprogress' : (item.isUploadSuccess ? 'upload_successfully': 'not_uploaded')) | translate}}
        </div>
        <div class="content-review">
            <div class="icon-text">
                <div class="upload-icn">
                    <mat-icon svgIcon="upload-icon"></mat-icon>
                </div>
                <div class="text">
                    <h4 class="step-name">{{item.stepName}}
                        <span class="mandatory"
                              *ngIf="isStepMandatory(item)">&nbsp;*</span>
                    </h4>
                    <span>{{item.extension}}&nbsp;{{item.size ? ''+ (item.size | number : '1.0-2') + 'MB': ''}}</span>
                </div>
            </div>
            <div class="action-div">
                <div class="eye">
                    <mat-icon *ngIf="(item.url || item.image || this.localVideoUrl) && !item.extension?.includes('pdf')"
                              (click)="openAttachment(item.url || item.image,item.stepName,item.uploadStatus,item)"
                              svgIcon="eye-fraud"></mat-icon>
                    <mat-icon *ngIf="(item.url || item.image) && item.extension?.includes('pdf')"
                              (click)="download(item)"
                              svgIcon="download-arrow"></mat-icon>

                    <mat-icon (click)="triggerUpload(i)"
                              *ngIf="!item.url && !item.image && !this.localVideoUrl"
                              svgIcon="upload-dark"></mat-icon>
                </div>
                <div class="eye"
                     *ngIf="(item.url || item.image) && item.extension?.includes('pdf')">
                    <mat-icon (click)="openAttachment(item.url || item.image,item.stepName,item.uploadStatus,item, true)"
                              svgIcon="delete"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <xa-button class="next-btn"
               [ngClass]="{'ios' : platform.IOS}"
               (click)="triggerNext()">{{'save_next' | translate}}</xa-button>
</div>
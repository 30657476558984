import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { PartialCaseDetail } from 'src/app/model/chq-cases-model';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { FooterModel, Modules, UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { inspectionpartPartMapper } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CaseService } from 'src/app/services/case/case.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { AddPhotosComponent } from 'src/app/shared/add-photos/add-photos.component';
import { ChqImagesUploaderComponent } from 'src/app/widgets/chq-images-uploader/chq-images-uploader.component';


const photosHeader: ChqWidgetsHeaderModel = {
  title: 'add_damage_photos',
  step: 4,
  description: 'damage_photos_msg',
  stepName: 'step_four',
};

@Component({
  selector: 'repair-photos',
  templateUrl: './repair-photos.component.html',
  styleUrls: [ './repair-photos.component.scss' ]
})
export class RepairPhotosComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() photosHeader: ChqWidgetsHeaderModel = photosHeader;
  @Output() currentUploadMode: EventEmitter<{ 'mode': string, 'id'?: string }> = new EventEmitter();
  @ViewChild('addPhotos') addPhotos: AddPhotosComponent;
  addPhotosHeader: ChqWidgetsHeaderModel;
  public noImagesData: boolean = false;
  public imageStatusSteps: UploadStepModel[] = [];
  public showUploading: boolean = false;
  public isMultipleUpload: boolean = false;
  public triggerMassUpload: boolean = false;
  public caseStatus = '';
  public caseId: string = '0';
  public inspection: any;
  public bookingId: string = '';
  public vehicleId: number;
  public maximumImages = 40;
  public domainId: number;
  public uploadType: string;

  @Input() showNotify: boolean;
  @Input() displayAI: boolean;
  @Input() isInvalidStatus: boolean;
  @Input() activateRepairButton: boolean;
  @Input() isRepairComplete: boolean;

  public showDamageAnalysis: boolean;
  @Input() objectId: number;
  @Input() showSPI: boolean;
  @Input() minimumImageJob: number;
  @Input() hideBulk: boolean;
  @Output() setStatus: EventEmitter<any> = new EventEmitter();

  @Output() notify: EventEmitter<any> = new EventEmitter();
  @Output() completeRepair: EventEmitter<any> = new EventEmitter();

  public minimumPhotosRequired: number = 0;
  public hideContent: boolean = true;
  public currentAdditionalModeMultiple = false;
  public vehicleType: string;
  public isBulkUploaded: boolean;
  public caseData: any;
  @Input() inspectionId: number;
  @Input() currentInspectionTemplate: any;
  public hideUploader = false;
  private readonly repairTypes: any = [];
  private readonly repairType: string = '';
  private readonly repairId: string = '';
  public isJobLoading: boolean;
  private readonly currentRepairStatus = '';
  public partialCaseDetail: PartialCaseDetail;
  @Input() repairDetail: any;
  @Input() inspectionTemplateId: number;
  private inspectionBulkTemplateId: number;
  @ViewChild('imageUploader') imageUploader: ChqImagesUploaderComponent;
  public currentObservable: (currentStep: UploadStepModel) => void;
  @Input() inspectionTemplates: ChqInspectionStep[];
  public inspectionItemTemplates: ChqInspectionStep[];
  public inspectionTemplateList: any[];
  public inspectionDetail: any;
  public originalSteps: UploadStepModel[] = [];
  public showNoAccess: boolean = false;
  public footer: FooterModel = {
    text: 'next_create_estimate',
    icon: 'boardview',
    route: 'case/gt',
    status: 'UploadPhotos',
    caseId: '',
    currentStatus: '',
    showButton: true,
    showUploadOption: true,
    section: 'repair-stage',
    hideDownloadButton: true
  }

  /**
   * handle back
   */
  handleBack($event: { back: boolean }): void {
    this.showDamageAnalysis = !$event.back;
  }

  /**
   * check repair types
   */
  get checkRepairTypes(): boolean {
    if (location.href.indexOf('repair') === -1) {
      return true;
    }

    if (this.repairType !== 'repair') {
      return true;
    }

    return this.repairTypes.filter((v) => {
      return v.repairServiceType === 'Body' || v.repairServiceType === 'Paint';
    }).length > 0;
  }

  /**
   * constructor
   * @param fb ChangeDetectorRef
   */
  constructor(
    private readonly cd: ChangeDetectorRef, 
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly router: Router, 
    private readonly caseService: CaseService,
    private readonly monitorService: MonitorService,
    private readonly inspectionService: InspectionService,
    private readonly bookingService: BookingService,
    private readonly translateService: TranslateService,
    private readonly estimateService: RepairEstimateService) {
    if (commonService.pageName.closed) {
      commonService.pageName = new BehaviorSubject<string>(null);
    }
    commonService.pageName.next('photos');

    if (this.commonService.accessRight.closed) {
      this.commonService.accessRight = new BehaviorSubject<boolean>(true);
    }
  }

  /**
   * update page status
   */
  updateCurrentStatus($event): void {
    this.monitorService.logEvent('updateCurrentStatus', [ 'RepairPhotosComponent', 'addenda-quote', {
      caseId: $event
    } ]);
    if ($event.initial) {
      this.setInitialMode();
      return;
    }
    this.showUploading = true;
    this.uploadBulkUploadFile($event.steps);
  }

  /**
   * handle delete all
   */
  handleDeleteAll(): void {
    this.setInitialMode();
  }

  /**
   * ngAfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.commonService.updatePage('/quote/case/0', 'photos');
    this.cd.detectChanges();
    this.monitorService.logEvent('ngAfterViewInit', [ 'RepairPhotosComponent', 'addenda-quote' ]);
  }

  /**
 * upload bulk file
 * @param uploads
 */
  uploadBulkUploadFile(uploads: UploadStepModel[]): void {
    for (let i = 0; i < uploads?.length; i++) {

      const img = new Image();
      img.src = uploads[i].image as string;
      img.onload = (): void => {
        const uploadObject = {
          'height': img.height,
          'width': img.width,
          'inspectionId': this.inspectionId,
          'templateId': this.inspectionBulkTemplateId,
          'type': this.vehicleType || '',
          'isBulk': true,
          'isSPI': false,
          'domainId': 3100,
          'objectId': this.objectId
        }
        const step = uploads[i];
        step.uploadInProgress = true;
        step.isSkipped = false;
        step.isUploadFailed = false;
        step.isUploadSuccess = false;
        this.monitorService.logEvent('uploadBulkUploadFile', [ 'RepairPhotosComponent', 'addenda-quote', {
          uploadObject
        } ]);
        this.inspectionService.uploadImageBulkJob(uploadObject, step.imageFile).subscribe({
          next: (data: any) => {
            step.url = data.rawImage?.rawAzureBlobUrl;
            step.inspectionItem = data;
            step.procedureSteps = 'Bulk Upload Image';
            this.imageUploader.updateUploadStatus('success', step, null, data);
            this.footer = { ...this.footer, showUploadOption: false };
          },
          error: (err: any) => {
            if (err.status != 401) {
              this.imageUploader.imageStatusSteps = this.imageUploader.imageStatusSteps.filter((ig: UploadStepModel) => {
                return (ig.index !== step.index || ig.stepName !== step.stepName || ig.uploadInProgress !== true);
              })
              this.imageUploader.updateUploadStatus('failed', step, err, null, true);
            }
            this.monitorService.logException(err, SeverityLevel.Error);
          },
        })
      }

    }

  }

  /**
   * upload photos
   */
  uploadFile(currentStep: UploadStepModel): void {
    const uploadObject = {
      'height': currentStep.imageHeight,
      'width': currentStep.imageWidth,
      'inspectionId': this.inspectionId,
      'templateId': this.inspectionTemplateId,
      'inspectionitemId': currentStep.inspectionItem.id,
      'type': this.vehicleType || '',
      'isBulk': false,
      'isSPI': true,
      'domainId': 3100,
      'objectId': this.objectId
    }

    this.monitorService.logEvent('uploadFile', [ 'RepairPhotosComponent', 'addenda-quote', {
      uploadObject
    } ]);

    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    this.commonService.inProgressSteps.next(1);
    this.footer = { ...this.footer, showUploadOption: false };
    this.commonService.showNotification(this.translateService.instant('photos_upload', { value: currentStep.stepName }));
    this.inspectionService.uploadImageBulkJob(uploadObject, currentStep.imageFile).subscribe({
      next: (data: any) => {
        const msg = 'photos_upload_success';
        this.commonService.showNotification(this.translateService.instant(msg, { value: currentStep.stepName }));
        const inspectionItem = this.inspectionItemTemplates.find((x: any) => x.inspectionItem.id == data?.id);
        inspectionItem.inspectionItem.rawAzureBlobUrl = data.rawImage?.rawAzureBlobUrl;
        if (inspectionItem) {
          currentStep.url = data.rawImage?.rawAzureBlobUrl;
          this.imageUploader.updateUploadStatus('success', currentStep, null, data);
        }
      },
      error: (err: any) => {
        if (err.status != 401) {
          const msg = 'photo_upload_error';
          this.commonService.showNotification(this.translateService.instant(msg, { value: currentStep.stepName }));
          this.imageUploader.updateUploadStatus('failed', currentStep, err);
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      },
    })
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.commonService.pageName.unsubscribe();
    this.commonService.accessRight.unsubscribe();
    this.monitorService.logEvent('ngOnDestroy', [ 'RepairPhotosComponent', 'addenda-quote' ]);
  }

  /**
   * map inspection from api
   * @param inspectionData
   */
  mapInspection(inspectionData: any): void {
    if (inspectionData) {
      try {
        this.inspection = inspectionData;
        this.uploadType = this.inspection.uploadType;
        let currentMode = 'SPI';
        if (this.uploadType) {
          this.footer = { ...this.footer, showUploadOption: false };
        }
        if (this.uploadType === 'Bulk') {
          currentMode = 'BULK';
        }
        const bulkUploadTemplateItem = this.inspectionTemplates.filter(step => step.name === 'Bulk Upload Image');
        const bulkUploadedItemsWithImages = this.inspection.inspectionItems
          .filter((step) => {
            return (step.name === 'Bulk Upload Image' || step.inspectionItemTemplateID === bulkUploadTemplateItem[0].id)
              && step.rawAzureBlobUrl
          });
        if (bulkUploadedItemsWithImages?.length > 0 || this.uploadType === 'Bulk') {
          const massUploadItems = [];
          this.currentUploadMode.emit({ 'mode': 'multiple' });
          for (const i in bulkUploadedItemsWithImages) {
            const currentObj = {
              ...bulkUploadTemplateItem[0], name: bulkUploadedItemsWithImages[i].name || bulkUploadTemplateItem[0].name,
              inspectionItem: bulkUploadedItemsWithImages[i]
            }
            massUploadItems.push(currentObj);
          }
          this.inspectionItemTemplates = massUploadItems;
        } else {

          this.currentUploadMode.emit({ 'mode': 'single' });
          this.inspectionTemplates.map((chqInspectionStep: ChqInspectionStep) => {
            const item = this.inspection.inspectionItems.find((el: any) => el.inspectionItemTemplateID == chqInspectionStep.id);
            if (item) {
              chqInspectionStep['inspectionItem'] = item;
            }
          });
          this.inspectionItemTemplates = this.inspectionTemplates.filter(step => step.name != 'Bulk Upload Image');
        }

        if (bulkUploadTemplateItem?.length > 0) this.inspectionBulkTemplateId = bulkUploadTemplateItem[0].id;

        this.inspectionItemTemplates.map((x: ChqInspectionStep) => {
          if (x.inspectionItem?.rawAzureBlobUrl) {
            x.isUploadSuccess = true;
            x.image = x.inspectionItem?.rawAzureBlobUrl;
          } else {
            if (!x.inspectionItem) {
              x.inspectionItem = { rawAzureBlobUrl: '' };
            }
            x.inspectionItem.rawAzureBlobUrl = '';
            x.isUploadFailed = false;
            x.isUploadSuccess = false;
            x.uploadInProgress = false;
          }
        })
        if (this.uploadType) {
          if (currentMode === 'SPI') {
            this.isMultipleUpload = false;
            this.showUploading = true;
            if (this.addPhotosHeader.isRepairModule) {
              this.photosHeader = { ...this.photosHeader, title: 'back_smart_photo' };
            }
            this.minimumPhotosRequired = this.minimumImageJob || this.inspectionItemTemplates.filter((value: ChqInspectionStep) => {
              return value.isSkipEnabled !== true;
            }).length;
            this.currentObservable = this.uploadFile.bind(this);
          } else {
            this.currentObservable = this.uploadBulkUploadFile.bind(this);
            this.isMultipleUpload = true;
            this.minimumPhotosRequired = this.minimumImageJob || this.currentInspectionTemplate.minImageLimit;
            this.triggerMassUpload = true;
            this.isBulkUploaded = true;
            if (this.addPhotosHeader.isRepairModule) {
              this.photosHeader = { ...this.photosHeader, title: 'bulk_upload_images' };
            }
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  /**
   * inspection detail by id
   */
  getInspectionTemplateByObject(): void {
    for (let i = 0; i < this.inspectionItemTemplates?.length; i++) {
      const templatePart = this.inspectionItemTemplates[i];
      const inspectionStep = inspectionpartPartMapper(templatePart, i);
      const isAdditionalImage = inspectionStep.stepName?.toLowerCase().indexOf('additional photo') != -1;
      const isMassUpload = inspectionStep.stepName?.toLowerCase().indexOf('bulk upload image') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !inspectionStep.url;
      this.imageStatusSteps.push({
        ...inspectionStep, mandatory: !templatePart.isSkipEnabled, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        isMassUpload,
        updatedDate: inspectionStep.url ? templatePart?.inspectionItem?.updatedDate : '',
        uploadedDate: inspectionStep.url ? templatePart?.inspectionItem?.created : '',
        domainId: this.domainId, objectId: this.objectId,
        uploadedPartGuid: '',
        uploadedGuid: ''
      });
    }
  }

  /**
   * create new inspection for job
   */
  createInspectionForJob(jobId: number, inspectionId: number, templateId: number): void {
    const inspectionItemObj = {
      'objectId': jobId,
      'inspectionId': inspectionId,
      'templateId': templateId
    }

    this.inspectionService.createInspectionItemJobDetail(inspectionItemObj).subscribe({
      next: (obj: any) => {
        const inspectionItems = obj.data;
        this.uploadType = obj.data.uploadType;
        this.mapInspection(inspectionItems);
        setTimeout(() => {
          this.hideContent = false;
          if (this.isBulkUploaded) {
            this.showUploading = true;
            this.isMultipleUpload = true;
            this.currentAdditionalModeMultiple = true;
          }
          this.isJobLoading = false;
          if (this.uploadType) {
            this.imageUploader?.goToReview();
          } else {
            if (this.showSPI && this.hideBulk) {
              this.addPhotos.allowSingleUpload();
              return;
            }

            if (!this.showSPI && !this.hideBulk) {
              this.addPhotos.allowMultipleUpload();
            }
          }
        })
        this.commonService.hideLoading();
      },
      error: () => {
        this.isJobLoading = false;
        this.noImagesData = true;
        this.commonService.hideLoading();
      }
    })
  }


  /**
   * load server details
   */
  loadApiData(): void {
    const promiseApi: any = {};
    this.isJobLoading = true;
    promiseApi.jobDetail = this.inspectionService.getInspectionJobDetail(this.inspectionId, this.objectId);
    const serverDetails = forkJoin(promiseApi).pipe(
      catchError(error => of(error))
    )

    serverDetails.subscribe({
      next: (serverMap: any) => {
        const { jobDetail } = serverMap;
        if (jobDetail.status === 204) {
          this.createInspectionForJob(this.objectId, this.inspectionId, this.inspectionTemplateId);
          return;
        }

        if (jobDetail.body) {
          const inspectionTemplateDetail = jobDetail.body.data;
          if (inspectionTemplateDetail) {
            this.inspectionDetail = inspectionTemplateDetail;
          }
          this.mapInspection(inspectionTemplateDetail);
          this.getInspectionTemplateByObject();
          setTimeout(() => {
            this.hideContent = false;
            if (this.isBulkUploaded) {
              this.showUploading = true;
              this.isMultipleUpload = true;
              this.currentAdditionalModeMultiple = true;
            }
            this.isJobLoading = false;
            if (this.uploadType) {
              this.imageUploader?.goToReview();
            } else {
              if (this.showSPI && this.hideBulk) {
                this.addPhotos.allowSingleUpload();
                return;
              }

              if (!this.showSPI && !this.hideBulk) {
                this.addPhotos.allowMultipleUpload();
              }
            }
          })
        } else {
          this.noImagesData = true;
          this.commonService.hideLoading();
        }
      },
      error: () => {
        this.noImagesData = true;
        this.isJobLoading = false;
        this.commonService.hideLoading();
      }
    });
  }



  /**
   * on init
   */
  ngOnInit(): void {
    const currentObjectId = this.route.parent?.snapshot.paramMap.get('id');
    this.addPhotosHeader = { ...this.photosHeader, isHeaderOnly: false };
    this.monitorService.logEvent('ngOnInit', [ 'RepairPhotosComponent', 'addenda-quote', {
      caseId: currentObjectId,
      repairId: currentObjectId
    } ]);

    this.footer = {
      ...this.footer, caseId: this.photosHeader.isRepairModule ? '' : currentObjectId,
      repairId: this.photosHeader.isRepairModule ? currentObjectId : '',
      handleBack: this.handleBack.bind(this),
      objectId: this.objectId,
      domainId: this.repairDetail?.domainId
    }

    this.commonService.accessRight.subscribe({
      next: (res: boolean) => {
        if (!res) {
          this.showNoAccess = true;
        }
      }
    });

    if (this.showSPI || !this.hideBulk) {
      this.loadApiData();
    } else {
      if (!this.repairDetail) {
        this.bookingService.getRepairDetailByGuid(currentObjectId).subscribe({
          next: (resp) => {
            const { vehicle, repairNumber, status } = resp.data;
            this.setStatus.emit(status);
            this.repairDetail = resp.data;
            this.inspectionId = vehicle.inspectionId;

            this.footer = {
              ...this.footer, isRepairModule: true,
              from: Modules.repair
            };
            // Send booking data in observable
            this.commonService.repairDetails.next(resp.data)
            this.commonService.bookingId.next(repairNumber);
          }
        })
      } else {
        const { vehicle, repairNumber, status } = this.repairDetail;
        this.setStatus.emit(status);
        this.inspectionId = vehicle.inspectionId;

        this.footer = {
          ...this.footer, isRepairModule: true,
          from: Modules.repair
        };
        // Send booking data in observable
        this.commonService.repairDetails.next(this.repairDetail)
        this.commonService.bookingId.next(repairNumber);
      }
      this.hideContent = false;
    }

    const container: any = document.querySelector('.addenda-container');
    if (container) {
      container.scrollTop = 0;
    }

    this.currentObservable = this.uploadFile.bind(this);
  }

  /**
   * reload inspection and map
   */
  public reloadInspection(): void {
    this.commonService.showLoading();
    if (this.inspectionId) {
      this.inspectionService.getInspectionJobDetail(this.inspectionId, this.objectId).subscribe({
        next: (inspectionDetailResponse: any) => {
          if (inspectionDetailResponse?.body?.data) {
            this.mapInspection(inspectionDetailResponse.body.data);
            this.getInspectionTemplateByObject();
            this.isMultipleUpload = false;

            this.isBulkUploaded = false;
            this.currentAdditionalModeMultiple = false;
            this.triggerMassUpload = false;
            setTimeout(() => {
              this.hideContent = false;
              this.showUploading = true;
            })

          }
        },
        complete: () => {
          this.commonService.hideLoading();
        },
      });
    }
  }


  /**
   * set to initial mode
   */
  setInitialMode(): void {
    this.showUploading = false;
    this.imageStatusSteps = [];
    this.triggerMassUpload = false;
    this.isBulkUploaded = false;
    this.imageUploader.isReviewSection = false;
    this.isMultipleUpload = false;
    this.currentAdditionalModeMultiple = false;
  }

  /**
   *handle mode selection
   * @param $event
   */
  handleMode($event: any): void {
    this.monitorService.logEvent('handleMode', [ 'RepairPhotosComponent', 'addenda-quote', {
      $event
    } ]);
    if (!this.inspectionId) {
      this.currentUploadMode.emit({ 'mode': 'error', 'id': this.bookingId });
      return;
    }
    this.currentUploadMode.emit($event);

    if ($event.mode === 'single') {

      if (this.imageStatusSteps?.length === 0) {
        this.isMultipleUpload = false;
        this.isBulkUploaded = false;
        this.currentAdditionalModeMultiple = false;
        this.triggerMassUpload = false;
        this.minimumPhotosRequired = this.minimumImageJob || this.inspectionItemTemplates.filter((value: ChqInspectionStep) => {
          return value.isSkipEnabled !== true;
        }).length;
        this.reloadInspection();
      } else {
        this.isMultipleUpload = false;
        this.showUploading = true;
        this.isBulkUploaded = false;
        this.currentAdditionalModeMultiple = false;
        this.minimumPhotosRequired = this.minimumImageJob || this.currentInspectionTemplate.minImageLimit;
        this.triggerMassUpload = false;
      }

      this.currentObservable = this.uploadFile.bind(this);
    } else {
      this.imageStatusSteps = [];
      this.currentObservable = this.uploadBulkUploadFile.bind(this);
      this.isMultipleUpload = true;
      this.triggerMassUpload = true;
      this.showUploading = true;
    }
  }
}

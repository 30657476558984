import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-claim-garage-assignment',
  templateUrl: './claim-garage-assignment.component.html',
  styleUrls: [ './claim-garage-assignment.component.scss' ]
})
export class ClaimGarageAssignmentComponent {
  public yesButton: ChqWidgetsButtonModel = {
    label: 'yes',
    type: 'primary',
    onclick: this.handleYes.bind(this)
  }

  public noButton: ChqWidgetsButtonModel = {
    label: 'cancel',
    type: 'outline',
    onclick: this.handleCancel.bind(this)
  }

  public garageDetails: any = {}

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClaimGarageAssignmentComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService
  ) {
    this.garageDetails = data.garageDetails || {}
  }

  /**
   * handle clicked on No
   */
  handleCancel(): void {
    this.monitorService.logEvent('handleCancel', [ 'ClaimGarageAssignmentComponent', 'addenda-claim' ]);
    this.close();
  }

  /**
   * handle clicked on Yes
   */
  handleYes(): void {
    this.monitorService.logEvent('handleYes', [ 'ClaimGarageAssignmentComponent', 'addenda-claim' ]);
    this.dialogRef.close(true);
  }
  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }
}

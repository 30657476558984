<div class="message-container">
    <div class="header">
        <h3>{{'decline_claim' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="content">
        <chq-input [inputModel]="notesModel" tabindex="-1" (controlOutput)="onFormUpdate($event);"></chq-input>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="cancelButton" class="left"></chq-button>
        <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
</div>
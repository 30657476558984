<div *ngIf="_thread"
     class="wrapper"
     [ngClass]="{'ios-wrapper': commonService.isiOS}">
    <div *ngIf="messages.length === 0 && !pageReady"
         class="spinner-wrapper">
        <mat-spinner [strokeWidth]="5"
                     [diameter]="50"
                     class="inner-loading"></mat-spinner>
    </div>
    <div class="message-list"
         [ngClass]="{'padding-error-message': hasErrorMessage}"
         #div>
        <ul class="list-unstyled px-2">
            <li *ngFor="let message of messages; let i = index"
                class="message"
                [ngClass]="{'out': !message.isCustomerResponse, 'in': message.isCustomerResponse, 'local-last': message.isLocalLast}"
                [id]="'message-'+message.id">
                <div class="date-group"
                     *ngIf="i === 0 || (i !== 0 && messages[i].dateFormat !== messages[i-1].dateFormat) ">{{message.dateFormat}}</div>
                <div [ngClass]="message.isCustomerResponse ? 'flex-start' : 'flex-end'"
                     class="message-container top"
                     [id]="'message-'+message.ConversationGuid">
                    <span class="arrow"
                          [ngClass]="message.isCustomerResponse ? 'order-0' : 'order-1'"></span>
                    <div class="content">
                        <div class="sender-name">{{ message.userID }}</div>
                        <div [innerHtml]="message.content"></div>
                        <div class="attachment-container"
                             *ngIf="message?.chatAttachments?.length > 0">
                            <div class="image-container"
                                 [ngClass]="{'flex-box': message.chatAttachments.length > 3 }">
                                <img *ngFor="let chatAttachment of (message.chatAttachments.length <= 3 ? message.chatAttachments :message.chatAttachments.slice(0,3) ) | keyFilter: { key: 'type', value: allowExtension}"
                                     [src]="chatAttachment.blobUrl"
                                     (click)="imageDialog(chatAttachment, true)"
                                     (keypress)="imageDialog(chatAttachment, true)"
                                     [alt]="chatAttachment.name" />
                                <div *ngIf="message?.chatAttachments?.length > 3"
                                     (click)="showImageDialog(message)"
                                     (keypress)="showImageDialog(message)">
                                    <span> {{ (message.chatAttachments.length - 3) }}</span>
                                    <span>{{ 'click_here_details' | translate }}...</span>
                                </div>
                            </div>
                            <div *ngIf="message.chatAttachments?.length > 0"
                                 class="file-attachment">
                                <div *ngFor="let chatAttachment of message.chatAttachments | keyFilter: { key: 'type', value: allowExtension , operator: 0 }">
                                    <mat-icon svgIcon="attachments"></mat-icon>
                                    <span>{{chatAttachment.name}}</span>
                                    <span class="action"
                                          (click)="displayFile(chatAttachment)"
                                          (keypress)="displayFile(chatAttachment)">{{ 'click_here_details' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="timestamp">
                            {{ message.timestamp | date:'shortTime' }}
                            <mat-icon *ngIf="!message.isCustomerResponse"
                                      [svgIcon]="'whatsapp-' + message?.status?.toLowerCase()"></mat-icon>
                        </span>
                    </div>
                </div>
                <div class="whatsapp-error"
                     *ngIf="message.errorMessage"><mat-icon svgIcon="info-circle"></mat-icon>{{ message.errorMessage | translate }}</div>
            </li>
        </ul>
    </div>
    <div *chqPermission="'whatsappCommunicationWrite'"
         class="thread-footer"
         [ngClass]="{'disabled': (thread.objectId && (repairStatus === 'Complete' || repairStatus === 'Closed'))}">
        <div class="emoji-icon ">
            <mat-icon svgIcon="plus"
                      color="dark-gray"
                      (click)="uploadAttachment()"
                      (keypress)="uploadAttachment()"></mat-icon>
        </div>
        <input [(ngModel)]="newMessage"
               (keyup.enter)="send();"
               class="form-control"
               placeholder="{{ 'start_typing' | translate }}..."
               fxFlex />
        <div class="send-icon">
            <mat-icon [svgIcon]="'paper-plane'"
                      color="dark-gray"
                      (click)="send()"
                      (keypress)="send()"></mat-icon>
        </div>
    </div>
</div>
<input hidden
       type="file"
       #upload
       (change)="handleUpload($event)"
       [multiple]="true" />
<div class="range-picker-wrapper">
  <div clss="range-text">
    <span *ngIf="selectedOption === 'day'">{{ startDate | date: 'd MMMM YYYY' : '' : i18n.locale }}</span>
    <span *ngIf="selectedOption === 'month'">{{ startDate | date: 'MMMM YYYY' : '' : i18n.locale }}</span>
    <span *ngIf="selectedOption === 'week' && sameMonth">{{ startDate | date: 'd' : '' : i18n.locale }}</span>
    <span *ngIf="selectedOption === 'week' && !sameMonth">{{ startDate | date: 'd MMM' : '' : i18n.locale }}</span>
    <span *ngIf="selectedOption === 'week'">-</span>
    <span *ngIf="selectedOption === 'week' && sameMonth">{{ endDate | date: 'd MMMM YYYY' : '' : i18n.locale }}</span>
    <span *ngIf="selectedOption === 'week' && !sameMonth">{{ endDate | date: 'd MMM YYYY' : '' : i18n.locale }}</span>
  </div>
  <div class="range-action">
    <mat-icon [svgIcon]="'arrow-left'"
              (click)="goPrevious()"
              (keypress)="goPrevious()"></mat-icon>
    <xa-button type="outline"
               (click)="updateDateRange()"
               (keypress)="updateDateRange()">{{ 'today' | translate }}</xa-button>
    <mat-icon [svgIcon]="'arrow-right'"
              (click)="goNext()"
              (keypress)="goNext()"></mat-icon>
  </div>
  <div *ngIf="showRangeType">
    <ul class="list">
      <li class="list-item"
          [class.active]="selectedOption === 'week'"
          (click)="selectOption('week')"
          (keypress)="selectOption('week')">
        {{ 'week' | translate }}
      </li>
      <li class="list-item"
          [class.active]="selectedOption === 'month'"
          (click)="selectOption('month')"
          (keypress)="selectOption('month')">
        {{ 'month' | translate }}
      </li>
    </ul>
  </div>
</div>
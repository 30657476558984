<div class="pagination-wrapper">
    <div class="header">
        <h4>{{currentTitle | translate}}</h4>
    </div>
    <div class="parameter-container row">
        <div class="col-6 parameter"
             [ngClass]="{'disabled': parameter.isDisabled}"
             [hidden]="parameter.parentFieldId"
             *ngFor="let parameter of _parameters">
            <mat-icon svgIcon="lock-outline"
                      *ngIf="parameter.isDisabled"></mat-icon>
            <xa-checkbox [(ngModel)]="parameter.parameterValue"
                         *ngIf="!parameter.parentFieldId"
                         (ngModelChange)="triggerChange(parameter, parameter.parameterValue);"></xa-checkbox>
            <div class="parameter-item">
                <span>{{parameter.name}}</span>
                <xa-radio-group [(ngModel)]="parameter.optionValue"
                                class="radio-group row"
                                *ngIf="parameter.options?.length > 0"
                                (ngModelChange)="triggerChange()">
                    <xa-radio *ngFor="let option of parameter.options"
                              [value]="option.value">{{ option.label }}</xa-radio>
                </xa-radio-group>
            </div>
        </div>
    </div>
</div>
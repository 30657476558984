<div class="dialog-container">
    <div class="header">
        <h3>{{ 'send_message' | translate }}</h3>

        <div class="close-icon" (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content class="email-dialog-body">
        <div class="form-container">

            <div class="input-wrapper" *ngIf="data[1].type === 'email'">
                <div class="div-wrapper">
                    <chq-input [inputModel]="emailForm.email" #emailRef (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                </div>
            </div>

            <div class="input-wrapper" style="margin-bottom: 34px;" *ngIf="data[1].type !== 'email'">
                <div class="div-wrapper flex">
                    <chq-input [inputModel]="dropdownModel" #codeRef (controlOutput)="onFormUpdate($event)">
                    </chq-input>
                    <div class="input-container">
                        <chq-input [inputModel]="emailForm.phone" #phoneRef
                            (controlOutput)="onFormUpdate($event)"></chq-input>
                    </div>
                </div>
            </div>

            <div class="input-wrapper label-wrapper" *ngIf="data[1].type === 'email'">
                <label class="title">{{'subject' | translate}} </label>
                <label [innerHTML]="mailSubject"></label>
            </div>
            <div class="input-wrapper">
                <label class="title">{{'message' | translate}}</label>
                <div class="div-wrapper">
                    <div class="message" [innerHTML]="mailBody">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-container">
        <chq-button [buttonModel]="sendButton" class="right"></chq-button>
    </div>
</div>
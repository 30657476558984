import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { labourRateForm, LabourRateForm } from 'src/app/helper/form/labour-rate.helper';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';
import { CaseService } from 'src/app/services/case/case.service';
import { CommonService } from 'src/app/services/common/common.service';
import { LabourRateService } from 'src/app/services/labour-rate/labour-rate.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Modules } from 'src/app/model/chq-upload-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-labour-rate',
  templateUrl: './labour-rate.component.html',
  styleUrls: [ './labour-rate.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ChqLabourRateComponent implements OnInit {

  public formModel: LabourRateForm;
  public updateButtonModel: ChqWidgetsButtonModel;
  public isViewOnly: boolean = false;
  public caseId: string;
  public objectId: string;
  public labourData: any;
  public pageReady: boolean = false;
  public showClose: boolean = true;

  private from: string;

  @ViewChild('partsDiscount') partsDiscount: ChqInputComponent;
  @ViewChild('labourDiscount') labourDiscount: ChqInputComponent;
  @ViewChild('paintDiscount') paintDiscount: ChqInputComponent;
  @ViewChild('matPaintDiscount') matPaintDiscount: ChqInputComponent;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChqLabourRateComponent>,
    private labourService: LabourRateService,
    private commonService: CommonService,
    private monitorService: MonitorService,
    private caseService: CaseService,
    private bookingService: BookingService,
    private translate: TranslateService
  ) {

  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqLabourRateComponent', 'addenda-quote' ]);
    this.formModel = labourRateForm();

    if(typeof this.data === 'object') {
      if(this.data) {
        this.objectId = this.data['repairId'];
        this.from = this.data['from'];
        this.getLabourDataFromRepair();
      }
    } else {
      this.objectId = this.data;
      this.getLabourData();
    }

    this.updateButtonModel = {
      label: 'update',
      type: 'primary',
      icon: 'edit',
      onclick: this.updateData.bind(this)
    }
  }

  /**
   * cancel dialog
   */
  cancelDialog(): void {
    this.monitorService.logEvent('cancelDialog', [ 'ChqLabourRateComponent', 'addenda-quote' ]);
    this.dialogRef.close(false);
  }

  /**
   * update labour 
   */
  updateData(): void {
    this.monitorService.logEvent('updateData', [ 'ChqLabourRateComponent', 'addenda-quote' ]);
    const keys = Object.keys(this.formModel);
    const validLabourRates = keys.filter(q=> this.formModel[q].value > 0 && q.toLowerCase().indexOf('discount') == -1);
    if(validLabourRates.length == 0){
      this.commonService.showToast(0, this.translate.instant('minimum_labour_value'));
      return;
    }

    if (this.validate()) {
      this.labourData.tariffDiscountRates.forEach((element: any) => {
        if (element['tariffTypeName']?.toLowerCase() == 'parts') {
          element.discount = this.formModel.partsDiscount.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'labour') {
          element.discount = this.formModel.labourDiscount.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'paint labour') {
          element.discount = this.formModel.paintDiscount.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'paint material') {
          element.discount = this.formModel.paintMatDiscount.value;
        }
      });

      this.labourData.tariffLabourRates.forEach((element: any) => {
        if (element['tariffTypeName']?.toLowerCase() == 'mechanics') {
          element.amount = this.formModel.mechanicCost.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'body') {
          element.amount = this.formModel.bodyCost.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'paint') {
          element.amount = this.formModel.paintCost.value;
        } else if (element['tariffTypeName']?.toLowerCase() == 'interior') {
          element.amount = this.formModel.interiorCost.value;
        }else if (element['tariffTypeName']?.toLowerCase() == 'electric') {
          element.amount = this.formModel.electricCost.value;
        }
      });

      this.commonService.showLoading();
      this.labourService.updateLabourData(this.objectId, this.labourData, this.from ? Modules.repair : 'cases').subscribe({
        next: (response) => {
          this.commonService.hideLoading();
          if (response?.success) {
            this.dialogRef.close(this.labourData);
          }
        },
        error: (err) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err, SeverityLevel.Error);
        }
      });
    }
  }

  /**
   * setTextMode
   */
  public setTextMode(): void {
    const keys = Object.keys(this.formModel);
    for (const k in keys) {
      const key = keys[k];
      this.formModel[key] = {
        ...this.formModel[key],
        mode: 'text', type: 'text'
      };

      if (key?.toLowerCase().indexOf('discount') != -1) {
        this.formModel[key].value += '%'; 
      }
    }
  }

  /**
   * check validation
   */
  private validate(): boolean {
    const keys = Object.keys(this.formModel);

    let isValid = true;

    for (const k in keys) {
      const prop = keys[k];
      if (!this.formModel[prop].value.toString()) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      this.updateButtonModel.type = 'disabled';
    } else {
      this.updateButtonModel.type = 'primary';
    }

    return isValid;
  }


  /**
   * 
   * @param event 
   */
  public onFormUpdate(output: IControlValue): void {
    this.monitorService.logEvent('onFormUpdate', [ 'ChqLabourRateComponent', 'addenda-quote', {
      output
    } ]);
    this.formModel[output.name].value = output.value;

    if (output.isValid) {
      this.formModel[output.name].value = output.value;
    } else {
      this.formModel[output.name].value = '';
    }
    this.validate();
  }

  /**
   * get labour data from repair
   */
  getLabourDataFromRepair(): void {
    this.commonService.showLoading();
    this.monitorService.logEvent('getLabourDataFromRepair', [ 'ChqLabourRateComponent', 'addenda-repair' ]);
    this.bookingService.getRepairDetailByGuid(this.objectId).subscribe({
      next: (result: any) => {
        if (result) {
          this.labourData = result.data?.tariffs[0];
          this.pageReady = true;

          this.loadData(result.data?.status);
          this.commonService.hideLoading();
        }
      },
      error: (err:any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }

    });
  }

  /**
   * get labour data to show
   */
  getLabourData(): void {
    this.commonService.showLoading();
    this.monitorService.logEvent('getLabourData', [ 'ChqLabourRateComponent', 'addenda-quote' ]);
    this.caseService.getCaseDetail(this.objectId).subscribe({
      next: (result: any) => {
        if (result) {
          this.labourData = result.data?.tariffs[0];
          this.pageReady = true;

          this.loadData(result.data?.status);
          this.commonService.hideLoading();
        }
      },
      error: (err:any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    });
  }

  /**
   * load labour data
   */
  loadData(status: string) : void {
    if (this.labourData) {
      this.loadFormModel();

      if (!this.labourData.isEditable || status.toLowerCase().indexOf('close') != -1) {
        this.isViewOnly = true;
        this.setTextMode()
      } else {
        setTimeout((() => {
          this.partsDiscount.setValidatorForLabour();
          this.paintDiscount.setValidatorForLabour();
          this.labourDiscount.setValidatorForLabour();
          this.matPaintDiscount.setValidatorForLabour();
        }));
      }
    }
  }

  /**
   * load form model
   */
  loadFormModel() : void {
    this.labourData.tariffDiscountRates.forEach((element: any) => {
      if (element['tariffTypeName']?.toLowerCase() == 'parts') {
        this.formModel.partsDiscount = { ...this.formModel.partsDiscount, value: element.discount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'labour') {
        this.formModel.labourDiscount = { ...this.formModel.labourDiscount, value: element.discount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'paint labour') {
        this.formModel.paintDiscount = { ...this.formModel.paintDiscount, value: element.discount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'paint material') {
        this.formModel.paintMatDiscount = { ...this.formModel.paintMatDiscount, value: element.discount }
      }
    });

    this.labourData.tariffLabourRates.forEach((element: any) => {
      if (element['tariffTypeName']?.toLowerCase() == 'mechanics') {
        this.formModel.mechanicCost = { ...this.formModel.mechanicCost, value: element.amount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'body') {
        this.formModel.bodyCost = { ...this.formModel.bodyCost, value: element.amount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'paint') {
        this.formModel.paintCost = { ...this.formModel.paintCost, value: element.amount }
      } else if (element['tariffTypeName']?.toLowerCase() == 'interior') {
        this.formModel.interiorCost = { ...this.formModel.interiorCost, value: element.amount }
      }else if (element['tariffTypeName']?.toLowerCase() == 'electric') {
        this.formModel.electricCost = { ...this.formModel.electricCost, value: element.amount }
      }
    });
  }
}

<div class="xa-select-wrapper">
    <div class="xa-select-container"
         [ngClass]="{'disabled': config.disabled}"
         [style]="{
        width: dropdownConfig.width
    }">
        <label class="select-label"
               *ngIf="config.label && config.label !== ''"
               for=""
               #label>
            {{ config.label | translate }}
            <span *ngIf="config.showRequiredLabel && config.label"
                  class="required">*</span>
        </label>
        <a (click)="toggleDropdown()">
            <div class="selected-option-wrapper"
                 [ngClass]="{'closed': !isDropdownOpen, 'loading': showLoader}"
                 #dropdownField>
                <ng-container *ngIf="!showLoader; else elseTemplate">
                    <div class="selected-option"
                         *ngIf="!showGroup || !groupByProperty || !isGroupPropertySelection">
                        <ng-container *ngFor="let option of selectedOption">
                            <div class="option"
                                 [ngClass]="{'placeholder': option.placeholder, 'vertical': option.vertical}">
                                <ng-container *ngIf="config.showHeaderIcon">
                                    <mat-icon *ngIf="option.icon && option.icon.type.toLowerCase() === 'svg'"
                                              [svgIcon]="option.icon.name"
                                              aria-hidden="false"
                                              [style]="option.icon.style"
                                              [attr.color]="option.icon.color"></mat-icon>

                                    <mat-icon *ngIf="option.icon && option.icon.type === 'material'"
                                              aria-hidden="false"
                                              [style]="option.icon.style"
                                              [attr.color]="option.icon.color">{{ option.icon.name }}</mat-icon>

                                    <img *ngIf="option.icon && option.icon.type === 'image'"
                                         [src]="option.icon.name"
                                         [style]="option.icon.style"
                                         [alt]="option.icon.name" />

                                    <img *ngIf="option.urlFlag"
                                         [src]="option.urlFlag"
                                         [alt]="option.urlFlag" />

                                </ng-container>
                                <span class="selected-text">{{ option[dropdownConfig.labelKey] | translate}}</span>
                                <span [innerHTML]="option[htmlProp]"
                                      class="html-container"
                                      *ngIf="htmlProp && htmlPropMode && htmlPropMode !== 'option' && option[htmlProp]"></span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="(!selectedOption || selectedOption?.length === 0)">
                            <div class="option"
                                 [ngClass]="{'placeholder': config.placeHolder}">
                                {{ config.placeHolder | translate}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="selected-option"
                         *ngIf="showGroup && groupByProperty && isGroupPropertySelection">
                        <ng-container *ngFor="let group of selectedOption | groupBy: groupByProperty">
                            <ng-container *ngIf="group.value?.length > 0">
                                <div class="option">
                                    <ng-container *ngIf="config.showHeaderIcon">
                                        <mat-icon *ngIf="group.value[0].icon && group.value[0].icon.type.toLowerCase() === 'svg'"
                                                  [svgIcon]="group.value[0].icon.name"
                                                  aria-hidden="false"
                                                  [style]="group.value[0].icon.style"
                                                  [attr.color]="group.value[0].icon.color"></mat-icon>

                                        <mat-icon *ngIf="group.value[0].icon && group.value[0].icon.type === 'material'"
                                                  aria-hidden="false"
                                                  [style]="group.value[0].icon.style"
                                                  [attr.color]="group.value[0].icon.color">{{ group.value[0].icon.name }}</mat-icon>

                                        <img *ngIf="group.value[0].icon && group.value[0].icon.type === 'image'"
                                             [src]="group.value[0].icon.name"
                                             [style]="group.value[0].icon.style"
                                             [alt]="group.value[0].icon.name" />
                                    </ng-container>
                                    {{ group.value[0][groupByProperty] | translate}}
                                    <span [innerHTML]="group.value[0][htmlProp]"
                                          class="html-container"
                                          *ngIf="htmlProp && htmlPropMode && htmlPropMode !== 'option' && group.value[0][htmlProp]"></span>
                                </div>
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="!selectedOption || selectedOption?.length === 0">
                            <div class="option"
                                 [ngClass]="{'placeholder': config.placeHolder}">
                                {{ config.placeHolder | translate}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    <span [hidden]="!showLoader"
                          class="spanLoading">Loading...</span>
                    <div class="loading"
                         *ngIf="showLoader">
                        <div class="overlay"></div>
                        <div class="spinner">
                            <mat-spinner [strokeWidth]="2"
                                         [diameter]="20"></mat-spinner>
                        </div>
                    </div>
                </ng-template>
            </div>
        </a>
        <div class="custom-dropdown"
             [style]="{
                        visibility: (isDropdownOpen ? 'visible' : 'hidden'),
                        width: dropdownConfig.dropdownWidth,
                        position: dropdownConfig.dropdownPosition || 'absolute'
                    }"
             [ngClass]="{'closed': !isDropdownOpen}"
             (scroll)="scrollEvent($event)"
             #dropdownList>

            <div *ngIf="dropdownConfig.enableSearch"
                 class="search-wrapper">
                <input type="text"
                       class="search-input"
                       #searchInput
                   placeholder="{{ 'search' | translate }}"
                       (input)="filterOptions(searchInput.value)" />
            </div>
            <div class="all-option"
                 *ngIf="dropdownConfig.showAllOption && dropdownConfig.multiple && options && options?.length > 0"
             (click)="allOptionsClicked(true);">
                <div class="option">
                    <mat-icon color="primary"
                              [svgIcon]="allOptionSelected? 'checkbox-checked' : 'checkbox-unchecked'"
                              aria-hidden="false"></mat-icon>
                    <mat-icon *ngIf="allOption.icon && allOption.icon.type.toLowerCase() === 'svg'"
                              [svgIcon]="allOption.icon.name"
                              aria-hidden="false"
                              [style]="allOption.icon.style"
                              [attr.color]="allOption.icon.color"></mat-icon>

                    <mat-icon *ngIf="allOption.icon && allOption.icon.type === 'material'"
                              aria-hidden="false"
                              [style]="allOption.icon.style"
                              [attr.color]="allOption.icon.color">{{ allOption.icon.name }}</mat-icon>

                    <img *ngIf="allOption.icon && allOption.icon.type === 'image'"
                         [src]="allOption.icon.name"
                         [style]="allOption.icon.style"
                         [alt]="allOption.icon.name" />

                    {{ allOption[dropdownConfig.labelKey] | translate}}
                </div>
            </div>
            <div *ngIf="!showGroup || !groupByProperty">
                <div *ngFor="let option of options"
                     (click)="selectOption(option)"
                     class="option"
                     [ngClass]="{'selected-item': isSelected(option[dropdownConfig.valueKey])}">

                    <mat-icon *ngIf="config.multiple"
                              color="primary"
                              [svgIcon]="isSelected(option[dropdownConfig.valueKey]) ? 'checkbox-checked' : 'checkbox-unchecked'"
                              aria-hidden="false"></mat-icon>

                    <mat-icon *ngIf="option.icon && option.icon.type.toLowerCase() === 'svg'"
                              [svgIcon]="option.icon.name"
                              aria-hidden="false"
                              [style]="option.icon.style"
                              [attr.color]="option.icon.color"></mat-icon>

                    <mat-icon *ngIf="option.icon && option.icon.type === 'material'"
                              aria-hidden="false"
                              [style]="option.icon.style"
                              [attr.color]="option.icon.color">{{ option.icon.name }}</mat-icon>

                <img *ngIf="option.urlFlag && config.showHeaderIcon"
                         [src]="option.urlFlag"
                         [alt]="option.urlFlag" />

                    <img *ngIf="option.icon && option.icon.type === 'image'"
                         [src]="option.icon.name"
                         [style]="option.icon.style"
                         [alt]="option.icon.name" />

                    {{ option[dropdownConfig.labelKey] | translate}}

                    <span [innerHTML]="option[htmlProp]"
                          class="html-container"
                          *ngIf="htmlProp && htmlPropMode && htmlPropMode !== 'selection' && option[htmlProp]"></span>
                </div>
            </div>
            <div *ngIf="showGroup && groupByProperty">
                <div *ngFor="let group of options | groupBy: groupByProperty">
                    <div class="group-header">
                        <div class="group-option-wrapper"
                             (click)="$event.stopPropagation();groupExpandedState[group.key] = !groupExpandedState[group.key]">
                            <div class="selected-option">
                                <ng-container *ngIf="group.value?.length > 0">
                                    <div class="option"
                                         (click)="$event.stopPropagation();isGroupPropertySelection? selectOption(group.value[0]):null;">
                                        <mat-icon color="primary"
                                                  *ngIf="isGroupPropertySelection"
                                                  [svgIcon]="isSelected(group.value[0][dropdownConfig.valueKey]) ? 'checkbox-checked' : 'checkbox-unchecked'"
                                                  aria-hidden="false"></mat-icon>

                                        <mat-icon *ngIf="groupIcon && groupIcon.type.toLowerCase() === 'svg'"
                                                  [svgIcon]="groupIcon.name"
                                                  aria-hidden="false"
                                                  [style]="groupIcon.style"
                                                  [attr.color]="groupIcon.color"></mat-icon>

                                        <mat-icon *ngIf="groupIcon && groupIcon.type === 'material'"
                                                  aria-hidden="false"
                                                  [style]="groupIcon.style"
                                                  [attr.color]="groupIcon.color">{{ groupIcon.name }}</mat-icon>
                                        <img *ngIf="groupIcon && groupIcon.type === 'image'"
                                             [src]="groupIcon.name"
                                             [style]="groupIcon.style"
                                             [alt]="groupIcon.name" />
                                        <div class="round-text"
                                             *ngIf="groupAvatarText && group.value[0][groupAvatarText] ">
                                            {{group.value[0][groupAvatarText]}}
                                        </div>
                                        {{group.key}} {{ groupBySubtitle && group.value[0][groupBySubtitle] ? '(' + group.value[0][groupBySubtitle] +')':'' }}
                                    </div>
                                </ng-container>
                            </div>
                            <mat-icon class="placeholder-icon"
                                      [svgIcon]="groupExpandedState[group.key] ? 'dropdown-up' : 'dropdown'"
                                      aria-hidden="false"
                                      aria-label="dropdown icon"></mat-icon>
                        </div>
                    </div>
                    <div class="group-wrapper"
                         [style.display]="groupExpandedState[group.key] ? 'none': ''"
                         [style.opacity]="groupExpandedState[group.key] ? '0': '1'">
                        <div *ngFor="let option of group.value"
                             (click)="selectOption(option)"
                             class="option"
                             [ngClass]="{'selected-item': isSelected(option[dropdownConfig.valueKey])}">

                            <mat-icon *ngIf="config.multiple && !isGroupPropertySelection"
                                      color="primary"
                                      [svgIcon]="isSelected(option[dropdownConfig.valueKey]) ? 'checkbox-checked' : 'checkbox-unchecked'"
                                      aria-hidden="false"></mat-icon>

                            <mat-icon *ngIf="option.icon && option.icon.type.toLowerCase() === 'svg'"
                                      [svgIcon]="option.icon.name"
                                      aria-hidden="false"
                                      [style]="option.icon.style"
                                      [attr.color]="option.icon.color"></mat-icon>

                            <mat-icon *ngIf="option.icon && option.icon.type === 'material'"
                                      aria-hidden="false"
                                      [style]="option.icon.style"
                                      [attr.color]="option.icon.color">{{ option.icon.name }}</mat-icon>

                            <img *ngIf="option.icon && option.icon.type === 'image'"
                                 [src]="option.icon.name"
                                 [style]="option.icon.style"
                                 [alt]="option.icon.name" />

                            {{ option[dropdownConfig.labelKey] | translate}}
                            <span [innerHTML]="option[htmlProp]"
                                  class="html-container"
                                  *ngIf="htmlProp && htmlPropMode && htmlPropMode !== 'selection' && option[htmlProp]"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showAddOption && addOption"
                 class="add-option">
                <div (click)="newOptionClicked()"
                     class="option">
                    <mat-icon *ngIf="addOption.icon && addOption.icon.type.toLowerCase() === 'svg'"
                              [svgIcon]="addOption.icon.name"
                              aria-hidden="false"
                              [style]="addOption.icon.style"
                              [attr.color]="addOption.icon.color"></mat-icon>

                    <mat-icon *ngIf="addOption.icon && addOption.icon.type === 'material'"
                              aria-hidden="false"
                              [style]="addOption.icon.style"
                              [attr.color]="addOption.icon.color">{{ addOption.icon.name }}</mat-icon>

                    <img *ngIf="addOption.icon && addOption.icon.type === 'image'"
                         [src]="addOption.icon.name"
                         [style]="addOption.icon.style"
                         [alt]="addOption.icon.name" />

                    {{ addOption[dropdownConfig.labelKey] | translate}}
                </div>
            </div>
        </div>
    </div>

    <ng-content></ng-content>
</div>
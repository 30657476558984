<div class="attention-dialog-container fx-col">
    <div class="header">
        <div class="close-icon">
            <mat-icon svgIcon="cross"
                      (click)="cancelDialog()"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <mat-icon svgIcon="attention"></mat-icon>
        <h4>{{'attention'| translate}}!</h4>
        <p>{{'ai_inference_wait'| translate}}</p>
    </div>
</div>
<div class="dialog-container">
  <div class="header">
      <h3>{{data.title | translate}}</h3>
      <div class="close-icon" (click)="cancel();">
          <mat-icon [svgIcon]="'cross'"></mat-icon>
      </div>
  </div>
  <div mat-dialog-content class="email-dialog-body">
      <div class="form-container">
          <div class="input-wrapper" *ngIf="data?.channel === 'email'">
              <div class="div-wrapper">
                  <chq-input [inputModel]="emailForm.email" (controlOutput)="onFormUpdate($event)">
                  </chq-input>
              </div>
          </div>
          <div class="input-wrapper" *ngIf="data?.channel === 'whatsapp' || data.channel === 'sms'">
              <div class="div-wrapper fx-row" style="gap: 10px;">
                  <chq-input #code [inputModel]="emailForm.code" (controlOutput)="onFormUpdate($event)" style="width: 115px;"></chq-input>
                  <chq-input #phone [inputModel]="emailForm.phone" (controlOutput)="onFormUpdate($event)"></chq-input>
              </div>
          </div>
          <div class="input-wrapper" *ngIf="(data?.channel === 'email' || data?.channel === 'whatsapp' || data.channel === 'sms' || data.channel === 'send_to_insurance_company') && estimateDocumentTypes?.length > 0">
            <div class="div-wrapper">
              <chq-input class="col-6 repair-type" [inputModel]="emailForm.reportType" (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
        </div>

      </div>
      <ng-container *ngIf="data.invoiceReportFileName; else attachmentTemplate">
        <div class="form-container">
        <div class="input-wrapper invoice-attachment">
          <label class="title" for="">{{'invoice_attachment' | translate | uppercase }}</label>
          <div class="content">
            <span class="name">{{data.invoiceReportFileName}}</span>
            <mat-icon svgIcon="attachment-reference"></mat-icon>
          </div>
        </div>
          <div class="input-wrapper"></div>
        </div>
      </ng-container>
      <ng-template #attachmentTemplate>
        <span *ngIf="data?.channel === 'email'" class='file-size-msg'>
        {{ 'maximum_total_attachment_size_30MB' | translate}}
        </span>
      </ng-template>
      <div *ngIf="data?.channel === 'email'" class="form-container">
          <div class="input-wrapper">
              <div class="div-wrapper">
                <chq-input class="col-6 repair-type" [inputModel]="emailForm.photos" (controlOutput)="onFormUpdate($event)"></chq-input>
              </div>
          </div>

          <div style='width: 40%;' class="input-wrapper">
              <div class="div-wrapper">
                <chq-input class="col-6 repair-type" [inputModel]="emailForm.documents" (controlOutput)="onFormUpdate($event)"></chq-input>
              </div>
          </div>
      </div>
      <div *ngIf="data?.channel === 'email'" class="attachment-container">
        <div class="col">
          <input hidden  type="file" #uploadPhoto (change)="handleUpload($event, 'photo')" accept="image/png, image/jpg, image/jpeg,">
          <chq-button [buttonModel]="imgAttachmentsBtnModel"></chq-button>
          <div class='attachment-list'>
            <div *ngFor="let item of emailForm.photos?.selectedOption;let i = index" class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <mat-icon class='red-cross' [svgIcon]="'cross'" (click)="removeFile(i,'photo')"></mat-icon>
            </div>
            <div *ngFor="let item of additionalPhotos;let i = index" class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <mat-icon class='red-cross' [svgIcon]="'cross'" (click)="removeFile(i,'additionalPhotos')"></mat-icon>
            </div>
          </div>
        </div>
        <div class="col">
          <input hidden  type="file" #uploadDoc (change)="handleUpload($event, 'document')"  accept="image/png, image/jpg, image/jpeg, application/pdf">
          <chq-button [buttonModel]="docAttachmentsBtnModel"></chq-button>
          <div class='attachment-list'>
            <div  *ngFor="let item of emailForm.documents?.selectedOption;let i = index" class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <mat-icon class='red-cross' [svgIcon]="'cross'" (click)="removeFile(i,'document')"></mat-icon>
            </div>
            <div  *ngFor="let item of additionalDocs;let i = index" class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <mat-icon class='red-cross' [svgIcon]="'cross'" (click)="removeFile(i,'additionalDocs')"></mat-icon>
            </div>
          </div>
        </div>
      </div>


      <!-- Email message container  -->
      <section class='message-container' *ngIf="data.channel !== 'send_to_insurance_company'">
        <div style='display: flex;gap: 0.5rem;margin-top: 5px;flex-direction: column;' class="input-wrapper label-wrapper" *ngIf="data?.channel === 'email' && data.emailSubject">
            <label class="title">{{'subject' | translate}} </label>
            <label [innerHTML]="data.emailSubject"></label>
        </div>
        <div style='background: #FFF;padding: 1rem 0.875rem;border-radius: 0.625rem;' class="input-wrapper label-wrapper">
          <label class="title">{{'message' | translate}} </label>
          <label class="message-content" [innerHTML]="data.body"></label>
        </div>
      </section>
      <!-- <div class="attachment-list" *ngIf="data?.channel === 'email'">
          <label class="attachment-header">
              {{'attachments' | translate | uppercase}}
          </label>
          <div class="files">
              <label>Repair Estimate</label>
              <div class="attachment-icon">
                  <mat-icon [svgIcon]="'pdf-file'"></mat-icon>
              </div>

          </div>
      </div> -->
  </div>
  <div class="btn-container">
      <chq-button [buttonModel]="sendButton" class="right"></chq-button>
  </div>
</div>

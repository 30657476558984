import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { AddGroupCategoryDialog } from 'src/app/dialogs/add-group-category-dialog/add-group-category-dialog.component';



export interface PartNode {
  name: string;
  value?: string;
  applyAll?: boolean;
  id?: number;
  guid?:string;
  children?: PartNode[];
  isParent?:boolean
}

@Component({
  selector: 'tree-view',
  templateUrl: './tree-component.html',
  styleUrls: [ './tree-component.scss' ]
})
export class TreeComponent implements OnInit, OnChanges {

  @Input() treeData: PartNode[];
  @Input() showNewBtn: boolean;
  @Input() applyAllMainCategories: boolean;
  @Input() applyAllMainCategoriesAmount: string;
  @Input() multipleInput: boolean;
  @Output() groupSelected = new EventEmitter();
  @Output() applyAll = new EventEmitter();
  @Output() updateNode = new EventEmitter();
  @Output() newGroupAddition = new EventEmitter();
  activeCategoryName:string = '';
  selectedNode: PartNode | null = null;

  @ViewChildren('parentCheckbox') checkboxes: any;


  treeControl = new NestedTreeControl<PartNode>(
    node => node.children,
  );

  dataSource = new MatTreeNestedDataSource();

  /**
   * Sets the data of the tree
   * @param data - array of nested objects that represent the tree
   */
  constructor(private readonly dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef,
  ) {
  }

  /**
     * ngOnChanges
     */
  ngOnChanges(changes: SimpleChanges): void {
    if(!this.multipleInput) {
      if (changes && changes['treeData']) {
        try{
          if (this.treeData && this.treeData.length > 0) {
            this.dataSource.data = [ ...this.treeData ];
          }
        }catch(e){
          console.log(e);
        }
      }
    }
  }

  /**
   * 
   * @param nodes 
   * @param name 
   * @returns 
   */
  findNodeByGuid(nodes: PartNode[], guid: string): PartNode | null {
    for (const node of nodes) {
      if (node.guid === guid) {
        return node;
      }
      if (node.children) {
        const foundInChildren = this.findNodeByGuid(node.children, guid);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  /**
   * 
   * @param guid 
   */
  scrollToNode(guid: string, groupGuid:string):void {
    const node = this.findNodeByGuid(this.dataSource.data as any[], guid);
    this.expandNode(node);
    if(!groupGuid){
      const nodeElement = document.getElementById(guid);
      if(nodeElement){
        setTimeout(()=>{
          nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        })
      }
      return;
    } 
    const nodeElement = document.getElementById(groupGuid);
    if (nodeElement) {
      const groupNode = this.findNodeByGuid(this.dataSource.data as any[], groupGuid);
      this.selectedNode = groupNode;
      this.nodeClick(groupNode);
      setTimeout(()=>{
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      })
      
    }
  }

  /**
   * expand node
   */
  expandNode(node):void{
    this.treeControl.expand(node);
  }

  /**
   * node clicked
   */
  nodeClick($event):void{
    this.selectedNode = $event;
    this.groupSelected.emit({ ...$event, category: this.activeCategoryName });
  }

  /**
   * apply all click
   * @param $event 
   */
  applyAllRadio($event: boolean, node: any): void {
    if ($event) {
      const data: any = this.treeData?.find(q=>q.id == node.id);
      if (data?.children?.length > 0) {
        data.applyAll = true;
        data.children.forEach((children: any) => {
          children.value = node.value;
        });
        setTimeout(()=>{
          this.treeData = [ ...this.treeData ];
          this.dataSource.data = this.treeData;
          this.treeControl.expand(node);
          this.cdRef.detectChanges();
          this.treeControl.expand(node);
        }, 500);
      }
    }
    this.applyAll.emit({ data: this.treeData, applyAll: $event } );
  }

  /**
   * update node value
   * @param node 
   * @param newValue 
   */
  updateNodeValue(node: any, newValue: any): void {
    node.value = newValue;
    this.treeControl.expand(node);
    if (node.isParent) {
      const obj = this.treeData?.find(q => q.name == node.name && q.id == node.id);
      obj.value = node.value;
      obj.applyAll = false;

      const parentCheckbox = this.checkboxes._results.find(q=>q.distinctId == node.id);

      if (parentCheckbox) {
        parentCheckbox.value = false;
      }
      this.treeData = [ ...this.treeData ];
    } else if (!node.isParent) {
      const parent = this.treeData?.find(q => q.children?.find(q => q.id == node.id));
      if (parent) {
        parent.applyAll = false;
        const obj = parent.children?.find(q => q.id == node.id)
        obj.value = node.value;

        const parentCheckbox = this.checkboxes._results.find(q=>q.distinctId == parent.id);
        if (parentCheckbox) {
          parentCheckbox.value = false;
        }
        
        this.treeData = [ ...this.treeData ];
        setTimeout(()=> {
          this.cdRef.detectChanges();
        }, 500);

      }
    }

    this.updateNode.emit({ data: this.treeData, applyAll: false });
  }

  /**
   * reinitialize tree
   */
  reInitializeTree(): void {
    this.dataSource.data = this.treeData;
    this.treeControl.expandAll();
  }

  /**
   * 
   * @param _ 
   * @param node 
   * @returns 
   */
  ngOnInit():void {
    if (this.treeData && this.treeData.length > 0) {
      this.dataSource.data = [ ...this.treeData ];
    }
  }

  /**
   * 
   * @param _ 
   * @param node 
   * @returns 
   */
  addNewGroup(guid:string):void{
    this.dialog.open(AddGroupCategoryDialog, {
      data: {
        title: 'add_new_group',
        label: 'group_name',
        placeholder: 'group_name', 
        maxLength: 50
      }
    }).afterClosed().subscribe({
      next: (groupName: string) => {
        if (groupName) {
          this.newGroupAddition.emit({ guid, groupName });
        }
      }
    });
  }

  hasChild = (_: number, node: PartNode):boolean => {
    return node.children?.length > 0 || node.isParent
  };
}
<chq-upload [isCurrentAdditionalModeMultiple]="xaReviewOptions.multipleUpload"
            [isMultiple]="xaReviewOptions.multipleUpload"
            [currentStep]="selectedStep"
            #xaUpload
            (imageOutput)="handleImageOutput($event);"></chq-upload>
<div class="file-wrapper">
    <div class="header">
        <div class="header-container">
            <div class="left-section">
                <div class="content-wrapper-left row-wrapper">
                    <div class="back-wrapper"
                         *ngIf="showBack || uploadedFiles?.length === 0"
                         (click)="handleBack()">
                        <div class="d-flex align-center">
                            <mat-icon svgIcon="arrow-back-left"
                                      class="back-icon"
                                      aria-hidden="false"
                                      aria-label='back icon'></mat-icon>
                        </div>
                        <div class="title-wrapper back-text back-section title-text">
                            <h4>{{'back' | translate}}</h4>
                        </div>
                    </div>
                </div>
                <label *ngIf="fileList?.length > 0 && xaReviewOptions.label">{{xaReviewOptions.label}}</label>
            </div>
            <ng-container *ngIf="fileList?.length > 0">
                <div class="content-wrapper-right">
                    <xa-button [type]="btn.btnType"
                               [color]="btn.btnColor"
                               [hidden]="(btn.requireFileUpload && uploadedList?.length === 0)"
                               *ngFor="let btn of xaReviewOptions.headerActions;let i = index "
                               (click)="handleIconActions(btn);">
                        <mat-icon [svgIcon]="btn?.icon"
                                  *ngIf="btn?.icon"
                                  aria-hidden="false"
                                  aria-label='right icon'></mat-icon>
                        <div class="title-wrapper back-text back-section">
                            <h4>{{btn?.label | translate}}</h4>
                        </div>
                    </xa-button>
                </div>
            </ng-container>
        </div>
    </div>

    <span class="mandatory-index"
          *ngIf="isMandatory()">* {{'mandatory_fields' | translate}}</span>
    <div class="file-review-wrapper row">
        <div class="file-review-card"
             *ngFor="let file of fileList; let i = index">
            <div class="file-card-actions">
                <ng-container>
                    <ng-container *chqPermission="xaReviewOptions.permissionTag">
                        <label for=""
                               *ngIf="file.procedureSteps  === 'Bulk Upload Image'">{{file.procedureSteps | translate}}</label>
                        <label for=""
                               *ngIf="file.procedureSteps  !== 'Bulk Upload Image' && (file.stepName || file.parentName)">{{file.stepName | translate}} <span class="mandatory"
                                  *ngIf="isStepMandatory(file)">&nbsp;*</span></label>
                        <div class="step-action-icon"
                             *ngIf="file?.action?.length > 0">
                            <div class="icon-div"
                                 [ngClass]="action.iconWrapperClass"
                                 *ngFor="let action of file?.action; let i = index">
                                <xa-button [type]="action.buttonType"
                                           *ngIf="action.displayAsButton"
                                           (click)="$event.stopPropagation();handleAction(action, file);">
                                    <mat-icon [svgIcon]="action.icon"
                                              [ngClass]="action.iconClass"
                                              aria-hidden="false"
                                              aria-label="unchecked icon">
                                    </mat-icon>
                                    <span>{{action.text}}</span>
                                </xa-button>
                                <mat-icon *ngIf="!action.displayAsButton"
                                          [svgIcon]="action.icon"
                                          [ngClass]="action.iconClass"
                                          class="delete-icon border-light-gray"
                                          aria-hidden="false"
                                          (click)="$event.stopPropagation();handleAction(action, file);"
                                          aria-label="unchecked icon">
                                </mat-icon>
                                <div class="red-icon"
                                     *ngIf="action.displayIndicator && !action.displayAsButton && 
                            (file?.inspectionItem?.inspectionNote && file?.inspectionItem?.inspectionNote[0]?.text)">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="step-title ai-status-text">
                <span *ngIf="xaReviewOptions?.aiReviewSection">{{file.analysisStatus ==='Analysis Failed' ? 'ai_analysis_not_available':(file.analysisStatus ==='Analysis in Progress'?'analyzing':
                    'Analyzed')}}</span>
                <span *ngIf="!xaReviewOptions?.aiReviewSection">{{(file.uploadInProgress
                    ? 'upload_inprogress' : (file.isUploadSuccess ? 'upload_successfully': 'not_uploaded')) | translate}}</span>
            </div>
            <div class="file-card-preview">
                <div class="file-image"
                     [ngClass]="{'document': xaReviewOptions?.type === 'document'}">
                    <ng-container *ngIf="file.contentType !=='application/pdf'">
                        <img alt=""
                             class="has-image"
                             *ngIf="file.image else serverImage"
                             (error)="setImageLoading(file.index + file?.stepName);"
                             (load)="setImageLoading(file.index + file?.stepName)"
                             [src]='file.image' />
                        <ng-template #serverImage>
                            <img alt=""
                                 class="has-image"
                                 *ngIf="(file.thumbnailUrl || file.url) else sampleImage"
                                 (error)="setImageLoading(file.index + file?.stepName);"
                                 (click)="imageDialog('',file?.url, file.procedureSteps  === 'Bulk Upload Image' ? file.procedureSteps: file.stepName, file);"
                                 (load)="setImageLoading(file.index + file?.stepName)"
                                 [src]='file?.thumbnailUrl ?? file.url' />

                        </ng-template>
                        <ng-template #sampleImage>
                            <img alt=""
                                 [ngClass]="{'has-image': xaReviewOptions?.aiReviewSection}"
                                 [src]='file.placeHolderUrl'
                                 (error)="setImageLoading(file.index + file?.stepName);"
                                 (load)="setImageLoading(file.index + file?.stepName);" />
                        </ng-template>
                        <mat-icon svgIcon="enlarge"
                                  class="enlarge-ico"
                                  aria-hidden="false"
                                  (click)="imageDialog('',(file?.image || file.url) || file.placeHolderUrl,file.procedureSteps  === 'Bulk Upload Image' ? file.procedureSteps: file.stepName, file);"
                                  aria-label="unchecked icon">
                        </mat-icon>
                        <mat-icon [svgIcon]="'clock'"
                                  class="status-ico"
                                  *ngIf="xaReviewOptions?.aiReviewSection && file.analysisStatus ==='Analysis in Progress' else navail"></mat-icon>
                        <ng-template #navail>
                            <mat-icon *ngIf="(xaReviewOptions?.aiReviewSection  && file.analysisStatus ==='Analysis Failed') else checked"
                                      class="status-ico"
                                      [svgIcon]="'xmark'"
                                      aria-hidden="false"
                                      aria-label="checked icon"></mat-icon>
                        </ng-template>
                        <ng-template #checked>
                            <mat-icon *ngIf="(file.uploadStatus ==='success' || file.url) else unchecked"
                                      [svgIcon]="'checked'"
                                      class="status-ico"
                                      aria-hidden="false"
                                      aria-label="checked icon"></mat-icon>
                        </ng-template>

                        <ng-template #unchecked>
                            <mat-icon [svgIcon]="'unchecked'"
                                      aria-hidden="false"
                                      class="status-ico"
                                      aria-label="unchecked icon"></mat-icon>
                        </ng-template>
                        <chq-custom-loader [showLoading]="!imageLoading[file.index + file?.stepName] || (file?.uploadInProgress && !xaReviewOptions?.aiReviewSection)"></chq-custom-loader>
                    </ng-container>
                    <ng-container *ngIf="file.contentType ==='application/pdf'">
                        <mat-icon class='pdf-icon'
                                  [svgIcon]="'pdf-file'"
                                  aria-hidden="false"
                                  aria-label="pdf-file icon"></mat-icon>
                        <chq-custom-loader [showLoading]="(file?.uploadInProgress && !xaReviewOptions?.aiReviewSection)"></chq-custom-loader>
                    </ng-container>
                </div>
            </div>
            <div class="step-title date-text">
                <span *ngIf="!file.isAdditionalDocumentFromReviewPageOnly && file.stepName.indexOf('Additional') === -1">{{( ( file.updatedDate && file.updatedDate !== '0001-01-01T00:00:00' )?
                    file.updatedDate:file.uploadedDate) | date:'dd/MM/yyyy HH:mm aa'}}</span>
                <span *ngIf="file.isAdditionalDocumentFromReviewPageOnly && file.stepName.indexOf('Additional') !== -1">
                    &nbsp;</span>
            </div>
            <div class="upload"
                 *ngIf="!file.image && !file.url && !xaReviewOptions.aiReviewSection && xaReviewOptions.isEditable">
                <xa-button size="sm"
                           (click)="triggerFileUpload(file);"> <mat-icon [svgIcon]="'upload'"
                              aria-hidden="false"
                              aria-label="icon"></mat-icon>{{'upload' | translate}}</xa-button>
            </div>
        </div>
        <div class="consolidated-wrapper"
             [ngClass]="{'align-item-center': xaReviewOptions.isEditable}"
             *ngIf="false">
            <h4 *ngIf="xaReviewOptions.isEditable">{{'or' | translate}}</h4>
            <div class="file-review-card"
                 *ngIf="consolidatedFile?.url || consolidatedFile?.image || !xaReviewOptions.isEditable">
                <div class="file-card-actions">
                    <ng-container>
                        <ng-container *chqPermission="xaReviewOptions.permissionTag">
                            <label for=""
                                   *ngIf="(consolidatedFile.stepName || consolidatedFile.parentName)">{{consolidatedFile.stepName | translate}} <span class="mandatory"
                                      *ngIf="isStepMandatory(consolidatedFile)">&nbsp;*</span></label>
                            <div class="step-action-icon"
                                 *ngIf="consolidatedFile?.action?.length > 0">
                                <div class="icon-div"
                                     [ngClass]="action.iconWrapperClass"
                                     *ngFor="let action of consolidatedFile?.action; let i = index">
                                    <xa-button [type]="action.buttonType"
                                               *ngIf="action.displayAsButton"
                                               (click)="$event.stopPropagation();handleAction(action, consolidatedFile);">
                                        <mat-icon [svgIcon]="action.icon"
                                                  [ngClass]="action.iconClass"
                                                  aria-hidden="false"
                                                  aria-label="unchecked icon">
                                        </mat-icon>
                                        <span>{{action.text}}</span>
                                    </xa-button>
                                    <mat-icon *ngIf="!action.displayAsButton"
                                              [svgIcon]="action.icon"
                                              [ngClass]="action.iconClass"
                                              class="delete-icon border-light-gray"
                                              aria-hidden="false"
                                              (click)="$event.stopPropagation();handleAction(action, consolidatedFile);"
                                              aria-label="unchecked icon">
                                    </mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="step-title ai-status-text">
                    <span>{{(consolidatedFile.uploadInProgress
                        ? 'upload_inprogress' : (consolidatedFile.isUploadSuccess ? 'upload_successfully': 'not_uploaded')) | translate}}</span>
                </div>
                <div class="file-card-preview">
                    <div class="file-image"
                         [ngClass]="{'document': xaReviewOptions?.type === 'document'}">
                        <ng-container *ngIf="consolidatedFile.contentType !=='application/pdf'">
                            <img alt=""
                                 class="has-image"
                                 *ngIf="consolidatedFile.image else serverImage"
                                 (error)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName);"
                                 (load)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName)"
                                 [src]='consolidatedFile.image' />
                            <ng-template #serverImage>
                                <img alt=""
                                     class="has-image"
                                     *ngIf="(consolidatedFile.thumbnailUrl || consolidatedFile.url) else sampleImage"
                                     (error)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName);"
                                     (click)="imageDialog('',consolidatedFile?.url, consolidatedFile.procedureSteps  === 'Bulk Upload Image' ? consolidatedFile.procedureSteps: consolidatedFile.stepName, consolidatedFile);"
                                     (load)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName)"
                                     [src]='consolidatedFile?.thumbnailUrl ?? consolidatedFile.url' />

                            </ng-template>
                            <ng-template #sampleImage>
                                <img alt=""
                                     [ngClass]="{'has-image': xaReviewOptions?.aiReviewSection}"
                                     [src]='consolidatedFile.placeHolderUrl'
                                     (error)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName);"
                                     (load)="setImageLoading(consolidatedFile.index + consolidatedFile?.stepName);" />
                            </ng-template>
                            <mat-icon svgIcon="enlarge"
                                      class="enlarge-ico"
                                      aria-hidden="false"
                                      (click)="imageDialog('',(consolidatedFile?.image || consolidatedFile.url) || consolidatedFile.placeHolderUrl,consolidatedFile.procedureSteps  === 'Bulk Upload Image' ? consolidatedFile.procedureSteps: consolidatedFile.stepName, consolidatedFile);"
                                      aria-label="unchecked icon">
                            </mat-icon>

                            <ng-template #checked>
                                <mat-icon *ngIf="(consolidatedFile.uploadStatus ==='success' || consolidatedFile.url) else unchecked"
                                          [svgIcon]="'checked'"
                                          class="status-ico"
                                          aria-hidden="false"
                                          aria-label="checked icon"></mat-icon>
                            </ng-template>

                            <ng-template #unchecked>
                                <mat-icon [svgIcon]="'unchecked'"
                                          aria-hidden="false"
                                          class="status-ico"
                                          aria-label="unchecked icon"></mat-icon>
                            </ng-template>
                            <chq-custom-loader [showLoading]="!imageLoading[consolidatedFile.index + consolidatedFile?.stepName] || (consolidatedFile?.uploadInProgress && !xaReviewOptions?.aiReviewSection)"></chq-custom-loader>
                        </ng-container>
                        <ng-container *ngIf="consolidatedFile.contentType ==='application/pdf'">
                            <mat-icon class='pdf-icon'
                                      [svgIcon]="'pdf-file'"
                                      aria-hidden="false"
                                      aria-label="pdf-file icon"></mat-icon>
                            <chq-custom-loader [showLoading]="(consolidatedFile?.uploadInProgress && !xaReviewOptions?.aiReviewSection)"></chq-custom-loader>
                        </ng-container>
                    </div>
                </div>
                <div class="step-title date-text">
                    <span *ngIf="!consolidatedFile.isAdditionalDocumentFromReviewPageOnly && consolidatedFile.stepName.indexOf('Additional') === -1">{{( ( consolidatedFile.updatedDate && consolidatedFile.updatedDate !== '0001-01-01T00:00:00' )?
                        consolidatedFile.updatedDate:consolidatedFile.uploadedDate) | date:'dd/MM/yyyy HH:mm aa'}}</span>
                    <span *ngIf="consolidatedFile.isAdditionalDocumentFromReviewPageOnly && consolidatedFile.stepName.indexOf('Additional') !== -1">
                        &nbsp;</span>
                </div>
            </div>
            <div class="button-consolidated"
                 *ngIf="consolidatedFile && !consolidatedFile?.url && !consolidatedFile?.image && xaReviewOptions.isEditable">
                <xa-button size="md"
                           (click)="triggerFileUpload(consolidatedFile);">
                    <mat-icon [svgIcon]="'upload'"
                              aria-hidden="false"
                              aria-label="icon"></mat-icon>
                    {{'upload_consolidated' | translate}}</xa-button>
            </div>
        </div>
    </div>
    <div class="bulk-wrapper">
        <label *ngIf="fileList?.length > 0 && xaReviewOptions.enableBulk && xaReviewOptions.isEditable">{{'upload_bulk' | translate}}</label>
        <div class="mass-upload"
             *ngIf="xaReviewOptions.enableBulk  && xaReviewOptions.isEditable">
            <img src="assets/icons/no-document.svg"
                 alt="document" />
            <ng-container *ngIf="!platform.ANDROID && !platform.IOS; else elseTemplate">
                <label for="">{{ 'drag_drop' | translate }}</label>
            </ng-container>
            <ng-template #elseTemplate>
                <label for="">{{ 'upload_here' | translate }}</label>
            </ng-template>
        </div>
    </div>
    <div *ngIf="fileList?.length === 0 && xaReviewOptions?.aiReviewSection && !commonService.isLoading"
         class="no-images-container">
        <label for=""
               class="no-data">Sorry, no vehicle images found for AI analysis.</label>
    </div>
    <div class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img alt=""
                     src="assets/img/{{ xaReviewOptions.footerLogo  || 'addenda.png'}}" />
            </div>
        </div>
        <div class="footer-button-wrapper"
             *chqPermission="xaReviewOptions.permissionTag">
            <ng-content></ng-content>
        </div>
    </div>
</div>
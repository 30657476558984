<div class="garage-assignment-preference">
    <div class="header">
        <!-- <h3>Do you want to assign this claim to this garage.</h3> -->
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="main-container">
        <div class="preference-text">{{'save_garage_preference' | translate }}</div>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="noButton" class="left"></chq-button>
        <chq-button [buttonModel]="yesButton" class="right"></chq-button>
    </div>
</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-claim-estimate',
  templateUrl: './claim-estimate.component.html',
  styleUrls: [ './claim-estimate.component.scss' ]
})
export class ClaimEstimateComponent {

  @Input() claimId: string = '';  
  @Input() dialogRef: any;  
}

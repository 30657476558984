import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { DynamicFormComponent } from 'src/app/config/core/dynamic-form/dynamic-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { startWith, pairwise } from 'rxjs';

@Component({
  selector: 'customer',
  standalone: true,
  imports: [ CommonModule, ReactiveFormsModule, DynamicFormComponent, TranslateModule ],
  providers: [ DynamicFormService ],
  templateUrl: './customer.component.html',
  styleUrls: [ './customer.component.scss' ]
})
export class CustomerComponent implements OnChanges {
  @Input() formControls: DynamicFormBase<string>[] | null = [];
  customerFormGroup: FormGroup;
  controls: DynamicFormBase<string>[] | null = [];

  /**
   * constructor
   */
  constructor(
    private dynamicFormService: DynamicFormService
  ) {
  }

  /**
   * change event
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControls']?.currentValue) {
      this.controls = this.dynamicFormService.getSortedFormControls(this.formControls);
      this.customerFormGroup = this.dynamicFormService.createFormGroup(this.formControls);

      this.customerFormGroup.get('work_provider')?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ prev, next ]: [any, any]) => {
          if (prev !== next) {
            this.customerFormGroup.get('company_name').setValue('');
            const companyControl = this.controls.find(x => x.key === 'company_name' && x.controlType === 'dropdown');
            if (companyControl?.optionsByUrl && next !== 'Retail') {
              if (prev) {
                companyControl.optionsByUrl.url = companyControl.optionsByUrl.url.replace(prev, next);
              } else {
                companyControl.optionsByUrl.url = companyControl.optionsByUrl.url.replace('{work_provider}', next);
              }
              this.controls = [ ...this.controls ];
            }

            const claimNoControl = this.controls.find(x => x.key === 'claimNumber' && x.controlType === 'textbox');
            if (claimNoControl && next !== 'Insurance Company') {
              this.customerFormGroup.get('claimNumber').setValue('');
              this.controls = [ ...this.controls ];
            }
          }
        });

      this.customerFormGroup.get('code')?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ prev, next ]: [any, any]) => {
          if (prev !== next) {
            // this.dynamicFormService.currentMessage.subscribe((optVals) => {
            //   if (optVals) {
            //     const selectedOption = optVals.filter(country => country.phoneCountryCode === next);
            //     if (selectedOption) {
            //       const driverPhoneControl = this.controls.find(x => x.key === 'driverPhone' && x.controlType === 'textbox');

            //       if (driverPhoneControl && selectedOption.length > 0) {
            //         const selectedRegex = selectedOption[0].regExpression;
            //         const patternValidatorIndex = driverPhoneControl.validators.findIndex(validator => validator.type === 'pattern');

            //         if (patternValidatorIndex !== -1) {
            //           driverPhoneControl.validators[patternValidatorIndex].regex = selectedRegex;
            //           this.customerFormGroup.get('driverPhone').clearValidators();
            //           // driverPhoneControl.required = true;
            //           this.customerFormGroup.get('driverPhone').setValidators(this.dynamicFormService.createValidators(driverPhoneControl));
            //         }
            //         this.controls = [ ...this.controls ];
            //       }
            //     }
            //   }
            // });
          }
        })
    }
  }
}

import { XADropdownConfig, OptionUrlConfig } from 'src/app/model/xa-dropdown-model';

export type OptionKey = {
  label: string;
  value: string;
}

export type ButtonAction = {
  actionType: 'submit' | 'link' | 'alert' | 'custom',
  formSubmitMethod?:string,
  formSubmitService?:string,
  formSubmitUrl?:string,
  linkUrl?:string,
  alertMessage?:string,
  customAction?:(param:any)=>void
}

export type Validator = {
  type: 'email' | 'maxlength' | 'minlength' | 'numeric' | 'pattern' | 'custom' | 'required';
  regex?: string;
  message?: string;
  customType?: string;
  customFunction?: any;
}

export type ControlTypes = 'textbox' | 'numeric' | 'textarea' | 'dropdown' | 'multiselect-dropdown' | 'radio' | 'checkbox' | 'checkbox-group' | 'toggle' | 'html' | 'date' | 'datetime' | 'time' | 'places' | 'rating' | 'recommendation' | 'tel' | 'search' | 'section' | 'title' | 'description' | 'input-group' | 'color-picker' | 'label' | 'button';

export type Alignment = 'left' | 'right';

export type Position = 'start' | 'end';

export type PrefillControl = {
  controlName:string;
  prefillAttribute:string;
}

export type Layout = {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  alignment?: Alignment;
  fullWidth?: boolean;
}

export const defaultLayout: Layout = {
  width: '50%',
  minWidth: '150px'
}
export class DynamicFormBase<T> {
  value?: T | undefined;
  key: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  requiredIf?:boolean;
  visible?: boolean;
  visibleIf?: string;
  order?: number;
  controlType?: ControlTypes;
  dropdownConfig?: XADropdownConfig;
  options?: Array<any>;
  allOption?:any;
  optionsByUrl?: OptionUrlConfig;
  optionKeys?: OptionKey;
  validators?: Array<Validator>;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  disabledIf?: string;
  layout?: Layout;
  prefillMinLength?:number;
  prefillControls?:PrefillControl[];
  rows?: number;
  visibleElement?: string;
  position?: Position;
  blockMaxLength?: boolean;
  min?: number;
  max?: number;
  minDate?: Date;
  maxDate?: Date;
  buttonAction?:ButtonAction;
  conditions?:any[];
  textPrefillValue?:string;
  defaultValue?:string;
  icon?: string;
  childControls?:DynamicFormBase<T>[];
  showAllOption?:boolean;
  showInput?:boolean;
  hint?: string;
  childControl?:DynamicFormBase<T>;
  showRadioLabel?:boolean;
  onSelection?:(any)=>void;
  enableSearch?:boolean;
  prepend?:boolean;
  btnType?:string;
  btnColor?:string;
  btnSize?:string;
  onSearch?:(searchText:string, any:any[])=>void;
  hintIcon?:string;
  step?:number;
  /**
   * constructor
   * @param options 
   */
  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    requiredIf?:boolean;
    visible?: boolean;
    visibleIf?: string;
    order?: number;
    controlType?: ControlTypes;
    dropdownConfig?: XADropdownConfig;
    options?: Array<any>;
    optionsByUrl?: OptionUrlConfig;
    optionKeys?: OptionKey;
    validators?: Array<Validator>;
    maxLength?: number;
    minLength?: number;
    disabled?: boolean;
    disabledIf?: string;
    layout?: Layout;
    rows?: number;
    visibleElement?: string;
    position?: Position;
    blockMaxLength?: boolean;
    min?: number;
    max?: number;
    minDate?: Date;
    maxDate?: Date;
    buttonAction?:ButtonAction,
    conditions?:any[], 
    textPrefillValue?:string,
    defaultValue?:string,
    prefillControls?:PrefillControl[],
    prefillMinLength?:number,
    icon?: string,
    childControls?:DynamicFormBase<T>[],
    allOption?:any,
    showAllOption?:boolean,
    showInput?:boolean,
    hint?: string,
    childControl?:DynamicFormBase<T>,
    showRadioLabel?:boolean,
    onSelection?:(any)=>void,
    enableSearch?:any,
    onSearch?:(searchText:string, any:any[])=>void,
    btnType?:string,
    btnColor?:string,
    btnSize?:string,
    prepend?:boolean,
    hintIcon?:string,
    step?:number
  } = {}) {
    this.value = options.value || null;
    this.key = options.key || '';
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.required = options.required ?? false;
    this.visible = options.visible ?? true;
    this.visibleIf = options.visibleIf || null;
    this.order = options.order || 1;
    this.controlType = options.controlType || 'textbox';
    this.options = options.options || [];
    this.optionKeys = options.optionKeys || null;
    this.minLength = options.minLength || null;
    this.maxLength = options.maxLength || null;
    this.disabled = !!options.disabled;
    this.layout = { ...defaultLayout, ...options.layout };
    this.rows = options.rows || 4;
    this.disabledIf = options.disabledIf || null;
    this.optionsByUrl = options.optionsByUrl || null;
    this.validators = options.validators || null
    this.visibleElement = options.visibleElement || 'control';
    this.position = options.position || 'start';
    this.blockMaxLength = options.blockMaxLength || false;
    this.min = options.min;
    this.max = options.max;
    this.minDate = options.minDate;
    this.maxDate = options.maxDate;
    this.buttonAction = options.buttonAction;
    this.childControls = options.childControls || [];
    this.conditions = options.conditions;
    this.textPrefillValue = options.textPrefillValue;
    this.defaultValue = options.defaultValue;
    this.prefillControls = options.prefillControls;
    this.prefillMinLength = options.prefillMinLength;
    this.icon = options.icon;
    this.allOption = options.allOption;
    this.showAllOption = options.showAllOption;
    this.requiredIf = options.requiredIf;
    this.showInput = options.showInput;
    this.hint = options.hint || '';
    this.childControl = options.childControl;
    this.showRadioLabel = options.showRadioLabel;
    this.onSelection = options.onSelection;
    this.enableSearch = options.enableSearch;
    this.onSearch = options.onSearch;
    this.prepend = options.prepend;
    this.btnColor = options.btnColor;
    this.btnType = options.btnType;
    this.btnSize = options.btnSize;
    this.hintIcon = options.hintIcon;
    this.step = options?.step;
  }
}
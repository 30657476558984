
export const RecoveryDetailsForm = [
  {
    'IsActive': true,
    'ItemKey': 'date',
    'ItemValue': 'RECOVERY DATE',
    'Category': null,
    'SortOrder': '1',
    'Value': [
    
    ],
    'controlType': 'date',
    'label': 'date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'recoveryDate',
    'placeholder': 'date',
    'required': 'true',
    'minDate': new Date('2023-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'IsActive': true,
    'ItemKey': 'beneficiary',
    'ItemValue': 'BENEFICIARY',
    'Category': null,
    'SortOrder': '2',
    'Value': [
    ],
    'controlType': 'search',
    'label': 'Search',
    'icon': '',
    'visible': 'true',
    'fieldValue': 'beneficiary',
    'placeholder': 'Beneficiary',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '150',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': 'left',
    'fullWidth': 'false'
  }, {
    'IsActive': true,
    'ItemKey': 'recovery_type',
    'ItemValue': 'SELECT RECOVERY TYPE',
    'Category': null,
    'SortOrder': '3',
    'Value': [
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'recoveryTypeId',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/RecoveryType',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': '',
    'maxDate': '',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': '',
    'fullWidth': 'false'
  }, 
  {
    'IsActive': true,
    'ItemKey': 'claimant_name',
    'ItemValue': 'SELECT CLAIMANT NAME',
    'Category': null,
    'SortOrder': '4',
    'Value': [
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'claimantName',
    'required': 'true',
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': '',
    'maxDate': '',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': '',
    'fullWidth': 'false'
  }, 
  {
    'IsActive': true,
    'ItemKey': 'recovery_amount',
    'ItemValue': 'RECOVERY AMOUNT',
    'Category': null,
    'SortOrder': '5',
    'Value': [
    ],
    'controlType': 'numeric',
    'label': 'numeric',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'recoveryAmount',
    'required': 'true',
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': '',
    'optionsKeyLabel': '',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': '',
    'maxDate': '',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': '',
    'fullWidth': 'false'
  }, 
  {
    'IsActive': true,
    'ItemKey': 'status',
    'ItemValue': 'SELECT STATUS',
    'Category': null,
    'SortOrder': '6',
    'Value': [
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'status',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/RecoveryStatus',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': '',
    'maxDate': '',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33',
    'maxWidth': '33',
    'width': '33',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'IsActive': true,
    'ItemKey': 'Notes',
    'ItemValue': 'remarks',
    'Category': null,
    'SortOrder': '6',
    'Value': [
    ],
    'controlType': 'textarea',
    'label': '',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'remarks',
    'required': 'false',
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': '',
    'maxDate': '',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': '',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '100',
    'maxWidth': '100',
    'width': '100',
    'alignment': '',
    'fullWidth': 'true'
  }
]
<div class="customer">
  <div class="title">
    {{'customer_details' | translate }}
  </div>
    <dynamic-form [formControls]="controls" [form]="customerFormGroup"></dynamic-form>
    <!-- <form [formGroup]="customerFormGroup" style="display: flex; flex-flow: wrap; margin: 0 -5px;">  
      <ng-container *ngFor="let control of formControls">
        <div class="form-row" [ngStyle]="{
          'flex-basis': control?.layout?.width, 
          'min-width': control?.layout?.minWidth, 
          'max-width': control?.layout?.maxWidth, 
          'margin-left' : (control?.layout?.fullWidth && control?.layout?.alignment === 'right') ? 'calc(100% - '+control?.layout?.width+')' : null,
          'margin-right' : (control?.layout?.fullWidth && control?.layout?.alignment !== 'right') ? control?.layout?.width : null
        }">
          <dynamic-form [control]="control" [form]="customerFormGroup"></dynamic-form>
        </div>
      </ng-container>     
    </form> -->
</div>
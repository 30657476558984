/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { finalize, firstValueFrom, forkJoin, map, Observable, of, tap } from 'rxjs';

import { PartItem } from 'src/app/model/manual-estimate.model';

import { ManualEstimatePreviewComponent } from 'src/app/dialogs/manual-estimate-preview/manual-estimate-preview.component';
import { RepairEstimateOperationComponent } from 'src/app/dialogs/repair-estimate-operation/repair-estimate-operation.component';
import { AdditionalPartsDialogComponent } from 'src/app/modules/repair/add-customers/booking/additional-parts-dialog/additional-parts-dialog.component';

import { BookingService } from 'src/app/services/booking/booking.service';
import { CaseService } from 'src/app/services/case/case.service';
import {
  CommonService,
  Localization,
} from 'src/app/services/common/common.service';
import { EstimateService } from 'src/app/services/estimate/estimate.service';
import { EstimateSources } from 'src/app/config/constants/app.constants';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';


@Component({
  selector: 'manual-estimate',
  templateUrl: './manual-estimate.component.html',
  styleUrls: [ './manual-estimate.component.scss' ],
})
export class ManualEstimateComponent implements OnInit {

  @Input() domainId;
  @Output() showCalculation: EventEmitter<any> = new EventEmitter();

  /**
   * Description placeholder
   *
   * @param {Event} event
   */
  @HostListener('window:resize', [ '$event' ])
  onResize(): void {
    // Update the innerWidth with the new window width
    this.innerWidth = window.innerWidth;
  }

  innerWidth: number;
  carView: 'inner' | 'outer' = 'outer';
  i18n: Localization;
  isExpanded = false;
  estimationMode: 1 | 2;
  activePart = '';
  outerSelection = '';
  innerSelection = {
    'Front': false,
    'Right': false,
    'Left': false,
    'Rear': false,
  };

  operationNameMap = {}
  partsList = [];

  vehicleSectionDockIconMap = {
    'Air Conditioning': 'ac',
    'My Operations': 'engine',
    'Air Bags': 'ac',
    'Steering': 'steering',
    'Wheels': 'wheels',
    'Gear box': 'gear-box',
    'Engine': 'engine',

  }

  BodyTypeId = 2;
  partItems: PartItem[] = [];
  originalPartItems: any[] = [];
  partsCount = 0;
  operationCount = 0;
  operationData: any;
  objectGuid = '';
  repairData: any;
  savedAddedParts = [];
  vehicleSection: any;
  vehicleSectionId = 0;

  // Cost per panel
  totalCostPerPanel = 0;
  costPerPanel = 0;
  // LabourRates
  labourRates = [];
  tariffDiscounts = {
    parts: 0,
    labour: 0,
    paintLabour: 0,
    paintMaterial: 0
  }
  paintMaterialTotal = 0;

  // Estimated hours
  costByHour = {
    totalLabour: { hours: 0, cost: 0 },
    totalParts: 0,
    totalPaint: { hours: 0, cost: 0 },
    totalPartsEstimated: 0,
  }
  readonly defaultVehicleSection = 'Front Outer';
  partSearchText = '';
  isSearchOpen = false;

  public showCloseButton: boolean = false;
  inspectionId: string = '';
  vehicleId: string = '';

  /**
   * Creates an instance of ManualEstimateComponent.
   *
   * @constructor
   * @param {CommonService} commonService
   */
  constructor(
    private readonly commonService: CommonService,
    private readonly dialog: MatDialog,
    private readonly estimateService: EstimateService,
    private readonly bookingService: BookingService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly caseService: CaseService,
    private readonly repairEstimateService: RepairEstimateService
  ) {
    // Initialize the width with the current window width
    this.innerWidth = window.innerWidth;
    this.i18n = this.commonService.geti18nInfo();
    this.objectGuid = this.route.parent?.snapshot.paramMap.get('id');

    this.route.queryParams.subscribe((params) => {
      this.inspectionId = params['inspectionId'] ?? '';
      this.vehicleId = params['vehicleId'] ?? '';
    });
  }
  /**
   * toggleExpand
   *
   * @param {any} item
   */
  toggleExpand(item: any): void {
    // Toggle the isExpanded property
    item.isExpanded = !item.isExpanded;
    this.calculateParticulars();
  }

  /** Description placeholder */
  expandScreen(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Description placeholder
   *
   * @param {*} item
   * @returns {boolean}
   */
  matchesSearch(item: any): boolean {
    const searchText = this.partSearchText.toLowerCase().trim();
    if (!searchText) {
      return true; // If no search text, show all items
    }
    return item.name.toLowerCase().includes(searchText) ||
      item.partDetails.some(detail =>
        detail.name.toLowerCase().includes(searchText) ||
        detail.description?.toLowerCase().includes(searchText)
      );
  }

  /** Description placeholder */
  updateCount(): void {
    const addedParts = this.getSelectedParts();
    const savedParts = this.savedAddedParts.filter(part => part.vehicleSectionId !== this.vehicleSectionId);
    // Filter and count parts in addedParts and savedParts with 'Replace' selected
    const replaceAddedPartsCount = addedParts.filter(part => part?.operationTypes?.some(
      operation => operation.repairOperationType === 'Replace' && operation.checked
    )).length;
    const replaceSavedPartsCount = savedParts.filter(part => part?.operationTypes?.some(
      operation => operation.repairOperationType === 'Replace'
    )).length;

    this.partsCount = replaceAddedPartsCount + replaceSavedPartsCount;
    this.operationCount = addedParts.reduce((sum, obj) => {
      return sum + (obj.operationTypes?.length || 0);
    }, 0) + savedParts.reduce((sum, obj) => {
      return sum + (obj.operationTypes?.length || 0);
    }, 0)

    // Loop through innerSelection keys
    for (const key in this.innerSelection) {
      // Check if any vehicleSection in the parts array starts with the key
      this.innerSelection[ key ] = addedParts.some(part => part.vehicleSection?.startsWith(key) && part.vehicleSection?.toLowerCase().includes(this.carView)) ||
        savedParts.some(part => part.vehicleSection?.startsWith(key) && part.vehicleSection?.toLowerCase().includes(this.carView))
    }
    this.partsList.forEach((el) => {
      const hasDockParts = savedParts.find(part => part.vehicleSection === el.name) || addedParts.find(part => part.vehicleSection === el.name)
      if (hasDockParts) el.hasSavedParts = true
      else el.hasSavedParts = false
    })
  }

  /** calculateCostPerPanel */
  calculateParticulars(): void {
    const addedParts = this.getSelectedParts();
    const savedParts = this.savedAddedParts.filter(part => part.vehicleSectionId !== this.vehicleSectionId)

    const paintRate = this.labourRates.find(rate => rate.tariffTypeName === 'Paint');
    const paintAmount = paintRate ? parseFloat(paintRate.amount) : 0;

    // Calculate Total for SavedParts
    savedParts.forEach((partDetail) => {
      let totalPriceWithoutPaint = 0;
      let totalPaintPrice = 0;

      // additionalParts cost
      let totalAdditionalPartCost = 0;

      // Total labour cost with only paint
      let totalAdditionalPaintCost = 0;
      let totalAdditionalPaintHours = 0;

      // Total labour cost without paint
      let totalAdditionalLabourCost = 0;
      let totalAdditionalLabourHours = 0;

      const rate = this.getLabourRate(partDetail);
      const amount = rate ? parseFloat(rate.amount) : 0;

      partDetail?.operationTypes?.forEach((op) => {
        if (op.repairOperationType !== 'Painting') {
          totalPriceWithoutPaint += this.estimationMode === 1 ? (op.labourTime * amount) : +op.labourAmount;
        } else {
          totalPaintPrice += this.estimationMode === 1 ? (op.labourTime * paintAmount) : +op.labourAmount;
        }
      });


      // Calculate total additionalParts labourCost
      if (partDetail?.additionalParts?.length > 0) {
        partDetail.additionalParts.forEach((adPart) => {
          const addRate = this.getLabourRate(partDetail);
          const addAmount = addRate ? parseFloat(addRate.amount) : 0;
          totalAdditionalPartCost += (+adPart?.partCost || 0);
          // Check operationTypes and sum the labourAmount
          if (adPart?.operationTypes?.length > 0) {
            adPart.operationTypes.forEach((op) => {
              if (!op.repairOperationType.includes('Paint')) {
                totalAdditionalLabourCost += this.estimationMode === 1 ? (op.labourTime * addAmount) : +op.labourAmount;
                totalAdditionalLabourHours += +op.labourTime;
              } else {
                totalAdditionalPaintCost += this.estimationMode === 1 ? (op.labourTime * paintAmount) : +op.labourAmount;
                totalAdditionalPaintHours += +op.labourTime;
              }
            });
          }
        });
      }

      partDetail.totalPriceWithoutPaint = totalPriceWithoutPaint;
      partDetail.totalPaintPrice = totalPaintPrice;

      // This is for Estimated hours, total of paint is separated and other labour separated & part cost
      partDetail.totalAdditionalLabourCost = totalAdditionalLabourCost;
      partDetail.totalAdditionalLabourHours = totalAdditionalLabourHours;

      partDetail.totalAdditionalPaintCost = totalAdditionalPaintCost;
      partDetail.totalAdditionalPaintHours = totalAdditionalPaintHours;

      partDetail.totalAdditionalPartCost = totalAdditionalPartCost;

    });


    addedParts.forEach((partDetail) => {
      const rate = this.getLabourRate(partDetail);
      const amount = rate ? parseFloat(rate.amount) : 0;
      let totalPriceWithoutPaint = 0;
      let totalPaintPrice = 0;

      // additionalParts cost
      let totalAdditionalPartCost = 0;

      // Total labour cost with only paint
      let totalAdditionalPaintCost = 0;
      let totalAdditionalPaintHours = 0;

      // Total labour cost without paint
      let totalAdditionalLabourCost = 0;
      let totalAdditionalLabourHours = 0;


      partDetail.operationTypes?.forEach((op) => {
        if (op.repairOperationType !== 'Painting') {
          if (op.checked) totalPriceWithoutPaint += this.estimationMode === 1 ? (op.labourTime * amount) : +op.labourAmount;
        } else {
          if (op.checked) totalPaintPrice += this.estimationMode === 1 ? (op.labourTime * paintAmount) : +op.labourAmount;
        }

      });

      // Calculate total additionalParts labourCost
      if (partDetail?.additionalParts?.length > 0) {
        partDetail.additionalParts.forEach((adPart) => {
          const addRate = this.getLabourRate(partDetail);
          const addAmount = addRate ? parseFloat(addRate.amount) : 0;
          totalAdditionalPartCost += (+adPart?.partCost || 0);
          // Check operationTypes and sum the labourAmount
          if (adPart?.operationTypes?.length > 0) {
            adPart.operationTypes.forEach((op) => {
              if (!op.repairOperationType.includes('Paint')) {
                totalAdditionalLabourCost += this.estimationMode === 1 ? (op.labourTime * addAmount) : +op.labourAmount;
                totalAdditionalLabourHours += +op.labourTime;
              } else {
                totalAdditionalPaintCost += this.estimationMode === 1 ? (op.labourTime * paintAmount) : +op.labourAmount;
                totalAdditionalPaintHours += +op.labourTime
              }
            });
          }
        });
      }


      partDetail.totalPriceWithoutPaint = totalPriceWithoutPaint;
      partDetail.totalPaintPrice = totalPaintPrice;

      // This is for Estimated hours, total of paint is separated and other labour separated & part cost
      partDetail.totalAdditionalLabourCost = totalAdditionalLabourCost;
      partDetail.totalAdditionalLabourHours = totalAdditionalLabourHours;

      partDetail.totalAdditionalPaintCost = totalAdditionalPaintCost;
      partDetail.totalAdditionalPaintHours = totalAdditionalPaintHours;

      partDetail.totalAdditionalPartCost = totalAdditionalPartCost;
    })

    const hoursFromAddedParts = addedParts.reduce(
      (total, item) =>
        total + (item?.operationTypes?.filter(operation => (operation.checked && operation.repairOperationType !== 'Painting'))
          .reduce((sum, operation) => sum + +operation.labourTime, 0) || 0),
      0
    );

    const hoursFromSavedParts = savedParts.reduce(
      (total, item) => {
        const operationTypes = item?.operationTypes?.filter(op => op.repairOperationType !== 'Painting') || [];
        const itemLabourTime = operationTypes.reduce((sum, operation) => sum + (+operation.labourTime || 0), 0);
        return total + itemLabourTime;
      }, 0);

    // total Additional PartCost
    const totalAdditionalPartCostFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPartCost, 0);
    const totalAdditionalPartCostFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPartCost, 0);
    let totalAdditionalPartCost = totalAdditionalPartCostFromAddedParts + totalAdditionalPartCostFromSavedParts;

    // Apply Discount
    totalAdditionalPartCost = totalAdditionalPartCost - (totalAdditionalPartCost * this.tariffDiscounts.parts / 100);

    const additionalHoursFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalLabourHours, 0);
    const additionalHoursFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalLabourHours, 0);
    const totalAddHours = additionalHoursFromAddedParts + additionalHoursFromSavedParts;

    const totalAdditionalCostFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalLabourCost, 0);
    const totalAdditionalCostFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalLabourCost, 0);
    let totalAdditionalLabourCost = totalAdditionalCostFromAddedParts + totalAdditionalCostFromSavedParts;

    // Apply Discount
    totalAdditionalLabourCost = totalAdditionalLabourCost - (totalAdditionalLabourCost * this.tariffDiscounts.labour / 100);

    const additionalPaintHoursFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPaintHours, 0);
    const additionalPaintHoursFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPaintHours, 0);
    const totalAddPaintHours = additionalPaintHoursFromAddedParts + additionalPaintHoursFromSavedParts

    const additionalPaintCostFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPaintCost, 0);
    const additionalPaintCostFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalAdditionalPaintCost, 0);
    let totalAddPaintCost = additionalPaintCostFromAddedParts + additionalPaintCostFromSavedParts;

    // Apply Discount
    totalAddPaintCost = totalAddPaintCost - (totalAddPaintCost * this.tariffDiscounts.paintLabour / 100);


    // cost need to be added from API
    const costFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalPriceWithoutPaint, 0);
    const costFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalPriceWithoutPaint, 0);
    let totalCostWithoutPaint = costFromAddedParts + costFromSavedParts;

    // Apply Discount
    totalCostWithoutPaint = totalCostWithoutPaint - (totalCostWithoutPaint * this.tariffDiscounts.labour / 100);

    this.costByHour.totalLabour.hours = parseFloat((+hoursFromSavedParts + +hoursFromAddedParts + +totalAddHours).toFixed(2));
    this.costByHour.totalLabour.cost = +(totalCostWithoutPaint + totalAdditionalLabourCost).toFixed(2);

    const paintHoursFromAddedParts = addedParts.reduce(
      (total, item) =>
        total + (item?.operationTypes?.filter(operation => (operation.checked && operation.repairOperationType === 'Painting'))
          .reduce((sum, operation) => sum + +operation.labourTime, 0) || 0),
      0
    );
    const paintHoursFromSavedParts = savedParts.reduce(
      (total, item) => {
        const operationTypes = item?.operationTypes?.filter(op => op.repairOperationType === 'Painting') || [];
        const itemLabourTime = operationTypes.reduce((sum, operation) => sum + (+operation.labourTime || 0), 0);
        return total + itemLabourTime;
      }, 0);

    // PAINT cost
    const paintCostFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalPaintPrice, 0);
    const paintCostFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + currentItem.totalPaintPrice, 0);
    let totalCostWithOnlyPaint = paintCostFromAddedParts + paintCostFromSavedParts;

    // Apply Discount
    totalCostWithOnlyPaint = totalCostWithOnlyPaint - (totalCostWithOnlyPaint * this.tariffDiscounts.paintLabour / 100);

    this.costByHour.totalPaint.hours = parseFloat((+paintHoursFromAddedParts + +paintHoursFromSavedParts + +totalAddPaintHours).toFixed(2));
    this.costByHour.totalPaint.cost = parseFloat((totalCostWithOnlyPaint + (+this.paintMaterialTotal || 0) + +totalAddPaintCost).toFixed(2));


    const totalPartCostFromAddedParts = addedParts.reduce((accumulator, currentItem) => accumulator + ((currentItem?.partCost * currentItem?.quantity) || 0), 0);
    const totalPartCostFromSavedParts = savedParts.reduce((accumulator, currentItem) => accumulator + ((currentItem?.partCost * currentItem?.partQuantity) || 0), 0);
    this.costByHour.totalParts = totalPartCostFromAddedParts + totalPartCostFromSavedParts + totalAdditionalPartCost;

    // Apply Discount
    this.costByHour.totalParts = this.costByHour.totalParts - (this.costByHour.totalParts * this.tariffDiscounts.parts / 100);

    this.costByHour.totalPartsEstimated = parseFloat((+this.costByHour.totalLabour.cost + +this.costByHour.totalParts + +this.costByHour.totalPaint.cost).toFixed(2))

    // Cost PerPanel
    this.costPerPanel = parseFloat((totalCostWithoutPaint + totalCostWithOnlyPaint + totalAddPaintCost + totalAdditionalLabourCost + (this.paintMaterialTotal || 0)).toFixed(2));
    this.totalCostPerPanel = +(this.costPerPanel + this.costByHour.totalParts).toFixed(2);

    this.updateCount();

  }

  /** ngOnInit */
  ngOnInit(): void {
    const api1 = this.estimateService.getDamageSeverities();
    const api2 = this.domainId === 3100 ? this.bookingService.getRepairDetailByGuid(this.objectGuid) : this.caseService.getCaseDetail(this.objectGuid);
    const api3 = this.estimateService.getVehicleSection();
    this.commonService.showLoading();
    forkJoin([ api1, api2, api3 ]).subscribe({
      next: ([ data1, repairData, vehicleSection ]) => {
        this.operationData = data1;
        this.repairData = repairData?.data;
        if(this.repairData?.estimateSource === EstimateSources.Audatex) {
          this.router.navigate([ `/repair/booking/${this.objectGuid}/estimate` ]);
        }
        this.vehicleSection = vehicleSection;
        this.BodyTypeId = repairData?.data?.vehicle?.vehicleTypeId || 2;
        this.commonService.repairDetails.next(repairData?.data);
        this.commonService.bookingId.next(repairData?.data.repairNumber);

        this.partsList = vehicleSection?.filter(el => el.position === 'Bottom').map((el) => {
          return { ...el, hasSavedParts: false }
        })
        this.partsList.push({
          'name': 'My Operations',
          'description': 'My Operations',
          'position': 'Bottom',
          'order': null,
          'hasSavedParts': false
        })

        this.estimationMode = this.repairData?.calculationTypeId || 1;

        this.operationNameMap = this.operationData.reduce((acc, item) => {
          acc[ item.name ] = item.description;
          acc[ item.description ] = item.description;
          return acc;
        }, {});

        // Get labour Rates
        const otherService = [ 'Others', 'Diagnosis', 'Tyres', 'Maintenance' ]
        this.labourRates = this.repairData.tariffs[ 0 ].tariffLabourRates;
        const mechanicsRate = this.labourRates.find(rate => rate.tariffTypeName === 'Mechanics');
        otherService.forEach((el) => {
          const obj = { amount: mechanicsRate.amount, tariffTypeName: el }
          this.labourRates.push(obj)
        })

        this.repairData?.tariffs[ 0 ]?.tariffDiscountRates?.forEach((tariff) => {
          switch (tariff.tariffTypeName.toLowerCase()) {
          case 'parts':
            this.tariffDiscounts.parts = tariff.discount;
            break;
          case 'labour':
            this.tariffDiscounts.labour = tariff.discount;
            break;
          case 'paint labour':
            this.tariffDiscounts.paintLabour = tariff.discount;
            break;
          case 'paint material':
            this.tariffDiscounts.paintMaterial = tariff.discount;
            break;
          }
        });

        this.getAddendaEstimateByObjectGuid();
      },
      complete: () => {
        // this.commonService.hideLoading();
      },
    });
  }

  /**
   * Description placeholder
   *
   * @param {*} item
   */
  openAdditionalParts(item: any): void {
    const dialogRef = this.dialog.open(AdditionalPartsDialogComponent, {
      width: '420px',
      panelClass: 'additional-parts-dialog',
      data: { ...item, estimationMode: this.estimationMode, operationNameMap: this.operationNameMap },
      disableClose: true,
      direction: this.commonService.direction
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;
      // Need to add parts blend parts in item
      const additionalParts = []
      res?.forEach((el) => {
        const operationTypes = (el?.operationTypes?.length > 0)
          ? el.operationTypes.map(operationType => ({
            'estimateChargeId': operationType?.estimateChargeId || null,
            'operationId': operationType?.operationId || null,
            'repairOperationType': this.operationNameMap[ operationType?.repairOperationType ] || null,
            'labourTime': operationType?.labourTime || null,
            'labourAmount': operationType?.labourAmount || null,
            'materialCharge': operationType?.materialCharge || null
          }))
          : [
            {
              'estimateChargeId': null,
              'operationId': null,
              'repairOperationType': this.operationNameMap[ el?.repairOperationType ],
              'labourTime': el?.labourTime,
              'labourAmount': el?.labourAmount
            }
          ];

        // Check if the partName already exists in additionalParts
        const existingPart = additionalParts.find(part => part.name === el?.partName);

        if (existingPart) {
          // Check for duplicates based on repairOperationType
          operationTypes.forEach((newOperation) => {
            const exists = existingPart.operationTypes.some(existingOperation =>
              existingOperation.repairOperationType === newOperation.repairOperationType
            );

            // Push only if it doesn't already exist
            if (!exists) {
              existingPart.operationTypes.push(newOperation);
            }
          });
        } else {
          // If it doesn't exist, create a new entry
          const addPart = {
            'estimateChargeId': el?.estimateChargeId || null,
            'operationId': el?.operationId || null,
            'vehicleSectionId': item.vehicleSectionId,
            'vehicleSection': item.vehicleSection,
            'code': el?.code,
            'description': el?.partName,
            'name': el?.partName,
            'information': '',
            // NEED TO CHANGE THIS WHEN IT"S ADDED IN API -> el?.repairServiceType//
            'repairServiceType': item?.repairServiceType,
            'quantity': 0,
            'cost': 0,
            'addendaPartMasterId': el?.id,
            'partPosition': '',
            'operationTypes': operationTypes
          };
          additionalParts.push(addPart);
        }
      })
      item.additionalParts = additionalParts;
      if (item.additionalParts?.length > 0) item.hasBlendParts = true;
      else item.hasBlendParts = false;

      this.calculatePrice();
      this.calculateParticulars();
    })
  }


  /**
   * Description placeholder
   *
   * @param {*} item
   * @param {string} operationName
   * @param {*} value
   */
  onValueChange(item: any, operationName: string, value: any): void {
    this.calculatePrice();
    this.calculateParticulars();

    // Mark as isEdited if item is in saved list
    if (this.savedAddedParts.some(el => el.addendaPartMasterId === item.id)) {
      item.isEdited = true
    }
  }

  /** Description placeholder */
  calculatePrice(): void {
    const paintRate = this.labourRates.find(rate => rate.tariffTypeName === 'Paint');
    const paintAmount = paintRate ? parseFloat(paintRate.amount) : 0;
    this.partItems.forEach((partItem) => {
      partItem.partDetails?.forEach((partDetail) => {
        let totalPrice = 0;

        if (this.estimationMode === 1) {
          const rate = this.getLabourRate(partDetail);
          const amount = rate ? parseFloat(rate.amount) : 0;
          partDetail.operationTypes?.forEach((op) => {
            if (op.checked) {
              if (!op.repairOperationType?.includes('Paint')) {
                totalPrice += (op.labourTime * amount);
              } else {
                totalPrice += (op.labourTime * paintAmount);
              }
            }
          });
        } else {
          partDetail.operationTypes.forEach((op) => {
            if (op.checked) totalPrice += +op.labourAmount;
          });
        }

        partDetail.price = Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2);

        partDetail.isSelected = partDetail.quantity && partDetail.operationTypes.some(el => el.checked)
      });
    });
  }

  /** get Parts By Section */
  getPartsBySection(getSavedParts = true): void {
    this.commonService.showLoading();
    const capitalizedCarView = this.carView.charAt(0).toUpperCase() + this.carView.slice(1);
    const vehicleSectionName = this.formatString(this.outerSelection).replace(/(Outer|Inner)/, capitalizedCarView);
    this.vehicleSectionId = this.vehicleSection.find(el => el.name === vehicleSectionName)?.id;

    const query = '?$count=true&$expand=PartDetails($expand=OperationTypes,BlendPartOperations)';
    this.partItems = [];
    this.estimateService.getStandardParts(query, this.BodyTypeId, this.vehicleSectionId).pipe(map(data => this.keysToCamelCase(data))).subscribe({
      next: (res) => {
        const modifiedData = res?.value?.map((item) => {
          return { ...item, isExpanded: false };
        }).filter(el => el.partDetails.length > 0)
        this.partItems = [ ...modifiedData ];

        // Create a map for faster lookups (repairOperationType => order)
        const operationDataMap = new Map<string, number>();
        this.operationData?.forEach((data) => {
          operationDataMap.set(data.name, data.order);
        });
        // Iterate through partItems and partDetails and assign order
        this.partItems?.forEach((el) => {
          el.partDetails?.forEach((part) => {
            part.additionalParts = part?.additionalParts || [];
            part.operationTypes?.forEach((op) => {
              // Set the order using the map (default to 0 if not found)
              op.order = operationDataMap.get(op.repairOperationType) || 0;
            });

            // Sort operationTypes by the order property
            part.operationTypes?.sort((a, b) => a.order - b.order);
          });
        });
        this.originalPartItems = cloneDeep(this.partItems); // Deep copy
        if (this.partItems?.length > 0 && getSavedParts) this.getAddendaEstimateByObjectGuid();
        else {
          this.commonService.hideLoading();
        }
      },
    })
  }

  /** Description placeholder */
  getAddendaEstimateByObjectGuid(getPartsBySection = true): void {
    this.commonService.showLoading();
    // Get saved parts and show in UI
    this.estimateService.getAddendaEstimateByObjectGuidAndMode(this.objectGuid, this.estimationMode, this.domainId).subscribe({
      next: (estimateDetails) => {
        this.showCloseButton = !!estimateDetails;
        this.savedAddedParts = estimateDetails?.estimatePartCharges || [];
        this.paintMaterialTotal = +estimateDetails?.paintMaterialTotal || 0;
        this.calculatePrice();
        this.calculateParticulars();
        this.savedAddedParts = this.savedAddedParts.map((el) => {
          return {
            ...el,
            hasBlendParts: el?.additionalParts?.length > 0,
            vehicleSection: el?.addendaPartMasterId ? el?.vehicleSection : 'My Operations',
            vehicleSectionId: el?.addendaPartMasterId ? el?.vehicleSectionId : 99,
          }
        })
        // Set Selected vehicle section
        if (this.savedAddedParts?.length > 0 && this.partItems.length === 0) {
          const vehicleSectionName = this.outerSelection || this.savedAddedParts.find(el => el.vehicleSection)?.vehicleSection || this.defaultVehicleSection
          if (vehicleSectionName?.toLowerCase()?.includes('inner')) {
            this.carView = 'inner'
          }
          this.outerSelection = (vehicleSectionName.includes('Inner') || vehicleSectionName.includes('Outer'))
            ? this.formatStringKebabCase(vehicleSectionName.replace('Inner', 'Outer'))
            : vehicleSectionName;

          if (this.outerSelection === 'My Operations') {
            this.setMyOperationsData();
            return;
          }
          if (getPartsBySection) this.getPartsBySection();
        }

        if (this.outerSelection === 'My Operations') {
          this.setMyOperationsData();
          return;
        }
        if (this.partItems.length === 0) {
          this.commonService.hideLoading();
          return;
        }

        if (this.savedAddedParts.length > 0) {
          this.savedAddedParts.forEach((apiItem) => {
            if (apiItem.vehicleSectionId === this.vehicleSectionId) {
              // Set Data in Part list
              const partId = apiItem.addendaPartMasterId;

              // Find the corresponding partItem in partItems
              const partItem = this.partItems.find(item =>
                item.partDetails.some(detail => detail.id === partId)
              );

              if (partItem) {
                // Find the corresponding partDetail in partItem
                const partDetails = partItem.partDetails.find(detail => detail.id === partId);

                if (partDetails) {
                  partItem.isExpanded = true;

                  // Use spread operator to merge the values from API into partDetails
                  Object.assign(partDetails, {
                    ...partDetails,
                    ...{
                      name: apiItem.name || partDetails.name,
                      description: apiItem.description || partDetails.description,
                      vehicleSectionId: apiItem.vehicleSectionId || partDetails.vehicleSectionId,
                      vehicleSection: apiItem.vehicleSection || partDetails.vehicleSection,
                      repairServiceTypeId: apiItem.repairServiceTypeId || partDetails.repairServiceId,
                      repairServiceType: partDetails?.repairServiceType || apiItem?.repairServiceType,
                      quantity: apiItem.partQuantity || partDetails.quantity,
                      price: apiItem.price || partDetails.price,
                      operationId: apiItem.operationId || partDetails.operationId,
                      estimateChargeId: apiItem.estimateChargeId || partDetails.estimateChargeId,
                      code: apiItem.code || partDetails.code,
                      partCost: apiItem?.partCost || 0,
                      additionalParts: apiItem?.additionalParts || [],
                      hasBlendParts: apiItem?.additionalParts?.length > 0,
                      inspectionItemId: apiItem?.inspectionItemId || null,
                      isEdited: false
                    }
                  });

                  // Update operationTypes
                  apiItem?.operationTypes?.forEach((op) => {
                    partDetails.operationTypes.forEach((po) => {
                      if (op.repairOperationType === po.repairOperationType || op.repairOperationType === this.operationNameMap[ po?.repairOperationType ]) {
                        Object.assign(po, {
                          ...op, checked: true,
                          repairOperationType: po.repairOperationType,
                          labourAmount: this.estimationMode === 1 ? 0 : (op?.labourAmount || 0),
                          labourTime: this.estimationMode === 2 ? 0 : (op?.labourTime || 0),

                        })
                        this.onCheckboxChange(partDetails, po.repairOperationType, true)
                      }
                    })
                  })

                }
              }
            }
          })
          this.calculatePrice();
          this.calculateParticulars();
        }
      },
      complete: () => {
        if (this.partItems.length !== 0) this.commonService.hideLoading();

      }
    })
  }

  /** open Review Estimation */
  openReviewEstimation(): void {
    this.calculateEstimate(true, true).subscribe((res) => {
      if (!res?.data) {
        this.updateCount();
        return;
      }
      if (res?.data?.estimateCharges?.length === 0) {
        this.commonService.showInfoToast(3000, 'select_part_operation_to_proceed');
        this.updateCount();
        return;
      }
      const dialogRef = this.dialog.open(ManualEstimatePreviewComponent, {
        data: {
          estimateResponse: { ...res.data },
          objectGuid: this.objectGuid,
          domainId: this.domainId
        },
        width: '75vw',
        height: '90vh',
        panelClass: 'estimate-preview-panel',
        autoFocus: false,
        direction: this.commonService.direction
      });

      dialogRef.afterClosed().subscribe({
        next: (response: any) => {
          if (response) {
            if (response?.eventType === 'save') {
              this.getAddendaEstimateByObjectGuid();
            } else if (response?.eventType === 'calculateEstimate') {
              this.redirectToEstimate();
            }
          }
        }
      });
    })

  }

  /** Description placeholder */
  openCreateOperation(): void {

    const dialogRef = this.dialog.open(RepairEstimateOperationComponent, {
      data: {
        objectGuid: this.objectGuid,
        estimationMode: this.estimationMode,
        addedParts: this.savedAddedParts,
        domainId: this.domainId
      },
      width: '85vw',
      height: '90vh',
      panelClass: 'operation-panel',
      autoFocus: false,
      direction: this.commonService.direction
    });

    dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          console.log('response:', response)
          if (response) this.getAddendaEstimateByObjectGuid();
        }
      }
    });
  }

  /** toggleView */
  toggleView(): void {
    this.carView === 'inner'
      ? (this.carView = 'outer')
      : (this.carView = 'inner');


    this.calculateEstimate(false, false, false).subscribe(() => {
      if (this.outerSelection && this.outerSelection !== 'My Operations') this.getPartsBySection(true);
    })

  }

  /** Description placeholder */
  setMyOperationsData(): void {
    this.vehicleSectionId = 99;
    const operationTypes = this.operationData.filter(el => el.name !== 'Clean').map((el) => {
      return {
        'estimateChargeId': null,
        'operationId': null,
        'repairOperationTypeId': el.id,
        'repairOperationType': el.name,
        'labourTime': 0,
        'labourAmount': 0,
        'order': el.order,
        'checked': false,
      }
    }).sort((a, b) => a.order - b.order);
    operationTypes.push({
      repairOperationType: 'Polish',
      repairOperationTypeId: 7,
      checked: false,
    })
    operationTypes.push({
      repairOperationType: 'Check',
      repairOperationTypeId: 8,
      checked: false,
    })
    operationTypes.push({
      repairOperationType: 'Perform',
      repairOperationTypeId: 9,
      checked: false,
    })
    this.partItems = this.savedAddedParts
      .filter(el => !el.addendaPartMasterId)
      .map((el) => {
        // For each part, map through the full operationTypes array and merge with the part's operation types if found
        const mergedOperationTypes = operationTypes.map((op) => {
          // Check if the part has a matching operation type
          const matchingPartOp = el.operationTypes?.find(
            partOp =>
              (partOp.repairOperationType === this.operationNameMap[ op.repairOperationType ] ||
              partOp.repairOperationType === op.repairOperationType)
          );

          // Merge the matching part operation type with the original operation type
          return {
            ...op,
            ...(matchingPartOp ? { ...matchingPartOp, checked: true } : {})
          };
        });

        return {
          name: el.name,
          code: '',
          Description: '',
          partDetails: [
            {
              ...el,
              quantity: el.partQuantity,
              operationTypes: mergedOperationTypes, // Use the merged operation types
              vehicleSection: 'My Operations',
              vehicleSectionId: 99,
              paint: el.operationTypes.some(op => op.repairOperationType.includes('Paint')),
            },
          ],
          isExpanded: true
        };
      });

    this.partItems.forEach((part) => {
      part.partDetails.forEach((partDetails) => {
        partDetails.operationTypes.forEach((po) => {
          this.onCheckboxChange(partDetails, po.repairOperationType, po?.checked)
        })
      })
    })
    this.calculatePrice()
    this.calculateParticulars();
    this.commonService.hideLoading();
  }

  /**
   * set Active Part
   *
   * @param {*} obj
   */
  setActivePart(obj: any): void {
    this.outerSelection = obj.name;
    if (this.outerSelection === 'My Operations') {
      this.calculateEstimate(true, false, false).subscribe(() => {
        this.setMyOperationsData();
      })
      return;
    }
    const vehicleSectionId = this.vehicleSection.find(el => el.name === this.outerSelection)?.id
    this.calculateEstimate(true, false, false).subscribe(() => {
      if (this.outerSelection && vehicleSectionId) {
        this.getPartsBySection();
      } else {
        this.partItems = [];
      }
    })
  }

  /**
   * set Active Part
   *
   * @param {*} obj
   */
  async wheelClicked(event: any): Promise<any> {
    const selectionId = (event.target.id && event.target.id.indexOf('-text') === -1 ? event.target.id : event.target.parentElement.id).replace('-text', '');
    if (selectionId && !selectionId.includes('inner')) {

      // Only call calculateEstimate if some section was select
      if (this.outerSelection)
        await firstValueFrom(this.calculateEstimate(false, false, false))

      const path = document.getElementById(selectionId);
      // Get all outer paths
      const outerPaths = document.querySelectorAll('path[id$="-outer"]');

      // Reset all outer paths to white
      outerPaths.forEach((outerPath) => {
        if (outerPath !== path) {
          outerPath.setAttribute('fill-opacity', '0');
        }
      });
      const existingColor = path.getAttribute('fill-opacity');
      this.outerSelection = existingColor === '0' ? selectionId : '';

      path.setAttribute('fill-opacity', existingColor === '0' ? '1' : '0');
      if (this.outerSelection) this.getPartsBySection();
      else this.partItems = [];
    }
  }

  /**
   * Description placeholder
   *
   * @returns {any[]}
   */
  getSelectedParts(): any[] {
    let selectedParts = []
    selectedParts = this.partItems.map((part) => {
      // Check if the part has valid partDetails
      const partDetails = part.partDetails || [];

      // Filter partDetails based on the conditions
      return partDetails.filter((el) => {
        return el.operationTypes.some(op => op.checked) && el.quantity && part.isExpanded;
      }).map(el => ({
        ...el,
        operationTypes: el.operationTypes.filter(op => op.checked).map((o) => {
          return {
            'checked': o?.checked,
            'estimateChargeId': o?.estimateChargeId || null,
            'operationId': o?.operationId || null,
            'repairOperationType': this.operationNameMap[ o?.repairOperationType ] || o?.repairOperationType,
            'labourTime': this.estimationMode === 2 ? 0 : o.labourTime,
            'labourAmount': o?.labourAmount,
            'materialCharge': o?.materialCharge || null
          }
        })
      }));
    }).flat()
    return selectedParts
  }


  /**
   * Description placeholder
   *
   * @async
   * @returns {*}
   */
  async onEstimateButtonClick(): Promise<any> {
    try {
      const result = await firstValueFrom(this.calculateEstimate(true, true));
      if (result) this.redirectToEstimate();
      else {
        this.commonService.showInfoToast(3000, 'select_part_operation_to_proceed');
      }
      // Additional actions if needed
    } catch (error) {
      console.error('Error saving estimate:', error);
    }
  }

  /**
   * Description placeholder
   *
   * @returns {Observable<any>}
   */
  calculateEstimate(getSaved = true, showMessage = false, showPartMsg = true, hideLoader = true): Observable<any> {
    let addedParts = this.getSelectedParts();
    this.savedAddedParts.forEach((el) => {
      if (el.vehicleSectionId !== this.vehicleSectionId) {
        addedParts.push(el);
      }
    });

    // Check for parts that belong to the current vehicleSectionId
    this.savedAddedParts.forEach((savedPart) => {
      if (savedPart.vehicleSectionId === this.vehicleSectionId) {
        // Check if the part already exists in addedParts
        const partExists = addedParts.some(part =>
          (part.id === savedPart.addendaPartMasterId ||
          (part.name === savedPart.name && part.vehicleSection === savedPart.vehicleSection && part.addendaPartGuid === savedPart.addendaPartGuid)));

        // If the part doesn't exist in addedParts, push it with isDeleted: true
        if (!partExists) {
          addedParts.push({ ...savedPart, isDeleted: true });
        }
      }
    });

    addedParts = addedParts.filter(el => el.operationTypes?.length > 0);
    // Add isDeleted to additionalParts if parent has it
    addedParts.forEach((el) => {
      el?.additionalParts?.forEach((ad) => {
        if (el?.isDeleted) {
          ad.isDeleted = true
        }
      })

      el?.operationTypes?.forEach((op) => {
        if (this.estimationMode === 1) {
          op.labourAmount = 0
        } else {
          op.labourTime = 0
        }
      })

    })
    const payload = addedParts.map(el => ({
      'estimateChargeId': el?.estimateChargeId || null,
      'operationId': el?.operationId || null,
      'vehicleSectionId': el?.vehicleSectionId,
      'vehicleSection': el?.vehicleSection,
      'code': el?.code,
      'description': el?.description,
      'partPosition': el?.position || '',
      'name': el?.name,
      'information': el?.information,
      'repairServiceType': el?.repairServiceType,
      'repairServiceTypeId': el?.repairServiceTypeId,
      'partQuantity': el?.quantity || el?.partQuantity || 1,
      'partCost': el?.partCost || 0,
      'addendaPartMasterId': el?.id || el?.addendaPartMasterId,
      'operationTypes': el?.operationTypes,
      'additionalParts': el?.hasBlendParts ? el?.additionalParts : [],
      'isDeleted': el?.isDeleted || false,
      'isEdited': el?.isEdited || false,
      'inspectionItemId': el?.inspectionItemId,
      'addendaPartGuid': el?.addendaPartGuid || null
    }));

    console.log('payload:', payload)
    if (payload.length === 0) {
      if (showPartMsg) this.commonService.showInfoToast(3000, 'select_part_operation_to_proceed');
      return of(null);
    }

    this.commonService.showLoading();
    return this.estimateService.saveAddendaEstimate(payload, this.objectGuid, this.estimationMode, this.domainId).pipe(
      tap((res) => {
        if (this.outerSelection !== 'My Operations') {
          this.partItems = cloneDeep(this.originalPartItems)
        }
        if (showMessage) this.commonService.showInfoToast(3000, res.message)

        if (getSaved) this.getAddendaEstimateByObjectGuid(false);

        // Re-calculate
        this.calculatePrice();
        this.calculateParticulars();
      }),
      finalize(() => {
        if (!getSaved && hideLoader) this.commonService.hideLoading();
      })
    );
  }

  /** Description placeholder */
  toggleEstimationMode(): void {
    this.calculateEstimate(false, false, false, false).subscribe(() => {
      this.estimationMode = this.estimationMode === 1 ? 2 : 1;
      this.getAddendaEstimateByObjectGuid(false)
    })

  }

  /**
   * formatString
   *
   * @param {string} str
   * @returns {string}
   */
  formatString(str: string): string {
    // Replace hyphens with spaces and capitalize each word
    return str
      .replace(/-/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  }
  /**
   * returns string with -
   *
   * @param {string} input
   * @returns {string}
   */
  formatStringKebabCase(input: string): string {
    return input.trim().toLowerCase().replace(/\s+/g, '-');
  }

  /**
   * Description placeholder
   *
   * @param {string} operation
   * @param {boolean} isChecked
   */
  onCheckboxChange(item: any, operationName: string, checked: boolean, openBlendOption = false): void {
    // Mark as isEdited if item is in saved list
    if (openBlendOption && this.savedAddedParts.some(el => el.addendaPartMasterId === item.id)) {
      item.isEdited = true
    }

    // First, handle the special case where Paint should always remain enabled
    item.operationTypes.forEach((op) => {
      if (op.repairOperationType === 'Paint') {
        op.disabled = false;
      }
    });

    // Apply logic based on which operation is selected
    if (operationName === 'Replace') {
      if (checked) {
        // If Replace is selected, disable Repair and R+R
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Repair' || op.repairOperationType === 'R+R') {
            op.disabled = true;
          } else if (op.repairOperationType === 'Paint') {
            op.disabled = false; // Paint can be combined with Replace
          }
        });
      } else {
        // If Replace is unselected, enable all except those that are not compatible
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Repair' || op.repairOperationType === 'R+R') {
            op.disabled = false; // Re-enable Repair and R+R if they are not checked
          }
        });
      }
    } else if (operationName === 'Repair') {
      if (checked) {
        // If Repair is selected, disable Replace
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Replace') {
            op.disabled = true;
          } else if (op.repairOperationType === 'R+R' || op.repairOperationType === 'Paint') {
            op.disabled = false; // Repair can be combined with Paint and R+R
          }
        });
      } else {
        // If Repair is unselected, enable all except those that are not compatible
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Replace' && !item.operationTypes.find(o => o.repairOperationType === 'R+R' && o.checked)) {
            op.disabled = false; // Re-enable Replace if it is not checked
          }
        });
      }
    } else if (operationName === 'R+R') {
      if (checked) {
        // If R+R is selected, disable Replace
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Replace') {
            op.disabled = true;
          } else if (op.repairOperationType === 'Repair' || op.repairOperationType === 'Paint') {
            op.disabled = false; // R+R can be combined with Repair and Paint
          }
        });
      } else {
        // If R+R is unselected, enable all except those that are not compatible
        item.operationTypes.forEach((op) => {
          if (op.repairOperationType === 'Replace' && !item.operationTypes.find(o => o.repairOperationType === 'Repair' && o.checked)) {
            op.disabled = false; // Re-enable Replace if it is not checked
          }
        });
      }
    }

    // Finally, ensure all selected checkboxes remain enabled
    item.operationTypes.forEach((op) => {
      if (op.checked) {
        op.disabled = false; // Ensure all checked boxes remain enabled
      }
    });

    //  If all checkboxes are unchecked, enable all of them
    const allUnchecked = item.operationTypes.every(op => !op.checked);
    if (allUnchecked) {
      item.operationTypes.forEach((op) => {
        op.disabled = false; // Enable all operation types
      });
    }

    if (openBlendOption) {
      const hasBlendParts = item?.blendPartOperations || [];
      if (hasBlendParts.length > 0 && checked) {
        const isReplaceChecked = item.operationTypes.some(op => op.repairOperationType === 'Replace' && op.checked);
        const isPaintChecked = item.operationTypes.some(op => op.repairOperationType === 'Paint' && op.checked);
        if (item.paint) {
          if (isReplaceChecked && isPaintChecked && (operationName === 'Paint' || operationName === 'Replace')) {
            this.openAdditionalParts(item)
          }
        } else if (isReplaceChecked && operationName === 'Replace') {
          this.openAdditionalParts(item)
        }

      } else if (hasBlendParts.length > 0 && !checked) {
        item.hasBlendParts = false;
      }
    }
    this.calculatePrice();
    this.calculateParticulars();
  }


  /**
 * Description placeholder
 *
 * @param {string} str
 * @returns {string}
 */
  toCamelCase(str: string): string {
    return str
      .replace(/_([a-z])/g, g => g[ 1 ].toUpperCase()) // Convert _ followed by a letter to uppercase
      .replace(/^[A-Z]/, g => g.toLowerCase()) // Convert the first letter to lowercase
      .replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters (if any)
  }

  /**
 * Description placeholder
 *
 * @param {*} obj
 * @returns {*}
 */
  keysToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(item => this.keysToCamelCase(item)); // Process each item in the array
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelKey = this.toCamelCase(key);
        acc[ camelKey ] = this.keysToCamelCase(obj[ key ]); // Recursively process nested objects
        return acc;
      }, {} as any);
    } else {
      return obj; // Return primitive values as is
    }

  }


  /**
   * get Labour Rate
   *
   * @param {*} partDetail
   * @returns {*}
   */
  getLabourRate(partDetail: any): any {
    return this.labourRates.find((rate) => {
      const tariffTypeNameFirst4Char = rate.tariffTypeName.split('').slice(0, 4).join('');
      const repairServiceTypeFirst4Char = partDetail.repairServiceType.split('').slice(0, 4).join('');
      return tariffTypeNameFirst4Char === repairServiceTypeFirst4Char;
    });
  }

  /**
   *
   */
  redirectToEstimate(): void {
    if (this.domainId === 3100) {
      this.router.navigate([ `repair/booking/${this.objectGuid}/estimate` ]);
    } else {
      this.showCalculation.emit(true);
    }
  }

  /**
   *
   */
  onCancelClick(): void {
    const dialogRef = this.commonService.openConfirmationOkDialog('confirm',
      'loose_any_unsaved_data_confirmation');

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.domainId === 3100) {
          this.router.navigate([ `repair/booking/${this.objectGuid}/estimate` ]);
        } else {
          this.showCalculation.emit(true);
        }
      }
    });
  }

  /**
     * upload xml file
     * @param {Event} event
     */
  onFileSelected(event: Event):void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      if (!file.name.endsWith('.xml')) {
        this.commonService.showToast(3000, 'invalid_xml');
        return;
      }
      // Process the XML file here
      const formData = new FormData();
      formData.append('estimateUploadFile', file);
      formData.append('domainId', this.domainId?.toString());
      formData.append('caseGuid', this.objectGuid);
      formData.append('inspectionId', this.inspectionId?.toString());
      formData.append('vehicleId', this.vehicleId?.toString());
      formData.append('estimateType', '4');
      this.commonService.showLoading();
      this.repairEstimateService.uploadAudatexEstimate(formData).subscribe({
        next: () => {
          this.router.navigate([ `/repair/booking/${this.objectGuid}/estimate` ], { queryParams: { uploadEstimate: 'true' } });
          this.commonService.hideLoading();
        },
        error: () => {
          this.commonService.hideLoading();
        }
      })
    }
    input.value = '';
  }
}

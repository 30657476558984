/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Localization } from 'src/app/services/common/common.service';

let nextUniqueId = 0;
@Component({
  selector: 'xa-numeric',
  templateUrl: './xa-numeric.component.html',
  styleUrls: [ './xa-numeric.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaNumeric),
      multi: true
    },
  ]
})
export class XaNumeric implements AfterContentInit {
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() label?: string;
  @Input() name?: string;
  @Input() i18n?: Localization;
  @Input() placeholder?: string;
  @Input() textAddon?: string;
  @Input() showCounter?: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() value: any = 0;
  @Input() pattern?: string;
  @Input() hint?: string;
  @Input() hintIcon?:string;
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  @Output() maxEventTriggered: EventEmitter<void> = new EventEmitter();
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-numeric-${nextUniqueId++}`

  _controlValueAccessorChangeFn: (value: any) => void = () => { };
  onTouched: () => any = () => { };

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    if(this.step){
      const multiplier = Math.pow(10, this.step);
      this.value = Math.round((Number(value) + Number.EPSILON) * multiplier) / multiplier;
      if(this._inputElement){
        const inputVal = this._inputElement.nativeElement.value;
        if (this.value != inputVal) this._inputElement.nativeElement.value = this.value;
      }
     
    }else{
      this.value = value;
    }
    if (this.value != 0 && !this.value && this._inputElement) this._inputElement.nativeElement.value = '';
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    const currentValue: any = parseFloat(this._inputElement.nativeElement.value);
    if (isNaN(currentValue)) {
      //TO fix the required validation
      this.value = currentValue;
      this._controlValueAccessorChangeFn(null);
      this._inputElement.nativeElement.value = currentValue
      return
    }
    let newValue = Number(parseFloat(this._inputElement.nativeElement.value).toFixed(2));
    if (newValue <= this.min) {
      newValue = this.min;
      this.maxEventTriggered.emit();
    }
    if (newValue >= this.max) {
      newValue = this.max;
      this.maxEventTriggered.emit();
    }
    if (currentValue !== newValue) {
      this._inputElement.nativeElement.value = newValue.toString();
      this._controlValueAccessorChangeFn(Number(newValue.toString()));
    }
    this._controlValueAccessorChangeFn(Number(this._inputElement.nativeElement.value));
  }

  /**
 * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
 * @param isDisabled Whether the control should be disabled.
 */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * get genrated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

  /**
   * function
   */
  increment(): void {
    if (this.disabled) return;
    const currentValue = this._inputElement.nativeElement.value.indexOf('.') !== -1 ? parseFloat(this._inputElement.nativeElement.value) : parseInt(this._inputElement.nativeElement.value);
    if (currentValue + this.step <= this.max) {
      const newValue = currentValue + this.step
      this._inputElement.nativeElement.value = newValue.toString()
      this._controlValueAccessorChangeFn(newValue);
    }
  }

  /**
   * function
   */
  decrement(): void {
    if (this.disabled) return;
    const currentValue = this._inputElement.nativeElement.value.indexOf('.') !== -1 ? parseFloat(this._inputElement.nativeElement.value) : parseInt(this._inputElement.nativeElement.value);
    if (currentValue - this.step >= this.min) {

      const newValue = currentValue - this.step
      this._inputElement.nativeElement.value = newValue.toString()
      this._controlValueAccessorChangeFn(newValue);
    }
  }


}

<div [formGroup]="formGroup"
     class="input-wrapper"
     [ngClass]="{ 'text-mode': inputModel.mode === 'text', 'input-text': (inputModel.type === 'text' || inputModel.type === 'number'), 'invalid-datetimes': (inputModel.type === 'datetime' && inputModel?.customMessage) }">
  <label class="input-label"
         *ngIf="inputModel?.label && input && inputModel?.type !== 'radio'">
    {{ inputModel.label | translate }}
    <span *ngIf="inputModel.required"
          class="required">*</span></label>

  <ng-container *ngIf="inputModel.type === 'date';else datetime">
    <input autocomplete="off"
           [readonly]="inputModel?.disabled"
           #input
           matInput
           [matDatepicker]="picker"
           [formControlName]="inputModel.name"
           placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}"
           (click)="openDatePicker(picker);"
           [name]="inputModel.name"
           type="text"
           class="form-input"
           [max]="inputModel.maxDate"
           [min]="inputModel.minDate"
           [ngClass]="{'picker-open': isPickerOpen}" />
    <mat-icon [svgIcon]="'book-calendar'"
              (click)="openDatePicker(picker);"
              class="calendar-icon"
              [ngClass]="{'disabled-icon': inputModel?.disabled}"></mat-icon>
    <mat-datepicker #picker
                    [calendarHeaderComponent]="datepickerHeader"
                    (closed)="onClose()"
                    style="display: none;">
    </mat-datepicker>
  </ng-container>

  <ng-template #datetime>
    <ng-container *ngIf="inputModel.type === 'datetime';else time">
      <dx-date-box type="datetime"
                   [showClearButton]="false"
                   [value]="inputModel.value"
                   [formControlName]="inputModel.name"
                   #input
                   [width]="'100%'"
                   placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}"
                   [invalidDateMessage]="''"
                   [useMaskBehavior]="true"
                   [openOnFieldClick]="true"
                   [adaptivityEnabled]="true"
                   [min]="inputModel.minDate"
                   [displayFormat]="'dd/MM/yyyy hh:mm aa'"
                   [dateSerializationFormat]="'yyyy-MM-ddTHH:mm:ssZ'"
                   [max]="inputModel.maxDate"
                   [pickerType]="'calendar'"
                   [validationError]="false"
                   [validationMessageMode]="'none'"
                   [acceptCustomValue]="false"
                   [disabled]="inputModel?.disabled"
                   [inputAttr]="{ 'aria-label': 'Date And Time' }">
      </dx-date-box>
      <div class="dropdown-overlay"
           *ngIf="input.opened"></div>
    </ng-container>
  </ng-template>

  <ng-template #time>
    <ng-container *ngIf="inputModel.type === 'time';else radio">
      <dx-date-box type="time"
                   [showClearButton]="false"
                   [formControlName]="inputModel.name"
                   #input
                   [width]="'100%'"
                   [invalidDateMessage]="''"
                   [useMaskBehavior]="true"
                   [openOnFieldClick]="true"
                   [adaptivityEnabled]="true"
                   [min]="inputModel.minDate"
                   [displayFormat]="'hh:mm aa'"
                   [max]="inputModel.maxDate"
                   [pickerType]="'list'"
                   [validationError]="false"
                   [validationMessageMode]="'none'"
                   [disabled]="inputModel?.disabled"
                   [interval]="inputModel.interval">
      </dx-date-box>
      <div class="dropdown-overlay"
           *ngIf="input.opened"></div>
    </ng-container>
  </ng-template>

  <!-- <ng-template #time>
    <ng-container *ngIf="inputModel.type === 'time';else radio">
      <dx-date-box type="time" [value]="'now'" [width]="'100%'">
      </dx-date-box>
    </ng-container>
  </ng-template> -->

  <ng-template #radio>
    <ng-container *ngIf="inputModel.type === 'radio';else textarea">
      <div class="radio"
           *ngIf="inputModel.mode !== 'text'">
        <div class="radio-option"
             *ngFor="let option of inputModel.options">
          <input autocomplete="off"
                 [readonly]="inputModel?.disabled"
                 [formControlName]="inputModel.name"
                 type="radio"
                 [id]="option.label + '_' + inputModel.name"
                 [value]="option.label"
                 [ngClass]="{'tick-icon': option.checked}" />
          <label *ngIf="!inputModel.hideLabel"
                 [for]="option.label + '_' + inputModel.name"
                 [ngClass]="option.label">{{
            option.label | translate }}</label>
        </div>
      </div>
      <div class="radio-text-mode"
           *ngIf="inputModel.mode === 'text'">
        <div>{{inputModel.value}}</div>
      </div>
    </ng-container>

  </ng-template>
  <ng-template #textarea>
    <ng-container *ngIf="inputModel.type === 'textarea';else dropdown">
      <textarea [readonly]="inputModel?.disabled"
                [value]="inputModel?.value"
                [rows]="inputModel?.rows || 8"
                [attr.maxLength]="inputModel?.maxLength > 0 ? inputModel?.maxLength : null"
                [formControlName]="inputModel?.name"
                *ngIf="inputModel.mode !== 'text'"
                placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}"
                #input
                (input)="textareaChange($event)"></textarea>
      <div *ngIf="inputModel.mode === 'text'"
           class="textarea-text-mode">
        <label>{{ inputModel?.placeHolder | translate}} </label>
        <div [innerText]="inputModel?.value ? inputModel?.value : '--'"></div>
      </div>
    </ng-container>

  </ng-template>
  <ng-template #dropdown>
    <ng-container *ngIf="inputModel.type === 'select';else checkbox">
      <chq-dropdown [ngClass]="{'disabled-dropdown': inputModel?.disabled}"
                    (clicked)="triggerClicked($event);"
                    class="chq-dropdown"
                    *ngIf="dropdownModel"
                    #dropdownContainer
                    [dropdownModel]="dropdownModel"
                    (selected)="getDropdownOutput($event)"></chq-dropdown>
    </ng-container>
  </ng-template>

  <ng-template #checkbox>
    <ng-container *ngIf="inputModel.type === 'checkbox';else search">
      <div class="checkbox-wrapper"
           *ngIf="inputModel.mode !== 'text'">
        <label class="checkbox"
               [ngClass]="option.disabled && 'disabled-checkbox'"
               *ngFor="let option of inputModel.options"
               [for]="option.label + '_' + inputModel.name">
          <mat-icon *ngIf="option.icon"
                    [svgIcon]="option.icon"
                    [attr.color]="option.color"
                    [style]=" option.backgroundColor ? 'background-color:'+ option.backgroundColor : ''"></mat-icon>
          <span class="span-label"
                [ngClass]="(option.icon) ? 'span-adjust' : ((inputModel.cssClass) ? inputModel.cssClass : '')">{{
            option.label | translate }}</span>
          <span *ngIf="option.legendColor"
                class="legend"
                [style.background]="option.legendColor"></span>
          <input autocomplete="off"
                 #input
                 type="checkbox"
                 [id]="option.label + '_' + inputModel.name"
                 [name]="option.label + '_' + inputModel.name"
                 [value]="option.value"
                 [checked]="option.checked"
                 (change)="checkboxValueChange($event, inputModel.name, option.value, option)"
                 [disabled]="option.disabled">
          <span class="checkmark"
                #spanCheckbox></span>
        </label>
        <input autocomplete="off"
               [name]="inputModel?.name"
               [formControlName]="inputModel?.name"
               type="hidden"
               [value]="inputModel?.value" />
      </div>
      <div class="checkbox-text-mode"
           *ngIf="inputModel.mode === 'text'">
        <div *ngFor="let option of inputModel.options"
             [ngStyle]="!option.checked?{'display': 'none'} : {}">
          <div>{{ option.label }}</div>
        </div>
      </div>

    </ng-container>
  </ng-template>
  <ng-template #search>
    <ng-container *ngIf="inputModel.type === 'search';else number">
      <mat-icon [svgIcon]="inputModel.preIcon"
                class="mat-pre-icon"></mat-icon>
      <input autocomplete="off"
             [readonly]="inputModel?.disabled"
             [formControlName]="inputModel.name"
             [name]="inputModel.name"
             type="text"
             [maxlength]="inputModel.maxLength"
             #input />
      <mat-icon [svgIcon]="inputModel.icon"
                class="mat-post-icon"
                (click)="postSearchIconClick()"></mat-icon>
    </ng-container>

  </ng-template>

  <ng-template #number>
    <ng-container *ngIf="inputModel.type === 'number';else placesearch">
      <div class="default-wrapper"
           [ngClass]="{'text-mode-icon' : inputModel.mode === 'text' && inputModel.textModeIcon, 'icon-clickable': inputModel?.onIconClick, 'prepand-text' : inputModel.prependText }">
        <mat-icon [svgIcon]="inputModel.textModeIcon"
                  (click)="inputModel?.onIconClick(inputModel.value);"
                  *ngIf="inputModel.mode === 'text' && inputModel.textModeIcon"
                  color="white"></mat-icon>
        <div class="input-group-prepend"
             *ngIf="inputModel.prependText">
          <span class="input-group-text">{{inputModel.prependText}}</span>
        </div>
        <input inputmode="numeric"
               autocomplete="off"
               [readonly]="inputModel?.disabled"
               [formControlName]="inputModel.name"
               [name]="inputModel.name"
               type="text"
               [pattern]="pattern"
               [maxlength]="inputModel.maxLength"
               #input
               placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}"
               onkeydown="javascript: return (['Backspace','Delete','ArrowLeft','ArrowRight', 'Period', 'Tab', 'Ctrl', 'c', 'v', 'a'].includes(event.key) || ['Backspace','Delete','ArrowLeft','ArrowRight', 'Period', 'Tab', 'Ctrl'].includes(event.code)) ? true : !isNaN(Number(event.key)) && (event.key!=='Space' || event.code!=='Space')"
               (keyup)="numericFilter()" />
        <mat-icon [svgIcon]="inputModel.icon"
                  *ngIf="inputModel.icon"
                  [attr.color]="inputModel.iconColor"></mat-icon>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #placesearch>
    <ng-container *ngIf="inputModel.type === 'placesearch';else default">
      <div class="default-wrapper"
           [ngClass]="{'text-mode-icon' : inputModel.mode === 'text' && inputModel.textModeIcon, 'icon-clickable': inputModel?.onIconClick }">
        <mat-icon [svgIcon]="inputModel.textModeIcon"
                  *ngIf="inputModel.mode === 'text' && inputModel.textModeIcon"
                  color="white"></mat-icon>
        <input autocomplete="off"
               [readonly]="inputModel?.disabled"
               [formControlName]="inputModel.name"
               [name]="inputModel.name"
               [type]="inputModel.type"
               placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}"
               chqGooglePlacesAutocomplete
               #places="chq-places"
               (addressChanged)="addressChanged($event)"
               #input />
        <mat-icon [svgIcon]="inputModel.icon"
                  (click)="inputModel?.onIconClick(inputModel.value);"
                  *ngIf="inputModel.icon"
                  [attr.color]="inputModel.iconColor"></mat-icon>
        <span *ngIf="inputModel.suffix"
              class="suffix">{{ inputModel.suffix }}</span>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #default>
    <div class="default-wrapper"
         [ngClass]="{'text-mode-icon' : inputModel.mode === 'text' && inputModel.textModeIcon, 'icon-clickable': inputModel?.onIconClick, 'prepand-text' : inputModel.prependText }">
      <mat-icon [svgIcon]="inputModel.textModeIcon"
                *ngIf="inputModel.mode === 'text' && inputModel.textModeIcon"
                color="white"></mat-icon>
      <div class="input-group-prepend"
           *ngIf="inputModel.prependText">
        <span class="input-group-text">{{inputModel.prependText}}</span>
      </div>
      <input autocomplete="off"
             [readonly]="inputModel?.disabled"
             [formControlName]="inputModel.name"
             [name]="inputModel.name"
             [type]="inputModel.type"
             [maxlength]="inputModel.maxLength"
             *ngIf="inputModel?.type !== 'select' && inputModel?.type !=='radio'"
             #input
             placeholder="{{ (inputModel.mode === 'text') ? '--' :  inputModel.placeHolder | translate }}" />
      <mat-icon [svgIcon]="inputModel.icon"
                (click)="inputModel?.onIconClick(inputModel.value);"
                *ngIf="inputModel.icon"
                [attr.color]="inputModel.iconColor"></mat-icon>
      <span *ngIf="inputModel.suffix"
            class="suffix">{{ inputModel.suffix }}</span>
    </div>
  </ng-template>
  <ng-container *ngIf="inputModel.type === 'select';else defaultValidation">
    <div *ngIf="dropdownValidationMessage !==''">
      <div class="invalid">
        {{dropdownValidationMessage | translate}}
      </div>
    </div>
  </ng-container>
  <ng-template #defaultValidation>
    <div *ngIf="(formControl?.dirty || formControl?.touched) && formControl?.errors && !isPickerOpen">
      <div class="invalid">
        {{inputModel.validation?.validationMessage(formControl.errors) | translate}}
      </div>
    </div>
  </ng-template>

</div>
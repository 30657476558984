import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { TableConfig } from 'src/app/widgets/xa-table/xa-table.component';

@Component({
  selector: 'reserve-info-dialog',
  templateUrl: './reserve-info-dialog.component.html',
  styleUrls: [ './reserve-info-dialog.component.scss' ]
})
export class ReserveInfoDialogComponent implements OnInit {
  coverageMapper: any = {};
  tableData: any[] = [];
  public reserveTableConfig: TableConfig = {
    columns: [
      {
        id: 'ReserveType',
        sortable: false,
        header: {
          text: this.translateService.instant('reserve_type').toUpperCase(),
          tooltip: '',
        },
        selector: {
          field: 'reserveType',
        },
        widget: {
          type: 'text'
        }
      }, {
        id: 'Cost',
        sortable: false,
        header: {
          text: 'to_be_paid_cur',
          tooltip: '',
        },
        selector: {
          field: 'toBePaid',
        },
        widget: {
          type: 'number'
        }
      },
      {
        id: 'remaining',
        sortable: false,
        header: {
          text: 'already_paid',
          tooltip: '',
        },
        selector: {
          field: 'alreadyPaid',
        },
        widget: {
          type: 'number'
        }
      },
      {
        id: 'totalRemaining',
        sortable: false,
        header: {
          text: 'remaining',
          tooltip: '',
        },
        selector: {
          field: 'remaining',
        },
        widget: {
          type: 'number'
        }
      }
    ]
  };
  i18n: Localization;
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }


  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<ReserveInfoDialogComponent>,
    private monitorService: MonitorService,
    private commonService: CommonService,
    private claimService: ClaimsService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
   * close
   */
  close(): void {
    this.monitorService.logEvent('send', [ 'NotifyDialogComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
   * ng oninit
   */
  ngOnInit(): void {
    this.tableData = this.data.reserveData;
    this.tableData.forEach((x)=>{
      x.remaining = x.toBePaid - x.alreadyPaid;
    })
  }

}


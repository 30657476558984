<div class="update-stock-container">
    <div class="header">
        <h3>{{'update_stock' | translate}}</h3>
        <div class="close-div">
            <div class="close-icon"
                 (click)="close(false);">
                <mat-icon [svgIcon]="'cross'"></mat-icon>
            </div>
        </div>
    </div>
    <div class="content-container"
         [formGroup]="formGroup">
        <div class="row">
            <div class="col-6">
                <xa-select [formControlName]="'updateType'"
                           class="update-select"
                           [options]="options"
                           [required]="true"
                           [config]="config"></xa-select>
            </div>
            <div class="col-6">
                <xa-text [label]="'Quantity'"
                         [formControlName]="'quantity'"
                         [placeholder]="'Quantity'"
                         [name]="'quantity'"
                         [maxLength]="10"
                         [required]="true"
                         (keyup)="allowWholeNumberOnly($event)">
                </xa-text>
            </div>
        </div>
        <div class="row mt-20 mb-20">
            <div class="col-12">
                <input type="file"
                       #fileInput
                       accept=".jpg,.jpeg,.png,.pdf"
                       (change)="onFileSelected($event)"
                       style="display: none;" />

                <xa-button (click)="triggerFileInput()"
                           [type]="'outline'"
                           [color]="'gray'"
                           style="width:auto;">
                    <mat-icon [svgIcon]="'attachment'">

                    </mat-icon>
                    {{'add_attachment' | translate}}
                </xa-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngIf="selectedFile"
                     class="selected-file">
                    <p>
                        <a *ngIf="isPreviewAvailable()"
                           [href]="fileUrl"
                           target="_blank">{{ selectedFile.name }}</a>
                        <span *ngIf="!isPreviewAvailable()">{{ selectedFile.name }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <xa-textarea [label]="'Remarks'"
                             [formControlName]="'remarks'"
                             [placeholder]="'Enter here'"
                             [name]="'remarks'"
                             [maxLength]="5000"
                             [rows]="12"></xa-textarea>
            </div>
        </div>
        <div class="row mt-20"
             [ngClass]="{'d-block': showReason, 'd-none': !showReason}">
            <div class="col-12">
                <xa-select [formControlName]="'reason'"
                           class="update-select"
                           [options]="optionsReason"
                           [config]="configReason"></xa-select>
            </div>
        </div>
    </div>
    <div class="btn-container">
        <xa-button [type]="'primary'" [disabled]="!this.formGroup.valid"
                   (click)="saveStock()">
            {{'save' | translate}}
        </xa-button>
    </div>
</div>
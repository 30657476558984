import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  /**
     * constructor
     * @param http
    */
  constructor(private http: HttpClient) { }
  /**
   * 
   * @returns 
   */
  getFeedbackQuestionnaires(token: string, source: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/feedback/${source}/questions`;
    const headers = new HttpHeaders().append('token', decodeURIComponent(token));
    return this.http.get(url, { headers });
  }

  /**
   * 
   * @returns 
   */
  saveFeedback(token, source, payload): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/feedback/${source}`;
    const headers = new HttpHeaders().append('token', decodeURIComponent(token));
    return this.http.post(url, payload, { headers });
  }
}
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepairTypeFormModel } from 'src/app/model/chq-settings-model';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public isRepairChange = new EventEmitter<boolean>(false)
  public isJobTasksEnabled = new EventEmitter<boolean>(false)
  private apiUrl: string = 'setting';
  /**
     * constructor
     * @param http
    */
  constructor(private http: HttpClient) { }


  /**
   * save RepairEstimationSettings
   * @returns {Observable<any>}
   */
  saveRepairEstimationSettings(data: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/task-estimation`;
    return this.http.put(url, data);
  }
  /**
   * get RepairEstimationSettings
   * @returns {Observable<any>}
   */
  getRepairEstimationSettings(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/task-estimation`;
    return this.http.get(url);
  }
  /**
   * getEmployeeOffTimeType
   * @returns {Observable<any>}
   */
  getEmployeeOffTimeType(): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/lookup?type=employeeOffTimeType`;
    return this.http.get(url);
  }

  /**
   * get employee Details
   */
  getEmployeeDetails(id: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${id}`;
    return this.http.get(url);
    // const url = `${environment.bookingMgmtUrl}/api/employee/getorcreate`;
    // return this.http.post(url, id);
    //return of(data.value[0]);
  }

  /**
   * update employee Details
   */
  updateEmployeeDetails(EmployeeGuid: any, data: any): Observable<any> {
    // const url = `${environment.bookingMgmtUrl}/api/employee/${id}`;
    // return this.http.get(url);
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}`;
    return this.http.put(url, data);
    //return of(data.value[0]);
  }

  /**
   * add new work schedule
   */
  addNewWorkHours(EmployeeGuid: any, data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/workschedule`;
    return this.http.post(url, data);
  }

  /**
   * add new off time
   */
  addNewOffTime(EmployeeGuid: any, data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/offtime`;
    return this.http.post(url, data);
  }

  /**
 * add new work schedule for Team
 */
  addNewWorkHoursForTeam(EmployeeGuid: any, data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/employees/workschedule`;
    return this.http.post(url, { workSchedule: data });
  }

  /**
   * add new off time for Team
   */
  addNewOffTimeForTeam(EmployeeGuid: any, data: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/employees/offtime`;
    return this.http.post(url, { offtime: data });
  }

  /**
   * delete work schedule
   */
  deleteWorkHours(EmployeeGuid: any, workScheduleGuid: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/workschedule/${workScheduleGuid}`;
    return this.http.delete(url);
  }

  /**
   * add new off time
   */
  deleteOffTime(EmployeeGuid: any, offTimeGuid: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/offtime/${offTimeGuid}`;
    return this.http.delete(url);
  }

  /**
   * getEmployeeCalendar
   */
  getEmployeeCalendar(EmployeeGuid: any, fromDate: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/employee/${EmployeeGuid}/${fromDate}`;
    return this.http.get(url);
  }

  /**
   * getCompetencies
   */
  getCompetencies(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Competencies`;
    return this.http.get(url);
  }

  /**
   * updateCompetencies
   */
  updateCompetencies({ competenciesIDs, userID, userGroupGuid }): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Employees/user-competencies`;
    return this.http.post(url, { competenciesIDs, userID, userGroupGuid });
  }

  /**
   * get booking types
   * @returns Observable
   */
  public getEmployees(pagination?: ApiPagination, filter?: any): Observable<any> {
    let url = '';
    if (pagination) {
      filter = (!filter) ? '' : `&$filter=(${filter})`;
      const orderBy = pagination.orderBy ? `&$orderby=${pagination.orderBy} ${pagination.sortDirection}` : '';
      const skip = (pagination.currentPage - 1) * pagination.pageSize;
      url = `${environment.autoCoreApi}/odata/Employees?$top=${pagination.pageSize}&$skip=${skip}${filter}${orderBy}&$count=true`;
    } else {
      url = `${environment.autoCoreApi}/odata/Employees`;
    }
    return this.http.get(url);
  }

  /**
   * Save new Booking Type
   * @returns Observable
   */
  public saveNewBookingType(bookingTypeFormData: any, query = ''): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/settings/booking-types${query}`;
    return this.http.put(url, bookingTypeFormData);
  }

  /**
   * Save new repair Type
   * @returns Observable
   */
  public saveNewRepairType(repairTypeFormData: RepairTypeFormModel): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/repair-service-type`;
    return this.http.post(url, repairTypeFormData);
  }

  /**
 * Get settings
 * @returns Observable
 */
  public getSettings(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}`;
    return this.http.get<any>(url);
  }

  /**
   * Get all settings
   * @returns Observable
   */
  public getAllSettings(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/get-all`;
    return this.http.get<any>(url);
  }

  /**
 * Get booking repair types from setting
 * @returns Observable
 */
  public getBookingRepairType(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/booking-repair-type`;
    return this.http.get<any>(url);
  }

  /**
 * Get booking repair types from setting
 * @returns Observable
 */
  public getRepairTypes(query: string = ''): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/repair-service-type${query}`;
    return this.http.get<any>(url);
  }

  /**
 * Get booking repair types from setting
 * @returns Observable
 */
  public getBookingTypes(query: string = ''): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/settings/booking-types${query}`;
    return this.http.get<any>(url);
  }

  /**
   * getBookingTypeColors
   * @returns {Observable<any>}
   */
  getBookingTypeColors(domainId: number): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/lookup?type=BookingTypeColor&domainId=${domainId}`;
    return this.http.get(url);
  }

  /**
  * Function to fetch VehicleLocation
   * @returns
   */
  public getVehicleLocation(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=vehicleLocation`;
    return this.http.get<any>(url);
  }

  /**
* Function to fetch VehicleLocation
 * @returns
 */
  public getRepairWorkflowType(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=RepairWorkflowType`;
    return this.http.get<any>(url);
  }

  /**
   * get Target Settings
   * @returns {Observable<any>}
   */
  getTargetSettings(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/target-type`;
    return this.http.get(url);
  }

  /**
   * Description placeholder
   *
   * @param {*} body
   * @returns {Observable<any>}
   */
  saveTargetSettings(body: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/target-type`;
    return this.http.post(url, body);
  }

  /**
   * Description xeroAuth
   */
  xeroAuth(orgId: number): Observable<any> {
    const url = `${environment.financeURL}/token/xero/authorize/${orgId}`;
    return this.http.get(url);
  }

  /**
   * generate Xero Token
   *
   * @param {string} code
   * @returns {Observable<any>}
   */
  generateXeroToken(code: string, orgId: number): Observable<any> {
    const url = `${environment.financeURL}/token/xero/generate/${code}/${orgId}`;
    return this.http.post(url, null);
  }

  /**
   * Description placeholder
   *
   * @param {number} orgId
   * @returns {Observable<any>}
   */
  getTokenExpiry(orgId: number): Observable<any> {
    const url = `${environment.financeURL}/token/xero/${orgId}`;
    return this.http.post(url, null);
  }

  /**
   * check-allocation-by-date
   */
  checkAllocationByDate(body: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/assignees/check-allocation-by-date`;
    return this.http.post(url, body);
  }

  /**
  * check-allocation-by-date
  */
  checkOrgAllocationByDate(body: any): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/assignees/check-org-allocation-by-date`;
    return this.http.post(url, body);
  }

  /**
   * @returns {*}
   */
  unassignByRepairNumber(data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repairstages/unassign/by-booking`;
    return this.http.put(url, data);
  }

  /**
  * @returns {*}
  */
  delayBooking(data): Observable<any> {
    const url = `${environment.bookingMgmtUrl}/api/booking/delay`;
    return this.http.post(url, data);
  }

  /**
   *
   * @returns
   */
  getPermissionSetting(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/users/permission-setting`;
    return this.http.get(url);
  }

  /**
   *
   * @param data
   * @returns
   */
  updatePermissionSetting(data): Observable<any> {
    const url = `${environment.autoCoreApi}/api/permission/permission-setting`;
    return this.http.post(url, data);
  }

  /**
   *
   * @returns
   */
  getNotificationSetting(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/notification`;
    return this.http.get(url);
  }

  /**
   *
   * @param domainId
   * @param templateName
   * @returns
   */
  getNotificationTemplate(domainId: any, templateName: string): Observable<any> {
    const url = `${environment.communicationApi}/api/communicationtemplate/${domainId}/${templateName}`;
    return this.http.get(url);
  }

  /**
   *
   * @param data
   * @returns
   */
  updateNotificationSetting(data): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/setting/repair-setting`;
    return this.http.put(url, data);
  }

  /**
   *
   * @returns
   */
  saveAudatexSettings(body:any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings/integrationsetting`;
    return this.http.post(url, body);
  }

  /**
   *
   * @returns
   */
  getAudatexSettings(integrationType = 2): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings/integrationsetting/${integrationType}`;
    return this.http.get(url);
  }
  /**
   * Description placeholder
   *
   * @param {*} payload
   * @returns {Observable<any>}
   */
  getSupplierLists(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Partnership/partner-orgType?orgTypeId=12`;
    // const url = `${environment.caseMgmtUrl}/api/TenantAccOrgPartner/supplier-partner/963`;
    return this.http.get(url);
  }

  /**
   * Description placeholder
   *
   * @param {*} payload
   * @returns {Observable<any>}
   */
  getSupplier(orgId): Observable<any> {
    const url = `${environment.autoCoreApi}/api/TenantAccOrgPartner/supplier-partner/${orgId}`;
    return this.http.get(url);
  }

  /**
   * Description placeholder
   *
   * @param {*} payload
   * @returns {Observable<any>}
   */
  autoCoreApiLookupType(type: any): Observable<any> {
    //SupplierMaterialType
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=${type}`;
    return this.http.get(url);
  }

  /**
   * Description placeholder
   *
   * @param {*} payload
   * @returns {Observable<any>}
   */
  upsertSupplier(payload: any): Observable<any> {
    //SupplierMaterialType
    const url = `${environment.autoCoreApi}/api/TenantAccOrgPartner`;
    return this.http.post(url, payload);
  }
}

<div class="search-container">
    <div class="row">
        <div class="col-12 small-margin">
            <div class="input-wrapper" #row >
                <mat-icon [svgIcon]="'search'" (click)="search(searchBox.value)"></mat-icon>
                <input type="text" autocomplete="off" class="" aria-describedby="search-text" placeholder="{{'repair_general_search_msg' | translate}}" autofocus
                        #searchBox id="search-box"  (keyup.enter)="search(searchBox.value)" />
                    <mat-icon [svgIcon]="'cross'" class="right-icon"  (click)="close()"></mat-icon>
            </div>
        </div>
      </div>
      <div class="search-result-container" [ngClass]="{ 'no-data-wrapper': !searchResult || groupedSearchResult?.length === 0 }">
         <div class="search-result-section-container" *ngIf="searchResult && groupedSearchResult.length > 0">
            <div *ngFor="let searchData of groupedSearchResult">
                <chq-search-result-section (closeSearch)="close();" (redirectUser)="handleUserAction($event);" [searchModel]="searchData" [showPagination]="true" [isClaimModule]="isClaimModule" ></chq-search-result-section>
            </div>
            
         </div>
         <div class="search-result-no-data-container" *ngIf="!searchInProgress && (!searchResult  || empty)">
            <mat-icon [svgIcon]="'no-data'" class="no-data"></mat-icon>
            <label>
                Sorry, no matches were found.
            </label>
            <label>
                Try a new search
            </label>
            <div class="button-wrapper">
                <chq-button  *ngIf="repairPermission && !isExternal" [buttonModel]="bookingButton"></chq-button>
                <chq-button *ngIf="quotePermission && !isExternal" [buttonModel]="estimateButton"></chq-button>
            </div>
        </div>
      </div>
</div>
import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TooltipPosition } from '@angular/material/tooltip';

export type IconOptions = {
  icon: string;
  showTooltip?: boolean;
  iconColor?:string;
  tooltipText?: string;
  iconClass?: string;
  tooltipPosition?: TooltipPosition
};

@Component({
  selector: 'expandable',
  templateUrl: './expandable.component.html',
  styleUrls: [ './expandable.component.scss' ]
})
export class ExpandableComponent {
  @ViewChild('mep') mep: MatExpansionPanel;
  @Input() titleText: string;
  @Input() iconOptions: IconOptions;
}

<div class="page-container">
    <div class="header-estimate">
        <!-- <div class="back-wrapper row">
            <mat-icon [svgIcon]="'left-arrow'" (click)="back();"></mat-icon>
            <div> {{'back' | translate  }}</div>
        </div> -->
        <div class="estimate-header fx-row align-center">
            <div class="header-label">{{'estimate' | translate}}</div>
            <div class="header-toolbar">
                <ng-container *ngIf="estimateStatus">
                    <div class="estiamte-status" [style.color]="estimateColorCode">
                        {{estimateStatus}}
                    </div>
                </ng-container>
                <chq-button [buttonModel]="estimateLogButtonModel"></chq-button>
            </div>
        </div>
    </div>
    <div class="estimate-container">
        <chq-estimate-details [estimateResponse]="estimateResponse" [editEstimate]="editEstimate"
            [estimateSource]="estimateSource" serviceType="claim" (radioButtonClicked)="radioButtonClicked($event)"
            [i18n]="i18n"> </chq-estimate-details>
    </div>
</div>

<div class="footer-full-wrapper">
    <div class="footer-image-wrapper">
        <div class="image-wrapper">
            <img src="assets/img/addenda-claims.png" alt=""/>
        </div>
    </div>

    <div class="footer-button-wrapper show-md">
        <ng-container *ngIf="estimateStatus === 'Pending Approval'">
            <ng-container *ngIf="editEstimate">
                <chq-button [buttonModel]="cancelEditedModel" (click)="editEstimate = false;"></chq-button>
                <chq-dropdown [dropdownModel]="dropdownAskRevisionModelFilter" *ngIf="estimateChangeList.length === estimateAddRevisionList.length && !isShowApprovedButton" (selected)="handleSelectedOptionAskRevision($event);"></chq-dropdown>
                <chq-button [buttonModel]="rejectEstimateButtonModel" *ngIf="isShowRejectedButton" (click)="openDeclineModel()"></chq-button>
                <chq-button [buttonModel]="approveButtonModel" *ngIf="isShowApprovedButton && !isTotalLossThresholdReached"></chq-button>
            </ng-container>
            <ng-container *ngIf="!editEstimate">
                <chq-button [buttonModel]="addRevisionButtonModel" *chqClaimRoles="'claimAssignBack'" (click)="editEstimate = true;"></chq-button>   
                <chq-button [buttonModel]="rejectEstimateButtonModel" *chqClaimRoles="'claimAssignBack'" (click)="openDeclineModel()"></chq-button>
                <ng-container *ngIf="!isTotalLossThresholdReached">
                    <chq-button [buttonModel]="approveButtonModel" *chqClaimRoles="'claimAssignBack'" ></chq-button> 
                    <xa-button (click)="viewLPO()" *chqClaimRoles="'claimAssignBack'" [type]="'outline'" color="gray">
                        <span> {{'view_lpo' | translate }}</span>
                    </xa-button>
                </ng-container>
                <ng-container *ngIf="isTotalLossThresholdReached && !(claimDetails.lossDetails?.isTotalLoss)">
                    <chq-button [buttonModel]="markAsTotalLossModel" *chqClaimRoles="'claimAssignBack'"></chq-button> 
                </ng-container>
            </ng-container>
            
        </ng-container>   
        <ng-container *ngIf="estimateStatus === 'Rejected'">
            <xa-button (click)="requestForRevisedEstimate()">
                <span> {{'request_for_revised_estimate' | translate }}</span>
            </xa-button>
        </ng-container>     
    </div>
</div>
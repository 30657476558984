
export const claimCustomerDetailsForm = [
  {
    'Id': 16287,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'heading_loss_information',
    'ItemValue': 'Loss Information',
    'Category': null,
    'SortOrder': '1',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'Loss Information',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  /*{
    'Id': 16322,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mandatory_fields',
    'ItemValue': '*mandatory fields',
    'Category': null,
    'SortOrder': '2',
    'Value': [],
    'controlType': 'description',
    'label': 'Paragraph',
    'icon': 'description',
    'visible': 'true',
    'textPrefillValue': '*mandatory fields',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },*/
  {
    'Id': 16252,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'police_report',
    'ItemValue': 'police_report',
    'Category': null,
    'SortOrder': '2',
    'Value': [
      // {
      //   'IsActive': false,
      //   'ItemKey': 'Pattern',
      //   'ItemValue': 'invalid_quote_id',
      //   'Category': null,
      //   'SortOrder': 0,
      //   'Value': [],
      //   'regex': '^\\S{3,}$',
      //   'itemType': 'validations',
      //   'type': 'pattern'
      
      // }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'policeReportNumber',
    'placeholder': 'police_report',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '15',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16794,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_date',
    'ItemValue': 'LOSS DATE',
    'Category': null,
    'SortOrder': '3',
    'Value': [
  
    ],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'lossDate',
    'placeholder': 'loss_date',
    'required': 'true',
    'minDate': new Date('2023-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_location',
    'ItemValue': 'LOSS LOCATION',
    'Category': null,
    'SortOrder': '4',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_loss_location',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9\\s.,\'\\/+-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } 
    ],
    'controlType': 'places',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'lossLocation',
    'placeholder': 'loss_location',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '150',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '40',
    'maxWidth': '40',
    'width': '40',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'no_of_parties',
    'ItemValue': 'NO OF PARTIES',
    'Category': null,
    'SortOrder': '5',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_data',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^\\d+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'noOfParties',
    'placeholder': 'no_of_parties',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '3',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '10',
    'maxWidth': '10',
    'width': '10',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16322,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'is_car_location_different_than_loss',
    'ItemValue': 'isCarAtDifferentLoc',
    'Category': null,
    'SortOrder': '5',
    'Value': [],
    'controlType': 'description',
    'label': 'Paragraph',
    'icon': 'description',
    'visible': 'true',
    'textPrefillValue': 'is_car_location_different_than_loss',
    'fieldValue': 'is_car_location_different_than_loss',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'is_car_location_different_than_loss',
    'ItemValue': 'isCarAtDifferentLoc',
    'Category': null,
    'SortOrder': '5',
    'Value': [],
    'controlType': 'radio',
    'label': 'radio',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'isCarAtDifferentLoc',
    'required': 'false',
    'options': [ { 'label': 'Yes', 'value': true }, { 'label': 'No', 'value': false } ],
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'value',
    'optionsKeyLabel': 'label',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'enter_car_location',
    'ItemValue': 'carLocation',
    'Category': null,
    'SortOrder': '5',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'car_location_required',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9\\s.,\'\\/+-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } 
    ],
    'controlType': 'places',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'carLocation',
    'placeholder': 'enter_car_location',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '150',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16322,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_description_text',
    'ItemValue': 'Loss Description',
    'Category': null,
    'SortOrder': '6',
    'Value': [],
    'controlType': 'description',
    'label': 'Paragraph',
    'icon': 'description',
    'visible': 'true',
    'textPrefillValue': 'Loss Description',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': '',
    'ItemValue': 'LOSS DESCRIPTION',
    'Category': null,
    'SortOrder': '7',
    'Value': [],
    'controlType': 'textarea',
    'label': 'Textarea',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'lossDescription',
    'placeholder': 'loss_description',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '1000',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '100',
    'maxWidth': '100',
    'width': '100',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  // {
  //   'Id': 16392,
  //   'TemplateId': 206,
  //   'DataId': 112,
  //   'ConfigurationItemId': 1253,
  //   'IsActive': true,
  //   'ItemKey': 'Claimant',
  //   'ItemValue': 'CLAIMANT 1',
  //   'Category': null,
  //   'SortOrder': '8',
  //   'Value': [
  //   ],
  //   'controlType': 'section',
  //   'label': 'section',
  //   'icon': 'text_fields',
  //   'visible': 'true',
  //   'fieldValue': 'sectionClaimant',
  //   'placeholder': '',
  //   'prefillControls': '[]',
  //   'required': 'false',
  //   'readonly': 'false',
  //   'maxLength': '',
  //   'blockMaxLength': '',
  //   'prefillMinLength': '',
  //   'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
  //   'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
  //   'minLength': '',
  //   'optionsUrl': '',
  //   'optionsService': '',
  //   'display': '',
  //   'optionsKeyLabel': '',
  //   'optionsKeyValue': '',
  //   'actionType': '',
  //   'formSubmitService': '',
  //   'formSubmitUrl': '',
  //   'formSubmitMethod': 'POST',
  //   'linkUrl': '',
  //   'textPrefillValue': '',
  //   'alertMessage': '',
  //   'defaultValue': '',
  //   'minWidth': '100',
  //   'maxWidth': '100',
  //   'width': '100',
  //   'alignment': 'left',
  //   'fullWidth': 'true'
  // },
  {
    'Id': 17031,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'atFault',
    'ItemValue': 'atFault',
    'Category': null,
    'SortOrder': '8',
    'Value': [],
    'controlType': 'radio',
    'label': 'radio',
    'icon': 'radio',
    'visible': 'true',
    'fieldValue': 'atFault',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'maxDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': 'true',
    'minWidth': '',
    'maxWidth': '',
    'width': '',
    'alignment': '',
    'fullWidth': 'false',
    'options': [
      {
        'label': 'At Fault',
        'value': true
      },
      {
        'label': 'Not At Fault',
        'value': false
      }
    ]
  },
  {
    'Id': 16287,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'heading_personal_information',
    'ItemValue': 'Personal Information',
    'Category': null,
    'SortOrder': '9',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'Personal Information',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'insurer_first_name',
    'ItemValue': 'INSURED FIRST NAME',
    'Category': null,
    'SortOrder': '10',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_first_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'firstName',
    'placeholder': 'first_name',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16427,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'insurer_last_name',
    'ItemValue': 'INSURED LAST NAME',
    'Category': null,
    'SortOrder': '11',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_last_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'placeholder': 'last_name',
    'fieldValue': 'lastName',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16462,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'email',
    'ItemValue': 'EMAIL',
    'Category': null,
    'SortOrder': '12',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'email_valid',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        'itemType': 'validations',
        'type': 'pattern'
      }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'email',
    'placeholder': 'email',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16501,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'code',
    'ItemValue': 'CODE',
    'Category': null,
    'SortOrder': '13',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'code',
    'placeholder': 'code',
    'width': '15',
    'minWidth': '15',
    'maxWidth': '15',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries',
    'optionsKeyValue': 'phoneCountryCode',
    'optionsKeyLabel': 'phoneCountryCode',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16536,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mobile_number',
    'ItemValue': 'MOBILE NUMBER',
    'Category': null,
    'SortOrder': '14',
    'Value': [ {
      'IsActive': false,
      'ItemKey': 'Pattern',
      'ItemValue': 'valid_mobile_number',
      'Category': null,
      'SortOrder': 0,
      'Value': [],
      'regex': '^[0-9]+[0-9 ,.\'-]+$',
      'itemType': 'validations',
      'type': 'pattern'
    } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'mobileNumber',
    'placeholder': 'mobile_number',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '35',
    'maxWidth': '35',
    'width': '35',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16287,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'heading_policy_information',
    'ItemValue': 'Policy Information',
    'Category': null,
    'SortOrder': '15',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'Policy Information',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'policy_number',
    'ItemValue': 'POLICY NUMBER',
    'Category': null,
    'SortOrder': '16',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_policy_number',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9-/ ]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'policyNumber',
    'placeholder': 'policy_number',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '15',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'insurance_company',
    'ItemValue': 'INSURANCE COMPANY',
    'Category': null,
    'SortOrder': '17',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_insurance_company',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[^*^%@!`{}<>]*$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'insuranceCompany',
    'placeholder': 'insurance_company',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16794,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'policy_start_date',
    'ItemValue': 'POLICY START DATE',
    'Category': null,
    'SortOrder': '18',
    'Value': [
  
    ],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'policyStartDate',
    'placeholder': 'policy_start_date',
    'required': 'false',
    'minDate': new Date('2023-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16794,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'policy_end_date',
    'ItemValue': 'POLICY END DATE',
    'Category': null,
    'SortOrder': '19',
    'Value': [
  
    ],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'policyEndDate',
    'placeholder': 'policy_end_date',
    'required': 'false',
    'minDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1),
    'maxDate': new Date(new Date().getFullYear() + 20, new Date().getMonth(), new Date().getDate()),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'sum_insured_currency',
    'ItemValue': 'SUM INSURED',
    'Category': null,
    'SortOrder': '20',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_sum_insured',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^\\d+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'numeric',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'sumInsured',
    'placeholder': 'sum_insured',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'true',
    'maxLength': '15',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16393,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'deductible_currency',
    'ItemValue': 'DEDUCTIBLE',
    'Category': null,
    'SortOrder': '21',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_sum_insured',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^\\d+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'numeric',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'deductible',
    'placeholder': 'deductible',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'true',
    'maxLength': '15',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'repair_type',
    'ItemValue': 'REPAIR TYPE',
    'Category': null,
    'SortOrder': '22',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'repairType',
    'required': 'false',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMRepairType',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'coverage_type',
    'ItemValue': 'COVERAGE TYPE',
    'Category': null,
    'SortOrder': '40',
    'Value': [],
    'controlType': 'multiselect-dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'coverageType',
    'required': 'false',
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': 'Select...',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16571,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vehicle_information',
    'ItemValue': 'Vehicle Information',
    'Category': null,
    'SortOrder': '23',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'vehicle_information',
    'fieldValue': 'vehicle_information',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16648,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vin',
    'ItemValue': 'VIN',
    'Category': null,
    'SortOrder': '24',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'pattern_vin',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-hA-Hj-nJ-NpPr-zR-Z0-9]+$',
        'itemType': 'validations',
        'type': 'pattern'
      } 
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'fieldValue': 'vin',
    'icon': 'text_fields',
    'prefillControls': '', //'[{"controlName":"makeID","prefillAttribute":"vehicleMake"},{"controlName":"modelID","prefillAttribute":"vehicleModel"}]',
    'placeholder': 'vehicle_chassis_number',
    'required': 'true',
    'readonly': 'false',
    'visible': 'true',
    'maxLength': '17',
    'blockMaxLength': 'true',
    'prefillMinLength': '17',
    'minDate': 'Fri Sep 27 2024 15:34:13 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:34:13 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '', //'api/v1/Vehicles/vehicleDetailsByVIN/{this}',
    'optionsService': '', //'autoFoundation',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16899,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'license_plate',
    'ItemValue': 'LICENSE PLATE',
    'Category': null,
    'SortOrder': '25',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'Only alphanumeric characters are allowed',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9]+$',
        'itemType': 'validations',
        'type': 'pattern'
      }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'plateNumber',
    'required': 'true',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '12',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false',
    'hint': ''
  },
  {
    'Id': 16934,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'body_type',
    'ItemValue': 'VEHICLE TYPE',
    'Category': null,
    'SortOrder': '26',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'vehicleType',
    'required': 'true',
    'optionsService': 'autoFoundation',
    'optionsUrl': 'api/v1/VehicleTypes',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16683,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'make',
    'ItemValue': 'make',
    'Category': null,
    'SortOrder': '27',
    'Value': [
      {
        'Id': 16718,
        'TemplateId': 206,
        'DataId': 112,
        'ConfigurationItemId': 1253,
        'IsActive': true,
        'ItemKey': 'Disabled If',
        'ItemValue': '\'\'',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'type': 'disabledIf',
        'controlKey': 'vin',
        'itemType': 'conditions',
        'operator': '<=',
        'conditionalValue': '\'\''
      }
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'makeID',
    'required': 'true',
    'optionsService': 'autoFoundation',
    'optionsUrl': 'api/v1/VehicleMakes',
    'optionsKeyValue': 'name',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]', //'[{"controlName":"modelID","prefillAttribute":"name"}]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'model',
    'ItemValue': 'MODEL',
    'Category': null,
    'SortOrder': '28',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'modelID',
    'required': 'true',
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'modelName',
    'optionsKeyLabel': 'modelName',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  
  {
    'Id': 16864,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'year',
    'ItemValue': 'YEAR',
    'Category': null,
    'SortOrder': '29',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'modelYearRange',
    'optionsService': '',
    'optionsKeyValue': 'name',
    'optionsKeyLabel': 'name',
    'optionsUrl': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 16:13:11 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16794,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'registration_date',
    'ItemValue': 'REGISTRATION DATE',
    'Category': null,
    'SortOrder': '30',
    'Value': [
  
    ],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'registrationDate',
    'placeholder': 'registration_date',
    'required': 'true',
    'minDate': new Date('1980-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16829,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'manufacturing_date',
    'ItemValue': 'MANUFACTURING DATE',
    'Category': null,
    'SortOrder': '31',
    'Value': [],
    'controlType': 'date',
    'label': 'Date',
    'icon': 'calendar_month',
    'visible': 'true',
    'fieldValue': 'manufacturingDate',
    'placeholder': 'manufacturing_date',
    'required': 'false',
    'minDate': new Date('1980-01-01'),
    'maxDate': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    'prefillControls': '[]',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16571,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'incident_information',
    'ItemValue': 'Incident Information',
    'Category': null,
    'SortOrder': '32',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'incident_information',
    'fieldValue': 'incident_information',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_severity',
    'ItemValue': 'LOSS SEVERITY',
    'Category': null,
    'SortOrder': '33',
    'Value': [],
    'controlType': 'label',
    'label': 'label',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'lossSeverity',
    'required': 'false',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMLossSeverity',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'cause_of_loss',
    'ItemValue': 'CAUSE OF LOSS',
    'Category': null,
    'SortOrder': '34',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'causeOfLoss',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMCauseOfLoss',
    'optionsKeyValue': 'name',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'jurisdiction_country',
    'ItemValue': 'JURISDICTION COUNTRY',
    'Category': null,
    'SortOrder': '35',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'jurisdictionCountry',
    'required': 'false',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[{"controlName":"jurisdictionState","prefillAttribute":"name"}]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'jurisdiction_state',
    'ItemValue': 'JURISDICTION STATE',
    'Category': null,
    'SortOrder': '36',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'jurisdictionState',
    'required': 'false',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries/county/{jurisdictionCountry}',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_impact_zone',
    'ItemValue': 'LOSS IMPACT ZONE',
    'Category': null,
    'SortOrder': '37',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'lossImpactZone',
    'required': 'false',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMLossImpactZone',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'nature_of_loss',
    'ItemValue': 'NATURE OF LOSS',
    'Category': null,
    'SortOrder': '38',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'bodyInjuryDetails',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMNatureOfLoss',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'intimation_type',
    'ItemValue': 'INTIMATION TYPE',
    'Category': null,
    'SortOrder': '39',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'intimationType',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMSubIntimationType',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'insurance_provider',
    'ItemValue': 'insuranceCompanyId',
    'Category': null,
    'SortOrder': '39',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'false',
    'fieldValue': 'insuranceCompanyId',
    'required': 'true',
    'optionsService': 'autoCore',
    'optionsUrl': 'odata/Partnership?$filter=PartnershipTypeLookupName eq [type] and   PartnershipOrgDTOs/any(c: c/OrgId eq [OrgId])',
    'optionsKeyValue': 'Id',
    'optionsKeyLabel': 'PartnerName',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'intimator_type',
    'ItemValue': 'INTIMATOR TYPE',
    'Category': null,
    'SortOrder': '41',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'intimatorType',
    'required': 'true',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMIntimationType',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'idv_currency',
    'ItemValue': 'idv',
    'Category': null,
    'SortOrder': '42',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'salvage_amount_two_decimal_places',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[0-9]+(.[0-9]{1,2})?$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'prefillControls': '[]',
    'fieldValue': 'idv',
    'required': 'false',
    'placeholder': 'IDV',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'getValuation',
    'ItemValue': 'getValuation',
    'Category': null,
    'SortOrder': '43',
    'Value': [],
    'controlType': 'button',
    'label': 'get_valuation',
    'icon': '',
    'visible': 'false',
    'fieldValue': '',
    'required': 'false',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'actionType': 'custom',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'getValuation',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'intimator_role',
    'ItemValue': 'INTIMATOR ROLE',
    'Category': null,
    'SortOrder': '44',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'false',
    'fieldValue': 'intimatorRole',
    'required': 'false',
    'optionsService': 'fnol',
    'optionsUrl': 'api/lookup/CLMIntimatorType',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_of_use_no_of_days',
    'ItemValue': 'LOSS OF USE (NUMBER OF DAYS)',
    'Category': null,
    'SortOrder': '45',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'only_positive_integer_allowed',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^(0|[1-9][0-9]*)$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'prefillControls': '[]',
    'fieldValue': 'lossOfUseNoOfDays',
    'required': 'false',
    'placeholder': 'loss_of_use_no_of_days',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_of_use_amount_per_day',
    'ItemValue': 'LOSS OF USE (AMOUNT PER DAY)',
    'Category': null,
    'SortOrder': '45',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'valid_up_to_two_decimal_place',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[0-9]+(.[0-9]{1,2})?$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'prefillControls': '[]',
    'fieldValue': 'lossOfUseAmountPerDay',
    'required': 'false',
    'placeholder': 'loss_of_use_amount_per_day',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16322,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vehicle_total_loss_description',
    'ItemValue': 'Vehicle Total Loss Description',
    'Category': null,
    'SortOrder': '45',
    'Value': [],
    'controlType': 'description',
    'label': 'Paragraph',
    'icon': 'description',
    'visible': 'true',
    'textPrefillValue': 'Vehicle Total Loss Description',
    'fieldValue': 'vehicleTotalLossDescription',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'vehicle_total_loss',
    'ItemValue': 'IS VEHICLE REPAIR OR TOTAL LOSS',
    'Category': null,
    'SortOrder': '46',
    'Value': [],
    'controlType': 'radio',
    'label': 'radio',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'vehicleTotalLoss',
    'required': 'false',
    'options': [ { 'label': 'Repair', 'value': false }, { 'label': 'Total Loss', 'value': true } ],
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'value',
    'optionsKeyLabel': 'label',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'true'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'repair_type',
    'ItemValue': 'repair_type',
    'Category': null,
    'SortOrder': '46',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'false',
    'fieldValue': 'repairTypeGarage',
    'required': 'false',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/lookup/type?type=12',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'prefillControls': '[]',
    'placeholder': 'repair_type',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'total_loss_amount',
    'ItemValue': 'totalLossAmount',
    'Category': null,
    'SortOrder': '47',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'salvage_amount_two_decimal_places',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[0-9]+(.[0-9]{1,2})?$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'totalLossAmount',
    'placeholder': 'total_loss_amount',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'salvage_amount',
    'ItemValue': 'Salvage Amount',
    'Category': null,
    'SortOrder': '48',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'salvage_amount_two_decimal_places',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[0-9]+(.[0-9]{1,2})?$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'salvageAmount',
    'placeholder': 'salvage_amount',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'loss_threshold_value',
    'ItemValue': 'Total Loss Threshold',
    'Category': null,
    'SortOrder': '48',
    'Value': [],
    'controlType': 'description',
    'label': 'paragraph',
    'icon': 'title',
    'visible': 'false',
    'fieldValue': 'lossThresholdValue',
    'placeholder': '',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16571,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_information',
    'ItemValue': 'Driver Information',
    'Category': null,
    'SortOrder': '49',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'driver_information',
    'fieldValue': 'driver_information',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16724,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'isOwner',
    'ItemValue': 'IS OWNER',
    'Category': null,
    'SortOrder': '50',
    'Value': [],
    'controlType': 'radio',
    'label': 'radio',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'isOwner',
    'required': 'false',
    'options': [ { 'label': 'Owner', 'value': true }, { 'label': 'Other', 'value': false } ],
    'optionsService': '',
    'optionsUrl': '',
    'optionsKeyValue': 'value',
    'optionsKeyLabel': 'label',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:49:54 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': '',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_first_name',
    'ItemValue': 'DRIVER FIRST NAME',
    'Category': null,
    'SortOrder': '51',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_first_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'driverFirstName',
    'placeholder': 'driver_first_name',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16427,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_last_name',
    'ItemValue': 'DRIVER LAST NAME',
    'Category': null,
    'SortOrder': '52',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_last_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'placeholder': 'driver_last_name',
    'fieldValue': 'driverLastName',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '25',
    'maxWidth': '25',
    'width': '25',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16501,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_code',
    'ItemValue': 'DRIVER CODE',
    'Category': null,
    'SortOrder': '53',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'false',
    'fieldValue': 'driverCode',
    'placeholder': 'driver_code',
    'width': '10',
    'minWidth': '10',
    'maxWidth': '10',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries',
    'optionsKeyValue': 'phoneCountryCode',
    'optionsKeyLabel': 'phoneCountryCode',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16536,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_mobile_number',
    'ItemValue': 'DRIVER MOBILE NUMBER',
    'Category': null,
    'SortOrder': '54',
    'Value': [ {
      'IsActive': false,
      'ItemKey': 'Pattern',
      'ItemValue': 'valid_mobile_number',
      'Category': null,
      'SortOrder': 0,
      'Value': [],
      'regex': '^[0-9]+[0-9 ,.\'-]+$',
      'itemType': 'validations',
      'type': 'pattern'
    } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'driverMobileNumber',
    'placeholder': 'driver_mobile_number',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '20',
    'maxWidth': '20',
    'width': '20',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16536,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'driver_dl',
    'ItemValue': 'DRIVER DL NUMBER',
    'Category': null,
    'SortOrder': '55',
    'Value': [ {
      'IsActive': false,
      'ItemKey': 'Pattern',
      'ItemValue': 'valid_license_number',
      'Category': null,
      'SortOrder': 0,
      'Value': [],
      'regex': '',
      'itemType': 'validations',
      'type': 'pattern'
    } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'false',
    'fieldValue': 'driverDl',
    'placeholder': 'driver_dl',
    'prefillControls': '[]',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '12',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '20',
    'maxWidth': '20',
    'width': '20',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16571,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'garage_information',
    'ItemValue': 'Garage Information',
    'Category': null,
    'SortOrder': '56',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'garage_information',
    'fieldValue': 'garage_information',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 17031,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'is_insured_garage_network',
    'ItemValue': 'IS INSURED GARAGE NETWORK',
    'Category': null,
    'SortOrder': '57',
    'key': 'is_insured_garage_network',
    'Value': [],
    'controlType': 'checkbox',
    'label': 'Claimant is under the Insured Garage network',
    'icon': 'check_box',
    'visible': 'true',
    'fieldValue': 'isInsuredGarageNetwork',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'maxDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '',
    'alignment': '',
    'fullWidth': 'false',
  },
  {
    'Id': 17031,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'additional_deductible_amount',
    'ItemValue': 'AdditionalDeductibleAmount',
    'Category': null,
    'SortOrder': '57',
    'key': 'additional_deductible_amount',
    'Value': [],
    'controlType': 'numeric',
    'label': 'additional_deductible_amount',
    'icon': 'check_box',
    'visible': 'true',
    'fieldValue': 'additionalDeductible',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'maxDate': 'Mon Sep 30 2024 12:40:34 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
]

export const claimBeneficiariesDetailsForm = [
  {
    'Id': 16287,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'heading_personal_information',
    'ItemValue': 'Personal Information',
    'Category': null,
    'SortOrder': '1',
    'Value': [],
    'controlType': 'title',
    'label': 'Heading',
    'icon': 'title',
    'visible': 'true',
    'textPrefillValue': 'Personal Information',
    'fieldValue': '',
    'prefillControls': '[]',
    'placeholder': '',
    'required': 'false',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:39:20 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '',
    'maxWidth': '',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'true'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'beneficiary_name',
    'ItemValue': 'BENEFICIARY NAME',
    'Category': null,
    'SortOrder': '2',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'beneficiary_name',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z]+[a-zA-Z ,.\'-]+$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'beneficiaryName',
    'placeholder': 'beneficiary_name',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33.33',
    'maxWidth': '33.33',
    'width': '33.33',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16683,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'type',
    'ItemValue': 'type',
    'Category': null,
    'SortOrder': '3',
    'Value': [
    ],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'typeId',
    'required': 'true',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/lookup/type?type=11',
    'optionsKeyValue': 'id',
    'optionsKeyLabel': 'name',
    'display': '',
    'prefillControls': '[]',
    'placeholder': '',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:41:35 GMT+0545 (Nepal Time)',
    'minLength': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33.33',
    'maxWidth': '33.33',
    'width': '33.33',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16462,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'email',
    'ItemValue': 'EMAIL',
    'Category': null,
    'SortOrder': '4',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'email_valid',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        'itemType': 'validations',
        'type': 'pattern'
      }
    ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'email',
    'placeholder': 'email',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '100',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '33.33',
    'maxWidth': '33.33',
    'width': '33.33',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16392,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'address',
    'ItemValue': 'ADDRESS',
    'Category': null,
    'SortOrder': '5',
    'Value': [
      {
        'IsActive': false,
        'ItemKey': 'Pattern',
        'ItemValue': 'invalid_address',
        'Category': null,
        'SortOrder': 0,
        'Value': [],
        'regex': '^[a-zA-Z0-9\\s.,#\'-]*$',
        'itemType': 'validations',
        'type': 'pattern'
      
      } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'address',
    'placeholder': 'address',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '150',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '50',
    'maxWidth': '50',
    'width': '50',
    'alignment': 'left',
    'fullWidth': 'false'
  },
  {
    'Id': 16501,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'code',
    'ItemValue': 'CODE',
    'Category': null,
    'SortOrder': '6',
    'Value': [],
    'controlType': 'dropdown',
    'label': 'Dropdown',
    'icon': 'splitscreen',
    'visible': 'true',
    'fieldValue': 'code',
    'placeholder': 'code',
    'width': '15',
    'minWidth': '15',
    'maxWidth': '15',
    'optionsService': 'autoCore',
    'optionsUrl': 'api/Countries',
    'optionsKeyValue': 'phoneCountryCode',
    'optionsKeyLabel': 'phoneCountryCode',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '',
    'blockMaxLength': 'false',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'display': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'alignment': '',
    'fullWidth': 'false'
  },
  {
    'Id': 16536,
    'TemplateId': 206,
    'DataId': 112,
    'ConfigurationItemId': 1253,
    'IsActive': true,
    'ItemKey': 'mobile_number',
    'ItemValue': 'MOBILE NUMBER',
    'Category': null,
    'SortOrder': '7',
    'Value': [ {
      'IsActive': false,
      'ItemKey': 'Pattern',
      'ItemValue': 'valid_mobile_number',
      'Category': null,
      'SortOrder': 0,
      'Value': [],
      'regex': '^[0-9]+[0-9 ,.\'-]+$',
      'itemType': 'validations',
      'type': 'pattern'
    } ],
    'controlType': 'textbox',
    'label': 'Text Box',
    'icon': 'text_fields',
    'visible': 'true',
    'fieldValue': 'mobileNumber',
    'placeholder': 'mobile_number',
    'prefillControls': '[]',
    'required': 'true',
    'readonly': 'false',
    'maxLength': '10',
    'blockMaxLength': 'true',
    'prefillMinLength': '',
    'minDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'maxDate': 'Fri Sep 27 2024 15:32:30 GMT+0545 (Nepal Time)',
    'minLength': '',
    'optionsUrl': '',
    'optionsService': '',
    'display': '',
    'optionsKeyLabel': '',
    'optionsKeyValue': '',
    'actionType': '',
    'formSubmitService': '',
    'formSubmitUrl': '',
    'formSubmitMethod': 'POST',
    'linkUrl': '',
    'textPrefillValue': '',
    'alertMessage': '',
    'defaultValue': '',
    'minWidth': '35',
    'maxWidth': '35',
    'width': '35',
    'alignment': '',
    'fullWidth': 'false'
  }
]
<div class="main-container" [dir]="direction">
    <div class="header">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="title">
        <h3>
            {{ message | translate }}
        </h3>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="noButton" class="left" *ngIf="data.showCancel"></chq-button>
        <chq-button [buttonModel]="yesButton" class="left"></chq-button>
    </div>
</div>
<div class="operation-container fx-col">
    <div class="header">
        <h3>{{'operation' | translate}}</h3>
        <div class="close-icon">
            <mat-icon svgIcon="cross"
                      (click)="cancelDialog(false);"
                      (keyPress)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <div class="content-header"
             *ngIf="!editRepairEstimateOperation">
            <chq-tabs [mode]="'button'"
                      [menuItems]="menuItems"
                      (menuItemClick)="onMenuItemClick($event);"></chq-tabs>
        </div>
        <div class="content-body fx-1">
            <ng-container *ngIf="activeMenu === 'add-parts'">
                <app-part-add></app-part-add>
            </ng-container>
            <ng-container *ngIf="activeMenu === 'create-parts-operations'">
                <app-operation-add [operationToEdit]="operationToEdit"
                                   (cancelDialog)="cancelDialog($event)"
                                   (switchToOperationLIst)="switchToOperationList()"
                                   [objectGuid]="data.objectGuid"
                                   [domainId]="data.domainId"
                                   [estimateSource]="data?.estimateSource"
                                   [estimationMode]="data.estimationMode"
                                   [addedParts]="data.addedParts"></app-operation-add>
            </ng-container>
            <ng-container *ngIf="activeMenu === 'operation-list'">
                <app-operation-list (handleEditRow)="switchToEditOperation($event);"
                                    (cancelDialog)="cancelDialog($event)"
                                    [objectGuid]="data.objectGuid"
                                    [domainId]="data.domainId"
                                    [estimationMode]="data.estimationMode"></app-operation-list>
            </ng-container>
        </div>
    </div>
</div>
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { validateEmails } from 'src/app/modules/quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import { CoreService } from 'src/app/services/core/core.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';

@Component({
  selector: 'app-ask-for-revision-notification-mail',
  templateUrl: './ask-for-revision-notification-mail.component.html',
  styleUrls: [ './ask-for-revision-notification-mail.component.scss' ]
})
export class AskForRevisionNotificationMailComponent implements OnInit {

  public sendButton: ChqWidgetsButtonModel;

  public emailForm: any = {
    email: {
      label: 'email_address',
      name: 'email',
      placeHolder: 'email_address',
      value: '',
      type: 'text',
      validation: {
        name: 'email',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required,
            validateEmails('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'email_required'
          }

          if (error['invalidEmailNumber']) {
            return 'email_invalid_number';
          }

          if (error['invalidEmail']) {
            return 'email_valid'
          }
          return '';
        }
      },
    },
    phone: {
      label: 'mobile_number',
      name: 'phone',
      placeHolder: 'mobile_number',
      value: '',
      type: 'number',
      validation: {
        name: 'phone',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required, Validators.pattern('^[0-9]+[0-9 ,.\'-]+$') ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'required_mobile_number'
          }
          if (error['pattern']) {
            return 'valid_mobile_number'
          }
          return '';
        }
      },
    },
  };

  @ViewChild('emailRef') emailRef: ChqInputComponent;
  @ViewChild('codeRef') codeRef: ChqInputComponent;
  @ViewChild('phoneRef') phoneRef: ChqInputComponent;
  public dropdownModel: ChqWidgetsInputModel = {
    placeHolder: 'select_dot',
    label: 'code',
    name: 'code',
    value: 0,
    type: 'select',
    showImageFlag: true,
    displayValue: 'phoneCountryCode',
    fieldValue: 'phoneCountryCode',
    options: [ { label: '+90' }, { label: '+971' }, { label: '+977' } ],
    validation: {
      name: 'code',
      validationFunction: (): ValidatorFn => {
        return Validators.compose([ Validators.required ])
      },
      validationMessage: (error: ValidationErrors): string => {
        if (error['required']) {
          return 'required_code'
        }
        return '';
      }
    },
  };
  selectedCode: string = '';
  mailBody: string = '';
  mailSubject: string = '';

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<AskForRevisionNotificationMailComponent>,
    private monitorService: MonitorService,    
    private coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'AskForRevisionNotificationMailComponent', 'addenda' ]);

    if (!this.dropdownModel.selectedOption) {
      this.dropdownModel.selectedOption = {};
    }
    this.dropdownModel.selectedOption[this.dropdownModel.displayValue] = this.data[0].garageDetails.garageMobileCountryCode || '+91';
    if (!this.dropdownModel.observable) {
      this.dropdownModel.observable = this.coreService.getPhoneNumber();
    }
    this.selectedCode = this.data[0].garageDetails.garageMobileCountryCode || '+91';
    if (this.selectedCode) {
      this.dropdownModel.onObservableLoad = this.onObservableLoad.bind(this);
    }
    
    this.sendButton = {
      label: 'send',
      type: 'disabled',
      icon: 'send',
      onclick: this.send.bind(this)
    }
    this.setMailBody();
  }

  /**
   * setMailBody
   * 
   */
  setMailBody(): void {

    if(this.data[1].type === 'email'){
      this.mailSubject = this.data[1].data.subject
        .replace('{{ClaimNumber}}', this.data[0].claimNo)
        .replace('{{RepairID}}', this.data[0].garageDetails.repairId)
        .replace('{{InsuranceCompany}}', this.data[0].organizationDetail.nameOfOrganization);
      
      this.mailBody = this.data[1].data.body
        .replace('{{ContactName}}', this.data[0].garageDetails.garageName)
        .replaceAll('{{InsuranceCompany}}', this.data[0].organizationDetail.nameOfOrganization)
        .replace('{{ClientName}}', `${this.data[0].customerDetails.policyHolderFirstName} ${this.data[0].customerDetails.policyHolderLastName}`)
        .replace('{{DateofIncident}}', dayjs(this.data[0].lossDetails.lossDate).format('DD/MM/YYYY'))
        .replace('{{ClaimNumber}}', '<b>'+this.data[0].claimNo+'</b>')
        .replace('{{ClientName}}', `<b>${this.data[0].customerDetails.policyHolderFirstName} ${this.data[0].customerDetails.policyHolderLastName}</b>`)
        .replace('{{VehicleMakeAndModel}}', `<b>${this.data[0].vehicleMetaData.make} ${this.data[0].vehicleMetaData.model}</b>`)
        .replace('{{DateofLoss}}', '<b>'+dayjs(this.data[0].lossDetails.lossDate).format('DD/MM/YYYY')+'</b>')
        .replace('{{DateofInitialEstimate}}', '<b>'+dayjs(this.data[0].garageDetails.estimationDate).format('DD/MM/YYYY')+'</b>')
        .replace('{{registrationlink}}', `${window.location.origin}/repair/booking/${this.data[0].garageDetails.repairGuid}/estimate/`);
        
    } else {
      const newLine = '\n';
      this.mailBody = this.data[1].data.whatsAppBody
        .replaceAll('\\n', newLine)
        .replace('{{ClaimNumber}}', this.data[0].claimNo)
        .replace('{{RepairID}}', this.data[0].garageDetails.repairId)
        .replace('{{InsuranceCompany}}', this.data[0].organizationDetail.nameOfOrganization);
    }

    this.emailForm.email = { ...this.emailForm.email, value: this.data[0].garageDetails.garageEmail };
    this.emailForm.phone = { ...this.emailForm.phone, value: this.data[0].garageDetails.garageMobileNumber };

    if((this.data[1].type === 'email' && this.emailForm.email.value) || (this.data[1].type !== 'email' && this.emailForm.phone.value)) {
      this.sendButton = { ...this.sendButton, type: 'primary' };
    }
  }


  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('cancel', [ 'AskForRevisionNotificationMailComponent', 'addenda' ]);
    this.dialogRef.close();
  }

  /**
   * send
   * 
   */
  send(): void {
    this.monitorService.logEvent('send', [ 'AskForRevisionNotificationMailComponent', 'addenda' ]);
    const response = {
      subject: this.mailSubject,
      body: this.mailBody,
      garageName: this.data[0].garageDetails?.garageName,
      type: this.data[1].type,
      email: this.emailForm.email.value?.split(',').map(x => x.trim()),
      phoneNumber: this.emailForm.phone.value,
      countryCode: this.selectedCode
    };

    this.dialogRef.close(response);
  }

  /**
   * onFormUpdate
   * 
   */
  onFormUpdate($event): void {
    if ($event.name === 'email') {
      let toEmails = [];
      if ($event.isValid) {
        toEmails = $event.name === 'email' ? ($event.value?.split(',') || []) : $event.value;
      } else {
        toEmails = [];
      }
      if (toEmails && toEmails.length > 0) {
        this.sendButton = { ...this.sendButton, type: 'primary' };
      } else {
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }

      this.emailForm.email.value = $event.value;
    } else if ($event.name === 'code') {
      this.selectedCode = $event.value.phoneCountryCode;
      const output = $event.value;
      const validationPattern = output['regExpression'];
      this.dropdownModel = { ...this.dropdownModel, selectedOption: $event.value };
      this.phoneRef?.updateCurrentValidation(validationPattern, true);
      this.dropdownModel.value = $event.value;
    } else if ($event.name === 'phone') {
      this.emailForm.phone.value = $event.value;
    }
  }

  /**
   * on dropdown observable load
   */
  onObservableLoad(items: any): void {
    this.monitorService.logEvent('onObservableLoad', [ 'AskForRevisionNotificationMailComponent', 'addenda-quote' ]);
  
    if (!items) {
      this.dialogRef.close();
      return;
    }
    if (items && items.length > 0) {
      const selectedItem = items.filter((it: any) => {
        return it.phoneCountryCode === this.selectedCode;
      });
      if (selectedItem.length > 0) {
        const output = selectedItem[0];
        const validationPattern = output['regExpression'];
        this.dropdownModel = { ...this.dropdownModel, selectedOption: output };
        this.phoneRef?.updateCurrentValidation(validationPattern, true);
      }
    }
  }
}

import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';

const now = new Date();
const lossMaxDate: any = now.setMinutes(now.getMinutes() - 1);

export const policyEnquiryFormV2: DynamicFormBase<any>[] = [
  {
    label: 'loss_date',
    key: 'lossDateTime',
    placeholder: 'loss_date',
    required: true,
    visible: true,
    controlType: 'date',
    maxDate: lossMaxDate,
    layout: {
      width: '15%',
      fullWidth: true
    },
  },
  {
    label: 'heading',
    key: 'heading',
    placeholder: 'heading',
    required: false,
    visible: true,
    controlType: 'title',
    textPrefillValue: 'select_and_search_claim',
    layout: {
      width: '100%',
      fullWidth: false,
      alignment: 'left'
    },
  },
  {
    label: 'selectAndSearchDetails',
    key: 'selectAndSearchDetails',
    placeholder: '',
    required: true,
    controlType: 'radio',
    layout: {
      width: '100%',
      fullWidth: true
    },
    options: [ { label: 'Policy Number', value: 'policyNumber' }, { label: 'Chassis Number', value: 'chassisNumber' }, { label: 'Plate Number', value: 'plateNumber' }, { label: 'Police Report', value: 'policeReport' } ]
  },
  {
    label: '',
    key: 'policyNumber',
    placeholder: 'search_data',
    required: true,
    controlType: 'textbox',
    maxLength: 50,
    visible: false,
    visibleIf: 'selectAndSearchDetails == policyNumber',
    conditions: [
      {
        'IsActive': true,
        'ItemKey': 'Visible If',
        'ItemValue': 'policyNumber',
        'SortOrder': 0,
        'type': 'visibleIf',
        'controlKey': 'selectAndSearchDetails',
        'itemType': 'conditions',
        'operator': '==',
        'conditionalValue': 'policyNumber'
      }
    ],
    validators: [
      {
        type: 'maxlength',
        message: 'Policy number should have not more than 20 characters'
      },
      {
        type: 'pattern',
        regex: '^[a-zA-Z0-9/-]+$',
        message: 'Invalid policy number'
      }
    ],
    layout: {
      width: '100%'
    }
  },
  {
    label: '',
    key: 'chassisNumber',
    placeholder: 'search_data',
    required: true,
    controlType: 'textbox',
    maxLength: 17,
    minLength: 17,
    visible: false,
    visibleIf: 'selectAndSearchDetails == chassisNumber',
    conditions: [
      {
        'IsActive': true,
        'ItemKey': 'Visible If',
        'ItemValue': 'chassisNumber',
        'SortOrder': 0,
        'type': 'visibleIf',
        'controlKey': 'selectAndSearchDetails',
        'itemType': 'conditions',
        'operator': '==',
        'conditionalValue': 'chassisNumber'
      }
    ],
    validators: [
      {
        type: 'minlength',
        message: 'Vin should have 17 characters'
      },
      {
        type: 'maxlength',
        message: 'Vin should have not more than 17 characters'
      },
      {
        type: 'pattern',
        regex: '^[a-hA-Hj-nJ-NpPr-zR-Z0-9]+$',
        message: 'pattern_driver_name'
      }
    ],
    layout: {
      width: '100%'
    }
  },
  {
    label: '',
    key: 'plateNumber',
    placeholder: 'search_data',
    required: true,
    controlType: 'textbox',
    maxLength: 12,
    visible: false,
    visibleIf: 'selectAndSearchDetails == plateNumber',
    conditions: [
      {
        'IsActive': true,
        'ItemKey': 'Visible If',
        'ItemValue': 'plateNumber',
        'SortOrder': 0,
        'type': 'visibleIf',
        'controlKey': 'selectAndSearchDetails',
        'itemType': 'conditions',
        'operator': '==',
        'conditionalValue': 'plateNumber'
      }
    ],
    validators: [
      {
        type: 'maxlength',
        message: 'Vehicle Registration / Plate Number should have not more than 12 characters'
      },
      {
        type: 'pattern',
        regex: '^[a-zA-Z0-9/]*$',
        message: 'Only alpha-numeric characters and "/" are allowed'
      }
    ],
    layout: {
      width: '100%'
    }
  },
  {
    label: '',
    key: 'policeReport',
    placeholder: 'search_data',
    required: true,
    controlType: 'textbox',
    maxLength: 12,
    visible: false,
    visibleIf: 'selectAndSearchDetails == policeReport',
    conditions: [
      {
        'IsActive': true,
        'ItemKey': 'Visible If',
        'ItemValue': 'policeReport',
        'SortOrder': 0,
        'type': 'visibleIf',
        'controlKey': 'selectAndSearchDetails',
        'itemType': 'conditions',
        'operator': '==',
        'conditionalValue': 'policeReport'
      }
    ],
    validators: [
      {
        type: 'pattern',
        regex: '^[a-zA-Z0-9-]*$',
        message: 'Only alpha-numeric characters and "-" are allowed'
      }
    ],
    layout: {
      width: '100%'
    }
  }
];
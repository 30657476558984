<div class="main-container">
    <div class="main-heading fx-row justify-content-between">
        <div class="main-heading-text">
            <span *ngIf="this.beneficiaryId  && beneficiaryId !== '0'">{{'update_beneficiary' | translate }}</span>
            <span *ngIf="!this.beneficiaryId || this.beneficiaryId === '0'">{{'add_beneficiary' | translate }}</span>   
        </div>
        <div>
            <mat-icon [svgIcon]="'cross'"
                      (click)="close(null)">
            </mat-icon>
        </div>
    </div>
    <div class="container-wrapper fx-col">

        <div class="content">
            <dynamic-form [formControls]="controls"
                          [form]="beneficiaryFormGroup"></dynamic-form>
        </div>
        <div class="button-wrapper fx-row">

            <xa-button (click)="saveBeneficiary()"
                       type="primary"
                       class="left">
                <span> {{'save' | translate }}</span>
            </xa-button>
        </div>

    </div>
</div>
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { uploadEventResult, UploadStepModel } from 'src/app/model/chq-upload-model';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { CommentDialogComponent } from 'src/app/dialogs/comment-dialog/comment-dialog.component';
import { ChqUploadComponent } from '../chq-upload/chq-upload.component';
import { exportMapper, PhotosAction, XAFileReviewSection, XaHeaderAction } from '../xa-photo-upload-mode-selecter/xa-file-upload.model';
import { Platform } from '@angular/cdk/platform';
import { allowExtension, photosSizeLimit } from 'src/app/config/constants/app.constants';

@Component({
  selector: 'xa-file-review-section',
  templateUrl: './xa-file-review-section.component.html',
  styleUrls: [ './xa-file-review-section.component.scss' ]
})
export class XaFileReviewSectionComponent implements OnInit {
  //input attribute
  @Input() xaReviewOptions:XAFileReviewSection;
  @Input() showBack:boolean;
  @Input() consolidatedFile:UploadStepModel;

  //component variables
  public imageLoading: { [name:string]:boolean} = {};
  public isLandscape: boolean = true;
  public stepsWithAdditionalStep:UploadStepModel[];
  public files:UploadStepModel[] = [];
  public selectedStep:UploadStepModel;

  //ViewChild
  @ViewChild('xaUpload') xaUpload: ChqUploadComponent;

  //private variables
  private landscape: MediaQueryList = window.matchMedia('(orientation: landscape)');
 

  //Output events
  @Output() selection: EventEmitter<{ 'step': UploadStepModel, 'mode': string }> = new EventEmitter();
  @Output() iconClick: EventEmitter<PhotosAction> = new EventEmitter();
  @Output() triggerHeaderAction: EventEmitter<XaHeaderAction> = new EventEmitter();
  @Output() fileUpload:EventEmitter<any> = new EventEmitter();
  @Output() updateMassUpload: EventEmitter<any> = new EventEmitter();
  @Output() handleBackAction:EventEmitter<any> = new EventEmitter();

  /**
   * constructor
   * @param router
   */
  constructor(public commonService: CommonService,
    private dialog: MatDialog,
    private inspectionService:InspectionService,
    private monitorService:MonitorService,
    public platform: Platform) {
  }

  /**
   * handles image upload
   * @param event
   */
  handleImageOutput($event: uploadEventResult | uploadEventResult[]): void {

    const { multipleUpload, type, objectId, inspectionId, templateId, bulkUploadInspectionItemId, objectType, observable, observer, collectionGuid, domainId } = this.xaReviewOptions;

    if (multipleUpload) {
      const events = $event as uploadEventResult[];
      let index = 0;
      const uploadedSteps = [];
      for (const i in events) {
        const fileResult = events[i];
        const stepObj: UploadStepModel = {
          stepName: fileResult.fileName,
          uploadStatus: 'notUploaded',
          url: '',
          image: fileResult.image,
          imageFile: fileResult.file,
          index,
          sizeLimit: 5242880,
          isMassUpload: true,
          allowExtension: [ 'jpg', 'jpeg', 'png' ],
          mandatory: true,
          placeHolderUrl: '',
          isUploadSuccess: false,
          uploadInProgress: false,
          isUploadFailed: false,
          isSkipped: false,
          isSkipEnabled: true,
          templateGuid: '',
          partTemplateGuid: ''
        }
        index++;
        uploadedSteps.push(stepObj);
        const img = new Image();
        img.src = fileResult.image as string;
        img.onload = (): void => {
          const uploadObject = {
            'height': img.height,
            'width': img.width,
            'inspectionId': inspectionId,
            'templateId': bulkUploadInspectionItemId,
            'type': objectType || '',
            'isBulk': true
          }

          stepObj.uploadInProgress = true;
          stepObj.isSkipped = false;
          stepObj.isUploadFailed = false;
          stepObj.isUploadSuccess = false;
          stepObj.action = exportMapper(stepObj, this.xaReviewOptions.actions);
          observable(uploadObject, fileResult.file).subscribe(observer(true, stepObj));
        }
      }

      this.updateMassUpload.emit({
        files: uploadedSteps
      }); 
     
    } else {
      const event = $event as uploadEventResult;
      let currentStep = this.selectedStep;
      if (this.selectedStep.isAdditionalDocumentFromReviewPageOnly) {
        const currentAdditionalStep = this.files.filter((step: UploadStepModel) => {
          return step.isAdditionalDocumentFromReviewPageOnly === true && step.uploadInProgress != true;
        });
        currentStep = currentAdditionalStep[0];
      }
      currentStep['image'] = event.image;
      currentStep.imageFile = event.file;
      currentStep.contentType = event?.file?.type;
      currentStep.fileName = event.fileName;
      this.xaReviewOptions.files = [ ...this.xaReviewOptions.files ];

      if (type === 'photo') {
        const img = new Image();
        img.src = event.image as string;
        img.onload = (): void => {
          currentStep.imageHeight = img.height;
          currentStep.imageWidth = img.width;

          const uploadObject = {
            'height': img.height,
            'width': img.width,
            'inspectionId': inspectionId,
            'templateId': bulkUploadInspectionItemId,
            'inspectionitemId': currentStep.inspectionItem.id,
            'type': objectType || '',
            'isBulk': false
          }
       
          currentStep.uploadInProgress = true;
          currentStep.isSkipped = false;
          currentStep.isUploadFailed = false;
          currentStep.isUploadSuccess = false;
          this.commonService.inProgressSteps.next(1);
          observable(uploadObject, event.file).subscribe(observer(false, currentStep));
          //show review page only if all the images have been uploaded
        }

      } else {
        const { templateGuid, partTemplateGuid, uploadedGuid, uploadedPartGuid } = currentStep;
        const obj = {
          mediaCollectionId: collectionGuid,
          domainId: domainId,
          objectId: objectId,
          mediaTemplateId: templateGuid,
          mediaPartTemplateId: partTemplateGuid,
          mediaGuid: uploadedGuid,
          mediaPartGuid: uploadedPartGuid
        }
        currentStep.uploadInProgress = true;
        currentStep.isSkipped = false;
        currentStep.isUploadFailed = false;
        currentStep.isUploadSuccess = false;
        this.commonService.inProgressSteps.next(1);
        if(this.xaReviewOptions?.onFileSelection){
          this.xaReviewOptions?.onFileSelection(event.file);
        }
        observable(event.file, obj).subscribe(observer(false, currentStep));

      }

    }

  }

  /**
   * should additional be displayed
   */
  checkInAdditional(): void {
    const currentPhotos = this.files?.filter((currentStep: UploadStepModel) => {
      return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    });

    if (this.xaReviewOptions.type === 'document') {
      this.commonService.mediaTemplates = currentPhotos;
    }

    if(currentPhotos?.length === 0 && this.xaReviewOptions.multipleUpload){
      this.stepsWithAdditionalStep = [];
      return;
    }

    if (this.xaReviewOptions.isEditable && ( currentPhotos?.length != this.files?.length || (this.xaReviewOptions.multipleUpload && currentPhotos?.length < (this.xaReviewOptions.maxFileLength || 20)))) {
      const additionStep:UploadStepModel = {
        stepName: this.xaReviewOptions.type == 'photo' ? 'Additional Image' : 'Additional Documents',
        uploadStatus: 'notUploaded',
        url: '',
        image: '',
        index: -1,
        isAdditionalDocumentFromReviewPageOnly: true,
        sizeLimit: this.files[0]?.sizeLimit || photosSizeLimit,
        allowExtension: this.files[0]?.allowExtension || allowExtension,
        mandatory: false,
        isMassUpload: this.xaReviewOptions.multipleUpload,
        isUploadSuccess: false,
        uploadInProgress: false,
        isUploadFailed: false,
        isSkipped: false,
        isSkipEnabled: true,
        templateGuid: this.files[0]?.templateGuid || '',
        partTemplateGuid: this.files[0]?.partTemplateGuid || '',
        placeHolderUrl: '/assets/icons/additional-image.svg',
      }
      additionStep.action = exportMapper(additionStep, this.xaReviewOptions.actions);
      this.stepsWithAdditionalStep = currentPhotos.concat([ additionStep ]);
    } else {
      this.stepsWithAdditionalStep = currentPhotos;
    }
  }

  /**
   * get review documents
   */
  get uploadedList(): UploadStepModel[] {

    if(this.xaReviewOptions?.aiReviewSection){
      const currentPhotos = this.files?.filter((currentStep: UploadStepModel) => {
        return currentStep.analysisStatus?.toLowerCase() === 'analysed';
      });
      return currentPhotos;
    }

    const currentPhotos = this.files?.filter((currentStep: UploadStepModel) => {
      return ( currentStep.image || currentStep.url);
    });

    return currentPhotos;

  }

  /**
   * get review documents
   */
  get fileList(): UploadStepModel[] {

    const currentPhotos = this.files?.filter((currentStep: UploadStepModel) => {
      return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    });
    
    if(currentPhotos?.length === 0 && this.xaReviewOptions.multipleUpload){
      this.files = [];
    }
    
    if (!this.xaReviewOptions?.aiReviewSection) {
      const inprogressPhotos = this.files?.filter((currentStep: UploadStepModel) => {
        return currentStep.uploadInProgress === true;
      });
      this.commonService.inProgressSteps.next(inprogressPhotos?.length);
    }

    if (currentPhotos?.length != this.files?.length || (this.xaReviewOptions.multipleUpload && currentPhotos?.length < (this.xaReviewOptions.maxFileLength || 20))) {
      return this.stepsWithAdditionalStep;
    }
    return currentPhotos;

  }

  /**
   * uploaded files
   */
  get uploadedFiles(): UploadStepModel[] {
    return this.files?.filter((currentStep: UploadStepModel) => {
      return (currentStep.image || currentStep.url);
    }) || [];
  }

  /**
   * triggerMultipleUpload
   */
  triggerMultipleUpload():void{
    this.xaReviewOptions = { ...this.xaReviewOptions, multipleUpload: true };
    this.triggerFileUpload();
  }

  /**
   * trigger back action
   */
  handleBack():void{
    this.handleBackAction.emit({ back: true });
  }

  /**
   * handleIconActions
   */
  handleIconActions(action:XaHeaderAction):void{
    this.triggerHeaderAction.emit(action);
  }

  /**
   * trigger upload
   */
  triggerFileUpload(step?:UploadStepModel):void{
    if(step){
      this.selectedStep = step;
    }
    this.xaUpload.triggerUpload();
  }

  /**
   * 
   * is step valid
   */
  get isMandatoryImagesFilled(): boolean {
    if(this.consolidatedFile?.url ){
      return true;
    }
    return this.files.filter(q => (q.isUploadSuccess !== true && !q.image) && q.mandatory)?.length === 0
  }

  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.setOrientation();
    if (changes && changes['xaReviewOptions']) {
      this.files = this.xaReviewOptions.files;
      this.checkInAdditional();
      this.files.forEach((val: UploadStepModel) => {
        if (this.imageLoading[val.index + val.stepName] === undefined) {
          this.imageLoading[val.index + val.stepName] = false;
        } else if (!this.imageLoading[val.index + val.stepName]) {
          this.imageLoading[val.index + val.stepName] = true;
        }
      });
    }

    if (changes && changes['consolidatedFile']) {
      if (this.imageLoading[this.consolidatedFile?.index + this.consolidatedFile?.stepName] === undefined) {
        this.imageLoading[this.consolidatedFile?.index + this.consolidatedFile?.stepName] = false;
      } else if (!this.imageLoading[this.consolidatedFile?.index + this.consolidatedFile?.stepName]) {
        this.imageLoading[this.consolidatedFile?.index + this.consolidatedFile?.stepName] = true;
      }
    }
  }

  /**
   * dragover
   */
  @HostListener('dragover', [ '$event' ])
  dragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * dragover
   */
  @HostListener('dragleave', [ '$event' ])
  public dragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * drop
   */
  @HostListener('drop', [ '$event' ])
  public drop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.xaUpload.handleUpload(event);
  }

  /**
   * nooninit
   */
  ngOnInit(): void {
    this.setOrientation();
    this.files = this.xaReviewOptions.files;
    this.checkInAdditional();
  }

  /**
   * return if step is mandatory
   * @param step 
   */
  isStepMandatory(step): boolean {
    if(this.xaReviewOptions.type === 'document') {
      return step.mandatory && !this.consolidatedFile?.url;
    } else {
      return !step.isSkipEnabled;
    }
  }

  /**
   * return if any one step is mandatory
   * @param step 
   */
  isMandatory(): boolean {
    if(this.xaReviewOptions.aiReviewSection){
      return false;
    }
    if(this.consolidatedFile?.url){
      return false;
    }
    if(this.xaReviewOptions.type === 'document') {
      return this.fileList?.some(x => x.mandatory);
    } else {
      return this.fileList?.some(x => !x.isSkipEnabled);
    }
  }

  /**
   * edit uploaded image
   * @param uploadStepModel
   */
  edit(uploadStepModel: UploadStepModel): void {
    if (uploadStepModel.analysisStatus) {
      if (uploadStepModel.analysisStatus?.toLowerCase() === 'analysed') {
        this.selection.emit({ 'step': uploadStepModel, 'mode': 'AI' });
      }
    } 
  }

  /**
   * handle action
   */
  handleAction(action:any, step:UploadStepModel):void{
    this.iconClick.emit({ step, action, 'type': 'review' });
  }

  /**
   * setloading
   */
  setImageLoading(name: string): void {
    if (this.imageLoading[name]) {
      return;
    }
    this.imageLoading[name] = true;
  }

  /**
   * set the orientation
   */
  private setOrientation(): void {
    if (this.landscape.matches) {
      //set style landscape
      this.isLandscape = true;
    } else {
      //set style potrait
      this.isLandscape = false;
    }
  }

  /**
   * 
   * @param url 
   */
  download(document): void {
    let documentUrl = '';
    if(document.image) {
      documentUrl = document.image;
    } else if(document.url) {
      documentUrl = document.url;
    } 
    
    if(documentUrl) {
      this.commonService.download(documentUrl);
    }
  }

  /**
   * insert/update comment
   */
  showCommentDialog(uploadStepModel: UploadStepModel, inspectionId:number): void {
    let note = '';
    if (uploadStepModel.inspectionItem?.inspectionNote?.length > 0) {
      note = uploadStepModel.inspectionItem?.inspectionNote[0].text;
    }

    this.monitorService.logEvent('changePhoneNumber', [ 'CommentDialogComponent', 'addenda-repair' ]);
    this.dialog.open(CommentDialogComponent, {
      data: {
        notes: note
      },
      panelClass: 'comment-modalbox',

      autoFocus: false
    })
      .afterClosed().subscribe({
        next: (data: any) => {
          if (!data) {
            return;
          }

          if (data.delete) {
            this.commonService.showLoading();
            this.inspectionService.deleteComment(uploadStepModel.inspectionItem?.id).subscribe({
              next: () => {
                uploadStepModel.inspectionItem.inspectionNote = [];
                this.commonService.hideLoading();
                this.commonService.showInfoToast(5000, 'Comment has been deleted successfully.');
              },
              error: () => {
                this.commonService.hideLoading();
              }
            });
            return;
          }
          const body = {
            'inspectionId': inspectionId,
            'inspectionItemId': uploadStepModel.inspectionItem.id,
            'notes': data.comment
          }
          this.commonService.showLoading();
          this.inspectionService.updateComment(body).subscribe({
            next: () => {
              if (!uploadStepModel.inspectionItem.inspectionNote) {
                uploadStepModel.inspectionItem.inspectionNote = [];
              }
              const inspectionNote = uploadStepModel.inspectionItem.inspectionNote[0];
              if (!inspectionNote) {
                uploadStepModel.inspectionItem.inspectionNote.push({ 'text': data.comment });
              } else {
                uploadStepModel.inspectionItem.inspectionNote[0].text = data.comment;
              }
              
              this.commonService.hideLoading();
              this.commonService.showInfoToast(5000, 'Comment has been saved successfully.');
            },
            error: () => {
              this.commonService.hideLoading();
            }
          });
        }
      });
  }

  /**
   * open image dialog
   */
  imageDialog(state: string, url: string | ArrayBuffer, name: string, file?:UploadStepModel): void {
    if(this.xaReviewOptions.aiReviewSection){
      if (file.analysisStatus) {
        if (file.analysisStatus?.toLowerCase() === 'analysed') {
          this.selection.emit({ 'step': file, 'mode': 'edit' });
        }
      } 
     
      return;
    }
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: name,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

}



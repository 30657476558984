<div class="third-party-view-estimate-container">
    <div class="header">
        <h3>{{ data.title }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="table-container payment-list">
        <ng-container>
            <div class="table-wrapper" #paymentTableWrapper>
                <xa-table #paymentTable [config]="tableConfig" [tableData]="tableData" [cellClicked]="handleCellClick" [i18n]="i18n"></xa-table>
            </div> 
        </ng-container>     
    </div>
    <div class="footer form-data-footer" *ngIf="data.showRejectEstimate">
        <ng-container *ngIf="!data?.isTotalLossThresholdReached">
            <xa-button (click)="handleApproveEstimateThirdParty()" *chqClaimRoles="'claimRequestEstimate'">
                <span> {{'approve_estimate' | translate }}</span>
            </xa-button>
            <xa-button (click)="viewLPO()" *chqClaimRoles="'claimRequestEstimate'" [type]="'outline'" color="gray">
                <span> {{'view_lpo' | translate }}</span>
            </xa-button>
        </ng-container>
        <ng-container *ngIf="data?.isTotalLossThresholdReached && !(data?.isTotalLoss)">
            <chq-button [buttonModel]="markAsTotalLossModel" *chqClaimRoles="'claimAssignBack'"></chq-button> 
        </ng-container>
        <xa-button type="outline" color="danger" (click)="handleRejectEstimateThirdParty()" *chqClaimRoles="'claimRequestEstimate'">
            <span> {{'reject_estimate' | translate }}</span>
        </xa-button>
    </div>
</div>
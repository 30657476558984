import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { VideoPlayerComponent } from 'src/app/dialogs/video-player/video-player.component';
import { CanDeactivateGuard } from 'src/app/guards/deactivate-guard/deactivate-guard.guard';
import { ErrorModule } from 'src/app/shared/error-page/error.module';
import { WidgetsModule } from 'src/app/widgets/widgets.module';
import { DynamicFormComponent } from '../config/core/dynamic-form/dynamic-form.component';
import { DynamicFormService } from '../config/core/dynamic-form/dynamic-form.service';
import { AddBeneficiaryDialogComponent } from '../dialogs/add-beneficiary-dialog/add-beneficiary-dialog.component';
import { AddManufacturerSuppliersComponent } from '../dialogs/add-manufacturer-suppliers/add-manufacturer-suppliers.component';
import { AiCorrelationDialogComponent } from '../dialogs/ai-correlation-dialog/ai-correlation-dialog.component';
import { AiInferenceDialogComponent } from '../dialogs/ai-inference-dialog/ai-inference-dialog.component';
import { AskForRevisionNotificationMailComponent } from '../dialogs/ask-for-revision-notification-mail/ask-for-revision-notification-mail.component';
import { BeneficiaryListDialogComponent } from '../dialogs/beneficiary-list-dialog/beneficiary-list-dialog.component';
import { ChangeClaimsAssigneeComponent } from '../dialogs/change-claims-assignee/change-claims-assignee.component';
import { ClaimGarageAssignmentComponent } from '../dialogs/claim-garage-assignment/claim-garage-assignment.component';
import { ConfirmDialogBigComponent } from '../dialogs/confirm-dialog-big/confirm-dialog-big.component';
import { EditRecoveryDialogComponent } from '../dialogs/edit-recovery-dialog/edit-recovery-dialog.component';
import { EstimateDeclineComponent } from '../dialogs/estimate-decline/estimate-decline.component';
import { ExistingClaimDialogComponent } from '../dialogs/existing-claim-dialog/existing-claim-dialog.component';
import { GarageAssignmentPreferenceComponent } from '../dialogs/garage-assignment-preference/garage-assignment-preference.component';
import { GarageAutoAssignmentConfirmationComponent } from '../dialogs/garage-auto-assignment-confirmation/garage-auto-assignment-confirmation.component';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { InternalGarageEstimateDialogComponent } from '../dialogs/internal-garage-estimate-dialog/internal-garage-estimate-dialog.component';
import { InternalGarageEstimateLogComponent } from '../dialogs/internal-garage-estimate-log/internal-garage-estimate-log.component';
import { MyInventoryFilterPopupComponent } from '../dialogs/my-inventory-filter-popup/my-inventory-filter-popup.component';
import { PolicyAuthenticationComponent } from '../dialogs/policy-authentication/policy-authentication.component';
import { RegisteredGarageRejectClaimComponent } from '../dialogs/registered-garage-reject-claim/registered-garage-reject-claim.component';
import { RegisteredGarageViewEstimateComponent } from '../dialogs/registered-garage-view-estimate/registered-garage-view-estimate.component';
import { RejectClaimDialogComponent } from '../dialogs/reject-claim-dialog/reject-claim-dialog.component';
import { RepairIntimationRequestComponent } from '../dialogs/repair-intimation-request/repair-intimation-request.component';
import { RequestMissingInformationDialogComponent } from '../dialogs/request-missing-information-dialog/request-missing-information-dialog.component';
import { SelectClaimantForRequestMissingInfoComponent } from '../dialogs/select-claimant-for-request-missing-info/select-claimant-for-request-missing-info.component';
import { TermsConditionsComponent } from '../dialogs/terms-conditions/terms-conditions.component';
import { UpdateStockDialogComponent } from '../dialogs/update-stock-dialog/update-stock-dialog.component';
import { UploadRegistrationCardDialogComponent } from '../dialogs/upload-registration-card-dialog/upload-registration-card-dialog.component';
import { VideoInfoDialogComponent } from '../dialogs/video-info-dialog/video-info-dialog.component';
import { DirectiveModule } from '../helper/directive/directive.module';
import { IconsModule } from '../icons.module';
import { MaterialModule } from '../material.module';
import { ClaimEstimateComponent } from '../modules/claim/estimate/claim-estimate.component';
import { EstimateDetailsComponent } from '../modules/claim/estimate/estimate-details/estimate-details.component';
import { EstimateTableComponent } from '../modules/claim/estimate/estimate-table/estimate-table.component';
import { ExpandableComponent } from '../modules/claim/expandable/expandable.component';
import { RepairPhotosComponent } from '../modules/repair/add-customers/repair/repair-stages/repair-photos/repair-photos.component';
import { AddPhotosComponent } from './add-photos/add-photos.component';
import { ClaimAssignModalComponent } from './claim-assign-modal/claim-assign-modal.component';
import { CustomerComponent } from './customer/customer.component';
import { DamageAnalysisComponent } from './damage-analysis/damage-analysis.component';
import { ManualEstimateComponent } from './manual-estimate/manual-estimate.component';
import { NewDocumentsComponent } from './new-documents/new-documents.component';
import { NewPhotosComponent } from './new-photos/new-photos.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VideoUploaderComponent } from './video-uploader/video-uploader.component';
import { CheckPartsAvailabilityPopupComponent } from '../dialogs/check-parts-availability-popup/check-parts-availability-popup.component';
import { CheckPartsLoaderComponent } from '../dialogs/check-parts-loader/check-parts-loader.component';

@NgModule({
  declarations: [
    NewDocumentsComponent,
    NewPhotosComponent,
    AddPhotosComponent,
    DamageAnalysisComponent,
    RequestMissingInformationDialogComponent,
    RejectClaimDialogComponent,
    ClaimGarageAssignmentComponent,
    GarageAssignmentPreferenceComponent,
    GarageAutoAssignmentConfirmationComponent,
    ExistingClaimDialogComponent,
    RegisteredGarageRejectClaimComponent,
    ClaimAssignModalComponent,
    ConfirmDialogBigComponent,
    RegisteredGarageViewEstimateComponent,
    VehicleComponent,
    TermsConditionsComponent,
    ExpandableComponent,
    RepairPhotosComponent,
    VideoUploaderComponent,
    PolicyAuthenticationComponent,
    VideoPlayerComponent,
    InternalGarageEstimateDialogComponent,
    ClaimEstimateComponent,
    EstimateTableComponent,
    EstimateDetailsComponent,
    EstimateDeclineComponent,
    AskForRevisionNotificationMailComponent,
    InternalGarageEstimateLogComponent,
    AiInferenceDialogComponent,
    AiCorrelationDialogComponent,
    InfoDialogComponent,
    BeneficiaryListDialogComponent,
    AddBeneficiaryDialogComponent,
    EditRecoveryDialogComponent,
    ManualEstimateComponent,
    VideoInfoDialogComponent,
    RepairIntimationRequestComponent,
    UploadRegistrationCardDialogComponent,
    SelectClaimantForRequestMissingInfoComponent,
    AddManufacturerSuppliersComponent,
    MyInventoryFilterPopupComponent,
    ChangeClaimsAssigneeComponent,
    UpdateStockDialogComponent,
    CheckPartsAvailabilityPopupComponent,
    CheckPartsLoaderComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule,
    WidgetsModule,
    ErrorModule,
    TranslateModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    CustomerComponent,
    DynamicFormComponent,
    MatExpansionModule,
    MaterialModule
  ],
  exports: [
    NewDocumentsComponent,
    NewPhotosComponent,
    AddPhotosComponent,
    DamageAnalysisComponent,
    FormsModule,
    ReactiveFormsModule,
    RequestMissingInformationDialogComponent,
    RejectClaimDialogComponent,
    ClaimGarageAssignmentComponent,
    GarageAssignmentPreferenceComponent,
    ExistingClaimDialogComponent,
    RegisteredGarageRejectClaimComponent,
    RegisteredGarageViewEstimateComponent,
    CustomerComponent,
    VehicleComponent,
    TermsConditionsComponent,
    DynamicFormComponent,
    ExpandableComponent,
    RepairPhotosComponent,
    VideoUploaderComponent,
    VideoPlayerComponent,
    VideoInfoDialogComponent,
    InternalGarageEstimateDialogComponent,
    ClaimEstimateComponent,
    EstimateTableComponent,
    EstimateDetailsComponent,
    EstimateDeclineComponent,
    AskForRevisionNotificationMailComponent,
    InternalGarageEstimateLogComponent,
    BeneficiaryListDialogComponent,
    AddBeneficiaryDialogComponent,
    EditRecoveryDialogComponent,
    ManualEstimateComponent,
    EditRecoveryDialogComponent,
    RepairIntimationRequestComponent,
    SelectClaimantForRequestMissingInfoComponent,
    AddManufacturerSuppliersComponent,
    MyInventoryFilterPopupComponent,
    ChangeClaimsAssigneeComponent,
    UpdateStockDialogComponent,
    CheckPartsAvailabilityPopupComponent,
    CheckPartsLoaderComponent
  ],
  providers: [
    CanDeactivateGuard, DynamicFormService
  ]
})
export class SharedModule { }
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RequestMissingInformationDialogComponent } from '../request-missing-information-dialog/request-missing-information-dialog.component';

@Component({
  selector: 'app-select-claimant-for-request-missing-info',
  templateUrl: './select-claimant-for-request-missing-info.component.html',
  styleUrls: [ './select-claimant-for-request-missing-info.component.scss' ]
})
export class SelectClaimantForRequestMissingInfoComponent implements OnInit {
  options: any[] = [];
  config: XADropdownConfig = {
    name: 'singleSelect',
    label: 'select_claimant_request_missing_info',
    placeHolder: 'select_dot',
    labelKey: 'name',
    dropdownPosition: 'fixed',
    valueKey: 'id'
  }
  formGroup: FormGroup;
  selectedClaim: string = ''
  /**
     * constructor
     * @param dialogRef 
     * @param data 
     */
  constructor(public dialogRef: MatDialogRef<SelectClaimantForRequestMissingInfoComponent>,
      private monitorService: MonitorService,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  /**
     * ngOnInit
     */
  ngOnInit(): void {
    this.options = this.data.claimants.map((ele) => {
      let fullName = '';
      if(ele.personalDetails?.insurerFirstName) {
        fullName += ele.personalDetails?.insurerFirstName
      }
      if(ele.personalDetails?.insurerLastName) {
        if(fullName) fullName += ' '
        fullName += ele.personalDetails?.insurerLastName
      }
      if(ele.partyType) {
        fullName += ` (${ele.partyType}) `
      }
      return {
        name: fullName,
        id: ele?.claimGuid
      }
    })
    this.formGroup = new FormGroup({
      'claimant': new FormControl('',),
    });
    this.formGroup.get('claimant').valueChanges.subscribe((v) => {
      if (v) {

        this.selectedClaim = v;
      }
    })
  }

  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('cancel', [ 'SelectClaimantForRequestMissingInfoComponent', 'addenda-claims' ]);
    this.dialogRef.close();
  }

  /**
      Opens a dialog box for the missing information component with the specified data and panel class.
      @returns {void}
    */
  openReqMissingInfoPopup(): void {
    const selectedClaimDetails = this.data.claimants.find(ele => ele.claimGuid === this.selectedClaim);
    if(selectedClaimDetails) {
      this.dialogRef.close()
      this.dialog.open(RequestMissingInformationDialogComponent, {
        data: {
          claimDetails: {
            claimGuid: selectedClaimDetails.claimGuid,
            inspectionId: selectedClaimDetails.inspectionId
          }
        },
        height: 'auto',
        panelClass: 'operation-panel',
        autoFocus: false
      });
    }
    
  }
}

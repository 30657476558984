import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-request-missing-information-dialog',
  templateUrl: './request-missing-information-dialog.component.html',
  styleUrls: [ './request-missing-information-dialog.component.scss' ]
})
export class RequestMissingInformationDialogComponent implements OnDestroy, OnInit {
  public sendButton: ChqWidgetsButtonModel;
  public cancelButton: ChqWidgetsButtonModel;

  subscriptions: Subscription[] = [];

  isUpdate: boolean = false;

  public notesModel: ChqWidgetsInputModel = {
    placeHolder: 'notes',
    label: 'notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 100
  };

  public missingInfoModel: ChqWidgetsInputModel = {
    placeHolder: 'select_dot',
    label: 'Select_missing_information_category',
    name: 'Select_missing_information_category',
    value: 0,
    type: 'select',
    displayValue: 'name',
    idField: 'missingInformation',
    fieldValue: 'id',
    options: 
    [ 
      //{ id: 'Customer Details', name: 'Customer Details' },
      { id: 'Loss Details', name: 'Loss Details' },
      { id: 'Documents', name: 'Documents' },
      { id: 'Vehicle Images', name: 'Vehicle Images' }, ],
    errorWhenEmpty: true
  };

  public missingInfoFieldsModel: ChqWidgetsInputModel = {
    placeHolder: 'select_dot',
    label: 'select_missing_information',
    name: 'select_missing_information',
    value: 0,
    disabled: true,
    type: 'select',
    isMultiSelect: true,
    displayValue: 'name',
    idField: 'missingInformation',
    fieldValue: 'id',
    selectedOption: [],
    options: [],
    errorWhenEmpty: true
  };


  nameDict = { //customerDetails
    'PolicyNo': 'POLICY NUMBER',
    'ExpiryDate': 'POLICY EXPIRY DATE',
    'PolicyHolderFirstName': 'INSURER FIRST NAME',
    'PolicyHolderLastName': 'INSURER LAST NAME',
    'InsuranceOrganizationName': 'INSURANCE ORG NAME',
    'PolicyHolderAddress1': 'POLICY HOLDER ADDRESS (PERMANENT)',
    'PolicyHolderAddress2': 'POLICY HOLDER ADDRESS (CURRENT)',
    'PolicyMobileCountryCode': 'CODE',
    'PolicyHolderMobileNumber': 'MOBILE NUMBER',
    'PolicyHolderEmail': 'EMAIL ADDRESS',
    'Category': 'POLICY CATEGORY',
    'InsuranceProductName': 'INSURANCE PRODUCT NAME',
    'TypeOfInformation': 'TYPE OF INFORMATION',
    'DateOfBirth': '',
    'PremiumAmount': '',
    'ClaimIntimationDate': 'CLAIM INTIMATION DATE',
    //loss detail
    'LossDate': 'LOSS DATE TIME',
    'ClaimCreationDate': 'CLAIM CREATION DATE',
    'RegistrationDate': 'REGISTRATION DATE',
    'State': 'STATE',
    'City': 'CITY',
    'IntimationType': 'INTIMATION TYPE',
    'SubIntimationType': 'SUB INTIMATION TYPE',
    'DamageType': 'DAMAGE TYPE',
    'NatureOfLoss': 'NATURE OF LOSS',
    'LossSeverity': 'LOSS SEVERITY',
    'LossImpactZone': '',
    'LossLocation': '',
    'LossLocationLatitude': '',
    'LossLocationLongitude': '',
    'CauseOfLoss': 'CAUSE OF LOSS',
    'SalvageAmount': 'SALVAGE AMOUNT',
    'IsTotalLoss': 'TOTAL LOSS',
    'RepairType': 'REPAIR TYPE',
    'LossDescription': 'LOSS DESCRIPTION',
    'AdditionalLossDetails': 'ADDITIONAL LOSS DETAILS',
  };

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDetectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RequestMissingInformationDialogComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private claimService: ClaimsService,
    private route: ActivatedRoute,
  ) {
  }

  /**
   * handle close
   */
  continue(): void {
    this.monitorService.logEvent('continue', [ 'RequestMissingInformationDialogComponent', 'addenda-repair' ]);
    this.send();
  }

  /**
   * on form update
   * @param output o
   */
  onFormUpdate(output: IControlValue): void {
    if (output.name === 'Select_missing_information_category') {
      this.missingInfoModel = { ...this.missingInfoModel, selectedOption: output.value, };
      this.getMissingFields(output.value.name);

    } else if (output.name === 'select_missing_information') {
      this.missingInfoFieldsModel = { ...this.missingInfoFieldsModel, selectedOption: output.value, };

      if (output.value.length > 0) {
        this.sendButton = { ...this.sendButton, type: 'primary' };
      } else {
        this.sendButton = { ...this.sendButton, type: 'disabled' };
      }

    } else {
      this.notesModel.value = output.value;
    }

  }

  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('cancel', [ 'RequestMissingInformationDialogComponent', 'addenda-repair' ]);
    this.dialogRef.close({ 'delete': true });
  }

  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }

  claimDetails = null;

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'RequestMissingInformationDialogComponent', 'addenda-repair' ]);
    this.sendButton = {
      label: 'send',
      type: 'disabled',
      onclick: this.continue.bind(this),
      icon: 'check'
    }

    this.cancelButton = {
      label: 'cancel',
      type: 'outline',
      onclick: this.cancel.bind(this)
    }

    this.notesModel = { ...this.notesModel, value: this.data?.notes, disabled: this.data?.hideUpdate }
    this.missingInfoModel = { ...this.missingInfoModel, value: this.data?.missingInformation, disabled: this.data?.hideUpdate };
    this.missingInfoFieldsModel = { ...this.missingInfoFieldsModel, value: this.data?.missingInformation, disabled: this.data?.hideUpdate };
    this.claimDetails = this.data.claimDetails
    // const claimDetails$ = this.commonService.claimDetails.subscribe({
    //   next: (response) => {
    //     this.claimDetails = response;
    //   }
    // })

    // this.subscriptions.push(claimDetails$);
  }

  /**
   * removeMissingDataField
   * @param data 
   * @param index 
   */
  removeMissingDataField(data: any, index: number): void {
    this.missingInfoFieldsModel.selectedOption.splice(index, 1)
    this.missingInfoFieldsModel = { ...this.missingInfoFieldsModel, selectedOption: this.missingInfoFieldsModel.selectedOption };
  }

  customerFields = [];
  lossDetailsFields = [];
  documentFields = [];
  imagesFields = [];

  /**
   * 
   */
  getMissingFields(type: string): void {
    this.commonService.showLoading();
    switch (type) {
    case 'Customer Details':
      if (this.customerFields.length === 0) {
        this.getCustomMissingFields('CustomerDetailsDTO');
      } else {
        this.updateMissingInfoDropdownOption(this.customerFields, 'Customer Details');
      }
      break;
    case 'Loss Details':
      if (this.lossDetailsFields.length === 0) {
        this.getCustomMissingFields('LossDetailsDTO');
      } else {
        this.updateMissingInfoDropdownOption(this.lossDetailsFields, 'Loss Details');
      }
      break;
    case 'Documents':
      if (this.documentFields.length === 0) {
        this.getDocumentMissingFields();
      } else {
        this.updateMissingInfoDropdownOption(this.documentFields, 'Documents');
      }
      break;
    case 'Vehicle Images':
      if (this.imagesFields.length === 0) {
        this.getMediaMissingFields(this.claimDetails.inspectionId);
      } else {
        this.updateMissingInfoDropdownOption(this.imagesFields, 'Photos');
      }
      break;
    }
  }

  /**
   * 
   * @param type 
   */
  getCustomMissingFields(type: string): void {
    const subs1 = this.claimService.getMissingCustomFields(type).subscribe({
      next: (response) => {
        if (type === 'CustomerDetailsDTO') {
          this.customerFields = response.data;
          this.updateMissingInfoDropdownOption(this.customerFields, 'Customer Details');
        } else {
          this.lossDetailsFields = response.data;
          this.updateMissingInfoDropdownOption(this.lossDetailsFields, 'Loss Details');
        }
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })

    this.subscriptions.push(subs1);
  }


  /**
  * 
  * 
  */
  getDocumentMissingFields(): void {
    const subs2 = this.claimService.getMissingDocumentFields().subscribe({
      next: (response) => {
        this.documentFields = response.data;
        this.updateMissingInfoDropdownOption(this.documentFields, 'Documents');
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })

    this.subscriptions.push(subs2);
  }

  /**
   * 
   * @param InspectionId 
   */
  getMediaMissingFields(inspectionId: any): void {
    const subs3 = this.claimService.getMissingMediaFields(inspectionId).subscribe({
      next: (response) => {
        this.imagesFields = response.data;
        this.updateMissingInfoDropdownOption(this.imagesFields, 'Photos');
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });

    this.subscriptions.push(subs3);
  }

  /**
   * 
   * @param optionArray 
   * @param type 
   */
  updateMissingInfoDropdownOption(optionArray: any[], type: string): void {
    this.missingInfoFieldsModel = {
      ...this.missingInfoFieldsModel,
      disabled: false,
      options: optionArray.map((x:any) => { 
        return { 
          id: x.name, 
          name: this.nameDict[x.name] ? this.nameDict[x.name] : x.description?.toUpperCase(), 
          type: type 
        } 
      })
    }
    this.commonService.hideLoading();
  }
  /**
   * 
   * 
   */
  send(): void {
    const fieldCategoryObj = new Object();
    this.missingInfoFieldsModel.selectedOption.forEach((item) => {
      if (fieldCategoryObj[item.type]) {
        fieldCategoryObj[item.type].push({
          name: item.id,
          description: item.name
        });
      } else {
        fieldCategoryObj[item.type] = [ 
          {
            name: item.id,
            description: item.name
          }
        ];
      }
    });
    const missingFieldsDTO = Object.keys(fieldCategoryObj).map((x) => { 
      return { 
        'key': x, 'values': fieldCategoryObj[x] 
      } 
    });

    const param = {
      'ClaimGuid': this.claimDetails.claimGuid,
      'Notes': this.notesModel.value,
      'MissingFieldsDTO': missingFieldsDTO
    };
    this.commonService.showLoading();
    this.claimService.sendMissingInfo(param).subscribe({
      next: (response) => {
        this.commonService.hideLoading();
        this.commonService.showInfoToast(1000, response.message);
        this.dialogRef.close({ 'comment': this.notesModel.value });
      }
    })
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}

import { AfterContentInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;
@Component({
  selector: 'xa-label',
  templateUrl: './xa-label.component.html',
  styleUrls: [ './xa-label.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaLabel),
      multi: true
    },
  ]
})
export class XaLabel implements AfterContentInit {
  @Input() color:string = '';
  @Input() size:string = '14';
  @Input() weight:string = '500';
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() name: string = ''
  @Input() hint: string = '';
  @Input() hintIcon:string = '';

  @Input() readonly?: boolean;
  @Input() disabled?: boolean;

  @Input() blockMaxLength?: boolean;
  value = '';
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-label-${nextUniqueId++}`

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
    if(!this.value && this._inputElement) this._inputElement.nativeElement.value = '';
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    const newValue = this._inputElement.nativeElement.value;
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

}

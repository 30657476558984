<div class="form-field-text">
  <label *ngIf="label"
         class="form-label pointer-events"
         for="inputId">
    {{label | translate}}
    <span class="required"
          *ngIf="required && label">*</span>
    <mat-icon *ngIf="hint"
              class="hint"
              [svgIcon]="hintIcon || 'hint'"
              [matTooltip]="hint">

    </mat-icon>
  </label>
  <div class="text-field">
    <input class="form-value"
           autocomplete="off"
           #input
           [id]="inputId"
           [name]="nameId"
           placeholder="{{placeholder || label | translate}}"
           [readonly]="readonly"
           [disabled]="disabled"
           [required]="required"
           [value]="value"
           [max]="max"
           [attr.maxlength]="blockMaxLength ? maxLength : null"
           [attr.minlength]="minLength"
           (keyup)="_onInputInteraction();onTouched()" />
    <ng-content></ng-content>
  </div>
</div>
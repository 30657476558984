/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';


let nextUniqueId = 0;
@Component({
  selector: 'xa-text-group',
  templateUrl: './xa-text-group.component.html',
  styleUrls: [ './xa-text-group.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaTextGroup),
      multi: true
    },
  ]
})
export class XaTextGroup implements ControlValueAccessor, AfterContentInit, OnInit {
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() label?: string;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() maxLength?: string | number;
  @Input() minLength?: string | number;
  @Input() max?:string | number;
  @Input() blockMaxLength?: boolean;
  @Input() hint?: string;
  @Input() inputLength:number;
  value = '';
  valueArr:any[];
  @ViewChildren('input') _inputElement: QueryList<ElementRef<HTMLInputElement>>;
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-text-group-${nextUniqueId++}`

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * ng onit
   */
  ngOnInit():void{
    if(this.inputLength > 0){
      this.valueArr = [];
      for (let i = 0; i < this.inputLength; i++) {
        this.valueArr.push('');
      }
    }
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
    if(!this.value && this._inputElement) {
      this._inputElement.forEach(input => input.nativeElement.value = '');
    }else if(this.value){
      this.valueArr = value.split('-');
      this._inputElement?.forEach((input, index) => {
        if(index < this.valueArr.length){
          input.nativeElement.value = this.valueArr[index];
        }
      });
    }
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction($event, index): void {
    if($event.code === 'Space'){
      $event.preventDefault();
      return;
    }
    const activeValue = $event.srcElement.value;
    if(!activeValue.trim()){
      return;
    }
    if(this.value){
      const valueArr = this.value.split('-');
      valueArr[index] = activeValue;
      this.value = valueArr.join('-')
    }else{
      const valueArr = cloneDeep(this.valueArr);
      valueArr[index] = activeValue;
      this.value = valueArr.join('-');
    }
 
    
    this._controlValueAccessorChangeFn(this.value);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

}

<div class="my-inventory-filters-popup-container">
    <div class="header">
        <h3>{{ 'filter' | translate}}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>

    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="dropdown-container row">
            <div class="col-6 height-170">
                <xa-select [config]="groupConfig" [options]="groupOptions" [(ngModel)]="selectedGroup"></xa-select>
            </div>
            <div class="col-6 height-170">
                <xa-select [config]="supplierConfig" [options]="supplierOptions" (searchOutput)="searchDropdown($event, 'supplier')" [(ngModel)]="selectedSupplier"></xa-select>
            </div>
            <div class="col-6 height-170">
                <xa-select [config]="manufacturerConfig" [options]="manufacturerOptions" (searchOutput)="searchDropdown($event, 'manufacturer')" [(ngModel)]="selectedManufacturer"></xa-select>
            </div>
            <div class="col-6 height-170">
                <xa-select [config]="conditionConfig" [options]="conditionOptions" [(ngModel)]="selectedCondition"></xa-select>
            </div>
            <div class="col-6">
                <xa-select [config]="stockStatusConfig" [options]="stockStatusOptions" [(ngModel)]="selectedStockStatus"></xa-select>
            </div>
            <div class="col-6">
                <xa-select [config]="statusConfig" [options]="statusOptions" [(ngModel)]="selectedStatus"></xa-select>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-5 pr-0">
                        <xa-select [config]="operatorConfig" [options]="operatorOptions" [(ngModel)]="selectedOperator"></xa-select>
                    </div>
                    <div class="col-7">
                        <xa-numeric placeholder="enter_value" label="stock_level" [(ngModel)]="selectedStockLevel"></xa-numeric>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="btn-container">
        <xa-button class="right" type="outline" color="gray" (click)="cancel();">
            <mat-icon [svgIcon]="'cross'" ></mat-icon>
            {{'cancel'|translate}}
        </xa-button>
        <xa-button class="right" type="outline" color="gray" (click)="resetFilters();">
            {{'reset_all'|translate}}
        </xa-button>
        <xa-button class="right" (click)="handleApplyFilter()">
            {{'apply'|translate}}
        </xa-button>
    </div>
</div>
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Localization } from 'src/app/services/common/common.service';

@Component({
  selector: 'xa-expandable-table',
  templateUrl: './xa-expandable-table.component.html',
  styleUrls: [ './xa-expandable-table.component.scss' ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class XaExpandableTable implements OnChanges {
  @Input() i18n?: Localization; 
  @Input() dataSource?: any;  
  @Input() columnDef?: string[] | undefined; 
  @Input() columnsToDisplay?: any | undefined; 
  @Input() cellClicked?: any;
  
  expandedElement: any;
  colorPalette: string[] = [
    '#BF9393', '#E3A4A4', '#FFC6C6', '#EFDCD4', '#C9C4C2', 
    '#FF9AB1', '#F0C1CD', '#FFDFE7', '#B0AEDA', '#9C9AAD', 
    '#CCCAFA', '#8DA1D3', '#ADC9F2', '#A4DEFF', '#A6EEF7',
    '#B1D5B2', '#A7CBCB', '#C5E1D3', '#E3F1E4', '#F1F0E3',
    '#B9B99D', '#CED1B2', '#DFE2AB', '#E2D3AB', '#C3B388',
    '#C8AF7F', '#EBC478', '#FFE5C6', '#E5B986', '#FFB055'
  ];

  public columnTotalCount = {};
  public columnTotalEstimateAmount = {};
  public columnTotalLabourAmount = {};

  /**
   * on change event
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.map(x => x.color = this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)]);

    this.columnDef.forEach((column) => {
      this.columnTotalCount[column] = this.getTotalCount(column);
      this.columnTotalEstimateAmount[column] = this.getTotalEstimate(column);
      this.columnTotalLabourAmount[column] = this.getTotalLabour(column);
    });
  }

  /**
   * get total count
   * @param column 
   */
  getTotalCount(column) : number {
    const count = this.dataSource.filter( t => t[column]);
    return count.length > 0 ? count.map( t => t[column]).map( x => x.count).reduce((acc, value) => Number(acc) + Number(value) + 0) : 0;
  }

  /**
   * get total estimate amount
   * @param column 
   */
  getTotalEstimate(column) : number {
    const estimate = this.dataSource.filter( t => t[column]);
    return estimate.length > 0 ? estimate.map( t => t[column]).map( x => x.estimate).reduce((acc, value) => Number(acc) + Number(value) + 0) : 0;
  }

  /**
   * get total labour amount
   * @param column 
   */
  getTotalLabour(column) : number {
    const labour = this.dataSource.filter( t => t[column]);
    return labour.length > 0 ? labour.map( t => t[column]).map( x => x.labour).reduce((acc, value) => Number(acc) + Number(value) + 0) : 0;
  }

  /**
   * @function handleCellClicked
   * @param row
   * @param column
   */
  handleCellClicked(row: any, col: any, event: any = null): void {
    event.stopPropagation()
    if (this.cellClicked) {
      this.cellClicked(row, col, event);
    }
  }

}

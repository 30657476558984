<div class="container">
    <xa-photo-upload-mode-selector (mode)="handleMode($event);"
                                   [hidden]="hideSelector"
                                   *ngIf="xaDocument?.displaySelector"
                                   [uploadModeSelector]="xaDocument?.uploadSelector">
    </xa-photo-upload-mode-selector>
    <xa-file-review-section #reviewSection
                            *ngIf="!dataLoading && xaDocument.displayReviewSection"
                            (fileUpload)="handleUpload($event)"
                            [hidden]="hideReviewSection"
                            [xaReviewOptions]="xaDocument.fileReviewSection"
                            [consolidatedFile]="consolidatedDocumentStep"
                            (handleBackAction)="handleBack();"
                            (triggerHeaderAction)="handleIconActions($event);"
                            (iconClick)="handleAction($event);">
        <xa-button (click)="handleFooterActions(step);"
                   [hidden]="(step.isUploadAction && xaDocument.fileReviewSection?.files?.length > 0) || (!step.isUploadAction && xaDocument.fileReviewSection?.files?.length === 0)"
                   *ngFor="let step of xaDocument?.fileReviewSection?.footerActions">
            <mat-icon [svgIcon]="step.icon"
                      aria-hidden="false"
                      *ngIf="step.icon"
                      aria-label="unchecked icon"></mat-icon>
            <span>{{step.label | translate}}</span>
        </xa-button>
    </xa-file-review-section>
    <div class="additional-doc-wrapper"
         *ngIf="displayConsolidatedAnalysis">
        <xa-file-review-section #reviewSection
                                [xaReviewOptions]="consolidatedFileReviewSection"
                                *ngIf="this.consolidatedFileReviewSection?.files?.length > 0"
                                [showBack]="true"
                                (iconClick)="handleAction($event);"
                                (handleBackAction)="backToReview();"></xa-file-review-section>
    </div>
    <div *ngIf="dataLoading"
         class="spinner-loader">
        <mat-spinner [strokeWidth]="5"
                     [diameter]="50"
                     class="inner-loading"></mat-spinner>
    </div>
</div>
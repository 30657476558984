<div class="form-field-checkbox">
    <div class="checkbox-field">
        <input class="form-value"
               type="checkbox"
               #input
               [id]="inputId"
               [name]="nameId"
               [readonly]="readonly"
               [disabled]="disabled"
               [required]="required"
               [checked]="value"
               [indeterminate]="indeterminate"
               (change)="_onInputInteraction($event)">
        <label class="form-label pointer-events"
               [ngClass]="disabled ? 'disabled-checkbox': ''"
               [for]="inputId">
            <span *ngIf="position === 'start'"
                  class="checkbox-mark"></span>
            <span>
                <ng-content></ng-content>
            </span>
            <span *ngIf="position === 'end'"
                  class="checkbox-mark"></span>
        </label>
    </div>
</div>
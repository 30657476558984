import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'otp-mobile-dialog',
  templateUrl: './otp-mobile-dialog.component.html',
  styleUrls: [ './otp-mobile-dialog.component.scss' ]
})
export class OTPMobileDialogComponent implements OnInit {
  
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:NgOtpInputComponent;
  correct_otp: number = 0;
  otpLoading: boolean = false;
  otpText: string;
  showResendOTP: boolean = false;
  resend: boolean = false;
  seconds=30;
  resendCounter=3;
  errorMessages = '';
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeOTP: EventEmitter<any> = new EventEmitter();
  @Input() data:any
  public editMobileNumber: ChqWidgetsButtonModel = {
    label: 'verify',
    icon: 'refresh',
    type: 'disabled'
  }

  public otpConfig: any = {
    length: 4,
    allowNumbersOnly: true,
    disableAutoFocus: true,
    inputClass: ''
  };

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor( private coreService: CoreService,
    private monitorService:MonitorService,
     public commonService: CommonService,
     private translate: TranslateService
  ) { }
  
  /**
   * onInit
   */
  ngOnInit(): void {
    this.requestOTP(this.data?.mobileNumber);
    this.editMobileNumber.onclick = this.verifyOtp.bind(this);
  }
  
  /**
   * revert to phonenumber
   */
  changeNumber(): void{
    this.monitorService.logEvent('changeNumber', [ 'OTPMobileDialogComponent', 'addenda-quote' ]);
    this.closeOTP.emit();
  }

  /**
   * request otp
   */
  requestOTP(phoneNumber: string): void {
    this.monitorService.logEvent('requestOTP', [ 'OTPMobileDialogComponent', 'addenda-quote', {
      phoneNumber
    } ]);

    this.commonService.showLoading();
    this.coreService.generateOTP(phoneNumber).subscribe({
      next: (res) => {
        this.showResendOTP = true;
        this.updateSeconds();
        setTimeout(() => {
          this.resend = true;
        }, 30000);
        this.commonService.hideLoading();
      }, error: (error) => {
        //this.dialogRef.close(false);
        this.closeOTP.emit(error);
      }
    });
  }

  /**
   * update second timer
   */
  updateSeconds():void{
    setTimeout(()=>{
      this.seconds--;
      if(this.seconds >0)
        this.updateSeconds();
    }, 1000)
  }
  
  /**
   * 
   * resend otp
   */
  resend_sms():void {
    if(this.resendCounter ===0){
      return;
    }
    this.monitorService.logEvent('resend_sms', [ 'OTPMobileDialogComponent', 'addenda-quote' ]);
    const header = this.translate.instant('resend_otp_code');
    const message = this.translate.instant('resend_otp_code', { mobileNumber: this.data.mobileNumber });
    const dialogRef = this.commonService.openConfirmationOkDialog(header, message)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService.showLoading();
        this.coreService.generateOTP(this.data.mobileNumber).subscribe((res: any) => {
          this.resend = false;
          setTimeout(() => {
            this.resendCounter = this.resendCounter -1;
            this.resend = true;
          }, 30000);
          this.seconds = 30;
          this.errorMessages = '';
          this.otpConfig.inputClass = '';
          try{
            this.ngOtpInput.setValue('');
          }catch(e){
            //error
          }
          const inputs = document.getElementsByClassName('otp-input');
          if(inputs) {
            for(let i = 0; i < inputs.length; i++) {
              (inputs[i] as HTMLInputElement).disabled = false;
            }
          }
          this.updateSeconds();
          this.commonService.hideLoading();
        })
      }
    });
    
  }



  /**
   * verify otp
   * @param otp 
   */
  verifyOtp():void {
   
    const body = { 'otpCode': this.otpText, 'value': this.data.mobileNumber }
    this.commonService.showLoading();
    this.monitorService.logEvent('verifyOtp', [ 'OTPMobileDialogComponent', 'addenda-quote', {
      body
    } ]);
    const errorRetries = this.translate.instant('otp_entered_many_times');
    this.otpLoading = true;
    this.coreService.verifyOTP(body).subscribe({
      next: (res: any) => {
        this.commonService.hideLoading();
         
        if (res) {        
          (document.activeElement as HTMLElement).blur();
          this.correct_otp = 2;
          this.otpConfig.inputClass = 'border-success';
          this.editMobileNumber.type = 'disabled';
          this.commonService.reloadUser.next(true);
          this.closeEvent.emit();
        } else {
          this.errorMessages = res.errors[0];
          this.correct_otp = 3;
          this.otpConfig.inputClass = 'border-danger';
        }
        this.otpLoading = false;
      }, error: (error) => {
        this.commonService.hideLoading();
        this.otpLoading = false;
        try{
          this.monitorService.logException(error, SeverityLevel.Error);
          if(error && error.error && error.error.msg.indexOf(errorRetries) != -1){
            this.errorMessages = this.translate.instant('tried_to_enter_too_many_times');
            setTimeout(() => {
              this.closeOTP.emit();
            }, 3000);
          
          }else{
            this.errorMessages = error.error.msg;
            //this.errorMessages = 'OTP code incorrect or expired, please resend OTP code.';
            if(!this.errorMessages){
              this.errorMessages = this.translate.instant('sorry_something_went_wrong_please_try_again');
            }
          }
        } catch(e){
          this.errorMessages = this.translate.instant('sorry_something_went_wrong_please_try_again');
        }finally{
          this.correct_otp = 3;
          this.otpConfig.inputClass = 'border-danger';
        }
      }
    })
  }

  /**
   * otp change
   */
  onOtpChange(event: string):void {
    if(this.otpLoading) return;
    if(event.length == 4) {
      this.correct_otp = 1;
      this.otpText = event;
      this.editMobileNumber.type = 'primary';
    }else{
      this.correct_otp = 0;
      this.otpText = '';
      this.editMobileNumber.type = 'disabled';
    }
  }

  /**
   * close dialog
   */
  close(): void {
    this.closeEvent.emit();
  }

}

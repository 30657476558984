import { Component } from '@angular/core';

@Component({
  selector: 'app-check-parts-loader',
  templateUrl: './check-parts-loader.component.html',
  styleUrls: [ './check-parts-loader.component.scss' ]
})
export class CheckPartsLoaderComponent {

}

<div class="beneficiary-list-container fx-col">
    <div class="search-container fx-row">
        <div class="radio-container">
            <xa-radio-group [(ngModel)]="selectedType"
                            [name]="'selectBeneficiary'"
                            class="radio-group-display-flex" (ngModelChange)="onRadioChange($event)">
                          <xa-radio [value]="item.id" *ngFor="let item of radioButtonOptions">{{ item.name }}</xa-radio>                
            </xa-radio-group>
        </div>

        <div class="search-button-container fx-row">
            <xa-search 
                       [(ngModel)]="searchBeneficiary"
                       [placeholder]="searchPlaceholder"
                       [maxLength]="20"
                       [minLength]="1"
                       (searchIconEmitter)="onSearchSelected($event)">
            </xa-search>
            <xa-button (click)="openBeneficiaryDialog('0')" *ngIf="!this.hideAddButton">
                <span>{{ 'add_beneficiary' | translate }}</span>
            </xa-button>
            <div class="close-button-wrapper">
                <mat-icon [svgIcon]="'cross'" [color]="'white'"
                          (click)="close(null)">
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="table-container">
        <div class="table-container-wrapper"
        *ngIf="beneficiaryTableData.length > 0">
       <div class="table-wrapper">
           <xa-table [config]="tableConfig"
                     [tableData]="beneficiaryTableData"
                     [cellClicked]="handleCellClick"
                     [i18n]="i18n"></xa-table>
       </div>
       <div class="pagination-wrapper row justify-end"
            *ngIf="beneficiaryTableData?.length > 0 && (pagination.count > pagination.pageSize)">
           <div class="col-6">
               <xa-paginator #paginator
                             (paginationChange)="handlePaginationChange($event)"
                             [pagination]="pagination">
               </xa-paginator>
           </div>

       </div>
   </div>
   <div class="table-no-data"
        *ngIf="beneficiaryTableData.length === 0">
       <div class="no-data-content">
           <img src="assets/img/no-data.png"
                alt="">
           <span>{{'no_result_found' | translate}}</span>
           <span>{{'could_not_find_any_data' | translate}}.</span>
       </div>
   </div>
    </div>
</div>
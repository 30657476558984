/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, Component, ElementRef, EventEmitter, INJECTOR, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Observer, catchError, forkJoin, of } from 'rxjs';
import { AllowedDocumentType, allowExtension, minimumSizeLimit, photosSizeLimit } from 'src/app/config/constants/app.constants';
import { CommentDialogComponent } from 'src/app/dialogs/comment-dialog/comment-dialog.component';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

let nextUniqueId = 0;

@Component({
  selector: 'xa-file-input',
  templateUrl: './xa-file-input.component.html',
  styleUrls: [ './xa-file-input.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaFileInput),
      multi: true
    },
  ]
})
export class XaFileInput implements ControlValueAccessor, AfterContentInit {
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() label?: string;
  @Input() name?: string;
  @Input() maxLength:number;
  @Input() formGroup:FormGroup;
  @Input() fileExtensions:string = allowExtension.join(',');
  @Input() multiple:boolean;
  @Input() maxSizeLimit:number = photosSizeLimit;
  @Input() minSizeLimit:number = minimumSizeLimit;
  @Input() placeholder?: string;
  @Input() showInput:boolean;
  @Input() wrapperStyle:{
    height:string,
    width:string
  };
  @Input() formControlName:string;

  @Input() wrapperClass:string;

  @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileDeleted = new EventEmitter<any>();
  @Output() nodeModified = new EventEmitter<any>();

  internalControl: FormControl = new FormControl('');
  allowedFileExtensions = AllowedDocumentType;

  value: any;
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-text-${nextUniqueId++}`

  public uploadedFiles: { image: string | ArrayBuffer, fileName: string, file: File, id?:number, comment?:string }[] = [];
  public placeHolderUrl:string = 'assets/icons/additional-image.svg';

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * constructor
   */
  constructor(private readonly commonService:CommonService,
    private readonly dialog:MatDialog,
    private readonly monitorService:MonitorService
  ) { }

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
     * insert/update comment
     */
  showCommentDialog(note:string, id:number): void {

    this.monitorService.logEvent('showCommentDialog', [ 'CommentDialogComponent', 'addenda-repair' ]);
    this.dialog.open(CommentDialogComponent, {
      data: {
        notes: note
      },
      panelClass: 'comment-modalbox',
  
      autoFocus: false
    })
      .afterClosed().subscribe({
        next: (data: any) => {
          if (!data) {
            return;
          }

          if(data.delete){
            this.nodeModified.emit({ id, comment: data.comment, delete: true });
            return;
          }

          this.nodeModified.emit({ id, comment: data.comment });
        }
      });
  }

  /**
   * get image extensions
   */
  get imageExtensions():string[]{
    const extensions = this.fileExtensions?.split(',');
    const fileTypes = extensions?.map((ext)=>{
      return this.allowedFileExtensions.find(x=>x.includes(ext));
    });
    return fileTypes;
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * checks file extension
   * @param filename
   * @returns boolean
   */
  public getFileExtension(filename: string): string | undefined {
    return filename.split('.').pop();
  }
  
  /**
   * remove selected file
   */
  removeFile(index):void{
    this.commonService.openDeleteDialog('image').afterClosed().subscribe((data) => {
      if(data){
        if(!data){
          return;
        }
        this.fileDeleted.emit(this.uploadedFiles[index]);
        this.uploadedFiles.splice(index, 1);
        const files = this.uploadedFiles.map((up)=>{
          return up.file;
        });
        const ob:any = {};
        
        ob[this.formControlName] = files; 
        this.formGroup?.patchValue(ob);
      }
    });
    
  }

  /**
   * trigger upload
   */
  triggerUpload(): void {
    setTimeout(() => {
      this._inputElement.nativeElement.click();
    });

  }


  /**
   * checks file size
   * @param blob
   * @returns boolean
   */
  checkFileSize(img: Blob): boolean {
    if (img.size > this.maxSizeLimit || img.size < this.minSizeLimit ) {
      const message = 'file_size_5mb_error_message';
      this.commonService.showToast(0, message);
      return false;
    }
    return true;
  }

  /**
   * checkx filename
   * @param filename
   * @returns
   */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      const fileExtension = this.fileExtensions;
      return fileExtension.includes(extension?.toLowerCase());
    }
    return false;

  }

  /**
   * GET FILE NAMES
   */
  get fileNames():string{
    if(this.uploadedFiles?.length === 0){
      return '';
    }
    return this.uploadedFiles?.map((file)=>{return file.fileName})?.join(',');
  }

  /**
   * file upload observable
   */
  fileReaderObservable(file: any): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any): void => {
        observer.next({ image: reader.result, fileName: file.name, file: file });
        observer.complete();
      }
    });
    return sequence;
  }

  /**
   * handle file upload
   * @param event
   */
  handleUpload(event: any): void {
    const currentFile:File[] = event.target.files || event.srcElement.files || event.dataTransfer.files;

    if (currentFile !== null && currentFile.length > 0) {
      if (this.multiple) {
        if (currentFile.length > this.maxLength) {
          return;
        }
        const observableList = {};
        let invalidFiles = 0;
        let invalidSize = 0;
        for (let i = 0; i < currentFile.length; i++) {
          if (!this.isValidImageFile(currentFile[i].name)) {
            invalidFiles++;
            continue;
          }

          if (!this.checkFileSize(currentFile[i])) {
            invalidSize++;
            continue;
          }
          observableList[i] = this.fileReaderObservable(currentFile[i]);
        }
        const error:any = {};
        if (invalidFiles > 0) {
          const message = 'invalid_file_type';
          this.commonService.showToast(0, message);
          error.fileSize = true;
        }

        if (invalidFiles > 0) {
          error.fileFormat = true; 
        }
        const invalidKeys = Object.keys(error);
        if(invalidKeys.length > 0){
          this.internalControl.setErrors(error);
          return;
        }
        
        const ob:any = {};
        ob[this.formControlName] = currentFile; 
        this.formGroup?.patchValue(ob);

        const keys = Object.keys(observableList);
        if(keys.length ===0){
          return;
        }
        const result = forkJoin(observableList).pipe(
          catchError(error => of(error))
        )
        result.subscribe((response: any) => {
          if(!this.uploadedFiles){
            this.uploadedFiles = [];
          }
          if (response) {
            for(const key in keys){
              this.uploadedFiles = [ ...this.uploadedFiles, response[key] ];
            }
          }
        })

      } else {
        const error:any = {};
        
        if (!this.isValidImageFile(currentFile[0].name)) {
          error.fileFormat = true;
        }

        if (!this.checkFileSize(currentFile[0])) {
          error.fileSize = true;
        }

        if ( error.fileFormat > 0) {
          const message = 'invalid_file_type';
          this.commonService.showToast(0, message);
          error.fileSize = true;
        }
        
        const invalidKeys = Object.keys(error);
        if(invalidKeys.length > 0){
          this.internalControl.setErrors(error);
          return;
        }
        const reader = new FileReader();
        const ob:any = {};
        ob[this.formControlName] = currentFile[0]; 
        this.formGroup?.patchValue(ob);
        this.fileUploaded.emit(currentFile[0]);
        reader.readAsDataURL(currentFile[0]);
        reader.onload = (_event: any): void => {
          if(!this.uploadedFiles){
            this.uploadedFiles = [];
          }
          this.uploadedFiles = [ ...this.uploadedFiles, { image: reader.result, fileName: currentFile[0].name, file: currentFile[0] } ];
        }
        reader.onerror = (_event:any):void =>{
        }
      }

    } else {
      return;
    }
  }

  /**
     * open image dialog
     */
  imageDialog(state: string, url: string | ArrayBuffer, name: string): void {
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: name,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
    this.internalControl.valueChanges.subscribe(fn);
  }
  

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    const newValue = this._inputElement.nativeElement.value;
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get genrated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

}

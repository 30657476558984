import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-registration-card-dialog',
  templateUrl: './upload-registration-card-dialog.component.html',
  styleUrls: [ './upload-registration-card-dialog.component.scss' ]
})
export class UploadRegistrationCardDialogComponent {

  /**
   * constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   public dialogRef: MatDialogRef<UploadRegistrationCardDialogComponent>) {

  }
 
  /**
   * close the dialog 
   */
  close(obj: any): void {
    this.dialogRef.close(obj);
  }
}

<div class="toast-notification" (click)="dismiss()">
    <div class="toast-icon">
        <img src="assets/img/push-icon.png" style="max-height: 38px"/>
    </div>
    <div class="toast-container">
        <div class="toast-header">
            <div class="toast-title">
                {{ 'addenda' | translate }}
            </div>
            <div class="toast-time">
                {{ 'now' | translate }}
            </div>
        </div>
        <div class="toast-message">
            {{message}}
        </div>
    </div>
</div>
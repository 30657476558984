<div class="form-field-text">
    <label *ngIf="label"
           class="form-label pointer-events"
           for="inputId">
        {{label | translate}}
        <span class="required"
              *ngIf="required && label">*</span>
        <mat-icon *ngIf="hint"
                  [svgIcon]="hintIcon || 'hint'"
                  class="hint"
                  [matTooltip]="hint">
        </mat-icon>
    </label>

    <div class="label-field">
        <label #input
               [ngStyle]="{'font-size': size +'px','font-weight': weight, 'color': color}">
            {{ value }}</label>
    </div>
</div>
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { CameraOverlayComponent } from '../camera-overlay/camera-overlay.component';
import { CommonService } from 'src/app/services/common/common.service';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import {
  Platform
} from '@angular/cdk/platform';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { TranslateService } from '@ngx-translate/core';
import { ProgressCheckComponent } from '../progress-check/progress-check.component';
import { isEqual } from 'lodash';

@Component({
  selector: 'xa-camera',
  templateUrl: './camera.component.html',
  styleUrls: [ './camera.component.scss' ]
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('overlayElement') overlayElement: CameraOverlayComponent;
  @ViewChild('progress') progress:ProgressCheckComponent;
  @Input() autoStart:boolean = false;
  @Input() outlineUrl:string = '';
  @Input() procedureStep:string = '';
  @Input() displaySkip:boolean = false;
  @Input() currentStep:string='';
  @Input() skipText:string = '';
  @Input() steps:UploadStepModel[];
  @Input() forceOrientation:boolean = false;
  @Input() showTiltText:boolean = false;
  @ViewChild('div') div:HTMLDivElement;
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() skipped = new EventEmitter<any>();
  @Output() initialized = new EventEmitter<any>();
  
  public image:string;
  public currentImageIndex = 0;
  public processing:boolean = false;
  public showCamera:boolean = false;
  private location:any;
  platform = inject(Platform);


  /**
   * constructor
   * @param cameraService 
   */
  constructor(private cameraService: CameraService, private commonService:CommonService,
    private configurationService:ConfigurationService, private translate:TranslateService
  ) {}


  /**
   * ng oninit
   */
  ngOnInit(): void {
    if(this.autoStart){
      this.startCamera();
    }
  }

  /**
   * current text
   */
  get currentText():string{
    if(!this.steps || this.steps?.length === 0){
      return '';
    }
    return `<strong>${this.translate.instant('capture_side')?.replace('{{side}}', this.currentStep)}: </strong> ${this.translate.instant('capture_click')?.replace('{{side}}', this.currentStep)}`
  }

  /**
   * get viewwidgh
   */
  get viewWidth():string{
    if(window.innerWidth > window.innerHeight){
      return '';
    }
    return `-${window.innerWidth/2 + (this.platform.IOS?85:110)}px`
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(changes:SimpleChanges):void{
    if(changes && changes['steps'] && changes['steps']?.currentValue){
      if(this.progress?.track && this.progress?.track.length > 0){
        const currentObj = this.stepNames;
        if(!isEqual(this.progress?.track, currentObj)){
          this.progress.track = [ ...currentObj ];
        }
      }
    }
  }

  /**
   * steps name
   */
  get stepNames():any[]{
    if(!this.steps || this.steps?.length === 0){
      return [];
    }
    return this.steps?.map((x)=> {
      return {
        title: x.stepName,
        isCompleted: x.isUploadSuccess || x.url || x.image
      }
    });
  }

  /**
   * get orientation
   */
  get getOrientationLock():boolean{
    //|| this.configurationService.getCameraFlags()?.Camera_Orientation?.toLowerCase() === 'landscape'
    return this.forceOrientation ;
  }

  /**
   * start camera
   * 
   */
  async startCamera():Promise<any>{
  
    this.initializeGeoLocation();
    await this.cameraService.initCamera(this.videoElement.nativeElement, this.platform.ANDROID || this.platform.IOS).catch((e) => {
      this.commonService.showToast(0, `Error initializing camera: ${e.message}`);
      this.closeCamera();
    });
    if(window.innerHeight > window.innerWidth){
      this.showTiltText = true;
    }
    this.showCamera = true;
  }

  /**
   * close camera
   */
  closeCamera():void{
    this.image = null;
    this.showCamera = false;
    this.cameraService.stopCamera();
  }
  
  /**
   * fill image
   * 
   */
  fillImage():void{
    this.overlayElement?.fillImage();
  }

  /**
   * ngAfterviewIint
   */
  ngAfterViewInit():void{
    this.initialized.emit();
  }

  /**
   * Capture a frame from the video and overlay the vehicle outline on it.
   * Then convert it to a base64 encoded string and log it to the console.
   * Finally, read the image using OpenCV.js and delete the image once done.
   */
  captureImage():void {
    this.processing = true;
    setTimeout(()=>{
      const video = this.videoElement.nativeElement;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      // Capture video frame    
      if(window.innerHeight > window.innerWidth){
        canvas.height = video.videoWidth;
        canvas.width = video.videoHeight;
        ctx.translate(canvas.width/2, canvas.height/2);
        ctx.rotate((270 * Math.PI) / 180);
        ctx.drawImage(video, -video.videoWidth/2, -video.videoHeight/2);

      }else{
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      }
  
      // Convert to image
      const image = canvas.toDataURL('image/jpeg', 1);
      this.image = image;
      if(this.steps?.length === 0){
        this.cameraService.stopCamera();
        this.showCamera = false;
      }else{
        this.emitUpload(true);
      }
      
      this.processing = false;
      this.commonService.hideLoading();
      
    });
    
  }

  /**
   * initialize geolocation
   */
  initializeGeoLocation():void{
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = position.coords;
      });
    }
  }
  
  /**
   * emit upload
   */
  emitUpload(nextStep?:boolean):void{
    const file = this.cameraService.insertExifData(this.image, this.location, this.videoElement.nativeElement.videoHeight, this.videoElement.nativeElement.videoWidth);
    const fileObj = new File([ file ], 'capture_image.jpeg', { type: 'image/jpeg' });
    this.imageUploaded.emit({ target: { files: [ fileObj ], step: this.steps[this.currentImageIndex] } });
    this.image = null;
    if(nextStep){
      this.currentImageIndex++;
    }else{
      this.showCamera = false;
    }
  }

  /***
   * update skip
   */
  updateSkip():void{
    this.skipped.emit();
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    this.cameraService.stopCamera();
  }
}

<div class="progress-track-wrapper-camera">
    <div class="progress-camera-items">
        <!-- History items  -->
        <div *ngFor="let item of track"
             class="progress-camera-tracker-block">
            <div class="camera-progress-track-ui">
                <div class="progress-tracker-date">
                    <img *ngIf="item.isCompleted"
                         alt="completed"
                         src="assets/img/tick_icon.png" />
                    <xa-radio *ngIf="!item.isCompleted"
                              class="progress-xa-tracker-radio-status"></xa-radio>

                </div>
                <div class="progress-tracker-block-container">
                    <div class="vertical-line">
                        <span [ngClass]="{ 'bg-green':item.isCompleted}"></span>
                    </div>
                </div>
            </div>
            <span class="title-span"
                  [style.left]="item.title?.length > 12? '-20px': ''">{{item.title}}</span>
        </div>
    </div>
</div>
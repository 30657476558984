<div class="dialog-container">
  <div mat-dialog-content
       class="dialog-body">
    <div class="title">{{ 'sent_insurer_header' | translate }}</div>
    <div class="sub-title">{{ 'sent_insurer_sub_header' | translate }}</div>
    <div class="input-wrapper">
      <xa-select [(ngModel)]="selectedPhotos"
                 [config]="photoSelectBox"
                 [options]="photoList"
                 [allOption]="photoSelectBox.allOption">
      </xa-select>

      <xa-select [(ngModel)]="selectedDocuments"
                 [config]="documentSelectBox"
                 [options]="documentList"
                 [allOption]="documentSelectBox.allOption">
      </xa-select>
    </div>
    <!-- <div class="attachment-container">
      <div class="attachment-list">
        <div *ngFor="let photo of selectedPhotos;let i = index"
             class="attachment-item">
          <img [src]="photoBlobObject[photo]"
               [alt]="photoNameObject[photo]"
               height="50px" />
          <span class="file-name">{{ photoNameObject[photo] }}</span>
          <a (click)="removeAttachment(photo, 'photo')">
            <mat-icon class='red-cross'
                      [svgIcon]="'cross'"></mat-icon>
          </a>
        </div>
      </div>
      <div class="attachment-list">
        <div *ngFor="let document of selectedDocuments;let i = index"
             class="attachment-item">
          <img [src]="documentBlobObject[document]"
               [alt]="documentNameObject[document]"
               height="50px" />
          <span class="file-name">{{ documentNameObject[document] }}</span>
          <a (click)="removeAttachment(document, 'document')">
            <mat-icon class='red-cross'
                      [svgIcon]="'cross'"></mat-icon>
          </a>
        </div>
      </div>
    </div> -->
    <div class="confirm">{{ 'want_to_continue' | translate }}</div>
  </div>
  <div class="btn-container">
    <a (click)="closeDialog()">
      <xa-button type="outline"
                 color="dark"
                 size="wide">{{ 'cancel' | translate }}</xa-button>
    </a>
    <a (click)="sendToInsurer()">
      <xa-button size="wide">{{ 'yes' | translate }}</xa-button>
    </a>
  </div>
</div>
<div class="message-container">
  <div class="header">
      <h3>{{'notes' | translate}}</h3>
      <div class="close-icon">
          <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
      </div>
  </div>
  <div class="content">
      <label>{{'notes' | translate | uppercase}}</label>
      <div class='notes'>
       <p>{{data.notes}}</p>
      </div>
  </div>
</div>

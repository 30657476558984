<div class="case-submit-success-message-container fx-col">
    <div class="header">
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-col fx-1">
        <mat-icon [svgIcon]="'case-submit-success'"></mat-icon>
        <h4>{{ 'thank_you' | translate }}</h4>
        <p> {{ 'case_info_updated' | translate:{ quoteId: data.quoteId } }}</p>
    </div>
</div>

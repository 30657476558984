import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { FNOLService } from 'src/app/services/fnol/fnol.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-change-claims-assignee',
  templateUrl: './change-claims-assignee.component.html',
  styleUrls: [ './change-claims-assignee.component.scss' ]
})
export class ChangeClaimsAssigneeComponent implements OnInit {
  formGroup: FormGroup;
  selectedAssignee: string = '';
  list: any = []
  /**
       * constructor
       * @param dialogRef 
       * @param data 
       */
  constructor(public dialogRef: MatDialogRef<ChangeClaimsAssigneeComponent>,
        private monitorService: MonitorService,
        private readonly commonService: CommonService,
        public dialog: MatDialog,
        private fnolService: FNOLService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  /**
       * ngOnInit
       */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'assignee': new FormControl(this.data.assignedId || ''),
    });
    if(this.data?.reviewers?.length > 0) {
      this.list = this.data.reviewers
    }
    this.formGroup.get('assignee').valueChanges.subscribe((v) => {
      if (v) {
        this.selectedAssignee = v;
      }
    });
    if(this.data.assignedId) {
      const selected = this.list.find(ele => ele.value === this.data.assignedId)
      if(selected) this.selectedAssignee = selected?.value
    }
  }
  /**
   * handle close
   */
  cancel(): void {
    this.monitorService.logEvent('cancel', [ 'SelectClaimantForRequestMissingInfoComponent', 'addenda-claims' ]);
    this.dialogRef.close();
  }

  /**
    * 
    * @param event 
    */
  assigneeChange = (): void => {
    if (!this.selectedAssignee) {
      return;
    }
    const assigneeObj = this.list.find(ele => ele.value === this.selectedAssignee)
    const AssigneeObject = [ { 'claimId': this.data.claimId, 'assigneeId': assigneeObj.value, 'assigneeName': assigneeObj.label } ]
    this.commonService.showLoading();
    this.fnolService.setAssignee(AssigneeObject).subscribe({
      next: (res) => {
        this.commonService.hideLoading();
        this.commonService.showInfoToast(1000, res?.message);
        this.dialogRef.close({
          'assigneeId': assigneeObj.value, 'assigneeName': assigneeObj.label
        })
      }, error: () => {
        this.commonService.hideLoading();
      }
    });
  }
}

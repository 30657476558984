<div class="recovery-edit-container">
    <div class="header">
        <h3>{{ this.data.claimantName }}</h3>
        <div class="close-div">
            <label for="">
                <h3>{{'beneficiary' | translate}}</h3>
                : {{this.recoveryData?.BeneficiaryName}}
            </label>
            <div class="close-icon"
                 (click)="cancel();">
                <mat-icon [svgIcon]="'cross'"></mat-icon>
            </div>
        </div>

    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="main-container"
             *ngIf="!isEdit">
            <div class="row">
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'beneficiary_name'|translate}}</label>
                    <label class="input-label"
                           for="">{{this.recoveryData?.BeneficiaryName}}</label>
                </div>
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'date'|translate}}</label>
                    <label class="input-label"
                           for="">{{ this.recoveryData?.RecoveryDate | date: 'dd/MM/yyyy'}}</label>
                </div>
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'recovery_amount'|translate}}</label>
                    <label class="input-label"
                           for="">{{this.recoveryData?.Amount}}</label>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'recovery_type' | translate}}</label>
                    <label class="input-label"
                           for="">{{this.recoveryData?.RecoveryType}}</label>
                </div>
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'claimant_name' | translate}}</label>
                    <label class="input-label"
                           for="">{{this.data.claimantName}}</label>
                </div>
                <div class="col-4 field-wrapper">
                    <label class="custom-label"
                           for="">{{'status' | translate}}</label>
                    <label class="input-label"
                           for="">{{this.recoveryData?.RecoveryStatus}}</label>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-12 field-wrapper">
                    <label class="custom-label"
                           for="">{{'notes' | translate}}</label>
                    <label class="input-label"
                           for="">{{this.recoveryData?.Remarks}}</label>
                </div>
            </div>
            <div class="documents pt-3">
                <div class="document-wrapper">
                    <div class="document">
                        <label class="custom-label"
                               for="">{{'document' | translate}}</label>
                        <span class="doc"
                              (click)="downloadMediaPart(doc);"
                              *ngFor="let doc of recoveryData?.DocumentDetails; let i = index;">
                            {{doc.Name}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content edit-ctrls" *ngIf="isEdit">
            <dynamic-form [formControls]="controls"
                          class="add-recovery-form"
                          [form]="formGroup"></dynamic-form>
            <xa-inline-document-uploader *ngIf="this.data.claimId" 
                          [objectId]="this.data.claimId"
                          [domainId]="this.data.domainId"
                          [existingDocumentConfig]="existingDocumentConfig"
                          [existingDocs]="recoveryData?.DocumentDetails"
                          (removeMediaPart)="this.handleFileRemove($event)"
                          #documentUpload
                     ></xa-inline-document-uploader>
        </div>
    </div>
    <div class="btn-container">
        <xa-button class="right"
                   *ngIf="!isEdit"
                   (click)="isEdit = true;">
            {{'edit'|translate}}
        </xa-button>
        <xa-button class="right"
                   *ngIf="isEdit"
                   (click)="saveRecovery();">
            {{'save'|translate}}
        </xa-button>
    </div>
</div>

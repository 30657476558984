<div *ngIf="!showNoAccess">
    <chq-images-uploader #imageUploader
                         *ngIf="imageStatusSteps && imageStatusSteps.length > 0"
                         [fileUploaderService]="currentObservable"
                         (cardSelection)="setObservable($event)"
                         [filteredSteps]="filteredSteps"
                         [footerOptions]="footer"
                         [quoteAssessmentStatus]="quoteAssessmentData?.caseStatus || ''"
                         [imageStatusSteps]="imageStatusSteps"
                         [header]="documentHeader"
                         [inspection]="'document'"
                         (stepSkipped)="stepSkipped()"
                         [sendRequest]="sendRequest"
                         [submitSection]="submitSection"
                         (completeUploadProcess)="completeUploadProcess($event)"></chq-images-uploader>
    <div class="wrapper"
         *ngIf="noDocumentData">
        <div class="no-document-div">
            <img alt="no-document"
                 src="assets/icons/no-document.svg">
            <label for=""
                   class="no-vehicle-content">
                {{ (!fromRepairView ? 'no_content' : 'bulk_spi_disabled') | translate}}
            </label>

        </div>
        <div class="empty-wrapper"
             *ngIf="!fromRepairView">
            <div class="step-container"></div>
            <div class="step-container"></div>
            <div class="step-container"></div>
            <div class="step-container"></div>
        </div>
    </div>

    <div *ngIf="noDocumentData"
         class="footer-full-wrapper">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img alt="module-logo"
                     src="assets/img/{{ + (documentHeader?.isFnolModule || documentHeader?.isQuoteAssessmentModule) ? 'addenda.png': (documentHeader?.isRepairModule && !noDocumentData ?'addenda-repair.png': fromRepairView ? 'addenda-claims.png' :'addenda-quote.png')}}" />
            </div>
        </div>
    </div>
</div>
<no-access-error *ngIf="showNoAccess"></no-access-error>
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: [ './alert-dialog.component.scss' ]
})
export class AlertDialogComponent {

  titleContainsHtml: boolean = false;
  messageContainsHtml: boolean = false;
  htmlTags = [ '<span>', '<b>', '<div>', '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<a>', '<p>', '<br />', '<mat-icon>', '</a>', '<em>' ];
  public direction: any;

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.direction = this.document.body.getAttribute('dir');
    for (const key in this.htmlTags) {
      if (!this.titleContainsHtml && data.title.indexOf(this.htmlTags[key]) !== -1) {
        this.titleContainsHtml = true;
      }

      if (!this.messageContainsHtml && data.message.indexOf(this.htmlTags[key]) !== -1) {
        this.messageContainsHtml = true;
      }
    }
  }

  /**
   * close dialog
   */
  close(): void {
    if(this.data?.afterClose !== undefined){
      this.dialogRef.close(this.data?.afterClose);
    }else{
      this.dialogRef.close(true);
    }
    this.monitorService.logEvent('close', [ 'AlertDialogComponent', 'addenda-quote' ]);
  }

}

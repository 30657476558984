/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterContentInit, Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;
@Component({
  selector: 'xa-textarea',
  templateUrl: './xa-textarea.component.html',
  styleUrls: [ './xa-textarea.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaTextarea),
      multi: true
    },
  ]
})
export class XaTextarea implements ControlValueAccessor, AfterContentInit {
  @Input() readonly?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() label?: string;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() canResize = true;
  @Input() rows = 5;
  @Input() cols = 40;
  @Input() maxLength = 524288;
  value: any;
  @ViewChild('textarea') _textAreaElement: ElementRef<HTMLTextAreaElement>;
  _isInitialized: boolean = false;

  _uniqueId: string = `xa-text-${nextUniqueId++}`

  _controlValueAccessorChangeFn: (value: any) => void = () => { };
  onTouched: () => any = () => { };

  /**
   * after content init
   */
  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    const newValue = this._textAreaElement.nativeElement.value;
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get genrated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

  /**
   * 
   * @param event 
   */
  onPaste(): void {
    setTimeout(() => {
      this._onInputInteraction();
      this.onTouched()
    }, 0);
  }

}

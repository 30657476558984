import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-garage-assignment-preference',
  templateUrl: './garage-assignment-preference.component.html',
  styleUrls: [ './garage-assignment-preference.component.scss' ]
})
export class GarageAssignmentPreferenceComponent {
  public yesButton: ChqWidgetsButtonModel = {
    label: 'yes',
    type: 'primary',
    onclick: this.handleYes.bind(this)
  }

  public noButton: ChqWidgetsButtonModel = {
    label: 'cancel',
    type: 'outline',
    onclick: this.handleCancel.bind(this)
  }


  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GarageAssignmentPreferenceComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private claimsService : ClaimsService,
  ) {}

  /**
   * handle clicked on No
   */
  handleCancel(): void {
    this.monitorService.logEvent('handleCancel', [ 'GarageAssignmentPreferenceComponent', 'addenda-claim' ]);
    this.close();
  }

  /**
   * handle clicked on Yes
   */
  handleYes(): void {
    this.monitorService.logEvent('handleYes', [ 'GarageAssignmentPreferenceComponent', 'addenda-claim' ]);
    this.commonService.showLoading();
    this.claimsService.saveGaragePreference(this.data).subscribe({
      next: (response) => {
        this.dialogRef.close(response.message);
        this.commonService.hideLoading()
      },
      error: (err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    });
  }
  /**
   * close
   */
  close(): void {
    this.monitorService.logEvent('close', [ 'GarageAssignmentPreferenceComponent', 'addenda-claim' ]);
    this.dialogRef.close();
  }
}

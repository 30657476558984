<div class="repair-estimate-v2">
  <div class="estimate-main-container">

    <section *ngIf="outerSelection === '' && false"
             class="step-1">
      <div class="step-1-a">
        <svg style="margin-left: 20px"
             width="292"
             viewBox="0 0 292 69"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M291.061 10.6469C291.646 11.2327 291.646 12.1825 291.061 12.7682L281.515 22.3142C280.929 22.9 279.979 22.9 279.393 22.3142C278.808 21.7284 278.807 20.7786 279.393 20.1929L287.879 11.7076L279.393 3.22228C278.808 2.63651 278.808 1.68676 279.393 1.10097C279.979 0.515181 280.929 0.515186 281.515 1.10098L291.061 10.6469ZM217 11.7075L217 10.2075L217 10.2075L217 11.7075ZM59.193 10.2073L217 10.2075L217 13.2075L59.1929 13.2073L59.193 10.2073ZM217 10.2075L290 10.2076L290 13.2076L217 13.2075L217 10.2075ZM0.499962 68.9001C0.499976 36.4849 26.7777 10.2072 59.193 10.2073L59.1929 13.2073C28.4346 13.2072 3.49997 38.1418 3.49997 68.9002L0.499962 68.9001Z"
                fill="#1A2233"
                fill-opacity="0.1" />
        </svg>
        <span class="title">{{'step_one' | translate}}</span>
        <span class="content">
          <span>A.</span> {{'start_with_selecting_damage_area' | translate}}
        </span>
        <span class="content"
              style="margin-top: 20px">
          {{'toggle_between_inner_outer_view' | translate}}
        </span>
      </div>

      <div class="step-1-b">
        <span class="content"
              style="margin-top: 100px">
          <span>B.</span> {{'filter_out_parts_msg' | translate}}
        </span>
        <svg style="margin-left: 20px"
             width="228"
             viewBox="0 0 228 57"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M227.062 46.0596C227.647 45.4731 227.646 44.5234 227.06 43.9383L217.502 34.4036C216.916 33.8185 215.966 33.8196 215.381 34.4061C214.796 34.9926 214.797 35.9424 215.384 36.5274L223.879 45.0027L215.404 53.498C214.819 54.0845 214.82 55.0342 215.406 55.6193C215.993 56.2044 216.942 56.2033 217.527 55.6168L227.062 46.0596ZM169.274 45.0672L169.276 46.5672L169.276 46.5672L169.274 45.0672ZM46.5891 46.712L169.276 46.5672L169.273 43.5672L46.5856 43.712L46.5891 46.712ZM169.276 46.5672L226.002 46.5002L225.998 43.5002L169.273 43.5672L169.276 46.5672ZM0.757559 0.880408C0.72767 26.2049 21.2647 46.7419 46.5891 46.712L46.5856 43.712C22.9208 43.7399 3.72961 24.5488 3.75757 0.883945L0.757559 0.880408Z"
                fill="#1A2233"
                fill-opacity="0.1" />
        </svg>
      </div>
    </section>
    <div *ngIf="!isExpanded"
         class="car-chart">
      <div class="chart-wrapper">
        <svg width="337"
             height="402"
             viewBox="0 0 337 402"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             class="wheel-icon"
             (click)="wheelClicked($event)">
          <path d="M63.3482 59.5786C49.6051 78.1503 38.7035 100.198 31.2658 124.463C23.8281 148.728 20 174.736 20 201C20 227.264 23.8281 253.272 31.2658 277.537C38.7035 301.802 49.6051 323.85 63.3482 342.421L168 201L63.3482 59.5786Z"
                stroke="#1B03A3"
                stroke-opacity="0.1"
                stroke-width="0.874984" />
          <path d="M272.652 59.5786C286.395 78.1503 297.296 100.198 304.734 124.463C312.172 148.728 316 174.736 316 201C316 227.264 312.172 253.272 304.734 277.537C297.296 301.802 286.395 323.85 272.652 342.421L168 201L272.652 59.5786Z"
                stroke="#1B03A3"
                stroke-opacity="0.1"
                stroke-width="0.874984" />
          <path d="M63.3482 342.421C91.1036 379.929 128.748 401 168 401C207.252 401 244.896 379.929 272.652 342.421L168 201L63.3482 342.421Z"
                stroke="#1B03A3"
                stroke-opacity="0.1"
                stroke-width="0.874984" />
          <path d="M63.3482 59.5786C91.1036 22.0714 128.748 0.999999 168 1C207.252 1 244.896 22.0714 272.652 59.5786L168 201L63.3482 59.5786Z"
                stroke="#1B03A3"
                stroke-opacity="0.1"
                stroke-width="0.874984" />

          <path attr.fill-opacity="{{outerSelection === 'left-center-outer' ? '1' : '0'}}"
                id="left-center-outer"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M31.2658 124.463C38.7035 100.198 49.6051 78.1503 63.3482 59.5786L168 201L97.2893 105.541C88.0035 118.076 80.6375 132.959 75.6121 149.338C70.5866 165.717 68 183.272 68 201C68 218.728 70.5866 236.283 75.612 252.662C80.6375 269.041 88.0035 283.924 97.2893 296.459L168 201L63.3482 342.421C49.6051 323.85 38.7035 301.802 31.2658 277.537C23.8281 253.272 20 227.264 20 201C20 174.736 23.8281 148.728 31.2658 124.463Z"
                fill="#A49ADA" />
          <path attr.fill-opacity="{{ innerSelection['Left'] ? '1' : '0' }}"
                id="left-inner"
                d="M97.2893 105.541C88.0035 118.076 80.6375 132.959 75.6121 149.338C70.5866 165.717 68 183.272 68 201C68 218.728 70.5866 236.283 75.612 252.662C80.6375 269.041 88.0035 283.924 97.2893 296.459L168 201L97.2893 105.541Z"
                fill="#FF842B" />
          <path attr.fill-opacity="{{outerSelection === 'right-center-outer' ? '1' : '0'}}"
                id="right-center-outer"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M304.734 124.463C297.296 100.198 286.395 78.1503 272.652 59.5786L168 201L272.652 342.421C286.395 323.85 297.296 301.802 304.734 277.537C312.172 253.272 316 227.264 316 201C316 174.736 312.172 148.728 304.734 124.463ZM168 201L238.711 105.541C247.997 118.076 255.362 132.959 260.388 149.338C265.413 165.717 268 183.272 268 201C268 218.728 265.413 236.283 260.388 252.662C255.362 269.041 247.997 283.924 238.711 296.459L168 201Z"
                fill="#A49ADA" />
          <path attr.fill-opacity="{{ innerSelection['Right'] ? '1' : '0' }}"
                id="right-inner"
                d="M238.711 105.541C247.997 118.076 255.362 132.959 260.388 149.338C265.413 165.717 268 183.272 268 201C268 218.728 265.413 236.283 260.388 252.662C255.362 269.041 247.997 283.924 238.711 296.459L168 201L238.711 105.541Z"
                fill="#FF842B" />
          <path attr.fill-opacity="{{outerSelection === 'rear-outer' ? '1' : '0'}}"
                id="rear-outer"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M168 401C128.748 401 91.1036 379.929 63.3482 342.421L97.3248 296.507C116.075 321.795 141.495 336 168 336C194.505 336 219.925 321.795 238.675 296.507L272.652 342.421C244.896 379.929 207.252 401 168 401ZM168 201L97.3248 296.507C97.3129 296.491 97.3011 296.475 97.2893 296.459L168 201ZM168 201L238.675 296.507C238.687 296.491 238.699 296.475 238.711 296.459L168 201Z"
                fill="#A49ADA" />
          <path attr.fill-opacity="{{ innerSelection['Rear'] ? '1' : '0' }}"
                id="back-inner"
                d="M97.2893 296.459C116.043 321.777 141.478 336 168 336C194.522 336 219.957 321.777 238.711 296.459L168 201L97.2893 296.459Z"
                fill="#FF842B" />
          <path attr.fill-opacity="{{outerSelection === 'front-outer' ? '1' : '0'}}"
                id="front-outer"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M168 1C128.748 0.999999 91.1036 22.0714 63.3482 59.5786L97.3249 105.493C97.313 105.509 97.3011 105.525 97.2893 105.541L168 201L238.711 105.541C238.699 105.525 238.687 105.509 238.675 105.493L272.652 59.5786C244.896 22.0714 207.252 1 168 1ZM238.675 105.493C219.925 80.2052 194.505 66 168 66C141.495 66 116.075 80.2052 97.3249 105.493L168 201L238.675 105.493Z"
                fill="#A49ADA" />
          <path attr.fill-opacity="{{ innerSelection['Front'] ? '1' : '0' }}"
                id="front-inner"
                d="M97.2893 105.541C116.043 80.2232 141.478 66 168 66C194.522 66 219.957 80.2232 238.711 105.541L168 201L97.2893 105.541Z"
                fill="#FF842B" />

          <text [class.color-w]="outerSelection === 'front-outer'"
                id="front-outer-text"
                fill="#1A2233"
                fill-opacity="0.4"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                font-size="8.74984"
                font-weight="900"
                letter-spacing="0em"
                text-anchor="end"
                direction="rtl">
            <tspan x="133.07"
                   y="36.6364">{{'front' | uppercase | translate}} {{ carView }}</tspan>
          </text>
          <text [class.color-w]="outerSelection === 'rear-outer'"
                id="rear-outer-text"
                fill="#1A2233"
                fill-opacity="0.4"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                font-size="8.74984"
                font-weight="900"
                letter-spacing="0em"
                text-anchor="end"
                direction="rtl">
            <tspan x="141.413"
                   y="371.932">{{'rear' | uppercase | translate}} {{ carView }}</tspan>
          </text>
          <text id="right-center-outer-text"
                fill="#1A2233"
                fill-opacity="0.4"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                font-size="8.74984"
                font-weight="900"
                letter-spacing="0em"
                text-anchor="end"
                direction="rtl">
            <tspan x="300.227"
                   y="191.307">{{'right' | uppercase | translate}}</tspan>
            <tspan x="296.207"
                   y="203.556">{{'center' | uppercase | translate}}&#10;</tspan>
            <tspan x="298.762"
                   y="215.806">{{ carView }}</tspan>
          </text>
          <text id="left-center-outer-text"
                fill="#1A2233"
                fill-opacity="0.4"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                font-size="8.74984"
                font-weight="900"
                letter-spacing="0em"
                text-anchor="end"
                direction="rtl">
            <tspan x="6.32786"
                   y="191.307">{{'left' | uppercase | translate}} &#10;</tspan>
            <tspan x="0.457603"
                   y="203.556">{{'center' | uppercase | translate}}&#10;</tspan>
            <tspan x="3.01249"
                   y="215.806">{{ carView }}</tspan>
          </text>
        </svg>

        <img class="car-img"
             [src]="
            'assets/img/' +
            (carView === 'outer' ? 'top-view.png' : 'top-view2.png')
          "
             alt="" />
        <mat-icon (click)="toggleView()"
                  [class.switch]="carView === 'outer'"
                  class="swap-icon"
                  svgIcon="swap"></mat-icon>
      </div>
      <div class="car-section-dock">
        <div *ngFor="let item of partsList"
             class="part-wrapper"
             [class.active-part]="outerSelection === item.name"
             (click)="setActivePart(item)">
          <mat-icon [svgIcon]="vehicleSectionDockIconMap[item.name]"></mat-icon>
          <span>{{ item.name }}</span>
          <div *ngIf="item?.hasSavedParts"
               class="selected-dot"></div>
        </div>
      </div>
    </div>

    <div class="expand-screen">
      <button [class.forward]="isExpanded"
              (click)="expandScreen()">
        <mat-icon svgIcon="back"></mat-icon>
      </button>
    </div>
    <div class="part-list">
      <!-- Part header buttons  -->
      <div class="part-header">
        <span>{{'estimation_mode' | translate}} :</span>
        <section class="btn-wrapper">
          <button (click)="toggleEstimationMode()"
                  [class.active-btn]="estimationMode === 1">
            <mat-icon svgIcon="clock4"></mat-icon> {{'estimated_hours' | translate}}
          </button>
          <button (click)="toggleEstimationMode()"
                  [class.active-btn]="estimationMode === 2">
            <mat-icon svgIcon="dollar"></mat-icon> {{'cost_per_panel' | translate}}
          </button>
        </section>
        <div class="adx-button-wrapper">
          <xa-button class="adx-button" color="" type="outline" (click)="fileInput.click()">
            <mat-icon svgIcon="upload-dark"></mat-icon>
            {{"upload_estimate_xml" | translate}}
          </xa-button>
          <input
              #fileInput
              type="file"
              accept=".xml"
              hidden
              (change)="onFileSelected($event)"
          />
        </div>
      </div>

      <!-- Part Table  -->
      <div class="parts-table-wrapper">
        <form #partForm="ngForm">
          <table class="parts-table">
            <thead>
              <tr>
                <th class="action-row"
                    colspan="5">
                  <div class="table-header-btn">
                    <button *ngIf="!isSearchOpen">
                      {{ 'parts' | translate }} <span>{{ partsCount }}</span>
                    </button>
                    <button *ngIf="!isSearchOpen">
                      {{ 'operations' | translate }} <span>{{ operationCount }}</span>
                    </button>
                    <mat-icon *ngIf="!isSearchOpen"
                              (click)="isSearchOpen = true"
                              svgIcon="search"></mat-icon>
                    <div *ngIf="isSearchOpen"
                         class="search-wrapper">
                      <mat-icon class="search"
                                svgIcon="search"></mat-icon>
                      <xa-text [(ngModel)]="partSearchText"
                               name="searchText"
                               value=""
                               placeholder="Search"></xa-text>
                      <mat-icon class="close"
                                (click)="isSearchOpen = false;partSearchText = ''"
                                svgIcon="cross"></mat-icon>
                    </div>
                    <span>{{ 'qty' | translate }}</span>
                  </div>
                </th>
                <th>{{ 'replace' | translate }}</th>
                <th>{{ 'repair' | translate }}</th>
                <th>{{ 'r_r' | translate }}</th>
                <th>{{ 'paint' | translate }}</th>
                <th>{{ 'price' | translate }} ({{i18n.currencySymbol}})</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of partItems; let partIndex = index">
                <!-- Default Row -->
                <ng-container *ngIf="matchesSearch(item)">
                  <tr *ngIf="!item.isExpanded"
                      style="height: 62px">
                    <td class="action-row expand-btn-td">
                      <button class="expand-btn"
                              (click)="toggleExpand(item)">
                        <mat-icon [svgIcon]="item.isExpanded ? 'dot-menu' : 'plus'"></mat-icon>
                      </button>
                    </td>
                    <td (click)="toggleExpand(item)"
                        class="action-row part-name">{{ item.name }}</td>
                    <td class="action-row"></td>
                    <td class="action-row"></td>
                    <td class="action-row"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <!-- Expanded Rows -->
                  <ng-container [ngModelGroup]="item.name + '_' + row?.addendaPartGuid + partIndex + (row?.position || '' + i)"
                                *ngFor="let row of item.partDetails; let i = index">
                    <tr *ngIf="item.isExpanded"
                        class="expanded-row"
                        [ngClass]="{ 'part-selected': row?.isSelected }">
                      <td class="expand-btn-td">
                        <button *ngIf="i === 0"
                                class="expand-btn"
                                (click)="toggleExpand(item)">
                          <mat-icon [svgIcon]="item.isExpanded ? 'dot-menu' : 'plus'"></mat-icon>
                        </button>
                      </td>
                      <td (click)="toggleExpand(item)"
                          class="part-name">{{ i === 0 ? item.name : "" }}</td>
                      <td style="width: 20px;">
                        <span *ngIf="row.hasBlendParts"
                              (click)="openAdditionalParts(row)"
                              class="has-blend">A</span>
                      </td>
                      <td style="width: 5%;">
                        <div class="flex-center"
                             style="align-items: center;">
                          <span *ngIf="row.position"
                                class="side-options">{{ row.position?.charAt(0) }}</span>
                        </div>
                      </td>
                      <td style="width: 5%">
                        <div class="flex-center"
                             style="justify-content: flex-end">
                          <xa-numeric [(ngModel)]="row.quantity"
                                      (ngModelChange)="onValueChange(row, 'quantity', $event)"
                                      [max]="999"
                                      [min]="1"
                                      name="quantity"
                                      class="unit-input"
                                      textAddon=" "></xa-numeric>
                        </div>
                      </td>
                      <td style="width:10%"
                          *ngFor="let op of row.operationTypes | slice:0:4; let opIndex = index">
                        <div [ngModelGroup]="op.repairOperationType"
                             class="flex-center"
                             style="align-items: center;">
                          <input class="op-checkbox"
                                 type='checkbox'
                                 [(ngModel)]="op.checked"
                                 (ngModelChange)="onCheckboxChange(row, op.repairOperationType, $event, true)"
                                 [class.disabled]="op.disabled || (op.repairOperationType === 'Paint' && !row.paint)"
                                 [disabled]="op.disabled || (op.repairOperationType === 'Paint' && !row.paint)"
                                 [name]="op.repairOperationType">
                          <ng-container *ngIf="estimationMode === 1 && op.checked">
                            <xa-numeric [(ngModel)]="op.labourTime"
                                        [name]="'labourTime' + i"
                                        value="0"
                                        [min]="0"
                                        [max]="99.99"
                                        (ngModelChange)="onValueChange(row, 'labourTime', $event)"
                                        *ngIf="estimationMode === 1 && op.checked"
                                        class="hour-input"
                                        textAddon="h"></xa-numeric>
                          </ng-container>
                          <ng-container *ngIf="estimationMode === 2 && op.checked">
                            <xa-numeric [(ngModel)]="op.labourAmount"
                                        [name]="'labourAmount' + i"
                                        value="0"
                                        [min]="0"
                                        [max]="999999"
                                        (ngModelChange)="onValueChange(row, 'labourAmount', $event)"
                                        class="amount-input"
                                        textAddon=" ">
                            </xa-numeric>
                          </ng-container>
                        </div>
                      </td>
                      <td style="width:5%">
                        <div style="justify-content: flex-end;"
                             class="flex-center">
                          <xa-numeric [(ngModel)]="row.price"
                                      name="price"
                                      [disabled]="true"
                                      class="price-input">
                          </xa-numeric>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>

      <!-- Particulars section -->
      <section class="particulars">
        <div *ngIf="estimationMode === 2"
             class="total-section">
          <div class="header">
            <span>{{'particulars' | translate}}</span>
            <span>{{'cost' | translate}} ({{i18n.currencySymbol}})</span>
          </div>
          <div class="content-row border-b cost-per-panel">
            <span>{{'total_parts' | translate}}</span>
            <span>{{costByHour.totalParts || '-'}}</span>
          </div>
          <div class="content-row border-b cost-per-panel">
            <span>{{'cost_per_panel' | translate}}</span>
            <span>{{costPerPanel || '-'}}</span>
          </div>
          <div class="content-row total">
            <span>{{'total_cost' | translate}}</span>
            <span>{{totalCostPerPanel || '-'}}</span>

          </div>
        </div>

        <div *ngIf="estimationMode === 1"
             class="total-section estimated-hours">
          <div class="header">
            <span>{{'particulars' | translate}}</span>
            <span>{{'hours' | translate}}</span>
            <span>{{'cost' | translate}} ({{i18n.currencySymbol}})</span>
          </div>
          <div class="content-row border-b cost-per-panel">
            <span>{{'total_parts' | translate}}</span>
            <span></span>
            <span>{{costByHour.totalParts || '-'}}</span>
          </div>
          <div class="content-row border-b cost-per-panel">
            <span>{{'total_labour' | translate}}</span>
            <span>{{(costByHour.totalLabour.hours + 'h') || '-'}}</span>
            <span>{{costByHour.totalLabour.cost || '-'}}</span>
          </div>
          <div class="content-row border-b cost-per-panel">
            <span>{{'total_paint' | translate}}</span>
            <span>{{(costByHour.totalPaint.hours + 'h') || '-'}}</span>
            <span>{{costByHour.totalPaint.cost || '-'}}</span>
          </div>
          <div class="content-row total">
            <span>{{'total' | translate}}</span>
            <span></span>
            <span>{{costByHour.totalPartsEstimated || '-'}}</span>
          </div>
        </div>

        <!-- Calculate btn -->
        <div class="calculate-btn-wrapper">
          <button (click)="openReviewEstimation()"
                  class="calculate-btn">
            <mat-icon svgIcon="calculate2"></mat-icon>
          </button>
        </div>
      </section>
    </div>

  </div>
</div>
<div class="footer-full-wrapper">
  <div class="footer-image-wrapper">
    <div class="image-wrapper">
      <img alt="addenda repair"
           src="assets/img/addenda-repair.png" />
    </div>
  </div>

  <div class="footer-button-wrapper btn-wrappers">
    <a (click)="openCreateOperation()">
      <xa-button class="operation-btn"
                 type="outline">{{ 'create_operation' | translate }}</xa-button>
    </a>
    <a (click)="onEstimateButtonClick()">
      <xa-button class="est-btn"
                 color="primary">{{ 'calculate_estimate' | translate }}</xa-button>
    </a>
    <a *ngIf="showCloseButton"
       (click)="onCancelClick()">
      <xa-button class="est-btn"
                 type="outline">{{ 'show_details' | translate }}</xa-button>
    </a>
  </div>
</div>
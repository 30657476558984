import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgImageSliderComponent } from 'ng-image-slider';
import { DialogModel } from 'src/app/model/chq-repair-dialog-model';
import { pairwise, startWith } from 'rxjs/operators';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { menuItem, menuItems } from 'src/app/model/menu';
type Email = {
To:string,
Subject:string,
Content:string,
}
@Component({
  selector: 'app-chq-repair-dialog',
  templateUrl: './chq-repair-dialog.component.html',
  styleUrls: [ './chq-repair-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ChqRepairDialogComponent implements AfterContentChecked, OnDestroy {

  @ViewChild('nav') slider: NgImageSliderComponent;
  public comments: string;
  public thumbnails = [];
  public index: number = 1;
  public currentImage: string;
  public totalImages: number;
  public commentForm: FormGroup;
  public showComment: boolean = false;
  public showLoading: boolean = true;
  public showEmail: boolean = false;
  public showNotes: boolean = false;
  public emailContent:Email;

  subscription: Subscription;
  isExternal: boolean = false;
  public TMInitialEstimateDeliveryDate : ChqWidgetsInputModel;
  menuItems: menuItem[] = menuItems;

  notesModel: ChqWidgetsInputModel = {
    placeHolder: '',
    label: '',
    name: 'notes',
    value: 0,
    type: 'textarea',
    displayValue: 'name',
    fieldValue: 'id',
    disabled: true
  }

  /**
   * constructor
   * @param dialogRef
   * @param data
   * @param changeDedectionRef
   */
  constructor(
    public dialogRef: MatDialogRef<ChqRepairDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private changeDedectionRef: ChangeDetectorRef,
    private monitorService:MonitorService,
    private formBuilder: FormBuilder,
    private commonService:CommonService
  ) {
    this.subscription = this.commonService.userProfileData.subscribe({
      next: (res: any) => {
        if (res) {
          const automotiveServices = res?.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices.find(x => x.automotiveServiceName?.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }
        }
      }
    });
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'ChqRepairDialogComponent', 'addenda-quote' ]);
    this.TMInitialEstimateDeliveryDate = {
      placeHolder: 'Estimate Delivery Date',
      label: 'Estimate Delivery Date',
      name: 'deliveryDate',
      value: '',
      type: 'date',
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1),
      validation: {
        name: 'deliveryDate',
        validationFunction: (): ValidatorFn => {
          return Validators.compose([ Validators.required ])
        },
        validationMessage: (error: ValidationErrors): string => {
          if (error['required']) {
            return 'Please enter date'
          }
          return '';
        }
      }
    }
    if(this.data?.status?.toLowerCase() === 'general notes updated' ){
      this.showNotes = true;
      this.notesModel.value = this.data?.email;
    }else if(this.data?.email) {
      this.showEmail = true;
      this.emailContent = JSON.parse(this.data?.email);
      this.emailContent.Content = this.emailContent.Content.replace( /(<a\b[^>]*>)(.*?)(<\/a>)/g,
        (match, startTag, content, endTag) => {
          return `<p class="link-text">${content}</p>`;
        })
    }
    if (this.data?.imageGallery) {
      this.getImages();
    } else if(this.data?.comment) {
      this.showComment = true;
      this.comments = this.data?.comment;
    } else {
      this.commentForm = this.formBuilder.group({
        comment: [ '', this.data.buttonModel?.label === 'Export to DMS' && this.isExternal ? [ noEmptySpacesValidator() ]
          : [ Validators.required, noEmptySpacesValidator() ] ]
      });

      if (this.data && this.data.buttonModel) {
        this.data.buttonModel.onclick = this.processCase.bind(this);
        if(this.isExternal && this.data.buttonModel.label === 'Export to DMS') {
          this.data.buttonModel.type = 'disabled';
        } else {
          if (this.commentForm.invalid) {
            this.data.buttonModel.type = 'disabled';
          } else {
            if(this.data.buttonModel.label === 'Approve Case') {
              this.data.buttonModel.type = 'success';
            } else if(this.data.buttonModel.label === 'Reject Case') {
              this.data.buttonModel.type = 'red';
            } else {
              this.data.buttonModel.type = 'primary';
            }
          }
        }

      }

      this.commentForm.get('comment').valueChanges.pipe(startWith(null), pairwise())
        .subscribe(([ prev, next ]: [any, any]) => {
          if (next?.trim()) {
            if(this.data.buttonModel.label === 'Approve Case') {
              this.data.buttonModel.type = 'success';
            } else if(this.data.buttonModel.label === 'Reject Case') {
              this.data.buttonModel.type = 'red';
            } else {
              if(this.isExternal) {
                if(this.TMInitialEstimateDeliveryDate.value) {
                  this.data.buttonModel.type = 'primary';
                } else {
                  this.data.buttonModel.type = 'disabled';
                }
                return;
              }
              this.data.buttonModel.type = 'primary';
            }
          } else if(this.data.buttonModel.label === 'Export to DMS' && this.isExternal && this.TMInitialEstimateDeliveryDate.value){
            this.data.buttonModel.type = 'primary';
          } else {
            this.data.buttonModel.type = 'disabled';
          }
        });
    }

  }

  /**
   *
   */
  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }
  /**
   * cancel dialog
   */
  cancelDialog(): void {
    this.monitorService.logEvent('cancelDialog', [ 'ChqRepairDialogComponent', 'addenda-quote' ]);
    this.dialogRef.close();
  }

  /**
  * show confirmation popup to navigate to gt estimate if all the estimate charges are 0
  */
  showCompleteConfirmation(): void {
    const message = 'dms_confirm_complete';
    const dialogRef = this.commonService.openConfirmYesNoDialog('Export to DMS', message);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const obj = {
          comment: this.commentForm.controls['comment'].value,
          deliveryDate: this.isExternal ? this.TMInitialEstimateDeliveryDate.value : null
        }
        this.dialogRef.close(obj);
      }
    });
  }

  /**
   * close dialog
   */
  processCase(): void {
    if (this.commentForm.invalid) {
      return;
    } else {
      if (this.data.buttonModel.label === 'Export to DMS' && this.isExternal) {
        this.showCompleteConfirmation();
      } else {
        this.dialogRef.close({ comment: this.commentForm.controls['comment'].value });
      }

    }
  }

  /**
   * get image data
  */
  getImages(): void {
    this.thumbnails = this.data.gallery;

    this.totalImages = this.thumbnails.length;
    this.showLoading = true;
    this.currentImage = this.thumbnails[0].thumbImage;
  }

  /**
   * show image
  */
  showImage($event): void {
    this.index = $event + 1;
    this.showLoading = true;
    this.currentImage = this.thumbnails[$event].thumbImage;
  }

  /**
   * previous image click
   */
  prevImageClick(): void {
    if(this.index > 1) {
      this.index = this.index - 1;
      this.slider.prev();

      this.showLoading = true;
      this.currentImage = this.thumbnails[this.index-1].thumbImage;
    }
  }

  /**
   * next image click
   */
  nextImageClick(): void {
    if(this.index < this.slider.totalImages) {
      this.index = this.index + 1;
      this.slider.next();

      this.showLoading = true;
      this.currentImage = this.thumbnails[this.index-1].thumbImage;
    }
  }

  /**
   * returns formControl
   */
  get formControl(): AbstractControl<any, any> {
    return this.commentForm.controls['comment'];
  }

  /**
   * update loading
   */
  updateLoading(): void {
    this.showLoading = !this.showLoading;
  }

  /**
   *
   * @param event
   */
  public onFormUpdate(output: IControlValue): void {
    if (!output.isValid) {
      this.TMInitialEstimateDeliveryDate.value = '';
      this.data.buttonModel.type = 'disabled';
    } else {
      this.TMInitialEstimateDeliveryDate.value = output.value;
      this.data.buttonModel.type = 'primary';
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}

/**
 * no empty space validator
 * @returns validator function
 */
export function noEmptySpacesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value && control.value.trim() === '') {
      return { noEmptySpaces: true };
    }
    return null;
  };
}

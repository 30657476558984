<div class="main-container">
    <ng-container *ngIf="name === 'liva' else defaultTemplate">
        <div class="liva-container">
            <div class="header">
                <img alt="liva-logo"
                     src="assets/img/liva-logo.png" />
            </div>
            <div class="main-content">
                <div class="title">
                    {{'title_fnol_liva' | translate}}
                </div>
                <div class="title-desc">
                    {{'desc_fnol_liva' | translate}}
                </div>
                <div class="step">
                    {{'registration_card' | translate}}
                    <div class="sub-step">
                        a. {{'policy_report' | translate}}
                    </div>
                    <div class="sub-step">
                        b. {{'photos_angle_wise' | translate}}
                    </div>
                    <div class="sub-step">
                        c. {{'registration_card_fnol' | translate}}
                    </div>
                    <div class="sub-step">
                        d. {{'driving_license_fnol' | translate}}
                    </div>
                </div>
                <div class="navigation"
                     (click)="navigate()">
                    <img alt="liva-claim"
                         src="assets/img/liva-claim.svg" />
                    <span>{{'submit_claim' | translate}}</span>
                </div>
            </div>
            <div class="footer">
                {{'copyright' | translate: { year: year } }}
            </div>
        </div>
    </ng-container>

    <ng-template #defaultTemplate>
        <div class="default-container">
            <div class="header">
                <img alt="addenda-logo"
                     *ngIf="logoUrl"
                     [src]="logoUrl" />
            </div>
            <div class="main-content">
                <div class="content-wrapper">
                    <div class="title">
                        {{'title_fnol' | translate}}
                    </div>
                    <div class="title-desc">
                        {{'desc_fnol' | translate}}
                    </div>
                    <div class="step-title">
                        {{pageComponents?.ItemValue | translate}}
                    </div>
                    <div class="step"
                         *ngIf="pageComponents?.value">
                        <div class="sub-step"
                             *ngFor="let slot of pageComponents?.value">
                            <div class="icon-wrapper">
                                <img [src]="baseConfigUrl + slot.ItemValue"
                                     alt="sloTIcon" />
                            </div>
                            {{slot.ItemKey | translate}}
                        </div>
                    </div>

                    <label for="select"
                           *ngIf="!isDirectUrl"
                           class="select-label">{{'get_started_title' | translate}}</label>
                    <div class="select-container"
                         *ngIf="!isDirectUrl">
                        <xa-select [(ngModel)]="selectedTenant"
                                   [config]="config"
                                   [options]="options"></xa-select>
                        <button class="proceed-btn"
                                [disabled]="!selectedTenant || !termsAndCondition"
                                (click)="proceed()">{{'submit_fnol' | translate}}</button>
                    </div>
                    <div class="terms-conditions"
                         *ngIf="!isDirectUrl">
                        <xa-checkbox [(ngModel)]="termsAndCondition"></xa-checkbox>
                        <div class="terms-text">{{'have_read' | translate}} <span class="terms-link"
                                  (click)="openTermsAndConditions($event)">{{'terms_and_conditions' | translate}}</span></div>
                    </div>
                    <xa-button class="navigation-btn"
                               type="outline"
                               size="lg"
                               *ngIf="isDirectUrl"
                               (click)="navigate()">
                        <span>{{'submit_fnol' | translate}}</span>
                    </xa-button>

                    <div class="image-wrapper show-sm"
                         *ngIf="androidLink || iosLink">
                        <span class="get-now"> {{'get_now' | translate}}</span>
                        <div class="img-content">
                            <img class="mobile-screen"
                                 alt="mobile-screen"
                                 src="assets/img/mobile-screen.svg" />
                            <div class="eclipse-bg show-sm"></div>
                        </div>
                        <div class="store-link show-sm">
                            <a rel="noopener"
                               *ngIf="androidLink"
                               [href]="androidLink"
                               target="_blank"><img alt="play-store"
                                     src="assets/img/googleplay.png" /></a>
                            <a rel="noopener"
                               [href]="iosLink"
                               *ngIf="iosLink"
                               target="_blank"><img alt="app-store"
                                     src="assets/img/appstore.png" /></a>
                        </div>

                    </div>
                </div>
                <div class="image-wrapper hide-sm"
                     *ngIf="androidLink || iosLink">
                    <span class="get-now"> {{'get_now' | translate}}</span>
                    <img class="mobile-screen"
                         alt="mobile-screen"
                         src="assets/img/mobile-screen.svg" />
                    <div class="store-link">
                        <a rel="noopener"
                           [href]="androidLink"
                           target="_blank"
                           *ngIf="androidLink"><img alt="play-store"
                                 src="assets/img/googleplay.png" /></a>
                        <a rel="noopener"
                           [href]="iosLink"
                           target="_blank"
                           *ngIf="iosLink"><img alt="app-store"
                                 src="assets/img/appstore.png" /></a>
                    </div>
                </div>
            </div>
            <div class="footer">
                {{ 'copyright_reserved' | translate: { year: year } }}
            </div>

            <div class="eclipse-bg hide-sm"
                 *ngIf="androidLink || iosLink"></div>
        </div>
    </ng-template>

</div>
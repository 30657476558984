/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { serviceURL } from 'src/app/config/constants/app.constants';

interface IParameterDetail {
  CustomParameterId: number;
  FieldValue: string;
}

export interface IParameterPayload {
  GroupGUID: string;
  RequestCustomParameterDetails: IParameterDetail[];
}
export interface IParameters{
  isDisabled:boolean,
  name:string;
  parameterValue:boolean;
  optionValue?:string;
  serviceUrl:string;
  customParameterId:number,
  customerParameterMappingId?: number;
  parentFieldId?:number;
  serviceName:string;
  isRequired:boolean;
  options?:{
    label:string,
    value:any
  }[]
}
let paramNextUniqueId = 0;


@Component({
  selector: 'parameters',
  templateUrl: './parameters.component.html',
  styleUrls: [ './parameters.component.scss' ],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParametersComponent),
      multi: true
    },
  ]
})
export class ParametersComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() currentTitle:string;
  @Input() parameters:IParameters[];

  _parameters:IParameters[];
  _isInitialized: boolean = false;
  private envUrl = serviceURL;
  @Input() name?: string;

  _uniqueId: string = `xa-text-${paramNextUniqueId++}`;
  value = '';

  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  /**
   * constructor
   */
  constructor(private readonly inventoryService:InventoryService) { }

  /**
   * Sets the value value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['parameters']) {
      this._parameters = cloneDeep(this.parameters);
    }
  }

  /**
   * get options
   */
  pullOptions(serviceName:string, serviceUrl:string, index:number):void{
    let service = '';
    if(serviceName?.toLowerCase() === 'fnol'){
      service = 'Fnol';
    }else if(serviceName?.toLowerCase() === 'autocore'){
      service = 'AutoCore';
    }else if(serviceName?.toLowerCase() === 'auto'){
      service = 'AutoApi';
    }else {
      service = serviceName;
    }
    this.inventoryService.getCommonValueUrl(`${this.envUrl[service]}/${serviceUrl}`).subscribe({
      next: (res:any) => {
        if(res){
          let data = [];
          if (res.constructor == Object) {
            data = res['data'];
          } else {
            data = res;
          }
          this._parameters[index].options = data.map((option)=>{
            return {
              label: option.name,
              value: option.id.toString()
            }
          }) || [];
          this._parameters = [ ...this._parameters ];
        }
      }
    });
  }

  /**
   * check parameter options
   */
  checkParameterOptions():void{
    this._parameters.forEach((parameter, index) => {
      if(parameter.serviceName && parameter.serviceUrl){
        this.pullOptions(parameter.serviceName, parameter.serviceUrl, index);
      }
    })
  }

  /**
   * ngon init
   */
  ngOnInit():void{
    this._parameters = cloneDeep(this.parameters);
    //this.checkParameterOptions();
  }

  /**
   * trigger modal change
   */
  triggerChange(parameter?:IParameters, value?:boolean):void{
    const childParams = this._parameters.filter(param => param.parentFieldId === parameter.customParameterId);
    if(childParams.length > 0){
      childParams.forEach((x=>x.parameterValue = value));
    }
    this._controlValueAccessorChangeFn(this._parameters);
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    this._controlValueAccessorChangeFn(this.value);
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }
}

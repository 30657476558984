import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { CLAIM_STATUS, GARAGE_REGISTRATION_STATUS } from 'src/app/config/constants/claims.constants';
import { AskForRevisionNotificationMailComponent } from 'src/app/dialogs/ask-for-revision-notification-mail/ask-for-revision-notification-mail.component';
import { EstimateDeclineComponent } from 'src/app/dialogs/estimate-decline/estimate-decline.component';
import { InternalGarageEstimateDialogComponent } from 'src/app/dialogs/internal-garage-estimate-dialog/internal-garage-estimate-dialog.component';
import { InternalGarageEstimateLogComponent } from 'src/app/dialogs/internal-garage-estimate-log/internal-garage-estimate-log.component';
import { ReserveAdjustmentDialogComponent } from 'src/app/dialogs/reserve-adjustent-dialog/reserve-adjustment-dialog.component';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsDropdownModel } from 'src/app/model/chq-widgets-dropdown-model';
import { ChqWidgetsInputModel } from 'src/app/model/chq-widgets-input-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
  selector: 'app-estimate-details',
  templateUrl: './estimate-details.component.html',
  styleUrls: [ './estimate-details.component.scss' ]
})
export class EstimateDetailsComponent implements OnInit, OnDestroy {

  @Input() claimId: string = '';
  @Input() dialogRef: any;
  public i18n: Localization;
  public userDetail = null;
  public estimateResponse = {};
  public editEstimate: boolean = false;
  public estimateSource: string = '';
  public isTotalLossThresholdReached: boolean = false;
  public totalLossThreshold: number = 0;
  haveFinancialPermission: boolean = false;
  requestForEnum: any = {
    requestForInvoice: 1,
    requestForEstimate: 2
  }
  public addRevisionButtonModel: ChqWidgetsButtonModel = {
    label: 'add_revision',
    type: 'outline',
    icon: 'send'
  };

  public rejectEstimateButtonModel: ChqWidgetsButtonModel = {
    label: 'reject_estimate',
    type: 'outline-danger',
  };

  public approveButtonModel: ChqWidgetsButtonModel = {
    label: 'approve_estimate',
    type: 'primary',
    onclick: () => this.handleApproveEstimate('Approved')
  }

  public markAsTotalLossModel: ChqWidgetsButtonModel = {
    label: 'mark_as_total_loss',
    type: 'primary',
    onclick: () => this.handleMarkAsTotalLoss()
  }

  public cancelEditedModel: ChqWidgetsButtonModel = {
    label: 'cancel',
    type: 'outline-danger',
  };

  public estimateLogButtonModel: ChqWidgetsButtonModel = {
    label: 'estimate_log',
    type: 'outline',
    icon: 'viewFile',
    onclick: () => {
      //this.router.navigate([ 'claim/claim-detail/'+this.claimId + '/estimate/estimate-log' ])
      this.dialog.open(InternalGarageEstimateLogComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'internal-estimate-panel',
        disableClose: true,
        data: {
          claimId: this.claimId,

        },
        autoFocus: false
      });
    }
  };

  public dropdownAskRevisionModelFilter: ChqWidgetsDropdownModel = {
    options: [
      { dropdownItemIcon: 'send', color: 'var(xa-white) !important;', label: this.translationService.instant('garage') },
      { dropdownItemIcon: 'email', color: 'var(xa-white) !important;', label: this.translationService.instant('email') },
      { dropdownItemIcon: 'whatsapp', color: 'var(xa-white) !important;', label: this.translationService.instant('whatsapp') },
    ],
    placeHolder: 'ask_for_revision',
    label: '',
    hideDropdownIcon: true,
    appearance: 'button',
    placeHolderIcon: 'send',
    name: 'option',
    displayValue: 'label',
    selectedOption: {}
  };
  //public claimId = '0';

  defaultRadioInputModel: ChqWidgetsInputModel = {
    placeHolder: '',
    label: '',
    name: '',
    idField: '',
    value: '',
    type: 'radio',
    options: [ { label: 'Approved', id: 1 }, { label: 'Rejected', id: 2 } ],
  }
  claimDetails;
  subscriptions: Subscription[] = [];
  fetchAPI: boolean = false;
  estimateStatus: string;
  estimateColorCode: string;

  estimateAddRevisionList: object[] = [];
  estimateChangeList;

  isShowApprovedButton: boolean = false;
  isShowRejectedButton: boolean = false;
  hasIdvValue: boolean = false;
  public dropdownOptions = [];
  public defaultSelectedReport = '';

  /**
   * 
   * @param commonService 
   * @param translationService 
   */
  constructor(private commonService: CommonService,
    private translationService: TranslateService,
    private claimService: ClaimsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,) {
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
    * ngOnInit
    */
  ngOnInit(): void {
    this.haveFinancialPermission = this.commonService.haveFinancialPermission()
    this.commonService.userProfileData.subscribe((res) => {
      this.userDetail = res?.data;
    });

    // this.route?.parent?.parent.params.subscribe((value) => {
    //   if (value?.['id']) {
    //     this.claimId = value['id'];
    //   }
    // });
  
    this.getClaimDetails();
    const estimateStatus$ = this.claimService.EstimateStatus.subscribe((status) => {
      if (status) {
        this.estimateStatus = status
      }
    });
    const isTotalLoss$ = this.claimService.isTotalLoss.subscribe((value) => {
      if (value !== null && this.claimDetails?.lossDetails && this.claimDetails?.claimGuid === value.claimGuid) {
        this.claimDetails.lossDetails.isTotalLoss = value.isTotalLoss
      }
    });
    this.subscriptions.push(estimateStatus$);
    this.subscriptions.push(isTotalLoss$);
  }

  /**
   * @returns Promise
  */
  getTotalLossThreshold(): any { // TODO call this function if estimate received from garage
    if(this.claimDetails?.marketValue || (this.claimDetails?.isODManualSetting === false && this.claimDetails?.lossDetails?.subIntimationType !== 'Third party')) {
      this.hasIdvValue = true;
      this.commonService.showLoading()
      const lossDate = this.claimDetails?.lossDetails?.lossDate ? dayjs.utc(this.claimDetails.lossDetails.lossDate).local().format('YYYY-MM-DD'): ''
      return firstValueFrom(this.claimService.getTotalLossThreshold(this.claimDetails?.claimGuid, lossDate)).then((response) => {
        if (response) {
          if (response.data) {
            this.totalLossThreshold = response.data.totalLoss;
            if(this.totalLossThreshold && this?.claimDetails?.garageDetails?.estimateAmount && this?.claimDetails.garageDetails.estimateAmount > this.totalLossThreshold) {
              this.isTotalLossThresholdReached = true;
            }

          }
        }
      }).catch((err) => {
        this.commonService.hideLoading()
      });
    } else {
      return null
    }
  }



  /**
   * getClaimDetails
   * 
   */
  getClaimDetails(): void {

    this.fetchAPI = true;
    this.commonService.showLoading();
    const claimDetails$ = this.claimService.getClaimDetail(this.claimId).subscribe({
      next: async (response) => {
        this.commonService.hideLoading();
        this.claimDetails = response;
        await this.getTotalLossThreshold()
        this.getLatestEstimate();
        this.commonService.claimDetails.next(response);
        this.commonService.claimStatus.next(response.claimStatus);
      },
      error: () => {
        this.fetchAPI = false;
        this.commonService.hideLoading();
      }
    });


    this.subscriptions.push(claimDetails$);
  }

  /**
   * 
   */
  getLatestEstimate(): void {
    this.commonService.showLoading();
    this.claimService.getLatestEstimateDetails(this.claimDetails.id).subscribe({
      next: (response) => {
        this.commonService.hideLoading();
        this.estimateAddRevisionList = [];
        this.estimateChangeList = response.data.estimate.estimateCharges.map((x) => {
          if (x.status) {
            this.estimateAddRevisionList.push({
              'estimateChargeId': x.id,
              'status': x.status,
              'notes': x.notes
            });
          }
          x['radioInputModel'] = { ...this.defaultRadioInputModel, name: x.id.toString(), value: x.status };
          return x;
        });
        this.showRejectedButton();
        this.showApprovedButton();
        this.estimateResponse = { ...response.data.estimate, estimateCharges: this.estimateChangeList };
        this.estimateStatus = response.data.estimate.approvalStatus;
        this.estimateColorCode = response.data.estimate.approvalStatusColorCode;

        /*this.dropdownOptions = response?.data?.estimate?.estimateDocumentTypes;
        if(this.dropdownOptions && this.dropdownOptions.length > 0){
          this.dropdownOptions.forEach((opt)=>{
            const keyValue = opt.key;
            opt.label = opt.value;
            opt.value = keyValue;
            opt.icon = {
              name: 'document-search',
              type: 'svg'
            }
          });
          this.defaultSelectedReport = this.dropdownOptions[0].value;
        }*/
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * back
   */
  back(): void {
    this.router.navigate([ 'claim/claim-detail/' + this.claimId + '/customer-view' ]);
  }

  /**
   * requestForRevisedEstimate
   */
  requestForRevisedEstimate(): void {
    this.commonService.showLoading();
    firstValueFrom(this.claimService.requestForEstimate(this.claimId, this.requestForEnum.requestForEstimate)).then((response) => {
      this.commonService.hideLoading();
      if (response) {
        this.commonService.showInfoToast(5000, response?.message);
      }
    }).catch((err) => {
      this.commonService.hideLoading();
    });
  }

  /**
    Handles the click event on an icon.
    @param {object} output
  */
  radioButtonClicked(output): void {

    if (output.$event.value === 'Rejected') {
      this.dialog.open(EstimateDeclineComponent, {
        data: {
          type: 'askRevision'
        },
        height: 'auto',
        panelClass: 'operation-panel',
        disableClose: true,
        autoFocus: false
      }).afterClosed().subscribe({
        next: (response) => {
          if (response) {
            this.estimateAddRevisionList = this.estimateAddRevisionList.filter(x => x['estimateChargeId'] !== output.row.id);
            this.estimateAddRevisionList.push({
              'estimateChargeId': output.row.id,
              'status': 'Rejected',
              'notes': response.comment
            });

            output.row = { ...output.row, notes: response.comment, status: 'Rejected', radioInputModel: { ...this.defaultRadioInputModel, value: 'Rejected' } };

          } else {
            this.estimateAddRevisionList = this.estimateAddRevisionList.filter(x => x['estimateChargeId'] !== output.row.id);
            output.row = { ...output.row, notes: null, status: null, radioInputModel: { ...this.defaultRadioInputModel, value: null } };
          }
          this.updateEstimateCharge(output);
        }
      });
    } else {
      this.estimateAddRevisionList = this.estimateAddRevisionList.filter(x => x['estimateChargeId'] !== output.row.id);
      this.estimateAddRevisionList.push({
        'estimateChargeId': output.row.id,
        'status': 'Approved',
        'notes': null
      });
      output.row = { ...output.row, notes: null, status: 'Approved', radioInputModel: { ...this.defaultRadioInputModel, value: 'Approved' } };
      this.updateEstimateCharge(output);
    }
  }

  /**
   * updateEstimateCharge()
  */
  public updateEstimateCharge(output): void {
    this.estimateChangeList = this.estimateChangeList.map((x) => {
      if (x.id === output.row.id) {
        x = output.row;
      }
      return x;
    });
    this.showRejectedButton();
    this.showApprovedButton();
    this.estimateResponse = { ...this.estimateResponse, estimateCharges: this.estimateChangeList };

  }

  /**
   * handleSelectedOptionAskRevision
   * @param{ $event }
   * 
   */
  handleSelectedOptionAskRevision($event): void {
    if ($event.value.dropdownItemIcon === 'send') {
      this.commonService.openConfirmYesNoDialogBig('', 'send_estimate_revision_message_garage').afterClosed().subscribe((data) => {
        if (data) {
          this.editEstimateRevision();
        }
      })
    } else {
      // send ask for revision notification 
      this.getAskForRevisionTemplate($event.value.dropdownItemIcon);
    }

  }


  /**
   * openDeclineModel
   * 
   */
  openDeclineModel(): void {
    this.dialog.open(EstimateDeclineComponent, {
      data: {
        type: 'Rejected'
      },
      height: 'auto',
      panelClass: 'operation-panel',
      autoFocus: false
    }).afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.commonService.openConfirmYesNoDialogBig('', 'reject_garage_confirmation_message', '', 'yes', 'assigned_another_garage_message').afterClosed().subscribe((data) => {
            if (data === false) {
              this.setApproveRejectEstimate('Rejected', response.comment, true);
            } else if (data) {
              this.setApproveRejectEstimate('Rejected', response.comment, false);
            }
          })
        }
      }
    });
  }

  /**
 * handleApproveEstimateThirdParty
 */
  handleApproveEstimate(status: string): void {
    if(this.haveFinancialPermission) {
      const dialogRef = this.commonService.openConfirmYesNoDialogBig('', 'Would you like to approve the estimate as it is or adjust the reserve first', '', 'Adjust Reserve', 'Approve estimate')
      dialogRef.afterClosed().subscribe((action: string | boolean) => {
        if (action === false) {
          this.setApproveRejectEstimate(status, null, false)
        } else if (action === true) {
          this.openClaimAdjustPopup();
        } 
      });
    } else {
      this.setApproveRejectEstimate(status, null, false)
    }
    
  }
  /**
 * handleMarkAsTotalLoss
 */
  handleMarkAsTotalLoss(): void {
    if(this.haveFinancialPermission) {
      const dialogRef = this.commonService.openConfirmYesNoDialogBig('', 'Would you like to mark the claimant as a total loss as is or adjust the reserve first?', '', 'Adjust Reserve', '', false)
      dialogRef.afterClosed().subscribe((action: string | boolean) => {
        if(action === true) {
          this.openClaimAdjustPopup(true);
        }
      });
    } else {
      this.commonService.showLoading()
      firstValueFrom(this.claimService.updateReserveWithTotalLoss({
        'claimGuid': this.claimId,
      })).then((res) => {
        this.commonService.hideLoading();
        if(res) {
          this.commonService.showInfoToast(5000, this.translateService.instant('total_loss_reserve_update_success'));
          this.claimService.isTotalLoss.next({
            claimGuid: this.claimId,
            isTotalLoss: true
          })
        }
      }).catch(() => {
        this.commonService.hideLoading();
      });
    }
  }
  /**
   * openClaimAdjustPopup
   */
  openClaimAdjustPopup(isForTotalLoss: boolean = false): void {
    this.commonService.showLoading();

    this.claimService.getFinancialSummary({
      PoliceReferenceNo: this?.claimDetails?.lossDetails?.policeReferenceNo,
      IntimationNo: this?.claimDetails.intimationNo
    }).subscribe({
      next: (response) => {
        if(response?.data?.reserveSummary) {
          this.commonService.hideLoading();
          const selectedSummary = response?.data?.reserveSummary?.claimantDetails.find(x=>x.claimGuid === this.claimId);
          const dialogRef = this.dialog.open(ReserveAdjustmentDialogComponent, {
            data: {
              reserveData: selectedSummary?.reserveDetails,
              claimGuid: this.claimId,
              garageType: GARAGE_REGISTRATION_STATUS.internal,
              objectId: this.claimDetails?.id,
              isForTotalLoss,
              isTotalLoss: this.claimDetails?.lossDetails?.isTotalLoss,
              hasIdvValue: this.hasIdvValue, 
              totalLossThreshold: this.totalLossThreshold || 0
            },
            width: '70vh',
          });
          dialogRef.afterClosed().subscribe((action: string | boolean) => {
            console.log('Actions', action)
          });
        }
          
      }, error: (err) => {
        this.commonService.hideLoading();
        //this.monitorService.logException(err, SeverityLevel.Error);
      }
    })
  
  }

  /**
   * setApproveRejectEstimate
   * 
   */
  setApproveRejectEstimate(status: string, comment: string, isUnassign: boolean): void {
    if(this.claimDetails.lossDetails?.isTotalLoss) {
      this.commonService.showToast(0, this.translateService.instant('total_loss_approval_warning_message'));
      return
    }
    this.commonService.showLoading();
    this.claimService.approveRejectEstimate(this.claimDetails.id, { status: status, reason: comment, isReassigned: isUnassign, domainId: this.claimDetails.domainId }).subscribe({
      next: () => {
        this.commonService.hideLoading();
        this.estimateStatus = status;
        if (status === 'Rejected') {
          this.estimateColorCode = '#FC0000';
          if (isUnassign) {
            this.commonService.showLoading();
            this.claimService.updateClaimStatus(this.claimDetails.claimGuid, 'GarageAssignmentRequestedRejected').subscribe({
              next: () => {
                this.commonService.hideLoading();
                this.claimService.ClaimStatus.next(CLAIM_STATUS.GarageAssignmentRequestedRejected);
                if(this.dialogRef) this.dialogRef.close()
                // need to go back to the detail page - TODO
                //this.router.navigate([ 'claim/claim-detail/'+this.claimId+ '/garages' ]);
              },
              error: () => this.commonService.hideLoading()
            })
          }
        } else {
          this.estimateColorCode = '#B3E573';
          // approve claim once the  status is approved
          this.approveClaim();
        }
      },
      error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * editEstimateRevision
   * 
   */
  editEstimateRevision(notificationMetaData = null): void {
    this.commonService.showLoading();
    firstValueFrom(this.claimService.editEstimateAddRevision(this.claimDetails.id, { domainId: this.claimDetails.domainId, revisions: this.estimateAddRevisionList })).then((response) => {
      this.commonService.hideLoading();
      this.getLatestEstimate();
      if (notificationMetaData !== null) {
        if (notificationMetaData.type === 'email') {
          this.sendEmailNotification(notificationMetaData);
        } else {
          this.sendWhatsappNotification(notificationMetaData);
        }
      }
      this.editEstimate = false;
      this.commonService.showInfoToast(1000, response.data);
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }

  /**
   * showApprovedButton
   */
  showApprovedButton(): void {
    let isApproved = true;
    this.estimateChangeList.forEach((x) => {
      if (x.status !== 'Approved') {
        isApproved = false;
      }
    });

    this.isShowApprovedButton = isApproved;
  }

  /**
   * showApprovedButton
   */
  showRejectedButton(): void {
    let isRejected = true;
    this.estimateChangeList.forEach((x) => {
      if (x.status !== 'Rejected') {
        isRejected = false;
      }
    });

    this.isShowRejectedButton = isRejected;
  }

  /**
   * getAskForRevisionTemplate
   * 
   */
  getAskForRevisionTemplate(type): void {
    this.commonService.showLoading();
    this.claimService.getAskForRevisionEmailTemplate(this.claimDetails?.claimGuid, this.claimDetails?.domainId).subscribe({
      next: (response) => {
        this.commonService.hideLoading();
        const data = [ response[0], { ...response[1], type: type } ];
        this.dialog.open(AskForRevisionNotificationMailComponent, {
          data: data,
          height: 'auto',
          panelClass: 'operation-panel',
          autoFocus: false
        }).afterClosed().subscribe({
          next: (response) => {
            if (response) {
              this.editEstimateRevision(response);
            }
          }
        })
      }, error: () => this.commonService.hideLoading()
    })
  }

  /**
   * sendWhatsappNotification
   * 
   */
  sendWhatsappNotification(metaData: any): void {
    const payloadData = {
      'content': metaData.body,
      'countryCode': metaData.countryCode,
      'mobileNo': metaData.countryCode + metaData.phoneNumber,
      'provider': 4,
      'isMessageWithTemplate': true,
      'templateKey': 'ClaimAskForRevision',
      'contents': {
        'ClaimNumber': this.claimDetails.claimNo,
        'RepairID': this.claimDetails.garageDetails?.repairId?.toString(),
        'InsuranceCompany': this.claimDetails.organizationDetail.nameOfOrganization
      }
    }
    this.commonService.showLoading();
    this.claimService.sendWhatsappAskForRevisionNotification(payloadData).subscribe({
      next: () => this.commonService.hideLoading(),
      error: () => this.commonService.hideLoading()
    })
  }

  /**
   * sendEmailNotification
   * 
   */
  sendEmailNotification(metaData: any): void {
    const formData = new FormData();
    formData.append('content', metaData.body);
    formData.append('subject', metaData.subject);
    formData.append('ToEmail', metaData.email);
    formData.append('firstName', metaData.garageName);
    formData.append('objectID', this.claimDetails.domainId);

    this.commonService.showLoading();
    this.claimService.sendEmailAskForRevisionNotification(formData).subscribe({
      next: () => this.commonService.hideLoading(),
      error: () => this.commonService.hideLoading()
    })
  }


  /**
   * Approve claim after estimation is approved
   */
  approveClaim(): void {
    this.claimService.updateClaimStatus(this.claimId, 'Approved', 'Claim Approved').subscribe({
      next: (response) => {
        if (response) {
          if (this.claimDetails?.garageDetails?.garageStatus === GARAGE_REGISTRATION_STATUS.internal) {
            this.claimService.lockEstimation(this.claimDetails?.id, CLAIM_STATUS.Approved).subscribe();
          }
          // this.status = CLAIM_STATUS.Approved;
          // this.commonService.claimStatus.next(this.status);
          // this.claimDetails.claimStatus = this.status;
          //this.canShowApproveRejectButton = this.showApproveRejectButtons();
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.commonService.hideLoading();
      }
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * viewLPO
   */
  viewLPO(): Promise<void> {
    this.commonService.showLoading()
    return firstValueFrom(this.claimService.getLpoURL(this.claimDetails?.claimGuid)).then((res) => {
      this.commonService.hideLoading();
      if(res.success && res.data) {
        window.open(res.data, '_blank');
      } else {
        this.commonService.showToast(0, res.message)
      }
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }
}

<chq-custom-loader [showLoading]="isJobLoading"></chq-custom-loader>
<div *ngIf="!showNoAccess"
     [ngClass]="{'photo-container': photosHeader.isRepairModule}">
    <ng-container *chqPermission="'repairStages'">
        <add-photos [caseData]="caseData"
                    #addPhotos
                    [caseNumber]="photosHeader.caseNumber"
                    [repairUnapproved]="isInvalidStatus"
                    [showSPI]="showSPI"
                    [photosHeader]="addPhotosHeader"
                    [showComplete]="false"
                    [isRepairComplete]="isRepairComplete"
                    [showNotify]="showNotify"
                    (notify)="this.notify.emit()"
                    [activateRepairButton]="activateRepairButton"
                    (completeRepair)="this.completeRepair.emit();"
                    [hideBulk]="hideBulk"
                    (mode)="handleMode($event)"
                    *ngIf="showUploading === false"
                    [hidden]="hideContent"
                    [showEstimate]="false"></add-photos>
        <div class="wrapper"
             *ngIf="noImagesData">
            <div class="no-document-div">
                <img src="assets/icons/no-document.svg"
                     alt="No Document">
                <span class="no-vehicle-content">
                    {{'no_content' | translate}}
                </span>

            </div>
            <div class="empty-wrapper">
                <div class="step-container"></div>
                <div class="step-container"></div>
                <div class="step-container"></div>
                <div class="step-container"></div>
            </div>
        </div>

        <div *ngIf="noImagesData"
             class="footer-full-wrapper">
            <div class="footer-image-wrapper">
                <div class="image-wrapper">
                    <img src="assets/img/{{ + photosHeader.isRepairModule ?'addenda-repair.png':'addenda-quote.png'}}" alt="Addenda Logo" />
                </div>
            </div>
        </div>
        <chq-images-uploader [maximumImages]="minimumImageJob"
                             [hideAI]="!displayAI"
                             [hidden]="showDamageAnalysis || !showUploading"
                             (handleDeleteAllEvent)="handleDeleteAll()"
                             (backToUpload)="this.setInitialMode();"
                             [currentAdditionalModeMultiple]="currentAdditionalModeMultiple"
                             [fileUploaderService]="currentObservable"
                             #imageUploader
                             [footerOptions]="footer"
                             (updateMassUpload)="updateCurrentStatus($event);"
                             [isRepairStage]="true"
                             *ngIf="(showSPI || !hideBulk ) && ((showUploading === true || isMultipleUpload) || (imageStatusSteps && imageStatusSteps.length > 0))"
                             [isMassUpload]="isMultipleUpload"
                             [showReTakeDelete]="true"
                             [imageStatusSteps]="imageStatusSteps"
                             [header]="photosHeader"
                             [inspectionId]="inspectionId"
                             [inspection]="'photos'"
                             [isRepairComplete]="isRepairComplete"
                             [showComplete]="true"
                             [showNotify]="showNotify"
                             (notify)="this.notify.emit()"
                             [activateRepairButton]="activateRepairButton"
                             (completeRepair)="this.completeRepair.emit();"></chq-images-uploader>
        <damage-analysis *ngIf="showDamageAnalysis"
                         [isBulkUpload]="isMultipleUpload"
                         [minPhotoRequired]="minimumPhotosRequired"
                         [caseDetail]="partialCaseDetail || repairDetail"
                         [steps]="imageStatusSteps"
                         [isRepairStage]="true"
                         [isRepairModule]="photosHeader.isRepairModule"
                         [jobId]="objectId"
                         (backEvent)="handleBack($event)"></damage-analysis>
    </ng-container>
</div>
<no-access-error *ngIf="showNoAccess"></no-access-error>
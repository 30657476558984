import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, fromEvent, map, of, Subject, Subscription, takeUntil } from 'rxjs';
import { CASE_STATUS } from 'src/app/config/constants/quote.assessment.constants';
import { ChqInspectionStep } from 'src/app/model/chq-inspection-step';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { inspectionpartPartMapper } from '../../quote/chq-new-cases/chq-new-customer/chq-new-customer-form-data-helper';
import * as dayjs from 'dayjs';
import { MediaUploadComponent } from 'src/app/widgets/media-upload/media-upload.component';
import { VideoInfoDialogComponent } from 'src/app/dialogs/video-info-dialog/video-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { time } from 'echarts';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';

@Component({
  selector: 'app-submit-missing-info-v2',
  templateUrl: './submit-missing-info-v2.component.html',
  styleUrls: [ './submit-missing-info-v2.component.scss' ]
})

export class SubmitMissingInfoV2Component implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('mediaDocumentUpload') mediaDocumentUpload:MediaUploadComponent;
  @ViewChild('videoComponent') videoUpload:MediaUploadComponent;
  token: string = '';
  caseGuid: string = ''; 
  caseDetails: any = {};
  alreadySubmitted: boolean = false;
  platform = inject(Platform);
  domainId: any = '';
  requestedSteps = [];
  requestedStepsDTO = {
    'photos': {
      text: this.translationService.instant('upload_vehicle_photos'),
      subStep: [],
      stepperTitle: 'Photos',
      reviewScreenText: 'Photos',
      step: 'photo',
      completed: false
    },
    'documents': {
      text: this.translationService.instant('personal_documents_you_need_to_upload'),
      subStep: [],
      stepperTitle: 'Documents',
      reviewScreenText: 'Documents',
      step: 'document',
      completed: false
    },
    'video': {
      text: this.translationService.instant('capture_video_of_your_vehicle'),
      stepperTitle: 'Video',
      reviewScreenText: '360 Video',
      step: 'video',
      completed: false
    },
    'review': {
      text: this.translationService.instant('review_your_details_and_submit'),
      stepperTitle: 'Review',
      reviewScreenText: '',
      step: 'review',
      completed: false
    }
  }
  customerName: string = '';
  customerMobileNo: string = '';
  showStepperScreen: boolean = false;
  public isSubmitted: boolean = false;
  public documentOptions = {
    isQuoteAssessmentModule: true
  };
  public inspectionItemTemplates:any;
  private inspectionTemplateId:number;
  private unsubscriber : Subject<void> = new Subject<void>();
  private imageFilters:any;
  private inspectionId:number;
  public steps:UploadStepModel[] = [];
  public objectId:number;
  public documentSteps:UploadStepModel[]= [];
  public missingDocumentSubSteps:any[];
  public activeDocumentStepIndex = 0;
  public activeChildDocumentStepIndex = 0;
  public activeDocumentSteps:UploadStepModel[] = [];
  public videoInspectionItemTemplate:UploadStepModel;
  subscription: Subscription[] = [];
  settings: any;

  /**
     * constructor
     */
  constructor(private claimService: ClaimsService,
    private vehicleService: VehicleService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private quoteManagementService: QuoteManagementService,
    private monitorService: MonitorService,
    private inspectionService: InspectionService,
    private translationService: TranslateService,
    private dialog:MatDialog,
    private configurationService:ConfigurationService,
    private renderer: Renderer2
  ) {
    this.settings = localStorage.getItem('languageSettings');
  }

  /**
   * ngondestory
   */
  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.unsubscriber.unsubscribe();
    this.subscription.forEach((element) => {
      element.unsubscribe()
    });
  }

  /**
     * Get missing info from api
     * comment noonInit
     * This function is called when the component is initialized. It calls the getMissingInfo
     * function from the ClaimsService to get the missing information from the API.
     * The response is logged in the console for debugging purposes.
     */
  async ngOnInit(): Promise<void> {
    
    if(this.platform.IOS || this.platform.ANDROID){
      history.pushState(null, '', location.href);
      history.back();
      history.forward();
      fromEvent(window, 'popstate').pipe(
        takeUntil(this.unsubscriber)
      ).subscribe((_) => {
        history.pushState(null, '', location.href);
        history.go(1);
      });
    }
    
    const token = this.route.snapshot.queryParams['token'];
    this.token = token;

    if (this.settings !== null && this.settings !== undefined && this.settings !== 'undefined') {
      this.commonService.showLoading();
      const languageSettings = JSON.parse(this.settings);
      const selectedLanguage = languageSettings.selectedLanguage;
      if (selectedLanguage) {
        if (selectedLanguage.resourceData) {
          this.configurationService.setTranslation(selectedLanguage.resourceData);
          this.commonService.hideLoading();
        } else {
          const remoteLanguageData = await this.configurationService.getTranslation();
          this.configurationService.setTranslation(remoteLanguageData);
          this.commonService.hideLoading();
        }
        const dir = selectedLanguage === 'ar' ? 'rtl' : 'ltr';
        this.renderer.setAttribute(document.body, 'dir', dir);
      } else {
        this.commonService.hideLoading();
        this.configurationService.setTranslation({});
      }
    }
    const status = localStorage.getItem(this.token);
    if(status === 'submitted'){
      this.isSubmitted = true;
      return
    }
    const obj: any = atob(token);
    const parsedObj = JSON.parse(obj);
    this.caseGuid = parsedObj.CaseGuid;
    this.commonService.showLoading();
    forkJoin([
      this.quoteManagementService.getCaseDetail(this.caseGuid),
      this.quoteManagementService.getMissingInfo(this.caseGuid, parsedObj.DomainId)
    ]
    ).pipe(
      map(([ caseDetails, missingInfoResp ]) => {
        return { caseDetails, missingInfoResp };
      })
    ).subscribe((result) => {
      this.caseDetails = result.caseDetails?.data || {};
      if(this.caseDetails?.driverDetails?.firstName) {
        this.customerName = this.caseDetails?.driverDetails?.firstName
      }
      if(this.caseDetails?.driverDetails?.lastName) {
        if(this.customerName) this.customerName += ' '
        this.customerName += this.caseDetails?.driverDetails?.lastName
      }

      if(this.caseDetails?.driverDetails?.countryCode) {
        this.customerMobileNo = this.caseDetails?.driverDetails?.countryCode
      }
      if(this.caseDetails?.driverDetails?.phoneNumber) {
        this.customerMobileNo += this.caseDetails?.driverDetails?.phoneNumber
      }
      const status = this.caseDetails?.caseStatus?.toLowerCase();
      if (status === 'customersubmitted' || status === 'inreview' || status === 'completed') {
        this.alreadySubmitted = true;
      } else {
        this.alreadySubmitted = false;
      }
      const { missingFieldsDTO, objectId } = result.missingInfoResp.data;
      this.objectId = objectId;
      this.domainId = this.caseDetails?.domainID;
      missingFieldsDTO?.forEach((field: any) => {
        if (field.key === 'Documents' && field.values?.length > 0) {
          this.requestedSteps.push({
            ...this.requestedStepsDTO.documents,
            subStep: field.values
          });
          this.missingDocumentSubSteps = field.values;
        }
        if (field.key === 'Photos' && field.values?.length > 0) {
          this.inspectionId = this.caseDetails?.inspectionId;
          const photosOnly = field.values.filter((video: any) => video.name?.toLowerCase() !== 'video');
          this.imageFilters = field.values.map((x: any) => x.name);
          if(photosOnly.length > 0) {
            this.requestedSteps.push({
              ...this.requestedStepsDTO.photos,
              subStep: photosOnly
            })
          }
          const video = field.values.find((video: any) => video.name?.toLowerCase() === 'video');
          if (video) {
            this.requestedSteps.push({
              ...this.requestedStepsDTO.video,
            })
          }
        }
      });
      this.requestedSteps.push({
        ...this.requestedStepsDTO.review,
      });
      const hasVideoOrImage = this.requestedSteps.filter(x=>x.step === 'video' || x.step === 'photo');
      if(hasVideoOrImage?.length > 0){
        this.loadApiData(this.inspectionId);
      }else{
        this.commonService.hideLoading();
      }
    });

    const updateDiscrepancyFlag = this.quoteManagementService.updateDiscrepancyFlag$.subscribe((updateDiscrepancy) => {
      if(updateDiscrepancy) {
        this.quoteManagementService.updateDiscrepancy(this.caseDetails)
      }
    });
    this.subscription.push(updateDiscrepancyFlag);
  }

  /**
  * popstate
  */
  @HostListener('window:popstate', [ '$event' ])
  onPopState(event:any):void {
    console.log(event);
    history.pushState(null, '', location.href);
    event.preventDefault();
    this.handleBackAction();
  }

  /**
   * get extensions
   */
  get activeDocumentExtensions():string{
    if(typeof this.activeDocumentSteps[this.activeChildDocumentStepIndex]?.allowExtension === 'string'){
      return this.activeDocumentSteps[this.activeChildDocumentStepIndex]?.allowExtension?.toString()?.replace(/,/g, ' ')?.replace(/\./g, '');
    }
    return this.activeDocumentSteps[this.activeChildDocumentStepIndex]?.allowExtension?.join('')?.replace(/,/g, ' ')?.replace(/\./g, '');
  }

  /**
   * gotoStepper
   */
  goToStepper():void{
    this.showStepperScreen = true;
    const documentStep = this.requestedSteps.find(x=>x.step?.toLowerCase() === 'document');
    if(documentStep){
      this.commonService.showLoading();
    }
    
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10); 
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    setTimeout(()=>{
      document.body.style.overflow = 'hidden';
    })
  }

  /**
   * handleActiveDocumentChange
   */
  handleActiveDocumentChange($event):void{
    this.activeChildDocumentStepIndex++; 
    if($event){
      $event.forEach((element) => {
        const index = this.documentSteps.findIndex(x=>x.stepName === element.stepName);
        this.documentSteps[index] = element;
      });
    } 
  }

  /**
   * on document load
   */
  onDocumentLoad(event:any):void{
    this.commonService.hideLoading();
    this.documentSteps = event.steps;
    const currentStep = this.missingDocumentSubSteps[this.activeDocumentStepIndex];
    this.activeDocumentSteps = this.documentSteps.filter(x=>x.stepName.includes(currentStep.name));
  }

  /**
   * update document status
   * @param status document status
   * @param currentStep current step details
   * @param err error response
   * @param responseData response data
   * @param isBulk is bulk upload
   */
  updateUploadStatus(status: string, currentStep: UploadStepModel, err?: any, responseData?: any, module?:string): void {
    this.monitorService.logEvent('updateUploadStatus', [ 'SubmitMissing', 'xa', {
      status,
      currentStep,
      responseData
    } ]);
  
    if (status == 'failed') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadSuccess = false;
      currentStep.isUploadFailed = true;
      currentStep.imageFile = null;
      currentStep.image = null;
  
    } else if (status == 'success') {
      currentStep.uploadInProgress = false;
      currentStep.isSkipped = false;
      currentStep.isUploadFailed = false;
      currentStep.isUploadSuccess = true;
      currentStep.uploadStatus = 'success';
      currentStep.updatedDate = dayjs().toISOString();
      currentStep.uploadedDate = dayjs().toISOString();
      currentStep.isAdditionalDocumentFromReviewPageOnly = false;
      if (module === 'image') {
        currentStep.inspectionItem = responseData;
      }
  
      if (responseData) {
        if (currentStep.originalName) {
          currentStep.stepName = currentStep.originalName;
        }
  
        currentStep.uploadedGuid = responseData.mediaGuid;
        currentStep.uploadedPartGuid = responseData.mediaPartGuid;
      }  
    }      
  }

  /**
   * upload video
   * @param currentStep 
   */
  uploadFile(currentStep: UploadStepModel, module:string, index?:number, timeStamp?:string): void {
    const uploadObject:any = {
      'height': currentStep.imageHeight,
      'width': currentStep.imageWidth,
      'inspectionId': this.inspectionId,
      'templateId': currentStep.inspectionItem?.inspectionItemTemplateID,
      'inspectionitemId': currentStep.inspectionItem.id,
      'type': '',
      'isBulk': false
    }

    if(timeStamp){
      uploadObject['framesInfo'] = timeStamp;
    }
    
    this.monitorService.logEvent('uploadFile', [ 'ChqNewPhotosComponent', 'addenda-quote', {
      uploadObject
    } ]);

    currentStep.uploadInProgress = true;
    currentStep.isSkipped = false;
    currentStep.isUploadFailed = false;
    currentStep.isUploadSuccess = false;
    this.commonService.showNotification(`${currentStep.stepName} ${this.translationService.instant('notify_uploaded')}`);
    if(module === 'video'){
      this.commonService.showLoading();
    }
   
    this.inspectionService.uploadImageBulk(uploadObject, currentStep.imageFile).subscribe({
      next: (data: any) => {
        this.commonService.hideLoading()
        currentStep.uploadInProgress = false;
        currentStep.isUploadSuccess = true;
        currentStep.uploadStatus = 'success';
        currentStep.url = data.rawImage?.rawAzureBlobUrl;
        this.updateUploadStatus('success', currentStep, null, data, module);
        const msg = this.translationService.instant('step_upload_success', { stepName: currentStep.stepName })
        this.commonService.showNotification(msg);
        if(module === 'video'){
          this.nextStep(index);
          this.videoUpload.localVideoUrl = '';
        }
        this.quoteManagementService.updateDiscrepancyFlag$.next(true);
      },
      error: (err: any) => {
        this.commonService.hideLoading()
        if (err.status != 401) {
          const msg = this.translationService.instant('step_upload_error', { stepName: currentStep.stepName });
          this.commonService.showNotification(msg);
          currentStep.uploadInProgress = false;
          currentStep.isUploadFailed = true;
          currentStep.isUploadSuccess = false;
          this.updateUploadStatus('failed', currentStep, err, null, module);
        }
      },
    })
  }

  /**
   * handle back
   */
  handleBackAction():void{
    if(this.stepper.selectedIndex === 0){
      if(this.activeDocumentStepIndex ===0){
        this.showStepperScreen = false;
      }else{
        this.activeDocumentStepIndex--;
        const currentStep = this.missingDocumentSubSteps[this.activeDocumentStepIndex];
        this.activeChildDocumentStepIndex = 0;
        this.activeDocumentSteps = this.documentSteps.filter(x=>x.stepName.includes(currentStep.name));
        setTimeout(()=>{
          this.mediaDocumentUpload?.resetFiles();
        });
      }
   
    }else{
      this.stepper.previous();
    }

  }

  /**
     * update deleted image
     */
  deleteSelectedImage(currentStep: UploadStepModel): void {
  
    currentStep.contentType = '';
    currentStep.url = '';
    currentStep.uploadedGuid = '';
    currentStep.updatedDate = '';
    currentStep.uploadedDate = '';
    currentStep.uploadInProgress = false;
    currentStep.isSkipped = false;
    currentStep.isUploadSuccess = false;
    currentStep.size = null;
    currentStep.extension = '';
    currentStep.uploadedPartGuid = '';
    currentStep.isUploadFailed = false;
    currentStep.imageFile = null;
    currentStep.image = null;
    currentStep.thumbnailUrl = null;
    currentStep.uploadStatus = 'notUploaded';
  }

  /**
   *  delete image
   */
  deleteImage(uploadStepModel: UploadStepModel): void {
    this.commonService.showLoading();

    if (uploadStepModel.inspectionItem?.id) {
      this.inspectionService.deleteInspection(uploadStepModel.inspectionItem.id).subscribe((resp: any) => {
        if (uploadStepModel.inspectionItem) {
          this.deleteSelectedImage(uploadStepModel);
        } else {
          const index = uploadStepModel.index;
          this.deleteSelectedImage(this.steps[index]);
        }
        this.quoteManagementService.updateDiscrepancyFlag$.next(true);
        this.commonService.hideLoading();
      }, () => {
        this.commonService.hideLoading();
      });
    } else {
      this.commonService.hideLoading();
    }
  }

  /**
   * show delete confirmation
   */
  showDeleteConfirmation(uploadStepModel: UploadStepModel, module:string): void {
    this.commonService.openDeleteDialog((module == 'image') ? 'photo' : module, '')
      .afterClosed().subscribe((data) => {
        if (data) {
          if(uploadStepModel.url){
            this.deleteImage(uploadStepModel);
          }
        }
      });
  }

  /**
     * Opens a dialog with the media content.
     * @param url the media url
     */
  openVideoInstruction():void{
    this.dialog.open(VideoInfoDialogComponent, {
      data: {
        vin: this.caseDetails?.vehicleMetaData?.vinNum
      }, 
      autoFocus: false,
    }).afterClosed().subscribe();
  }

  /**
   * handle image upload
   */
  handleImageChange(event:any, index:number):void{
    if(event.action === 'add'){
      event.step.imageFile = event.file;
      this.uploadFile(event.step, 'image', index);
    }else{
      this.showDeleteConfirmation(event.step, 'image');
    }
  }

  /**
   * handle video file change
   * @param event 
   */
  handleFileChange(event: any, index:number): void {
    if(event.action === 'add'){
      this.videoInspectionItemTemplate.imageFile = event.file;
      const timeStamp = event.timeStamp;
      this.uploadFile(this.videoInspectionItemTemplate, 'video', index, timeStamp);
    }else{
      this.showDeleteConfirmation(this.videoInspectionItemTemplate, 'video');
    }

  }

  /**
   * side names
   */
  get sideCount():string{
    return `${this.steps?.filter(x=>!x.stepName?.toLowerCase().includes('video') && !x.stepName?.toLowerCase().includes('chassis') && !x.stepName?.toLowerCase().includes('mileage'))?.length}`;
  }

  /**
   * side names
   */
  get sideNames():string{
    return `(${this.steps?.filter(x=>!x.stepName?.toLowerCase().includes('video')).map(x=>x.stepName)?.join(',')})`;
  }

  /**
     * map inspection from api
     * @param inspectionData 
     */
  mapInspection(inspectionData: any, inspectionTemplates:any): void {
    if (inspectionData) {
      try {

        inspectionTemplates?.map((chqInspectionStep: ChqInspectionStep) => {
          const item = inspectionData.inspectionItems?.find((el: any) => el.inspectionItemTemplateID == chqInspectionStep.id);
          if (item) {
            chqInspectionStep['inspectionItem'] = item;
          }
        });
        this.inspectionItemTemplates = inspectionTemplates?.filter(step => step.name != 'Bulk Upload Image');
        this.inspectionTemplateId = inspectionTemplates[0].id;
  
        this.inspectionItemTemplates?.map((x: ChqInspectionStep) => {
          if (x.inspectionItem?.rawAzureBlobUrl) {
            x.isUploadSuccess = true;
            x.image = x.inspectionItem?.rawAzureBlobUrl;
            x.isLocked = x.inspectionItem?.isLocked;
          } else {
            if (!x.inspectionItem) {
              x.inspectionItem = { rawAzureBlobUrl: '' };
            }
            x.inspectionItem.rawAzureBlobUrl = '';
            x.isUploadFailed = false;
            x.isUploadSuccess = false;
            x.uploadInProgress = false;
          }
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  /**
     * inspection detail by id
     */
  getInspectionTemplateByObject(): void {
    const files:UploadStepModel[] = [];
    for (let i = 0; i < this.inspectionItemTemplates?.length; i++) {
      const templatePart = this.inspectionItemTemplates[i];
      const stepExists = this.imageFilters?.find(x=>x.toLowerCase() === templatePart.name?.toLowerCase());
      if(!stepExists){
        continue;
      }
      const inspectionStep = inspectionpartPartMapper(templatePart, i);
      const isAdditionalImage = inspectionStep.stepName?.toLowerCase().indexOf('additional photo') != -1;
      const isMassUpload = inspectionStep.stepName?.toLowerCase().indexOf('bulk upload image') != -1;
      const isAdditionalDocumentFromReviewPageOnly = isAdditionalImage && !inspectionStep.url;
      const step = {
        ...inspectionStep, mandatory: !templatePart.isSkipEnabled, index: i,
        isAdditionalDocumentFromReviewPageOnly,
        isMassUpload,
        updatedDate: inspectionStep.url ? templatePart?.inspectionItem?.updatedDate : '',
        uploadedDate: inspectionStep.url ? templatePart?.inspectionItem?.created : '',
        domainId: this.domainId, objectId: this.caseDetails.id,
        uploadedPartGuid: '',
        uploadedGuid: ''
      };
      files.push(step);
    }
    this.steps = files?.filter(step => step.stepName !== 'Video');
    this.videoInspectionItemTemplate = files?.find(step => step.stepName === 'Video');

  }

  /**
   * has value
   */
  hasValue(name:string, module:string):boolean{
    let steps = [];
    if(module === 'photo'){
      steps = this.steps;
    }else if(module === 'document'){
      steps = this.documentSteps;
    }else{
      const url = this.videoInspectionItemTemplate?.url;
      if(url){
        return true;
      }
    }
    const selectedStep = steps.find(x=>x.stepName?.toLowerCase().includes(name?.toLowerCase()));
    return selectedStep && selectedStep.url !== '' && selectedStep.url !== null && selectedStep.url !== undefined;
  }

  /**
     * @description
     * load server details
     *
     * @param id: number, id of the case or repair
     */
  loadApiData(id: number): void {
    if (id && id != 0) {
      this.inspectionService.getInspectionTemplateList().subscribe({
        next: (inspectionList: any) => {
          let inspectionTemplateId = 0;
          const repairInspectionTemplate = inspectionList?.data;
  
          if (repairInspectionTemplate?.length > 0) {
            this.commonService.repairInspectionTemplate = repairInspectionTemplate[0];
            inspectionTemplateId = repairInspectionTemplate[0].id
          }
          const promiseApi: any = {};
          promiseApi.inspectionTemplate = this.inspectionService.getInspectionTemplate(inspectionTemplateId);
          const serverDetails = forkJoin(promiseApi).pipe(
            catchError(error => of(error))
          )
          serverDetails.subscribe({
            next: (resultMap: any) => {
              if (resultMap.inspectionTemplate) {
                this.inspectionService.getInspection(id).subscribe({
                  next: (inspectionDetailResponse: any) => {
                    if (inspectionDetailResponse) {
                      if(inspectionDetailResponse.uploadType && inspectionDetailResponse.uploadType?.toLowerCase() !== 'spi'){
                        this.requestedSteps = this.requestedSteps.filter(x=>x.step?.toLowerCase() !== 'photo');
                      }
                      this.mapInspection(inspectionDetailResponse, resultMap?.inspectionTemplate?.inspectionItemTemplates);
                      this.getInspectionTemplateByObject();
                    }
                  },
                  error: ()=>{
                    this.commonService.hideLoading();
                  },
                  complete: () => {
                    this.commonService.hideLoading();
                  },
                });
  
                 
              }else{
                this.commonService.hideLoading();
              }
            }
          });
        }, error: ()=>{
          this.commonService.hideLoading();
        }
      });
  
    }
  }

  /**
     * submit form
     */
  submitForm():void{
    // TODO add required validation
    this.commonService.showLoading();
    this.quoteManagementService.updateCaseStatus({
      Status: CASE_STATUS.CustomerSubmitted
    }, this.caseGuid).subscribe({
      next: (result: any) => {
        this.commonService.hideLoading();
        if(result) {
          this.alreadySubmitted = true;
          this.showStepperScreen = false;
          if(result) {
            this.isSubmitted = true;
            localStorage.setItem(this.token, 'submitted');
          }
        }
      },
      error: (err: any) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err);
      }
    });
  }

  /**
   * handle document next
   */
  handleDocumentNext(index:number):void{
    if(this.activeDocumentStepIndex < (this.missingDocumentSubSteps.length -1)){
      this.activeDocumentStepIndex ++;
      const currentStep = this.missingDocumentSubSteps[this.activeDocumentStepIndex];
      this.activeChildDocumentStepIndex = 0;
      this.activeDocumentSteps = this.documentSteps.filter(x=>x.stepName.includes(currentStep.name));
      setTimeout(()=>{
        this.mediaDocumentUpload?.resetFiles();
      });
    }else{
      this.nextStep(index);
    }
  }

  /**
   * handle skip
   */
  handleSkip():void{
    if(this.activeChildDocumentStepIndex === (this.activeDocumentSteps?.length-1)){
      this.mediaDocumentUpload.files = this.mediaDocumentUpload.steps;
    }else{
      this.activeChildDocumentStepIndex++;
      this.mediaDocumentUpload?.setFile(this.activeChildDocumentStepIndex);
    }
  }

  /**
   * activeStep
   */
  get activeStep():string{
    const index = this.stepper?.selectedIndex;
    if(this.requestedSteps){
      return this.requestedSteps[index]?.step;
    }else{
      return '';
    }
   
  }

  /**
   * handle video skip
   */
  handleVideoSkip():void{
    this.nextStep(this.stepper?.selectedIndex);
  }

  /**
   * go to step
   */
  goToStep($event:number):void{
    this.stepper.selectedIndex = $event;
  }

  /**
     * go to next step
     * @param $event 
     */
  nextStep($event: number): void {
    this.requestedSteps[$event].completed = true;
    setTimeout(()=>{
      this.stepper?.next();
    });
  }
}

import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
declare let require: any;


@NgModule()
export class IconsModule {
  /**
   * constructor
   * @param iconRegistry
   * @param sanitizer
   */
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('login', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/login.svg')));
    iconRegistry.addSvgIconLiteral('logout', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/logout.svg')));
    iconRegistry.addSvgIconLiteral('search', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/search.svg')));
    iconRegistry.addSvgIconLiteral('plus-active', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-active.svg')));
    iconRegistry.addSvgIconLiteral('plus-filled', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-filled.svg')));
    iconRegistry.addSvgIconLiteral('plus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus.svg')));
    iconRegistry.addSvgIconLiteral('plus-white', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-white.svg')));
    iconRegistry.addSvgIconLiteral('minus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/minus.svg')));
    iconRegistry.addSvgIconLiteral('minus-white', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/minus-white.svg')));
    iconRegistry.addSvgIconLiteral('dropdown', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/down-arrow.svg')));
    iconRegistry.addSvgIconLiteral('dropdown-up', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/up-arrow.svg')));
    iconRegistry.addSvgIconLiteral('check', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/check.svg')));
    iconRegistry.addSvgIconLiteral('refresh', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/refresh.svg')));
    iconRegistry.addSvgIconLiteral('date', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/date.svg')));
    iconRegistry.addSvgIconLiteral('delete', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/delete.svg')));
    iconRegistry.addSvgIconLiteral('search', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/search.svg')));
    iconRegistry.addSvgIconLiteral('round-arrow-left', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/round-arrow-left.svg')));
    iconRegistry.addSvgIconLiteral('round-arrow-right', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/round-arrow-right.svg')));
    iconRegistry.addSvgIconLiteral('sms', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/sms.svg')));
    iconRegistry.addSvgIconLiteral('arrow-right', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/arrow-right.svg')));
    iconRegistry.addSvgIconLiteral('arrow-search-right', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/arrow-search-right.svg')));
    iconRegistry.addSvgIconLiteral('arrow-left', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/arrow-left.svg')));
    iconRegistry.addSvgIconLiteral('arrow-back-left', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/arrow-back-left.svg')));
    iconRegistry.addSvgIconLiteral('double-arrow-right', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/double-arrow-right.svg')));
    iconRegistry.addSvgIconLiteral('double-arrow-left', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/double-arrow-left.svg')));
    iconRegistry.addSvgIconLiteral('cases', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/cases.svg')));
    iconRegistry.addSvgIconLiteral('plus-active', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-active.svg')));
    iconRegistry.addSvgIconLiteral('checked', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/checked.svg')));
    iconRegistry.addSvgIconLiteral('unchecked', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/unchecked.svg')));
    iconRegistry.addSvgIconLiteral('edit', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/edit.svg')));
    iconRegistry.addSvgIconLiteral('i', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/i.svg')));
    iconRegistry.addSvgIconLiteral('i-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/i-circle.svg')));
    iconRegistry.addSvgIconLiteral('infos', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/infos.svg')));
    iconRegistry.addSvgIconLiteral('upload', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/upload.svg')));
    iconRegistry.addSvgIconLiteral('no-data', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/no-data.svg')));
    iconRegistry.addSvgIconLiteral('upload-dark', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/upload-dark.svg')));
    iconRegistry.addSvgIconLiteral('credit-card', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/credit-card.svg')));
    iconRegistry.addSvgIconLiteral('renew', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/retakenew.svg')));
    iconRegistry.addSvgIconLiteral('boardview', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/boardview.svg')));
    iconRegistry.addSvgIconLiteral('review', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/review.svg')));
    iconRegistry.addSvgIconLiteral('camera', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/camera.svg')));
    iconRegistry.addSvgIconLiteral('gobackward', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/gobackward.svg')));
    iconRegistry.addSvgIconLiteral('indicator', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/indicator.svg')));
    iconRegistry.addSvgIconLiteral('indicatorBlack', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/indicator-black.svg')));
    iconRegistry.addSvgIconLiteral('gobackward', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/gobackward.svg')));
    iconRegistry.addSvgIconLiteral('folderplus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/folder-plus.svg')));
    iconRegistry.addSvgIconLiteral('eye', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/eye.svg')));
    iconRegistry.addSvgIconLiteral('cross', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/cross.svg')));
    iconRegistry.addSvgIconLiteral('folder-plus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/folder-plus.svg')));
    iconRegistry.addSvgIconLiteral('download-arrow', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/download-arrow.svg')));
    iconRegistry.addSvgIconLiteral('call', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/call.svg')));
    iconRegistry.addSvgIconLiteral('mail', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/mail.svg')));
    iconRegistry.addSvgIconLiteral('pdf', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/pdf.svg')));
    iconRegistry.addSvgIconLiteral('back', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/back.svg')));
    iconRegistry.addSvgIconLiteral('chip', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/chip.svg')));
    iconRegistry.addSvgIconLiteral('clock', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock.svg')));
    iconRegistry.addSvgIconLiteral('clock-new', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock_new.svg')));
    iconRegistry.addSvgIconLiteral('xmark', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/xmark.svg')));
    iconRegistry.addSvgIconLiteral('invoices', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/invoices.svg')));
    iconRegistry.addSvgIconLiteral('settings', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/settings.svg')));
    iconRegistry.addSvgIconLiteral('settings-outline', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/settings-outline.svg')));
    iconRegistry.addSvgIconLiteral('info', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/info.svg')));
    iconRegistry.addSvgIconLiteral('flag-yellow', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-yellow.svg')));
    iconRegistry.addSvgIconLiteral('flag-blue', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-blue.svg')));
    iconRegistry.addSvgIconLiteral('flag-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-circle.svg')));
    iconRegistry.addSvgIconLiteral('check-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/check-circle.svg')));
    iconRegistry.addSvgIconLiteral('customer', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/customer.svg')));
    iconRegistry.addSvgIconLiteral('approximate', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/approximate.svg')));
    iconRegistry.addSvgIconLiteral('quote', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/quote.svg')));
    iconRegistry.addSvgIconLiteral('repair', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair.svg')));
    iconRegistry.addSvgIconLiteral('vehicle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vehicle.svg')));
    iconRegistry.addSvgIconLiteral('calculate', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/calculate.svg')));
    iconRegistry.addSvgIconLiteral('calendar', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/calendar.svg')));
    iconRegistry.addSvgIconLiteral('calendar-color', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/calendar-color.svg')));
    iconRegistry.addSvgIconLiteral('book-calendar', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/booking-calendar.svg')));
    iconRegistry.addSvgIconLiteral('view', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/view.svg')));
    iconRegistry.addSvgIconLiteral('home', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/home.svg')));
    iconRegistry.addSvgIconLiteral('comment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/comment.svg')));
    iconRegistry.addSvgIconLiteral('comment-new', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/comment-new.svg')));
    iconRegistry.addSvgIconLiteral('dashboard', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/dashboard.svg')));
    iconRegistry.addSvgIconLiteral('parts', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts.svg')));
    iconRegistry.addSvgIconLiteral('filter', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/filter.svg')));
    iconRegistry.addSvgIconLiteral('assign', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/assign.svg')));
    iconRegistry.addSvgIconLiteral('show', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/show.svg')));
    iconRegistry.addSvgIconLiteral('send', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/send.svg')));
    iconRegistry.addSvgIconLiteral('vehicle-parts', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vehicle-parts.svg')));
    iconRegistry.addSvgIconLiteral('bolt-car', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/bolt-car.svg')));
    iconRegistry.addSvgIconLiteral('house', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/house.svg')));
    iconRegistry.addSvgIconLiteral('magazine', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/magazine.svg')));
    iconRegistry.addSvgIconLiteral('person-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person-circle.svg')));
    iconRegistry.addSvgIconLiteral('car', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car.svg')));
    iconRegistry.addSvgIconLiteral('paper-plane', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/paper-plane.svg')));
    iconRegistry.addSvgIconLiteral('tire', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tire.svg')));
    iconRegistry.addSvgIconLiteral('tires', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tire.svg')));
    iconRegistry.addSvgIconLiteral('mechanical', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/mechanical.svg')));
    iconRegistry.addSvgIconLiteral('diagnosis', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/diagnosis.svg')));
    iconRegistry.addSvgIconLiteral('gears', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/gears.svg')));
    iconRegistry.addSvgIconLiteral('paintbrush', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/paintbrush.svg')));
    iconRegistry.addSvgIconLiteral('checkbox-open', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/checkbox-open.svg')));
    iconRegistry.addSvgIconLiteral('door-panel', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/door-panel.svg')));
    iconRegistry.addSvgIconLiteral('car-roof', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-roof.svg')));
    iconRegistry.addSvgIconLiteral('paint-nozzle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/paint-nozzle.svg')));
    iconRegistry.addSvgIconLiteral('car-paint', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-paint.svg')));
    iconRegistry.addSvgIconLiteral('car-cleaning', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-cleaning.svg')));
    iconRegistry.addSvgIconLiteral('box-truck', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/box-truck.svg')));
    iconRegistry.addSvgIconLiteral('box-truck-cross', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/box-truck-cross.svg')));
    iconRegistry.addSvgIconLiteral('box-truck-plus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/box-truck-plus.svg')));
    iconRegistry.addSvgIconLiteral('box-truck-check', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/box-truck-check.svg')));
    iconRegistry.addSvgIconLiteral('dollarsign-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/dollarsign-circle.svg')));
    iconRegistry.addSvgIconLiteral('dollarsign-circle-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/dollarsign-circle-gray.svg')));
    iconRegistry.addSvgIconLiteral('clock2', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock2.svg')));
    iconRegistry.addSvgIconLiteral('multi-users', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/multi-users.svg')));
    iconRegistry.addSvgIconLiteral('single-user', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/single-user.svg')));
    iconRegistry.addSvgIconLiteral('list-bullet', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/list-bullet.svg')));
    iconRegistry.addSvgIconLiteral('grid', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/grid.svg')));
    iconRegistry.addSvgIconLiteral('car-door', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-door.svg')));
    iconRegistry.addSvgIconLiteral('paint', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/paint.svg')));
    iconRegistry.addSvgIconLiteral('maintenance', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/maintenance.svg')));
    iconRegistry.addSvgIconLiteral('maintainance', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/maintenance.svg')));
    iconRegistry.addSvgIconLiteral('tools', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tools.svg')));
    iconRegistry.addSvgIconLiteral('serviceman-left', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/serviceman-left.svg')));
    iconRegistry.addSvgIconLiteral('serviceman-right', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/serviceman-right.svg')));
    iconRegistry.addSvgIconLiteral('contact', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/contact.svg')));
    iconRegistry.addSvgIconLiteral('booking-type-repair', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/booking-type-repair.svg')));
    iconRegistry.addSvgIconLiteral('booking-type-assessment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/booking-type-assessment.svg')));
    iconRegistry.addSvgIconLiteral('stage', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/stage.svg')));
    iconRegistry.addSvgIconLiteral('layer', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/layer.svg')));
    iconRegistry.addSvgIconLiteral('send-request', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/send-request.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp.svg')));
    iconRegistry.addSvgIconLiteral('email', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/email.svg')));
    iconRegistry.addSvgIconLiteral('estimate-no-data', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-no-data.svg')));
    iconRegistry.addSvgIconLiteral('person-add', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person-add.svg')));
    iconRegistry.addSvgIconLiteral('body', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/body.svg')));
    iconRegistry.addSvgIconLiteral('electric', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/electric.svg')));
    iconRegistry.addSvgIconLiteral('electrical', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/electric.svg')));
    iconRegistry.addSvgIconLiteral('others', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/others.svg')));
    iconRegistry.addSvgIconLiteral('tyres', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tyres.svg')));
    iconRegistry.addSvgIconLiteral('folder-open', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/folder_open.svg')));
    iconRegistry.addSvgIconLiteral('report-check', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/report-check.svg')));
    iconRegistry.addSvgIconLiteral('attachment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/attachment.svg')));
    iconRegistry.addSvgIconLiteral('list', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/list.svg')));
    iconRegistry.addSvgIconLiteral('microbe-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/microbe.circle.svg')));
    iconRegistry.addSvgIconLiteral('briefcase-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/briefcase.circle.svg')));
    iconRegistry.addSvgIconLiteral('trash', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/trash.svg')));
    iconRegistry.addSvgIconLiteral('start', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/start.svg')));
    iconRegistry.addSvgIconLiteral('pause', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/pause.svg')));
    iconRegistry.addSvgIconLiteral('stop', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/stop.svg')));
    iconRegistry.addSvgIconLiteral('attachments', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/attachments.svg')));
    iconRegistry.addSvgIconLiteral('left-arrow', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/left-arrow.svg')));
    iconRegistry.addSvgIconLiteral('save', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/save.svg')));
    iconRegistry.addSvgIconLiteral('attachment-reference', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/attachment-reference.svg')));
    iconRegistry.addSvgIconLiteral('bell', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/bell.svg')));
    iconRegistry.addSvgIconLiteral('parts-ordered', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts-ordered.svg')));
    iconRegistry.addSvgIconLiteral('parts-arrived-partial', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts-arrived-partial.svg')));
    iconRegistry.addSvgIconLiteral('parts-arrived', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts-arrived.svg')));
    iconRegistry.addSvgIconLiteral('parts-ordered-partial', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts-ordered-partial.svg')));
    iconRegistry.addSvgIconLiteral('parts-not-ordered', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/parts-not-ordered.svg')));
    iconRegistry.addSvgIconLiteral('towing', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/towing.svg')));
    iconRegistry.addSvgIconLiteral('colorpicker', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/colorpicker.svg')));
    iconRegistry.addSvgIconLiteral('double-database', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/double-database.svg')));
    iconRegistry.addSvgIconLiteral('bookings', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/bookings.svg')));
    iconRegistry.addSvgIconLiteral('collected', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/collected.svg')));
    iconRegistry.addSvgIconLiteral('in-progress', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/in-progress.svg')));
    iconRegistry.addSvgIconLiteral('total', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/total.svg')));
    iconRegistry.addSvgIconLiteral('email-provider', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/email-provider.svg')));
    iconRegistry.addSvgIconLiteral('fullscreen', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/fullscreen.svg')));
    iconRegistry.addSvgIconLiteral('information-operation', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/information-operation.svg')));
    iconRegistry.addSvgIconLiteral('pdf-file', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/pdf-file.svg')));
    iconRegistry.addSvgIconLiteral('checkbox-checked', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/checkbox-checked.svg')));
    iconRegistry.addSvgIconLiteral('checkbox-unchecked', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/checkbox-unchecked.svg')));
    iconRegistry.addSvgIconLiteral('claim', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/claim.svg')));
    iconRegistry.addSvgIconLiteral('viewFile', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/viewFile.svg')));
    iconRegistry.addSvgIconLiteral('location', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/location.svg')));
    iconRegistry.addSvgIconLiteral('share', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/share.svg')));
    iconRegistry.addSvgIconLiteral('view-outlined', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/view-outlined.svg')));
    iconRegistry.addSvgIconLiteral('fraud-detection', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/fraud-detection.svg')));
    iconRegistry.addSvgIconLiteral('save-checked', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/save-checked.svg')));
    iconRegistry.addSvgIconLiteral('ai', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/ai.svg')));
    iconRegistry.addSvgIconLiteral('ellipse', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/ellipse.svg')));
    iconRegistry.addSvgIconLiteral('pdf-review', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/pdf-review.svg')));
    iconRegistry.addSvgIconLiteral('document-search', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/document-search.svg')));
    iconRegistry.addSvgIconLiteral('internal-garage', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/internal-garage.svg')));
    iconRegistry.addSvgIconLiteral('registered-garage', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/registered-garage.svg')));
    iconRegistry.addSvgIconLiteral('unregistered-garages', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/unregistered-garages.svg')));

    iconRegistry.addSvgIconLiteral('horizontal-alignment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/horizontal-alignment.svg')));
    iconRegistry.addSvgIconLiteral('vertical-alignment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vertical-alignment.svg')));
    iconRegistry.addSvgIconLiteral('xa-logo-white', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/xa-logo-white.svg')));

    iconRegistry.addSvgIconLiteral('peoples', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/peoples.svg')));
    iconRegistry.addSvgIconLiteral('passives', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/passives.svg')));
    iconRegistry.addSvgIconLiteral('promoters', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/promoters.svg')));

    iconRegistry.addSvgIconLiteral('drag-menu', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/drag-menu.svg')));
    iconRegistry.addSvgIconLiteral('feedback', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/feedback.svg')));
    iconRegistry.addSvgIconLiteral('drag-menu', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/drag-menu.svg')));
    iconRegistry.addSvgIconLiteral('setting-round-fnol', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/setting-round.svg')));
    iconRegistry.addSvgIconLiteral('skate-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/skate-round.svg')));
    iconRegistry.addSvgIconLiteral('file', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/file.svg')));
    iconRegistry.addSvgIconLiteral('filter-dashboard', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/filter_dashboard.svg')));
    iconRegistry.addSvgIconLiteral('file-claim', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/file_claim.svg')));
    iconRegistry.addSvgIconLiteral('carvana', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/carvana.svg')));
    iconRegistry.addSvgIconLiteral('warning', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/alert-triangle.svg')));
    iconRegistry.addSvgIconLiteral('fraud-detect-positive', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/fraud-detect-positive.svg')));
    iconRegistry.addSvgIconLiteral('replay', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/replay.svg')));
    iconRegistry.addSvgIconLiteral('view-info', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/view-info.svg')));
    iconRegistry.addSvgIconLiteral('disable', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/disable.svg')));
    iconRegistry.addSvgIconLiteral('rating-sad', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/rating-sad.svg')));
    iconRegistry.addSvgIconLiteral('hint', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/hint.svg')));
    iconRegistry.addSvgIconLiteral('edit-notes', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/edit-notes.svg')));
    iconRegistry.addSvgIconLiteral('check-circle-white', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/check-circle-white.svg')));
    iconRegistry.addSvgIconLiteral('person-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person-green.svg')));
    iconRegistry.addSvgIconLiteral('person-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person-gray.svg')));
    iconRegistry.addSvgIconLiteral('truck-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/truck-gray.svg')));
    iconRegistry.addSvgIconLiteral('truck-white', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/truck-white.svg')));
    iconRegistry.addSvgIconLiteral('truck-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/truck-green.svg')));
    iconRegistry.addSvgIconLiteral('truck-orange', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/truck-orange.svg')));
    iconRegistry.addSvgIconLiteral('truck-blue', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/truck-blue.svg')));
    iconRegistry.addSvgIconLiteral('car-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-green.svg')));
    iconRegistry.addSvgIconLiteral('car-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-gray.svg')));
    iconRegistry.addSvgIconLiteral('clock-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock-green.svg')));
    iconRegistry.addSvgIconLiteral('clock-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock-gray.svg')));
    iconRegistry.addSvgIconLiteral('clock-red', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock-red.svg')));
    iconRegistry.addSvgIconLiteral('tow-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tow-green.svg')));
    iconRegistry.addSvgIconLiteral('tow-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tow-gray.svg')));
    iconRegistry.addSvgIconLiteral('flag-red', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-red.svg')));
    iconRegistry.addSvgIconLiteral('flag-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-gray.svg')));
    iconRegistry.addSvgIconLiteral('clock3', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock3.svg')));
    iconRegistry.addSvgIconLiteral('compact-view', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/compact-view.svg')));
    iconRegistry.addSvgIconLiteral('detail-view', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/detail-view.svg')));
    iconRegistry.addSvgIconLiteral('person', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person.svg')));
    iconRegistry.addSvgIconLiteral('vendor', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vendor.svg')));
    iconRegistry.addSvgIconLiteral('estimate-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-green.svg')));
    iconRegistry.addSvgIconLiteral('estimate-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-gray.svg')));
    iconRegistry.addSvgIconLiteral('estimate-blue', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-blue.svg')));
    iconRegistry.addSvgIconLiteral('estimate-orange', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-orange.svg')));
    iconRegistry.addSvgIconLiteral('estimate-red', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/estimate-red.svg')));
    iconRegistry.addSvgIconLiteral('photos-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/photos-green.svg')));
    iconRegistry.addSvgIconLiteral('photos-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/photos-gray.svg')));
    iconRegistry.addSvgIconLiteral('detail-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/detail-green.svg')));
    iconRegistry.addSvgIconLiteral('detail-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/detail-gray.svg')));
    iconRegistry.addSvgIconLiteral('document-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/document-green.svg')));
    iconRegistry.addSvgIconLiteral('document-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/document-gray.svg')));
    iconRegistry.addSvgIconLiteral('vehicle-location-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vehicle-location-green.svg')));
    iconRegistry.addSvgIconLiteral('vehicle-location-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vehicle-location-gray.svg')));
    iconRegistry.addSvgIconLiteral('carhealicon', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/carhealicon.svg')));
    iconRegistry.addSvgIconLiteral('messaging', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/messaging.svg')));
    iconRegistry.addSvgIconLiteral('add', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/add.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-sending', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-sending.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-send', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-send.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-delivered', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-delivered.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-read', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-read.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-failed', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-failed.svg')));
    iconRegistry.addSvgIconLiteral('car-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-round.svg')));
    iconRegistry.addSvgIconLiteral('paint-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/paint-round.svg')));
    iconRegistry.addSvgIconLiteral('maintenance-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/maintenance-round.svg')));
    iconRegistry.addSvgIconLiteral('gears-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/gears-round.svg')));
    iconRegistry.addSvgIconLiteral('electric-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/electric-round.svg')));
    iconRegistry.addSvgIconLiteral('mechanical-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/mechanical-round.svg')));
    iconRegistry.addSvgIconLiteral('diagnosis-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/diagnosis-round.svg')));
    iconRegistry.addSvgIconLiteral('tire-round', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tire-round.svg')));
    iconRegistry.addSvgIconLiteral('whatsapp-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/whatsapp-green.svg')));
    iconRegistry.addSvgIconLiteral('t', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/t.svg')));
    iconRegistry.addSvgIconLiteral('color-picker', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/color-picker.svg')));
    iconRegistry.addSvgIconLiteral('person-tech', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/person-tech.svg')));
    iconRegistry.addSvgIconLiteral('team', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/team.svg')));
    iconRegistry.addSvgIconLiteral('t.a.t', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/t.a.t.svg')));
    iconRegistry.addSvgIconLiteral('repair-body', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-body.svg')));
    iconRegistry.addSvgIconLiteral('repair-maintenance', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-maintenance.svg')));
    iconRegistry.addSvgIconLiteral('repair-others', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-others.svg')));
    iconRegistry.addSvgIconLiteral('repair-electrical', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-electrical.svg')));
    iconRegistry.addSvgIconLiteral('repair-paint', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-paint.svg')));
    iconRegistry.addSvgIconLiteral('repair-diagnosis', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-diagnosis.svg')));
    iconRegistry.addSvgIconLiteral('repair-tyres', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-tyres.svg')));
    iconRegistry.addSvgIconLiteral('repair-mechanical', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/repair-mechanical.svg')));
    iconRegistry.addSvgIconLiteral('holiday', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/holiday.svg')));
    iconRegistry.addSvgIconLiteral('auto-assign', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/auto-assign.svg')));
    iconRegistry.addSvgIconLiteral('completed', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/completed.svg')));
    iconRegistry.addSvgIconLiteral('workload', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/workload.svg')));
    iconRegistry.addSvgIconLiteral('productivity', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/productivity.svg')));
    iconRegistry.addSvgIconLiteral('utilization', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/utilization.svg')));
    iconRegistry.addSvgIconLiteral('efficiency', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/efficiency.svg')));
    iconRegistry.addSvgIconLiteral('menu-expand', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/menu-expand.svg')));
    iconRegistry.addSvgIconLiteral('menu-collapse', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/menu-collapse.svg')));
    iconRegistry.addSvgIconLiteral('nauseated-face', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/nauseated-face.svg')));
    iconRegistry.addSvgIconLiteral('confused-face', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/confused-face.svg')));
    iconRegistry.addSvgIconLiteral('neutral-face', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/neutral-face.svg')));
    iconRegistry.addSvgIconLiteral('smiling-eyes-face', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/smiling-eyes-face.svg')));
    iconRegistry.addSvgIconLiteral('heart-eyes-face', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/heart-eyes-face.svg')));
    iconRegistry.addSvgIconLiteral('driving-license', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/driving-license.svg')));
    iconRegistry.addSvgIconLiteral('registration-card', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/registration-card.svg')));
    iconRegistry.addSvgIconLiteral('car-photo', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-photo.svg')));
    iconRegistry.addSvgIconLiteral('police-report', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/police-report.svg')));
    iconRegistry.addSvgIconLiteral('upload-doc', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/upload-doc.svg')));
    iconRegistry.addSvgIconLiteral('info-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/info-circle.svg')));
    iconRegistry.addSvgIconLiteral('link', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/link.svg')));
    iconRegistry.addSvgIconLiteral('completed-gray', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/completed-gray.svg')));
    iconRegistry.addSvgIconLiteral('column', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/column.svg')));
    iconRegistry.addSvgIconLiteral('calendar-empty', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/calendar-empty.svg')));
    iconRegistry.addSvgIconLiteral('snooze', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/snooze.svg')));
    iconRegistry.addSvgIconLiteral('check-half-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/check-half-circle.svg')));
    iconRegistry.addSvgIconLiteral('clock-blue', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock-blue.svg')));
    iconRegistry.addSvgIconLiteral('task-start', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-start.svg')));
    iconRegistry.addSvgIconLiteral('task-pause', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-pause.svg')));
    iconRegistry.addSvgIconLiteral('task-stop', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-stop.svg')));
    iconRegistry.addSvgIconLiteral('task-image', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-image.svg')));
    iconRegistry.addSvgIconLiteral('car-no-data', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-no-data.svg')));
    iconRegistry.addSvgIconLiteral('car-del', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/car-del.svg')));
    iconRegistry.addSvgIconLiteral('tools-wrench', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/tools-wrench.svg')));
    iconRegistry.addSvgIconLiteral('unavailable', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/unavailable.svg')));
    iconRegistry.addSvgIconLiteral('ai-estimate', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/ai-estimate.svg')));
    iconRegistry.addSvgIconLiteral('lock', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/lock.svg')));
    iconRegistry.addSvgIconLiteral('thumb-up', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/thumb-up.svg')));
    iconRegistry.addSvgIconLiteral('thumb-down', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/thumb-down.svg')));
    iconRegistry.addSvgIconLiteral('not-sure', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/not-sure.svg')));
    iconRegistry.addSvgIconLiteral('steering', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/steering.svg')));
    iconRegistry.addSvgIconLiteral('ac', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/ac.svg')));
    iconRegistry.addSvgIconLiteral('wheels', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/wheels.svg')));
    iconRegistry.addSvgIconLiteral('engine', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/engine.svg')));
    iconRegistry.addSvgIconLiteral('gear-box', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/gear-box.svg')));
    iconRegistry.addSvgIconLiteral('swap', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/swap.svg')));
    iconRegistry.addSvgIconLiteral('clock4', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock4.svg')));
    iconRegistry.addSvgIconLiteral('dollar', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/dollar.svg')));
    iconRegistry.addSvgIconLiteral('dot-menu', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/dot-menu.svg')));
    iconRegistry.addSvgIconLiteral('calculate2', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/calculate2.svg')));
    iconRegistry.addSvgIconLiteral('expand', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/expand.svg')));
    iconRegistry.addSvgIconLiteral('shutter', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/shutter.svg')));
    iconRegistry.addSvgIconLiteral('noun-rocket', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/noun-rocket.svg')));
    iconRegistry.addSvgIconLiteral('noun-rocket-upsale', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/noun-rocket-upsale.svg')));
    iconRegistry.addSvgIconLiteral('flag-green', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-green.svg')));
    iconRegistry.addSvgIconLiteral('flag-red-single', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/flag-red-single.svg')));
    iconRegistry.addSvgIconLiteral('case-submit-success', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/case-submit-success.svg')));
    iconRegistry.addSvgIconLiteral('play', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/play.svg')));
    iconRegistry.addSvgIconLiteral('report-flag', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/report-flag.svg')));
    iconRegistry.addSvgIconLiteral('quote-assessment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/quote-assessment.svg')));
    iconRegistry.addSvgIconLiteral('expand-preview', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/expand-preview.svg')));
    iconRegistry.addSvgIconLiteral('fraud-detection-v2', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/fraud-detection-v2.svg')));
    iconRegistry.addSvgIconLiteral('arrow-down-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/arrow-down-circle.svg')));
    iconRegistry.addSvgIconLiteral('information-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/information-circle.svg')));
    iconRegistry.addSvgIconLiteral('view-1', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/view-1.svg')));
    iconRegistry.addSvgIconLiteral('view-2', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/view-2.svg')));
    iconRegistry.addSvgIconLiteral('total-request', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/total-request.svg')));
    iconRegistry.addSvgIconLiteral('incomplete-request', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/incomplete-request.svg')));
    iconRegistry.addSvgIconLiteral('complete-request', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/complete-request.svg')));
    iconRegistry.addSvgIconLiteral('clock-with-circle', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/clock-with-circle.svg')));
    iconRegistry.addSvgIconLiteral('filter-icon', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/filter-icon.svg')));
    iconRegistry.addSvgIconLiteral('enlarge', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/enlarge.svg')));
    iconRegistry.addSvgIconLiteral('task-start-alt', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-start-alt.svg')));
    iconRegistry.addSvgIconLiteral('task-stop-alt', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-stop-alt.svg')));
    iconRegistry.addSvgIconLiteral('vin-no-data', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/vin-no-data.svg')));
    iconRegistry.addSvgIconLiteral('list-search', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/list-search.svg')));
    iconRegistry.addSvgIconLiteral('eye-fraud', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/eye-fraud.svg')));
    iconRegistry.addSvgIconLiteral('ai-inference', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/ai-inference.svg')));
    iconRegistry.addSvgIconLiteral('attention', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/attention.svg')));
    iconRegistry.addSvgIconLiteral('plus-blue', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-blue.svg')));
    iconRegistry.addSvgIconLiteral('case-submit-info-thank-you', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/case-submit-info-thank-you.svg')));
    iconRegistry.addSvgIconLiteral('left-arrow-round-filled', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/left-arrow-round-filled.svg')));
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/upload-icon.svg')));
    iconRegistry.addSvgIconLiteral('home-plus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/home-plus.svg')));
    iconRegistry.addSvgIconLiteral('add-circle-outline', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/add-circle-outline.svg')));
    iconRegistry.addSvgIconLiteral('inventory', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/inventory.svg')));
    iconRegistry.addSvgIconLiteral('group-dot', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/group-dot.svg')))
    iconRegistry.addSvgIconLiteral('refresh-ccw', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/refresh-ccw.svg')));
    iconRegistry.addSvgIconLiteral('lock-outline', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/lock-outline.svg')));
    iconRegistry.addSvgIconLiteral('plus-square', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-square.svg')));
    iconRegistry.addSvgIconLiteral('minus-square', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/minus-square.svg')));
    iconRegistry.addSvgIconLiteral('plus-outline', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/plus-outline.svg')));
    iconRegistry.addSvgIconLiteral('task-alt', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/task-alt.svg')));
    iconRegistry.addSvgIconLiteral('zoom-in', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/zoom-in.svg')));
    iconRegistry.addSvgIconLiteral('zoom-out', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/zoom-out.svg')));
    iconRegistry.addSvgIconLiteral('text-send', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/text-send.svg')));
    iconRegistry.addSvgIconLiteral('more-vertical', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/more-vertical.svg')));
    iconRegistry.addSvgIconLiteral('inventory-plus', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/inventory-plus.svg')));
    iconRegistry.addSvgIconLiteral('file-with-attachment', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/file-with-attachment.svg')));
    iconRegistry.addSvgIconLiteral('group-dot', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/group-dot.svg')));
    iconRegistry.addSvgIconLiteral('zoom-in', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/zoom-in.svg')));
    iconRegistry.addSvgIconLiteral('zoom-out', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/zoom-out.svg')));
    iconRegistry.addSvgIconLiteral('refresh-ccw', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/refresh-ccw.svg')));
    iconRegistry.addSvgIconLiteral('text-send', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/text-send.svg')));
    iconRegistry.addSvgIconLiteral('magnify-expand', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/magnify-expand.svg')));
    iconRegistry.addSvgIconLiteral('download-inventory', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/download-inventory.svg')));
    iconRegistry.addSvgIconLiteral('upload-inventory', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/upload-inventory.svg')));

    iconRegistry.addSvgIconLiteral('file-document-outline', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/file-document-outline.svg')));
    iconRegistry.addSvgIconLiteral('submit-success', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/submit-success.svg')));
    iconRegistry.addSvgIconLiteral('add-business', sanitizer.bypassSecurityTrustHtml(require('!svg-inline-loader!../assets/icons/add-business.svg')));
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-existing-claim-dialog',
  templateUrl: './existing-claim-dialog.component.html',
  styleUrls: [ './existing-claim-dialog.component.scss' ]
})
export class ExistingClaimDialogComponent {
  public yesButton: ChqWidgetsButtonModel = {
    label: 'yes',
    type: 'primary',
    onclick: this.handleYes.bind(this)
  }

  public noButton: ChqWidgetsButtonModel = {
    label: 'no',
    type: 'outline',
    onclick: this.handleNo.bind(this)
  }

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExistingClaimDialogComponent>,
    private monitorService: MonitorService,
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {
  }

  /**
   * handle clicked on No
   */
  handleNo(): void {
    this.monitorService.logEvent('handleNo', [ 'ExistingClaimDialogComponent', 'addenda-claim' ]);
    this.dialogRef.close(false);
  }

  /**
   * handle clicked on Yes
   */
  handleYes(): void {
    this.monitorService.logEvent('handleYes', [ 'ExistingClaimDialogComponent', 'addenda-claim' ]);
    this.dialogRef.close(true);
  }

  /**
   * handle split for existing claim number
   */
  splitExistingClaimData(ind:number):string {
    return this.translateService.instant('existing_claim').replace('{{policeReferenceNo}}', this?.data?.policeReferenceNo).split('{{existingClaim}}')[ind]
  }

  /**
   * handle split for existing claim number
   */
  getURLToRedirect():string {
    return `claim/claim-detail-v2/${encodeURIComponent(this.data.policeReferenceNo)}/${encodeURIComponent(this.data.intimationNo)}/details`
  }

  /**
   * close
   */
  close(): void {
    this.dialogRef.close();
  }

}

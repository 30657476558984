import { Component, OnInit, ViewChild } from '@angular/core';
import { AddEstimatePartForm, newEstimatePartForm, partsMenu } from 'src/app/helper/form/estimate-part.helper';
import { EstimateOperationFromDataModel } from 'src/app/model/chq-estimate-operation-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { IControlValue } from 'src/app/model/chq-widgets-input-model';
import { ChqInputComponent } from 'src/app/widgets/chq-input/chq-input.component';

@Component({
  selector: 'app-part-add',
  templateUrl: './part-add.component.html',
  styleUrls: [ './part-add.component.scss' ]
})
export class PartAddComponent implements OnInit {
  @ViewChild('operationType') operationType: ChqInputComponent;
  public formModel?: AddEstimatePartForm;

  public formData: EstimateOperationFromDataModel = {
    operationType: '',
    operation: '',
    code: '',
    repairType: '',
    quantity: 0,
    price: 0,
    labourTime: 0,
    labourAmount: 0,
    //saveOperation: false
  };

  public addButtonModel: ChqWidgetsButtonModel = {
    label: 'add',
    type: 'disabled',
    icon: '',
    onclick: this.submitForm.bind(this)
  }

  public resetButtonModel: ChqWidgetsButtonModel = {
    label: 'reset',
    type: 'outline',
    icon: '',
    onclick: this.resetForm.bind(this)
  }

  menuItems = partsMenu;

  /**
   * on init
   */
  ngOnInit(): void {
    this.formModel = newEstimatePartForm();
  }
  /**
    * submitForm
  */
  submitForm(): void {
    // if(!this.isOperationFormValid()) {
    //   return ;
    // }
  }

  /**
    * resetForm
  */
  resetForm(isResetOpeartionType = true): void {
    // if (true) {
    //   //this.populateFormData();
    //   //this.setInitialStateOfForm();
    //   //this.onObservableLoadRepairTypes(this.formModel.repairType.options)  
    // } else {
    //   if (isResetOpeartionType) {
    //     //this.setInitialStateOfForm()
    //   }

    //   // Reset code
    //   this.formModel.code = { ...this.formModel.code, value: '' }
    //   this.formData.code = ''

    //   //Reset operation
    //   this.formModel.operation = { ...this.formModel.operation, value: '' }
    //   this.formData.operation = ''

    //   //repairType
    //   this.formModel.repairType = { ...this.formModel.repairType, selectedOption: [] }
    //   this.formData.repairType = ''

    //   //information
    //   this.formModel.information = { ...this.formModel.information, value: '' }
    //   this.formData.information = ''

    //   //quantity
    //   if (this.formModel.quantity) {
    //     this.formModel.quantity = { ...this.formModel.quantity, value: 0 }
    //     this.formData.quantity = 0
    //   }

    //   //price
    //   if (this.formModel.price) {
    //     this.formModel.price = { ...this.formModel.price, value: 0 }
    //     this.formData.price = 0
    //   }

    //   //labourTime
    //   if (this.formModel.labourTime) {
    //     this.formModel.labourTime = { ...this.formModel.labourTime, value: 0 }
    //     this.formData.labourTime = 0
    //   }

    //   //labourAmount
    //   if (this.formModel.labourAmount) {
    //     this.formModel.labourAmount = { ...this.formModel.labourAmount, value: 0 }
    //     this.formData.labourAmount = 0
    //   }
    // }
  }

  /**
   * hide dropdown
   */
  hideDropdown(name: any): void {
    if (name === this.formModel.operationType.name) {
      this.operationType?.closeDropdown();
    }
  }

  /**
     * on form update
     */
  onFormUpdate(output: IControlValue): void {
    //todo
  }
}

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { Subscription, catchError, filter, forkJoin, map, of, pairwise, throttleTime } from 'rxjs';
import { QUOTE_MENU } from 'src/app/config/configuration/configuration.constant';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { ChatNotificationComponent } from 'src/app/dialogs/chat-notification/chat-notification.component';
import { HasPermissionDirective } from 'src/app/helper/directive/has-permission/has-permission.directive';
import { menuItem, menuItems, partialUrlsMatch } from 'src/app/model/menu';
import { ChqCasesTableComponent } from 'src/app/modules/quote/chq-cases/chq-cases-table/chq-cases-table.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MessagesService } from 'src/app/services/message/message.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SignalRService } from 'src/app/services/signalr/signalr.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { ClaimAssignModalComponent } from '../claim-assign-modal/claim-assign-modal.component';
import { RepairIntimationRequestComponent } from 'src/app/dialogs/repair-intimation-request/repair-intimation-request.component';
import { TranslateService } from '@ngx-translate/core';
dayjs.extend(relativeTime);
dayjs.extend(isToday);

const caseIndexMapper = {
  'draft': 1,
  'vehicledetails': 2,
  'uploaddocuments': 3,
  'uploadphotos': 4,
  'aianalysis': 4,
  'repairestimation': 5,
  'approved': 6,
  'rejected': 6,
  'closed': 6,
  'complete': 6
}

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements AfterViewInit, OnDestroy, OnInit {
  title: string = 'title';
  subTitle: string = 'subtitle';
  icon: string = 'home';
  @ViewChild('notificationsMenuTrigger') menuTrigger: MatMenuTrigger;
  @Input() avatarText: string = '';
  @Input() avatar: string | ArrayBuffer;
  @Output() searchEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;
  partialUrlsMatch = partialUrlsMatch;
  showSearchDetail: boolean = false;
  showSearchBar: boolean = false;
  searchResultString = '';
  public showNoAccess: boolean = false;
  inspectionId: number;
  searchPlaceHolder: string = 'repair_general_search_msg';

  caseId: string = '';
  caseStatus: string = '';
  stepInProgress: boolean = false;
  menuItems: menuItem[] = menuItems;
  tabMenus: menuItem[] = [];

  @Output() toggle = new EventEmitter();
  public menuIcon: string = 'menu-expand';
  public expandedMenu: boolean = true;

  public allTabMenus = [
    {
      name: 'Add customer',
      active: false,
      title: 'add_customer',
      subTitle: 'quote',
      url: '',
      index: 1
    },
    {
      name: 'Add vehicle',
      active: false,
      title: 'add_vehicle',
      url: 'vehicle',
      subTitle: 'quote',
      index: 2
    },
    {
      name: 'Add documents',
      active: false,
      title: 'add_documents',
      subTitle: 'quote',
      url: 'document',
      index: 3
    },
    {
      name: 'Add photos',
      active: false,
      title: 'add_photos',
      subTitle: 'quote',
      url: 'photos',
      index: 4
    },
    {
      name: 'Repair estimate',
      active: false,
      title: 'repair_estimate',
      subTitle: 'quote',
      url: 'estimate',
      index: 5
    }
  ];
  showTabMenus: boolean = false;
  notificationsPerScroll: number = 50;
  skipCounter: number = 0;
  notificationLoading$;
  notificationUnreadCount: number = 0;
  notificationList = []
  notificationGuidList = []
  notificationTotalCount = 0;
  automotiveServiceId: number = 0;
  domainIds: any[] = [];
  subs: Subscription[] = [];
  hasRepairPermission: boolean;
  hasClaimPermission: boolean;
  hasQuotePermission: boolean;
  hasFnolPermission: boolean;
  hasQuoteAssessmentPermission: boolean;
  tempUnreadNotificationsCheck: string[] = [];
  ConversationGuid: string;

  caseDetail: any;
  isExternal: boolean = false;
  public userPermission: any;
  notificationsPerScrollInitialized: boolean = false;
  public whatsAppEnabled: boolean = true;
  generalCommunicationMessagesCount: number = 0;

  @ViewChild('messaging') public messaging: MatSidenav;

  @ViewChildren(HasPermissionDirective) permissionDirective;

  public selectedLang: string = 'en';

  config: any = {
    name: 'singleSelect',
    label: '',
    placeHolder: 'select_dot',
    labelKey: 'Name',
    valueKey: 'Code'
  }

  options: Array<any> = [
    { Code: 'ar', Name: 'AR', Direction: 'rtl', Locale: 'ar-AE' },
    { Code: 'en', Name: 'EN', Direction: 'ltr', Locale: 'en' }
  ];


  /**
   * constructor
   * @param router
   * @param authService
   * @param commonService
   * @param coreService
   * @param monitorService
   */
  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly messagesService: MessagesService,
    private readonly monitorService: MonitorService,
    private readonly inspectionService: InspectionService,
    private readonly userService: UserService,
    private readonly signalR: SignalRService,
    private readonly _snackBar: MatSnackBar,
    private readonly configurationService: ConfigurationService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly translateService: TranslateService
  ) {
    this.notificationLoading$ = this.userService.notificationLoading$;
    this.signalR.hubReceivedMessage.subscribe((data: any) => {
      if (data?.messageType === 'Notification') {
        this.notificationList = []
        this.notificationGuidList = [];
        this.getNotificationMessages(0);
        this.getNotificationUnreadCount();
      }

      if ([ 'bodyshop manager', 'service advisor' ].includes(this.commonService.roleName) && data?.messageType === 'BidirectionalNotification') {
        const signalRData = JSON.parse(data.message);

        if (signalRData.BidirectionalConversation.IsCustomerResponse && this.router.url.indexOf(signalRData.ObjectGuid) < 0) {
          signalRData.isWhatsApp = true;
          this.showWhatsappToast(10000, signalRData);
          this.getGeneralCommunicationMessagesCount();
        }
      }

    })
    this.subs.push(this.commonService.userProfileData.subscribe({
      next: (data) => {
        if (data) {
          data.data.userPermission?.automotiveServices.forEach((item) => {
            if (item.automotiveServiceName === 'Addenda Repair' 
              || item.automotiveServiceName === 'Carheal Quote' 
              || item.automotiveServiceName === 'Addenda Claims' 
              || item.automotiveServiceName === 'Quote Assessment'
              || item.automotiveServiceName === 'Addenda Inventory') {
              this.automotiveServiceId = item.automotiveServiceId;
              this.domainIds.push(item.domainId);
            }
          })

          const automotiveServices = data.data?.userPermission?.automotiveServices;
          const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
          const quoteMenus = this.menuItems?.find(x => x.name === 'quote')?.childMenu;
          if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
            this.isExternal = this.commonService.hasPermission('case.external', permissionList);
          }

          const { userPermission } = data.data;

          const automotiveService = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');
          this.commonService.roleName = automotiveService?.roleName?.toLowerCase();

          this.userPermission = userPermission;
          this.commonService.setPermission(automotiveServices);
          this.hasRepairPermission = this.commonService.repairPermission;
          this.hasClaimPermission = this.commonService.claimPermission;
          this.hasQuotePermission = this.commonService.quotePermission;
          this.hasFnolPermission = this.commonService.fnolPermission;
          this.hasQuoteAssessmentPermission = this.commonService.quoteAssessmentPermission;

          let isRepairerView = false;
          if (this.router.url.indexOf('/repairer-view/') > -1) {
            isRepairerView = true;
          }

          if (this.hasRepairPermission || isRepairerView) {
            this.searchPlaceHolder = 'repair_general_search_msg';
          }


          if (this.hasClaimPermission && !isRepairerView) {
            this.searchPlaceHolder = 'claim_general_search_msg';
          }

          const packages = data?.data?.userPermission?.orgPackages;
          const channels = packages?.find(x => x.description === 'Communication Channel');
          if (channels) {
            const whatsappPermission = channels.permissions.find(x => x.permissionName === 'whatsApp');
            if (whatsappPermission) {
              this.whatsAppEnabled = true;
            } else {
              this.whatsAppEnabled = false;
            }
          } else {
            this.whatsAppEnabled = false;
          }

        }
      }
    }));
    this.subs.push(this.userService.notificationSource$.subscribe({
      next: (data: any) => {
        if (data && data['@odata.count'] > 0) {
          this.notificationTotalCount = data['@odata.count']
          if (this.notificationTotalCount === 0 && this.skipCounter !== 0) {
            this.skipCounter -= this.notificationsPerScroll;
          }
          if (this.notificationTotalCount !== 0) {
            const dataRemovedDuplicates = data['value']?.filter(notification => this.notificationGuidList.indexOf(notification.NotificationGuid) == -1)
              .map((item) => {
                return { ...item, isShowActionButton: this.isShowActionButton(item), actionButtonText: this.actionButtonText(item) }
              });
            this.notificationList = [ ...this.notificationList, ...dataRemovedDuplicates ];
            this.notificationGuidList = [ ...this.notificationGuidList, ...dataRemovedDuplicates.map(notification => notification.NotificationGuid) ]
          }
        }
      }
    }));

    this.commonService.accessRight.subscribe({
      next: (res: boolean) => {
        if (!res) {
          this.showNoAccess = true;
        }
      }
    });

    this.commonService.closeSearch.subscribe({
      next: (closeSearch: boolean) => {
        if (closeSearch) {
          this.close();
        }
      }
    })

    this.commonService.inProgressSteps.subscribe((length: number) => {
      this.stepInProgress = length > 0;
    });

    this.commonService.caseId.subscribe((id) => {
      if (id) {
        this.caseId = id;
      }
    });

    this.commonService.inspectionId.subscribe((inspectionId) => {
      if (inspectionId) {
        this.inspectionId = inspectionId;
      }
    });

    this.commonService.caseStatus.subscribe((caseStatus) => {
      if (caseStatus) {
        this.monitorService.logEvent('caseStatus', [ 'HeaderComponent', 'addenda-quote', {
          caseStatus
        } ]);
        this.caseStatus = caseStatus;
      }
    });

    this.subs.push(this.commonService.pageChange$.subscribe((menuRoutes: string[]) => {
      if (this.showTabMenus && menuRoutes[1]) {
        const subMenuItem = this.tabMenus?.find(x => x.url === menuRoutes[1]);
        if (subMenuItem) {
          subMenuItem.active = true;
          this.title = subMenuItem.title;
          this.subTitle = subMenuItem.subTitle;
          return;
        }
      } else if (this.tabMenus[0]) {
        this.tabMenus[0].active = true;
      }
    }));

    this.router?.events
      ?.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((event: any) => {
        const caseId = this.route?.firstChild.snapshot.params['id'];
        this.monitorService.logEvent('router.navigationStart', [ 'HeaderComponent', 'addenda-quote', {
          event
        } ]);

        if (event.navigationTrigger === 'popstate' && event.url === 'quote/case/0' && caseId) {
          this.router.navigate([ `quote/case/${caseId}` ], { queryParams: { isBack: true }, replaceUrl: true });
        }

        const url = (event.url == '/') ? '/home' : event.url;

        if (url.indexOf('/quote/case/') != -1) {
          this.showTabMenus = true;
        } else {
          this.showTabMenus = false;
        }
      });

    this.router.events
      ?.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        window.scrollTo(0, 0);
        let url = (event.url == '/') ? '/home' : event.url;

        if (url === '/accessDenied') {
          this.title = 'access_denied';
          this.subTitle = null;
          this.icon = null;
          return;
        } else if (url === '/notFound') {
          this.title = 'not_found';
          this.subTitle = null;
          this.icon = null;
          return;
        } else if (url === '/profile') {
          this.title = 'my_profile';
          this.subTitle = 'home';
          this.icon = 'home';

          sessionStorage.setItem('pagenumber', '1');
          ChqCasesTableComponent.currentPage = 1;
          ChqCasesTableComponent.filterDetail = [];
          return;
        } else if (url.includes('/fnol')) {
          const partialUrlMatchData = this.partialUrlsMatch.filter(item => url.indexOf(item.partialUrl) != -1)
          if (partialUrlMatchData.length > 0 && partialUrlMatchData[0].title) {
            this.title = partialUrlMatchData[0].title;
            this.subTitle = partialUrlMatchData[0].subTitle;
            this.icon = partialUrlMatchData[0].icon;
            this.commonService.updatePage(this.title, this.subTitle);
            return;
          }
          return;
        } else if (url.includes('/case/detail/')) {
          return
        } else if (url === '/repair/check-in-out-logs') {
          this.title = this.translateService.instant('check_in_check_out_logs');
          this.subTitle = this.translateService.instant('repair');
          this.icon = null;
          return
        } else if (url === '/settings/partners/add-supplier') {
          this.title = 'partners';
          this.subTitle = 'settings';
          this.icon = null;
          return
        } else {
          if (url.indexOf('?') !== -1) url = url.substring(0, url.lastIndexOf('?'));
          this.monitorService.logEvent('router.navigationEnd', [ 'HeaderComponent', 'addenda-quote', {
            event
          } ]);
          this.tabMenus.map(x => x.active = false);
          if (url.indexOf('/quote/case/') != -1) {
            this.showTabMenus = true;
          } else {
            this.showTabMenus = false;
          }

          const partialUrlMatchData = this.partialUrlsMatch.filter(item => url.indexOf(item.partialUrl) != -1)
          if (partialUrlMatchData.length > 0 && partialUrlMatchData[0].title) {
            this.title = partialUrlMatchData[0].title;
            this.subTitle = partialUrlMatchData[0].subTitle;
            this.icon = partialUrlMatchData[0].icon;
            this.commonService.updatePage(this.title, this.subTitle);
            return;
          }

          this.caseId = url.split('/quote/case/')[1] ? url.split('/quote/case/')[1].split('/')[0] : null;

          this.commonService.caseId.next(this.caseId);
          const menuItem = this.menuItems?.find(x => x.url === url);
          if (menuItem) {
            this.title = menuItem.title;
            this.subTitle = menuItem.subTitle;
            this.icon = menuItem.icon ?? 'home';
          } else {
            const menuItem = this.menuItems?.find((obj) => {
              return obj.childMenu?.some((child: menuItem) => child.url === url);
            });

            if (menuItem) {
              const childMenuItem = menuItem.childMenu?.find(x => x.url === url);
              if (childMenuItem) {
                this.title = childMenuItem.title;
                this.subTitle = childMenuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              }
            } else {
              if (url.toLowerCase().indexOf('/quote/case/') != -1) {
                const menuItem = this.menuItems?.find((obj) => {
                  return obj.childMenu?.some((child: menuItem) => child.url === '/quote/case/0');
                });
                if (menuItem) {
                  const childMenuItem = menuItem.childMenu?.find(x => x.url === '/quote/case/0');
                  if (childMenuItem) {
                    this.title = childMenuItem.title;
                    this.subTitle = childMenuItem.subTitle;
                    this.icon = menuItem.icon ?? 'home';
                  }
                }
              } else if (url === '/repair/booking/0') {
                const menuItem = this.menuItems?.find(x => x.url === '/repair');
                this.title = 'add_customer';
                this.subTitle = menuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              } else if (url.toLowerCase().indexOf('/repair/booking/') != -1) {
                const menuItem = this.menuItems?.find(x => x.url === '/repair');
                if (url.toLowerCase().indexOf('/parts') != -1 || url.toLowerCase().indexOf('/repair-stages') != -1 || url.toLowerCase().indexOf('/invoices') != -1) {
                  this.title = 'repair_details'
                } else {
                  this.title = 'booking_details';
                }
                this.subTitle = menuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              } else if (url.toLowerCase().indexOf('/repair/customers/') != -1) {
                const menuItem = this.menuItems?.find((obj) => {
                  return obj.childMenu?.some((child: menuItem) => child.url === '/repair/customers/company');
                });
                const childMenuItem = menuItem.childMenu?.find(x => x.url === '/repair/customers/company');
                this.title = childMenuItem.title;
                this.subTitle = childMenuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              } else if (
                url === '/settings/repair-type' ||
                url === '/settings/jobs-tasks' ||
                url === '/settings/scheduling' ||
                url === '/settings/calendar' ||
                url === '/settings/target'
              ) {
                const menuItem = this.menuItems?.find(x => x.url === '/settings');
                this.title = menuItem.title;
                this.subTitle = menuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              } else if (url.toLowerCase().indexOf('/claim/') != -1) {

                const menuItem = this.menuItems?.find(x => x.name === 'claim');

                this.title = 'claim';
                if (url.toLowerCase().indexOf('/claim-edit/') != -1) {
                  this.title = 'file_a_claim'
                  if (event instanceof NavigationEnd) {
                    const urlTree = this.router.parseUrl(event.urlAfterRedirects);
                    const queryParams = urlTree?.queryParams;

                    if (queryParams) {
                      if (queryParams['mode'] === 'edit') {
                        this.title = 'edit_claim';
                      }
                    }
                  }
                } else if (url.toLowerCase().indexOf('/estimate') != -1) {
                  this.title = 'my_claim'
                } else if (url === '/claim') {
                  this.title = menuItem.defaultChildMenu;
                } else if (url.toLowerCase().indexOf('/garages/') != -1) {
                  this.title = 'garages'
                } else if (url.toLowerCase().indexOf('customer-satisfaction') != -1) {
                  this.title = 'claim_dashboard'
                } else {
                  this.title = 'my_claim'
                }
                this.subTitle = menuItem.subTitle;
                this.icon = menuItem.icon ?? 'home';
              } else if (url.toLowerCase().indexOf('/repair/technician-calendar') != -1) {
                this.title = 'technician_calendar';
                this.subTitle = 'repair';
              } else if (url.toLowerCase().indexOf('/home/dashboard/') != -1) {
                this.title = 'dashboard';
                this.subTitle = 'home';
              } else if (url.toLowerCase().indexOf('repairer-view') != -1) { //repairer-view check
                if (url.toLowerCase().indexOf('dashboard/customers') != -1) {
                  const menuItem = this.menuItems?.find(x => x.url === '/repairer-view/dashboard/general');
                  this.title = menuItem.title
                  this.subTitle = menuItem.subTitle;
                  this.icon = menuItem.icon ?? 'home';
                } else if (url.toLowerCase().indexOf('/estimate') != -1) {
                  this.title = 'Repairer View'
                  this.subTitle = 'estimate';
                } else if (url.toLowerCase().indexOf('/photo') != -1) {
                  this.title = 'Repairer View'
                  this.subTitle = 'photos';
                } else if (url.toLowerCase().indexOf('/document') != -1) {
                  this.title = 'Repairer View'
                  this.subTitle = 'documents';
                } else {
                  this.title = 'Repairer View'
                  this.subTitle = 'customer';
                }
              } else if (
                url === '/settings/estimate/tax' ||
                url === '/settings/estimate/invoice'
              ) {
                const menuItem = this.menuItems.find(x => x.url === '/settings');
                if (menuItem) {
                  const childMenuItem = menuItem.childMenu?.find(x => x.url === '/settings/estimate/tax');
                  if (childMenuItem) {
                    this.title = childMenuItem.title;
                    this.subTitle = childMenuItem.subTitle;
                    this.icon = menuItem.icon ?? 'home';
                  }
                }
              } else {
                this.title = 'dashboard';
                this.subTitle = 'home';
                this.icon = 'home';
              }
            }
          }
        }

      });

    this.hasRepairPermission = this.commonService.repairPermission;
    this.hasClaimPermission = this.commonService.claimPermission;
    this.hasQuotePermission = this.commonService.quotePermission;
    this.hasFnolPermission = this.commonService.fnolPermission;

    let isRepairerView = false;
    if (this.router.url?.indexOf('/repairer-view/') > -1) {
      isRepairerView = true;
    }

    if (this.hasRepairPermission || isRepairerView) {
      this.searchPlaceHolder = 'repair_general_search_msg';
    }


    if (this.hasClaimPermission && !isRepairerView) {
      this.searchPlaceHolder = 'claim_general_search_msg';
    }

    const caseDetail = this.commonService.caseDetail.subscribe((caseDetail) => {
      this.caseDetail = caseDetail
    })

    this.subs.push(caseDetail);

    const openGeneralChat$ = this.commonService.generalCommOpen$.subscribe((event: string) => {
      if (event === 'open') {
        this.openChatWindow();
      }
    });

    this.subs.push(openGeneralChat$);

    const tabChangeEvent = this.commonService.tabChange$.subscribe((tab: menuItem) => {
      if (tab) {
        this.title = tab.title;
        this.subTitle = tab.subTitle;
      }
    })

    this.subs.push(tabChangeEvent);

    this.breakpointObserver.observe([
      '(max-width: 576px)'
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.expandedMenu = false;
        this.menuIcon = 'menu-collapse';
      } else {
        this.expandedMenu = true;
        this.menuIcon = 'menu-expand';
      }
    });

    const settings = localStorage.getItem('languageSettings');
    if (settings !== null && settings !== undefined && settings !== 'undefined') {
      const languageSettings = JSON.parse(settings);
      this.selectedLang = languageSettings.selectedLanguage;
      this.commonService.loadLocaleMessages(this.selectedLang);
    }
  }

  /**
   * get current case status
   */
  get caseStatusIndex(): number {
    return caseIndexMapper[this.caseStatus?.toLowerCase()]
  }


  /**
   * close search bar
   */
  close(): void {
    this.showSearchBar = false;
    this.searchEvent.emit(false);
    this.showSearchDetail = false;
  }

  /**
   * open chat window
   */
  openChatWindow(ConversationGuid?: string): void {
    if (!this.whatsAppEnabled) {
      this.commonService.openAlertDialog('information', 'upgrade_subscription');
      return;
    }
    this.ConversationGuid = ConversationGuid ?? null;
    this.messaging.toggle();
  }

  /**
   * load more
   */
  loadMorePages($event): void {
    this.searchResultString = $event.searchKey;
    this.showSearchBar = false;
    this.searchEvent.emit(true);
    this.showSearchDetail = true;

  }

  /**
   * open user profile
  */
  openProfile(): void {
    this.monitorService.logEvent('openProfile', [ 'HeaderComponent', 'addenda-quote' ]);
    this.router.navigate([ 'profile' ]);
  }

  /**
 * logout
 */
  logout(): void {
    this.monitorService.logEvent('logout', [ 'HeaderComponent', 'addenda-quote' ]);
    const dialogRef = this.commonService.openConfirmationOkDialog(this.translateService.instant('logout'), this.translateService.instant('want_logout'));

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const localData = localStorage.getItem('cid');
        if (localData && localData !== 'null' && localData.toUpperCase() === 'TATA') {
          this.commonService.getAccessToken().subscribe((res) => {
            const token = this.parseJwt(res);
            window.location.href = environment.keyCloakLogoutUrl + environment.baseUrl + '/logoutext' + '&id_token_hint=' + token.extid;
          });
        } else {
          this.authService.signOut();
        }
      }
    });
  }

  /**
   * decode acces token
   * @returns promise
 */
  parseJwt(token): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  /**
   * check is going to previous Route
   */
  isPrevious(target: menuItem, current: menuItem): boolean {
    const targetIndex = this.tabMenus.indexOf(target);
    const currentIndex = this.tabMenus.indexOf(current);
    if (targetIndex < currentIndex) {
      return true;
    }
    return false;
  }

  /**
   * map inspection from api
   * @param inspectionData
   */
  mapInspection(inspectionData: any, item: menuItem, previousActive: menuItem[], minBulkCount: number): void {
    if (inspectionData) {
      try {
        const inspection = inspectionData;
        const bulkUploadedItemsWithImages = inspection.inspectionItems
          .filter((step) => {
            return step.rawAzureBlobUrl != null && step.rawAzureBlobUrl != '';
          });
        const requiredStepWOImage =
          inspection.inspectionItems.find((x: any) => Object.hasOwn(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '');

        if ((bulkUploadedItemsWithImages.length < minBulkCount && inspection.uploadType === 'Bulk')) {
          const numbertoDisplay = minBulkCount;
          this.commonService.showToast(0, `All ${numbertoDisplay} sides images are mandatory and cannot skip to next section.`);
          return;
        } else if (requiredStepWOImage) {
          const numbertoDisplay = inspection.inspectionItems.filter((x: any) =>
            Object.hasOwn(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '').length;
          this.commonService.showToast(0, `All ${numbertoDisplay} sides images are mandatory and cannot skip to next section.`);
          return;
        } else {
          let isGoingPrevious = false;
          if (previousActive?.length > 0) {
            isGoingPrevious = this.isPrevious(item, previousActive[0]);
          }
          const extraParam = {};
          if (item?.url === 'estimate') {
            extraParam['skipUpdate'] = true;
          }
          this.tabMenus.map(x => x.active = false);
          if (item) item.active = true;
          this.router.navigate([ `quote/case/${this.caseId}${item?.url ? '/' + item?.url : ''}` ],
            { queryParams: { ...extraParam, isBack: isGoingPrevious } });
          this.commonService.titleConfig$.next(
            {
              title: item?.title,
              subTitle: item?.subTitle
            }
          )
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  /**
   * get current inspectionItems length
   */
  getInspectionItems(id, item: menuItem, previousItem: menuItem[]): void {
    this.commonService.showLoading();
    this.inspectionService.getInspectionTemplateList().subscribe({
      next: (inspectionList: any) => {
        let inspectionTemplateId = 0;
        const repairInspectionTemplate = inspectionList?.data

        if (repairInspectionTemplate.length > 0) {
          this.commonService.repairInspectionTemplate = repairInspectionTemplate[0];
          inspectionTemplateId = repairInspectionTemplate[0].id;
        }
        const serverDetails = forkJoin({
          inspectionTemplate: this.inspectionService.getInspectionTemplate(inspectionTemplateId),
          inspectionDetail: this.inspectionService.getInspection(id)
        }).pipe(
          catchError(error => of(error))
        )
        serverDetails.subscribe({
          next: (mapResponse: any) => {
            if (mapResponse) {
              if (mapResponse.inspectionDetail) {
                const minCount = mapResponse.inspectionTemplate.minImageLimit;
                this.mapInspection(mapResponse.inspectionDetail, item, previousItem, minCount);
              }
            }

            this.commonService.hideLoading();
          },
          complete: () => {
            this.commonService.hideLoading();
          },
        });
      }
    });

  }

  /**
   * tab change event
   * @param item
   */
  tabChanged(item: menuItem): void {
    this.monitorService.logEvent('tabChanged', [ 'HeaderComponent', 'addenda-quote', {
      item
    } ]);

    if (this.showNoAccess) {
      return;
    }

    if (this.caseId && this.caseId !== '0') {

      if ((!this.caseDetail?.registrationDate || !this.caseDetail?.modelYearRange) && item.index > 2 && this.isExternal) {
        return;
      }

      if (this.caseDetail?.status?.toLowerCase() === 'vehicledetails' && item.index > 3) {
        return;
      }

      const previousActive = this.tabMenus.filter(x => x.active);
      if (previousActive && previousActive[0].name === item.name) {
        return
      }
      let isGoingPrevious = false;

      if (previousActive[0].index === 4 || previousActive[0].index === 3) {
        if (this.stepInProgress) {
          const module = previousActive[0].index === 4 ? 'images' : 'documents';
          this.commonService.showToast(0, `Please wait until all ${module} are uploaded.`);
          return;
        }
      }

      const currentIndex = caseIndexMapper[this.caseStatus?.toLowerCase()];
      if (item.index === 5 && currentIndex >= 5) {
        const isImagesAvailable = this.tabMenus.filter((t) => {
          return t.name === 'photos';
        });
        if (isImagesAvailable.length > 0) {
          this.getInspectionItems(this.inspectionId, item, previousActive);
          return;
        }
      }

      if (item.index && currentIndex == 1 && ((currentIndex + 1) < item.index)) {
        return;
      }

      if (previousActive.length > 0) {
        isGoingPrevious = this.isPrevious(item, previousActive[0]);
      }
      const extraParam = {};
      if (item.url === 'estimate') {
        extraParam['skipUpdate'] = true;
      }
      this.tabMenus.map(x => x.active = false);
      item.active = true;
      this.router.navigate([ `quote/case/${this.caseId}${item.url ? '/' + item.url : ''}` ],
        { queryParams: { ...extraParam, isBack: isGoingPrevious } });
    }

  }

  /**
   * checkAgoFormat
   * @param {*} dateTime
   * @returns {string}
   */
  checkAgoFormat(date): string {
    if (date.indexOf('Z') === -1) date += 'Z';
    if (dayjs(date).isToday()) {
      return dayjs().to(dayjs(date));
    } else {
      return dayjs(date).format('MMM DD, YYYY - hh:mm A');
    }
  }

  /**
   * notificationMessageGenerate
   * @param {*} item
   */
  getNotificationMessages(skip): void {
    this.skipCounter = skip;
    this.userService.getNotifications(this.notificationsPerScroll, skip, 'CreatedDate desc', this.domainIds)
  }

  /**
   * Get total Unread notification count
   * @param {*} notification
   */
  getNotificationUnreadCount(): void {
    this.userService.getNotificationsUnreadCount(this.domainIds).subscribe((count: any) => {
      this.notificationUnreadCount = count
    })
  }

  /**
   * setNotificationRead
   * @param {*} notification
   */
  setNotificationRead(notification): void {
    if (notification.IsRead) return;
    if (this.tempUnreadNotificationsCheck.includes(notification.NotificationGuid
    )) return;
    this.tempUnreadNotificationsCheck.push(notification.NotificationGuid
    );
    this.userService.markAsReadNotification(notification.NotificationGuid
    ).subscribe({
      next: (data) => {
        if (data.success) {
          this.notificationList = this.notificationList.map(item => item.Id === notification.Id ? { ...item, IsRead: true } : item);
          this.getNotificationUnreadCount();
        } else {
          this.commonService.showToast(0, 'Could Not Mark It as Unread. Please Try again later');
        }
      }
    })


  }

  /**
   * setAllNotificationsRead
   * @date 7/10/2023 - 3:46:35 PM
   */
  setAllNotificationsRead(): void {
    if (this.notificationUnreadCount <= 0) return
    this.commonService.showLoading();
    forkJoin(
      this.domainIds.map(domainId => this.userService.markAllAsReadNotification(domainId))
    ).subscribe({
      next: (res) => {
        let failed = false;
        res.forEach((data) => {
          if (!data['success']) failed = true;
        })
        this.commonService.hideLoading();
        if (!failed) {
          this.notificationList = this.notificationList.map((item) => {
            return { ...item, IsRead: true };
          });
          this.notificationUnreadCount = 0;
        } else {
          this.commonService.showToast(0, 'Could Not Mark Notifications as Unread. Please Try again later');
        }
      }
    })
  }

  /**
   * notificationNavigate
   * @param {*} event
   * @param {*} repairId
   * @param {*} type
   */
  notificationNavigate(event, id, type, item): void {
    this.menuTrigger?.closeMenu();
    if (type === 'ViewParts') {
      this.router.navigate([ `/repair/booking/${id}/parts` ], { queryParams: { mode: 'edit' } });
      this.commonService.activeItem.next('Repair')
    } else if (type === 'ViewRepairHistory') {
      this.router.navigate([ `/repair/booking/${id}/estimate` ], { queryParams: { mode: 'edit', history: 'open' } });
    } else if (type === 'SendIntimationInformation') {
      this.router.navigate([ `/claim/fnol-details/${item.Data?.ClaimGuid}` ]);
    } else if (type === 'ClaimSubmitForReview') {
      if (item?.Type == 'EstimateReceived') {
        this.router.navigate([ `/claim/claim-detail-v2/${encodeURIComponent(item.Data?.PoliceReferenceNo)}/${encodeURIComponent(item.Data?.IntimationNo)}/details` ],
          { queryParams: { from: 'notification', selectedTab: `${item.Data?.ClaimGuid},garage` } });
      } else {
        this.router.navigate([ `/claim/claim-detail-v2/${encodeURIComponent(item.Data?.PoliceReferenceNo)}/${encodeURIComponent(item.Data?.IntimationNo)}/details` ],
          { queryParams: { selectedTab: `${item.Data?.ClaimGuid},garage` } });
      }

    } else if (type === 'CaseSubmitForReview') {
      this.router.navigate([ `/case/detail/${item.Data?.CaseGuid}` ], { queryParams: { selectedIndex: '0' } });
    } else if (type === 'GarageReAssignment') {
      this.router.navigate([ `/claim/claim-detail-v2/${encodeURIComponent(item.Data?.PoliceReferenceNo)}/${encodeURIComponent(item.Data?.IntimationNo)}/details` ],
        { queryParams: { selectedTab: `${item.Data?.ClaimGuid},garage` } });
    } else if (type === 'GarageEstimateNotification') {
      if (item.Data?.ClaimGuid) {
        this.router.navigate([ `/claim/claim-detail-v2/${encodeURIComponent(item.Data?.PoliceReferenceNo)}/${encodeURIComponent(item.Data?.IntimationNo)}/details` ],
          { queryParams: { from: 'notification', selectedTab: `${item.Data?.ClaimGuid},garage` } });
      } else if (item.Data?.RepairGuid) {
        this.router.navigate([ `/repairer-view/booking/${item.Data?.RepairGuid}/estimate` ], { queryParams: { repairView: 'true' } })
      }
    } else if (type === 'SendRequestForRepairInvoice' || item.Type === 'SendRequestForRepairInvoice') {
      this.router.navigate([ `/repair/booking/${id}/invoices` ]);
    } else if (item.Type === 'SendRequestForRepairEstimate' || item.Type === 'ClaimRejected' || item.Type === 'RepairClaimIntimationApproved' || item.Type === 'RepairClaimIntimationRejected') {
      this.router.navigate([ `/repair/booking/${id}/` ], { onSameUrlNavigation: 'reload' });
    } else if ([ 'ApprovalRequestResponseFromClaim', 'EstimateRevisionFromClaim' ].indexOf(item.Type) !== -1) {
      this.router.navigate([ `/repair/booking/${id}/estimate` ], { queryParams: { mode: 'edit' } });
    } else if (item.Data?.Action?.indexOf('ClaimRequest') !== -1) {
      this.notificationViewClaimRequest(item, item.Message, item.Data?.ObjectGuid)
    } else if (item.Data?.Action.indexOf('IntimationRequest') !== -1) {
      this.notificationViewIntimationRequest(item, item.Message, item.Data?.ObjectGuid)
    } else if (item.Type === 'BidirectionalMessage') {
      if (item.Data?.ObjectGuid) {
        if (this.hasClaimPermission) {
          this.router.navigate([ `/claim/claim-detail-v2/${encodeURIComponent(item.Data?.PoliceReferenceNo)}/${encodeURIComponent(item.Data?.IntimationNo)}/details` ], { queryParams: { conversationGuid: item.Data?.ConversationGuid } });
        } else {
          this.router.navigate([ `/repair/booking/${item.Data?.ObjectGuid}` ], { queryParams: { conversationGuid: item.Data?.ConversationGuid } });
        }
      } else {
        this.openChatWindow(item.Data?.ConversationGuid);
      }
    } else if (item.Type === 'BookingDelayed' || item.Type === 'BookingOutDelayed' || item.Type === 'BookingInDelayed') {
      this.router.navigate([ `/repair/technician-calendar/${item.Data?.RepairGuid}` ]);
    } else if (item.Type === 'ReorderLevel') {
      this.router.navigate([ `/inventory/detail/${item.Data?.InventoryItemGuid}` ]);
    }
  }

  /**
   * notificationViewClaimRequest
   */
  notificationViewClaimRequest(item, message, bookingGuid): void {
    this.menuTrigger.closeMenu();
    const messageJSON = JSON.parse(message);
    this.dialog.open(ClaimAssignModalComponent, {
      data: { ...messageJSON, bookingGuid, type: item.Data.Action, notificationGuid: item.NotificationGuid },
      maxHeight: '90vh', minHeight: '400px', minWidth: '400px', disableClose: true
    });
  }

  /**
   * notificationViewIntimationRequest
   */
  notificationViewIntimationRequest(item, message, bookingGuid): void {
    this.menuTrigger.closeMenu();
    const messageJSON = JSON.parse(message);
    this.dialog.open(RepairIntimationRequestComponent, {
      data: { ...messageJSON, bookingGuid, type: item.Data.Action, notificationGuid: item.NotificationGuid },
      maxHeight: '90vh', minHeight: '400px', minWidth: '400px', disableClose: true
    });
  }

  /**
   * stopEventPropagation
   * @param {*} event
   */
  stopEventPropagation(event): void {
    event.stopPropagation();
  }
  /**
   * ng onInit
   */
  ngOnInit(): void {
    if (this.hasQuotePermission) {
      const stepData = this.configurationService.getStepByDomainId(2100);

      const orderedStepMenu = [];

      stepData.sort((a, b) => a.SortOrder - b.SortOrder).forEach((element) => {
        const menuItem = this.allTabMenus.find(x => x.name == QUOTE_MENU[element.ItemKey]);
        if (menuItem) {
          menuItem.title = element?.ItemValue ?? menuItem.title;
          orderedStepMenu.push(menuItem);
        }
      });

      this.tabMenus = orderedStepMenu;
    }

    this.getNotificationUnreadCount();
    this.getGeneralCommunicationMessagesCount();
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    if (this.notificationList.length === 0) {
      this.getNotificationMessages(0);
    }
  }

  /**
   * notificationMenuOpened
   */
  notificationMenuOpened(): void {
    setTimeout(() => this.scroller?.scrollTo({ top: 1 }), 0);
    if (!this.notificationsPerScrollInitialized) {
      setTimeout(() => {
        if (this.scroller?.elementScrolled) {
          this.scroller.elementScrolled().pipe(
            map(() => this.scroller.measureScrollOffset('bottom')),
            pairwise(),
            filter(([ y1, y2 ]) => (y2 < y1 && y2 < 140)),
            throttleTime(1000)
          ).subscribe(() => {
            if (!this.notificationLoading$.value) {
              this.skipCounter += this.notificationsPerScroll;
              this.getNotificationMessages(this.skipCounter);
            }
          });
          this.notificationsPerScrollInitialized = true;
        }
      }, 1000)
    }
  }

  /**
   * isShowActionButton
   * @param {*} item
   * @returns {boolean}
   */
  isShowActionButton(item): boolean {
    let flag = false;
    if (item.Data?.Action === 'GarageEstimateNotification' && !this.checkClaimPermission('estimate.view') && !this.checkClaimPermission('insurance.viewer')) {
      return false;
    }

    const condition1 = [ 'ViewRepairHistory', 'ViewParts', 'ClaimSubmitForReview', 'GarageReAssignment', 'GarageEstimateNotification', 'SendIntimationInformation', 'ViewRepair', 'ViewStockForReview' ]
      .indexOf(item.Data?.Action) !== -1;
    const condition2 = [ 'SendRequestForRepairInvoice', 'SendRequestForRepairEstimate', 'ApprovalRequestResponseFromClaim', 'EstimateRevisionFromClaim', 'ClaimRejected', 'BidirectionalMessage',
      'BookingDelayed', 'BookingOutDelayed', 'BookingInDelayed' ]
      .indexOf(item.Type) !== -1;
    const condition3 = item.Data !== null && Object.prototype.hasOwnProperty.call(item.Data, 'Action') && item.Data?.Action?.indexOf('ClaimRequest') !== -1
    const condition4 = item.Data !== null && Object.prototype.hasOwnProperty.call(item.Data, 'Action') && item.Data?.Action?.indexOf('IntimationRequest') !== -1

    if (condition1 || condition2 || condition3 || condition4) {
      flag = true;
    }

    return flag;
  }

  /**
   * actionButtonText
   * @param {*} item
   * @returns {boolean}
   */
  actionButtonText(item): string {
    if (item.Data?.Action === 'ViewRepairHistory') {
      return 'view_repair_history'
    } else if (item.Data?.Action === 'ViewParts') {
      return 'view_parts'
    } else if (item.Data?.Action === 'ClaimSubmitForReview') {
      return 'view_claim'
    } else if (item.Data?.Action === 'CaseSubmitForReview') {
      return 'view_case'
    } else if (item.Data?.Action === 'GarageReAssignment') {
      return 'reassign'
    } else if (item.Data?.Action === 'ViewStockForReview') {
      return 'view_inventory'
    } else if (item.Type === 'SendRequestForRepairInvoice') {
      return 'view_invoice'
    } else if (item.Type === 'ApprovalRequestResponseFromClaim' || item.Type === 'EstimateRevisionFromClaim') {
      return 'view_estimate';
    } else if (item.Type === 'SendRequestForRepairEstimate' || item.Type === 'ClaimRejected') {
      return 'view_repair';
    } else if (item.Type === 'BidirectionalMessage') {
      return 'view_message';
    } else if (item.Type === 'SendIntimationInformation' || item.Type === 'BookingDelayed' || item.Type === 'BookingOutDelayed' || item.Type === 'BookingInDelayed') {
      return 'view_details';
    } else if (item.Type === 'RepairClaimIntimationApproved' || item.Type === 'RepairClaimIntimationRejected') {
      return 'view_repair';
    }
    return 'view_details';
  }


  /**
   * claim permission
   * @param permissionName
   */
  checkClaimPermission(permissionName: string): boolean {

    const automotiveService = this.userPermission?.automotiveServices;
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;

    return this.commonService.hasPermission(permissionName, permissionList);
  }

  /**
 * show toast
 * @param data
 */
  showWhatsappToast(duration: number, data: any, action?: any): void {
    this._snackBar.openFromComponent(ChatNotificationComponent, {
      data: { data, action, preClose: () => { this._snackBar.dismiss() } },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'whatsapp-notification',
      duration: duration
    })
  }

  /**
   *
   */
  closeNav(isItemSelected): void {
    this.messaging.close();
    if (isItemSelected) {
      this.getGeneralCommunicationMessagesCount();
    }
  }

  /**
   *
   */
  toggleSideMenu(): void {
    this.expandedMenu = !this.expandedMenu;
    if (this.expandedMenu) {
      this.menuIcon = 'menu-expand';
    } else {
      this.menuIcon = 'menu-collapse';
    }
    this.toggle.emit();
  }

  /**
   * function
   */
  getGeneralCommunicationMessagesCount(): void {
    const automotiveService = this.userPermission?.automotiveServices;
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');
    if (permissionList && (permissionList.roleName.toLowerCase() === 'bodyshop manager' || permissionList.roleName.toLowerCase() === 'service advisor')) {
      this.messagesService.getGeneralCommunicationMessagesCount().subscribe((res: any) => {
        if (res.length > 0) {
          this.generalCommunicationMessagesCount = res[0]['unreadConversationCount'];
        }
      })
    }
  }

  /**
   * onLangChange
   */
  async onLangChange(): Promise<void> {
    const lang = this.options.find(x => x.Code === this.selectedLang);
    if (lang) {
      this.configurationService.languageSettings.selectedLanguage = lang.Code;
      localStorage.setItem('languageSettings', JSON.stringify(this.configurationService.languageSettings));
      localStorage.setItem('languageCode', this.configurationService.languageSettings.selectedLanguage);
      localStorage.setItem('locale', lang.Locale);
      const selectedLanguage = this.configurationService.languageSettings.languageFiles.find(x => x.code === lang.Code);
      if (selectedLanguage) {
        if (selectedLanguage.resourceData) {
          this.configurationService.setTranslation(selectedLanguage.resourceData);
        } else {
          this.commonService.showLoading();
          const remoteLanguageData = await this.configurationService.getTranslation();
          this.configurationService.setTranslation(remoteLanguageData);
          this.commonService.hideLoading();
        }

        const newLocale = selectedLanguage.code;
        this.commonService.loadLocaleMessages(newLocale);
        this.commonService.setLocale(lang.Locale);
      } else {
        this.configurationService.setTranslation({});
      }
    } else {
      console.warn('Selected language not found in options:', this.selectedLang);
    }

  }

  /**
   * ngOnDestroy
   * unsubscribe subscriptions
   */
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

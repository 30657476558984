<div class="add-task-container fx-col">
    <div class="header">
        <h3>{{'tasks' | translate}}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'"
                      (click)="cancelDialog(false);"></mat-icon>
        </div>
    </div>
    <div class="content fx-1">
        <form>
            <div class="taskName">
                <chq-input #operationType
                           [inputModel]="formModel.name"
                           (controlOutput)="onFormUpdate($event)"></chq-input>
            </div>
            <div>
                <label class="repairtype-label">{{'created_by' | translate | uppercase}}</label>
                <br />
                <h5 class="createdby-label">{{this.createdByUserName ? this.createdByUserName : this.currentUserName}}
                </h5>
            </div>
            <div class="vector"></div>
            <div>
                <label class="repairtype-label">{{'select_repair_type' | translate | uppercase}}</label>
                <div class="repairtypes">
                    <ng-container *ngFor="let itemModel of repairTypeModel">
                        <div class="toggle">
                            <div class="toggle-button">
                                <chq-input [inputModel]="itemModel"
                                           (controlOutput)="onFormUpdate($event)"></chq-input>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="!isOtherType">
                <div class="vector"></div>
                <div class="importTasks">
                    <chq-input [inputModel]="formModel.importFromGTMotive"
                            *ngIf="data.isDynamicJob"
                            (controlOutput)="onFormUpdate($event)"></chq-input>
                </div>
            </ng-container>
            
            <ng-container *ngIf="isImportFromGTMotive && operationTypeModel?.length > 0">
                <div class="vector"></div>
                <div>
                    <label class="repairtype-label">{{'select_operation_type' | translate | uppercase}}</label>
                    <div class="repairtypes">
                        <ng-container *ngFor="let itemModel of operationTypeModel">
                            <div class="toggle">
                                <div class="toggle-button">
                                    <chq-input [inputModel]="itemModel"
                                            (controlOutput)="onFormUpdate($event)"></chq-input>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            
            
        </form>
        <div class="row">
            <div class="col-8">
                <div class="toggle">
                    <label class="label-toggle">{{'mandatory' | translate}}</label>
                    <chq-toggle [checked]="this.data?.taskData?.isMandatory"
                                [name]="'isMandatory'"
                                (ToggleChange)="onToggleChange($event)"></chq-toggle>
                </div>
            </div>
            <div class="col-4">
                <div class="footer-button fx-row justify-end">
                    <chq-button *ngIf="this.data.taskData?.jobTaskGuid"
                                class="dlt-btn"
                                [buttonModel]="deleteButtonModel"></chq-button>
                    <chq-button [buttonModel]="saveButtonModel"></chq-button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-internal-garage-estimate-dialog',
  templateUrl: './internal-garage-estimate-dialog.component.html',
  styleUrls: [ './internal-garage-estimate-dialog.component.scss' ]
})
export class InternalGarageEstimateDialogComponent {

  claimId: string='';
  /**
 * Constructor for InternalGarageEstimateDialogComponent.
 * 
 * @param data - Data injected into the dialog.
 * @param dialogRef - Reference to the dialog opened.
 * @param monitorService - Service for monitoring.
 * @param commonService - Common service for shared functionality.
 * @param claimService - Service for handling claims.
 * @param translationService - Service for translating text.
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InternalGarageEstimateDialogComponent>,
    private commonService: CommonService,
    private claimService: ClaimsService,
    private translationService: TranslateService,
    public dialog: MatDialog,
  ) {
    this.claimId = data.claimId;
  }

  /**
   * close dialog
   */
  close(): void {
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, pairwise, startWith } from 'rxjs';
import { DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { DynamicFormService } from 'src/app/config/core/dynamic-form/dynamic-form.service';
import { CommonService } from 'src/app/services/common/common.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { cloneDeep } from 'lodash';

export interface VinDetails {
  isVinSpecifiedManualZ: string | null;
  modelYearRange: string;
  umc: string;
  vehicleMake: string;
  vehicleModel: string;
  vin: string;
  vehicleBodyTypeId:number;
  vehicleBodyType:string;
}
@Component({
  selector: 'vehicle',
  // standalone: true,
  // imports: [ CommonModule, ReactiveFormsModule, DynamicFormComponent, TranslateModule ],
  // providers: [ DynamicFormService ],
  templateUrl: './vehicle.component.html',
  styleUrls: [ './vehicle.component.scss' ]
})
export class VehicleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formControls: DynamicFormBase<string>[] | null = [];
  @Input() ocrTags:any[] = [];
  @Input() isEditMode:boolean;
  @Input() aiPrefillObj:any;
  @Input() hideTitle:boolean;
  @Input() showDelete:boolean;
  @Input() enableSearch:boolean;
  @Input() isReadOnlyMode:boolean;
  @Output() deleteVehicle: EventEmitter<any> = new EventEmitter<any>();
  VehicleFormGroup: FormGroup;
  public viewIndex:number;
  controls: DynamicFormBase<string>[] | null = [];
  private estimateId: number;
  private optionSubscription: Subscription;
  public vehicleMakeList: Array<any> = [];
  public vehicleModelList: Array<any> = [];
  private vehicleYearList: Array<any> = [];
  private vehicleTypeList: Array<any> = [];
  private vinDetail: VinDetails;
  public makeOcrTag:string;
  public modelOcrTag:string;
  public yearOcrTag:string;
  public makeTypeOcrTag:string;
  private userPermission: any = {};

  /**
   * constructor
   */
  constructor(
    private dynamicFormService: DynamicFormService,
    private vehicleService: VehicleService,
    public commonService: CommonService
  ) {
    this.optionSubscription = this.dynamicFormService.updatedOption.subscribe((option) => {
      if (option) {
        if (option?.item === 'make') {
          this.vehicleMakeList = option.values;
          if(this.vinDetail?.vehicleMake) {
            const selectedMake = this.vehicleMakeList.filter((typeObj)=>{
              return typeObj.name?.toLowerCase() === this.vinDetail?.vehicleMake?.toLowerCase();
            });
            if(selectedMake){
              this.VehicleFormGroup.get('make').setValue(selectedMake[0].name);
            }
            return;
          }
          if(this.makeOcrTag || this.isEditMode){
            const makeControlIndex = this.controls.findIndex(x => x.key === 'make' && x.controlType === 'dropdown');
            const selectedValue = this.isEditMode ? this.controls[makeControlIndex].value: this.makeOcrTag;
            const selectedMake = this.vehicleMakeList.filter((make)=>{
              return selectedValue?.toLowerCase().includes(make[this.controls[makeControlIndex].optionKeys.value].toLowerCase())
            });
           
            if(makeControlIndex !== -1 && selectedMake.length > 0){
              this.VehicleFormGroup.get('make').patchValue(selectedMake[0][this.controls[makeControlIndex].optionKeys.value] );
            }
            this.makeOcrTag = '';
          }
        }else if(option?.item === 'vehicleType'){
          const vehicleTypes = option.values;
          this.vehicleTypeList = vehicleTypes;
          if(this.vinDetail?.vehicleBodyType) {
            const selectedType = vehicleTypes.filter((typeObj)=>{
              return typeObj.name?.toLowerCase() === this.vinDetail?.vehicleBodyType?.toLowerCase();
            });
            if(selectedType){
              this.VehicleFormGroup.get('vehicleType').setValue(selectedType[0].id);
            }
            return;
          }
          const typeControlIndex = this.controls.findIndex(x => x.key === 'vehicleType' && x.controlType === 'dropdown');
          if(this.isEditMode && typeControlIndex !== -1){
            const selectedValue = this.controls[typeControlIndex].value;
            const selectedType = vehicleTypes.filter((typeObj)=>{
              return selectedValue === typeObj[ this.controls[typeControlIndex].optionKeys.value];
            });
            if(selectedType.length > 0){
              this.VehicleFormGroup.get('vehicleType').patchValue(selectedType[0][this.controls[typeControlIndex].optionKeys?.value] );
            }
          }
        } else if (option?.item === 'model') {
          this.vehicleModelList = option.values;
          const gtVinPermission = this.commonService.checkOrgPackagesPermission(this.userPermission?.orgPackages, 'Vehicle Identification', 'gt.data.vincoding');
          const matchProp = gtVinPermission? 'umc':'modelName';
          const vinProp = gtVinPermission? 'umc':'vehicleModel';
          let vehicleModel:any = null;
          if(this.vinDetail){ 
            vehicleModel = this.vehicleModelList.find(x => x[matchProp]?.toLowerCase() === this.vinDetail[vinProp]?.toLowerCase());
          }
          let isOcrTagUsed = false;
          let prefillValue = '';
          const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
          if(vehicleModel) {
            this.VehicleFormGroup.get('model').patchValue(vehicleModel[modelControl.optionKeys.value]);
            return;
          }
          if(this.modelOcrTag || this.isEditMode || vehicleModel){
            const modelOcrSplittedList = this.modelOcrTag?.toLowerCase();
            const selectedValue = this.isEditMode ? modelControl.value:'';
            const selectedMake = this.vehicleModelList.filter((model)=>{
              return selectedValue?.toLowerCase() === model[modelControl.optionKeys.value].toLowerCase() ||
              modelOcrSplittedList === model[modelControl.optionKeys.value].toLowerCase();
            });
            if(selectedMake.length > 0){
              isOcrTagUsed = true;
              prefillValue = selectedMake[0][modelControl.optionKeys.value];
            }
            this.modelOcrTag = '';
          }

          if(!isOcrTagUsed && this.makeTypeOcrTag){
            const selectedMake = this.vehicleModelList.filter((model)=>{
              return this.makeTypeOcrTag.toLowerCase().includes(model[modelControl.optionKeys.value].toLowerCase())
            });
            if(selectedMake.length > 0){
              isOcrTagUsed = true;
              prefillValue = selectedMake[0][modelControl.optionKeys.value];
            }
          }

          if(prefillValue){
            this.VehicleFormGroup.get('model').patchValue(prefillValue);
          }

        } else if(option?.item === 'year') {
          this.vehicleYearList = option.values;
          if(this.vinDetail?.modelYearRange) {
            this.VehicleFormGroup.get('year').setValue(this.vinDetail?.modelYearRange.replace(/[()]/g, ''));
            return;
          }
          if(this.yearOcrTag || this.isEditMode){
            const yearControlIndex = this.controls.findIndex(x => x.key === 'year' && (x.controlType === 'dropdown' || x.controlType === 'multiselect-dropdown'));
            const selectedValue = this.isEditMode ? this.controls[yearControlIndex].value: this.yearOcrTag;
            const selectedYear = this.vehicleYearList.filter((year)=>{
              return selectedValue.toString().toLowerCase() === year[this.controls[yearControlIndex].optionKeys.value].toLowerCase();
            });

            if(yearControlIndex !== -1 && selectedYear.length > 0){
              this.VehicleFormGroup.get('year').patchValue(selectedYear[0][this.controls[yearControlIndex].optionKeys.value] );
            }

            if( this.controls[yearControlIndex].controlType === 'multiselect-dropdown'){
              const selectedYear = this.vehicleYearList.filter((year)=>{
                return selectedValue.toString().toLowerCase().includes(year[this.controls[yearControlIndex].optionKeys.value].toLowerCase());
              });
              if(yearControlIndex !== -1 && selectedYear.length > 0){
                const selectedYearIds = selectedYear.map((year)=>{
                  return year[this.controls[yearControlIndex].optionKeys.value];
                });
                this.VehicleFormGroup.get('year').patchValue(selectedYearIds);
              }
            }
          }
        }
      }
    });
  }

  /**
   * on init
   */
  ngOnInit(): any {
    if(this.aiPrefillObj && !this.isEditMode){
      this.handleAIRegistrationPrefillDetails(this.aiPrefillObj);
    }
    if(!this.aiPrefillObj){
      const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
      if(makeControl && Object.prototype.hasOwnProperty.call(makeControl, 'optionsByUrl')){
        makeControl.optionsByUrl.preventExecutionByDefault = false;
        this.controls = [ ...this.controls ];
      }
    }
    if(this.isEditMode && !this.isReadOnlyMode){
      this.enableFields();
    }
  
    this.commonService.userProfileData.subscribe((res) => {
      if (res?.data) {
        const { userPermission } = res.data;
        this.userPermission = userPermission || {};
        const { vehicleSourceType } = res.data;
        this.estimateId = vehicleSourceType;
      }
    });
  }

  /**
   * load vin decoder
   */
  loadVinDecoder(vin:string):void{
    const gtVinPermission = this.commonService.checkOrgPackagesPermission(this.userPermission?.orgPackages, 'Vehicle Identification', 'gt.data.vincoding');
    const xaVinPermission = this.commonService.checkOrgPackagesPermission(this.userPermission?.orgPackages, 'Vehicle Identification', 'xa.data.vincoding');
    if(xaVinPermission) {
      this.commonService.showLoading();
      this.vinDetail = null;
      this.vehicleService.getVinDecoder(vin)?.subscribe({
        next: (result: any) => {
          if (result?.success && result.data) {
            this.vinDetail = result.data;
            const matchProp = gtVinPermission? 'umc':'modelName';
            const vinProp = gtVinPermission? 'umc':'vehicleModel';
            if(this.vinDetail?.vehicleMake === this.VehicleFormGroup?.get('make').value && this.vehicleModelList?.length >0) {
             
              let vehicleModel:any = null;
              if(this.vinDetail){ 
                vehicleModel = this.vehicleModelList.find(x => x[matchProp]?.toLowerCase() === this.vinDetail[vinProp]?.toLowerCase());
              }
              if(vehicleModel) {
                const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
                this.VehicleFormGroup.get('model').patchValue(vehicleModel[modelControl.optionKeys.value]);
              }
            }

            if(this.vinDetail && this.vinDetail[vinProp] === this.VehicleFormGroup?.get('model').value && this.vehicleYearList?.length >0) {
              this.VehicleFormGroup.get('year').setValue(this.vinDetail?.modelYearRange.replace(/[()]/g, ''));
            }

            setTimeout(() => {
              if(this.vehicleMakeList && this.vehicleMakeList?.length > 0){
                this.VehicleFormGroup?.get('make').setValue(this.vinDetail?.vehicleMake);
              }
             
              const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
              if(makeControl && Object.prototype.hasOwnProperty.call(makeControl, 'optionsByUrl')){

                makeControl.optionsByUrl.preventExecutionByDefault = false;
                this.controls = [ ...this.controls ];
              }
              if(this.vinDetail?.vehicleBodyType) {
                const selectedType = this.vehicleTypeList?.filter((typeObj)=>{
                  return typeObj.name?.toLowerCase() === this.vinDetail?.vehicleBodyType?.toLowerCase();
                });
                if(selectedType){
                  this.VehicleFormGroup.get('vehicleType').setValue(selectedType[0].id);
                }
                return;
              }
             
            });
            this.commonService.hideLoading();
          } else {
            if(!this.VehicleFormGroup?.get('make').value){
              this.VehicleFormGroup?.get('make').setValue('');
            }

            if(!this.VehicleFormGroup?.get('model').value){
              this.VehicleFormGroup?.get('model').setValue('');
            }
            
            if(!this.VehicleFormGroup?.get('year').value){
              this.VehicleFormGroup?.get('year').setValue('');
              
            }
            
            this.commonService.showInfoToast(5000, 'show-info | ' + result?.message);

            this.commonService.hideLoading();
            
          }
          this.enableFields();
          this.controls = [ ...this.controls ];
        }
      });
    } else {
      this.enableFields();
      const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
      if(makeControl && Object.prototype.hasOwnProperty.call(makeControl, 'optionsByUrl')){
        makeControl.optionsByUrl.preventExecutionByDefault = false;
      }
      this.controls = [ ...this.controls ];
    }
    
  }

  /**
   * get make/modelId
   */
  getMakeModelId(value:string, control:string):number{
    if(control === 'make'){
      return this.vehicleMakeList.find(x => x.name === value)?.id;
    }
    return this.vehicleModelList.find(x => x.modelDescription === value)?.id;
  }

  /**
   * change event
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControls']?.currentValue) {
      const controlsForVehicle = cloneDeep(this.formControls);
      this.controls = this.dynamicFormService.getSortedFormControls(controlsForVehicle);
      this.VehicleFormGroup = this.dynamicFormService.createFormGroup(controlsForVehicle);
      if(this.enableSearch){
        this.controls.forEach((x)=>{
          x.enableSearch = true;
          x.onSearch = ($event:string, options:any[]):void=>{
            x.options = options.filter(o => o[x.dropdownConfig.labelKey]?.toLowerCase().includes($event?.toLowerCase()));
            x.options = [ ...x.options ];
          }
        })
      }

      const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
      if(this.isEditMode && makeControl){
        makeControl.optionsByUrl.preventExecutionByDefault = false;
        this.controls = [ ...this.controls ];
      }
      const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
      const yearControl = this.controls.find(x => x.key === 'year' && (x.controlType === 'dropdown' || x.controlType === 'multiselect-dropdown'));
      this.VehicleFormGroup.get('vin')?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ prev, next ]: [any, any]) => {
          if (prev !== next && next) {
            if (next.length === 17) {
              this.loadVinDecoder(next);
            }
          }
        });

      this.VehicleFormGroup.get('make')?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ makePrev, makeNext ]: [any, any]) => {
          if (makePrev !== makeNext && makeNext) {
            const makeNextId = this.vehicleMakeList.find(x => x.name === makeNext)?.id;
            this.VehicleFormGroup.get('model')?.patchValue('');
            this.VehicleFormGroup.get('year').setValue('');
            if (modelControl?.optionsByUrl) {
              modelControl.optionsByUrl.preventExecutionByDefault = false;
              modelControl.optionsByUrl.url = modelControl.optionsByUrl.placeHolderUrl?.replace('{makeId}', makeNextId).replace('{estimateId}', this.estimateId?.toString());
              this.controls = [ ...this.controls ];
            }
          }
        });

      this.VehicleFormGroup.get('model')?.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([ modelPrev, modelNext ]: [any, any]) => {
          if (modelPrev !== modelNext && modelNext) {
            const modelNextId = this.vehicleModelList.find(x => x.modelDescription === modelNext)?.id;
            const modelPrevId = this.vehicleModelList.find(x => x.modelDescription === modelPrev)?.id;
            this.VehicleFormGroup.get('year').setValue('');
            if (yearControl?.optionsByUrl) {
              yearControl.optionsByUrl.preventExecutionByDefault = false;
              yearControl.optionsByUrl.url = yearControl.optionsByUrl.placeHolderUrl?.replace('{modelId}', modelNextId).replace('{estimateId}', this.estimateId?.toString());
              this.controls = [ ...this.controls ];
            }
          }
        });

      this.VehicleFormGroup.get('licensePlate')?.valueChanges.subscribe((value) => {
        const control = this.controls?.find(q => q.key == 'licensePlate');
        if (value && /[^a-zA-Z0-9]/.test(value)) {
          control.hint = `For a license plate ${value}, use ${value.replace(/[^a-zA-Z0-9]/g, '')}`; 
        } else {
          control.hint = '';
        }
        this.controls = [ ...this.controls ];
        this.VehicleFormGroup.get('licensePlate').markAsTouched();
      })
    }
    if(changes['aiPrefillObj']?.currentValue && !this.isEditMode){
      if(this.aiPrefillObj){
        this.handleAIRegistrationPrefillDetails(this.aiPrefillObj);
      }
    }
  }

  /**
   * handle AI Registration
   */
  handleAIRegistrationPrefillDetails(obj:any):void{
    if(obj){ 
      const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
      const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
      const yearControl = this.controls.find(x => x.key === 'year' && x.controlType === 'dropdown');

      if(obj.vinNumber && obj.vinNumber?.length > 0){
        this.VehicleFormGroup?.get('vin')?.patchValue(obj.vinNumber, { emitEvent: false, onlySelf: true });
        setTimeout(()=>{
          this.loadVinDecoder(obj.vinNumber );
        });
        
      }else{
        const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
        if(makeControl && Object.prototype.hasOwnProperty.call(makeControl, 'optionsByUrl')){
          makeControl.optionsByUrl.preventExecutionByDefault = false;
          this.controls = [ ...this.controls ];
        }
      }

      if((obj.year)){
        const yearValue = obj.year ?? '';
        this.yearOcrTag = yearValue;
        if (yearControl && obj.model) {
          yearControl.disabled = false;
        }
      }

      if((obj.model && obj.model.length > 0)){
        const modelValue = obj.model;
        this.modelOcrTag = modelValue;
        if (modelControl && obj.make) {
          modelControl.disabled = false;
        }
      }
    

      if((obj.make && obj.make.length > 0)){
        const makeValue = obj.make;
        this.makeOcrTag = makeValue;
        if (makeControl) {
          makeControl.disabled = false;
        }
        if (modelControl) {
          modelControl.disabled = false;
        }
      }

      if (obj['registration_Date']) {
        const date = obj['registration_Date'];
        const registrationDate = this.controls.find(x => x.key === 'registrationDate');
        if(registrationDate){
          this.VehicleFormGroup.get('registrationDate')?.patchValue(date);
          registrationDate.value = date;
        }
      }

      if (obj['vehicle_Registration_Number']) {
        const plateValue = obj['vehicle_Registration_Number'];
        const registrationNumber = this.controls.find(x => x.key === 'licensePlate');
        if(registrationNumber){
          this.VehicleFormGroup.get('licensePlate')?.patchValue(plateValue);
          registrationNumber.value = plateValue;
        }
      }

      
    }
  }

  /**
   * prefill vehicle details
   */
  handleRegistrationPrefillOCR(tags: any[]): void {
    if(this.formControls[0]) this.formControls[0].value = '';

    if (tags && tags.length > 0) {
      const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
      const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
      const yearControl = this.controls.find(x => x.key === 'year' && x.controlType === 'dropdown')

      const vinTag = tags.filter((pb)=>{
        return pb.key .includes('Chassis');
      });

      if(vinTag && vinTag.length > 0 && vinTag[0].value){
        this.VehicleFormGroup?.get('vin')?.patchValue(vinTag[0].value);
        this.loadVinDecoder(vinTag[0].value);
      }

      const type = tags?.filter((pb)=>{
        return pb.key === 'Veh. Type.';
      });

      const model = tags.filter((pb)=>{
        return pb.key.includes('Model');
      });

      const make = tags.filter((pb)=>{
        return pb.key.includes('Make');
      });

      const year = tags.filter((pb)=>{
        return pb.key.includes('Year');
      });

      if((make && make.length > 0) || (type && type.length > 0)){
        let makeValue = '';
        let typeValue = '';
        if(make.length > 0){
          makeValue = make[0].value ?? '';
        }

        if(type.length > 0){
          typeValue = type[0].value ?? '';
        }
        
        const splittedType = typeValue.split(' ');
        if(!makeValue){
          this.makeOcrTag = splittedType[0];
        }else{
          this.makeOcrTag = makeValue;
        }
        if (makeControl) {
          makeControl.disabled = false;
        }
        if (modelControl) {
          modelControl.disabled = false;
        }
        modelControl.optionsByUrl = { ...modelControl?.optionsByUrl, preventExecutionByDefault: true };
      }

      if((model && model.length > 0) || (type && type.length > 0)){
        let modelValue = '';
        if(model.length > 0){
          modelValue = model[0].value ?? '';
        }
        let typeValue = '';
        if(type.length > 0){
          typeValue = type[0].value ?? '';
        }
        const splittedType = typeValue.split(' ');
        this.modelOcrTag = modelValue;
        this.makeTypeOcrTag = splittedType[1];
        if (modelControl) {
          modelControl.disabled = false;
        }
      }

      if((year && year.length > 0)){
        const yearValue = year[0].value ?? '';
        this.yearOcrTag = yearValue;
        if (yearControl) {
          yearControl.disabled = false;
        }
      }
    }
  }

  /**
   * enable all fields
   */
  enableFields(): void {
    const makeControl = this.controls.find(x => x.key === 'make' && x.controlType === 'dropdown');
    const modelControl = this.controls.find(x => x.key === 'model' && x.controlType === 'dropdown');
    const yearControl = this.controls.find(x => x.key === 'year' && (x.controlType === 'dropdown' || x.controlType === 'multiselect-dropdown'));
    const carMilageControl = this.controls.find(x => x.key === 'carMileage' && x.controlType === 'textbox');
    const registrationDateControl = this.controls.find(x => x.key === 'registrationDate' && x.controlType === 'date');
    const manufacturingDateDateControl = this.controls.find(x => x.key === 'manufacturingDate' && x.controlType === 'date');
    const licensePlateControl = this.controls.find(x => x.key === 'licensePlate' && x.controlType === 'textbox');
    if (carMilageControl) {
      carMilageControl.disabled = false;
    }
    if (registrationDateControl) {
      registrationDateControl.disabled = false;
    }
    if (manufacturingDateDateControl) {
      manufacturingDateDateControl.disabled = false;
    }
    if (licensePlateControl) {
      licensePlateControl.disabled = false;
    }
    if (makeControl) {
      makeControl.disabled = false;
    }
    if (modelControl) {
      modelControl.disabled = false;
    }
    if (yearControl) {
      yearControl.disabled = false;
    }
  }

  /**
   * on destroy event
   */
  ngOnDestroy(): void {
    this.optionSubscription.unsubscribe();
  }
}


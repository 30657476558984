import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { serviceURL } from 'src/app/config/constants/app.constants';
import { XADropdownConfig, XADropdownOption, XADropdownOptionIcon } from 'src/app/model/xa-dropdown-model';

@Component({
  selector: 'xa-select',
  templateUrl: './xa-select.component.html',
  styleUrls: [ './xa-select.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaSelect),
      multi: true
    }
  ]
})
export class XaSelect implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  @Input() options: any;
  @Input() config: any = {};
  @Input() showGroup: boolean = false;
  @Input() groupByProperty: string;
  @Input() groupBySubtitle: string;
  @Input() groupAvatarText: string;
  @Input() htmlProp: string;
  @Input() htmlPropMode: 'selection' | 'option' | 'both';
  @Input() showAddOption: boolean;
  @Input() addOption: XADropdownOption;
  @Input() allOption: XADropdownOption;
  @Input() groupIcon: XADropdownOptionIcon;
  @Input() isGroupPropertySelection: boolean;
  @Input() showRequired: boolean;
  @Input() isDefaultAllSelected: boolean = false;

  @Output() searchOutput: EventEmitter<any> = new EventEmitter();
  @Output() scrollEndOutput: EventEmitter<any> = new EventEmitter();
  @Output() newItemClick: EventEmitter<any> = new EventEmitter();
  @Output() selection: EventEmitter<any> = new EventEmitter();
  @Output() updateOptions: EventEmitter<any> = new EventEmitter();

  public selectedValue: any;
  public selectedOption: any[];
  public dropdownConfig: XADropdownConfig;
  public isDropdownOpen = false;
  public groupExpandedState: any = {};
  public showLoader: boolean = false;
  public allOptionSelected: boolean = false;

  @ViewChild('dropdownField') dropdownField: ElementRef;
  @ViewChild('dropdownList') dropdownList: ElementRef;
  @ViewChild('label') label: ElementRef;

  private optionsUrl = '';
  private envUrl = serviceURL;
  /**
   *
   */
  constructor(
    public el: ElementRef,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
  }

  /**
   * on init
   */
  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }

  /**
   * ng change
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']?.currentValue) {
      this.dropdownConfig = {
        ...this.config,
        placeHolder: this.config?.placeHolder ?? 'select_dot',
        multiple: this.config?.multiple ?? false,
        enableSearch: this.config?.enableSearch ?? false,
        labelKey: this.config?.labelKey ?? 'label',
        valueKey: this.config?.valueKey ?? 'value',
        showHeaderIcon: this.config?.showHeaderIcon ?? false
      };
      if (!this.dropdownConfig.optionsByUrl?.preventExecutionByDefault) {
        this.loadOptionsByUrl();
      }
    }
  }

  /**
   * load observable
   */
  loadOptionsByUrl(): void {
    const optionsByUrl = this.dropdownConfig?.optionsByUrl;

    if (this.showLoader) {
      return;
    }

    if (!optionsByUrl) {
      return;
    }

    const { service, url, mapper, extraHeaders } = optionsByUrl;
    const curlyBraceRegex = /{.[a-z0-9]{0,50}}/; // This regex matches any substring containing curly braces

    if (curlyBraceRegex.test(url) || url.indexOf('undefined') !== -1 || !url) {
      return; // Return early if the URL contains curly braces
    }

    if (this.optionsUrl === `${this.envUrl[service]}/${url}`) {
      return;
    }

    this.showLoader = true;
    let headers = undefined;
    if(extraHeaders){
      headers = new HttpHeaders(extraHeaders);
    }
    this.http.get(`${this.envUrl[service]}/${url}`, { headers }).subscribe({
      next: (resp) => {
        if (resp) {
          this.optionsUrl = `${this.envUrl[service]}/${url}`;
          let optionArray: any = [];

          if (resp.constructor == Object) {
            optionArray = resp['data'];
          } else {
            optionArray = resp;
          }
          if (mapper) {
            optionArray = mapper(resp);
          }
          this.options = optionArray;
          
          this.updateOptions.emit({
            item: this.config.key,
            values: this.options
          });
          if (this.selectedValue) {
            this.writeValue(this.selectedValue);
          }
        }

        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      }
    })
  }

  /**
   * scroll event
   * @param event
   */
  scroll = (event: any): void => {
    if (!event?.target?.classList?.contains('custom-dropdown')) {
      if(event?.target?.classList?.contains('mat-mdc-dialog-surface')){
        if( this.isDropdownOpen){
          this.isDropdownOpen = false;
        }
      } else{
        this.isDropdownOpen = false;
      }
    }
  };

  /**
   *
   * @param event
   */
  @HostListener('document:click', [ '$event' ])
  closeDropdownOnClickOutside(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
  /**
   *
   * @param value
   */
  writeValue(value: any): void {
    this.allOptionSelected = this.config?.allOptionSelected;
    if (this.config?.allOptionSelected) {
      this.allOptionsClicked()
    } else if (this.dropdownConfig.multiple) {
      if (value) {
        if (!(value instanceof Array)) {
          value = [ value ];
        }
        this.selectedValue = value;
        this.selectedOption = this.options?.filter(x => value.indexOf(x[this.dropdownConfig.valueKey]) !== -1);
      } else {
        this.selectedValue = [];
        this.selectedOption = [];
      }
    } else {
      this.selectedValue = value;
      const options = this.options?.filter(x => x[this.dropdownConfig.valueKey] == value);
      if (options && options.length > 0) {
        this.selectedOption = options
      } else {
        const option = {
          value: '',
          placeholder: true
        }
        option[this.isGroupPropertySelection ? this.groupByProperty : this.dropdownConfig.labelKey] = (this.dropdownConfig.placeHolder || 'select_dot');
        this.selectedOption = [];
        this.selectedOption.push(option);
      }
    }

    this.onChange(value);
  }

  /**
   *
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   *
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   *
   */
  public toggleDropdown(): void {
    this.onTouched();
    const box = this.dropdownField.nativeElement?.getBoundingClientRect();
    this.dropdownConfig = { ...this.dropdownConfig, dropdownWidth: box.width + 'px' };

    if (!this.isDropdownOpen) {
      const dropdownHeight = this.dropdownList.nativeElement.clientHeight
      const windowHeight = window.innerHeight
      const { top, bottom } = this.dropdownField.nativeElement.getBoundingClientRect();
      let availableHeight = windowHeight - bottom;


      if (this.dropdownConfig.marginBottom) {
        availableHeight = availableHeight - this.dropdownConfig.marginBottom;
      }

      if (availableHeight < dropdownHeight) {
        if (this.dropdownConfig.dropdownPosition === 'fixed') {
          this.dropdownList.nativeElement.style.bottom = `${windowHeight - top}px`;
          this.dropdownList.nativeElement.style.top = null;
        } else {
          this.dropdownList.nativeElement.style.bottom = this.label ? 'calc(100% - 17px)' : '100%';
          this.dropdownList.nativeElement.style.top = 'auto';
        }

      } else {
        if (this.dropdownConfig.dropdownPosition === 'fixed') {
          this.dropdownList.nativeElement.style.top = `${bottom}px`;
          this.dropdownList.nativeElement.style.bottom = null;
        } else {
          this.dropdownList.nativeElement.style.bottom = 'auto'
          this.dropdownList.nativeElement.style.top = '100%'
        }
      }
    }

    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   *
   * @param option
   */
  public selectOption(option: any): void {
    if (this.dropdownConfig?.multiple) {
      const index = this.selectedValue.indexOf(option[this.dropdownConfig.valueKey]);

      if (index === -1) {
        this.selectedValue.push(option[this.dropdownConfig.valueKey]);
        this.selection.emit({ value: option[this.dropdownConfig.valueKey], option, isSelected: true });
      } else {
        this.selectedValue.splice(index, 1);
        this.selection.emit({ value: option[this.dropdownConfig.valueKey], option, isSelected: false });
      }

      this.selectedOption = this.options.filter(x => this.selectedValue.indexOf(x[this.dropdownConfig.valueKey]) !== -1)
      this.onChange([ ...this.selectedValue ]);
    } else {
      this.selectedValue = option[this.dropdownConfig.valueKey];
      this.selectedOption = [ option ];
      this.isDropdownOpen = false;
      this.selection.emit({ value: this.selectedValue, option, isSelected: true });
      this.onChange(this.selectedValue);
    }

    this.allOptionSelected = Array.isArray(this.selectedValue) && Array.isArray(this.options) && this.selectedValue.length === this.options.length;
    this.onTouched();
  }

  /**
   *
   * @param option
   */
  public selectOptionAll(option: any, isAllSelected: any): void {
    const index = this.selectedValue.indexOf(option[this.dropdownConfig.valueKey]);
    if (index === -1 && isAllSelected) {
      this.selectedValue.push(option[this.dropdownConfig.valueKey]);
    } else if (index !== -1 && !isAllSelected) {
      this.selectedValue.splice(index, 1);
    }
  }

  /**
   * trigger action
   */
  newOptionClicked(): void {
    this.isDropdownOpen = false;
    this.newItemClick.emit();
  }

  /**
   * trigger action
   */
  allOptionsClicked(toggle?: boolean): void {
    if(toggle) this.allOptionSelected = !this.allOptionSelected;
    this.selectedValue = [];
    this.options.forEach((x) => {
      this.selectOptionAll(x, this.allOptionSelected);
    });
    this.selectedOption = this.options.filter(x => this.selectedValue.indexOf(x[this.dropdownConfig.valueKey]) !== -1)
    this.onChange([ ...this.selectedValue ]);
    this.onTouched();
  }

  private onChange: (value: any) => void = () => { this.cdr.markForCheck() };
  private onTouched: () => void = () => { this.cdr.markForCheck() };

  /**
   *
   * @param value
   * @returns
   */
  public isSelected(value: any): boolean {
    if (this.dropdownConfig?.multiple) {
      return this.selectedValue ? this.selectedValue.indexOf(value) !== -1 : false;
    } else {
      return this.selectedValue === value;
    }
  }

  /**
   *
   * @param search
   */
  public filterOptions(search: string): void {
    this.searchOutput.emit(search);
  }

  /**
   *
   * @param event
   */
  public scrollEvent(event: any): void {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrollEndOutput.emit();
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'app-estimate-decline',
  templateUrl: './estimate-decline.component.html',
  styleUrls: [ './estimate-decline.component.scss' ]
})
export class EstimateDeclineComponent implements OnInit {

  public notesModel:ChqWidgetsInputModel = {
    placeHolder: 'notes',
    label: 'notes',
    name: 'notes',
    value: '',
    type: 'textarea',
    maxLength: 100
  };
  public sendButton: ChqWidgetsButtonModel;
  public cancelButton: ChqWidgetsButtonModel;

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
      private monitorService: MonitorService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<EstimateDeclineComponent>,
      public commonService:CommonService
  
  ) {
  }

  /**
   * handle close
   */
  continue():void{
    this.monitorService.logEvent('continue', [ 'EstimateDeclineComponent', 'addenda-repair' ]);
    this.dialogRef.close({ 'comment': this.notesModel.value });
  }

    
  /**
   * ng on init
   */
  ngOnInit(): void {
    this.sendButton = {
      label: 'send',
      type: 'disabled',
      onclick: this.continue.bind(this)
    }

    this.cancelButton = {
      label: 'cancel',
      type: 'outline-danger',
      onclick: this.close.bind(this)
    }
  }

  /**
   * close
   */
  close():void{
    this.dialogRef.close();
  }

  /**
   * on form update
   * @param output o
   */
  onFormUpdate(output: IControlValue): void {
    this.notesModel.value = output.value;
    if(this.notesModel.value.toString().trim()){
      this.sendButton = { ...this.sendButton, type: 'primary' };
    }else{
      this.sendButton = { ...this.sendButton, type: 'disabled' };
    }
  }
  
}

<div class="claim-garage-assignment">
    <div class="header">
        <h3>{{ 'assign_garage_confirmation_text' | translate }}</h3>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" (click)="close();"></mat-icon>
        </div>
    </div>
    <div class="main-container">
        <p class="hint-text">{{ 'garage_cannot_be_reassigned' | translate }}</p>
        <div class="garage-info-wrapper">
            <div class="info-group">
                <img class="avtar" src="{{garageDetails.LogoUrl || 'assets/img/xa_logo.png'}}" />
                <div class="card-wrapper">
                    <div class="title-wrap">{{garageDetails.Name}}
                    </div>
                    <div class="status-wrap" *ngIf="garageDetails.Type">
                        <div class="label-wrap">{{garageDetails.Type}}</div>
                    </div>
                    <div class="address-wrap" *ngIf="garageDetails.Address">{{garageDetails.Address}}</div>
                    <div class="contact-group row"*ngIf="garageDetails.GarageMobileNumber">
                        <mat-icon class="phone-icon" [svgIcon]="'call'"></mat-icon>
                        <span class="contact">{{garageDetails.GarageMobileNumber}}</span>
                    </div>
                    <div class="location-group row" *ngIf="garageDetails.Distance">
                        <mat-icon class="location-icon" [svgIcon]="'location'"></mat-icon>
                        <span class="distance">{{garageDetails.Distance}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer form-data-footer">
        <chq-button [buttonModel]="noButton" class="left"></chq-button>
        <chq-button [buttonModel]="yesButton" class="right"></chq-button>
    </div>
</div>
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { CommonService } from 'src/app/services/common/common.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';

@Component({
  selector: 'update-stock-dialog',
  templateUrl: './update-stock-dialog.component.html',
  styleUrls: [ './update-stock-dialog.component.scss' ]
})
export class UpdateStockDialogComponent implements OnInit {

  formGroup: FormGroup;
  options: any[] = [];
  config: XADropdownConfig = {
    name: 'singleSelect',
    label: 'update_type',
    placeHolder: 'Select...',
    labelKey: 'description',
    valueKey: 'id',
    showRequiredLabel: true
  }

  optionsReason: any[] = [];
  configReason: XADropdownConfig = {
    name: 'singleSelectReason',
    label: 'reason',
    placeHolder: 'Select...',
    labelKey: 'name',
    valueKey: 'id'
  }

  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFile: File | null = null;

  fileUrl: string | null = null;
  inventoryItemGuid: string = '';

  showReason = false;

  /**
   * constructor
   */
  constructor(private readonly inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateStockDialogComponent>,
    private readonly commonService: CommonService
  ) {
    this.inventoryItemGuid = data.guid;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      updateType: new FormControl(null),
      quantity: new FormControl(null),
      remarks: new FormControl(null),
      reason: new FormControl(null)
    });

    this.getMovementTypes();
    this.getUpdateStockReasons();

    this.formGroup.get('updateType').valueChanges.subscribe({
      next: (val) => {
        if (val) {
          const adjustment = this.options?.find(q => q.id == val);
          if (adjustment?.name == 'StockAdjustment') {
            this.showReason = true;
            this.formGroup.get('reason').addValidators(Validators.required);
            this.configReason.showRequiredLabel = true;
          } else {
            this.showReason = false;
            this.formGroup.get('reason').clearValidators();
            this.configReason.showRequiredLabel = false;
          }

          this.formGroup.get('reason').updateValueAndValidity();
        }
      }
    });
  }

  /**
   * get movement types
   */
  getMovementTypes(): void {
    this.commonService.showLoading();
    this.inventoryService.getMovementTypes().subscribe({
      next: (res) => {
        if (res?.data) {
          this.options = res.data;
        }

        this.commonService.hideLoading();
      }, error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * get update stock reasons
   */
  getUpdateStockReasons(): void {
    this.commonService.showLoading();
    this.inventoryService.getUpdateStockReasons().subscribe({
      next: (res) => {
        if (res?.data) {
          this.optionsReason = res.data;
        }

        this.commonService.hideLoading();
      }, error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * cancel 
   */
  close(val: boolean): void {
    this.dialogRef.close(val);
  }

  /**
   * save stock
   */
  saveStock(): void {

    if (!this.formGroup.valid) {
      this.commonService.showToast(0, 'invalid_form_msg');
      return;
    }

    this.commonService.showLoading();

    let payload: any = {
      'quantity': this.formGroup.get('quantity').value || 0,
      'movementTypeId': this.formGroup.get('updateType').value,
      'remarks': this.formGroup.get('remarks').value || 0
    }

    if (this.showReason) {
      payload = {
        ...payload, 'reasonId': this.formGroup.get('reason').value
      }
    }

    this.inventoryService.updateStock(payload, this.inventoryItemGuid).subscribe({
      next: (res) => {
        if (res?.data) {
          if (this.selectedFile) {
            this.uploadFile(res.data.id);
          } else {
            this.commonService.showInfoToast(5000, 'stock_updated');
            this.commonService.hideLoading();
            this.close(true);
          }
        }
      }, error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * trigger file input
   */
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * on file selection
   * @param event 
   * @returns 
   */
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Check file type
      const allowedTypes = [ 'image/jpeg', 'image/png', 'application/pdf' ];

      if (!allowedTypes.includes(file.type)) {
        this.commonService.showToast(0, 'invalid_file_type_inventory');
        return;
      }

      this.selectedFile = file;
      this.fileUrl = URL.createObjectURL(file);
    }
  }

  /**
   * upload file 
   * @returns 
   */
  uploadFile(id: number): void {
    if (!this.selectedFile) {
      this.commonService.showToast(0, 'no_file_selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('domainId', '12100');
    formData.append('objectId', id.toString())

    this.inventoryService.uploadStockFile(formData).subscribe({
      next: (res) => {
        if (res?.data) {
          const payload = [
            {
              'name': this.selectedFile.name,
              'mediaGuid': res.data.mediaGuid,
              'mediaPartGuid': res.data.mediaPartGuid,
              'objectId': res.data.objectId,
              'attachmentType': 2
            }
          ]
          // call document mapper
          this.inventoryService.stockDocumentMapper(payload).subscribe({
            next: (res: any) => {
              this.commonService.showInfoToast(5000, 'stock_updated_attachment');
              this.commonService.hideLoading();
              this.close(true);
            },
            error: (err: any) => {
              this.commonService.hideLoading();
            }
          })
        }
      }, error: () => {
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * is Preview available
   * @returns 
   */
  isPreviewAvailable(): boolean {
    if (!this.selectedFile) return false;

    const previewAbleTypes = [ 'image/jpeg', 'image/png', 'application/pdf' ];
    return previewAbleTypes.includes(this.selectedFile.type);
  }

  /**
   * allowWholeNumberOnly
   * @param $event 
   */
  allowWholeNumberOnly($event: any): void {
    const charCode = $event.which ? $event.which : $event.keyCode;
    if (charCode < 48 || charCode > 57) {
      $event.preventDefault();
      this.formGroup.get('quantity').setValue('');
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';

@Component({
  selector: 'add-group-category-dialog',
  templateUrl: './add-group-category-dialog.component.html',
  styleUrls: [ './add-group-category-dialog.component.scss' ]
})
export class AddGroupCategoryDialog implements OnInit {
  
  public inputText:string = '';

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param changeDedectionRef 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddGroupCategoryDialog>,
    private monitorService:MonitorService,
    public commonService:CommonService

  ) {
  }

  /**
   * handle close
   */
  add():void{
    this.monitorService.logEvent('continue', [ 'AddGroupCategoryDialog', 'addenda-repair' ]);
    this.dialogRef.close( this.inputText);
  }


  /**
   * close
   */
  close():void{
    this.dialogRef.close();
  }


  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'AddGroupCategoryDialog', 'addenda' ]);
  }

}

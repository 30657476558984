<div class="dialog-container">
    <div class="title-wrapper">
        <div class="title-handler">
           <h3>{{ 'enter_otp_code' | translate }}</h3>
        </div>
        <div class="close-icon">
            <mat-icon [svgIcon]="'cross'" class="cross" style="fill:black;" (click)="close();"></mat-icon>
        </div>
    </div>
    
    <div mat-dialog-content class="content-wrapper">
        <p>
            {{ 'otp_msg' | translate }}
        </p>
        <div class="mobile-wrapper">
            <label> {{ 'your_mobile' | translate }} </label>
            <div class="div-row">
                <span>{{data?.mobileNumber}}</span>
            <mat-icon [svgIcon]="'edit'" (click)="changeNumber()" class="edit-icon" aria-hidden="false" aria-label="call icon"></mat-icon>
            </div>
            
        </div>
        <div class="mobile-otp">
            <label>{{ 'enter_otp' | translate }}</label>
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
            <div *ngIf="correct_otp === 3" class="wrong_otp">
                {{errorMessages}}
            </div>
            <div *ngIf="correct_otp === 2" class="correct_otp">
                {{ 'success' | translate }}
            </div>
        </div>
       
        <div class="button-actions" >
            <chq-button *ngIf="otpText" [buttonModel]="editMobileNumber"></chq-button>
        </div>
         <div class="bottom_text" *ngIf="showResendOTP">
            {{ 'otp_counter' | translate: {second: seconds} }}&nbsp;<span class="resend-sms" [ngClass]="{'disabled-link':resendCounter ===0}" (click)="resend_sms()" *ngIf="resend">Resend</span><span *ngIf="resend">&nbsp;({{resendCounter}})</span>
        </div>
    </div>
    <div class="loading" *ngIf="commonService.isLoading">
        <div class="overlay"></div>
        <div class="spinner">
            <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
        </div>
    </div>

</div>
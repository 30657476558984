import { Component, ElementRef, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SearchSectionModel } from 'src/app/model/chq-search-result-section-model';
import { CommonService } from 'src/app/services/common/common.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ApiPagination, defaultPagination, PaginateInfo } from '../xa-paginator/xa-paginator.component';
import { CaseService } from 'src/app/services/case/case.service';
import { Router } from '@angular/router';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { forkJoin, catchError, of, Observable } from 'rxjs';

const caseStatusMapper = {
  'draft': 'quote/case/{{caseGUID}}',
  'vehicledetails': 'quote/case/{{caseGUID}}/vehicle',
  'uploaddocuments': 'quote/case/{{caseGUID}}/document',
  'uploadphotos': 'quote/case/{{caseGUID}}/photos',
  'aianalysis': 'quote/case/{{caseGUID}}/ai',
  'repairestimation': 'quote/case/{{caseGUID}}/estimate',
  'approved': 'quote/case/{{caseGUID}}/estimate',
  'reject': 'quote/case/{{caseGUID}}/estimate',
  'rejected': 'quote/case/{{caseGUID}}/estimate',
  'closed': 'quote/case/{{caseGUID}}/estimate',
  'complete': 'quote/case/{{caseGUID}}/estimate',
}

@Component({
  selector: 'chq-search-result-section',
  templateUrl: './chq-search-result-section.component.html',
  styleUrls: [ './chq-search-result-section.component.scss' ]
})
export class ChqSearchResultSectionComponent implements OnInit {
  @Input() searchModel: SearchSectionModel;
  @Input() showPagination = false;
  @Input() isClaimModule = false;
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();
  @Output() redirectUser: EventEmitter<any> = new EventEmitter();

  repairPermission: boolean = false;
  quotePermission: boolean = false;
  public searchResult = [];

  private caseStatusMapper = {
    'draft': '',
    'uploaddocuments': 'document',
    'uploadphotos': 'photos',
    'repairestimation': 'estimate',
    'pendingapproval': 'estimate',
    'approved': 'estimate',
    'rejected': 'estimate',
    'partsordered': 'parts'
  }

  public validStatuses = [ 'Repair Estimation', 'Approved', 'Complete' ];
  public pagination: ApiPagination = { ...defaultPagination, orderBy: '' };

  public repairerView: boolean = false;
  public userIsBMOrSA = false;

  /**
   * constructor
   * @param router
   */
  constructor(private commonService: CommonService,
    private searchService: SearchService,
    private eRef: ElementRef,
    private caseService: CaseService,
    private inspectionService: InspectionService,
    private router: Router,
    private monitorService: MonitorService) {
    this.commonService.userPermission.subscribe((res: any) => {
      this.repairPermission = res.repair;
      this.quotePermission = res.quote;
    });

    if (this.router.url.indexOf('repairer-view') != -1 ) {
      this.repairerView = true;
    }

    this.commonService.userProfileData.subscribe((res) => {
      if (res && res.data) {
        const roles = res?.data?.roleDetail;
        const isBodyShopOrSA = roles?.filter((service) => {
          return service.name.toLowerCase() === 'bodyshop manager' || service.name.toLowerCase() === 'service advisor';
        }).length > 0;

        if (isBodyShopOrSA) {
          this.userIsBMOrSA = true;
        }
      }
    });
  }

  /**
   * get display status
   * @param status
   */
  getDisplayStatus(status): string {
    const statusMapper =
    {
      draft: 'Draft',
      vehicledetails: 'Vehicle Details',
      uploaddocuments: 'Upload Documents',
      uploadphotos: 'Upload Photos',
      repairestimation: 'Repair Estimation',
      pendingapproval: 'Pending Approval',
      approved: 'Approved',
      reject: 'Reject',
      rejected: 'Rejected',
      closed: 'Closed',
      complete: 'Closed',
      partsorderedpartially: 'Parts Ordered Partially',
      partsordered: 'Parts Ordered',
      repairinprogress: 'Repair In Progress',
      readytoinvoice: 'Ready To Invoice',
      invoiceready: 'Invoice Ready',
      invoiced: 'Invoiced',
      paid: 'Paid',
      others: 'Others',

      claimauthenticated: 'Authenticated',
      detailsupdated: 'Details Updated',
      submitted: 'Submitted',
      assigned: 'Assigned',
      reviewinprogress: 'Review in Progress',
      claimsubmittedforapproval: 'Submitted for Approval',
      approvalreviewinProgress: 'Approval in Progress',
      assignedback: 'Assigned Back',
      garageassignedforclaim: 'Garage Assigned',
      garageassignmentrequestedrejected: 'Garage Rejected',
      garageassignmentrequested: 'Garage Requested',
      garageassignmentrequestedfailed: 'Garage Request Failed',
      estimateapproved: 'Estimate Approved',
      estimaterejected: 'Estimate Rejected',
      repairinitiated: 'Repair Initiated',
      repairinvoice: 'Repair Invoice',
      settlementinitiated: 'Settlement Initiated'
    }
    return statusMapper[status.replace(/\s/g, '').toLowerCase()] || status;
  }

  /**
   * check current platform
   */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * handlePaginationChange
   * @param event
   */
  handlePaginationChange(event: PaginateInfo): void {
    const pagination: ApiPagination = {
      pageSize: event.pageSize,
      offset: event.offset,
      currentPage: event.currentPage
    };

    this.pagination.currentPage = event.currentPage;
    this.pagination = { ...this.pagination, ...pagination };
    this.searchWithPagination(this.searchModel?.searchKey);
  }

  /**
   * handle search event
   * @param $event
   * @returns
   */
  searchWithPagination(searchKey: string): void {
    if (!searchKey) {
      return;
    }
    this.commonService.showLoading();
    const title = this.searchModel.title;

    const searchObject = {
      'pageSize': (this.isClaimModule) ? 10 : this.pagination.pageSize,
      'pageNumber': this.pagination.currentPage,
      'sectionName': title.charAt(0).toUpperCase() + title.slice(1),
      searchKey
    }
    this.monitorService.logEvent('search', [ 'ChqSearchResultSectionComponent', 'Addenda-Quote' ]);

    let promiseApi: Observable<any> = null;
    if (!this.isClaimModule || this.repairerView) {
      promiseApi = this.searchService.generalSearchWithProperty(searchObject);
    } else {
      promiseApi = this.searchService.generalSearchClaimWithProperty(searchObject);
    }

    promiseApi.subscribe({
      next: (results) => {
        if (results?.data) {
          this.searchModel.data = results.data.items;
          this.pagination.count = results.data.totalCount;
        }
        this.commonService.hideLoading();
      },
      error: () => {
        this.searchResult = [];
        this.commonService.hideLoading();
      }
    });

  }


  /**
   * get case Details and statuses
   * @param row
   * @param column
   * @param event
   */
  goToCaseDetailPage(caseGuid: string, caseStatus: string, caseId: number, orgId: number): void {
    const route = caseStatusMapper[caseStatus.toLowerCase()]
    const extraParam = {};
    this.monitorService.logEvent('goToCaseDetailPage', [ 'ChqCasesTableComponent', 'addenda-quote', {
      caseGuid,
      caseStatus
    } ]);

    if (route.indexOf('/estimate') !== -1) {
      this.getInspectionItems(caseId, route, caseGuid, extraParam, orgId);
    } else {
      this.closeSearch.emit();
      this.router.navigateByUrl('/quote/case/0/refresh', { skipLocationChange: true }).then(() => {
        this.router.navigate([ route.replace('{{caseGUID}}', caseGuid) ], { queryParams: { ...extraParam } });
      });
    }

  }

  /**
   * get case Details and statuses
   * @param row
   * @param column
   * @param event
   */
  goToClaimDetailPage(policeReferenceNo: string, intimationNo: string): void {
    this.monitorService.logEvent('goToClaimDetailPage', [ 'ChqSearchResultSectionComponent', 'addenda-claim', {
      policeReferenceNo,
      intimationNo
    } ]);

    this.router.navigate([ 'claim/claim-detail-v2/' + encodeURIComponent(policeReferenceNo) + '/' + encodeURIComponent(intimationNo) + '/details' ]);
    this.closeSearch.emit();
  }

  /**
   * get case Details and statuses
   * @param row
   * @param column
   * @param event
   */
  goToRepairPage(bookingId: string, status: string): void {
    this.monitorService.logEvent('goToRepairPage', [ 'ChqSearchResultSectionComponent', 'addenda-quote', {
      bookingId
    } ]);

    if (this.repairerView) {
      this.router.navigate([ `/repairer-view/booking/${bookingId}` ], { queryParams: { repairView: 'true' } });
    } else {
      const caseUrl = this.caseStatusMapper[status.toLowerCase()] ? `/${this.caseStatusMapper[status.toLowerCase()]}` : '';
      this.router.navigateByUrl('/repair/booking/0/refresh', { skipLocationChange: true }).then(() => {
        this.router.navigate([ `repair/booking/${bookingId}${caseUrl}` ]);
      });
    }

    this.closeSearch.emit();
  }


  /**
   * get case Details and statuses
   * @param row
   * @param column
   * @param event
   */
  goToClaimPage(claimId: string): void {
    this.monitorService.logEvent('goToClaimPage', [ 'ChqSearchResultSectionomponent', 'addenda-claim', {
      claimId
    } ]);

    this.router.navigateByUrl('/claim/claim-detail/0/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([ `claim/claim-detail/${claimId}/customer-view` ]);
    });
    this.closeSearch.emit();
  }

  /**
   * handle click
   */
  handleAction(row: any, mode: string): void {
    if (mode === 'view') {
      if (this.isClaimModule && !this.repairerView) {
        this.goToClaimDetailPage(row.policeReferenceNumber, row.intimationNo);
      } else if (this.searchModel?.title === 'quotes') {
        this.goToCaseDetailPage(row.caseGuid, row.status.replace(' ', ''), row.caseNumber, row.orgId);
      } // Removed again ref# User Story 36924: Technician Calendar View - List view and search
      // else if (this.commonService.roleName?.toLowerCase() === 'technician') {
      //   this.router.navigate([ `repair/technician-calendar/${row.repairGuid}` ]);
      //   this.closeSearch.emit();
      // }
      else {
        this.goToRepairPage(row.repairGuid, row.status);
      }
    } else if (mode === 'calculate') {
      this.commonService.setSessionStorage('selectedSearchItem', JSON.stringify(row));
      this.redirectUser.emit({ 'module': 'quote' });
    } else if (mode === 'calendar') {
      if (row.caseGuid) {
        this.redirectUser.emit({ 'module': 'repair', 'caseId': row.caseGuid });
      } else {
        // let message = 'Are you sure you want to create a new repair case with same customer details? ';
        let message = 'Do you want to create a new repair order with same customer details?';

        if (row.vin) {
          // message = 'Are you sure you want to create a new repair case with same vehicle and customer details?';
          message = 'Do you want to create new repair order with same customer and vehicle details?';
        }

        ///Commenting below confirmation popups as discussed with Rohan for User Story 39260: New booking from existing repair order

        // const dialogRef = this.commonService.openConfirmYesNoDialog('Confirm', message);
        // dialogRef.afterClosed().subscribe((result) => {
        //   if (result) {
        this.commonService.setSessionStorage('selectedSearchItem', JSON.stringify(row));
        if (!row.vin) {
          this.commonService.isRepairOrderCustomer.next(true);
          // this.router.navigateByUrl('/repair/booking/0/refresh', { skipLocationChange: true }).then(() => {
          //   this.router.navigate([ 'repair/booking/0' ]);
          // });
          // this.redirectUser.emit({ 'module': 'repair' });
        } else {
          this.commonService.isRepairOrderVehicle.next(true);
        }
        //   }
        // });
      }
    }
  }

  /**
     * map inspection from api
     * @param inspectionData
     */
  mapInspection(inspectionData: any, route: string, caseGuid: string, extraParam: any, minBulkCount: number): void {
    if (inspectionData) {
      try {
        const inspection = inspectionData;
        const bulkUploadedItemsWithImages = inspection.inspectionItems
          .filter((step) => {
            return step.rawAzureBlobUrl != null && step.rawAzureBlobUrl != '';
          });

        const requiredStepWOImage =
          inspection.inspectionItems.find((x: any) => Object.prototype.hasOwnProperty.call(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '');

        if (bulkUploadedItemsWithImages.length < minBulkCount && inspection.uploadType === 'Bulk') {
          this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
            caseGuid,
            message: 'All 8 sides images are mandatory and cannot skip to review section.'
          } ]);
          this.commonService.showToast(0, 'All 8 sides images are mandatory and cannot skip to review section.');
          this.closeSearch.emit();
          this.router.navigate([ caseStatusMapper['uploadphotos'].replace('{{caseGUID}}', caseGuid) ]);
        } else if (requiredStepWOImage) {
          const numbertoDisplay = inspection.inspectionItems.filter((x: any) =>
            Object.prototype.hasOwnProperty.call(x, 'isSkipEnabled') && !x.isSkipEnabled && x.url == '').length;
          this.commonService.showToast(0, `All ${numbertoDisplay} sides images are mandatory and cannot skip to next section.`);
          return;
        } else {
          this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
            caseGuid,
            message: 'Navigated successfully'
          } ]);
          this.closeSearch.emit();
          this.router.navigateByUrl('/quote/case/0/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([ route.replace('{{caseGUID}}', caseGuid) ], { queryParams: { ...extraParam } });
          });
        }
      } catch (error: any) {
        this.monitorService.logEvent('mapInspection', [ 'ChqCasesTableComponent', 'addenda-quote', {
          caseGuid,
          error
        } ]);
        console.log(error);
      }
    }
  }


  /**
     * get current inspectionitems length
     */
  getInspectionItems(id, route: string, caseGuid: string, extraParam: any, orgId: number): void {
    this.commonService.showLoading();
    const configObj = forkJoin({
      templateList: this.inspectionService.getInspectionTemplateList(),
      inspectionIdList: this.caseService.getInspectionIdFromList(id, orgId, true)
    }).pipe(
      catchError(error => of(error))
    );
    configObj.subscribe({
      next: (configResultMap: any) => {
        if (configResultMap.inspectionIdList && configResultMap.templateList) {
          const caseResult = configResultMap.inspectionIdList;
          const caseData = caseResult?.value[0];
          const inspections = caseData?.Inspections[0];
          const templateListData = configResultMap.templateList;
          let inspectionTemplateId = 0;
          const repairInspectionTemplate = templateListData?.data;

          if (repairInspectionTemplate.length > 0) {
            inspectionTemplateId = repairInspectionTemplate[0].id
          }
          const serverDetails = forkJoin({
            inspectionTemplate: this.inspectionService.getInspectionTemplate(inspectionTemplateId),
            inspectionDetail: this.inspectionService.getInspection(inspections.InspectionId)
          }).pipe(
            catchError(error => of(error))
          )

          serverDetails.subscribe({
            next: (mapResponse: any) => {
              if (mapResponse) {
                if (mapResponse.inspectionDetail) {
                  const minCount = mapResponse.inspectionTemplate.minImageLimit;
                  this.mapInspection(mapResponse.inspectionDetail, route, caseGuid, extraParam, minCount);
                }
              }

              this.commonService.hideLoading();
            },
            complete: () => {
              this.commonService.hideLoading();
            },
          });

        } else {
          this.commonService.hideLoading();
        }
      },
      error: (err: any) => {
        this.commonService.hideLoading();
      }

    })

  }

  /**
   * oninit
   */
  ngOnInit(): void {
    this.pagination = { ...this.pagination, currentPage: 1, count: this.searchModel?.count, pageSize: (this.isClaimModule) ? 10 : 5 };
  }
}

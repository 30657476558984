<div class="dialog-container">
    <div class="header">        
        <span>
            <h3>{{ (data.selectedReport === 'ReportEstimateSummary' ? 'estimate_date_comment' : 'estimate_date') | translate }}</h3>
            <span>
                {{ (data.selectedReport === 'ReportEstimateSummary' ? 'enter_estimate_date_comment' : 'enter_estimate_date') | translate }}
            </span>
        </span>
        <div class="close-icon"
             (click)="closeDialog()"
             (keypress)="closeDialog()">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="form-container">
            <form [formGroup]="estimateReportForm">
                <div class="col-6 input-wrapper">
                    <xa-date-picker name="estimateDate"
                                    formControlName="estimateDate"
                                    label="{{ 'estimate_date' | translate }}"
                                    placeholder="DD/MM/YYYY"
                                    type="date"
                                    [maxDate]="estimateMaxDate"
                                    [minDate]="estimateMinDate"></xa-date-picker>
                </div>

                <div class="input-wrapper"
                     *ngIf="data.selectedReport === 'ReportEstimateSummary'">
                    <div class="div-wrapper">
                        <xa-textarea label="{{ 'comments' | translate }}"
                                     formControlName="comment"
                                     placeholder="{{ 'enter_here' | translate }}"
                                     name="comment"></xa-textarea>
                        <xa-error *ngIf="estimateReportForm.get('comment').dirty && estimateReportForm.get('comment').hasError('maxlength')">
                            {{ 'max_250_characters' | translate }}
                        </xa-error>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="footer form-data-footer">
        <xa-button type="outline"
                   color="gray"
                   (click)="onCancelForm()"
                   (keypress)="onCancelForm()">{{ 'cancel' | translate }}</xa-button>
        <xa-button type="default"
                   [color]="this.estimateReportForm ? 'primary':'gray'"
                   [disabled]="!this.isEstimateReportFormValid"
                   (click)="onSubmitForm()"
                   (keypress)="onSubmitForm()">{{ 'done' | translate }}</xa-button>
    </div>
</div>
<table mat-table
       matSort
       [dataSource]="dataSource"
       matSortDirection="asc"
       (matSortChange)="sortData($event)"
       matSortDisableClear>
  <ng-container *ngFor="let column of (columns$ | async); index as i;"
                matColumnDef="{{column.id}}">
    <mat-header-cell *matHeaderCellDef
                     mat-sort-header
                     [disabled]="!column.sortable"
                     [style.min-width]="getColumnWidthPlusSpacing(column)"
                     [style.max-width]="getColumnWidthPlusSpacing(column)"
                     [style.padding-inline-end]="this.config?.columnSpacing ?? '5px'"
                     [style]="column.style">
      <div class="mat-header-container"
           *ngIf="column.header.text === ''">
        <div [style]="column.header.style">
          <mat-checkbox #masterCheckbox
                        (change)="toggleMasterSelection($event.checked)"
                        [checked]="allSelected"></mat-checkbox>
        </div>
      </div>
      <div class="mat-header-container"
           *ngIf="column.header.text !== ''">
        <span [style]="column.header.style">{{ column.header.text | translate:{currency: '(' + i18n.currencySymbol + ')'} | uppercase}}</span>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let row"
              (click)="handleCellClicked(row, column, $event)"
              [style]="column?.cellStyle"
              [style.min-width]="getColumnWidthPlusSpacing(column)"
              [style.max-width]="getColumnWidthPlusSpacing(column)"
              [style.padding-inline-end]="column.sortable ? '17px': '17px'">
      <div [style]="column?.widget?.config?.style"
           *ngIf="!column?.childColumns">
        <div *ngIf="column?.widget?.type === 'text'"
             [ngStyle]="{'font-weight': checkStyle(row)}"
             class="text-ellipsis-2">{{row[i]?.result?.value | translate }}</div>
        <div *ngIf="column?.widget?.type === 'date'">{{row[i]?.result?.value | date:'dd/MM/YYYY'}}</div>
        <div *ngIf="column?.widget?.type === 'dateTime'">{{row[i]?.result?.value | date: column?.widget?.dateFormat || 'dd/MM/YYYY HH:mm:ss'}}</div>
        <div *ngIf="column?.widget?.type === 'number'">
          {{row[i]?.result?.value | number : i18n.digitsInfo : i18n.locale }}
        </div>
        <div *ngIf="column?.widget?.type === 'currency'">
          {{row[i]?.result?.value | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
        </div>
        <div *ngIf="column?.widget?.type === 'html'"
             [innerHTML]="row[i]?.result?.value"></div>
        <div *ngIf="column?.widget?.type.toLowerCase() === 'status'">
          <div class="case-status "
               [ngClass]="removeSpacing(row[i]?.result?.value?.split('$#$')[0])"
               style="margin-bottom: 5px;">
              {{ (row[i]?.result?.value?.split('$#$')[0] | translate) }}
          </div>
          <span *ngIf="row[i]?.result?.value?.split('$#$')[1] && row[i]?.result?.value?.split('$#$')[1] !== '-'"
                class="case-status integrated">
            {{row[i]?.result?.value?.split('$#$')[1]}}
          </span>
        </div>
        <div *ngIf="column?.widget?.type === 'checkbox'">
          <ng-container *ngIf="!column?.widget.default">
            <mat-checkbox [checked]="selection.isSelected(row[i])"
            (change)="selection.toggle(row[i])"></mat-checkbox>
          </ng-container>
          <ng-container  *ngIf="column?.widget.default">
            <mat-checkbox [checked]="row[i]?.result?.value"
            (change)="row[i].result.value = !row[i]?.result?.value"></mat-checkbox>
          </ng-container>
        </div>
        <div *ngIf="column?.widget?.type === 'status-check-icon' && checkStatus(row)">
          <mat-icon class="cross"
                    [svgIcon]="column.widget.config.svgIcon"
                    [style]="column?.widget?.config?.style"></mat-icon>
        </div>
        <div *ngIf="column?.widget?.type === 'icon'">
          <span *ngIf="column?.widget.config.text !== ''" [style]="column?.widget?.config?.style"> {{ column?.widget.config.text }}</span>
          <mat-icon [svgIcon]="column.widget.config.svgIcon" *ngIf="column.widget.config.svgIcon"
                    [style]="column?.widget?.config?.style" matTooltip="{{ column?.widget.config.tooltip | translate}}"></mat-icon>
        </div>
        <div *ngIf="column?.widget?.type === 'iconText'" class="icon-div">
          <mat-icon [svgIcon]="column.widget.config.svgIcon" *ngIf="column.widget.config.svgIcon && row[i]?.result?.value"
                    [style]="column?.widget?.config?.style"></mat-icon>
          <div *ngIf="column?.widget.config.valueText" [style]="column?.widget?.config?.styleText"> {{row[i]?.result?.value?.trim()}}</div>
        </div>
        <div *ngIf="column?.widget?.type === 'iconGroup'"
             class="row-icons"
             [style]="column?.widget?.config?.style">
          <div *ngFor="let item of row[i]?.result?.value">
            <mat-icon color="secondary"
                      [svgIcon]="item"></mat-icon>
          </div>
        </div>
        <div *ngIf="column?.widget?.type === 'dropdown'">
          <div class="flex align-center justify-space-between">
            <div *ngIf="row[actionIndex]?.result?.value !== 'edit'">
              {{row[i]?.result?.value?.value}}
            </div>
            <div *ngIf="row[actionIndex]?.result?.value === 'edit'"
                 class="cell-dropdown">
              <chq-input [inputModel]="row[i]?.result.value"
                         (controlOutput)="ondropdownChange($event, row[i], row)">
              </chq-input>
            </div>
            <div *ngIf="!row[i]?.result?.value?.editHide">
              <mat-icon class="cross"
                        [svgIcon]="row[actionIndex]?.result?.value === 'edit' ? 'check' : 'edit'"
                        (click)="changeValue(row[i], i, row)"></mat-icon>
            </div>
          </div>

        </div>
        <div *ngIf="column?.widget?.type === 'dropdown-open'">
          <div class="flex align-center justify-space-between">
            <div class="cell-dropdown">
              <chq-input [inputModel]="row[i]?.result.value"
                         (controlOutput)="ondropdownChange($event, row[i], row)">
              </chq-input>
            </div>
          </div>
        </div>
        <div *ngIf="column?.widget?.type === 'AIAnalysis'">
          <ng-container *ngIf="row[i]?.result?.value === null; else showFlag">
            -
          </ng-container>
          <ng-template #showFlag>
            <mat-icon svgIcon="report-flag"
                      [ngClass]="{'green-flag': row[i]?.result?.value?.toLowerCase() === 'damage_not_detected', 'red-flag': row[i]?.result?.value?.toLowerCase() === 'damage_detected', 'gray-flag': row[i]?.result?.value?.toLowerCase() === 'failed' }"
                      [matTooltip]="row[i]?.result?.value"></mat-icon>
          </ng-template>

        </div>
        <div *ngIf="column?.widget?.type === 'Discrepancy'">
          <ng-container *ngIf="row[i]?.result?.value === null; else showFlag">
            -
          </ng-container>
          <ng-template #showFlag>
            <mat-icon svgIcon="report-flag"
                      [ngClass]="{'green-flag': row[i]?.result?.value?.toLowerCase() === 'no_discrepancy_observed', 'red-flag': row[i]?.result?.value?.toLowerCase() === 'discrepancy_observed' }"
                      matTooltip="{{ row[i]?.result?.value | translate }}"></mat-icon>
          </ng-template>

        </div>
        <div *ngIf="column?.widget?.type === 'logo'">
          <img [src]="row[i]?.result?.value"
               style="width:50px;height:50px"
               alt="logo"
               *ngIf="row[i]?.result?.value" />
        </div>
        <div *ngIf="column?.widget?.type === 'editable'">
          <mat-icon *ngIf="!isEditable(row)"
                    svgIcon="edit"
                    style="fill: var(--xa-black) !important"
                    (click)="makeEditable(row)"></mat-icon>
          <mat-icon *ngIf="isEditable(row)"
                    svgIcon="check"
                    [ngClass]="{'disable-icons':!isRowValid(row)}"
                    style="fill: var(--xa-black) !important"
                    (click)="makeEditable(row)"></mat-icon>
        </div>
        <div *ngIf="column?.widget?.type === 'input'">
          <ng-container *ngIf="isEditable(row); else notEditable">

            <xa-text [(ngModel)]="row[i].result.value"
                     (keyup)="validationCheck(row[i], !name.invalid)"
                     [minlength]="column?.widget?.validations?.minLength"
                     [name]="column.header.text"
                     [pattern]="column?.widget?.validations?.pattern"
                     #name="ngModel"></xa-text>
            <div *ngIf="name.invalid && (name.dirty || name.touched)"
                 class="alert alert-validation">
              <div *ngIf="name.hasError('minlength')">
                {{'minLength'| translate: { min: (column?.widget?.validations?.minLength || '1') } }}
              </div>
              <div *ngIf="name.hasError('pattern') && !name.hasError('minlength')">
                {{'pattern_numeric'| translate}}
              </div>
            </div>
          </ng-container>
          <ng-template #notEditable>
            {{row[i]?.result?.value}}
          </ng-template>
        </div>
        <div *ngIf="column?.widget?.type === 'input_number'">
          <ng-container *ngIf="isEditable(row); else notEditable">

            <xa-numeric [(ngModel)]="row[i].result.value"
                        (keyup)="validationCheck(row[i], !name.invalid)"
                        [minlength]="column?.widget?.validations?.minLength"
                        [name]="column.header.text"
                        [min]="column?.widget?.validations?.max ? (column?.widget?.validations?.max[maxRowIndex(row, column)] || undefined):undefined"
                        [pattern]="column?.widget?.validations?.pattern"
                        (maxEventTriggered)="showErrorToast(column?.widget?.validations?.maxValidationMessage)"
                        #name="ngModel"></xa-numeric>
            <div *ngIf="name.invalid && (name.dirty || name.touched)"
                 class="alert alert-validation">
              <div *ngIf="name.hasError('minlength')">
                {{'minLength'| translate: { min: (column?.widget?.validations?.minLength || '1') } }}
              </div>
              <div *ngIf="name.hasError('pattern') && !name.hasError('minlength')">
                {{'pattern_numeric'| translate}}
              </div>
            </div>
          </ng-container>
          <ng-template #notEditable>
            {{row[i]?.result?.value}}
          </ng-template>
        </div>
        <div *ngIf="column?.widget?.type === 'disable_delete_action'">
          <div class="disable-delete-action" *ngIf="row[i].result.value.deleteAllowed">
            <xa-toggle [(ngModel)]="row[i].result.value.enable" name="enable" (ngModelChange)="toggleChange(row, $event)" #enable></xa-toggle>
            <mat-icon color="dark-red2" svgIcon="delete"  (click)="handleRowDelete(row, $event)"></mat-icon>
          </div>
        </div>
      </div>
      <div [style]="column?.widget?.config?.style"
           *ngIf="column?.childColumns">
        <ng-container *ngFor="let child of column.childColumns">
          <mat-icon color="secondary"
                    title="{{(!row[i]?.result?.value) ? child.selector.field + ' is not available': ''}}"
                    [ngClass]="{ 'disable-icons': !row[i]?.result?.value && child.disableColumns}"
                    [svgIcon]="child?.widget?.config?.svgIcon"
                    (click)="handleCellClicked(row, child, $event)"></mat-icon>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <div *matNoDataRow
       class="no-data"><span *ngIf="tableData && tableData.length === 0">{{'no_data_available' | translate}}</span></div>
</table>
<div [ngClass]="{'sm': smDevice}" class="layout-content">
    <div class="side-menu-content">
        <side-menu [hidden]="hideHeader" [sideMenuExpanded]="isSideMenuExpanded" (sideMenuCollapse)="isSideMenuExpanded = false"></side-menu>
    </div>
    <div class="main-content">
        <div class="header-content">
            <header [hidden]="hideHeader" [avatar]="avatar" [avatarText]="avatarText" (searchEvent)="handleSearchEvent($event);" (toggle)="onToggle()"></header>
        </div>
        <div class="addenda-container" [hidden]="showSearchDetail">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as piexif from 'piexifjs';

@Injectable({
  providedIn: 'root' })export class CameraService {
  private stream: MediaStream | null = null;
  public videoObjectObservable:BehaviorSubject<any> = new BehaviorSubject<any>(null); 

  /**
   * 
   * @param ngZone 
   */
  constructor(
    private ngZone: NgZone) {
  }

  /**
   * 
   * @param videoElement 
   * initCamera
   */
  async initCamera(videoElement: HTMLVideoElement, useBackCamera?:boolean, isVideo?:boolean): Promise<void> {
    const constraints:any = {
      width: { ideal: isVideo ? 1280 : 1920, max: isVideo? 1280: 1920 },
      facingMode: useBackCamera? 'environment': '', 
      //facingMode: 'environment', //Forcing back camera can be changed later
      height: { ideal: isVideo ? 720 : 1080, max: isVideo? 720: 1080 } };
    if(isVideo){
      constraints.frameRate = { ideal: 15, max: 15 } 
    }
   
    this.stream = await navigator.mediaDevices.getUserMedia({ video: constraints, audio: false });
    videoElement.srcObject = this.stream;
  }

  /**
   * insert exit data from device
   */
  insertExifData(dataUrl:string, position:any, height:number, width:number):Blob{
    
    const latitude = position?.latitude || '';
    const longitude = position?.longitude || '';

    // Convert latitude and longitude to EXIF GPS format
    const toDMS = (degrees):any[][]=> {
      const deg = Math.floor(degrees);
      const min = Math.floor((degrees - deg) * 60);
      const sec = ((degrees - deg - min / 60) * 3600).toFixed(2);
      return [ [ deg, 1 ], [ min, 1 ], [ sec, 100 ] ];
    }

    const gpsLatitude = latitude ? toDMS(latitude): '';
    const gpsLongitude = longitude ? toDMS(longitude): '';
    const gpsLatitudeRef = latitude >= 0 ? 'N' : 'S';
    const gpsLongitudeRef = longitude >= 0 ? 'E' : 'W';

    // Capture current date and time
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ').replace(/-/g, ':'); // YYYY:MM:DD HH:MM:SS format

    // Additional browser information (optional)
    const browserInfo = {
      userAgent: navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      language: navigator.language,
      platform: navigator.platform
    };

    // 2. Inject date/time, browser info, and GPS location into EXIF metadata
    const exifObj = {
      '0th': {},
      'Exif': {},
      'GPS': {},
      'Interop': {},
      '1st': {},
      'thumbnail': null
    };

    // Date and Time metadata
    exifObj['0th'][piexif.ImageIFD.DateTime] = formattedDateTime;
    exifObj['0th'][piexif.ImageIFD.XResolution] = [ width, 1 ];
    exifObj['0th'][piexif.ImageIFD.YResolution] = [ height, 1 ];
    exifObj['0th'][piexif.ImageIFD.Software] = 'Addenda';
    exifObj['0th'][piexif.ImageIFD.Make] = 'AddendaCamera'
    exifObj['0th'][piexif.ImageIFD.Model] = 'AddendaModel'
    exifObj['Exif'][piexif.ExifIFD.LensMake] = 'LensMake';
    exifObj['Exif'][piexif.ExifIFD.Sharpness] = 777;
    exifObj['Exif'][piexif.ExifIFD.DateTimeOriginal] = formattedDateTime;

    // Browser information (optional)
    exifObj['Exif'][piexif.ExifIFD.UserComment] = `
        User Agent: ${browserInfo.userAgent}
        Screen: ${browserInfo.screenWidth}x${browserInfo.screenHeight}
        Language: ${browserInfo.language}
        Platform: ${browserInfo.platform}
    `;

    // GPS metadata
    exifObj['GPS'][piexif.GPSIFD.GPSLatitude] = gpsLatitude;
    exifObj['GPS'][piexif.GPSIFD.GPSLatitudeRef] = gpsLatitudeRef;
    exifObj['GPS'][piexif.GPSIFD.GPSLongitude] = gpsLongitude;
    exifObj['GPS'][piexif.GPSIFD.GPSLongitudeRef] = gpsLongitudeRef;
    exifObj['GPS'][piexif.GPSIFD.GPSVersionID] = [ 7, 7, 7, 7 ];
    exifObj['GPS'][piexif.GPSIFD.GPSDateStamp] = formattedDateTime;

    // 4. Insert EXIF metadata into the image
    const exifStr = piexif.dump(exifObj);
    const dataURLWithExif = piexif.insert(exifStr, dataUrl);

    // 5. Convert the image with metadata into a Blob
    const dataURLtoBlob = (dataurl):Blob=> {
      const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([ u8arr ], { type: mime });
    }

    return dataURLtoBlob(dataURLWithExif);

  }

  /**
   * 
   * @param videoElement 
   * initCamera
   */
  async initVideo(videoElement: HTMLVideoElement, useBackCamera?:boolean): Promise<MediaRecorder> {
    await this.initCamera(videoElement, useBackCamera, true);
    const mime = { mimeType: 'video/mp4', }
    if (!MediaRecorder.isTypeSupported('video/mp4')) { // <2>
      mime.mimeType = 'video/webm'
    }
    const mediaRecorder = new MediaRecorder(this.stream, { ...mime, videoBitsPerSecond: 4 * 1024 * 1024 
    });

    if(this.videoObjectObservable?.closed || !this.videoObjectObservable ){
      this.videoObjectObservable = new BehaviorSubject<Blob>(null); 
    }

    mediaRecorder.addEventListener('dataavailable', (event) => {
      this.videoObjectObservable.next({ 'data': event.data, 'action': 'blob' }) // <6>
    })
    return mediaRecorder;
  }

  /**
   * get camera height widgh
   */
  getCameraHeightWidth():any{
    return {
      height: this.stream?.getVideoTracks()[0].getSettings().height || 0,
      width: this.stream?.getVideoTracks()[0].getSettings().width || 0
    }
  }

  /**
   * start recording
   */
  startRecording(mediaRecorder:MediaRecorder):void{
    mediaRecorder.start();
  }

  /**
   * stop Recording
   */
  stopRecording(mediaRecorder:MediaRecorder, recordedBlobs:any[]):void{
    mediaRecorder.onstop = async ():Promise<void> => {
      this.ngZone.run(async () => {
        let rotate = false;
        if(window.innerHeight > window.innerWidth){
          rotate = true;
        }
        if (MediaRecorder.isTypeSupported('video/mp4')) { 
          const blb = new Blob([ ...recordedBlobs ], { type: 'video/mp4' });
          this.videoObjectObservable.next( { 'data': new File([ blb ], 'output.mp4'), 'action': 'upload' });
        }else{
          const blb = new Blob([ ...recordedBlobs ], { type: 'video/webm' });
          this.videoObjectObservable.next( { 'data': new File([ blb ], 'output.webm'), 'action': 'upload' });
        }
       
        mediaRecorder = null;
      });
    };
    try{
      mediaRecorder.stop();
    }catch(e){
      console.log(e);
    }
    
    this.stopCamera();
  }

  /**
   * stop camera
   */
  stopCamera(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }
  
}
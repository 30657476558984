import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface Terms {
  title: string;
  description: string;
}
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: [ './terms-conditions.component.scss' ]
})

export class TermsConditionsComponent {
  terms: Array<Terms> = [
    {
      title: this.translate.instant('data_protection_law_title'),
      description: this.translate.instant('data_protection_law_description'),
    },
    {
      title: this.translate.instant('consent_title'),
      description: this.translate.instant('consent_description'),
    },
    {
      title: this.translate.instant('purpose_limitation_title'),
      description: this.translate.instant('purpose_limitation_description'),
    },
    {
      title: this.translate.instant('data_minimization_title'),
      description: this.translate.instant('data_minimization_description'),
    },
    {
      title: this.translate.instant('accuracy_title'),
      description: this.translate.instant('accuracy_description'),
    },
    {
      title: this.translate.instant('security_title'),
      description: this.translate.instant('security_description'),
    },
    {
      title: this.translate.instant('data_retention_title'),
      description: this.translate.instant('data_retention_description'),
    },
    {
      title: this.translate.instant('access_correction_title'),
      description: this.translate.instant('access_correction_description'),
    },
    {
      title: this.translate.instant('cross_border_data_transfer_title'),
      description: this.translate.instant('cross_border_data_transfer_description'),
    },
    {
      title: this.translate.instant('regulatory_notifications_title'),
      description: this.translate.instant('regulatory_notifications_description'),
    },
    {
      title: this.translate.instant('breach_notification_title'),
      description: this.translate.instant('breach_notification_description'),
    }
  ];

  /**
   * constructor
   * @param dialogRef 
   */
  constructor(
    public dialogRef: MatDialogRef<TermsConditionsComponent>,
    private translate: TranslateService
  ){}

  /**
 * close
 */
  close(): void {
    this.dialogRef.close();
  }
}

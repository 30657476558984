<div class="collapsible-widget">
    <mat-accordion>
        <mat-expansion-panel #mep>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <label class="graph-title">{{titleText | translate }}</label>
                    <mat-icon 
                        *ngIf="iconOptions"
                        [svgIcon]="iconOptions.icon" 
                        [color]="iconOptions.iconColor" 
                        [matTooltip]="iconOptions.tooltipText"
                        [matTooltipClass]="iconOptions.iconClass"
                        [matTooltipPosition]="iconOptions.tooltipPosition"
                    ></mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-content></ng-content>
        </mat-expansion-panel>
    </mat-accordion>
</div> 
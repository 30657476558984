import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-claim-assign-modal',
  templateUrl: './claim-assign-modal.component.html',
  styleUrls: [ './claim-assign-modal.component.scss' ]
})
export class ClaimAssignModalComponent {
  bookingGuid: string = ''
  notificationGuid: string = ''
  approveRejectCompleted: boolean = false;
  showApprovedOrRejected = '';
  formViewModel = {
    CustomerDetails: {
      OrganizationName: {
        label: 'Insurance Org Name',
        type: 'text',
        placeHolder: '',
        name: 'OrganizationName',
        class: 'col-12',
        value: '',
        disabled: true
      },
      FullName: {
        label: 'Policy holder name',
        type: 'text',
        placeHolder: '',
        name: 'FullName',
        class: 'col-12',
        value: '',
        disabled: true
      },
      CountryCode: {
        label: 'Code',
        type: 'text',
        placeHolder: '',
        name: 'CountryCode',
        class: 'col-3',
        value: '',
        disabled: true
      },
      MobileNumber: {
        label: 'Mobile Number',
        type: 'text',
        placeHolder: '',
        name: 'MobileNumber',
        class: 'col-9',
        value: '',
        disabled: true
      },
      PolicyNo: {
        label: 'Policy Number',
        type: 'text',
        placeHolder: '',
        name: 'PolicyNo',
        class: 'col-12',
        value: '',
        disabled: true
      },
      ClaimNo: {
        label: 'Claim Number',
        type: 'text',
        placeHolder: '',
        name: 'ClaimNo',
        class: 'col-12',
        value: '',
        disabled: true
      }
    },
    VehicleMetaData: {
      Make: {
        label: 'Make',
        type: 'text',
        placeHolder: '',
        name: 'Make',
        class: 'col-6',
        value: '',
        disabled: true
      },
      Model: {
        label: 'Model',
        type: 'text',
        placeHolder: '',
        name: 'Model',
        class: 'col-6',
        value: '',
        disabled: true
      }
    }
  }

  public approveButtonModel: ChqWidgetsButtonModel = {
    label: 'Approve',
    type: 'primary',
    icon: '',
    width: '230px',
    onclick: this.approveForm.bind(this)
  }

  public rejectButtonModel: ChqWidgetsButtonModel = {
    label: 'Reject',
    type: 'red',
    icon: '',
    width: '230px',
    onclick: this.rejectForm.bind(this)
  }

  public repairButtonModel: ChqWidgetsButtonModel = {
    label: 'View Repair',
    type: 'primary',
    icon: '',
    width: '230px',
    onclick: this.viewRepair.bind(this)
  }

  /**
   * Creates an instance of ClaimAssignModalComponent.
   * @date 12/28/2023 - 6:59:50 PM
   *
   * @constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClaimAssignModalComponent>,
    private readonly bookingService: BookingService,
    private readonly commonService: CommonService,
    private readonly router: Router
  ) {
    this.bookingGuid = data['bookingGuid'];
    this.notificationGuid = data['notificationGuid'];
    const type = data['type'];
    if (type?.indexOf('Approve') !== -1) {
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Approved';
    } else if (type?.indexOf('Reject') !== -1) {
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Rejected';
    } else if (type?.indexOf('Reassigned') !== -1) {
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Reassigned';
    }

    for (const key in this.formViewModel) {
      if (key === 'CustomerDetails' || key === 'VehicleMetaData') {
        for (const key2 in this.formViewModel[key]) {
          if (key2 === 'FullName') {
            if (data['CustomerDetails']) {
              this.formViewModel[key][key2].value = data['CustomerDetails']['FirstName'] + ' ' + data['CustomerDetails']['LastName'];
            }
          } else {
            if (data[key]) {
              this.formViewModel[key][key2].value = data[key][key2];
            }
          }
        }
      } else {
        this.formViewModel[key].value = data[key];
      }
    }
  }

  /**
   * cancelDialog
   * @param {*} data
   */
  cancelDialog(data: any): void {
    this.dialogRef.close(data);
  }

  /**
   * approveForm
   */
  approveForm(): void {
    this.commonService.showLoading();
    this.bookingService.updateClaimRepairNotificationStatus(this.notificationGuid, this.bookingGuid, 'Accepted').subscribe({
      next: (data) => {
        this.commonService.hideLoading();
        this.approveRejectCompleted = true;
        if (data.success) {
          this.commonService.showInfoToast(5000, data.message);
          this.showApprovedOrRejected = 'Approved';
          this.router.navigate([ `repair/booking/${data?.data.repairGuid}` ]);
          this.dialogRef.close();
        }
      },
      error: (data) => {
        this.commonService.hideLoading();
        if (data.error.errorMessages[0].error.toLowerCase().indexOf('already') !== -1) {
          this.approveRejectCompleted = true;
        }
      }
    })
  }


  /**
   * rejectForm
   */
  rejectForm(): void {
    this.commonService.showLoading();
    this.bookingService.updateClaimRepairNotificationStatus(this.notificationGuid, this.bookingGuid, 'Rejected').subscribe({
      next: (data) => {
        this.commonService.hideLoading();
        this.approveRejectCompleted = true;
        if (data.success) {
          this.showApprovedOrRejected = 'Rejected';
          this.commonService.showInfoToast(5000, data.message);
          this.dialogRef.close();
        }
      },
      error: (data) => {
        this.commonService.hideLoading();
        if (data.error.errorMessages[0].error.toLowerCase().indexOf('already') !== -1) {
          this.approveRejectCompleted = true;
          this.showApprovedOrRejected = 'Rejected';
        }
      }
    })
  }

  /**
   * viewRepair
   */
  viewRepair(): void {
    this.dialogRef.close();
    this.router.navigate([ `repair/booking/${this.bookingGuid}` ]);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { CoreService } from 'src/app/services/core/core.service';

@Injectable({
  providedIn: 'root',
})
export class EstimateSettingGuard implements CanActivate {
  /**
   * constructor
   * @param router
   */
  constructor(
    private readonly commonService: CommonService,
    private readonly coreService: CoreService,
    private readonly msalGuard: MsalGuard,
    private readonly router: Router
  ) { }

  /**
   * canActivate
   * @returns
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.msalGuard.canActivate(next, state)) {
      return this.checkUserPermission(next, state);
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }

  /**
  * checkUserPermission
  * @returns
  */
  checkUserPermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.commonService.userProfileData.pipe(
      switchMap((res: any) => {
        if (res) {
          let roleCheckResult = this.roleCheck(res);
          if (roleCheckResult && (state.url === '/settings/estimate/tax' || state.url === '/settings/estimate/invoice')) {
            roleCheckResult = this.estimateSettingPermissionCheck(res);
          }
          return of(roleCheckResult);
        } else {
          this.commonService.showLoading();
          return this.coreService.getUserProfile().pipe(
            switchMap((res) => {
              if (res) {
                this.commonService.hideLoading();
                this.commonService.userProfileData.next(res);
                if (res?.data.userProfileThumbnailImageBlobUrl || res?.data.userProfileImageBlobUrl) {
                  this.commonService.avatar.next({
                    image: res?.data.userProfileThumbnailImageBlobUrl ?? res?.data.userProfileImageBlobUrl
                  });
                }
                if (res?.data?.userPermission?.automotiveServices) {
                  let roleCheckResult = this.roleCheck(res);
                  if (roleCheckResult && (state.url === '/settings/estimate/tax' || state.url === '/settings/estimate/invoice')) {
                    roleCheckResult = this.estimateSettingPermissionCheck(res);
                  }
                  return of(roleCheckResult);
                } else {
                  this.router.navigate([ 'home' ]);
                  return of(true);
                }
              } else {
                this.commonService.hideLoading();
                this.router.navigate([ 'home' ]);
                return of(false);
              }
            })
          )
        }
      })
    );
  }

  /**
   * 
   * @param res 
   */
  private estimateSettingPermissionCheck(res: any): boolean {
    const orgPackages = res?.data?.userPermission?.orgPackages;
    const repairPackage = orgPackages.find(el => el.automotiveServiceName === 'Repair Estimate');
    const repairPermission = repairPackage?.permissions?.find(p => p.permissionName === 'xa.repair.estimate' || p.permissionName === 'audatex.estimate');
    const AIPackage = orgPackages.find(el => el.automotiveServiceName === 'Artificial Intelligence Estimation');
    const AIPermission = AIPackage?.permissions?.find(p => p.permissionName === 'ai.estimation');
    const returnValue = (repairPermission !== undefined || AIPermission !== undefined);

    if (!returnValue) {
      this.router.navigate([ 'settings' ]);
    }

    return returnValue;
  }

  /**
   * 
   * @returns 
   */
  private roleCheck(res: any): boolean {
    const automotiveServices = res?.data?.userPermission?.automotiveServices;
    this.commonService.setPermission(automotiveServices);
    const automotiveService = automotiveServices?.filter(x => [ 'addenda repair', 'carheal quote' ].includes(x.automotiveServiceName.toLowerCase()));

    const roleRank = {
      'bodyshop manager': 1,
      'service advisor': 2,
      'technician': 3,
      'service desk': 4,
      'driver': 5
    }

    let highestRole = 'driver'

    automotiveService.forEach((service) => {
      if (roleRank[service.roleName.toLowerCase()] < roleRank[highestRole]) {
        highestRole = service.roleName.toLowerCase();
      }
    });

    if (highestRole) {
      if (highestRole === 'bodyshop manager') {
        return true;
      } else if (highestRole === 'service advisor') {
        this.router.navigate([ 'repair/booking-journal' ]);
        return false;
      } else if (highestRole === 'technician') {
        this.router.navigate([ 'repair/technician-calendar' ]);
        return false;
      } else if (highestRole === 'service desk') {
        this.router.navigate([ 'repair/lounge' ]);
        return false;
      } else if (highestRole === 'driver') {
        if (this.router.url.indexOf('/outbound') !== -1) {
          this.router.navigate([ 'repair/outbound' ]);
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      this.router.navigate([ 'home' ]);
      return false;
    }
  }
}

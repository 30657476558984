import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ar';
import * as isBetween from 'dayjs/plugin/isBetween';
import { BehaviorSubject, map, Observable, Observer, Subject } from 'rxjs';
import { RepairStatusMapper, RepairStatusToPermissionMapper } from 'src/app/config/route-mapper/repair-status-mapper';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogBigComponent } from 'src/app/dialogs/confirm-dialog-big/confirm-dialog-big.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ToastComponent } from 'src/app/dialogs/toast/toast.component';
import { UploadStepModel } from 'src/app/model/chq-upload-model';
import * as CryptoJS from 'crypto-js';
import { AudatexAESKey } from 'src/app/config/constants/app.constants';
import { menuItem } from 'src/app/model/menu';
import { environment } from 'src/environments/environment';

import { loadMessages, locale } from 'devextreme/localization';
import arMessages from 'devextreme/localization/messages/ar.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { Direction } from '@angular/cdk/bidi';

dayjs.extend(isBetween);

export type DisplayConfig = {
  title: string;
  subTitle: string;
};

export interface Localization {
  locale?: string;
  timezone?: string;
  digitsInfo?: string;
  currencySymbol?: string;
  position?: string;
}

const defaulti18nInfo: Localization = {
  locale: 'en-In',
  digitsInfo: '1.2-2',
  currencySymbol: 'Rs.',
  position: 'start'
}

const currencyMapper = {
  'INR': {
    symbol: 'Rs.',
    position: 'start',
    locale: 'en-In'
  },
  'USD': {
    symbol: 'USD',
    position: 'start',
    locale: 'en'
  },
  'AED': {
    symbol: 'AED',
    position: 'end',
    locale: 'en'
  },
  'EUR': {
    symbol: 'EUR',
    position: 'start',
    locale: 'en'
  },
  'QR': {
    symbol: 'QR',
    position: 'end',
    locale: 'en'
  },
  'SAR': {
    symbol: 'SAR',
    position: 'end',
    locale: 'en'
  }
}

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {
  isLoading: boolean = false;
  titleConfig$: BehaviorSubject<DisplayConfig> = new BehaviorSubject<DisplayConfig>(null);
  private pageChanged = new Subject<string[]>();
  pageChange$ = this.pageChanged.asObservable();
  notificationMessage: string = '';
  displayNotification: boolean = false;
  pageName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  caseId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  caseStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  inspectionId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  reloadUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  pdfLoad: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userProfileLoading: boolean = false;
  userProfileData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  maxFileSize: number = 5 * 1024 * 1024;
  inProgressSteps: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  allowExtension: Array<any> = [ 'png', 'jpg', 'jpeg' ];
  allowFilesExtension: Array<any> = [ 'png', 'jpg', 'jpeg', 'xml', 'pdf' ];
  avatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accessRight: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  bookingId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  domainId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  repairGuid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  actionTriggered: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  repairDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  claimDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  thirdPartyClaimDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  closeSearch: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  reloadChartData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  tabMenus: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private fromToastComponent = new Subject<any>();
  public eventfromToastComponent$ = this.fromToastComponent.asObservable();
  userPermission: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  repairPermission: boolean = false;
  quotePermission: boolean = false;
  claimPermission: boolean = false;
  fnolPermission: boolean = false;
  quoteAssessmentPermission: boolean = false;
  customerDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  activeItem: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  viewActionTriggeredBooking: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  reloadDetail: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  locale: string = localStorage.getItem('locale') || 'en';
  digitsInfo: string;
  currencySymbol: string;
  symbolPosition: string;
  repairInspectionTemplate: any;
  claimInspectionTemplate: any;
  roleName: string = '';
  repairStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  claimStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  itemStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  displayImage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isViewOnly: boolean = false;
  labourData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accessRightRepair: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  claimWorkflowStage: string = '';
  userId: number;
  claimAssigneeId: number;
  claimCreatedId: number;
  fraudStatus: string;
  fnolId: string;
  isThirdPartyExternalGarage: boolean = false;
  claimId: number;
  claimCreatedFromSurveyor: boolean = false;
  applicationFeatureData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // To Resolve - Bug 21819: Incorrect total paint cost calculation is shown in repair estimation
  // As per Sajna refresh the estimate data when discount is changed
  isRefreshRepairEstimateDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  caseDetail: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  activeForm: BehaviorSubject<{
    isEdit?:boolean,
    isCopy?:boolean,
    isDelete?:boolean,
    isDisable?:boolean
  }> = new BehaviorSubject<{
    isEdit?:boolean,
    isCopy?:boolean,
    isDelete?:boolean,
    isDisable?:boolean
  }>(null);
  surveyorAttachments: any = [];

  private generalCommMessage = new BehaviorSubject('default message');
  public generalCommOpen$ = this.generalCommMessage.asObservable();
  tabChange$: BehaviorSubject<menuItem> = new BehaviorSubject<menuItem>(null);
  customerSurveyorInspection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  mediaTemplates: any;
  documentFraudStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  documentFraudCall: boolean = false;
  onlySurveyorImages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  externalClaimDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  requestCustomer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  caseManagementStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  upSaleItemMarked: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setParameterMenuActive: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isUpSalesSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRepairOrderVehicle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRepairOrderCustomer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cloneRepairOrderData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  objectId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  estimateReportInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerEstimationDetailMethod = new Subject<void>();
  triggerEstimationDetailMethod$ = this.triggerEstimationDetailMethod.asObservable();
  private readonly audatexKey = CryptoJS.enc.Utf8.parse(AudatexAESKey);

  private readonly localeSubject = new BehaviorSubject<string>(localStorage.getItem('locale') || 'en'); // Default locale
  locale$ = this.localeSubject.asObservable();
  direction: Direction = 'ltr';

  /**
   * constructor
   */
  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly httpClient: HttpClient,
    private readonly msalService: MsalService,
    public dialog: MatDialog,
    public platform: Platform
  ) {
  }



  /**
   * ng on destry
   */
  ngOnDestroy(): void {
    console.log('commonservice is destroyed');
  }
  /**
   * encrypt AES
   *
   * @param {string} text
   * @returns {string}
   */
  encryptAES(text: string): string {
    const encryptedBytes = CryptoJS.AES.encrypt(text, this.audatexKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedBytes.toString();

  }

  /**
   * decrypt AES
   *
   * @param {string} text
   * @returns {string}
   */
  decryptAES(text: string): string {
    const decryptedBytes = CryptoJS.AES.decrypt(text, this.audatexKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  }

  /**
   * return access token
   */
  getAccessToken(): Observable<string> {
    const request = {
      redirectStartPage: '/',
      scopes: [ 'openid', 'profile', `${environment.b2cSettings.caseManagementAppClientId}` ]
    };
    return this.msalService.acquireTokenSilent(request).pipe(map(el => el.accessToken))
  }

  /**
   * hide Notification
   */
  hideNotification(): void {
    this.displayNotification = false;
  }


  /**
   * image reader observable
   */
  /**
   * file upload observable
   */
  imageReaderObservable(url: string): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = (): void => {
        const reader = new FileReader();
        reader.onload = (): void => {
          observer.next({ image: reader.result });
          observer.complete();
        }
        reader.readAsDataURL(xhr.response);
      }
      xhr.onerror = (): void => {
        observer.error({ errorMessage: xhr.statusText });
        observer.complete();
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
    return sequence;
  }

  /**
   * blob observable
   */
  /**
   * file upload observable
   */
  blobObservableSize(url: string): Observable<Blob> {
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = (): void => {
        const blob = new Blob([ xhr.response ], {
          type: xhr.response.type
        });

        observer.next(blob);
        observer.complete();
      }
      xhr.onerror = (): void => {
        observer.error({ errorMessage: xhr.statusText });
        observer.complete();
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.setRequestHeader('Ocp-Apim-Subscription-Key', environment.subscriptionKey);
      xhr.send();
    });
    return sequence;
  }

  /**
   * blob observable
   */
  /**
   * file upload observable
   */
  blobObservable(url: string): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = (): void => {
        const blob = new Blob([ xhr.response ], {
          type: 'application/pdf',
        });
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        observer.next({ fileURL: href });
        observer.complete();
      }
      xhr.onerror = (): void => {
        observer.error({ errorMessage: xhr.statusText });
        observer.complete();
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.setRequestHeader('Ocp-Apim-Subscription-Key', environment.subscriptionKey);
      xhr.send();
    });
    return sequence;
  }

  /**
   *
   */
  blobObservable1(url: string): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      const localTime = new Date().toString();
      const headers = new HttpHeaders().append('localtime', localTime)
      this.httpClient.get(url, { responseType: 'blob', headers }).subscribe({
        next: (res) => {
          const blob = new Blob([ res ], { type: 'application/pdf', });
          const windowUrl = window.URL || window.webkitURL;
          const href = windowUrl.createObjectURL(blob);
          observer.next({ fileURL: href });
          observer.complete();
        },
        error: () => {
          observer.error({ errorMessage: xhr.statusText });
          observer.complete();
        }
      })
    });
    return sequence;
  }

  /**
   *
   */
  getFileBlob(url: string): Observable<any> {
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      this.httpClient.get(url, { responseType: 'blob' }).subscribe({
        next: (res) => {
          observer.next({ res });
          observer.complete();
        },
        error: () => {
          observer.error({ errorMessage: xhr.statusText });
          observer.complete();
        }
      })
    });
    return sequence;
  }

  /**
   * update page header
   * @param state
   */
  updatePage(menu: string, subtitle?: string): void {
    this.pageChanged.next([ menu, subtitle ]);
  }

  /**
   * update case status
   * @param state
   */
  updateCaseStatus(status: string): void {
    this.caseStatus.next(status);
  }



  /**
   * show loading
   */
  showLoading(): void {
    this.isLoading = true;
    const el: any = document.getElementById('custom-loader');
    if (el) {
      el.style.display = 'none';
    }
  }

  /**
   * hide loading
  */
  hideLoading(): void {
    this.isLoading = false;
  }

  /**
   * show toast
   * @param message
   */
  showToast(status: number, message: string): void {
    this._snackBar.openFromComponent(ToastComponent, {
      data: { message, preClose: () => { this._snackBar.dismiss() } },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 10000,
      panelClass: 'http-toast'
    })
  }

  /**
   * hex to rgg
   */
  hexToRgb(hexStr: string): any {
    const col: any = {};
    col.r = parseInt(hexStr.substr(1, 2), 16);
    col.g = parseInt(hexStr.substr(3, 2), 16);
    col.b = parseInt(hexStr.substr(5, 2), 16);
    return col;
  }


  /**
   * Applies the Sobel operator to the image data to detect edges in the image.
   * The Sobel operator is a 3x3 kernel that slides over the image and uses the
   * gradient of the image intensity to detect edges. The resulting edge data
   * is a grayscale image with edge pixels set to white (255) and non-edge pixels
   * set to black (0). The threshold for the edge detection is set to 128, so
   * only pixels with a gradient greater than 128 will be detected as edges.
   * @param imageData The image data to be processed.
   * @param width The width of the image.
   * @param height The height of the image.
   * @returns An array of edge data. Each element of the array is a pixel of the
   * edge image, with 0 indicating a non-edge pixel and 255 indicating an edge pixel.
   */
  private sobelEdgeDetection(canvas: HTMLCanvasElement, color: string): any {
    const cv = (window as any).cv;
    const src = cv.imread(canvas); // Ensure the imageSrc element exists

    // Convert image to RGBA (if it's not already in that format)
    const rgba = new cv.Mat();
    if (src.channels() === 4) {
      src.copyTo(rgba);
    } else if (src.channels() === 3) {
      cv.cvtColor(src, rgba, cv.COLOR_RGB2RGBA);
    } else {
      console.error('Unsupported image format');
      return;
    }

    // Convert to grayscale
    const gray = new cv.Mat();
    cv.cvtColor(rgba, gray, cv.COLOR_RGBA2GRAY);

    // Apply Sobel operator to find edges
    const gradX = new cv.Mat();
    const gradY = new cv.Mat();
    const absGradX = new cv.Mat();
    const absGradY = new cv.Mat();
    const edges = new cv.Mat();

    // Sobel in x direction
    cv.Sobel(gray, gradX, cv.CV_16S, 1, 0);
    cv.convertScaleAbs(gradX, absGradX);

    // Sobel in y direction
    cv.Sobel(gray, gradY, cv.CV_16S, 0, 1);
    cv.convertScaleAbs(gradY, absGradY);
    const hex = this.hexToRgb(color);
    const lineColor = new cv.Scalar(hex.r, hex.g, hex.b, 255);
    const overlayColor = new cv.Scalar(hex.r, hex.g, hex.b, 155);


    // Combine gradients
    cv.addWeighted(absGradX, 0.5, absGradY, 0.5, 0, edges);
    const mask = new cv.Mat();
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    cv.threshold(edges, mask, 50, 255, cv.THRESH_BINARY); // Adjust threshold to fine-tune edges
    cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);


    // Fill all contours (the regions inside the edges) with white
    for (let i = 0; i < contours.size(); i++) {
      cv.drawContours(rgba, contours, i, overlayColor, cv.FILLED); // FILL the contour
    }

    // Flood fill outside of the edges with transparent
    // Invert the mask to get regions outside the edges
    const maskInv = new cv.Mat();
    cv.bitwise_not(mask, maskInv); // Inverted mask where outside is white (255)

    // Make the regions inside the edges white
    rgba.setTo(lineColor, mask); // Inside edges are white


    cv.imshow(canvas, rgba);

    if (src) src.delete();
    if (rgba) rgba.delete();
    if (gray) gray.delete();
    if (gradX) gradX.delete();
    if (gradY) gradY.delete();
    if (absGradX) absGradX.delete();
    if (absGradY) absGradY.delete();
    if (edges) edges.delete();

  }

  /**
   * Changes the color of all pixels within the detected region of the
   * provided image data. The detected region is determined by performing
   * a flood fill starting at the specified (x, y) coordinates and
   * bounded by the edges of the image. The new color is specified as an
   * array of four values: [R, G, B, A], where each value is between 0 and
   * 255. The resulting image data is returned.
   * @param {ImageData} imageData - The image data to modify
   * @param {number} canvasWidth - The width of the canvas
   * @param {number} canvasHeight - The height of the canvas
   * @param {Array<number>} newColor - The new color to apply to the region
   * @param {number} startX - The x-coordinate of the starting point
   * @param {number} startY - The y-coordinate of the starting point
   * @returns {ImageData} - The modified image data
   */
  public changeColorWithinEdges(canvasElement: HTMLCanvasElement, color: string): void {
    // Step 1: Detect edges using Sobel filter
    this.sobelEdgeDetection(canvasElement, color);
  }

  /**
   * prefill overlay image
   */
  prefillOverlayImage(url: string, step: UploadStepModel): void {
    // eslint-disable-next-line max-len
    this.imageReaderObservable(url).subscribe((data) => {
      const url = data.image;
      const tempImg = new Image();
      tempImg.src = url;
      tempImg.onload = (event: any): void => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.height = tempImg.naturalHeight;
        tempCanvas.width = tempImg.naturalWidth;

        tempCtx.drawImage(tempImg, 0, 0);
        this.processImage(tempCanvas, '#FFFFFF');
        step.overlayPrefilled = tempCanvas.toDataURL('image/png');
      }
    });
  }

  /**
   *process image
   *
   */
  processImage(canvasElement: HTMLCanvasElement, colorTo: string): any {
    return this.changeColorWithinEdges(canvasElement, colorTo);
  }
  /**
   * show toast
   * @param message
   */
  showInfoToast(duration: number, message: string, action?: any): void {
    this._snackBar.openFromComponent(ToastComponent, {
      data: { message, action, preClose: () => { this._snackBar.dismiss() } },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: duration,
      panelClass: 'http-info-toast',
    })
  }

  /**
   * get all child of array
   * @param arr
   * @param term
   * @returns
   */
  getAllChild(arr: any, term: string, addExtra?: string, customMapper?: { 'parentProp': string, 'childProp': string }): any[] {
    let matches: Array<any> = [];
    if (!Array.isArray(arr)) return matches;

    arr.forEach((i) => {
      if (i[term]) {
        i[term].forEach((x) => {
          if (addExtra && i[addExtra]) {
            x[addExtra] = i[addExtra];
          }
          if (customMapper && i[customMapper.parentProp]) {
            x[customMapper.childProp] = i[customMapper.parentProp];
          }
        })
        matches = [ ...matches, ...i[term] ];
      }
    })
    return matches;
  }

  /**
   * show local notification
   * @param message
   */
  showNotification(message: string): void {
    this.notificationMessage = message;
    this.displayNotification = true;
    setTimeout(() => {
      this.hideNotification();
    }, 10000);
  }

  /**
   * open Confirmation Dialog
   * @param header
   * @param message
   * @returns
   */
  openConfirmationDialog(header: string, message: string, okText?: string, cancelText?: string, width?: string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      width,
      data: {
        title: header,
        message: message,
        okText: okText || 'Continue',
        cancelText: cancelText || 'Cancel'
      },
      panelClass: 'custom-dialog'
    })

    return dialogRef;
  }

  /**
   * open Confirmation Ok Dialog
   * @param header
   * @param message
   * @returns
   */
  openConfirmationOkDialog(header: string, message: string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: header,
        message: message,
        okText: 'ok',
        cancelText: 'cancel'
      },
      panelClass: 'custom-dialog'
    })

    return dialogRef;
  }

  /**
   * open Confirmation Ok Dialog
   * @param header
   * @param message
   * @returns
   */
  openConfirmationSingleOkDialog(header: string, message: string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: header,
        message: message,
        okText: 'Ok',
      },
      panelClass: 'custom-dialog'
    })

    return dialogRef;
  }

  /**
   * open Confirm Yes No Dialog
   * @param header
   * @param message
   * @returns
   */
  openConfirmYesNoDialog(header: string, message: string, entity?: string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: header,
        message: message,
        messageEntity: entity,
        okText: 'yes',
        cancelText: 'no'
      },
      panelClass: 'custom-dialog'
    })

    return dialogRef;
  }

  /**
   * open Alert Dialog
   * @param header
   * @param message
   * @param hideTitle
   * @param hideButton
   * @returns
   */
  openAlertDialog(header: string, message: string, hideTitle?: boolean, hideButton?: boolean, showCross?: boolean, afterClose?:boolean): MatDialogRef<AlertDialogComponent, any> {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      data: { title: header, message: message, hideTitle: hideTitle, hideButton: hideButton, showCross: showCross, afterClose },
      panelClass: 'large-custom-dialog'
    })

    return dialogRef;
  }

  /**
   * open Delete Dialog
   * @returns
   */
  openDeleteDialog(entity?: string, deleteMessage?: string, okText?: string, cancelText?: string, title?:string): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: title || 'delete',
        message: deleteMessage || 'delete_confirm',
        messageEntity: entity,
        okText: okText || 'yes',
        cancelText: cancelText || 'no'
      },
      panelClass: 'custom-dialog'
    })

    return dialogRef;
  }

  /**
  * show change warning message
  */
  showChangeWarningMessage(callBack: any, at: string = ''): void {
    const alertDlg = this.openConfirmYesNoDialog('confirm_modification',
      'confirm_modification_text', at);
    alertDlg.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          callBack();
        } else {
          return;
        }
      }
    });
  }

  /**
   * open Confirm Yes No Dialog
   * @param header
   * @param message
   * @returns
   */
  openConfirmYesNoDialogBig(header: string, message: string, entity?: string, okText?: string, cancelText?: string, showCancel = true): MatDialogRef<ConfirmDialogBigComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogBigComponent, {
      disableClose: true,
      //To remove console error of Blocked aria-hidden
      autoFocus: false,
      data: {
        title: header,
        message: message,
        messageEntity: entity,
        okText: okText || 'yes',
        cancelText: cancelText || 'no',
        showCancel
      }
    })

    return dialogRef;
  }

  /**
   * set session storage
   * @param x
   * @param y
   */
  setSessionStorage(x: any, y: any): void {
    sessionStorage.setItem(x, y);
  }

  /**
   * get session storage
   * @param x
   */
  getSessionStorage(x: string): string {
    return sessionStorage.getItem(x);
  }

  /**
   * clear session storage

   */
  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * check image orientation
   */
  checkImageOrientation(image): Promise<any> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = image;
      img.onload = (): any => {
        if (img.height > img.width) {
          resolve(true);
        } else {
          resolve(false);
        }
      }

      img.onerror = (): any => {
        resolve(false);
      }
    })
  }

  /**
   * emit from dialog component
   * @param event
   */
  emitFromDialogComponent(labourData: any): void {
    this.fromToastComponent.next(labourData);
  }

  /**
 * emit from dialog component
 * @param event
 */
  openGeneralChatComponent(message: any): void {
    this.generalCommMessage.next(message);
  }

  /**
   * check new version
   */
  checkVersion(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.get('assets/config.json', httpOptions);
  }

  /**
   * clear all application data
   */
  clearData(): void {
    const appVersion = localStorage.getItem('appVersion');
    const cid = localStorage.getItem('cid');
    const loginParam = localStorage.getItem('currentLoginParam');
    const loginPolicy = localStorage.getItem('customLoginPolicy');
    const activePolicy = localStorage.getItem('activePolicy');
    const settings = localStorage.getItem('languageSettings');
    const languageCode = localStorage.getItem('languageCode');

    localStorage.setItem('customLoginPolicy', '');
    localStorage.clear();
    sessionStorage.clear();
    if (cid && cid !== 'null') {
      localStorage.setItem('cid', cid);
    }
    if (loginParam && loginParam !== 'null') {
      localStorage.setItem('currentLoginParam', loginParam);
    }

    if (activePolicy && activePolicy !== 'null') {
      localStorage.setItem('activePolicy', activePolicy);
    }

    if (loginPolicy && loginPolicy !== 'null') {
      localStorage.setItem('customLoginPolicy', loginPolicy);
    }

    if (appVersion && appVersion != 'undefined' && appVersion != 'null') {
      localStorage.setItem('appVersion', appVersion);
    }

    if (settings !== null && settings !== undefined && settings !== 'undefined') {
      localStorage.setItem('languageSettings', settings);
    }

    if (locale !== null && locale !== undefined && languageCode !== 'undefined') {
      localStorage.setItem('languageCode', languageCode);
    }
  }

  /**
 *
 * @param permissionKey
 * @param permissionList
 * @returns
 */
  hasPermission(permissionKey, permissionList): boolean {
    return permissionList?.some(x => x.permissionName == permissionKey);
  }

  /**
 *
 * @param permissionKey
 * @param permissionList
 * @returns
 */
  containsPermission(permissions, permissionList): boolean {
    return permissionList?.some(x => permissions.findIndex(p => p === x.permissionName) > -1);
  }

  /**
   * set repair and quote permission
   * @param automotiveServices
   */
  setPermission(automotiveServices: any): void {
    this.repairPermission = !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair');
    this.quotePermission = !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote');
    this.claimPermission = !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims');
    this.fnolPermission = !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'fnol');
    this.quoteAssessmentPermission = !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'quote assessment');
    this.userPermission.next({
      repair: !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair'),
      quote: !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote'),
      claim: !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims'),
      fnol: !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'fnol'),
      quoteAssessment: !!automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'quote assessment'),
    });
  }

  /**
   * return locale
   * @returns
   */
  getLocale(): string {
    return this.localeSubject.value;
  }

  /**
   * set locale
   * @param locale
   */
  setLocale(locale: string): void {
    this.locale = locale ?? defaulti18nInfo.locale;
    this.localeSubject.next(this.locale);
  }

  /**
   * return number format
   * @returns
   */
  getDigitsInfo(): string {
    if (this.digitsInfo) {
      return this.digitsInfo
    } else {
      return defaulti18nInfo.digitsInfo
    }
  }

  /**
   * set number format
   * @param digitsInfo
   */
  setDigitsInfo(digitsInfo: string): void {
    this.digitsInfo = digitsInfo ?? defaulti18nInfo.digitsInfo;
  }

  /**
   * return currency symbol
   * @returns
   */
  getCurrencySymbol(): string {
    if (this.currencySymbol) {
      return this.currencySymbol
    } else {
      return defaulti18nInfo.currencySymbol
    }
  }

  /**
   * set number format
   * @param symbol
   */
  setCurrencySymbol(symbol: string): void {
    this.currencySymbol = symbol ?? defaulti18nInfo.currencySymbol;
  }

  /**
   * set number format
   * @param symbol
   */
  setCurrencySymbolFromSymbolMapper(symbol: string): void {
    if (symbol) {
      this.currencySymbol = currencyMapper[symbol]?.symbol ?? symbol;
      this.symbolPosition = currencyMapper[symbol]?.position;
      this.locale = currencyMapper[symbol]?.locale;
    } else {
      this.currencySymbol = defaulti18nInfo.currencySymbol;
      this.symbolPosition = defaulti18nInfo.position;
      this.locale = currencyMapper[symbol]?.locale;
    }

  }

  /**
   * return locale
   * @returns
   */
  getSymbolPosition(): string {
    if (this.symbolPosition) {
      return this.symbolPosition
    } else {
      return defaulti18nInfo.position
    }
  }

  /**
   * set locale
   * @param locale
   */
  setSymbolPosition(position: string): void {
    this.symbolPosition = position ?? defaulti18nInfo.position;
  }

  /**
   * return i18nInfo
   * @returns
   */
  geti18nInfo(): Localization {
    return {
      locale: this.getLocale(),
      digitsInfo: this.getDigitsInfo(),
      currencySymbol: this.getCurrencySymbol(),
      position: this.getSymbolPosition() || 'start'
    }
  }

  /**
   * return default i18nInfo
   * @returns
   */
  getDefaulti18nInfo(): Localization {
    return defaulti18nInfo
  }

  /**
   *
   * @param img
   */
  download(imgUrl, name?): void {
    const imgName = imgUrl?.includes('https://') ? (imgUrl?.substr(imgUrl.lastIndexOf('/') + 1).split('?')[0] || name) : name;

    this.httpClient
      .get(imgUrl, { responseType: 'blob' as 'json' })
      .subscribe({
        next: (res: any) => {
          const file = new Blob([ res ], { type: res.type });

          // IE
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return
          }

          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = blob;
          link.download = decodeURIComponent(imgName);

          // Version link.click() to work at firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        },
        error: (error: any) => {
          console.log(error);
          window.open(imgUrl, '_blank');
        }
      });
  }

  /**
   * download from base64 file
   * @param base64Data
   * @param fileName
   */
  downloadBase64File(base64Data: string, fileName: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Data;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  /**
   *
   * @param direction
   * @param distance
   * @param duration
   */
  scrollTo(el, direction: 'left' | 'right', distance: number, duration = 500): Promise<void> {
    return new Promise((resolve) => {
      const startScrollLeft = el.scrollLeft;
      const rtlEnabled = document.documentElement.getAttribute('dir') === 'rtl';

      let targetScrollLeft;

      if (rtlEnabled) {
        targetScrollLeft = (direction === 'right') ? startScrollLeft - distance : startScrollLeft + distance;
      } else {
        targetScrollLeft = (direction === 'left') ? startScrollLeft - distance : startScrollLeft + distance;
      }

      const startTime = performance.now();

      const step = (): void => {
        const elapsed = performance.now() - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const newScrollLeft = startScrollLeft + (targetScrollLeft - startScrollLeft) * progress;

        el.scrollLeft = newScrollLeft;

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    })
  }

  /**
   *
   * @param date
   */
  getMonthWeek(date: Date): any {
    const weeksArray = [];
    let currentDate = dayjs(date);

    const month = currentDate.get('M');
    let weekNumber = 1;

    while (currentDate.month() === month) {
      const startOfWeek = currentDate;
      let endOfWeek = currentDate.endOf('week');

      if (endOfWeek.month() != currentDate.month()) {
        endOfWeek = currentDate.endOf('month');
      }

      weeksArray.push({
        weekNumber: weekNumber++,
        startDate: startOfWeek.format('D'),
        endDate: endOfWeek.format('D'),
        isToday: dayjs().isBetween(startOfWeek, endOfWeek, 'day')
      });

      currentDate = endOfWeek.add(1, 'day');
    }

    return weeksArray;
  }

  /**
   * convertSpaceToLowercaseUrl
   * @param {*} text
   * @returns {string}
   */
  convertSpaceToLowercaseUrl(text): string {
    if (text.includes('-')) {
      return text.toLowerCase().split(' ').join('');
    } else {
      return text.toLowerCase().split(' ').join('-');
    }
  }

  /**
   * insertSpaceBeforeCapitalLetters
   * @param {*} text
   * @returns {string}
   */
  insertSpaceBeforeCapitalLetters(text): string {
    return text.replace(/([A-Z])/g, ' $1').trim()
  }

  /**
  * check current platform
  */
  get isiOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  /**
   * claim permission
   * @param permissionName
   */
  checkOrgPackagesPermission(orgPackages: any, serviceName: string, permissionName: string): boolean {
    const permissionList = orgPackages?.find(x => x.automotiveServiceName.toLowerCase() === serviceName.toLowerCase())?.permissions;

    if (this.hasPermission(permissionName, permissionList)) {
      return true;
    }

    return false;
  }

  /**
   * splitTime - get days, hours from decimal string
   * @param numberOfHours
   */
  splitTime(numberOfHours: number): any {
    const days = Math.floor(numberOfHours / 24);
    const remainder = numberOfHours % 24;
    const hours = Math.floor(remainder);
    const minutes = Math.floor(60 * (remainder - hours));
    return ({ 'days': days, 'hours': hours, 'minutes': minutes })
  }

  /**
     * formatNumber
     */
  formatNumber(value: number): string {
    if (value !== null && value !== undefined) {
      // Convert to integer if the value has a decimal part
      // Check if the value has a decimal part
      const hasDecimal = value % 1 !== 0;

      // Convert to integer if the value doesn't have a decimal part
      const formattedValue = +(hasDecimal ? value.toFixed(2) : value.toFixed(0));

      if (formattedValue >= 1e6) {
        const formattedValue = +(value / 1e6).toFixed(2);
        return formattedValue + 'M';
      } else if (formattedValue >= 1e3) {
        return Math.floor(formattedValue / 1e3) + 'K';
      }

      return formattedValue.toString();
    } else {
      return '';
    }
  }

  /**
     * formatNumber
     */
  getFileObjectFromBase64(fileContents: string, fileName, fileType): any {
    const bstr = atob(fileContents);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    const file = new File([ uint8Array ], fileName, { type: fileType });
    return file;
  }


  /**
   * claim permission
   * @param permissionName
   */
  checkPermission(serviceName: string, permissionName: string): boolean {
    const automotiveServices = this.userProfileData.value?.data?.userPermission?.automotiveServices

    const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === serviceName.toLowerCase())?.permissions;

    if (this.hasPermission(permissionName, permissionList)) {
      return true;
    }

    return false;
  }

  /**
   * check gtEstimate permission
   */
  checkGTEstimatePermission(): boolean {
    const { orgPackages = [] } = this.userProfileData.value.data.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'gt.estimate' || perm.permissionName === 'gt.estimate.vinquery';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        return true;
      }
    }
    return false;
  }
  /**
     * check AI Estimate permission
     */
  checkAIEstimatePermission(): boolean {
    const { orgPackages = [] } = this.userProfileData.value.data.userPermission;
    const AIEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Artificial Intelligence Estimation';
    });

    if (AIEstimatePackage && AIEstimatePackage.length > 0) {
      const { permissions } = AIEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'ai.estimation';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
     * check XA Estimate Permission
     *
     * @returns {boolean}
     */
  checkXAEstimatePermission(): boolean {
    const { orgPackages = [] } = this.userProfileData.value.data.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'xa.repair.estimate';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        return true;
      }
    }
    return false;
  }
  /**
     * check XA Estimate Permission
     *
     * @returns {boolean}
     */
  checkAudatexPermission(): boolean {
    const { orgPackages = [] } = this.userProfileData.value.data.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const audatexPermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'audatex.estimate';
      });
      if (audatexPermission && audatexPermission.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * @param status
   * @param repairGuid
   * @returns
   */
  getRepairRouterBasedOnStatusAndPermission(status: string, repairGuid: string): string {
    const baseRoute = '/';
    const statusKey = status?.replace(/\s/g, '').toLowerCase();

    if (!this.checkPermission('Addenda Repair', RepairStatusToPermissionMapper.others)) {
      return '';
    }

    const permissionKey = RepairStatusToPermissionMapper[statusKey] || 'others';
    if (!this.checkPermission('Addenda Repair', permissionKey)) {
      return baseRoute + RepairStatusMapper.others.replace('{{repairGUID}}', repairGuid);
    }

    return baseRoute + RepairStatusMapper[statusKey].replace('{{repairGUID}}', repairGuid);
  }

  /**
   * function
   *
   * @param {string} contentType
   * @returns {string}
   */
  getFileExtension(contentType: string): string {
    switch (contentType) {
    case 'application/pdf':
      return '.pdf'
    case 'image/jpeg':
      return '.jpg'
    case 'image/png':
      return '.png'
    case 'image/gif':
      return '.gif'
    case 'image/heic':
      return '.heic';
    }
    return '';
  }

  /**
   * Call Estimation Detail
   */
  callEstimationDetailMethod(): any {
    this.triggerEstimationDetailMethod.next();
  }

  /**
     * function
     *
     * @private
     * @param {(string | null)} contentDisposition
     * @returns {string}
     */
  getFileNameFromContentDisposition(contentDisposition: string | null): string {
    if (!contentDisposition) return 'default-filename.ext';
    const matches = /filename="([^"]+)"/.exec(contentDisposition);
    return matches?.[1] ?? 'default-filename.ext';
  }

  /**
     * haveFinancialPermission
  */
  haveFinancialPermission(): boolean {
    const automotiveServices = this.userProfileData?.value?.data?.userPermission?.automotiveServices || [];
    const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;
    if (permissionList && permissionList?.length > 0) {
      return this.checkOrgPackagesPermission(this.userProfileData.value?.data?.userPermission?.orgPackages, 'Financial', 'financial')
        && [ 'claim.selfapproval', 'claim.submitforapproval' ].some(itemP => permissionList?.map(item => item?.permissionName).includes(itemP));
    }
    return false;
  }

  /**
     * load locale message
  */
  loadLocaleMessages(lang: string): any {
    if (lang === 'ar') {
      loadMessages(arMessages);
    } else {
      loadMessages(enMessages);
    }

    locale(lang)
    dayjs.locale(lang);
    this.direction = lang === 'ar' ? 'rtl' : 'ltr';
    document.dir = this.direction;
    document.documentElement.lang = lang;
  }

  /**
    * hasIsExternalPermission
  */
  hasIsExternalPermission(): boolean {
    const automotiveServices = this.userProfileData?.value?.data?.userPermission?.automotiveServices || [];

    const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
    if (permissionList && permissionList?.length > 0) {
      return this.hasPermission('case.external', permissionList);
    }
    return false;
  }

  /**
     * hasPartsProcurementPermission
  */
  hasPartsProcurementPermission(): boolean {
    const orgPackages = this.userProfileData?.value?.data?.userPermission?.orgPackages || [];
    const permissionList = orgPackages?.find(x => x.automotiveServiceName.toLowerCase() === 'parts procurement')?.permissions;
    if (permissionList && permissionList?.length > 0) {
      return this.hasPermission('parts.procurement', permissionList);
    }
    return false;
  }

  /**
   * claim permission
   * @param permissionName
   */
  checkClaimPermission(permissionName: string, automotiveService: any): boolean {
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;
    return this.hasPermission(permissionName, permissionList);
  }
}

<div class="dialog-container"
     *ngIf="!isLoading">
  <div class="dialog-title">
    <h3>{{ 'send_email' | translate }}</h3>
    <div class="close-icon">
      <a (click)="close();">
        <mat-icon [svgIcon]="'cross'"
                  color="white"></mat-icon>
      </a>
    </div>
  </div>
  <div mat-dialog-content
       class="email-wrapper">
    <div class="form-row"
         [ngClass]="{'no-attach': files?.length === 0}">
      <div class="attachment-container"
           *ngIf="files?.length > 0">
        <div class="attachment-wrapper">
          <div class="col"
               style="display:none;"><!--removed this for now -->
            <div class="input-wrapper">
              <div class="div-wrapper">
                <xa-select class="col-6 repair-type"
                           [options]="documents"
                           [(ngModel)]="selectedDocuments"
                           [config]="existingDocumentConfig"></xa-select>
              </div>
            </div>
            <input hidden
                   type="file"
                   #uploadDoc
                   (change)="handleUpload($event, 'document')"
                   accept="image/png, image/jpg, image/jpeg, application/pdf">
            <xa-button (click)="uploadDoc?.click()"
                       color="black"
                       type="outline">
              <mat-icon svgIcon="attachment"></mat-icon>
              {{ 'add_attachment' | translate }}
            </xa-button>
          </div>
          <div class='attachment-list'>
            <label>{{'attachments' | translate}}</label>
            <div *ngFor="let item of files;let i = index"
                 class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <div class="icon-div">
                <img src="assets/img/pdf.png"
                     *ngIf="item?.name?.endsWith('pdf')"
                     alt="pdf" />
                <mat-icon class='red-cross'
                          [svgIcon]="'cross'"
                          (click)="removeFile(i,'document')"></mat-icon>
              </div>

            </div>
            <div *ngFor="let item of additionalDocs;let i = index"
                 class="attachment-item">
              <span class='file-name'>{{ item.name }}</span>
              <div class="icon-div">
                <img src="assets/img/pdf.png"
                     alt="pdf"
                     *ngIf="item?.name?.endsWith('pdf')" />
                <mat-icon class='red-cross'
                          [svgIcon]="'cross'"
                          (click)="removeFile(i,'additionalDocs')"></mat-icon>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="email-input"> <xa-text [required]="true"
                 [(ngModel)]="email"
                 label="email"
                 #name="ngModel"
                 [pattern]="emailPattern"
                 [placeholder]="'email' | translate"></xa-text>
        <div *ngIf="name.invalid && (name.dirty || name.touched)"
             class="alert alert-validation">
          <div *ngIf="name.hasError('required')">
            {{'email_required' }}
          </div>
          <div *ngIf="name.hasError('pattern')">
            {{'email_valid'| translate}}
          </div>
        </div>
      </div>

      <label for=""
             class="sub">{{'subject' | translate}}</label>
      <div class="subject-wrapper">
        <span>{{this.emailTemplateDetail?.subject}} - </span>
        <span>{{'claim_no_recovery' | translate}}</span>
        <span class="claim-id">{{this.claimantDetails?.claimNo}}</span>
      </div>
    </div>
    <div class="message-wrapper">
      <label for=""
             class="sub">{{'message' | translate}}</label>
      <div [innerHTML]="handleReplacementTags"
           class="message-html-container"></div>
    </div>
  </div>
  <div class="footer form-data-footer">
    <xa-button [disabled]="name.invalid"
               (click)="sendEmail()">
      <mat-icon svgIcon="send"></mat-icon>
      {{'send_remainder' | translate}}</xa-button>
  </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { menuItem } from 'src/app/model/menu';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';

@Component({
  selector: 'app-ai-inference-claim-dialog',
  templateUrl: './ai-correlation-dialog.component.html',
  styleUrls: [ './ai-correlation-dialog.component.scss' ]
})
export class AiCorrelationDialogComponent implements OnInit{
  public isPDFLoading: boolean = false;
  claimId: any;
  inspectionId: any;
  public claimDetails: any;

  dateToday = dayjs().format('DD-MM-YYYY');

  imageCorrelation: any[] = [];
  documentCorrelation: any[] = [];
  videoCorrelation: any[] = [];
  additionalCorrelation: any[] = [];
  aiCorrelation: any[] = [];

  claimantData: any;
  userListMenus: menuItem[] = [];
 
  /**
   * constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AiCorrelationDialogComponent>,
    private readonly claimService: ClaimsService,
  private readonly commonService: CommonService,
  private readonly quoteManagementService: QuoteManagementService) {

    this.claimId = data.claimId;
    this.inspectionId = data.inspectionId;

    this.claimantData = data.claimantData;

    if (this.claimantData && data.type == 'claim') {
      let i: number = 0;
      this.claimantData.claims?.forEach((claim: any) => {
        if (i == 0) {
          this.claimDetails = claim;
          this.getAICorrelationCase();
        }
        if (claim?.customerDetails) {
          const name = claim.customerDetails.policyHolderFirstName + ' ' + claim.customerDetails.policyHolderLastName;
          this.userListMenus[i] = {
            ...this.userListMenus[i], name: name, displayName: name, url: claim.claimGuid, active: i === 0
          }
          this.userListMenus = [ ... this.userListMenus ];
          i++;
        }
      });
    }
  }

  /**
   * on init
   */
  ngOnInit(): void {
    if(this.data.type == 'case') {
      this.getAICorrelationCase();
    }
  }

  /**
   * called after tab is changed
   * @param $event 
   */
  changeTab($event): void {
    if ($event && $event.newTabTitle != $event.prevTabTitle ) {
      const activeMenu = this.userListMenus.find(x => x.displayName?.toLowerCase() === $event?.newTabTitle?.toLowerCase());
      if (activeMenu) {
        this.claimDetails = null;
        activeMenu.active = true;
        const selectedClaim = this.claimantData?.claims?.find(q=> q.claimGuid == activeMenu.url);
        this.claimDetails = selectedClaim;
        this.getAICorrelationCase();
      }
    }  
  }

  /**
   * map AI Correlation
   * @param obj 
   */
  mapAICorrelation(claimData: any): any {
    const obj = {
      objectId: claimData?.id?.toString(),
      make: claimData?.vehicleMetaData?.make,
      model: claimData?.vehicleMetaData?.model,
      vin: claimData?.vehicleMetaData?.vinNum,
      plateNo: claimData?.vehicleMetaData?.plateNo,
      domainId: claimData?.domainID?.toString() ?? claimData?.domainId?.toString(),
      inspectionId: claimData?.inspectionId?.toString(),
      vehicleId: claimData?.vehicleMetaData?.vehicleId?.toString()
    };
    return obj;
  }

  /**
   * get AI Inference
   */
  getAICorrelationCase(): void {
    this.commonService.showLoading();

    const obj = this.data.type == 'case' ? this.mapAICorrelation(this.claimantData) : this.mapAICorrelation(this.claimDetails);
  
    this.quoteManagementService.getAICorrelation(obj).subscribe({
      next: (response: any) => {
        if (response?.length > 1) {
          this.imageCorrelation = response[1].inspectionDetail?.items;
          this.documentCorrelation = response[0].data?.documents?.items;
          this.additionalCorrelation = response[1].inspectionDetail?.additionalPhotos;
          this.videoCorrelation = response[1].inspectionDetail?.video;
          if (this.data.type == 'case') {
            const aiCorrelation = response[1].inspectionDetail?.aiInferenceDetails;
            if(aiCorrelation?.length > 0) {
              this.mapAiCorrelationWithDocument(aiCorrelation); 
            }
          }
        }
        this.commonService.hideLoading();
      },
      error: (err: any) => {
        console.log(err);
        this.commonService.hideLoading();
      }
    });
  }


  /**
   * map AI Correlation With Document
   */
  mapAiCorrelationWithDocument(aiCorrelation: any): void {
    if(this.documentCorrelation?.length > 0) {
      const obj = {
        'itemName': aiCorrelation[0].itemName,
        'make': {
          'actual': this.documentCorrelation[0].make?.result,
          'result': aiCorrelation[0].make?.result,
          'isMatch': this.matchMakeModel(this.documentCorrelation[0].make?.result, aiCorrelation[0].make?.result)
          //'isMatch': this.documentCorrelation[0].make?.result?.toLowerCase() === aiCorrelation[0].make?.result?.toLowerCase()
        },
        'model': {
          'actual': this.documentCorrelation[0].model?.result,
          'result': aiCorrelation[0].model?.result,
          'isMatch': this.matchMakeModel(this.documentCorrelation[0].model?.result, aiCorrelation[0].model?.result)
          //'isMatch': this.documentCorrelation[0].model?.result?.toLowerCase() === aiCorrelation[0].model?.result?.toLowerCase()
        },
        'plateNumber': {
          'actual': this.documentCorrelation[0].plateNumber?.result,
          'result': aiCorrelation[0].plateNumber?.result,
          'isMatch': this.matchPlateNumber(this.documentCorrelation[0].plateNumber?.result, aiCorrelation[0].plateNumber?.result)
          //'isMatch': this.documentCorrelation[0].plateNumber?.result?.toLowerCase() === aiCorrelation[0].plateNumber?.result?.toLowerCase()
        },
        'vin': {
          'actual': this.documentCorrelation[0].vin?.result,
          'result': aiCorrelation[0].vin?.result,
          'isMatch': this.documentCorrelation[0].vin?.result?.toLowerCase() === aiCorrelation[0].vin?.result?.toLowerCase()
        }
      }
      this.aiCorrelation.push(obj);
    }
  }

  /**
   * match make and model from doc and photos
   * @param make 
   * @param model 
   */
  matchMakeModel(doc, pic): boolean {
    const document = doc?.toLowerCase()?.replaceAll(' ', '') || '';
    const photo = pic?.toLowerCase()?.replaceAll(' ', '') || '';
    if (!photo || !document) {
      return false;
    }
    if (document?.includes(photo) || photo?.includes(document)) {
      return true;
    }
    return false;
  }

  /**
   * match plate number
   * @param doc 
   * @param pic 
   */
  matchPlateNumber(doc, pic): boolean {
    const docNumeric = doc?.trim().replace(/\D/g, '');
    const picNumeric = pic?.trim().replace(/\D/g, '');

    if (!docNumeric || !picNumeric) {
      return false;
    }

    if (docNumeric == picNumeric) {
      return true;
    } 
    return false;
  }

  /**
   * get tooltip
   * @param make 
   * @returns 
   */
  getTooltipText(item: any, key: string, type?: string): string {
    if (!item) return '';
    if(type == 'docVsPhoto') {
      return `${item.itemName}
      DOC: ${item[key]?.actual || ''}
      PHOTO: ${item[key]?.result || ''}`;
    }
    return `${item.itemName}
    CASE: ${item[key]?.actual || ''}
    ACTUAL: ${item[key]?.result || ''}`;
  }

  /**
    @returns {void} function to close Summary popup
  */
  onClose(): void {
    this.dialogRef.close();
  }
}



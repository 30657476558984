import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClaimsService } from 'src/app/services/claims/claims.service';

@Component({
  selector: 'app-estimate-table',
  templateUrl: './estimate-table.component.html',
  styleUrls: [ './estimate-table.component.scss' ]
})
export class EstimateTableComponent implements OnInit, OnDestroy {

  @Input() claimId: string = '0';
  public i18n: Localization;
  public estimateData = [];
  //public claimId = '0';
  public claimDetails;
  public subscriptions: Subscription[] = [];
  public fetchAPI = false;

  /**
   * 
   * @param commonService 
   * @param translateService 
   */
  constructor(private route: ActivatedRoute,
        private commonService: CommonService,
        private translateService: TranslateService,
        private claimService: ClaimsService,
        private router: Router,){
    this.i18n = this.commonService.geti18nInfo();
  }

  /**
    * ngOnInit
    */
  ngOnInit(): void {

    // this.route?.parent?.parent.params.subscribe((value) => {
    //   if (value && value['id']) {
    //     this.claimId = value['id'];
    //     this.getClaimDetails();
    //   }
    // });
    this.getClaimDetails();
  }

  /**
   * getClaimDetails
   * 
   */
  getClaimDetails(): void {
    
    this.fetchAPI = true;
    this.commonService.showLoading();
    const claimDetails$ = this.claimService.getClaimDetail(this.claimId).subscribe({
      next: (response)=>{
        this.commonService.hideLoading();
        this.claimDetails = response;
        this.getEstimateLog();
        this.commonService.claimDetails.next(response);
        this.commonService.claimStatus.next(response.claimStatus);
      },
      error: ()=>{
        this.fetchAPI = false;
        this.commonService.hideLoading();
      }
    });
    

    this.subscriptions.push(claimDetails$);
  }

  /**
   * back
   */
  back(): void {
    this.router.navigate([ 'claim/claim-detail/'+this.claimId + '/estimate/' ]);
  }

  /**
   * getEstimateLog
   * 
   */
  getEstimateLog(): void {
    this.fetchAPI = true;
    this.commonService.showLoading();
    const subs$ = this.claimService.getEstimateLog(this.claimDetails.id).subscribe({
      next: (response) =>{
        this.commonService.hideLoading();
        this.fetchAPI = false;
        this.estimateData = response.data;
      },
      error: ()=>{
        this.fetchAPI = false;
        this.commonService.hideLoading();
      }
    });

    this.subscriptions.push(subs$);
  }

  /**
   * 
   * @param estimate 
   */
  openEstimate(estimate): void {
    window.open(estimate.blobUrl, '_blank');
  }
  
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}

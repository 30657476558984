import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CellClickedCallback, TableCellData, TableConfig, TableRowData } from 'src/app/widgets/xa-table/xa-table.component';
import { RegisteredGarageTableConfig } from 'src/app/config/display/registered-garage-table-config';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import * as saveAs from 'file-saver';
import { CommonService, Localization } from 'src/app/services/common/common.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { ReserveAdjustmentDialogComponent } from '../reserve-adjustent-dialog/reserve-adjustment-dialog.component';
import { CLAIM_STATUS } from 'src/app/config/constants/claims.constants';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-registered-garage-view-estimate',
  templateUrl: './registered-garage-view-estimate.component.html',
  styleUrls: [ './registered-garage-view-estimate.component.scss' ]
})
export class RegisteredGarageViewEstimateComponent {
  public tableConfig: TableConfig = RegisteredGarageTableConfig;
  public tableData: any = [];
  i18n: Localization;
  haveFinancialPermission: boolean = false;
  public markAsTotalLossModel: ChqWidgetsButtonModel = {
    label: 'mark_as_total_loss',
    type: 'primary',
    onclick: () => this.handleMarkAsTotalLoss()
  }

  /**
 * Constructor for RegisteredGarageViewEstimateComponent.
 * 
 * @param data - Data injected into the dialog.
 * @param dialogRef - Reference to the dialog opened.
 * @param monitorService - Service for monitoring.
 * @param commonService - Common service for shared functionality.
 * @param claimService - Service for handling claims.
 * @param translationService - Service for translating text.
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegisteredGarageViewEstimateComponent>,
    private monitorService: MonitorService,
    private commonService: CommonService,
    private claimService: ClaimsService,
    private translationService: TranslateService,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.i18n = this.commonService.geti18nInfo();
    this.tableData = data.list;
    this.claimService.isTotalLoss.subscribe((value) => {
      if (value !== null && this.data?.claimGuid === value.claimGuid) {
        this.data.isTotalLoss = value.isTotalLoss
      }
    });
    this.haveFinancialPermission = this.commonService.haveFinancialPermission()
  }

  /**
 * close
 */
  close(): void {
    this.dialogRef.close();
  }

  /**
    Handles a cell click event.
    @param {TableRowData} row - The row data.
    @param {Partial} column - The column data.
    @returns {void}
  */
  handleCellClick: CellClickedCallback = (row: TableRowData, column: Partial<TableCellData>): void => {
    if (column.id === 'Download') {
      const urls = row[4]?.result?.value?.split('/');
      const fileName = urls[urls?.length - 1]?.split('?')[0];

      this.commonService.showLoading()
      this.commonService.getFileBlob(row[4]?.result?.value).subscribe({
        next: (value) => {
          this.commonService.hideLoading()
          const blob = new Blob([ value.res ], { type: 'application/octet-stream' });
          saveAs(blob, fileName)
        },
        error: (err: any) => {
          this.commonService.hideLoading();
          this.monitorService.logException(err);
        }
      });

    } else if (column.id === 'Preview') {
      window.open(row[4]?.result?.value, '_blank');
    }
  };

  /**
 * handleRejectEstimateThirdParty
 */
  handleRejectEstimateThirdParty(): void {
    this.dialogRef.close('rejectEstimate');
  }

  /**
 * handleApproveEstimateThirdParty
 */
  handleApproveEstimateThirdParty(): void {
    if(this.haveFinancialPermission) {
      this.dialogRef.close();
      const dialogRef = this.commonService.openConfirmYesNoDialogBig('', this.translateService.instant('approve_estimate_or_adjust_reserve'), '', this.translateService.instant('adjust_reserve'), this.translateService.instant('approve_estimate'))
      dialogRef.afterClosed().subscribe((action: string | boolean) => {
        if (action === false) {
          this.approveEstimate();
        } else if (action === true) {
          this.openClaimAdjustPopup();
        }
      });
    } else {
      this.approveEstimate();
    }
    
  }

  /**
 * openClaimAdjustPopup
 */
  openClaimAdjustPopup(isForTotalLoss: boolean = false): void {
    this.commonService.showLoading();
    this.claimService.getFinancialSummary({
      PoliceReferenceNo: this?.data?.policeReferenceNo,
      IntimationNo: this.data.intimationNo
    }).subscribe({
      next: (response) => {
        if(response?.data?.reserveSummary) {
          this.commonService.hideLoading();
          const selectedSumary = response?.data?.reserveSummary?.claimantDetails.find(x=>x.claimGuid === this.data.claimGuid);
          const dialogRef = this.dialog.open(ReserveAdjustmentDialogComponent, {
            data: {
              claimGuid: this.data.claimGuid,
              isForTotalLoss,
              reserveData: selectedSumary?.reserveDetails,
              isTotalLoss: this.data.isTotalLoss,
              hasIdvValue: this.data.hasIdvValue,
              totalLossThreshold: this.data.totalLossThreshold
            },
            minWidth: '550px',
          });
          dialogRef.afterClosed().subscribe((action: string | boolean) => {
            console.log('Actions', action)
          });
        }
        
      }, error: (err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    })

  }

  /**
 * approveEstimate
 */
  approveEstimate(): void {
    if(this.data.isTotalLoss) {
      this.commonService.showToast(0, this.translateService.instant('total_loss_approval_warning_message'));
      return
    }
    this.commonService.showLoading();
    this.claimService.thirdPartyApproveEstimate(this.data.claimGuid, 'Approved').subscribe({
      next: () => {
        this.claimService.updateClaimStatus(this.data?.claimGuid, 'Approved', 'Claim Approved').subscribe({
          next: (response) => {
            if (response) {
              this.commonService.showInfoToast(5000, this.translationService.instant('estimate_approved_successfully'))
              this.dialogRef.close();
              this.claimService.ClaimStatus.next(CLAIM_STATUS.Approved)
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }, error: (err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      }
    })
  }

  /**
 * handleMarkAsTotalLoss
 */
  handleMarkAsTotalLoss(): void {
    if(this.haveFinancialPermission) {
      const dialogRef = this.commonService.openConfirmYesNoDialogBig('', this.translateService.instant('mark_claimant_total_loss_or_adjust_reserve'), '',
        this.translateService.instant('adjust_reserve'), '', false)
      dialogRef.afterClosed().subscribe((action: string | boolean) => {
        if(action === true) {
          this.openClaimAdjustPopup(true);
        }
      });
    } else {
      this.commonService.showLoading()
      firstValueFrom(this.claimService.updateReserveWithTotalLoss({
        'claimGuid': this.data?.claimGuid,
      })).then((res) => {
        this.commonService.hideLoading();
        if(res) {
          this.commonService.showInfoToast(5000, this.translateService.instant('total_loss_reserve_update_success'));
          this.claimService.isTotalLoss.next({
            claimGuid: this.data?.claimGuid,
            isTotalLoss: true
          })
          this.dialogRef.close();
        }
      }).catch(() => {
        this.commonService.hideLoading();
      });
    }
  }

  /**
   * viewLPO
   */
  viewLPO(): Promise<void> {
    this.commonService.showLoading()
    return firstValueFrom(this.claimService.getLpoURL(this.data?.claimGuid)).then((res) => {
      this.commonService.hideLoading();
      if(res.success && res.data) {
        window.open(res.data, '_blank');
      } else {
        this.commonService.showToast(0, res.message)
      }
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-repair-intimation-request',
  templateUrl: './repair-intimation-request.component.html',
  styleUrls: [ './repair-intimation-request.component.scss' ]
})
export class RepairIntimationRequestComponent {
  bookingGuid: string = ''
  notificationGuid: string = ''
  approveRejectCompleted: boolean = false;
  showApprovedOrRejected = '';
  acceptRejectEnum = {
    Accepted: 2,
    Rejected: 3,
  }
  /**
     * Creates an instance of ClaimAssignModalComponent.
     * @date 12/28/2023 - 6:59:50 PM
     *
     * @constructor
     */
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<RepairIntimationRequestComponent>,
      private commonService: CommonService,
      private claimsService: ClaimsService,
       private router: Router
  ) {
    this.bookingGuid = data['bookingGuid'];
    this.notificationGuid = data['notificationGuid'];
    const type = data['type'];
    if(type?.indexOf('Approve') !== -1) {
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Approved';
    }else if(type?.indexOf('Reject') !== -1){
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Rejected';
    }else if(type?.indexOf('Reassigned') !== -1){
      this.approveRejectCompleted = true;
      this.showApprovedOrRejected = 'Reassigned';
    }
  }
  /**
   * cancelDialog
   * @param {*} data
   */
  cancelDialog(data: any): void {
    this.dialogRef.close();
  }

  /**
   * approveForm
   */
  approveForm(): void {
    this.commonService.showLoading();
    this.claimsService.updateRepairIntimationNotificationStatus(this.notificationGuid, this.bookingGuid, this.acceptRejectEnum.Accepted).subscribe({
      next: (data) => {
        this.commonService.hideLoading();
        this.approveRejectCompleted = true;
        if(data.success){
          this.commonService.showInfoToast(5000, data.message);
          this.showApprovedOrRejected = 'Approved';
          this.router.navigate([ `claim/fnol-details/${data?.data.fnolGuid}` ]);
          this.dialogRef.close();
        }
      },
      error: (data) =>{
        this.commonService.hideLoading();
      }
    })
  }


  /**
   * rejectForm
   */
  rejectForm(): void {
    this.commonService.showLoading();
    this.claimsService.updateRepairIntimationNotificationStatus(this.notificationGuid, this.bookingGuid, this.acceptRejectEnum.Rejected).subscribe({
      next: (data) => {
        this.commonService.hideLoading();
        this.approveRejectCompleted = true;
        if(data.success){
          this.showApprovedOrRejected = 'Rejected';
          this.commonService.showInfoToast(5000, data.message);
          this.dialogRef.close();
        }
      },
      error: (data) =>{
        this.commonService.hideLoading();
      }
    })
  }

  /**
   * viewIntimation
   */
  viewIntimation(): void {
    this.dialogRef.close();
    this.router.navigate([ `claim/fnol-details/${this.bookingGuid}` ]);
  }
}

<div class="file-upload-container"
     [ngClass]="wrapperClass"
     [style.height]="wrapperStyle?.height"
     [style.width]="wrapperStyle?.width">
  <div class="form-field-text"
       [hidden]="!showInput">
    <label class="form-label pointer-events">
      {{label | translate}}
    </label>
    <div class="text-field">
      <input class="form-value"
             type="file"
             [formControl]="internalControl"
             (change)="handleUpload($event)"
             autocomplete="off"
             #input
             [id]="inputId"
             [name]="nameId"
             [maxLength]="maxLength"
             [multiple]="multiple"
             [accept]="imageExtensions"
             [placeholder]="placeholder ?? label"
             [readonly]="readonly"
             [disabled]="disabled"
             (wheel)="$event.preventDefault()"
             [required]="required"
             (keyup)="_onInputInteraction()">
      <div class="input"
           (click)="input?.click();"
           *ngIf="showInput">
        <span *ngIf="!input?.value">{{'choose_file' | translate}}</span>
        <span *ngIf="!input?.value">{{'no_file_chosen' | translate}}</span>
        <span *ngIf="input.value">{{fileNames}}</span>
      </div>
    </div>
  </div>
  <div class="thumbnail-wrapper"
       *ngIf="uploadedFiles?.length > 0 && !showInput">
    <div class="thumbnail-container">
      <div class="thumbnail-card"
           *ngFor="let file of uploadedFiles;let i = index;">
        <img [src]="file.image"
             (click)="imageDialog('',file?.image, file.fileName);"
             [alt]="file.fileName" />
        <div class="icon-container">
          <div class="comment-div">
            <div class="comment-wrapper">
              <mat-icon svgIcon="comment"
                        (click)="showCommentDialog(file.comment, file.id)"></mat-icon>
              <div class="red-icon"
                   *ngIf="file?.comment">
              </div>
            </div>

          </div>
          <mat-icon svgIcon="delete"
                    (click)="removeFile(i);"></mat-icon>
        </div>
      </div>
      <div class="placeholder-uploader new-item"
           *ngIf="uploadedFiles.length < maxLength">
        <div class="drag-drop-container">
          <div class="dd-image-container">
            <div class="car-image-container default-image">
              <img [src]="placeHolderUrl"
                   alt="loading..." />
            </div>
          </div>
          <div class="dd-content">
            <xa-button type="primary"
                       size="sm"
                       (click)="triggerUpload()">{{'upload' | translate}}</xa-button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="uploadedFiles.length === 0 && !showInput"
       class="placeholder-uploader new-item">

    <div class="drag-drop-container">
      <div class="dd-image-container">
        <div class="car-image-container default-image">
          <img [src]="placeHolderUrl"
               alt="loading..." />
        </div>
      </div>
      <div class="dd-content">
        <xa-button type="primary"
                   size="sm"
                   (click)="triggerUpload()">{{'upload' | translate}}</xa-button>
      </div>
    </div>
  </div>
</div>
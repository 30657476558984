export const CheckPartsAvailabilityTableConfig = {
  columns: [
    {
      id: 'checked',
      width: '60px',
      header: {
        text: '',
        tooltip: '',
      },
      selector: {
        field: 'checked',
      },
      cellStyle: 'justify-content:center;padding:0',
      widget: {
        type: 'checkbox',
        default: true
      }
    },
    {
      id: 'Id',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'Id',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'id',
      },
      widget: {
        type: 'text'
      }
    },
    {
      id: 'itemCode',
      sortable: false,
      header: {
        text: 'item_code',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'itemCode',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'oemCode',
      sortable: false,
      header: {
        text: 'oe_code',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'oemCode',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'amCode',
      sortable: false,
      header: {
        text: 'am_code',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'amCode',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'itemName',
      sortable: false,
      width: 'auto',
      header: {
        text: 'item_name_inventory',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'itemName',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'manufacturer',
      sortable: false,
      header: {
        text: 'manufacturer',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'manufacturer',
      },
      widget: {
        type: 'text'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'supplier',
      sortable: false,
      header: {
        text: 'supplier',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'supplier',
      },
      widget: {
        type: 'text'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'condition',
      sortable: false,
      header: {
        text: 'condition',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'condition',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'location',
      sortable: false,
      header: {
        text: 'location',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'location',
      },
      widget: {
        type: 'html'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'stockInHand',
      sortable: false,
      header: {
        text: 'stock_in_hand',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'stockInHand',
      },
      widget: {
        type: 'text'
      },
      cellStyle: 'justify-content: center;'
    },
    // {
    //   id: 'available',
    //   sortable: false,
    //   header: {
    //     text: 'available',
    //     tooltip: '',
    //     style: 'text-align: center;'
    //   },
    //   selector: {
    //     field: 'available',
    //   },
    //   widget: {
    //     type: 'text'
    //   },
    //   cellStyle: 'justify-content: center;'
    // },
    {
      id: 'unitPrice',
      sortable: false,
      header: {
        text: 'price_unit',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'unitPrice',
      },
      widget: {
        type: 'text'
      },
      cellStyle: 'justify-content: center;'
    },
   
    {
      id: 'quantity',
      sortable: false,
      header: {
        text: 'quantity',
        tooltip: '',
        style: 'text-align: center;'
      },
      selector: {
        field: 'dropDownModel',
      },
      widget: {
        type: 'dropdown-open'
      },
      result: {
        value: 'edit'
      },
      cellStyle: 'justify-content: center;'
    },
    {
      id: 'action',
      width: '0px',
      style: 'display: none',
      selector: {
        field: 'mode',
      },
      header: {
        text: 'action',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      widget: {
        type: 'action',
        config: {
          svgIcon: 'edit',
          style: 'fill: var(--xa-black) !important'
        }
      },
      result: {
        value: 'edit'
      }
    },
    {
      id: 'PartGuid',
      width: '0px',
      style: 'display: none',
      header: {
        text: 'PartGuid',
        tooltip: '',
        style: 'display: none; max-width: 0'
      },
      cellStyle: 'display: none; max-width: 0',
      selector: {
        field: 'partGuid',
      },
      widget: {
        type: 'text'
      }
    },
  ]
};
<div class="form-field-text">
  <label for="inputId"
         class="form-label pointer-events">
    {{label | translate}}
    <span class="required"
          *ngIf="required">*</span>
  </label>
  <div class="text-field">
    <textarea class="form-value"
              [class.resize]='!canResize'
              autocomplete="off"
              #textarea
              [id]="inputId"
              [name]="nameId"
              placeholder="{{placeholder || label | translate}}"
              [readonly]="readonly"
              [disabled]="disabled"
              [required]="required"
              [value]="value"
              [rows]="rows"
              [maxLength]="maxLength"
              [cols]="cols"
              (keyup)="_onInputInteraction();onTouched()"
              (paste)="onPaste()">
    </textarea>
  </div>
</div>

<div class="dashboard-view-container">
    <div class="header">
        <h3>{{modalTitle | translate}}</h3>
        <span style="display: flex;gap: 16px;padding-bottom: 16px;">
            <chq-button [buttonModel]="exportButtonModel"></chq-button>
            <div class="close-icon">
                <mat-icon [svgIcon]="'cross'" (click)="cancelDialog();" (keyPress)="cancelDialog();"></mat-icon>
            </div>
        </span>
    </div>
    <div class="content">
        <div class="table-section fx-1">
            <div class="table-container">
                <div class="table-wrapper">
                    <xa-table 
                    [config]="tableConfig" 
                    [tableData]="tableContent" 
                    [cellClicked]="handleCellClick"
                    [pageChanged]="pageChanged" [i18n]="i18n"
                    ></xa-table>
                </div>
                <div class="pagination-wrapper" *ngIf="tableContent?.length > 0 && (pagination.count > pagination.pageSize)">
                    <xa-paginator #paginator (paginationChange)="handlePaginationChange($event)"
                    [pagination]="pagination">
                    </xa-paginator>
                </div>        
            </div>
        </div>
    </div>
</div>
